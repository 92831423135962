import LossSupportLandingView, {
  LossSupportLandingViewProps,
} from './views/LossSupportLandingView'
import Router from 'shared/universal-router'
import { CompatRouter, Routes, Route } from 'react-router-dom-v5-compat'
import LossSupportGuideCardsView from './views/LossSupportGuideCardsView'
// @ts-ignore
import { getHistory } from 'lib/history'
import css from './styles.scss'
import { LossSupportGuideCardsViewProps } from './views/LossSupportGuideCardsView'
import { babylossGuidesPath, babylossPath } from '../lib/urls'

export interface BabylossAppProps {
  supportContentLinks: LossSupportLandingViewProps['links']
  guides: LossSupportGuideCardsViewProps['guides']
  path: string
}

export default function BabylossApp({
  guides,
  supportContentLinks,
  path,
}: BabylossAppProps) {
  return (
    <div className={css.root}>
      {/* @ts-ignore */}
      <Router location={{ pathname: path }}>
        <CompatRouter>
          <Routes>
            <Route
              path={babylossPath}
              element={<LossSupportLandingView links={supportContentLinks} />}
            />
            <Route
              path={babylossGuidesPath}
              element={<LossSupportGuideCardsView guides={guides} />}
            />
          </Routes>
        </CompatRouter>
      </Router>
    </div>
  )
}
