import Fade from 'react-reveal/Fade'
import Image from 'components/image'
import Link from 'components/link'
import { helloBabyPath } from 'lib/urls'
import { InfoButton } from '../shared/Buttons'
import css from './GuidesSection.scss'
import { SectionHeading } from '../shared/Headings'
import { DEFAULT_GUIDE_CATEGORIES, DEFAULT_GUIDES } from './constants'
import { Guide, GuideCategory } from './types'
import SectionText from '../shared/SectionText'

interface GuidesSectionProps {
  categories?: Array<GuideCategory>
  guides?: Array<Guide>
}

const GuidesSection = ({
  categories = DEFAULT_GUIDE_CATEGORIES,
  guides = DEFAULT_GUIDES,
}: GuidesSectionProps) => {
  const guideFlexItems = guides.map((guide) => (
    <div className={css.Guide} key={guide.title} title={guide.title}>
      <Link title={guide.title} url={guide.link}>
        <Image lazyLoad alt={guide.title} src={guide.image} />
        <p className={css.GuideTitle}>{guide.title}</p>
      </Link>
    </div>
  ))

  const guideCategoryFlexItems = categories.map((category) => (
    <InfoButton
      empty
      pill
      className={css.GuideCategoryLink}
      href={category.link}
      key={category.title}
    >
      {category.title}
    </InfoButton>
  ))
  return (
    <section className={css.GuidesSection}>
      <div className={css.GuidesSectionContainer}>
        <Fade bottom>
          <SectionHeading className={css.GuidesSectionHeading}>
            Your Questions, Answered
          </SectionHeading>
          <SectionText
            className={css.SectionText}
            data-testid="guidesSectionCopy"
          >
            Make the right decisions for your family every step of the way with
            our expert-vetted guides.
          </SectionText>
        </Fade>
        <div className={css.GuidesContainer}>{guideFlexItems}</div>
        <SectionHeading className={css.GuideCategoryHeading}>
          Explore Guides By Category
        </SectionHeading>
        <div className={css.GuideCategoryContainer}>
          {guideCategoryFlexItems}
        </div>
        <div className={css.ButtonGroup}>
          <InfoButton pill href={helloBabyPath}>
            Explore Guides
          </InfoButton>
        </div>
      </div>
    </section>
  )
}

export default GuidesSection
