import Fade from 'react-reveal/Fade'
import { findPath, newRegistryPath } from 'lib/urls'
import { InfoButton, LightButton } from '../shared/Buttons'
import css from './CongratulationsSection.scss'
import { SectionHeading } from '../shared/Headings'
import SectionText from '../shared/SectionText'

const CongratulationsSection = () => (
  <section className={css.CongratulationsSection}>
    <div className={css.CongratulationsSectionContent}>
      <Fade bottom>
        <SectionHeading className={css.CongratulationsSectionHeading}>
          Congratulations!
        </SectionHeading>

        <SectionText
          className={css.CongratulationsSectionSubtext}
          data-testid="congratsSectionSubtext"
        >
          Here at Babylist, we take care of growing families. Join millions of
          families who have used Babylist as their baby registry and beyond.
        </SectionText>
      </Fade>

      <div className={css.ButtonGroup}>
        <InfoButton pill className={css.Button} href={newRegistryPath}>
          Start Your Registry
        </InfoButton>
        <LightButton empty pill className={css.Button} href={findPath}>
          Find a Registry
        </LightButton>
      </div>
    </div>
  </section>
)

export default CongratulationsSection
