import { Grid, Row, Col } from 'react-bootstrap'
import classnames from 'classnames'
import { LinkButton } from '../../components/buttons'
import Image from '../../components/image'
import css from './styles.scss'
import Link from '../../components/link'
// @ts-ignore
import { FacebookIcon, InstagramIcon, TikTokIcon } from '../../components/icons'

// Note to developer: Remember to update the `lastmod` entry for this page in file sitemap.rb
export default function BabylistCribs() {
  function trackVisitToEventbrite() {
    // @ts-ignore
    window.ga('send', 'event', {
      eventCategory: 'Outbound Link',
      eventAction: 'click',
      eventLabel:
        'https://www.eventbrite.com/e/babylist-cribs-nyc-tickets-313891406647',
    })
  }

  return (
    <div className="company-page">
      <section className={classnames('company-page-hero', css.header)}>
        <Image
          alt="Babylist cribs logo"
          className={classnames(css.babylistCribsLogo)}
          src="//images.babylist.com/image/upload/v1638571891/logo_ojjpmm.svg"
        />
      </section>
      <Grid componentClass="main" className={classnames('text-center')}>
        <Row>
          <Col xs={12} lg={8} lgOffset={2}>
            <p className="mtxl">
              Babylist Cribs is coming to New York! (Brooklyn, to be specific.)
            </p>
            <p className="mtxl">
              Babylist Cribs is an immersive home where guests can experience
              our editor-tested and most popular products, interactive brand
              partnerships and educational content all styled in a <em>real</em>{' '}
              home. Test and try Babylist brands and products firsthand as they
              are intended to be.
            </p>
            <p className="mtxl">See you at the Crib!</p>
            <p className="mtxl">
              <address className="man">Brooklyn, New York</address>
              <time dateTime="2022-01-14" className="man">
                May 13 - 22, 2022
              </time>
            </p>

            <LinkButton
              pill
              href="https://www.eventbrite.com/e/babylist-cribs-nyc-tickets-313891406647"
              target="_blank"
              onClick={() => {
                trackVisitToEventbrite()
              }}
              className="mtl mbxxl"
              newWindow
              variant="info"
            >
              Attend Cribs
            </LinkButton>

            <div className="visible-xs">
              <div className={css.imageGrid}>
                <Image
                  className="wh-100 object-cover"
                  src="//images.babylist.com/image/upload/v1649953049/crib-mobile_kpa2hk.png"
                />

                <Image
                  className={classnames(
                    css.twoCellFirstRow,
                    'wh-100',
                    'object-cover'
                  )}
                  src="//images.babylist.com/image/upload/v1649953054/playroom-mobile_nrqyjf.png"
                />

                <Image
                  className="wh-100 object-cover"
                  src="//images.babylist.com/image/upload/v1649953048/house-mobile_ijiuby.png"
                />

                <Image
                  className={classnames(
                    css.twoCellSecondRow,
                    'wh-100',
                    'object-cover'
                  )}
                  src="//images.babylist.com/image/upload/v1649953053/nursery-mobile_sqtftb.png"
                />
              </div>
            </div>

            <div className="hidden-xs">
              <div className="flex">
                <div className="flex-grow prm">
                  <Image
                    className="w-100"
                    src="//images.babylist.com/image/upload/v1649953058/house-desktop_wee0w2.png"
                  />
                </div>

                <div
                  className="flex space-between flex-grow plm prm"
                  style={{ flexDirection: 'column' }}
                >
                  <Image
                    className="w-100"
                    src="//images.babylist.com/image/upload/v1649953055/playroom-desktop_kbib7m.png"
                  />

                  <Image
                    className="w-100"
                    src="//images.babylist.com/image/upload/v1649953056/nursery-desktop_ljnkys.png"
                  />
                </div>

                <div className="flex-grow plm">
                  <Image
                    className="w-100"
                    src="//images.babylist.com/image/upload/v1649953062/crib-desktop_gbcv7u.png"
                  />
                </div>
              </div>
            </div>

            <h2 className="color-underline--info text-bold h3 mbm mbm-sm mtxxl">
              Editors' Picks
            </h2>
            <p>
              Babylist welcomes guests to walk room-by-room to see, touch and
              test our vast array of products for life with baby. Need an
              expert? Expect to find one on-site!
            </p>

            <h2 className="color-underline--info text-bold h3 mbm mbm-sm">
              Custom Activations
            </h2>
            <p>
              Babylist and our brand partners offer guests customized and
              engaging activations from photo moments and giveaways to
              one-of-a-kind experiences.
            </p>

            <h2 className="color-underline--info text-bold h3 mbm mbm-sm">
              Gifting
            </h2>
            <p>
              Leave with an exclusive Babylist gift you'll find <em>only</em> at
              Cribs.
            </p>
            <p className="mtxl">
              The space will bring our Babylist community together, so join us
              for a taste of all that Babylist has to offer! Whether you
              currently have a Babylist registry, already have little ones at
              home, are on your unique path to parenthood, or are celebrating
              the parents in your life—Babylist Cribs invites you all.
            </p>
            <p className="mtxl">
              A welcoming canvas for creators, we hope to authentically inspire
              attendees to capture content through their own lens and share
              their experiences in this real-life setting.
            </p>
            <p className="mtxl">
              Socially distanced and safe in nature, access will require
              reservations. All current Covid-19 safety precautions will be
              taken.
            </p>

            <h2 className="color-underline--info text-bold h3 mbm mbm-sm">
              <Link url="https://www.babylist.com/babylist-cribs-giveaway">
                Enter our Babylist Cribs Sweepstakes!
              </Link>
            </h2>
            <p>
              To celebrate the launch of our Cribs NYC experience, we're filling{' '}
              <em>your</em> crib! Enter to win a $5,000 Babylist Shop gift card
              to get <em>allll</em> the baby gear you need (and then some).
            </p>
            <LinkButton
              pill
              href="https://www.babylist.com/babylist-cribs-giveaway"
              target="_blank"
              className="mtl"
              newWindow
              variant="info"
            >
              Enter Now
            </LinkButton>

            <h2 className="color-underline--info text-bold h3 mbm mbm-sm">
              Can't make it?
            </h2>
            <p>Follow us on social media to get involved!</p>
            <div
              className={classnames(
                css.socialMediaIcons,
                'flex justify-center'
              )}
            >
              <Link
                url="https://www.tiktok.com/@babylist?lang=en"
                newWindow
                className="mll mrl"
              >
                {/* @ts-ignore */}
                <TikTokIcon height="45" />
              </Link>
              <Link
                url="https://www.instagram.com/babylist/?hl=en"
                newWindow
                className="mll mrl"
              >
                <InstagramIcon height={45} />
              </Link>
              <Link
                url="https://www.facebook.com/babylist/"
                newWindow
                className="mll mrl"
              >
                <FacebookIcon height={45} />
              </Link>
            </div>
            <br />
            <p>
              You can also check out{' '}
              <a
                href="https://showroom.babylist.com/los-angeles"
                target="_blank"
              >
                Virtual Showroom
              </a>{' '}
              to get a peek at our Babylist Cribs LA experience or watch the
              video walkthrough below.
            </p>
            <br />
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/w8viE7CdSik"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>

            <h2 className="color-underline--info text-bold h3 mbm mbm-sm mtxxl">
              Brought to you by our Partners:
            </h2>

            <h3 className="mtxl bold">Presenting Partner:</h3>

            <Link newWindow url="https://www.afterpay.com/en-AU">
              <Image
                height={46}
                className="mtxl"
                src="//images.babylist.com/image/upload/v1638573874/afterpay_logo_f16coi.svg"
              />
            </Link>

            <h3 className="mtxl bold">Partners:</h3>

            <div
              className={classnames(
                css.partners,
                'mbl flex wrap justify-center align-center'
              )}
            >
              <Link url="https://www.babylist.com/store/b/4moms/408" newWindow>
                <Image
                  height={36}
                  alt="4moms logo"
                  src="//images.babylist.com/image/upload/v1638808611/logos/4moms.svg"
                />
              </Link>

              <Link
                url="https://www.babylist.com/store/b/babybjorn/62"
                newWindow
              >
                <Image
                  height={36}
                  alt="BabyBjorn Logo"
                  src="//images.babylist.com/image/upload/v1649950996/logos/babybjorn-logo_zw4pvv.svg"
                />
              </Link>

              <Link
                url="https://www.babylist.com/store/b/baby-brezza/680"
                newWindow
              >
                <Image
                  height={36}
                  alt="Baby brezza logo"
                  src="//images.babylist.com/image/upload/v1638808705/logos/babybrezza.svg"
                />
              </Link>

              <Link url="http://babylist.com/store/b/boon/234" newWindow>
                <Image
                  style={{ position: 'relative', top: -10 }}
                  alt="Boon logo"
                  height={40}
                  src="//images.babylist.com/image/upload/v1639416108/logos/Boon_1.svg"
                />
              </Link>

              <Link url="https://www.babylist.com/store/b/bugaboo/2" newWindow>
                <Image
                  alt="Bugaboo logo"
                  height={40}
                  src="//images.babylist.com/image/upload/v1649950996/logos/Bugaboo_Logo_copy_wcrk9a.svg"
                />
              </Link>

              <Link
                url="https://www.babylist.com/store/b/ergobaby/67"
                newWindow
              >
                <Image
                  alt="Ergo Baby logo"
                  height={66}
                  src="//images.babylist.com/image/upload/v1638808754/logos/ergo.svg"
                />
              </Link>

              <Link url="https://hellobello.com/" newWindow>
                <Image
                  alt="Hello Bello logo"
                  height={66}
                  src="//images.babylist.com/image/upload/v1649950997/logos/HelloBello_FinalLogo-WhiteLetters_9-13-2021_2_copy_i0yugv.svg"
                />
              </Link>

              <Link url="https://www.babylist.com/store/b/lalo/1123" newWindow>
                <Image
                  alt="Lalo logo"
                  height={26}
                  src="//images.babylist.com/image/upload/v1638808830/logos/lalo.svg"
                />
              </Link>

              <Link
                url="https://www.babylist.com/store/b/maxi-cosi/481"
                newWindow
              >
                <Image
                  alt="Maxi-Cosi logo"
                  height={44}
                  src="//images.babylist.com/image/upload/v1649950996/logos/Maxi_LogoNEW_full_blue_V4_copy_ar9rz4.svg"
                />
              </Link>

              <Link url="https://www.babylist.com/store/b/nanit/936" newWindow>
                <Image
                  alt="Nanit logo"
                  height={36}
                  src="//images.babylist.com/image/upload/v1638808880/logos/Nanit.svg"
                />
              </Link>

              <Link url="https://www.babylist.com/store/b/nuna/628" newWindow>
                <Image
                  alt="Nuna logo"
                  height={20}
                  src="//images.babylist.com/image/upload/v1638808975/logos/Nuna.svg"
                />
              </Link>

              <Link url="https://www.babylist.com/store/b/owlet/911" newWindow>
                <Image
                  alt="Owlet logo"
                  height={36}
                  src="//images.babylist.com/image/upload/v1638809035/logos/owlet.svg"
                />
              </Link>

              <Link url="https://pipettebaby.com/collections/baby" newWindow>
                <Image
                  alt="Pipette logo"
                  height={40}
                  src="//images.babylist.com/image/upload/v1649950996/logos/Pipette_qzgnn5.png"
                />
              </Link>

              <Link url="https://www.skiphop.com/" newWindow>
                <Image
                  alt="Skip Hop logo"
                  height={36}
                  src="//images.babylist.com/image/upload/v1649950996/logos/SH_Logo_CMYK_1_copy_qnztgv.svg"
                />
              </Link>

              <Link url="https://www.babylist.com/store/b/stokke/4" newWindow>
                <Image
                  alt="Stokke logo"
                  height={66}
                  src="//images.babylist.com/image/upload/v1638809142/logos/Stokke.svg"
                />
              </Link>

              <Link url="https://www.tinyorganics.com/" newWindow>
                <Image
                  alt="Tiny Organics logo"
                  height={22}
                  src="//images.babylist.com/image/upload/v1638809181/logos/Tiny_Organics.svg"
                />
              </Link>

              <Link
                url="https://www.babylist.com/store/b/uppababy/12"
                newWindow
              >
                <Image
                  alt="UPPAbaby logo"
                  height={30}
                  src="//images.babylist.com/image/upload/v1638809226/logos/Uppa.svg"
                />
              </Link>

              <Link
                style={{ position: 'relative', top: -10 }}
                url="https://babylist.com/store/b/wellements/1347"
                newWindow
              >
                <Image
                  alt="Wellments logo"
                  height={40}
                  src="//images.babylist.com/image/upload/v1638809288/logos/wellements.svg"
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}
