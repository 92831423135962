import January from './January.svg'
import February from './February.svg'
import March from './March.svg'
import April from './April.svg'
import May from './May.svg'
import June from './June.svg'
import July from './July.svg'
import August from './August.svg'
import September from './September.svg'
import October from './October.svg'
import November from './November.svg'
import December from './December.svg'

export {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}
