import { useState } from 'react'
import { map } from 'lodash'
import { SubHeading } from 'components/headings'
import { Input, Form, Select, DatePickerInput, Label } from 'components/forms'
import { SaveButton } from 'components/buttons'
import { FieldValues, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import Moment from 'moment'

export const UserRegistryShape = {
  babies: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      gender: PropTypes.string,
      birthdate: PropTypes.string,
      relationship: PropTypes.string,
      giftGiverNickname: PropTypes.string,
    })
  ).isRequired,
}

export interface GiftGiverPreferencesFormProps {
  userRegistry: any
  handleSubmit: (arg0: Object, arg1: (errors: any) => void) => void
  formOptions: any
}

interface FormData extends FieldValues {
  firstName: string
  gender: string
  giftGiverNickname: string
}

const GiftGiverPreferencesForm = ({
  userRegistry,
  handleSubmit,
  formOptions,
}: GiftGiverPreferencesFormProps) => {
  const {
    register,
    handleSubmit: onSubmit,
    errors,
    setError,
  } = useForm<FormData>()
  const [submitting, setSubmitting] = useState(false)
  // we don't handle multiple babies (yet) but we pretend to in term of API
  const baby = userRegistry.babies[0]
  const [birthdate, setBirthdate] = useState(baby?.birthdate)
  const { genders } = formOptions
  const handleUpdate = (form: FormData) => {
    setSubmitting(true)
    const { firstName, gender, giftGiverNickname } = form
    handleSubmit(
      {
        babies: [
          {
            birthdate,
            firstName,
            gender,
            giftGiverNickname,
          },
        ],
      },
      (errors: any) => {
        errors &&
          Object.keys(errors).map((key: string) =>
            setError(key, 'manual', errors[key].join(','))
          )
        setSubmitting(false)
      }
    )
  }

  const updateDate = (date: Date) => {
    setBirthdate(date && Moment(date).format('YYYY-MM-DD'))
  }

  return (
    <div>
      <SubHeading>{userRegistry.registryTitle}</SubHeading>

      <Form onSubmit={onSubmit(handleUpdate)}>
        <div className="mbxl">
          <Input
            label={<b>Baby's First Name</b>}
            name="firstName"
            inputRef={register({
              required: `Please enter baby's first name`,
            })}
            defaultValue={baby?.firstName}
            error={errors.firstName?.message}
          />
          <Label>
            <b>Baby's Birth Date</b>
          </Label>
          <DatePickerInput
            name="birthdate"
            placeholder="Baby's Birth Date"
            initialValue={birthdate && new Date(birthdate)}
            handleChange={updateDate}
            error={errors.birthdate?.message}
          />
          <Select
            label={<b>It's a...</b>}
            name="gender"
            // todo: use value/onChange
            inputRef={register}
            error={errors.gender?.message}
          >
            <option />
            {map(genders, (label, key) => (
              <option key={key} value={key} selected={baby?.gender == key}>
                {label}
              </option>
            ))}
          </Select>
          <Input
            label={<b>What Does Baby Call You?</b>}
            name="giftGiverNickname"
            help="ex: Aunt Cici, Grandma, Abuela, Guncle Dave, ..."
            inputRef={register({
              required: 'Please indicate what baby calls you',
            })}
            defaultValue={baby?.giftGiverNickname}
            error={errors.giftGiverNickname?.message}
          />
        </div>
        <SaveButton block submitting={submitting} className="">
          Done
        </SaveButton>
      </Form>
    </div>
  )
}

GiftGiverPreferencesForm.propTypes = {
  userRegistry: PropTypes.shape(UserRegistryShape).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formOptions: PropTypes.object.isRequired,
}

export default GiftGiverPreferencesForm
