import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Media } from 'react-bootstrap'

import RegItemNote from 'shared/reg-item-note'
import Image from 'components/image'
import { LinkButton } from 'components/buttons'
import BundleComponentsList from 'shared/product-list/bundle-components-list'

import { CANCEL_RESERVATION } from 'cart/constants'
import {
  setActiveModal,
  showPurchaseInfoForm,
  updateReservation,
} from '../actions'

const UnpurchasableItem = ({
  onSetReservationToRemove,
  onSetPurchasedReservation,
  reservation,
}) => {
  const { regItem } = reservation

  const purchaseInfoComponent = (
    <>
      <LinkButton
        className="xsmall pan"
        onClick={() => onSetPurchasedReservation(reservation)}
      >
        I've purchased this
      </LinkButton>{' '}
      <span className="text-muted mhs">| </span>
    </>
  )

  const bundleComponentList =
    reservation.bundleComponents?.length > 0 ? (
      <BundleComponentsList
        products={reservation.bundleComponents}
        className="pbm"
        showShippingMsg={false}
      />
    ) : null

  return (
    <div className="unpurchasable-item">
      <Media key={`${reservation.id}`} className="media-order-item pvl">
        <Media.Left className="prl">
          <Image
            width={100}
            height={100}
            src={regItem.imgUrl}
            alt={regItem.title}
          />
        </Media.Left>

        <Media.Body>
          <div className="media-heading h5 mbm">{regItem.title}</div>
          <div>
            {regItem.description ? (
              <RegItemNote
                description={regItem.description}
                className="h6 mtn mbm"
              />
            ) : null}

            <div className="hidden-xs">
              {bundleComponentList}
              {onSetPurchasedReservation && purchaseInfoComponent}
              <LinkButton
                className="xsmall pan"
                onClick={() => onSetReservationToRemove(reservation)}
              >
                Remove
              </LinkButton>
            </div>
          </div>
        </Media.Body>
      </Media>

      <div className="visible-xs">
        {bundleComponentList}
        {purchaseInfoComponent}
        <p className="h7">
          <LinkButton
            className="xsmall pan"
            onClick={() => onSetReservationToRemove(reservation)}
          >
            Remove
          </LinkButton>
        </p>
      </div>
    </div>
  )
}

UnpurchasableItem.propTypes = {
  onSetReservationToRemove: PropTypes.func.isRequired,
  onSetPurchasedReservation: PropTypes.func.isRequired,
  onUpdateQuantity: PropTypes.func.isRequired,
  reservation: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSetReservationToRemove: (reservation) =>
      dispatch(setActiveModal(CANCEL_RESERVATION, { reservation })),
    onShowPurchaseInfoForm: showPurchaseInfoForm,
    onUpdateReservation: updateReservation,
  }
}

// eslint-disable-next-line import/no-default-export
export default connect((state) => ({}), mapDispatchToProps)(UnpurchasableItem)
