import BabyUITheme from 'baby-ui/lib/BabyUITheme'
import BabyUIPaletteColorNode from 'baby-ui/lib/BabyUIPaletteColorNode'
import { ComponentsProps } from '@material-ui/core/styles/props'

export default class LandingPageTheme extends BabyUITheme {
  protected htmlBaseFontSize = 10

  private readonly colorCream = '#F9F6F3'

  private readonly colorEggplant = '#6E3264'

  private readonly colorPoppy = '#E05F31'

  private readonly dividerColor = '#54A2AB80'

  getPropsOverrides(): Partial<ComponentsProps> {
    return {
      ...super.getPropsOverrides(),
      MuiDivider: { style: { backgroundColor: this.dividerColor } },
    }
  }

  protected getHTMLFontSize(): number {
    return this.htmlBaseFontSize
  }

  protected getPrimaryColor() {
    return new BabyUIPaletteColorNode(this.colorEggplant)
  }

  protected getSecondaryColor() {
    return new BabyUIPaletteColorNode(this.colorPoppy)
  }

  protected getBackgroundColor() {
    return { contrast: this.colorCream, default: '#fff' }
  }
}
