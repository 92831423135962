import PropTypes from 'prop-types'
import { SubHeading } from 'components/headings'
import pluralize from 'pluralize'

const CartSectionHeader = ({ className, title, count }) => (
  <SubHeading className={className}>
    <span className="text-bold">{title}</span>
    {count > 0 && ` (${count} ${pluralize('Item', count)})`}
  </SubHeading>
)

CartSectionHeader.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
}

CartSectionHeader.defaultProps = {
  count: 0,
  className: undefined,
}

export default CartSectionHeader
