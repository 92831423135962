import cn from 'classnames'
import PropTypes from 'prop-types'

import css from './border-box.scss'

export const BorderBox = ({ children, p }) => {
  let parray
  if (typeof p === 'string') {
    parray = [p]
  } else {
    parray = p
  }

  return (
    <div
      className={cn(
        css.borderBox,
        parray.map((pstring) => `p${pstring}`)
      )}
    >
      {children}
    </div>
  )
}

BorderBox.propTypes = {
  children: PropTypes.node.isRequired,
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

BorderBox.defaultProps = {
  p: '',
}
