import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty, pick } from 'lodash'
import { Media } from 'react-bootstrap'
import Image from 'components/image'
import { numberToCurrency } from 'lib/money'
import GiftActions from 'registry/gift-reservations/gift-actions'
import Card from 'components/card'
import { getGiftCardImageUrl } from 'cart/lib'
import CollapsablePurchaseInfo from './collapsable-purchase-info'
import {
  markAsPurchased,
  submitReservation,
  updateReservation,
} from '../actions'
import { PrintableDownloadButton } from './printable-download-button'

export class PurchasedItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showUpdateReservationForm: false,
    }
  }

  handleUpdateReservation(data) {
    const reservationFields = pick(data, [
      'id',
      'orderNumber',
      'storeName',
      'quantity',
    ])

    if (!isEmpty(reservationFields)) {
      const reservation = {
        ...this.props.reservation,
        ...reservationFields,
      }
      return submitReservation(reservation).then(() => {
        // Update the reservation first, otherwise there won't be a render with the updated reservation props
        this.props.onUpdateReservation(reservation)
        this.showForm(false)
      })
    }
  }

  showForm(show) {
    this.setState({ showUpdateReservationForm: show })
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      onMarkAsPurchased,
      reservation,
      reservation: {
        canCancelOrder,
        displayTitle,
        giftAmount,
        quantity,
        regItem,
        orderCancelUntil,
        orderNumber,
        orderUuid,
        storeName,
        giftCardPrintableUrl,
        isGroupGiftContribution,
        trackingInfos,
      },
    } = this.props
    const { imgUrl, isCashGift, isGiftCard, title } = regItem
    const giftCardImgUrl =
      reservation.giftCardOptions?.length > 0
        ? getGiftCardImageUrl(
            reservation.productId,
            reservation.giftCardOptions
          )
        : null

    const collapsablePurchaseInfoComponent = (
      <CollapsablePurchaseInfo
        formKey={`${this.props.reservation.id}-purchased`}
        reservation={this.props.reservation}
        show={storeName !== 'Babylist'}
        onDismiss={this.showForm.bind(this, false)}
        onShowPurchaseInfoForm={onMarkAsPurchased.bind(
          this,
          this.props.reservation,
          { isPurchased: false, showPurchaseInfoForm: false }
        )}
        onSubmit={this.handleUpdateReservation.bind(this)}
      />
    )

    const orderInfoComponent = (
      <>
        {!this.state.showUpdateReservationForm && (
          <div className="mbl">
            {storeName || orderNumber ? (
              <div>
                {storeName === 'Babylist' ? (
                  <>
                    <div className="h6 mbs text-uppercase text-bold">
                      Purchase Info
                    </div>
                    <div className="h6 mvs">Store: {storeName}</div>
                    <div className="h6 mvs">Order Number: {orderNumber}</div>
                  </>
                ) : null}
                <GiftActions
                  canCancelOrder={canCancelOrder}
                  className="hidden-xs mbm"
                  orderCancelUntil={orderCancelUntil}
                  orderUuid={orderUuid}
                  trackingInfos={trackingInfos}
                />

                {giftCardPrintableUrl && (
                  <div className="pts">
                    <PrintableDownloadButton url={giftCardPrintableUrl} />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )}
      </>
    )

    return (
      <Card className="pal mbl">
        <Media id={`${reservation.id}`}>
          <Media.Left className="prl">
            <Image
              alt={title}
              height={100}
              src={giftCardImgUrl || imgUrl}
              width={100}
            />
          </Media.Left>

          <Media.Body>
            <div className="h5 media-heading mbm">
              {isGroupGiftContribution || isGiftCard ? displayTitle : title}
            </div>
            {isGroupGiftContribution && <div className="h6 mbm">{title}</div>}
            {giftAmount && (
              <div className="h6 mbn">
                Gift Amount: {giftAmount && numberToCurrency(giftAmount)}
              </div>
            )}
            {!giftAmount && <div className="h6">Qty: {quantity}</div>}

            <div>
              {!isCashGift && !isGiftCard && collapsablePurchaseInfoComponent}
              {orderInfoComponent}
            </div>
          </Media.Body>
        </Media>
      </Card>
    )
  }
}

PurchasedItem.propTypes = {
  reservation: PropTypes.object.isRequired,
  onMarkAsPurchased: PropTypes.func.isRequired,
  onUpdateReservation: PropTypes.func.isRequired,
}

export default connect((state) => ({}), {
  onMarkAsPurchased: markAsPurchased,
  onUpdateReservation: updateReservation,
})(PurchasedItem)
