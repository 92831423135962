import { FeaturedGuideCard } from 'components/card'
import PageHeader from '../../assets/PageHeader'
import ExpectfulGuideLabel from './ExpectfulGuideLabel'
import { Col, Row } from 'react-bootstrap'
import { FeaturedGuideCardProps } from '../../../component-types'
// @ts-ignore
import { Helmet } from 'react-helmet'
// @ts-ignore
import { babylistUrl, babylossPath } from 'lib/urls'

export type BLAndExpectfulGuideCardMeta = Pick<
  FeaturedGuideCardProps,
  'imageUrl' | 'postUrl' | 'teaser' | 'title' | 'type'
> & { isExpectfulGuide: boolean; guideLabel?: string }

export interface LossSupportGuideCardsViewProps {
  guides: BLAndExpectfulGuideCardMeta[]
}

export default function LossSupportGuideCardsView({
  guides,
}: LossSupportGuideCardsViewProps) {
  return (
    <>
      <Helmet
        link={[
          {
            rel: 'canonical',
            href: babylistUrl(babylossPath),
          },
        ]}
        meta={[
          {
            name: 'description',
            content:
              'Read articles about miscarriage and loss to support you as you heal. Babylist is here for you.',
          },
        ]}
        title="Miscarriage & Loss Articles | Babylist"
      />
      <PageHeader content="Articles on Miscarriage & Loss" />
      <Row>
        {guides.map(({ postUrl, guideLabel, isExpectfulGuide, ...rest }) => (
          <Col key={postUrl} sm={12}>
            <a href={postUrl} target="_blank" rel="nofollow">
              <FeaturedGuideCard
                {...rest}
                guideLabel={
                  isExpectfulGuide ? <ExpectfulGuideLabel /> : guideLabel
                }
              />
            </a>
          </Col>
        ))}
      </Row>
    </>
  )
}
