export const EmptyCartWave = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="231"
      viewBox="0 0 375 231"
      fill="none"
      preserveAspectRatio="none"
      style={{ display: 'block' }}
    >
      <path
        d="M662 3.81252V279.235C655.313 281.228 649.86 283.764 644.486 286.262C633.138 291.54 621.403 297 598.326 297C575.248 297 563.513 291.54 552.165 286.262C540.749 280.953 528.947 275.46 505.716 275.46C482.485 275.46 470.682 280.953 459.267 286.262C447.918 291.54 436.183 297 413.106 297C390.029 297 378.3 291.54 366.951 286.262C355.536 280.953 343.727 275.46 320.502 275.46C297.278 275.46 285.469 280.953 274.053 286.262C262.705 291.54 250.97 297 227.892 297C204.815 297 193.086 291.54 181.738 286.262C170.322 280.953 158.519 275.46 135.289 275.46C112.058 275.46 100.255 280.953 88.8394 286.262C77.4911 291.54 65.756 297 42.6849 297C21.9473 297 10.3657 292.59 0 287.867V12.4715C10.3596 17.1779 21.9903 21.5397 42.6849 21.5397C65.9157 21.5397 77.7184 16.0471 89.1342 10.7375C100.489 5.46031 112.224 0 135.295 0C158.366 0 170.107 5.46031 181.455 10.7375C192.871 16.0471 204.68 21.5397 227.905 21.5397C251.129 21.5397 262.938 16.0471 274.354 10.7375C285.702 5.46031 297.437 0 320.515 0C343.592 0 355.321 5.46031 366.669 10.7375C378.085 16.0471 389.894 21.5397 413.118 21.5397C436.343 21.5397 448.152 16.0471 459.567 10.7375C470.916 5.46031 482.651 0 505.722 0C528.793 0 540.534 5.46031 551.883 10.7375C563.298 16.0471 575.101 21.5397 598.332 21.5397C621.563 21.5397 633.365 16.0471 644.775 10.7375C650.068 8.28201 655.442 5.77802 662 3.81252Z"
        fill="#F9EAFF"
      />
    </svg>
  )
}

export default EmptyCartWave
