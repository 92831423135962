import { Component } from 'react'
import PropTypes from 'prop-types'

export default class FormHeader extends Component {
  static propTypes = {
    questionText: PropTypes.string,
  }

  render() {
    return (
      <div>
        <p className="mtl mbm lead">{this.props.questionText}</p>
      </div>
    )
  }
}
