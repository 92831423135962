/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react'
import { Button } from 'baby-design'
import css from './how-it-works.styles.scss'

const EXAMPLE_URL =
  'https://www.skiphop.com/skiphop-bath-tubs-and-essentials/V_235100.html'

const HowItWorks: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const onVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }

  return (
    <div className={css.HowItWorks}>
      <div className={css.HowItWorks__content}>
        <div className={css.HowItWorks__header}>
          <h3 className={css.HowItWorks__header__title}>
            How to add items using the
            <br />
            Babylist share extension
          </h3>
          <p className={css.HowItWorks__header__description}>
            Find an item from any store on the web. Then, share the item to
            Babylist to add it to your registry.
            <br />
            <br />
            <strong>Watch how it works:</strong>
          </p>
        </div>
        <video
          controls
          muted
          className={css.HowItWorks__video}
          poster="//images.babylist.com/image/upload/v1730343613/extension-instructions/skip-hop-video-poster.png"
          ref={videoRef}
          src="//images.babylist.com/video/upload/v1730342491/extension-instructions/AppShareExtension.mp4"
          onClick={onVideoClick}
          onTouchStart={onVideoClick}
        />
      </div>
      <div className={css.HowItWorks__actions}>
        <h3 className={css.HowItWorks__actions__text}>Now try it out!</h3>
        <Button href={EXAMPLE_URL} size="lg" target="_blank" variant="primary">
          Add the Skip Hop Bath Spout
        </Button>
      </div>
    </div>
  )
}

export default HowItWorks
