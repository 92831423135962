import React from 'react'

interface BackgroundLeftProps {
  className?: string
}

const BackgroundLeft: React.FC<BackgroundLeftProps> = ({
  className,
}: BackgroundLeftProps) => (
  <svg
    className={className}
    width="310"
    height="380"
    viewBox="0 0 310 380"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M240.087 34C230.555 34 222.727 41.3749 222.004 50.6973C221.945 51.4486 222.549 52.0912 223.312 52.0912H231.04C231.595 52.0912 232.061 51.6859 232.13 51.1421C232.606 47.1779 235.995 44.0935 240.087 44.0935C243.951 44.0935 247.191 46.8418 247.944 50.4798C248.132 51.4091 248.945 52.0912 249.896 52.0912H257.04C257.704 52.0912 258.229 51.5277 258.179 50.8653C257.545 41.4639 249.668 34 240.087 34Z"
      fill="url(#paint0_linear_1468_7505)"
    />
    <path
      d="M73.0866 242C63.5549 242 55.7273 249.375 55.004 258.697C54.9446 259.449 55.549 260.091 56.3119 260.091H64.0404C64.5952 260.091 65.0609 259.686 65.1303 259.142C65.6059 255.178 68.9945 252.094 73.0866 252.094C76.9509 252.094 80.1909 254.842 80.9439 258.48C81.1322 259.409 81.9446 260.091 82.8958 260.091H90.0397C90.7035 260.091 91.2287 259.528 91.1791 258.865C90.545 249.464 82.6679 242 73.0866 242Z"
      fill="#FFD43E"
    />
    <path
      d="M47.3704 25.6456C51.4411 30.0112 58.2799 30.2503 62.6455 26.1797C67.0111 22.1091 67.2502 15.2702 63.1796 10.9046C59.109 6.53901 52.2701 6.29989 47.9045 10.3705C43.5389 14.4411 43.2998 21.28 47.3704 25.6456Z"
      fill="url(#paint1_linear_1468_7505)"
    />
    <path
      d="M142.496 130.882L133.247 146.705C132.553 147.894 133.405 149.382 134.783 149.382H153.788C155.186 149.382 156.038 147.855 155.305 146.665L145.54 130.843C144.836 129.703 143.17 129.723 142.496 130.882Z"
      fill="#A0DCFF"
    />
    <path
      d="M259.5 308C253.704 308 249 312.924 249 318.994L249 330.821C249 332.03 250.002 333 251.223 333H267.584C268.92 333 270 331.942 270 330.632L270 318.994C270.013 312.924 265.309 308 259.5 308Z"
      fill="url(#paint2_linear_1468_7505)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1468_7505"
        x1="222"
        y1="52"
        x2="258"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A0DCFF" />
        <stop offset="0.7359" stop-color="#56BB6C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1468_7505"
        x1="45.2334"
        y1="27.5633"
        x2="63.1446"
        y2="10.8671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E3264" />
        <stop offset="1" stop-color="#A0DCFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1468_7505"
        x1="257.388"
        y1="334.761"
        x2="263.301"
        y2="306.594"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0795732" stop-color="#FF6D4E" />
        <stop offset="0.772297" stop-color="#FED7FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default BackgroundLeft
