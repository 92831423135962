/* eslint-disable import/no-default-export */
/* eslint-disable complexity */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { ReservationShape } from 'cart/lib/prop-types'
import { connect } from 'react-redux'
import { remove } from 'lodash'
import RegItemNote from 'shared/reg-item-note'
import BundleComponentsList from 'shared/product-list/bundle-components-list'
import { tracking, track } from 'lib/analytics'
import RegItemImage from 'registry/components/reg-item-image'
import { getGiftCardImageUrl, getGiftCardProductId } from 'cart/lib'
import { Dropdown, IconButton, PriceTag, QuantityPicker } from 'baby-design'
import { GarbageBin } from 'baby-design/icons'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { numberToCurrency } from 'lib/money'
import {
  changeStore,
  markAsPurchased,
  showPurchaseInfoForm,
  updateGiftAmount,
  updateQuantity,
  updateReservation,
} from '../actions'

@tracking(({ reservation }) => ({ reservation }))
export class StoreOrderItem extends Component {
  // reservation is set on comp
  @tracking((props, state, [reservationId, event]) => {
    const { storeName: reservationStoreOriginal } = props
    const reservationStoreUpdated = event.target.value
    return {
      event: track.reservationUpdated,
      eventType: track.EventType.CHANGED_STORE,
      reservationStoreOriginal,
      reservationStoreUpdated,
    }
  })
  handleChangeStore(reservationId, e) {
    const { onChangeStore, storeName } = this.props
    onChangeStore(reservationId, storeName, e.target.value)
  }

  handleUpdateGiftAmount(reservationToken, e) {
    const {
      reservation: { giftCardOptions },
      onUpdateGiftAmount,
    } = this.props
    const giftAmount = e.target.value
    const productId = getGiftCardProductId(giftAmount, giftCardOptions)
    onUpdateGiftAmount(reservationToken, { giftAmount, productId })
  }

  render() {
    const {
      className,
      currentOffer,
      disableBabylistOffer,
      onSetReservationToRemove,
      onUpdateQuantity,
      reservation,
    } = this.props

    const {
      description,
      imgUrl,
      offers,
      quantityNeeded,
      title,
      purchaseQuantityLimit,
      showPurchaseQuantityLimit,
    } = reservation.regItem

    const {
      bundleComponents,
      displayTitle,
      id,
      giftAmount,
      isGroupGiftContribution,
      giftCardOptions,
      productId,
      store,
      quantity,
      token,
    } = reservation

    const isGiftCard = giftCardOptions?.length > 0
    const giftCardImgUrl = isGiftCard
      ? getGiftCardImageUrl(productId, giftCardOptions)
      : null

    // Formats options for gift card dropdown
    const giftCardOptionsObject =
      giftCardOptions?.map((option) => ({
        label: numberToCurrency(option.price, {
          unit: '$',
          truncateZeroCents: true,
        }),
        value: option.price,
      })) || []

    const shouldDisplayQuantitySelect =
      reservation.regItem.quantity &&
      quantity + quantityNeeded > 1 &&
      !isGroupGiftContribution &&
      !isGiftCard

    if (disableBabylistOffer) {
      remove(offers, (o) => o.storeName === 'Babylist')
    }

    const {
      msrp,
      currentPrice,
      currentlyInActiveSale,
      callouts: saleCallouts,
    } = getPriceTagPropsFromPriceDetails(currentOffer?.priceDetails, {
      showCalloutTypes: ['text'],
    })

    const priceTagProps = {
      callouts: saleCallouts,
      currentPrice:
        isGiftCard || isGroupGiftContribution ? giftAmount : currentPrice,
      currentlyInActiveSale,
      msrp: isGiftCard || isGroupGiftContribution ? undefined : msrp,
    }

    const bundleComponentList =
      store === 'Babylist' &&
      !isGroupGiftContribution &&
      bundleComponents?.length > 0 ? (
        <BundleComponentsList
          showShippingMsg
          className="pbm"
          products={bundleComponents}
        />
      ) : null

    const removeComponent = (
      <IconButton
        aria-label="Remove"
        className="mrl"
        size="sm"
        variant="fill-secondary"
        onClick={onSetReservationToRemove}
      >
        <GarbageBin />
      </IconButton>
    )

    return (
      <div className={className}>
        <div className="flex pvl" id={id}>
          <div className="prl">
            <RegItemImage src={giftCardImgUrl || imgUrl} width={100} />
          </div>
          <div className="flex-grow prs-md">
            <div className="h5 man">
              {isGroupGiftContribution || isGiftCard ? displayTitle : title}
            </div>

            {description ? (
              <RegItemNote className="h6 mtn mbm" description={description} />
            ) : null}

            <div className="mvm">
              <div className="visible-xs mvm mbl">
                <PriceTag {...priceTagProps} showSavings size="xs" />
                {bundleComponentList}
              </div>
              {isGiftCard ? (
                <div className="flex" style={{ width: '220px' }}>
                  {removeComponent}
                  <Dropdown
                    options={giftCardOptionsObject}
                    size="sm"
                    value={giftCardOptionsObject.find(
                      (option) => option.value === giftAmount
                    )}
                    onChange={(option) =>
                      this.handleUpdateGiftAmount(token, {
                        target: { value: option.value },
                      })
                    }
                  />
                </div>
              ) : null}
              {!shouldDisplayQuantitySelect && !isGiftCard && removeComponent}
              {shouldDisplayQuantitySelect ? (
                <>
                  <QuantityPicker
                    max={
                      showPurchaseQuantityLimit
                        ? Math.min(
                            purchaseQuantityLimit,
                            quantity + quantityNeeded
                          )
                        : quantity + quantityNeeded
                    }
                    size="sm"
                    type="cart"
                    value={quantity}
                    variant="fill"
                    onMinusClick={(prevValue) =>
                      onUpdateQuantity(token, prevValue - 1)
                    }
                    onPlusClick={(prevValue) =>
                      onUpdateQuantity(token, prevValue + 1)
                    }
                    onTrashClick={onSetReservationToRemove}
                  />
                  <div className="xsmall mts text-muted">
                    {showPurchaseQuantityLimit
                      ? `Limit ${purchaseQuantityLimit} per customer`
                      : (quantityNeeded > 0 &&
                          `Still needs: ${quantity + quantityNeeded}`) ||
                        'Quantity requested reached'}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="hidden-xs text-right">
            <PriceTag {...priceTagProps} showSavings align="right" size="xs" />
          </div>
        </div>
      </div>
    )
  }
}

StoreOrderItem.propTypes = {
  className: PropTypes.string,
  disableBabylistOffer: PropTypes.bool,
  onChangeStore: PropTypes.func.isRequired,
  onSetReservationToRemove: PropTypes.func.isRequired,
  onUpdateGiftAmount: PropTypes.func.isRequired,
  onUpdateQuantity: PropTypes.func.isRequired,
  reservation: PropTypes.shape(ReservationShape).isRequired,
  storeName: PropTypes.string.isRequired,
  useRegistryDiscount: PropTypes.bool.isRequired,
}

StoreOrderItem.defaultProps = {
  className: '',
  disableBabylistOffer: false,
}

export default connect(undefined, {
  onChangeStore: changeStore,
  onMarkAsPurchased: markAsPurchased,
  onShowPurchaseInfoForm: showPurchaseInfoForm,
  onUpdateGiftAmount: updateGiftAmount,
  onUpdateReservation: updateReservation,
  onUpdateQuantity: updateQuantity,
})(StoreOrderItem)
