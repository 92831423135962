import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { RegistryShape } from '../lib/prop-types'
import CartSectionHeader from './cart-section-header'
import ShippingAddress from './shipping-address'

const OrderHistoryHeader = ({ className, registry, showAddress }) => {
  return (
    <div className={className}>
      <Row>
        <CartSectionHeader title="Order History" className="h2" />
        <Col xs={12} md={8}>
          <div>Gift purchases, favors and funds.</div>
        </Col>
        <Col xs={12} md={4}>
          {showAddress && <ShippingAddress registry={registry} />}
        </Col>
      </Row>
    </div>
  )
}

OrderHistoryHeader.propTypes = {
  className: PropTypes.string,
  registry: PropTypes.shape(RegistryShape).isRequired,
  showAddress: PropTypes.bool,
}

OrderHistoryHeader.defaultProps = {
  className: undefined,
  showAddress: false,
}

export default OrderHistoryHeader
