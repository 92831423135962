import css from './BabySizer.scss'
import { Button, Image } from 'baby-design'
import Link from 'components/link'
import classNames from 'classnames'
import { helloBabyPostPath } from 'lib/urls'

interface BabySizerProps {
  numWeeksPregnant: number
  emailUrls: string[]
}

const PregnancyColors = [
  'BabySizer__pink',
  'BabySizer__orange',
  'BabySizer__yellow',
  'BabySizer__green',
  'BabySizer__blue',
]

const babySizes = [
  'poppy seed',
  'Pop Rocks crystal',
  'Tiny Chiclet Gum',
  'Sweethearts candy',
  'bike spoke bead',
  "Hershey's Kiss",
  'original Polly Pocket',
  'LEGO man',
  'roll of film',
  'Tamagotchi virtual pet',
  'Bagel Bite',
  'Ring Pop',
  'Hacky Sack',
  'cassette tape',
  'Nintendo NES Classic Controller',
  'Magic 8-Ball',
  'Troll Doll',
  'My Little Pony',
  'Classic Game Boy',
  "Cher's cell phone in Clueless",
  'Mr. Potato Head',
  'TI-89 calculator',
  'VHS tape case',
  'Barbie',
  'Kit-Kat Klock',
  'fanny pack',
  'bike helmet',
  '1/2 gallon of SunnyD',
  'Care Bear',
  'lunch box',
  'Tickle Me Elmo',
  'George Foreman Grill',
  'Bugles',
  'Pound Puppy',
  'bowling pin',
  'Cabbage Patch Kid doll',
  'mylar balloon',
  'Easy-Bake Oven',
  'punch balloon',
]

const BabySizer = ({ numWeeksPregnant, emailUrls }: BabySizerProps) => {
  const weeks = Array.from(
    { length: 42 - numWeeksPregnant },
    (_, i) => i + numWeeksPregnant + 1
  ).slice(0, 6)

  const colorClass = (week: number) => {
    return PregnancyColors[(week - 4) % PregnancyColors.length]
  }
  const imageUrl = `https://images.babylist.com/image/upload/v1718125897/DueDateCalculator/Week_Week_${numWeeksPregnant}_2x.png`
  const babySize = babySizes[numWeeksPregnant - 4]

  return (
    <div className={css.BabySizer}>
      <div className={css.BabySizer__wrapper}>
        <Image src={imageUrl} className={css.BabySizer__babyImage} />
        <div className={css.BabySizer__babySize}>
          <h1>You're {numWeeksPregnant} weeks pregnant!</h1>
          <p>Your baby is the size of a {babySize}.</p>
          <Link url={emailUrls[numWeeksPregnant]}>
            <Button
              size="lg"
              variant="primary"
              className={css.BabySizer__button}
            >
              Learn about Week {numWeeksPregnant}
            </Button>
          </Link>
        </div>
      </div>
      <div className={css.BabySizer__toCome}>
        <h3>A glimpse at what's to come</h3>
        <div className={css.BabySizer__modules}>
          {weeks.map((week) => (
            <div
              className={classNames(
                css.BabySizer__module,
                css[colorClass(week)]
              )}
              key={week}
            >
              <Link url={emailUrls[week]}>
                <div className={css.BabySizer__moduleNumber}>{week}</div>
                <div className={css.BabySizer__moduleText}>weeks pregnant</div>
              </Link>
            </div>
          ))}
        </div>
        <div className={css.BabySizer__toComeButton}>
          <div className={css.BabySizer__desktopOnly}>
            <Link url={helloBabyPostPath('pregnancy-week-by-week')}>
              <Button
                size="lg"
                variant="secondary"
                className={css.BabySizer__button}
              >
                View all pregnancy weeks
              </Button>
            </Link>
          </div>
          <div className={css.BabySizer__mobileOnly}>
            <Link url={helloBabyPostPath('pregnancy-week-by-week')}>
              <Button
                size="md"
                variant="secondary"
                className={css.BabySizer__button}
              >
                View all pregnancy weeks
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BabySizer
