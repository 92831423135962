import { SET_CHECKLIST, EMAIL_SUBMIT_SUCCESS } from '../actions'

const checklistReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CHECKLIST:
      const { checklist } = action
      return { ...state, ...checklist }
    case EMAIL_SUBMIT_SUCCESS:
      return { ...state, showEmailSuccess: true }
    default:
      return state
  }
}

export default checklistReducer
