import submitEmail from '../api'

export const SET_CHECKLIST = 'SET_CHECKLIST'
export const SET_QUESTIONS = 'SET_QUESTIONS'

export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const updateAnswer = (questionId, answer) => {
  return {
    type: UPDATE_ANSWER,
    answer,
    questionId,
  }
}

export const EMAIL_SUBMIT_SUCCESS = 'EMAIL_SUBMIT_SUCCESS'
