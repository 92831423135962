import { ReactElement } from 'react'
import Fade from 'react-reveal/Fade'
import Image from 'components/image'
import classNames from 'classnames'
import { FadeType, NavInfoTileProps } from './types'
import css from './NavInfoTile.scss'

const withFade = (element: ReactElement, fade: FadeType) => (
  <Fade bottom={fade === 'bottom'}>{element}</Fade>
)

const NavInfoTile = ({
  children,
  className,
  image,
  title,
  fade,
  lazyLoadImage = true,
}: NavInfoTileProps) => {
  const renderImageContainer = () => {
    let imgElement = (
      <div className={css.ImageContainer}>
        <Image alt={title} lazyLoad={lazyLoadImage} src={image} />
      </div>
    )

    if (fade) {
      imgElement = withFade(imgElement, fade)
    }

    return imgElement
  }

  return (
    <div className={classNames(css.NavInfoTile, className)}>
      {image && renderImageContainer()}
      {children}
    </div>
  )
}

export default NavInfoTile
