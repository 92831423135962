import PropTypes from 'prop-types'
import { InfoButton } from 'components/buttons'
import Spinner from 'shared/spinner'

const CancelOrderForm = ({ onCancelYes, onCancelNo, submitting }) => {
  return (
    <div>
      <p>Are you sure you want to cancel this order?</p>
      <p>
        <strong>This action cannot be undone </strong>
        and any refund will be processed back to the original form of payment.
      </p>
      <InfoButton block className="mtl mbm" onClick={onCancelYes}>
        {submitting ? <Spinner size="small" /> : 'Cancel Now'}
      </InfoButton>
      <InfoButton
        block
        className="mbm text-brand-info"
        empty
        onClick={onCancelNo}
      >
        Nevermind
      </InfoButton>
    </div>
  )
}

CancelOrderForm.propTypes = {
  onCancelYes: PropTypes.func.isRequired,
  onCancelNo: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default CancelOrderForm
