import React from 'react'

interface HorseStickerProps {
  className?: string
}

const HorseSticker: React.FC<HorseStickerProps> = ({
  className,
}: HorseStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1019_6473)">
      <path
        d="M27.7883 70.272C27.7883 70.272 33.9129 61.151 37.4633 53.3311C41.0137 45.5112 41.8981 37.4958 51.3998 35.4515C63.1825 32.9186 69.195 40.7385 74.555 46.1463C79.915 51.5542 91.6009 79.8046 91.6009 79.8046C91.6009 79.8046 77.5319 99.3402 61.7858 96.4241L54.7513 69.7757C54.7513 69.7757 53.0768 73.9621 46.0423 76.8062C39.0078 79.6503 32.9495 90.9365 30.495 88.4602C28.0406 85.9839 17.9502 80.2057 27.7908 70.2772L27.7883 70.272Z"
        fill="#FFD43F"
      />
      <path
        d="M45.7417 37.9844C45.7417 37.9844 39.4616 30.9437 42.8948 27.4799C42.8948 27.4799 51.3999 29.6656 51.3999 35.4489L45.7417 37.9818V37.9844Z"
        fill="#FFD43F"
      />
      <path
        d="M57.5803 35.4515C57.5803 35.4515 59.2243 26.0398 63.8069 26.0398C63.8069 26.0398 66.8756 34.8883 63.8069 37.9844C60.7382 41.0804 57.5803 35.4515 57.5803 35.4515Z"
        fill="#FFD43F"
      />
      <path
        d="M28.1477 77.7012C28.1477 77.7012 26.3126 80.1544 30.2708 81.6073C30.2708 81.6073 32.6946 77.7012 28.1477 77.7012Z"
        fill="#6F3264"
      />
      <path
        d="M44.3654 60.4309C44.3654 60.4309 43.3943 52.233 53.0438 51.5336C53.0438 51.5336 54.1551 60.4309 44.3654 60.4309Z"
        fill="#F9F6F3"
      />
      <path
        d="M48.7108 58.1911C49.92 58.1911 50.9002 57.2022 50.9002 55.9822C50.9002 54.7623 49.92 53.7733 48.7108 53.7733C47.5017 53.7733 46.5215 54.7623 46.5215 55.9822C46.5215 57.2022 47.5017 58.1911 48.7108 58.1911Z"
        fill="#6F3264"
      />
      <path
        d="M62.3492 29.1152C62.3492 29.1152 59.8489 33.9754 59.8489 36.0505L61.3756 34.2994L61.1003 37.2411L62.3492 34.5797V37.9869C62.3492 37.9869 66.1341 32.9339 62.3492 29.1152Z"
        fill="#F3D7E8"
      />
      <path
        d="M58.1362 33.155C58.1362 33.155 51.2419 31.7073 41.312 34.1579C31.3822 36.6085 38.2561 37.9843 38.2561 37.9843C38.2561 37.9843 30.3397 41.0932 32.631 43.405C34.9224 45.7168 39.3673 43.405 39.3673 43.405C39.3673 43.405 29.6286 48.3963 33.9513 49.9211C38.2739 51.446 40.3053 44.773 42.7699 42.2838C45.2345 39.7946 53.969 34.6516 57.5831 35.4488L58.1362 33.1525V33.155Z"
        fill="#A4DAF6"
      />
      <path
        d="M65.1707 34.0886C65.1707 34.0886 77.1599 33.8726 81.2354 37.9844C85.3108 42.0962 79.4309 42.7751 79.4309 42.7751C79.4309 42.7751 91.6011 44.7629 91.6011 50.145C91.6011 55.5271 83.1112 50.7622 83.1112 50.7622C83.1112 50.7622 93.8745 53.6397 95.0546 58.1912C96.2347 62.7427 86.6514 58.1912 86.6514 58.1912C86.6514 58.1912 101.233 65.1239 98.7324 67.6466C96.2321 70.1692 90.8161 67.086 90.8161 67.086C90.8161 67.086 102.482 71.3598 100.121 74.7233C97.7614 78.0868 92.9009 74.7233 92.9009 74.7233L88.303 72.1235C88.303 72.1235 77.4862 48.6793 63.8071 37.987L65.1707 34.0912V34.0886Z"
        fill="#A4DAF6"
      />
      <path
        d="M44.3655 60.4308L43.3945 59.6594C43.3945 59.6594 43.2135 55.3855 46.6365 52.7935C50.0594 50.2014 53.0848 50.996 53.0848 50.996L53.0465 51.5334C53.0465 51.5334 49.435 51.2069 47.1207 53.5418C44.8065 55.8767 44.3655 60.4308 44.3655 60.4308Z"
        fill="#6F3264"
      />
      <path
        d="M42.3979 52.125C42.5941 51.7419 42.8388 51.351 43.0453 50.955C43.0962 50.8444 43.2135 50.7056 43.3103 50.6001C43.5983 50.2787 43.9067 49.983 44.2253 49.6744C44.2738 49.6307 44.3298 49.587 44.3706 49.5432C44.7861 49.0907 45.3162 48.7769 45.8259 48.4606C46.1853 48.2318 46.5804 48.0698 46.9499 47.8538C47.1589 47.7432 47.4265 47.6455 47.6738 47.5683C48.4205 47.324 49.203 47.2058 49.9778 47.2212C50.2199 47.2212 50.4264 47.252 50.457 47.2906C50.4748 47.3163 50.6685 47.3369 50.6787 47.3575C50.7042 47.414 50.3831 47.4886 49.8071 47.6326C49.5496 47.6969 49.2871 47.7509 49.0322 47.8255C48.5429 47.9643 48.0841 48.1572 47.5948 48.2986C47.4214 48.3552 47.2201 48.4478 47.0442 48.5481C46.537 48.8155 46.0426 49.0829 45.5736 49.4018C45.4258 49.5072 45.2652 49.605 45.1378 49.7284C44.7937 50.0421 44.4649 50.3687 44.154 50.7184C44.001 50.8804 43.8736 51.0553 43.7207 51.2224C43.5729 51.4024 43.4378 51.6159 43.3103 51.8267C42.9663 52.3745 42.6986 52.9633 42.4387 53.5599C42.2118 54.2799 41.9748 55.0437 41.9034 55.8022C41.8856 56.0363 41.8397 56.314 41.7938 56.5866C41.6868 57.232 41.6919 57.3091 41.6231 57.0597C41.6027 56.9851 41.5823 56.9106 41.5594 56.8334C41.5211 56.7074 41.5084 56.5531 41.5007 56.2703C41.5084 55.8768 41.5007 55.4834 41.5288 55.0977C41.5466 54.7891 41.5721 54.4882 41.6715 54.1616C41.7097 54.0434 41.725 53.9096 41.7582 53.7862C41.8856 53.223 42.0869 52.665 42.3928 52.125H42.3979Z"
        fill="#6F3264"
      />
      <path
        d="M57.0554 66.0368C57.1803 65.7668 57.3205 65.4788 57.374 65.1728C57.3919 65.0931 57.4428 64.9774 57.4862 64.8899C57.6187 64.6276 57.7461 64.3576 57.8353 64.067C57.8481 64.0233 57.871 63.9796 57.8787 63.9385C57.95 63.5219 58.1233 63.1362 58.2074 62.7247C58.2763 62.4367 58.3425 62.1487 58.3629 61.8504C58.3629 61.6833 58.4037 61.493 58.4343 61.313C58.4776 61.043 58.5107 60.773 58.5617 60.5004C58.5923 60.2278 58.6484 59.9552 58.6815 59.6801C58.7045 59.5078 58.735 59.3586 58.7784 59.3355C58.8039 59.3226 58.8293 59.1786 58.8497 59.1709C58.8982 59.1504 58.9899 59.3844 59.074 59.8344C59.0995 60.0375 59.1072 60.2432 59.1199 60.449C59.1301 60.8398 59.1581 61.2384 59.0562 61.6267C59.0205 61.7656 59.0205 61.9379 58.9976 62.0947C58.9517 62.5396 58.837 62.9664 58.7146 63.3907C58.6713 63.5245 58.6203 63.6633 58.5949 63.797C58.5617 63.9745 58.5133 64.1493 58.4598 64.3216C58.4062 64.4939 58.3553 64.6688 58.2865 64.8385C58.2227 65.0005 58.1667 65.1574 58.0902 65.3168C58.0061 65.4788 57.9424 65.6639 57.8685 65.8388C57.6671 66.2965 57.4505 66.7517 57.1727 67.1708C56.9076 67.6928 56.5253 68.1789 56.1761 68.6443C56.0691 68.7909 55.9212 68.9477 55.7505 69.0789C55.3529 69.3823 55.3172 69.4158 55.3911 69.2332L55.4548 69.066C55.4905 68.976 55.5415 68.8732 55.6638 68.7112C55.8193 68.472 55.9595 68.2303 56.0793 67.9783C56.171 67.7777 56.2628 67.59 56.3953 67.3894C56.4437 67.3174 56.4794 67.23 56.5176 67.1503C56.6043 66.9651 56.691 66.7825 56.7776 66.5974C56.8719 66.4148 56.9662 66.2297 57.0631 66.0394L57.0554 66.0368Z"
        fill="#6F3264"
      />
      <path
        d="M73.11 37.9252C73.3547 37.9355 73.6146 37.9844 73.8721 37.9484C73.9409 37.9381 74.0428 37.9612 74.1218 37.9844C74.3589 38.0512 74.5934 38.1155 74.8406 38.1618C74.8763 38.1695 74.9145 38.1849 74.9502 38.1849C75.3019 38.1978 75.6256 38.3392 75.9595 38.419C76.1965 38.473 76.4234 38.5707 76.6706 38.5964C76.8057 38.6144 76.956 38.6761 77.0988 38.725C77.5219 38.8818 77.9297 39.0773 78.3145 39.3164C78.4343 39.3961 78.5337 39.4656 78.5337 39.5118C78.5337 39.5401 78.628 39.607 78.6255 39.6276C78.6204 39.679 78.4139 39.6893 78.0622 39.6378C77.9042 39.6121 77.7512 39.5736 77.5983 39.5376C77.3027 39.4707 77.007 39.4295 76.7292 39.301C76.6298 39.2547 76.4998 39.229 76.3826 39.2084C76.0487 39.1415 75.7301 39.0413 75.4064 38.9461C75.3019 38.9178 75.1974 38.8818 75.0929 38.8715C74.8176 38.8381 74.5475 38.7944 74.2722 38.7455C74.1422 38.7224 74.0148 38.7044 73.8848 38.6684C73.7497 38.635 73.5994 38.6324 73.4566 38.6144C73.0769 38.5707 72.6971 38.5527 72.3148 38.4884C71.8688 38.5012 71.4023 38.419 70.9538 38.4318C70.8161 38.4318 70.6505 38.4087 70.4873 38.3727C70.1076 38.2749 70.0643 38.2672 70.207 38.2029C70.2503 38.1824 70.2936 38.1618 70.337 38.1412C70.4083 38.1078 70.4975 38.0795 70.6607 38.0615C70.8875 38.0435 71.1118 37.9947 71.331 37.9587C71.5068 37.9304 71.6776 37.8892 71.8739 37.9098C71.9427 37.9149 72.0217 37.9072 72.0931 37.8995C72.4244 37.8687 72.7583 37.8687 73.1074 37.9227L73.11 37.9252Z"
        fill="#6F3264"
      />
      <path
        d="M87.4899 62.8584C87.7168 62.9562 87.941 63.0925 88.1934 63.149C88.2622 63.1645 88.3488 63.221 88.4151 63.2699C88.6139 63.4165 88.8127 63.5579 89.0268 63.6916C89.0599 63.7122 89.0905 63.7379 89.1211 63.7508C89.4448 63.887 89.6997 64.1339 89.9851 64.3293C90.189 64.4631 90.3649 64.6353 90.5866 64.7485C90.7064 64.8153 90.8262 64.9234 90.9409 65.0211C91.2824 65.3168 91.5959 65.6434 91.8737 66.006C91.9578 66.1217 92.0267 66.2245 92.0114 66.2682C92.0012 66.294 92.0674 66.3917 92.0572 66.4097C92.0343 66.456 91.8381 66.3917 91.5271 66.2194C91.3895 66.1397 91.2569 66.0497 91.127 65.9622C90.8746 65.7951 90.6096 65.6511 90.3955 65.4325C90.319 65.3554 90.2069 65.2834 90.1024 65.2216C89.8118 65.0391 89.5493 64.8334 89.2791 64.6302C89.1925 64.5685 89.1033 64.4965 89.0115 64.4476C88.7668 64.3191 88.5273 64.1828 88.2877 64.0388C88.173 63.9719 88.0608 63.9102 87.9512 63.8305C87.8365 63.7508 87.6964 63.6968 87.5689 63.6273C87.2299 63.4499 86.8782 63.3007 86.5443 63.1053C86.1212 62.9587 85.7135 62.717 85.2904 62.5704C85.1604 62.5242 85.0151 62.4419 84.8749 62.3493C84.5538 62.123 84.5155 62.1024 84.671 62.0921C84.7194 62.087 84.7679 62.0844 84.8163 62.0793C84.8953 62.0741 84.9871 62.0793 85.1451 62.1204C85.3643 62.1847 85.5911 62.2181 85.8103 62.2619C85.9862 62.2979 86.1595 62.321 86.3353 62.4084C86.3991 62.4393 86.4755 62.4573 86.5443 62.4779C86.8655 62.5679 87.179 62.6862 87.4874 62.8584H87.4899Z"
        fill="#6F3264"
      />
      <path
        d="M75.9595 44.2794C76.2653 44.408 76.589 44.5648 76.9254 44.6343C77.0146 44.6548 77.1421 44.714 77.2389 44.7654C77.532 44.9197 77.8277 45.0689 78.1463 45.1897C78.1947 45.2077 78.2406 45.236 78.2865 45.2463C78.7427 45.3646 79.1581 45.5909 79.6016 45.74C79.9126 45.8506 80.2184 45.9766 80.5446 46.0486C80.7256 46.0846 80.9295 46.1617 81.1207 46.2312C81.6967 46.4317 82.2676 46.6477 82.8385 46.8818C83.0169 46.9538 83.1673 47.0258 83.18 47.0746C83.1877 47.1029 83.333 47.1698 83.333 47.1929C83.3406 47.2443 83.0679 47.2675 82.5734 47.2212C82.3542 47.1929 82.1325 47.1492 81.9133 47.108C81.4953 47.0232 81.0748 46.9615 80.6772 46.7866C80.537 46.7249 80.3509 46.6863 80.1878 46.64C79.7214 46.514 79.2754 46.3392 78.8344 46.1592C78.6943 46.1 78.549 46.0332 78.4063 45.992C78.0316 45.8789 77.6646 45.74 77.3001 45.5883C77.1268 45.5137 76.9586 45.4469 76.7878 45.3594C76.6119 45.2669 76.4131 45.1974 76.222 45.1177C75.7224 44.9017 75.2229 44.6883 74.7488 44.4183C74.1626 44.1714 73.5968 43.814 73.0361 43.5182C72.8628 43.4257 72.6665 43.2945 72.4906 43.1402C72.0803 42.7802 72.0344 42.7468 72.2434 42.7957C72.3046 42.8085 72.3683 42.8239 72.432 42.8368C72.5365 42.8599 72.6538 42.8985 72.8577 42.9988C73.1431 43.1325 73.4311 43.2457 73.7191 43.3485C73.9511 43.4308 74.1703 43.5054 74.4073 43.6314C74.4914 43.6777 74.5908 43.7111 74.6851 43.7471C75.1056 43.9065 75.5236 44.0788 75.9544 44.2897L75.9595 44.2794Z"
        fill="#6F3264"
      />
      <path
        d="M82.7008 52.7884C82.9531 52.8501 83.2131 52.9453 83.4858 52.9556C83.5597 52.9556 83.6616 52.9993 83.7381 53.0353C83.9726 53.1407 84.2096 53.2462 84.462 53.3284C84.5002 53.3413 84.5384 53.3619 84.5715 53.3696C84.936 53.4364 85.2572 53.6216 85.5987 53.745C85.8408 53.8299 86.0702 53.9507 86.3251 54.0073C86.4653 54.0407 86.6182 54.1153 86.7635 54.1796C87.1942 54.375 87.6147 54.6065 88.0149 54.8713C88.1398 54.9536 88.2417 55.0359 88.2417 55.0822C88.2417 55.1105 88.3411 55.1851 88.336 55.2056C88.3309 55.2571 88.1117 55.2596 87.7422 55.1799C87.5765 55.1439 87.4134 55.0925 87.2528 55.0436C86.9419 54.9536 86.6284 54.8816 86.3404 54.7222C86.2384 54.6656 86.1008 54.627 85.9784 54.591C85.6293 54.483 85.2979 54.3416 84.9641 54.2027C84.857 54.159 84.7474 54.1076 84.6404 54.0793C84.3524 54.0047 84.072 53.9173 83.7891 53.8196C83.654 53.7733 83.524 53.7347 83.3915 53.6756C83.2539 53.6164 83.0958 53.5856 82.948 53.5419C82.5555 53.4236 82.1553 53.3259 81.7705 53.1793C81.299 53.0893 80.8275 52.899 80.361 52.7961C80.2158 52.7627 80.0501 52.6959 79.8921 52.6136C79.5225 52.4156 79.4817 52.3976 79.6474 52.3744C79.6984 52.3667 79.7493 52.359 79.7978 52.3487C79.8793 52.3358 79.9787 52.3333 80.1495 52.359C80.3865 52.4001 80.6287 52.413 80.8657 52.4284C81.0543 52.4438 81.2378 52.4464 81.4366 52.5081C81.508 52.5313 81.5895 52.539 81.666 52.5493C82.0126 52.5956 82.3592 52.665 82.711 52.7884H82.7008Z"
        fill="#6F3264"
      />
      <path
        d="M84.2198 54.9922C84.541 55.054 84.88 55.1465 85.2215 55.1568C85.3132 55.1568 85.4458 55.198 85.5477 55.234C85.8561 55.342 86.1645 55.4448 86.4908 55.5271C86.5392 55.54 86.5902 55.5605 86.6335 55.5683C87.0948 55.6325 87.523 55.8203 87.9665 55.9437C88.28 56.0286 88.5833 56.152 88.9095 56.206C89.0879 56.2394 89.2918 56.314 89.4804 56.3783C90.0488 56.5763 90.6044 56.8077 91.1473 57.0777C91.3155 57.1626 91.4582 57.2449 91.4659 57.2937C91.471 57.322 91.6061 57.3966 91.6061 57.4197C91.6061 57.4712 91.341 57.4763 90.8669 57.3992C90.6554 57.3632 90.4464 57.3117 90.2349 57.2629C89.8347 57.1729 89.432 57.1009 89.0471 56.9389C88.9095 56.8823 88.7337 56.8437 88.5756 56.8051C88.122 56.6971 87.6887 56.5531 87.2477 56.4143C87.1076 56.3706 86.9623 56.3191 86.8221 56.2908C86.4525 56.2188 86.0855 56.1288 85.7185 56.0311C85.5426 55.9848 85.3744 55.9437 85.1986 55.8871C85.0176 55.828 84.8162 55.7971 84.6225 55.7534C84.1128 55.6351 83.6005 55.54 83.0959 55.3934C82.4918 55.306 81.875 55.1131 81.2735 55.0128C81.0875 54.9794 80.8708 54.9151 80.6618 54.8328C80.1725 54.6322 80.1189 54.6168 80.3254 54.5911C80.3866 54.5834 80.4503 54.5731 80.5114 54.5654C80.6134 54.5525 80.7357 54.5499 80.9549 54.5757C81.2582 54.6168 81.5641 54.6271 81.8623 54.6425C82.1018 54.6554 82.3312 54.6605 82.5861 54.7222C82.6779 54.7454 82.7824 54.7505 82.8767 54.7608C83.3176 54.8045 83.7585 54.8765 84.2147 54.9948L84.2198 54.9922Z"
        fill="#6F3264"
      />
      <path
        d="M87.913 61.4519C88.1602 61.5393 88.41 61.6679 88.6827 61.7142C88.7566 61.7245 88.8534 61.781 88.9248 61.8273C89.1465 61.9662 89.3683 62.1025 89.6079 62.2285C89.6435 62.2465 89.6792 62.2748 89.7124 62.2851C90.0641 62.4111 90.3546 62.6502 90.6707 62.8379C90.8975 62.9665 91.0989 63.1362 91.341 63.2417C91.4735 63.3059 91.6086 63.4139 91.736 63.5065C92.1209 63.7971 92.4777 64.1211 92.8014 64.4837C92.9008 64.5968 92.9824 64.7023 92.9696 64.7485C92.962 64.7743 93.0385 64.872 93.0308 64.8926C93.013 64.9388 92.7989 64.8848 92.4624 64.7125C92.3121 64.6354 92.1668 64.548 92.0215 64.4605C91.7411 64.3011 91.4531 64.1597 91.2085 63.9437C91.1218 63.8665 90.9969 63.7997 90.8822 63.7405C90.5636 63.5657 90.2654 63.3651 89.9621 63.1697C89.8653 63.1079 89.7659 63.0411 89.6639 62.9948C89.3938 62.8739 89.1287 62.7454 88.8662 62.6091C88.7387 62.5448 88.6164 62.4882 88.4941 62.4111C88.3666 62.3365 88.2137 62.2851 88.071 62.2233C87.6963 62.0613 87.3114 61.9251 86.9419 61.745C86.4806 61.619 86.0294 61.3928 85.5656 61.2719C85.4228 61.2307 85.2623 61.1587 85.1068 61.0713C84.7449 60.8605 84.7015 60.8425 84.8672 60.8245C84.9182 60.8167 84.9692 60.8116 85.0176 60.8039C85.1017 60.7936 85.1985 60.7936 85.3693 60.827C85.6063 60.8785 85.8485 60.9016 86.0855 60.9325C86.2741 60.9607 86.4602 60.9736 86.6539 61.0507C86.7227 61.079 86.8043 61.0919 86.8807 61.1099C87.2273 61.1845 87.5689 61.2873 87.9104 61.4493L87.913 61.4519Z"
        fill="#6F3264"
      />
      <path
        d="M91.0962 70.0175C91.2797 70.1075 91.4632 70.2283 91.6799 70.2695C91.7385 70.2797 91.8099 70.3286 91.8634 70.3698C92.0239 70.4958 92.1896 70.6166 92.3731 70.7195C92.4012 70.7349 92.4266 70.758 92.4521 70.7683C92.7299 70.8635 92.9466 71.0641 93.1989 71.2029C93.3748 71.3006 93.5379 71.4266 93.7341 71.4961C93.8412 71.5346 93.9508 71.6144 94.0553 71.6812C94.3688 71.8869 94.6695 72.1184 94.9499 72.3781C95.0366 72.4629 95.1079 72.5375 95.1003 72.5812C95.0952 72.6069 95.164 72.6789 95.1563 72.6995C95.141 72.7484 94.9626 72.7432 94.667 72.6661C94.537 72.6275 94.4096 72.5735 94.2847 72.5221C94.0451 72.4244 93.7953 72.3447 93.594 72.1775C93.5201 72.1184 93.4156 72.0747 93.3213 72.0309C93.0536 71.9075 92.8141 71.7481 92.5719 71.5886C92.4955 71.5372 92.4165 71.4806 92.3349 71.4446C92.1157 71.3495 91.9067 71.2389 91.7028 71.1155C91.606 71.0589 91.5091 71.0049 91.4148 70.9355C91.318 70.8635 91.1982 70.8146 91.0911 70.7555C90.8031 70.596 90.51 70.4495 90.2398 70.2566C89.8881 70.1023 89.567 69.8477 89.228 69.6703C89.1235 69.6137 89.0113 69.5237 88.9119 69.4157C88.6826 69.1611 88.6571 69.138 88.7947 69.1457C88.838 69.1457 88.8788 69.1483 88.9221 69.1509C88.991 69.1534 89.0674 69.1689 89.1949 69.2254C89.3707 69.3026 89.5568 69.3592 89.7377 69.4106C89.883 69.4543 90.0257 69.4877 90.1634 69.5752C90.2143 69.606 90.2755 69.6266 90.3341 69.6472C90.5967 69.7423 90.8515 69.8555 91.0988 70.0149L91.0962 70.0175Z"
        fill="#6F3264"
      />
      <path
        d="M77.7615 46.4908C77.945 46.5808 78.1285 46.7017 78.3451 46.7429C78.4038 46.7531 78.4751 46.802 78.5287 46.8431C78.6892 46.9691 78.8549 47.09 79.0384 47.1929C79.0664 47.2083 79.0919 47.2314 79.1174 47.2417C79.3952 47.3369 79.6119 47.5374 79.8642 47.6763C80.0401 47.774 80.2032 47.9 80.3994 47.9695C80.5065 48.008 80.6161 48.0877 80.7206 48.1546C81.0341 48.3603 81.3348 48.5918 81.6152 48.8515C81.7018 48.9363 81.7732 49.0109 81.7655 49.0546C81.7605 49.0803 81.8293 49.1523 81.8216 49.1729C81.8063 49.2218 81.6279 49.2166 81.3323 49.1395C81.2023 49.1009 81.0748 49.0469 80.95 48.9955C80.7104 48.8978 80.4606 48.818 80.2593 48.6509C80.1853 48.5918 80.0808 48.548 79.9865 48.5043C79.7189 48.3809 79.4793 48.2215 79.2372 48.062C79.1607 48.0106 79.0817 47.954 79.0002 47.918C78.781 47.8229 78.572 47.7123 78.3681 47.5889C78.2712 47.5323 78.1744 47.4783 78.0801 47.4089C77.9832 47.3369 77.8634 47.288 77.7564 47.2289C77.4684 47.0694 77.1753 46.9229 76.9051 46.73C76.5534 46.5757 76.2323 46.3211 75.8933 46.1437C75.7888 46.0871 75.6766 45.9971 75.5772 45.8891C75.3478 45.6345 75.3224 45.6114 75.46 45.6191C75.5033 45.6191 75.5441 45.6217 75.5874 45.6243C75.6562 45.6268 75.7327 45.6423 75.8601 45.6988C76.036 45.776 76.2221 45.8325 76.403 45.884C76.5483 45.9277 76.691 45.9611 76.8287 46.0485C76.8796 46.0794 76.9408 46.1 76.9994 46.1206C77.2619 46.2157 77.5168 46.3288 77.764 46.4883L77.7615 46.4908Z"
        fill="#6F3264"
      />
      <path
        d="M47.299 35.7215C47.1155 35.7163 46.9192 35.6881 46.7281 35.7446C46.6771 35.7601 46.6007 35.7472 46.542 35.7343C46.3636 35.6932 46.1827 35.6598 45.9941 35.6521C45.966 35.6521 45.9354 35.6418 45.9099 35.6469C45.65 35.6932 45.3926 35.6186 45.1326 35.6186C44.9491 35.6186 44.7681 35.5903 44.5846 35.6289C44.4827 35.6521 44.3629 35.6392 44.2507 35.6341C43.9143 35.6186 43.5753 35.5801 43.2312 35.5158C43.1242 35.4926 43.0299 35.4695 43.0146 35.4258C43.0044 35.4 42.9177 35.3769 42.9101 35.3563C42.8948 35.3075 43.0324 35.2149 43.3026 35.1172C43.4224 35.0812 43.5473 35.058 43.6696 35.0349C43.9041 34.9938 44.1386 34.9269 44.3807 34.9706C44.4674 34.986 44.5693 34.968 44.6662 34.9578C44.9363 34.9295 45.2014 34.9526 45.4665 34.9758C45.5506 34.9835 45.6398 34.9989 45.7213 34.9912C45.9405 34.9706 46.1597 34.9758 46.3789 34.9912C46.4834 34.9989 46.5854 35.004 46.6899 35.0246C46.7995 35.0478 46.9167 35.04 47.0314 35.0452C47.3347 35.0658 47.638 35.076 47.9362 35.1455C48.293 35.1455 48.6524 35.256 49.0041 35.2998C49.1137 35.3152 49.2386 35.3563 49.3558 35.4232C49.6311 35.5801 49.6591 35.5929 49.5368 35.6341C49.4986 35.6469 49.4629 35.6623 49.4246 35.6752C49.3635 35.6958 49.2921 35.7112 49.1672 35.7035C48.9914 35.6958 48.8155 35.7112 48.6447 35.7318C48.5071 35.7472 48.3746 35.7678 48.2293 35.7446C48.1758 35.7343 48.1171 35.7446 48.0636 35.7446C47.8113 35.7626 47.5615 35.7626 47.299 35.7215Z"
        fill="#6F3264"
      />
      <path
        d="M42.1786 36.9198C42.3468 36.8941 42.5303 36.8941 42.6985 36.8092C42.7444 36.7861 42.8158 36.7887 42.8718 36.7938C43.0426 36.8092 43.2134 36.8221 43.3867 36.8067C43.4122 36.8067 43.4402 36.8092 43.4631 36.8041C43.6976 36.7321 43.9423 36.7809 44.1819 36.7578C44.3501 36.7424 44.5209 36.7578 44.6865 36.7089C44.7783 36.6806 44.8904 36.6884 44.9949 36.6858C45.3084 36.6858 45.6219 36.7141 45.938 36.7784C46.0374 36.8015 46.1215 36.8247 46.1368 36.8658C46.1444 36.8915 46.226 36.9147 46.2311 36.9352C46.2438 36.9841 46.1189 37.0767 45.8793 37.1769C45.7723 37.2155 45.6601 37.2387 45.548 37.2669C45.3365 37.3158 45.13 37.3878 44.9108 37.3569C44.8318 37.3467 44.7401 37.3698 44.6559 37.3852C44.4138 37.429 44.1742 37.4264 43.9321 37.429C43.8556 37.429 43.7741 37.4238 43.7002 37.4392C43.5065 37.4804 43.3102 37.501 43.1114 37.5112C43.0171 37.5164 42.9254 37.5267 42.8285 37.519C42.7291 37.5112 42.6246 37.537 42.5201 37.5472C42.2474 37.573 41.9772 37.6115 41.6994 37.5961C41.3834 37.6604 41.0444 37.627 40.7284 37.6604C40.6289 37.6707 40.5117 37.6604 40.3919 37.6218C40.1141 37.5344 40.0861 37.5292 40.1829 37.4624C40.2059 37.4444 40.2364 37.4212 40.2721 37.3955C40.3206 37.3621 40.3817 37.3287 40.4939 37.3081C40.6519 37.2772 40.8048 37.2207 40.9552 37.1667C41.075 37.1229 41.1897 37.0741 41.3273 37.0715C41.3757 37.0715 41.4293 37.0535 41.4777 37.0381C41.702 36.9738 41.9314 36.9275 42.176 36.9249L42.1786 36.9198Z"
        fill="#6F3264"
      />
      <path
        d="M37.4993 42.068C37.6191 42.014 37.7542 41.978 37.8561 41.8674C37.8841 41.8366 37.9377 41.8263 37.981 41.8211C38.1135 41.8057 38.2435 41.7851 38.3684 41.7389C38.3862 41.7311 38.4092 41.7311 38.4245 41.7209C38.5799 41.6077 38.7762 41.6103 38.947 41.546C39.0693 41.4997 39.1993 41.4843 39.3114 41.4071C39.3726 41.3634 39.4593 41.348 39.5357 41.33C39.7702 41.2734 40.0123 41.2426 40.2647 41.2426C40.3437 41.2451 40.415 41.2528 40.438 41.2888C40.4507 41.312 40.5195 41.3171 40.5272 41.3377C40.5501 41.384 40.4838 41.4946 40.3309 41.6334C40.2621 41.6926 40.1831 41.7337 40.1092 41.78C39.9639 41.8649 39.8288 41.9729 39.6581 41.9857C39.5969 41.9909 39.5332 42.0294 39.4745 42.0603C39.3063 42.1477 39.1254 42.1889 38.947 42.2351C38.8883 42.2506 38.8272 42.2583 38.7762 42.2866C38.6411 42.3612 38.5009 42.4177 38.3557 42.4666C38.2868 42.4897 38.2206 42.5154 38.1467 42.5257C38.0702 42.536 37.9988 42.5823 37.9249 42.608C37.7287 42.6826 37.5375 42.77 37.326 42.8086C37.1093 42.9295 36.8443 42.9577 36.6174 43.0503C36.5461 43.0786 36.4543 43.0889 36.3549 43.076C36.1255 43.0452 36.1 43.0452 36.1561 42.9603C36.1688 42.9372 36.1841 42.9115 36.2045 42.8806C36.2326 42.8394 36.2682 42.7983 36.3473 42.7546C36.4568 42.6954 36.5562 42.6132 36.6531 42.5334C36.7321 42.4692 36.8035 42.3997 36.9029 42.3714C36.9386 42.3612 36.9742 42.3354 37.0048 42.3123C37.1552 42.2094 37.3132 42.1246 37.4942 42.0757L37.4993 42.068Z"
        fill="#6F3264"
      />
      <path
        d="M37.8328 45.6937C37.9119 45.6269 38.0113 45.5857 38.0673 45.47C38.0826 45.4392 38.1234 45.4237 38.1591 45.416C38.261 45.3929 38.363 45.3672 38.4573 45.3209C38.47 45.3132 38.4904 45.3132 38.5006 45.3029C38.6077 45.1923 38.7682 45.1949 38.8982 45.1383C38.99 45.0972 39.097 45.092 39.1786 45.0226C39.2245 44.9866 39.2933 44.9789 39.3544 44.966C39.4462 44.948 39.543 44.9429 39.6373 44.9429C39.7342 44.948 39.8311 44.9609 39.9279 44.9789C39.9891 44.9994 40.0426 45.02 40.0579 45.0586C40.0655 45.0817 40.1165 45.0972 40.1216 45.1177C40.1293 45.146 40.1191 45.1769 40.091 45.218C40.063 45.2566 40.0222 45.3235 39.9636 45.3672C39.9101 45.4057 39.854 45.4392 39.8005 45.4752C39.696 45.5369 39.6042 45.6217 39.4793 45.614C39.436 45.614 39.3901 45.6423 39.3519 45.6655C39.2372 45.7323 39.1098 45.7606 38.9849 45.7966C38.9441 45.8095 38.9008 45.8146 38.8676 45.8403C38.781 45.9072 38.6892 45.9612 38.5924 46.0075C38.5465 46.0306 38.5032 46.0563 38.4522 46.0666C38.3987 46.0795 38.3579 46.1258 38.3095 46.154C38.1846 46.2338 38.0724 46.3289 37.9297 46.3829C37.8125 46.5166 37.6315 46.5758 37.5041 46.6966C37.4607 46.7301 37.4047 46.7609 37.3256 46.7661C37.1396 46.7815 37.1294 46.7969 37.1294 46.7095C37.1294 46.6889 37.1294 46.6606 37.1294 46.6323C37.1294 46.5912 37.1396 46.5449 37.1804 46.4909C37.239 46.4189 37.2772 46.3212 37.3205 46.2338C37.3562 46.1618 37.3868 46.0872 37.4556 46.0435C37.4811 46.028 37.499 45.9998 37.5168 45.9715C37.5984 45.8532 37.7003 45.7529 37.8303 45.686L37.8328 45.6937Z"
        fill="#6F3264"
      />
      <path
        d="M30.1893 73.1572C30.5741 73.1469 30.9743 73.2318 31.3643 73.2909C31.4713 73.3038 31.6191 73.3758 31.7313 73.4323C32.0652 73.6175 32.3685 73.8386 32.6667 74.0829C32.7125 74.1189 32.7508 74.1678 32.7941 74.1961C33.2325 74.5098 33.5154 74.9804 33.7728 75.4381C33.9512 75.7647 34.0659 76.117 34.2061 76.4616C34.2826 76.6544 34.3284 76.8961 34.3565 77.1173C34.4482 77.7885 34.4049 78.4673 34.2392 79.1051C34.1883 79.3031 34.1271 79.4702 34.0787 79.4882C34.0506 79.4985 33.9945 79.6528 33.9716 79.6579C33.9155 79.6682 33.8951 79.3853 33.8697 78.8891C33.8595 78.6679 33.8569 78.4416 33.8365 78.223C33.8008 77.8013 33.7065 77.4002 33.6581 76.9836C33.6428 76.8344 33.5791 76.6621 33.5179 76.5078C33.3497 76.0707 33.1611 75.6567 32.9037 75.2787C32.8221 75.1578 32.7304 75.0344 32.6284 74.9367C32.3532 74.6821 32.0575 74.4532 31.7389 74.2629C31.5885 74.1704 31.4331 74.1009 31.2725 74.0186C31.1043 73.9364 30.908 73.8952 30.722 73.8618C30.2199 73.7641 29.7254 73.8181 29.2437 73.9646C28.7161 74.2038 28.1707 74.5587 27.8648 75.0678C27.7629 75.2195 27.6635 75.4073 27.5513 75.595C27.3296 76.0578 27.3475 76.1453 27.3041 75.9267C27.2965 75.8753 27.2786 75.8058 27.2761 75.7287C27.271 75.6181 27.2761 75.4844 27.35 75.253C27.4647 74.9367 27.6176 74.6307 27.8113 74.3555C27.9693 74.1395 28.1401 73.9286 28.3975 73.7744C28.4867 73.7152 28.5836 73.6484 28.6779 73.5841C29.1035 73.3141 29.626 73.1315 30.1842 73.1572H30.1893Z"
        fill="#6F3264"
      />
      <path
        d="M33.8008 86.4827C34.0633 86.2384 34.359 85.989 34.5527 85.6701C34.6087 85.5878 34.7107 85.4798 34.7948 85.395C35.0497 85.1481 35.2841 84.873 35.4982 84.5669C35.5288 84.5207 35.5696 84.4769 35.5951 84.4307C35.8398 83.9729 36.1635 83.5692 36.4209 83.1141C36.6018 82.7952 36.8032 82.484 36.951 82.1395C37.0326 81.9492 37.16 81.7383 37.2747 81.5403C37.6213 80.9489 38.0011 80.3651 38.4344 79.82C38.5669 79.6503 38.6994 79.514 38.7479 79.514C38.7785 79.514 38.8957 79.3828 38.9186 79.388C38.9722 79.3983 38.8804 79.6863 38.6663 80.1877C38.5695 80.4114 38.4573 80.6326 38.3528 80.8537C38.1515 81.278 37.9782 81.7126 37.7105 82.1112C37.6162 82.2526 37.5321 82.4429 37.448 82.6126C37.2135 83.0961 36.9357 83.5538 36.6426 84.0038C36.5483 84.1478 36.4464 84.2918 36.3623 84.4384C36.1456 84.8241 35.8984 85.1918 35.6155 85.5441C35.4829 85.7138 35.3479 85.8707 35.1949 86.025C35.0318 86.1793 34.8814 86.3696 34.7107 86.5316C34.2672 86.9636 33.788 87.3699 33.2324 87.6682C32.6309 88.0899 31.8765 88.3239 31.1577 88.4679C30.9335 88.5065 30.6582 88.5219 30.3931 88.4782C30.0847 88.437 29.9216 88.3753 29.8604 88.3548C29.7993 88.329 29.8426 88.3188 29.9598 88.2905C30.0287 88.275 30.0975 88.2596 30.1688 88.2416C30.281 88.2236 30.4161 88.1799 30.6582 88.1619C30.9972 88.1053 31.3209 88.0076 31.6165 87.8662C31.7363 87.8147 31.851 87.7582 31.9657 87.6965C32.0829 87.6399 32.2027 87.5807 32.3327 87.5216C32.427 87.4804 32.5239 87.4213 32.6105 87.3596C33.0132 87.0922 33.3981 86.8016 33.8033 86.4827H33.8008Z"
        fill="#6F3264"
      />
      <path
        d="M65.8357 82.9933C65.9275 83.2659 65.9963 83.5667 66.139 83.8316C66.1773 83.9036 66.2027 84.0219 66.2155 84.1144C66.2588 84.3973 66.3072 84.6802 66.3761 84.9656C66.3863 85.0093 66.3913 85.0556 66.4041 85.0942C66.5519 85.4773 66.5774 85.8862 66.6641 86.2822C66.7278 86.5599 66.7533 86.8454 66.8476 87.1205C66.896 87.2722 66.9189 87.4625 66.9444 87.6348C67.0183 88.1568 67.054 88.684 67.0489 89.2163C67.0489 89.3809 67.0362 89.5274 66.9954 89.5532C66.9725 89.5686 66.9648 89.7049 66.9444 89.7126C66.8985 89.7357 66.7788 89.53 66.6437 89.1314C66.585 88.954 66.5392 88.7688 66.4907 88.5888C66.3964 88.2417 66.2843 87.9023 66.2588 87.5397C66.2486 87.4111 66.2078 87.2594 66.1696 87.1205C66.0651 86.7271 66.0014 86.3336 65.9326 85.9376C65.9096 85.8116 65.8918 85.6805 65.8536 85.5596C65.7542 85.2459 65.6701 84.927 65.591 84.6056C65.5528 84.4513 65.512 84.3047 65.4865 84.1479C65.4585 83.9833 65.3948 83.8162 65.3489 83.6516C65.224 83.2119 65.0787 82.7824 64.9793 82.3324C64.7831 81.8361 64.6735 81.2832 64.4874 80.7844C64.4314 80.6301 64.3906 80.4346 64.3651 80.2392C64.3141 79.7789 64.3039 79.7301 64.4212 79.8715L64.5282 80.0001C64.5868 80.0721 64.6455 80.1595 64.7219 80.3369C64.8264 80.5838 64.9564 80.8178 65.0762 81.0518C65.1731 81.2395 65.275 81.4144 65.3362 81.6355C65.3591 81.7152 65.3999 81.7975 65.433 81.8747C65.5936 82.2295 65.7312 82.5947 65.8306 82.9984L65.8357 82.9933Z"
        fill="#6F3264"
      />
      <path
        d="M60.1776 33.6C60.2235 33.4046 60.2999 33.204 60.2974 32.9854C60.2974 32.9263 60.3305 32.8491 60.3611 32.79C60.4529 32.61 60.5421 32.43 60.6109 32.2371C60.6211 32.2088 60.6389 32.1806 60.644 32.1523C60.6924 31.8668 60.8581 31.6277 60.9652 31.3654C61.0391 31.1802 61.1487 31.0105 61.1945 30.8125C61.2226 30.7045 61.2914 30.5888 61.35 30.4808C61.5284 30.1594 61.7374 29.8534 61.9872 29.5731C62.0662 29.4882 62.1401 29.4162 62.1835 29.4239C62.2089 29.4291 62.2778 29.3622 62.2981 29.3673C62.3466 29.3828 62.3466 29.5576 62.2803 29.8456C62.2497 29.9742 62.2038 30.0951 62.1605 30.2185C62.079 30.4551 62.0229 30.6942 61.8776 30.9025C61.8266 30.9771 61.7935 31.08 61.7629 31.1725C61.6712 31.4374 61.5488 31.6817 61.429 31.9311C61.3908 32.0108 61.3475 32.0931 61.3245 32.1754C61.2659 32.3966 61.1945 32.61 61.1155 32.8234C61.0799 32.9263 61.0467 33.024 60.9957 33.1243C60.9473 33.2272 60.9269 33.3506 60.8912 33.4637C60.7995 33.7646 60.7307 34.0706 60.616 34.3638C60.5625 34.7289 60.4095 35.0863 60.3458 35.4489C60.3254 35.5595 60.2719 35.6881 60.2056 35.8089C60.0425 36.0944 60.0272 36.1278 59.9915 35.9967C59.9788 35.9555 59.9661 35.9169 59.9559 35.8784C59.938 35.8141 59.9253 35.7369 59.938 35.6032C59.9584 35.4181 59.9508 35.2278 59.9508 35.0426C59.9508 34.8935 59.9406 34.7495 59.989 34.5952C60.0068 34.5412 60.0068 34.4743 60.0119 34.4152C60.0323 34.1426 60.0807 33.87 60.1776 33.5975V33.6Z"
        fill="#6F3264"
      />
    </g>
    <defs>
      <clipPath id="clip0_1019_6473">
        <rect
          width="76.88"
          height="70.68"
          fill="white"
          transform="translate(23.5598 26.0399)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default HorseSticker
