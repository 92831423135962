import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Media } from 'react-bootstrap'
import { AmazonCartItemShape } from 'cart/lib/prop-types'
import {
  removeAmazonCartItem,
  updateAmazonCartItemQuantity,
} from 'cart/actions'
import Image from 'components/image'
import { PrimaryButton, DeleteButton } from 'components/buttons'
import { NumberSelect } from 'components/forms'
import OwnerMarkAsPurchasedAction from 'registry/shop-registry/components/owner-mark-as-purchased-action'
import { InfoIcon, LeavingSiteIcon } from 'components/icons'
import BorderedOrderWrapper from 'cart/components/bordered-order-wrapper'
import CartSectionHeader from '../cart-section-header'
import { track, useTracking } from 'lib/analytics/track'

const AmazonCartItems = ({
  items,
  removeAmazonCartItem,
  updateAmazonCartItemQuantity,
}) => {
  return (
    <div className="pbxl">
      {items.map((item) => {
        const {
          id,
          imageUrl,
          price,
          quantity,
          regItemId,
          registryId,
          title,
          url,
        } = item
        return (
          <Media key={id} className="ptl pbl-sm">
            <Media.Left className="prl">
              <Image width={100} height={100} src={imageUrl} alt={title} />
            </Media.Left>
            <Media.Body>
              <div className="media-heading h5 mbm">{title}</div>
              <div className="flex mbm">
                <NumberSelect
                  inline
                  wrapperClassName="mrm mbn"
                  min={1}
                  max={10}
                  optionPrefix="Qty: "
                  onChange={(e) =>
                    updateAmazonCartItemQuantity(id, e.target.value)
                  }
                  pill
                  value={quantity}
                />
                <DeleteButton
                  aria-label="Remove this Amazon item from your cart"
                  className="mlxs"
                  onClick={() => removeAmazonCartItem(item.id)}
                />
              </div>
              <OwnerMarkAsPurchasedAction
                regItemId={regItemId}
                registryId={registryId}
                quantity={quantity}
                afterMarkAsPurchased={() => {
                  removeAmazonCartItem(item.id)
                }}
              />
            </Media.Body>
          </Media>
        )
      })}
    </div>
  )
}

AmazonCartItems.propTypes = {
  updateAmazonCartItemQuantity: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(AmazonCartItemShape)).isRequired,
  removeAmazonCartItem: PropTypes.func.isRequired,
}

const trackCheckoutClick = ({ items, registryId, tracker }) => {
  const regItemIds = items.map(({ regItemId }) => regItemId)
  const productIds = items.map(({ productId }) => productId)

  return () => {
    const trackingEvent = {
      eventType: track.EventType.CLICK,
      event: track.buyOnAmazonButtonClicked,
      registryId,
      productIds,
      regItemIds,
    }
    tracker.trackEvent(trackingEvent)
  }
}

export const AmazonCart = ({
  checkoutUrl,
  items,
  removeAmazonCartItem,
  updateAmazonCartItemQuantity,
  registry,
}) => {
  const count = items.length || 0
  const tracker = useTracking()

  return (
    <div id="amazon-cart-items" className="pbxl">
      <CartSectionHeader className="mtn mbl h2" title="Amazon" count={count} />
      <BorderedOrderWrapper>
        <AmazonCartItems
          items={items}
          removeAmazonCartItem={removeAmazonCartItem}
          updateAmazonCartItemQuantity={updateAmazonCartItemQuantity}
        />
        <div>
          <div className="bas rounded-corners pal mtm mbl small flex">
            <InfoIcon height={30} width={30} className="mrm" />
            <div>
              <strong>Avoid receiving duplicates.</strong>
              <br />
              <span className="text-normal">
                After checkout on Amazon, return to Babylist to mark items as
                purchased.
              </span>
            </div>
          </div>
          <PrimaryButton
            block
            pill
            href={checkoutUrl}
            newWindow
            onClick={trackCheckoutClick({
              items,
              tracker,
              registryId: registry.id,
            })}
          >
            <span className="mrm">Buy On Amazon</span>
            <LeavingSiteIcon style={{ position: 'relative', top: -1 }} />
          </PrimaryButton>
        </div>
      </BorderedOrderWrapper>
    </div>
  )
}

AmazonCart.propTypes = {
  checkoutUrl: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(AmazonCartItemShape)).isRequired,
  removeAmazonCartItem: PropTypes.func.isRequired,
  updateAmazonCartItemQuantity: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    checkoutUrl: state.cart?.amazonCart?.checkoutUrl,
    items: state.cart?.amazonCart?.items,
  }
}

export default connect(mapStateToProps, {
  removeAmazonCartItem,
  updateAmazonCartItemQuantity,
})(AmazonCart)
