import classNames from 'classnames'
import css from './SectionText.scss'
import { SectionTextProps } from './types'

const SectionText = ({ children, className, ...rest }: SectionTextProps) => (
  <p className={classNames(css.SectionText, className)} {...rest}>
    {children}
  </p>
)

export default SectionText
