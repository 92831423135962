import React from 'react'

interface DogStickerProps {
  className?: string
}

const DogSticker: React.FC<DogStickerProps> = ({
  className,
}: DogStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1019_6464)">
      <path
        d="M37.2837 40.6077C37.2837 40.6077 37.3644 72.3287 32.124 83.8193C32.124 83.8193 54.6441 105.435 79.6911 98.8691L88.2957 46.5965L37.2837 40.6077Z"
        fill="#FFD43F"
      />
      <path
        d="M59.3145 79.9741C59.3145 79.9741 54.7399 92.1324 60.6763 94.7126C66.6127 97.2929 69.2909 81.7561 70.7788 81.214L58.7698 76.6609L59.3145 79.9741Z"
        fill="#F3D7E8"
      />
      <path
        d="M42.5991 23.4093C41.9384 23.9916 38.322 26.1451 37.8378 26.873C35.2706 30.7032 30.9633 27.1441 36.2037 40.6076C42.6243 57.1082 44.9999 51.6113 51.94 71.6258C53.5439 76.2492 49.63 82.755 65.79 83.8444C80.1292 84.8132 77.219 73.6338 78.9843 65.6069C81.5768 53.8452 88.7489 52.4296 89.9241 38.5193C91.0993 24.609 82.1467 19.8401 61.0439 19.8401C49.5896 19.8401 44.7527 21.5067 42.5991 23.4093Z"
        fill="#FFD43F"
      />
      <path
        d="M16.4131 33.7804C16.4131 33.7804 30.7826 18.4394 36.3811 23.6401C41.9796 28.8408 37.8589 52.9265 34.4191 58.7998C30.9793 64.6731 13.6643 44.4578 16.4131 33.7804Z"
        fill="#FFD43F"
      />
      <path
        d="M87.1007 26.2556C87.1007 26.2556 85.9154 48.2429 90.0513 54.2719C94.1871 60.3008 105.455 42.0132 107.654 35.0204C109.853 28.0276 95.6094 19.9405 87.1007 26.2556Z"
        fill="#FFD43F"
      />
      <path
        d="M58.9556 69.6932C58.9556 69.6932 57.2054 76.7211 59.7121 76.2141C62.2189 75.7071 60.2417 73.5034 62.1482 72.9662C64.0548 72.4291 61.8557 77.6247 64.4179 77.8054C66.9801 77.9862 66.7985 72.8809 68.7252 72.8809C70.6519 72.8809 68.9068 76.435 70.9243 76.2543C72.9418 76.0736 73.9455 69.141 72.4828 68.5035C71.0201 67.866 61.0134 67.1381 58.9606 69.6882L58.9556 69.6932Z"
        fill="#6F3264"
      />
      <path
        d="M61.9775 88.7889C61.9976 88.5831 62.0783 88.3773 61.9876 88.1413C61.9623 88.0761 61.9976 87.9958 62.033 87.9355C62.1338 87.7498 62.2297 87.5641 62.27 87.3582C62.2751 87.3281 62.3003 87.298 62.2952 87.2679C62.2549 86.9617 62.4516 86.7107 62.5121 86.4295C62.5575 86.2287 62.6584 86.048 62.6332 85.8322C62.618 85.7117 62.6785 85.5862 62.7189 85.4707C62.8399 85.1143 63.0518 84.778 63.2888 84.4467C63.3746 84.3463 63.4603 84.2609 63.541 84.266C63.5915 84.266 63.6621 84.1856 63.7024 84.1907C63.7629 84.2007 63.8033 84.2459 63.8537 84.3362C63.8991 84.4216 63.9798 84.5621 64.005 84.7178C64.0252 84.8583 64.0303 84.9989 64.0353 85.1394C64.0353 85.4055 64.0908 85.6766 63.9395 85.9125C63.889 85.9978 63.889 86.1133 63.884 86.2187C63.8638 86.5149 63.7579 86.786 63.6621 87.0621C63.6318 87.1474 63.5864 87.2378 63.5814 87.3281C63.5763 87.5691 63.536 87.805 63.4704 88.0359C63.4401 88.1464 63.42 88.2518 63.3645 88.3622C63.309 88.4727 63.3141 88.6032 63.2888 88.7287C63.2233 89.05 63.1829 89.3813 63.0417 89.6875C63.0316 90.079 62.8248 90.4455 62.7542 90.822C62.7239 90.9375 62.6634 91.068 62.5424 91.1834C62.2498 91.4495 62.2549 91.4846 62.154 91.334C62.1288 91.2989 62.1036 91.2537 62.0683 91.1985C62.0279 91.1232 61.9876 91.0379 61.9976 90.9023C62.0077 90.7065 61.9623 90.5007 61.922 90.3049C61.8867 90.1443 61.8463 89.9887 61.8968 89.8331C61.917 89.7778 61.9018 89.7076 61.8968 89.6423C61.8665 89.3512 61.8816 89.07 61.9775 88.7889Z"
        fill="#6F3264"
      />
      <path
        d="M22.6014 36.9882C22.667 37.2342 22.6821 37.5103 22.8586 37.7513C22.9091 37.8165 22.904 37.927 22.899 38.0123C22.8737 38.2734 22.8536 38.5294 22.8838 38.7904C22.8838 38.8306 22.8737 38.8707 22.8889 38.9059C23.0301 39.2573 22.9242 39.6287 22.9444 39.9852C22.9645 40.2361 22.904 40.4922 22.9898 40.7482C23.0301 40.8887 23.0049 41.0594 22.9898 41.215C22.9393 41.6819 22.8233 42.1487 22.6266 42.6005C22.556 42.7411 22.4904 42.8616 22.4047 42.8716C22.3542 42.8766 22.2887 42.9921 22.2483 42.9921C22.1475 42.9971 21.9961 42.7963 21.865 42.4299C21.8095 42.2692 21.7944 42.1036 21.7591 41.9429C21.7036 41.6367 21.6027 41.3305 21.6885 41.0243C21.7187 40.9138 21.6885 40.7783 21.6683 40.6578C21.6128 40.3114 21.638 39.9701 21.6481 39.6287C21.6481 39.5183 21.6683 39.4028 21.638 39.2974C21.5674 39.0213 21.5271 38.7452 21.5019 38.4591C21.4867 38.3236 21.4716 38.1981 21.4817 38.0575C21.4918 37.9119 21.4363 37.7663 21.4111 37.6208C21.3455 37.2342 21.2396 36.8627 21.2345 36.4612C21.0631 36.0345 21.0782 35.5375 20.937 35.1058C20.8966 34.9702 20.8916 34.7996 20.937 34.6138C21.0328 34.1821 21.0328 34.1369 21.1892 34.2474C21.2295 34.2725 21.2799 34.3076 21.3354 34.3478C21.4111 34.403 21.4918 34.4783 21.5624 34.6289C21.6582 34.8447 21.8045 35.0455 21.9407 35.2463C22.0466 35.407 22.1727 35.5626 22.1979 35.7634C22.208 35.8337 22.2534 35.909 22.2887 35.9792C22.4501 36.2955 22.5711 36.6268 22.6014 36.9933V36.9882Z"
        fill="#6F3264"
      />
      <path
        d="M28.0535 39.2521C28.0888 39.5634 28.0888 39.8947 28.2502 40.2009C28.2905 40.2812 28.2905 40.4118 28.2805 40.5172C28.2502 40.8284 28.23 41.1396 28.2704 41.4559C28.2754 41.5011 28.2704 41.5563 28.2805 41.5965C28.4368 42.0181 28.3612 42.4699 28.4368 42.9016C28.4822 43.2079 28.4822 43.5191 28.6033 43.8153C28.6739 43.9759 28.684 44.1817 28.7041 44.3725C28.7647 44.9397 28.7899 45.517 28.7596 46.1144C28.7445 46.3001 28.7243 46.4658 28.6486 46.506C28.6033 46.5311 28.5831 46.6867 28.5478 46.7068C28.457 46.7469 28.2401 46.5511 27.9728 46.1345C27.8669 45.9437 27.7963 45.7429 27.7105 45.5472C27.5643 45.1656 27.3726 44.7942 27.3877 44.3725C27.3877 44.2219 27.3272 44.0562 27.2868 43.8956C27.1608 43.4488 27.1305 42.992 27.1002 42.5402C27.0901 42.3946 27.1002 42.244 27.0599 42.1035C26.9641 41.742 26.9136 41.3706 26.8834 40.9941C26.8733 40.8134 26.8531 40.6427 26.8632 40.462C26.8783 40.2712 26.8329 40.0754 26.8127 39.8796C26.7724 39.3626 26.7169 38.8455 26.7724 38.3285C26.6816 37.7261 26.7976 37.0886 26.8027 36.4862C26.8027 36.3004 26.848 36.0795 26.954 35.8637C27.191 35.3667 27.2061 35.3165 27.3171 35.5173C27.3524 35.5775 27.3877 35.6378 27.423 35.698C27.4785 35.7984 27.5239 35.9139 27.539 36.1247C27.5693 36.4209 27.645 36.7121 27.7257 36.9982C27.7912 37.2291 27.8669 37.445 27.8568 37.6909C27.8568 37.7813 27.882 37.8767 27.9022 37.9721C27.998 38.3887 28.0636 38.8104 28.0585 39.2572L28.0535 39.2521Z"
        fill="#6F3264"
      />
      <path
        d="M40.9702 31.3407C41.1014 31.9883 41.172 32.686 41.3435 33.3487C41.3989 33.5244 41.3939 33.8055 41.3788 34.0213C41.3435 34.679 41.2729 35.3265 41.2275 35.9992C41.2174 36.0996 41.1922 36.205 41.1972 36.2954C41.2224 37.2291 40.945 38.1277 40.7534 39.0212C40.6222 39.6587 40.3902 40.2611 40.2742 40.9087C40.2036 41.2651 40.0422 41.6617 39.8909 42.0282C39.442 43.1325 38.867 44.1817 38.176 45.1506C37.9642 45.4518 37.7524 45.6977 37.6565 45.6977C37.601 45.6977 37.4094 45.9286 37.364 45.9236C37.2581 45.9086 37.364 45.4016 37.6313 44.518C37.7574 44.1265 37.9138 43.74 38.0449 43.3534C38.3021 42.6105 38.4887 41.8575 38.8267 41.1446C38.9427 40.8886 39.0284 40.5523 39.094 40.2511C39.2957 39.3927 39.5328 38.5594 39.7144 37.706C39.7648 37.4349 39.8404 37.1538 39.8505 36.8827C39.8959 36.1699 39.9312 35.4621 39.9464 34.7442C39.9615 34.4029 39.9413 34.0766 39.9615 33.7302C39.9665 33.3738 39.9111 32.9923 39.8606 32.6208C39.7446 31.6419 39.5176 30.6881 39.3008 29.7243C38.9023 28.65 38.5341 27.5004 37.944 26.5115C37.7574 26.2103 37.5758 25.8288 37.4043 25.4372C37.0008 24.5286 36.9252 24.4282 37.2581 24.6591C37.3589 24.7294 37.4598 24.7947 37.5607 24.865C37.7322 24.9804 37.8986 25.1461 38.1659 25.4874C38.519 25.9744 38.8973 26.4412 39.2302 26.9382C39.5025 27.3298 39.7597 27.7163 39.9312 28.2082C39.9918 28.389 40.0977 28.5647 40.1733 28.7454C40.5516 29.5486 40.829 30.407 40.9652 31.3457L40.9702 31.3407Z"
        fill="#6F3264"
      />
      <path
        d="M86.263 33.2483C86.2327 33.8156 86.1974 34.423 86.3285 35.0003C86.3588 35.1559 86.3487 35.3919 86.3386 35.5776C86.3184 35.8587 86.3033 36.1398 86.2932 36.4209C86.2932 36.7021 86.3083 36.9882 86.3336 37.2794C86.3437 37.3647 86.3336 37.4601 86.3487 37.5354C86.5404 38.3185 86.5202 39.1217 86.6816 39.9048C86.7774 40.457 86.8833 41.0142 87.08 41.5463C87.2061 41.8375 87.2868 42.1989 87.3726 42.5352L87.7811 44.0412C87.9425 44.5382 88.0535 45.0552 88.2149 45.5572C88.3107 45.8735 88.3763 46.1596 88.3157 46.2299C88.2804 46.2751 88.351 46.5411 88.3157 46.5713C88.2451 46.6466 87.8921 46.3203 87.3826 45.6024C87.1759 45.2711 86.9943 44.9197 86.8127 44.5683C86.4849 43.8956 86.1217 43.223 86.0007 42.465C85.9553 42.1939 85.8191 41.8927 85.7434 41.6015C85.4812 40.7883 85.3551 39.95 85.2492 39.1167C85.2239 38.8506 85.2088 38.5695 85.1533 38.3135C85.0777 37.9771 85.0323 37.6307 84.997 37.2894C84.9617 36.943 84.9213 36.6017 84.9213 36.2503C84.9163 35.9189 84.9012 35.5977 84.9213 35.2613C84.9566 34.915 84.9264 34.5435 84.9314 34.177C84.9314 33.7001 84.9768 33.2182 85.002 32.7413C85.0373 32.2644 85.118 31.7875 85.2088 31.3157C85.3046 30.1962 85.6476 29.0617 85.9502 27.9874C86.041 27.6511 86.1923 27.2646 86.4143 26.9182C86.9237 26.0999 86.9691 26.0196 86.964 26.3961V26.7375C86.964 26.9232 86.9439 27.1391 86.843 27.5156C86.732 28.0477 86.6513 28.5798 86.616 29.1069C86.5958 29.5286 86.5656 29.9251 86.4748 30.3719C86.4395 30.5325 86.4395 30.7133 86.4294 30.8839C86.3991 31.2655 86.389 31.652 86.3638 32.0435C86.3285 32.4301 86.2932 32.8267 86.2579 33.2333L86.263 33.2483Z"
        fill="#6F3264"
      />
      <path
        d="M94.1563 34.7243C94.2017 34.905 94.1866 35.1058 94.3429 35.2865C94.3833 35.3367 94.3732 35.417 94.3581 35.4773C94.3076 35.663 94.2673 35.8487 94.2723 36.0395C94.2723 36.0696 94.2572 36.0997 94.2723 36.1248C94.3782 36.3859 94.242 36.6469 94.232 36.908C94.232 37.0937 94.1513 37.2694 94.2118 37.4601C94.242 37.5656 94.2017 37.686 94.1714 37.7965C94.0958 38.1278 93.9142 38.4491 93.7024 38.7453C93.6217 38.8356 93.541 38.9109 93.4603 38.9059C93.4098 38.9059 93.3443 38.9762 93.3039 38.9711C93.2434 38.9661 93.2031 38.9209 93.1425 38.8456C93.0921 38.7704 93.0013 38.6549 92.971 38.5194C92.9408 38.3989 92.9206 38.2834 92.9004 38.168C92.8702 37.9471 92.7895 37.7262 92.9004 37.5254C92.9408 37.4501 92.9206 37.3547 92.9105 37.2694C92.8803 37.0234 92.9357 36.7925 92.9761 36.5566C92.9862 36.4813 93.0114 36.4009 92.9963 36.3256C92.9509 36.1299 92.9357 35.9341 92.9357 35.7383C92.9357 35.6429 92.9256 35.5526 92.9509 35.4572C92.9761 35.3568 92.9307 35.2514 92.9206 35.151C92.8903 34.8799 92.8248 34.6189 92.855 34.3377C92.7289 34.0365 92.7895 33.6902 92.7037 33.3789C92.6886 33.2836 92.6886 33.1631 92.7491 33.0275C92.9004 32.7113 92.8803 32.6812 93.0316 32.7514C93.0719 32.7665 93.1123 32.7916 93.1627 32.8167C93.2333 32.8569 93.3039 32.902 93.3544 33.0125C93.4199 33.1681 93.5511 33.3087 93.6671 33.4542C93.7579 33.5697 93.8638 33.6801 93.8739 33.8257C93.8739 33.8809 93.9142 33.9311 93.9445 33.9813C94.0756 34.2122 94.1614 34.4582 94.1563 34.7243Z"
        fill="#6F3264"
      />
      <path
        d="M101.414 31.4512C101.551 31.6972 101.631 31.9883 101.863 32.2092C101.929 32.2694 101.949 32.3899 101.959 32.4803C101.989 32.7664 102.025 33.0425 102.095 33.3236C102.105 33.3638 102.1 33.414 102.121 33.4491C102.317 33.8106 102.262 34.2322 102.322 34.6188C102.373 34.8949 102.327 35.176 102.433 35.4521C102.479 35.6027 102.459 35.7934 102.453 35.9641C102.418 36.4812 102.297 36.9932 102.075 37.4751C102 37.6257 101.924 37.7512 101.838 37.7562C101.788 37.7562 101.717 37.8767 101.677 37.8767C101.576 37.8767 101.44 37.6508 101.329 37.2643C101.278 37.0936 101.263 36.9229 101.238 36.7573C101.182 36.436 101.082 36.1298 101.152 35.8035C101.177 35.688 101.152 35.5475 101.117 35.427C101.041 35.0756 101.031 34.7192 101.006 34.3628C100.996 34.2473 100.996 34.1268 100.955 34.0264C100.85 33.7503 100.764 33.4742 100.688 33.1881C100.648 33.0525 100.607 32.9271 100.592 32.7815C100.572 32.6359 100.481 32.4953 100.426 32.3548C100.275 31.9783 100.073 31.6319 99.9518 31.2353C99.6593 30.8588 99.513 30.3719 99.2154 29.9954C99.1246 29.8799 99.0591 29.7093 99.0238 29.5235C98.938 29.0768 98.9128 29.0266 99.1095 29.0768C99.1599 29.0868 99.2205 29.1019 99.2911 29.1169C99.3869 29.142 99.4928 29.1872 99.624 29.3127C99.7954 29.4984 100.022 29.644 100.224 29.8097C100.386 29.9402 100.562 30.0607 100.658 30.2615C100.693 30.3317 100.759 30.397 100.819 30.4572C101.082 30.7384 101.304 31.0596 101.435 31.4462L101.414 31.4512Z"
        fill="#6F3264"
      />
      <path
        d="M99.5224 46.6866C99.5022 46.8071 99.4316 46.9175 99.5173 47.0781C99.5426 47.1233 99.5073 47.1635 99.477 47.1936C99.3812 47.284 99.2904 47.3743 99.25 47.4898C99.245 47.5048 99.2198 47.5199 99.2248 47.54C99.2652 47.7358 99.0785 47.8462 99.0231 48.0018C98.9827 48.1123 98.8869 48.2026 98.9121 48.3382C98.9272 48.4135 98.8718 48.4787 98.8314 48.539C98.7154 48.7297 98.5136 48.8853 98.2917 49.0359C98.206 49.0761 98.1303 49.1112 98.0496 49.0861C97.9992 49.0711 97.9336 49.1062 97.8933 49.0962C97.8378 49.0761 97.7974 49.0409 97.747 48.9757C97.7016 48.9104 97.626 48.81 97.6007 48.7096C97.5806 48.6193 97.5755 48.5339 97.5654 48.4436C97.5654 48.2829 97.5099 48.1022 97.6512 47.9918C97.7016 47.9516 97.6966 47.8813 97.7016 47.8161C97.7167 47.6404 97.8176 47.4948 97.9084 47.3492C97.9387 47.304 97.979 47.2589 97.9841 47.2036C97.9841 47.0581 98.0244 46.9225 98.0849 46.797C98.1101 46.7368 98.1303 46.6766 98.1858 46.6213C98.2413 46.5661 98.2312 46.4858 98.2564 46.4155C98.322 46.2348 98.3573 46.044 98.4884 45.8884C98.4935 45.6525 98.6952 45.4768 98.7608 45.2609C98.7911 45.1957 98.8465 45.1304 98.9625 45.0903C99.2399 44.9949 99.2399 44.9698 99.3358 45.0903C99.366 45.1254 99.3912 45.1605 99.4165 45.1907C99.4568 45.2459 99.4921 45.3061 99.4871 45.3914C99.477 45.5069 99.5274 45.6424 99.5627 45.773C99.598 45.8784 99.6384 45.9838 99.593 46.0691C99.5779 46.0993 99.588 46.1444 99.593 46.1846C99.6233 46.3703 99.6132 46.536 99.5224 46.6866Z"
        fill="#6F3264"
      />
      <path
        d="M46.2764 46.722C46.2764 46.722 51.2242 39.3075 58.3611 45.0805C58.3611 45.0805 53.5141 52.5501 46.2764 46.722Z"
        fill="#F9F6F3"
      />
      <path
        d="M52.3185 48.5894C53.8088 48.5894 55.0169 47.3869 55.0169 45.9037C55.0169 44.4204 53.8088 43.218 52.3185 43.218C50.8282 43.218 49.6201 44.4204 49.6201 45.9037C49.6201 47.3869 50.8282 48.5894 52.3185 48.5894Z"
        fill="#6F3264"
      />
      <path
        d="M67.9395 45.6928C67.9395 45.6928 74.0726 39.212 80.125 46.1044C80.125 46.1044 74.0827 52.6555 67.9395 45.6928Z"
        fill="#F9F6F3"
      />
      <path
        d="M74.0314 48.5894C75.5217 48.5894 76.7298 47.3869 76.7298 45.9037C76.7298 44.4204 75.5217 43.218 74.0314 43.218C72.5411 43.218 71.333 44.4204 71.333 45.9037C71.333 47.3869 72.5411 48.5894 74.0314 48.5894Z"
        fill="#6F3264"
      />
      <path
        d="M48.9897 42.7962C49.3378 42.6406 49.7312 42.5402 50.0842 42.3293C50.1801 42.2691 50.3465 42.254 50.4726 42.254C50.871 42.259 51.2443 42.2691 51.6276 42.2741C51.6831 42.2741 51.7486 42.2892 51.7991 42.2841C52.3337 42.1888 52.8482 42.3845 53.3525 42.4749C53.7157 42.5402 54.0486 42.6857 54.4168 42.7159C54.6185 42.7359 54.8404 42.8363 55.0422 42.9217C55.6525 43.1827 56.2073 43.5391 56.7116 43.9658C56.863 44.1064 56.989 44.2369 56.9739 44.3222C56.9638 44.3724 57.0798 44.4979 57.0697 44.5381C57.0395 44.6385 56.7318 44.6536 56.2224 44.5833C55.9954 44.5481 55.7836 44.4779 55.5667 44.4277C55.1531 44.3222 54.7396 44.2771 54.3714 44.0612C54.2402 43.9909 54.0587 43.9558 53.8973 43.9357C53.4383 43.8604 53.0045 43.7249 52.5607 43.6345C52.4195 43.6094 52.2732 43.5642 52.132 43.5743C51.7587 43.5944 51.4006 43.6144 51.0324 43.6345C50.861 43.6395 50.6996 43.6747 50.518 43.6747C50.3364 43.6847 50.1498 43.755 49.9682 43.8102C49.4891 43.9558 49.0503 44.1817 48.5913 44.3825C48.1424 44.7389 47.6229 45.0602 47.2951 45.527C47.2043 45.6726 47.053 45.8182 46.8815 45.9688C46.483 46.3252 46.4528 46.4005 46.4175 46.1646C46.4074 46.1093 46.3922 46.034 46.3821 45.9487C46.362 45.8282 46.3821 45.6927 46.4729 45.4668C46.6192 45.1656 46.7403 44.8393 46.9067 44.5431C47.0378 44.2971 47.1791 44.0612 47.4212 43.8855C47.5069 43.8203 47.5825 43.7249 47.6632 43.6496C48.0214 43.2831 48.4652 42.9769 48.9897 42.8062V42.7962Z"
        fill="#6F3264"
      />
      <path
        d="M71.5353 42.2992C71.9489 42.1587 72.4028 42.0784 72.8265 41.8926C72.9425 41.8374 73.1291 41.8324 73.2754 41.8424C73.7192 41.8625 74.153 41.9227 74.5968 41.9579C74.6624 41.9629 74.733 41.988 74.7935 41.983C75.424 41.9478 76.004 42.2189 76.574 42.4147C76.9876 42.5452 77.3457 42.7811 77.7693 42.9117C77.9963 42.992 78.2384 43.1576 78.4452 43.3133C79.0857 43.7801 79.6254 44.3875 79.9936 45.0753C80.1096 45.2911 80.1802 45.4919 80.1298 45.5622C80.0995 45.6074 80.1701 45.7881 80.1399 45.8182C80.0642 45.8985 79.7666 45.7279 79.2824 45.4267C79.0706 45.2911 78.8739 45.1305 78.6671 44.9949C78.2737 44.7289 77.845 44.5532 77.4919 44.2369C77.3608 44.1315 77.1691 44.0462 76.9926 43.9859C76.4983 43.7952 76.0343 43.5793 75.535 43.4187C75.3736 43.3735 75.2122 43.3032 75.0457 43.2982C74.617 43.2681 74.1933 43.248 73.7697 43.248C73.5679 43.243 73.3712 43.2681 73.1644 43.248C72.9476 43.248 72.7256 43.3082 72.5037 43.3584C71.9237 43.4789 71.3739 43.6998 70.809 43.8906C70.2189 44.252 69.5481 44.5532 69.0387 45.0351C68.8874 45.1857 68.6655 45.3263 68.4334 45.4568C67.8837 45.768 67.8282 45.8283 67.8837 45.5672C67.8938 45.502 67.9139 45.4216 67.9341 45.3263C67.9593 45.1907 68.0299 45.0552 68.1964 44.8343C68.4435 44.5431 68.6655 44.2219 68.9176 43.9307C69.1143 43.6948 69.3161 43.4639 69.6187 43.3032C69.7297 43.248 69.8255 43.1526 69.9314 43.0773C70.3904 42.7259 70.925 42.4448 71.5303 42.2992H71.5353Z"
        fill="#6F3264"
      />
      <path
        d="M47.129 38.7954C47.5577 38.5695 48.0419 38.3938 48.4757 38.1177C48.5917 38.0373 48.7934 37.9922 48.9548 37.9721C49.439 37.8968 49.9232 37.8566 50.4175 37.7863C50.4932 37.7763 50.5739 37.7863 50.6394 37.7663C51.3153 37.5906 52.0163 37.7161 52.6872 37.7663C53.1714 37.7964 53.6303 37.9369 54.1196 37.957C54.3869 37.9821 54.6946 38.0825 54.972 38.1829C55.8092 38.4791 56.591 38.9359 57.2467 39.5182C57.4484 39.6989 57.6048 39.8847 57.5846 39.97C57.5745 40.0202 57.7208 40.1859 57.7056 40.226C57.6653 40.3264 57.2921 40.2411 56.6616 40.0654C56.3842 39.9851 56.1118 39.8746 55.8395 39.7943C55.3149 39.6287 54.7803 39.5584 54.281 39.3275C54.0994 39.2522 53.8623 39.217 53.6455 39.202C53.0352 39.1317 52.4451 39.0313 51.8348 38.9911C51.6431 38.9811 51.4363 38.956 51.2497 38.9911C50.7554 39.0614 50.2712 39.1417 49.782 39.2421C49.55 39.2823 49.3281 39.3526 49.091 39.3877C48.8439 39.4379 48.5967 39.5483 48.3597 39.6487C47.7191 39.9048 47.124 40.2511 46.5086 40.5674C45.8832 41.0694 45.1569 41.5262 44.6273 42.1386C44.4659 42.3294 44.2339 42.5202 43.9918 42.7059C43.4118 43.1326 43.3563 43.2079 43.4118 42.9117C43.4219 42.8414 43.437 42.746 43.4622 42.6406C43.4875 42.49 43.5581 42.3344 43.7245 42.0684C43.9767 41.717 44.1986 41.3405 44.4508 40.984C44.6475 40.6979 44.8493 40.4218 45.1569 40.2009C45.2679 40.1206 45.3688 40.0052 45.4747 39.9098C45.9387 39.458 46.4834 39.0664 47.129 38.7954Z"
        fill="#6F3264"
      />
      <path
        d="M72.1002 38.7C72.5441 38.5595 73.0333 38.5093 73.4772 38.2984C73.5982 38.2382 73.7949 38.2282 73.9462 38.2382C74.4102 38.2432 74.8692 38.2533 75.3433 38.2482C75.4139 38.2482 75.4896 38.2583 75.5552 38.2482C76.2108 38.1378 76.8615 38.2984 77.502 38.3687C77.961 38.3988 78.3998 38.5595 78.8689 38.5595C79.1261 38.5796 79.4186 38.68 79.6859 38.7553C80.0894 38.8808 80.4828 39.0565 80.8611 39.2322C81.2344 39.443 81.6076 39.6338 81.9405 39.8948C82.1473 40.0604 82.3238 40.206 82.3137 40.2964C82.3087 40.3466 82.4701 40.4871 82.46 40.5273C82.4348 40.6277 82.0514 40.6227 81.426 40.4922C81.1436 40.442 80.8662 40.3717 80.5938 40.2964C80.0642 40.1859 79.5397 40.1207 79.0353 39.9199C78.8537 39.8496 78.6217 39.8094 78.4099 39.7994C77.8046 39.7342 77.2246 39.6287 76.6244 39.5484C76.4328 39.5334 76.2361 39.5032 76.0494 39.5133C75.8023 39.5233 75.5552 39.5484 75.3131 39.5635C75.0659 39.5836 74.8188 39.5785 74.5767 39.6087C74.3396 39.6287 74.1177 39.6538 73.8756 39.6488C73.6285 39.6589 73.3712 39.7342 73.114 39.7793C72.4432 39.9048 71.7976 40.1157 71.1268 40.2562C70.4055 40.5926 69.5834 40.7934 68.9025 41.21C68.6907 41.3305 68.4082 41.4259 68.1207 41.5213C67.4348 41.712 67.3541 41.7471 67.5155 41.5012C67.5609 41.4259 67.6113 41.3506 67.6567 41.2753C67.7374 41.1548 67.8433 41.0193 68.0854 40.8385C68.4335 40.6126 68.7462 40.3165 69.0841 40.0705C69.3565 39.8797 69.6036 39.6538 69.9516 39.5434C70.0777 39.5032 70.1987 39.4279 70.3198 39.3577C70.5871 39.197 70.8746 39.0715 71.1722 38.9611C71.4698 38.8456 71.7774 38.7502 72.1053 38.7H72.1002Z"
        fill="#6F3264"
      />
      <path
        d="M60.7825 84.4166C60.026 84.3413 59.2543 84.1003 58.4927 83.9296C58.291 83.8794 57.9984 83.7389 57.7916 83.6033C57.1511 83.2068 56.561 82.755 55.9709 82.273C55.8851 82.1978 55.7994 82.1074 55.7136 82.0522C54.8562 81.4247 54.261 80.5261 53.7163 79.6476C53.328 79.0251 53.0556 78.3475 52.7227 77.6949C52.5361 77.3334 52.405 76.8666 52.3041 76.4349C51.9863 75.1347 51.9107 73.7944 52.0569 72.4992C52.1023 72.0926 52.178 71.7512 52.2637 71.701C52.3142 71.6759 52.3848 71.3496 52.4251 71.3396C52.5311 71.3045 52.6521 71.8516 52.8387 72.8305C52.9144 73.2673 52.9799 73.714 53.0758 74.1458C53.2523 74.974 53.5247 75.7572 53.7012 76.5804C53.7617 76.8766 53.9181 77.2129 54.0643 77.5091C54.4779 78.3575 54.9066 79.1707 55.4564 79.9187C55.6329 80.1546 55.8145 80.4056 56.0314 80.5964C56.5963 81.1084 57.1864 81.5803 57.8269 81.9869C58.1346 82.1777 58.4423 82.3433 58.76 82.524C59.0929 82.7098 59.4863 82.8252 59.8646 82.9307C60.3639 83.0712 60.8733 83.1616 61.3878 83.1967C61.8972 83.2369 62.3764 83.2168 62.7445 83.0813C63.1279 82.8554 63.3952 82.4588 63.5414 81.9016C63.6928 81.3494 63.6928 80.742 63.6524 80.0793C63.6322 79.6727 63.6625 79.1557 63.8239 78.6537C64.0105 78.0613 64.2324 77.7852 64.3232 77.6949C64.4191 77.6045 64.3938 77.6949 64.3737 77.9157C64.3636 78.0262 64.3585 78.1617 64.3636 78.3173C64.3636 78.6185 64.3737 78.8394 64.3787 79.231C64.3888 79.8685 64.5502 80.4809 64.6107 81.1536C64.6511 81.6857 64.6662 82.2429 64.3787 82.8503C64.2728 83.0662 64.1517 83.3122 63.9954 83.523C63.6373 84.02 63.0169 84.3513 62.4419 84.4417C61.8569 84.5521 61.3222 84.5019 60.7876 84.4266L60.7825 84.4166Z"
        fill="#6F3264"
      />
      <path
        d="M67.9748 83.9648C68.5598 84.0703 69.2256 84.1305 69.8258 83.9448C69.9872 83.8946 70.2343 83.8795 70.4361 83.8644C71.0363 83.8243 71.6415 83.6887 72.2216 83.4377C72.3073 83.4026 72.4082 83.3775 72.4788 83.3273C73.2152 82.8655 73.9969 82.4789 74.6274 81.8715C75.0612 81.4448 75.4949 81.0031 75.7622 80.4459C75.9085 80.1396 76.1254 79.8033 76.2868 79.4619C76.7861 78.4529 77.1291 77.3486 77.2854 76.1839C77.3359 75.8225 77.3661 75.4962 77.4468 75.446C77.4973 75.4159 77.5124 75.1046 77.5527 75.0795C77.6082 75.0544 77.6738 75.1649 77.7797 75.4058C77.8755 75.6468 78.0016 76.0283 78.0521 76.5353C78.0975 76.9921 78.0773 77.464 78.0268 77.9258C77.931 78.8094 77.7343 79.7129 77.235 80.486C77.0635 80.7621 76.8819 81.1135 76.6953 81.4247C76.1506 82.3032 75.394 83.0161 74.5618 83.5833C74.2945 83.7691 74.002 83.9247 73.7397 84.1004C73.0487 84.5572 72.277 84.8985 71.46 85.1044C71.0716 85.2048 70.6883 85.275 70.2848 85.3052C69.8611 85.3303 69.4122 85.3805 68.9684 85.3654C67.8134 85.3252 66.5928 85.0491 65.6143 84.3062C65.0091 83.9598 64.4694 83.3875 64.1264 82.735C63.7784 82.0824 63.6019 81.3695 63.5565 80.6517C63.5313 80.2099 63.6321 79.6627 63.8944 79.2059C64.192 78.6688 64.4744 78.473 64.5703 78.4027C64.6762 78.3325 64.6409 78.4178 64.5804 78.6236C64.545 78.719 64.5198 78.8545 64.4896 79.0001C64.4543 79.2059 64.3988 79.4368 64.3433 79.8585C64.2626 80.4509 64.419 81.0432 64.661 81.5352C64.8578 81.9368 65.115 82.2631 65.3823 82.6346C65.4781 82.7701 65.6244 82.8805 65.7656 82.986C66.4062 83.4528 67.1476 83.749 67.9798 83.9648H67.9748Z"
        fill="#6F3264"
      />
      <path
        d="M59.476 48.4487C59.5366 48.8102 59.5416 49.2017 59.708 49.5631C59.7534 49.6585 59.7484 49.8141 59.7383 49.9346C59.703 50.3011 59.6727 50.6625 59.6879 51.039C59.6879 51.0942 59.6778 51.1545 59.6879 51.2047C59.8089 51.7167 59.6879 52.2338 59.6879 52.7458C59.6929 53.1072 59.6223 53.4687 59.6879 53.8351C59.7181 54.0359 59.6879 54.2769 59.6627 54.4978C59.587 55.1604 59.4407 55.823 59.2138 56.4656C59.1381 56.6664 59.0625 56.837 58.9767 56.8521C58.9263 56.8621 58.8557 57.0228 58.8103 57.0278C58.7094 57.0378 58.5732 56.7467 58.4673 56.2146C58.4269 55.9786 58.4118 55.7427 58.3967 55.5068C58.3614 55.055 58.2807 54.6132 58.3816 54.1664C58.4169 54.0058 58.3967 53.81 58.3816 53.6343C58.3462 53.1273 58.3816 52.6303 58.4169 52.1283C58.4269 51.9677 58.4471 51.802 58.4219 51.6464C58.3614 51.2348 58.3362 50.8282 58.321 50.4165C58.3109 50.2208 58.3009 50.03 58.321 49.8292C58.3362 49.6234 58.2857 49.4025 58.2655 49.1867C58.2151 48.6194 58.1193 48.0572 58.1193 47.4849C57.9579 46.8373 57.9831 46.1295 57.8419 45.4769C57.8015 45.2761 57.8066 45.0251 57.8419 44.7741C57.9478 44.1818 57.9478 44.1165 58.1041 44.3073C58.1546 44.3675 58.205 44.4227 58.2555 44.4779C58.3362 44.5733 58.4118 44.6938 58.4824 44.9247C58.5732 45.251 58.7195 45.5623 58.8557 45.8735C58.9616 46.1245 59.0826 46.3604 59.1028 46.6516C59.1129 46.757 59.1532 46.8674 59.1885 46.9678C59.3449 47.4397 59.4559 47.9266 59.471 48.4537L59.476 48.4487Z"
        fill="#6F3264"
      />
      <path
        d="M68.0906 48.5642C68.1158 49.011 68.1007 49.4879 68.252 49.9346C68.2923 50.0551 68.2822 50.2409 68.2722 50.3864C68.2318 50.8282 68.2015 51.275 68.2318 51.7268C68.2318 51.792 68.2217 51.8673 68.2369 51.9276C68.3831 52.55 68.2923 53.1825 68.3478 53.805C68.3831 54.2468 68.373 54.6935 68.489 55.1253C68.5546 55.3662 68.5596 55.6624 68.5748 55.9335C68.6202 56.7517 68.6252 57.57 68.5748 58.4033C68.5546 58.6643 68.5294 58.8902 68.4487 58.9354C68.4033 58.9605 68.3781 59.1764 68.3377 59.1914C68.2419 59.2316 68.0301 58.9153 67.7779 58.2828C67.677 58.0017 67.6064 57.7055 67.5307 57.4144C67.3946 56.8571 67.213 56.3049 67.2432 55.7226C67.2533 55.5168 67.1928 55.2708 67.1575 55.0449C67.0415 54.4024 67.0314 53.7598 67.0163 53.1223C67.0163 52.9165 67.0213 52.7056 66.9911 52.5049C66.9053 51.9828 66.87 51.4607 66.8498 50.9286C66.8448 50.6776 66.8297 50.4316 66.8498 50.1756C66.87 49.9095 66.8297 49.6284 66.8196 49.3523C66.7944 48.6244 66.759 47.8965 66.8297 47.1636C66.759 46.3102 66.9003 45.4217 66.9204 44.5683C66.9255 44.3023 66.986 43.991 67.0919 43.6898C67.3492 42.987 67.3643 42.9117 67.4702 43.1929C67.5055 43.2782 67.5358 43.3635 67.5711 43.4489C67.6215 43.5894 67.6669 43.7551 67.672 44.0563C67.6921 44.478 67.7527 44.8946 67.8283 45.3012C67.8888 45.6275 67.9544 45.9388 67.9342 46.2952C67.9292 46.4257 67.9544 46.5663 67.9695 46.6968C68.0502 47.2992 68.1007 47.9016 68.0805 48.5441L68.0906 48.5642Z"
        fill="#6F3264"
      />
      <path
        d="M41.9493 58.4786C42.3124 59.1111 42.6756 59.8089 43.1648 60.3861C43.296 60.5418 43.4372 60.8128 43.5431 61.0287C43.8609 61.6813 44.1887 62.3289 44.5922 62.9614C44.6527 63.0568 44.7032 63.1672 44.7637 63.2475C45.4042 64.0658 45.8531 64.9995 46.4382 65.8478C46.8417 66.4502 47.22 67.0727 47.6991 67.6299C47.9715 67.9311 48.2287 68.3377 48.4809 68.7042C49.2425 69.8086 49.9789 70.933 50.6951 72.0776C50.922 72.434 51.0986 72.7553 51.0683 72.8456C51.0481 72.9009 51.2247 73.2021 51.1994 73.2422C51.154 73.3376 50.6598 73.0063 49.8528 72.2633C49.5048 71.922 49.1769 71.5505 48.844 71.1891C48.2237 70.4913 47.568 69.8186 47.0888 68.9954C46.9173 68.7042 46.645 68.393 46.418 68.0968C45.7623 67.2584 45.2075 66.3699 44.6578 65.4714C44.4863 65.1852 44.3148 64.879 44.1231 64.6129C43.6137 63.9152 43.1547 63.1873 42.7159 62.4393C42.5091 62.0829 42.3024 61.7415 42.1157 61.365C41.9291 60.9735 41.6668 60.5869 41.4449 60.1954C40.8548 59.1563 40.2647 58.1171 39.7805 57.0228C39.0845 55.813 38.5599 54.4425 37.9749 53.1775C37.7933 52.786 37.6218 52.299 37.5159 51.802C37.2587 50.6374 37.2234 50.522 37.5058 50.8884C37.5915 50.9938 37.6823 51.1043 37.7681 51.2197C37.9093 51.4004 38.0606 51.6314 38.2775 52.0731C38.5952 52.6856 38.9382 53.2779 39.3014 53.8401C39.5888 54.2919 39.8713 54.7237 40.1184 55.2558C40.2092 55.4465 40.3353 55.6423 40.4463 55.828C40.9658 56.6714 41.4651 57.5298 41.9443 58.4685L41.9493 58.4786Z"
        fill="#6F3264"
      />
      <path
        d="M38.2171 73.468C38.1868 73.709 38.1061 73.9549 38.1919 74.226C38.212 74.2963 38.1767 74.3967 38.1414 74.467C38.0355 74.6879 37.9346 74.9138 37.8842 75.1547C37.8792 75.1899 37.8539 75.225 37.8539 75.2601C37.8842 75.6166 37.6825 75.9177 37.6169 76.2541C37.5664 76.49 37.4605 76.7109 37.4807 76.9619C37.4908 77.1025 37.4303 77.248 37.3849 77.3886C37.2437 77.8103 37.0469 78.2219 36.7847 78.6135C36.6989 78.7339 36.6132 78.8394 36.5274 78.8394C36.477 78.8394 36.3963 78.9347 36.356 78.9347C36.2551 78.9247 36.1391 78.7088 36.0634 78.3323C36.0382 78.1667 36.0483 78.001 36.0433 77.8404C36.0433 77.5292 36.0029 77.2129 36.1593 76.9318C36.2147 76.8314 36.2147 76.6958 36.2248 76.5703C36.25 76.224 36.366 75.8977 36.472 75.5714C36.5073 75.466 36.5527 75.3605 36.5577 75.2551C36.5678 74.969 36.6182 74.6929 36.6838 74.4218C36.7141 74.2913 36.7393 74.1658 36.7998 74.0353C36.8603 73.9048 36.8553 73.7491 36.8855 73.6036C36.9612 73.222 37.0116 72.8355 37.163 72.469C37.1831 72.0072 37.405 71.5705 37.4807 71.1237C37.5059 70.9831 37.5816 70.8325 37.7077 70.692C37.9952 70.3607 38.0204 70.3255 38.1061 70.4962C38.1313 70.5364 38.1616 70.5916 38.1919 70.6518C38.2322 70.7372 38.2675 70.8325 38.2574 70.9982C38.2423 71.2291 38.2827 71.4651 38.318 71.696C38.3482 71.8817 38.3886 72.0624 38.3331 72.2482C38.3129 72.3134 38.323 72.3937 38.3331 72.469C38.3533 72.8054 38.3331 73.1367 38.2322 73.473L38.2171 73.468Z"
        fill="#6F3264"
      />
      <path
        d="M46.4228 77.8205C46.3926 78.1267 46.3119 78.448 46.3926 78.7843C46.4128 78.8747 46.3774 79.0002 46.3421 79.0955C46.2312 79.3867 46.1303 79.6779 46.0799 79.9941C46.0698 80.0393 46.0496 80.0895 46.0496 80.1296C46.0799 80.5764 45.8731 80.983 45.8025 81.4097C45.752 81.7109 45.6411 82.0021 45.6612 82.3183C45.6713 82.494 45.6058 82.6898 45.5604 82.8705C45.4141 83.4177 45.2174 83.9599 44.9501 84.4819C44.8593 84.6426 44.7786 84.7831 44.6878 84.7932C44.6374 84.7932 44.5567 84.9287 44.5113 84.9287C44.4104 84.9287 44.2894 84.6677 44.2187 84.1958C44.1935 83.985 44.2036 83.7791 44.2036 83.5733C44.2036 83.1818 44.1683 82.7802 44.3247 82.4087C44.3801 82.2782 44.3801 82.1025 44.3902 81.9469C44.4205 81.5051 44.5315 81.0834 44.6424 80.6567C44.6777 80.5212 44.7282 80.3806 44.7282 80.2451C44.7433 79.8837 44.7937 79.5323 44.8593 79.1759C44.8946 79.0052 44.9148 78.8445 44.9753 78.6789C45.0358 78.5082 45.0358 78.3124 45.0661 78.1267C45.1468 77.6347 45.1922 77.1428 45.3485 76.6659C45.3738 76.0836 45.5957 75.5013 45.6764 74.929C45.7016 74.7533 45.7823 74.5475 45.9084 74.3567C46.2009 73.9099 46.2261 73.8597 46.3119 74.0656C46.3371 74.1158 46.3674 74.181 46.3976 74.2513C46.438 74.3517 46.4733 74.4722 46.4632 74.683C46.4481 74.9742 46.4834 75.2703 46.5237 75.5615C46.5489 75.7924 46.5943 76.0183 46.5338 76.2593C46.5136 76.3446 46.5237 76.445 46.5288 76.5404C46.5489 76.9671 46.5288 77.3888 46.4228 77.8205Z"
        fill="#6F3264"
      />
      <path
        d="M37.9442 63.9151C37.924 64.0657 37.8483 64.2113 37.9341 64.4021C37.9593 64.4523 37.924 64.5075 37.8937 64.5527C37.7929 64.6782 37.7021 64.8087 37.6567 64.9543C37.6516 64.9744 37.6264 64.9944 37.6315 65.0195C37.6718 65.2555 37.4802 65.4211 37.4196 65.6219C37.3793 65.7675 37.2784 65.893 37.2986 66.0587C37.3137 66.149 37.2532 66.2344 37.2128 66.3197C37.0918 66.5707 36.885 66.7966 36.653 67.0175C36.5672 67.0827 36.4865 67.1329 36.4058 67.1179C36.3554 67.1078 36.2898 67.163 36.2444 67.153C36.189 67.1379 36.1486 67.0978 36.0931 67.0225C36.0477 66.9522 35.9721 66.8367 35.9469 66.7163C35.9267 66.6058 35.9267 66.5004 35.9166 66.395C35.9166 66.1942 35.8662 65.9834 36.0124 65.8227C36.0629 65.7675 36.0578 65.6771 36.0679 65.6018C36.0881 65.381 36.189 65.1902 36.2898 64.9944C36.3201 64.9342 36.3655 64.874 36.3655 64.8037C36.3705 64.623 36.4109 64.4523 36.4714 64.2816C36.5017 64.2013 36.5218 64.121 36.5723 64.0507C36.6278 63.9754 36.6227 63.875 36.6479 63.7846C36.7135 63.5487 36.7539 63.3077 36.8951 63.0969C36.9052 62.8007 37.112 62.5547 37.1826 62.2786C37.2128 62.1983 37.2683 62.1029 37.3894 62.0327C37.6769 61.872 37.6718 61.8419 37.7727 61.9724C37.8029 62.0126 37.8282 62.0477 37.8534 62.0879C37.8937 62.1481 37.9341 62.2184 37.924 62.3238C37.9089 62.4694 37.9593 62.63 37.9997 62.7857C38.035 62.9112 38.0753 63.0316 38.0249 63.1471C38.0047 63.1873 38.0198 63.2425 38.0249 63.2877C38.0501 63.5085 38.04 63.7194 37.9442 63.9202V63.9151Z"
        fill="#6F3264"
      />
      <path
        d="M40.6126 84.4669C40.537 84.6827 40.4058 84.8936 40.4361 85.1596C40.4411 85.2299 40.3907 85.3102 40.3403 85.3705C40.194 85.5512 40.0528 85.7319 39.9519 85.9478C39.9368 85.9779 39.9065 86.008 39.9015 86.0381C39.8611 86.3745 39.6039 86.6104 39.4728 86.9016C39.3769 87.1074 39.2307 87.2881 39.1953 87.524C39.1752 87.6546 39.0894 87.7801 39.0138 87.8955C38.7919 88.2519 38.5195 88.5832 38.1866 88.8895C38.0756 88.9798 37.9748 89.0601 37.8941 89.035C37.8436 89.02 37.7478 89.0953 37.7075 89.0802C37.6116 89.0501 37.541 88.8192 37.541 88.4577C37.5511 88.2971 37.5915 88.1515 37.6217 87.9959C37.6873 87.7098 37.7125 87.4086 37.9193 87.1827C37.9899 87.1024 38.0202 86.9769 38.0555 86.8664C38.1513 86.5502 38.3278 86.2791 38.4943 86.003C38.5498 85.9126 38.6153 85.8273 38.6405 85.7319C38.7061 85.4709 38.812 85.2299 38.9331 84.989C38.9886 84.8785 39.039 84.7631 39.1197 84.6576C39.2054 84.5522 39.2307 84.4066 39.2912 84.2811C39.4425 83.9448 39.5635 83.5984 39.7855 83.2972C39.8964 82.8756 40.199 82.5242 40.3655 82.1276C40.4159 82.0071 40.5218 81.8816 40.6732 81.7812C41.0161 81.5453 41.0464 81.5152 41.0968 81.6909C41.112 81.7461 41.1321 81.8013 41.1473 81.8515C41.1725 81.9368 41.1826 82.0372 41.1422 82.1878C41.0817 82.3987 41.0716 82.6246 41.0615 82.8454C41.0514 83.0211 41.0565 83.2019 40.9657 83.3575C40.9304 83.4127 40.9253 83.493 40.9152 83.5583C40.8699 83.8745 40.7841 84.1757 40.6126 84.4619V84.4669Z"
        fill="#6F3264"
      />
      <path
        d="M49.9437 91.3943C49.974 91.5449 49.9538 91.7106 50.1051 91.8562C50.1455 91.8963 50.1354 91.9616 50.1152 92.0118C50.0648 92.1674 50.0244 92.318 50.0345 92.4736C50.0345 92.4987 50.0194 92.5238 50.0345 92.5439C50.1505 92.7547 50.0345 92.9756 50.0446 93.1864C50.0547 93.337 50.0043 93.4927 50.085 93.6382C50.1304 93.7186 50.1051 93.824 50.0951 93.9143C50.0698 94.1955 49.9538 94.4766 49.8126 94.7627C49.7521 94.8531 49.6966 94.9284 49.6159 94.9434C49.5655 94.9535 49.5201 95.0237 49.4797 95.0288C49.4192 95.0338 49.3688 95.0087 49.2931 94.9585C49.2275 94.9033 49.1115 94.828 49.046 94.7175C48.9905 94.6222 48.9501 94.5218 48.9047 94.4214C48.8341 94.2306 48.7131 94.0499 48.7938 93.8491C48.824 93.7788 48.7887 93.6935 48.7686 93.6182C48.708 93.4023 48.7383 93.1864 48.7686 92.9706C48.7787 92.9003 48.7988 92.83 48.7736 92.7648C48.7131 92.5891 48.6929 92.4134 48.6929 92.2377C48.6929 92.1523 48.6828 92.072 48.708 91.9867C48.7333 91.8963 48.6929 91.8009 48.6879 91.7106C48.6677 91.4646 48.6223 91.2237 48.6778 90.9777C48.587 90.6966 48.6929 90.3903 48.6626 90.1042C48.6626 90.0138 48.6828 89.9084 48.7736 89.803C48.9854 89.552 48.9754 89.5269 49.1115 89.6123C49.1519 89.6374 49.1922 89.6675 49.2275 89.6926C49.2881 89.7377 49.3486 89.7879 49.3738 89.8883C49.4142 90.0289 49.515 90.1644 49.6008 90.3C49.6764 90.4054 49.7571 90.5108 49.7521 90.6313C49.7521 90.6765 49.7773 90.7217 49.8025 90.7668C49.9034 90.9676 49.9639 91.1684 49.9488 91.3893L49.9437 91.3943Z"
        fill="#6F3264"
      />
      <path
        d="M71.7071 95.0589C71.6516 94.813 71.6466 94.5369 71.4802 94.3009C71.4348 94.2357 71.4348 94.1302 71.4448 94.0449C71.4701 93.7889 71.4902 93.5329 71.4549 93.2768C71.4499 93.2367 71.46 93.1965 71.4448 93.1614C71.2935 92.82 71.3843 92.4536 71.3339 92.0972C71.2986 91.8512 71.3288 91.5952 71.228 91.3492C71.1725 91.2137 71.1775 91.048 71.1775 90.8924C71.1674 90.4255 71.2179 89.9587 71.3288 89.4768C71.3692 89.3262 71.4146 89.1956 71.4953 89.1705C71.5457 89.1555 71.5861 89.03 71.6264 89.0199C71.7222 88.9948 71.9089 89.1705 72.1056 89.5169C72.1863 89.6725 72.2317 89.8382 72.2871 89.9988C72.388 90.305 72.5343 90.6062 72.4838 90.9376C72.4687 91.053 72.5141 91.1886 72.5444 91.3141C72.6352 91.6655 72.6402 92.0269 72.6503 92.3833C72.6503 92.4988 72.6452 92.6192 72.6755 92.7247C72.7612 93.0108 72.8066 93.2969 72.8369 93.5931C72.852 93.7337 72.8722 93.8692 72.8571 94.0098C72.847 94.1604 72.8974 94.311 72.9176 94.4666C72.9731 94.8682 73.0588 95.2698 73.0387 95.6814C73.1748 96.1432 73.1143 96.6503 73.19 97.1221C73.2152 97.2677 73.19 97.4484 73.1244 97.6291C72.963 98.0508 72.9529 98.096 72.8167 97.9554C72.7814 97.9253 72.736 97.8801 72.6856 97.8299C72.62 97.7597 72.5545 97.6743 72.5091 97.5087C72.4435 97.2777 72.3275 97.0569 72.2165 96.841C72.1257 96.6653 72.0299 96.5047 72.0198 96.2989C72.0198 96.2236 71.9795 96.1483 71.9492 96.078C71.8181 95.7517 71.7222 95.4153 71.7071 95.0539V95.0589Z"
        fill="#6F3264"
      />
      <path
        d="M32.5021 82.9107L28.8555 92.6243C28.8555 92.6243 42.7105 101.786 54.8456 103.427C66.9808 105.069 79.59 103.427 79.59 103.427L80.0742 97.9554C80.0742 97.9554 52.349 103.417 32.5071 82.9056L32.5021 82.9107Z"
        fill="#F26D51"
      />
      <path
        d="M80.6541 66.1189C80.881 65.4061 81.1685 64.6581 81.2896 63.8951C81.3198 63.6892 81.4409 63.3981 81.5367 63.1722C81.8343 62.4895 82.1268 61.8068 82.379 61.0839C82.4143 60.9785 82.4698 60.863 82.495 60.7626C82.7422 59.7285 83.2163 58.7797 83.5643 57.7858C83.8064 57.083 84.109 56.4003 84.2855 55.6724C84.3864 55.2708 84.573 54.814 84.7395 54.3873C85.2388 53.1122 85.7885 51.8522 86.4039 50.6173C86.5955 50.2308 86.7771 49.9045 86.878 49.8844C86.9385 49.8744 87.105 49.5631 87.1503 49.5581C87.2563 49.5481 87.226 50.1555 87.0041 51.2297C86.8982 51.7066 86.762 52.1886 86.6308 52.6655C86.3787 53.5741 86.1719 54.4927 85.7684 55.3612C85.6271 55.6724 85.5162 56.074 85.4103 56.4304C85.1026 57.4595 84.7142 58.4384 84.3309 59.4273C84.2099 59.7436 84.0686 60.0648 83.9779 60.3811C83.7358 61.2144 83.4634 62.0377 83.1658 62.861C83.0246 63.2525 82.8935 63.634 82.727 64.0206C82.5555 64.4222 82.4294 64.874 82.2832 65.3057C81.8898 66.4452 81.5266 67.5897 81.0626 68.7042C80.6742 70.0545 80.069 71.4099 79.6251 72.7402C79.4839 73.1518 79.2721 73.6287 79.0199 74.0755C78.4247 75.1247 78.3693 75.2401 78.4096 74.7733C78.4197 74.6327 78.4298 74.4922 78.4348 74.3466C78.455 74.1107 78.4954 73.8396 78.6366 73.3526C78.8333 72.68 78.9796 71.9973 79.1258 71.3296C79.2418 70.7975 79.3377 70.2804 79.5495 69.7182C79.6251 69.5174 79.6756 69.2865 79.7311 69.0706C79.9782 68.0917 80.2657 67.1129 80.649 66.1089L80.6541 66.1189Z"
        fill="#6F3264"
      />
      <path
        d="M34.2924 31.0547C34.3277 31.1902 34.3075 31.3358 34.4588 31.4663C34.4992 31.5015 34.4891 31.5617 34.4739 31.6069C34.4285 31.7475 34.3882 31.888 34.4033 32.0235C34.4033 32.0436 34.3932 32.0687 34.4033 32.0838C34.5244 32.2695 34.4033 32.4703 34.4235 32.6611C34.4336 32.7966 34.3882 32.9372 34.4689 33.0677C34.5143 33.138 34.4941 33.2334 34.484 33.3187C34.4639 33.5697 34.3529 33.8307 34.2167 34.0918C34.1612 34.1721 34.1007 34.2474 34.02 34.2624C33.9696 34.2725 33.9242 34.3377 33.8838 34.3428C33.8233 34.3528 33.7729 34.3277 33.6972 34.2825C33.6316 34.2373 33.5156 34.1671 33.4501 34.0717C33.3946 33.9863 33.3492 33.896 33.3038 33.8106C33.2281 33.645 33.1071 33.4793 33.1878 33.2986C33.213 33.2334 33.1777 33.1631 33.1575 33.0928C33.092 32.902 33.1222 32.7063 33.1424 32.5105C33.1474 32.4502 33.1676 32.38 33.1424 32.3247C33.0819 32.1691 33.0567 32.0135 33.0567 31.8529C33.0567 31.7776 33.0466 31.7023 33.0667 31.622C33.092 31.5416 33.0516 31.4563 33.0415 31.376C33.0214 31.1551 32.9659 30.9392 33.0214 30.7133C32.9255 30.4623 33.0214 30.1862 32.9911 29.9302C32.9911 29.8499 33.0062 29.7545 33.097 29.6541C33.3038 29.4232 33.2937 29.3981 33.4299 29.4734C33.4702 29.4985 33.5106 29.5186 33.5459 29.5387C33.6064 29.5738 33.6669 29.624 33.6972 29.7144C33.7376 29.8399 33.8435 29.9603 33.9343 30.0758C34.0099 30.1712 34.0957 30.2615 34.0906 30.372C34.0906 30.4121 34.1209 30.4523 34.1411 30.4925C34.247 30.6682 34.3125 30.8539 34.2974 31.0497L34.2924 31.0547Z"
        fill="#6F3264"
      />
      <path
        d="M31.5686 54.568C31.5585 54.6584 31.503 54.7337 31.5989 54.8642C31.6241 54.8993 31.5989 54.9294 31.5686 54.9495C31.4829 55.0098 31.4072 55.07 31.3769 55.1553C31.3769 55.1654 31.3517 55.1754 31.3568 55.1905C31.4122 55.3411 31.2458 55.4114 31.2055 55.5268C31.1752 55.6071 31.0945 55.6674 31.1298 55.7728C31.15 55.833 31.1046 55.8732 31.0693 55.9184C30.9785 56.0539 30.7969 56.1543 30.5952 56.2497C30.5195 56.2748 30.4439 56.2899 30.3682 56.2698C30.3178 56.2547 30.2572 56.2748 30.2169 56.2597C30.1614 56.2397 30.1211 56.2095 30.0656 56.1543C30.0151 56.1041 29.9344 56.0188 29.8991 55.9385C29.8739 55.8682 29.8588 55.8029 29.8437 55.7326C29.8235 55.6071 29.7579 55.4666 29.884 55.3963C29.9294 55.3712 29.9193 55.316 29.9193 55.2708C29.9193 55.1353 30 55.0399 30.0757 54.9345C30.1009 54.8993 30.1362 54.8742 30.1312 54.829C30.1211 54.7186 30.1463 54.6182 30.1866 54.5278C30.2068 54.4827 30.2219 54.4375 30.2673 54.4024C30.3127 54.3672 30.3026 54.302 30.3178 54.2518C30.3632 54.1212 30.3783 53.9757 30.4943 53.8702C30.4792 53.6895 30.6507 53.5741 30.696 53.4184C30.7162 53.3733 30.7616 53.3281 30.8726 53.303C31.1298 53.2528 31.1248 53.2377 31.2307 53.3331C31.2609 53.3632 31.2912 53.3883 31.3164 53.4184C31.3618 53.4636 31.4022 53.5138 31.4022 53.5741C31.4022 53.6594 31.4627 53.7698 31.5131 53.8702C31.5535 53.9556 31.6039 54.0359 31.5686 54.0961C31.5535 54.1162 31.5686 54.1514 31.5787 54.1815C31.6241 54.322 31.6291 54.4526 31.5585 54.558L31.5686 54.568Z"
        fill="#6F3264"
      />
      <path
        d="M91.5686 43.6847C91.5989 43.8805 91.5787 44.0964 91.7199 44.2922C91.7603 44.3474 91.7452 44.4277 91.73 44.493C91.6796 44.6887 91.6342 44.8895 91.6443 45.0903C91.6443 45.1204 91.6292 45.1556 91.6443 45.1807C91.7603 45.4568 91.6342 45.7379 91.6443 46.014C91.6544 46.2098 91.5989 46.4056 91.6746 46.6013C91.7149 46.7118 91.6897 46.8423 91.6796 46.9628C91.6493 47.3242 91.5283 47.6907 91.3871 48.0571C91.3265 48.1726 91.266 48.268 91.1853 48.288C91.1349 48.2981 91.0895 48.3884 91.0441 48.3985C90.9836 48.4035 90.9331 48.3734 90.8625 48.2981C90.797 48.2278 90.686 48.1174 90.6204 47.9768C90.5649 47.8513 90.5246 47.7208 90.4842 47.5903C90.4187 47.3443 90.2976 47.0983 90.3834 46.8473C90.4136 46.7569 90.3834 46.6465 90.3632 46.5511C90.3077 46.27 90.343 45.9889 90.3632 45.7078C90.3733 45.6174 90.3935 45.5271 90.3733 45.4367C90.3178 45.2108 90.2976 44.9799 90.3027 44.749C90.3027 44.6385 90.2976 44.5331 90.3228 44.4227C90.3481 44.3072 90.3128 44.1817 90.3077 44.0663C90.2926 43.75 90.2472 43.4337 90.3077 43.1125C90.2169 42.746 90.3279 42.3494 90.3077 41.983C90.3077 41.8675 90.3329 41.732 90.4237 41.5964C90.6406 41.2752 90.6305 41.2451 90.7616 41.3605C90.797 41.3906 90.8323 41.4258 90.8776 41.4659C90.9382 41.5262 90.9937 41.5964 91.0239 41.727C91.0592 41.9127 91.1601 42.0934 91.2458 42.2691C91.3215 42.4097 91.3972 42.5452 91.3921 42.7059C91.3921 42.7611 91.4173 42.8263 91.4375 42.8816C91.5384 43.1426 91.5939 43.4137 91.5737 43.6948L91.5686 43.6847Z"
        fill="#6F3264"
      />
    </g>
    <defs>
      <clipPath id="clip0_1019_6464">
        <rect
          width="91.76"
          height="84.32"
          fill="white"
          transform="translate(16.1201 19.84)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default DogSticker
