import classnames from 'classnames'
import { LinkButton } from 'components/buttons'
import ListItem from '../../../registry/components/ListItem'
import List from '../../../registry/components/List'
import css from './styles.scss'
import Link from 'components/link'
import PageHeader from '../../assets/PageHeader'
// @ts-ignore
import { Helmet } from 'react-helmet'
// @ts-ignore
import { babylistUrl, babylossPath } from 'lib/urls'

export interface LossSupportLandingContentLink {
  text: string
  url: string
  routerLink: boolean
}

export interface LossSupportLandingViewProps {
  links: LossSupportLandingContentLink[]
}

export default function LossSupportLandingView({
  links,
}: LossSupportLandingViewProps) {
  return (
    <>
      <Helmet
        link={[
          {
            rel: 'canonical',
            href: babylistUrl(babylossPath),
          },
        ]}
        meta={[
          {
            name: 'description',
            content:
              "We're so sorry you've experienced loss. Find Babylist loss support resources here.",
          },
        ]}
        title="Miscarriage & Loss Support | Babylist"
      />
      <PageHeader
        content={
          <>
            We're So Sorry For <br /> Your Loss
          </>
        }
      />
      <section className="mbxxl">
        <div className="mbxl">
          <p>
            We will pause this account, and you won't receive any more emails or
            notifications related to it. We will also hide your registry from
            visitors for the time being.
          </p>

          <p>Here are some resources that may be helpful:</p>
        </div>

        {/*@ts-ignore*/}
        <List>
          {/*@ts-ignore*/}
          <ListItem variant="plain">
            <hr />
          </ListItem>
          {links.map((link) => (
            <>
              {/*@ts-ignore*/}
              <ListItem key={link.text + link.url} variant="plain">
                {link.routerLink ? (
                  <h2 className="block mvs">
                    <Link
                      to={link.url}
                      className={classnames(css.listItemLink, 'block pvm')}
                      routerVersion={6}
                    >
                      {link.text}
                    </Link>
                  </h2>
                ) : (
                  <h2 className="block mvs">
                    <a
                      href={link.url}
                      target="_blank"
                      className={classnames(css.listItemLink, 'block pvm')}
                      rel="nofollow"
                    >
                      {link.text}
                    </a>
                  </h2>
                )}
              </ListItem>
              {/*@ts-ignore*/}
              <ListItem key={'hr:' + link.text + link.url} variant="plain">
                <hr />
              </ListItem>
            </>
          ))}
        </List>
      </section>

      <div className="text-center mvxl">
        <LinkButton variant="info" href="/" pill>
          Back To Babylist
        </LinkButton>
      </div>
    </>
  )
}
