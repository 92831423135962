import PropTypes from 'prop-types'

import Image from 'components/image'

import { ProductCard } from './components/ui'
import { ProductShape } from './prop-types'

export const DiaperCalculatorProducts = ({ products }) => {
  return (
    <div className="flex row space-around">
      {products.map((product) => {
        return (
          <div key={product.product_id} style={{ width: '45%' }}>
            <ProductCard
              href={product.url}
              highlights={product.highlights}
              mediaComponent={
                <Image src={product.image.url} alt={product.image.alt} />
              }
            />
          </div>
        )
      })}
    </div>
  )
}

DiaperCalculatorProducts.propTypes = {
  products: PropTypes.arrayOf(ProductShape).isRequired,
}
