import React from 'react'

interface ScorpioStickerProps {
  className?: string
}

const ScorpioSticker: React.FC<ScorpioStickerProps> = ({
  className,
}: ScorpioStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.946 95.2865C61.957 95.2536 61.9735 95.2755 61.9899 95.1439C61.9899 95.1055 62.0009 95.1219 62.0064 95.1439C62.0173 95.1987 62.0338 95.2426 62.0448 95.2481C62.0448 95.2481 62.0448 95.2646 62.0503 95.2536C62.0667 95.1603 62.0886 95.2975 62.1051 95.3084C62.1161 95.3249 62.1325 95.3852 62.1435 95.3578C62.149 95.3413 62.16 95.3852 62.1654 95.4126C62.1764 95.462 62.1874 95.5169 62.2038 95.5772C62.2148 95.6375 62.2258 95.7034 62.2422 95.7747C62.2477 95.8076 62.2587 95.8624 62.2587 95.8789C62.2587 95.8899 62.2642 95.9063 62.2642 95.9063C62.2642 95.9063 62.2642 95.9063 62.2587 95.9337C62.2477 96.005 62.2367 96.0489 62.2367 96.0599C62.2093 96.1861 62.2203 96.1367 62.2093 96.1861C62.1929 96.2519 62.1764 96.378 62.16 96.3451C62.1545 96.3342 62.1435 96.3671 62.138 96.389C62.1161 96.4603 62.0996 96.4439 62.0777 96.4274C62.0722 96.4219 62.0667 96.4055 62.0612 96.4274C62.0448 96.4822 62.0283 96.4932 62.0119 96.4877C62.0064 96.4877 61.9954 96.4877 61.9899 96.4603C61.9844 96.4329 61.9735 96.4603 61.9625 96.4603C61.9406 96.4603 61.9186 96.4768 61.8967 96.378C61.8857 96.389 61.8692 96.3616 61.8583 96.3122C61.8583 96.3067 61.8583 96.2958 61.8199 96.1915C61.8144 96.1696 61.8034 96.1093 61.7925 96.0489C61.7705 95.8789 61.7705 95.9063 61.776 95.9173C61.776 95.9173 61.776 95.9173 61.7815 95.9008C61.7815 95.8844 61.7925 95.8624 61.7979 95.835C61.8089 95.8076 61.8254 95.7418 61.8363 95.665C61.8473 95.6101 61.8583 95.5333 61.8692 95.5169C61.8692 95.5169 61.8802 95.4894 61.8802 95.4675C61.8967 95.3633 61.9186 95.281 61.9351 95.2975L61.946 95.2865Z"
      fill="white"
    />
    <path
      d="M95.1918 57.2861C95.1918 57.2861 58.606 60.0561 50.7568 61.0928C42.9076 62.1295 41.0646 63.1717 41.8709 66.0568C42.6772 68.942 47.5261 76.4457 57.2238 76.2153C66.916 75.9849 68.9949 77.7182 81.6929 72.2934C94.391 66.8686 97.5065 64.329 97.5065 63.5227C97.5065 62.7164 97.6217 57.2916 95.1973 57.2916L95.1918 57.2861Z"
      fill="#030500"
    />
    <path
      d="M98.4709 63.9835C104.252 64.4991 109.194 52.2672 107.461 48.2247C105.728 44.1877 99.6118 43.6062 97.5329 45.3395C95.4541 47.0728 95.0427 54.8123 95.1085 56.4195C95.1743 58.1199 95.8325 63.7476 98.4654 63.9835H98.4709Z"
      fill="#030500"
    />
    <path
      d="M104.621 44.7852C107.16 45.0156 108.383 44.4725 108.998 43.0025C109.535 41.708 108.888 37.5119 105.427 32.7837C101.966 28.0501 99.5415 27.5893 96.1955 30.3593C92.8496 33.1293 91.1163 34.2812 92.5041 38.0933C93.8863 41.9 95.6196 44.3244 98.2744 44.5548C100.929 44.7852 104.621 44.7852 104.621 44.7852Z"
      fill="#030500"
    />
    <path
      d="M94.2977 32.5701C96.404 30.6394 99.1301 27.2605 98.5267 25.3078C97.4242 21.7589 91.6429 20.4535 87.1396 20.4535C82.6363 20.4535 81.4844 23.2235 81.4844 25.3023C81.4844 27.3812 81.3693 30.3816 85.5215 31.879C89.6737 33.3819 92.91 33.8427 94.2922 32.5701H94.2977Z"
      fill="#030500"
    />
    <path
      d="M82.2576 22.121C81.912 19.4662 80.2939 18.3143 73.9476 18.6599C67.6014 19.0055 65.4018 19.8886 65.2921 22.3514C65.1879 24.688 66.6744 30.431 72.7958 30.431C78.9172 30.431 80.645 27.7762 81.5665 26.6243C82.488 25.4724 82.2576 22.121 82.2576 22.121Z"
      fill="#030500"
    />
    <path
      d="M61.8198 22.1596C58.7536 22.4613 58.1612 30.8316 59.6642 37.1834C61.1671 43.5297 61.743 43.8752 63.3557 43.1841C64.9738 42.493 69.4716 38.796 69.3564 34.5286C69.2412 30.2557 66.7016 24.3702 65.3193 23.2183C63.9371 22.0664 61.8198 22.1596 61.8198 22.1596Z"
      fill="#030500"
    />
    <path
      d="M60.9093 45.4386C61.2055 46.5411 65.9885 52.0756 70.1134 48.8778C74.2382 45.6799 71.5943 41.7252 72.4829 39.2239C73.1082 37.4742 75.8782 35.8506 78.6427 36.1358C79.7178 36.2455 79.9866 34.6823 78.9499 34.3915C78.544 34.2764 78.0778 34.1941 77.5512 34.1447C73.0863 33.7388 70.3437 36.7556 69.6416 37.8691C68.9395 38.9826 65.1438 42.2792 63.1143 42.9867C61.0848 43.6943 60.6296 44.3964 60.9093 45.4496V45.4386Z"
      fill="#030500"
    />
    <path
      d="M51.1072 75.0037C51.1072 75.0037 45.0078 77.088 45.0078 78.5361C45.0078 79.9842 48.6993 88.4861 48.6993 88.4861C48.6993 88.4861 44.0479 89.7696 43.7242 93.4611C43.7242 93.4611 35.6995 97.7943 35.8641 98.7542C36.0231 99.7196 42.2817 96.5053 44.8487 95.5454C47.4157 94.5801 51.5899 90.4114 52.0671 88.6452C52.5498 86.8789 49.1765 81.2622 49.1765 80.6204C49.1765 79.9787 54.9523 75.6454 54.9523 75.6454L51.1017 75.0037H51.1072Z"
      fill="#030500"
    />
    <path
      d="M57.5474 75.7106C57.5474 75.7106 53.4171 80.6582 54.1685 81.8924C54.92 83.1265 62.4895 88.48 62.4895 88.48C62.4895 88.48 59.1764 91.9905 60.822 95.3145C60.822 95.3145 56.2145 103.18 56.8508 103.921C57.487 104.661 61.1675 98.6659 62.8624 96.5102C64.5573 94.3546 65.9561 88.6226 65.4514 86.8619C64.9468 85.1012 59.149 82.0514 58.8199 81.5029C58.4853 80.9544 61.173 74.2516 61.173 74.2516L57.5474 75.7051V75.7106Z"
      fill="#030500"
    />
    <path
      d="M66.3508 75.168L65.8187 81.1249C65.7584 81.8105 66.2795 82.4139 66.9706 82.4468L82.872 83.1873C82.872 83.1873 81.9066 86.078 81.9066 89.764C81.9066 93.45 82.872 95.3807 82.872 95.3807C82.872 95.3807 76.9919 105.248 77.897 106.614C78.2645 107.163 86.0808 96.187 86.0808 96.187C86.0808 96.187 88.3297 81.9038 86.8816 79.5013C85.439 77.0933 68.8136 80.1156 68.7478 79.441C68.682 78.7718 69.0165 74.4714 69.0165 74.4714L66.3453 75.168H66.3508Z"
      fill="#030500"
    />
    <path
      d="M80.1022 69.387L79.5702 75.3439C79.5098 76.0295 80.0309 76.6329 80.7221 76.6658L96.6234 77.4063C96.6234 77.4063 95.658 82.7268 95.658 86.4183C95.658 90.1098 97.7863 93.2967 97.7863 93.2967C97.7863 93.2967 96.2614 105.15 97.9728 105.699C98.6036 105.901 99.8322 92.8359 99.8322 92.8359C99.8322 92.8359 102.081 76.1228 100.633 73.7148C99.1905 71.3068 82.5651 74.3291 82.4992 73.6545C82.4334 72.9798 82.768 68.6849 82.768 68.6849L80.0968 69.3815L80.1022 69.387Z"
      fill="#030500"
    />
    <path
      d="M47.5256 62.136L43.6805 59.1959C43.6805 59.1959 46.2256 57.6382 47.6024 54.0344C48.9791 50.4307 48.458 50.2716 48.458 50.2716C48.458 50.2716 49.1766 44.1502 47.4872 39.8719C45.7978 35.5935 42.5012 33.2678 39.5338 33.2349C36.5663 33.2019 27.7353 36.6356 21.4164 44.9017C18.0376 49.3227 16.8692 52.6193 16.5182 54.731C16.403 55.4277 17.3519 55.7458 17.6755 55.1205C18.9207 52.7125 20.9556 49.2075 22.6944 48.2257C22.6944 48.2257 21.751 50.9189 21.0928 53.0416C20.9337 53.5517 21.5864 53.9083 21.932 53.4969C23.6434 51.4564 26.3146 48.0886 26.4846 46.827C26.4846 46.827 36.0013 45.0169 38.1789 42.8722C38.1789 42.8722 40.2249 47.1945 42.2324 49.7122L44.2455 52.2298C44.2455 52.2298 39.1498 55.3454 38.3874 57.0458C37.6249 58.7462 37.592 60.3643 38.6068 62.2128C39.627 64.0612 47.1416 66.4911 47.1416 66.4911L47.5365 62.1305L47.5256 62.136Z"
      fill="#030500"
    />
    <path
      d="M45.99 69.6121L41.2289 70.4952C41.2289 70.4952 42.7977 73.0348 42.3973 76.8689C41.9969 80.703 41.4593 80.6097 41.4593 80.6097C41.4593 80.6097 39.3311 86.3965 35.8919 89.4463C32.4527 92.496 28.4596 93.0829 25.7993 91.772C23.139 90.461 16.8092 83.4126 14.9113 73.1829C13.9021 67.7362 14.3409 64.275 14.9717 62.2291C15.1801 61.5489 16.1839 61.697 16.1894 62.4046C16.2113 65.1197 16.4472 69.1403 17.5497 70.7914C17.5497 70.7914 17.9117 68.0653 18.2792 65.8877C18.3724 65.3392 19.1568 65.3172 19.283 65.8602C19.8863 68.4602 20.7146 72.5685 20.3032 73.7478C20.3032 73.7478 27.9769 79.6608 28.9478 82.5624C28.9478 82.5624 32.7215 79.6279 35.6561 78.2895L38.5851 76.9512C38.5851 76.9512 35.4476 71.8719 35.5299 70.007C35.6177 68.1421 36.3198 66.6885 38.0585 65.4982C39.8028 64.3134 47.6081 65.5366 47.6081 65.5366L45.99 69.6066V69.6121Z"
      fill="#030500"
    />
    <path
      d="M53.959 69.8656C53.9206 69.5145 53.9261 69.1415 53.789 68.7905C53.7506 68.6972 53.7616 68.5491 53.778 68.4339C53.8274 68.0829 53.8658 67.7373 53.8713 67.3753C53.8713 67.3205 53.8877 67.2656 53.8713 67.2162C53.778 66.7171 53.9097 66.2289 53.9316 65.7407C53.9426 65.3952 54.0194 65.0496 53.981 64.6876C53.959 64.4846 54.0084 64.2488 54.0523 64.0349C54.1839 63.3821 54.3869 62.7184 54.705 62.0986C54.8037 61.9066 54.9134 61.7476 55.0945 61.8189C55.1987 61.8573 55.3523 61.7531 55.4729 61.8189C55.802 61.9944 55.9008 62.3345 55.7691 62.7568C55.7143 62.9488 55.632 63.1408 55.5826 63.3383C55.4839 63.7222 55.4839 64.1226 55.3193 64.5121C55.259 64.6547 55.2645 64.8357 55.2535 65.0002C55.2371 65.472 55.1548 65.9382 55.0999 66.4099C55.0835 66.558 55.0506 66.7171 55.067 66.8652C55.0999 67.2546 55.1109 67.6386 55.1164 68.0335C55.1164 68.22 55.1328 68.401 55.1164 68.5875C55.0945 68.785 55.1493 68.9934 55.1658 69.1909C55.2206 69.7284 55.3248 70.255 55.3413 70.7925C55.5333 71.3959 55.5113 72.0706 55.7911 72.652C55.8788 72.833 55.9392 73.0524 55.9392 73.2828C55.9501 73.8203 55.8953 73.8971 55.4674 73.7874C55.3632 73.76 55.2645 73.7216 55.1658 73.6667C55.0231 73.5954 54.9299 73.4857 54.8696 73.2608C54.7818 72.9482 54.6337 72.652 54.5021 72.3558C54.3978 72.1199 54.2827 71.8896 54.2717 71.6098C54.2662 71.5111 54.2223 71.4069 54.1894 71.3027C54.0413 70.8474 53.959 70.3757 53.959 69.871V69.8656Z"
      fill="white"
    />
    <path
      d="M59.543 69.8656C59.5046 69.5145 59.5101 69.1415 59.373 68.7905C59.3346 68.6972 59.3456 68.5491 59.362 68.4339C59.4114 68.0829 59.4553 67.7373 59.4553 67.3753C59.4553 67.3205 59.4717 67.2656 59.4553 67.2162C59.362 66.7171 59.4937 66.2289 59.5156 65.7407C59.5266 65.3952 59.6034 65.0496 59.565 64.6876C59.543 64.4846 59.5979 64.2488 59.6363 64.0349C59.7679 63.3821 59.9709 62.7184 60.289 62.0986C60.3877 61.9066 60.4974 61.7476 60.6784 61.8189C60.7827 61.8573 60.9362 61.7531 61.0569 61.8189C61.386 61.9944 61.4848 62.3345 61.3531 62.7568C61.2983 62.9488 61.216 63.1408 61.1666 63.3383C61.0679 63.7222 61.0679 64.1226 60.9033 64.5121C60.843 64.6547 60.8485 64.8357 60.8375 65.0002C60.8211 65.472 60.7388 65.9382 60.6839 66.4099C60.6675 66.558 60.6346 66.7171 60.651 66.8652C60.6839 67.2546 60.6949 67.6386 60.7004 68.0335C60.7004 68.22 60.7168 68.401 60.7004 68.5875C60.6784 68.785 60.7333 68.9934 60.7497 69.1909C60.8046 69.7284 60.9088 70.255 60.9253 70.7925C61.1172 71.3959 61.0953 72.0706 61.375 72.652C61.4628 72.833 61.5231 73.0524 61.5231 73.2828C61.5341 73.8203 61.4793 73.8971 61.0514 73.7874C60.9472 73.76 60.8485 73.7216 60.7497 73.6667C60.6071 73.5954 60.5194 73.4857 60.4535 73.2608C60.3658 72.9482 60.2177 72.652 60.086 72.3558C59.9818 72.1199 59.8721 71.8896 59.8557 71.6098C59.8502 71.5111 59.8063 71.4069 59.7734 71.3027C59.6253 70.8474 59.543 70.3757 59.543 69.871V69.8656Z"
      fill="white"
    />
    <path
      d="M65.1268 69.8656C65.0884 69.5145 65.0938 69.1415 64.9567 68.7905C64.9183 68.6972 64.9293 68.5491 64.9457 68.4339C64.9951 68.0829 65.0335 67.7373 65.039 67.3753C65.039 67.3205 65.0555 67.2656 65.039 67.2162C64.9458 66.7171 65.0774 66.2289 65.0993 65.7407C65.1103 65.3952 65.1871 65.0496 65.1487 64.6876C65.1268 64.4846 65.1761 64.2488 65.22 64.0349C65.3516 63.3821 65.5546 62.7184 65.8727 62.0986C65.9715 61.9066 66.0812 61.7476 66.2622 61.8189C66.3664 61.8573 66.52 61.7531 66.6407 61.8189C66.9698 61.9944 67.0685 62.3345 66.9368 62.7568C66.882 62.9488 66.7997 63.1408 66.7504 63.3383C66.6516 63.7222 66.6516 64.1226 66.4871 64.5121C66.4267 64.6547 66.4322 64.8357 66.4212 65.0002C66.4048 65.472 66.3225 65.9382 66.2677 66.4099C66.2512 66.558 66.2183 66.7171 66.2348 66.8652C66.2677 67.2546 66.2786 67.6386 66.2841 68.0335C66.2841 68.22 66.3006 68.401 66.2841 68.5875C66.2622 68.785 66.317 68.9934 66.3335 69.1909C66.3883 69.7284 66.4926 70.255 66.509 70.7925C66.701 71.3959 66.679 72.0706 66.9588 72.652C67.0466 72.833 67.1069 73.0524 67.1069 73.2828C67.1179 73.8203 67.063 73.8971 66.6352 73.7874C66.5309 73.76 66.4322 73.7216 66.3335 73.6667C66.1909 73.5954 66.0976 73.4857 66.0373 73.2608C65.9495 72.9482 65.8014 72.652 65.6698 72.3558C65.5656 72.1199 65.4504 71.8896 65.4394 71.6098C65.4339 71.5111 65.39 71.4069 65.3571 71.3027C65.209 70.8474 65.1268 70.3757 65.1268 69.871V69.8656Z"
      fill="white"
    />
    <path
      d="M71.8457 65.6674C71.8457 65.9527 71.8238 66.2653 71.9664 66.556C72.0048 66.6328 71.9993 66.7535 71.9829 66.8522C71.95 67.1429 71.9335 67.4391 71.9829 67.7353C71.9884 67.7792 71.9829 67.8286 71.9993 67.867C72.1694 68.2564 72.1255 68.6843 72.2407 69.0902C72.3175 69.3754 72.3449 69.6716 72.504 69.9349C72.5917 70.0775 72.6301 70.264 72.674 70.4231C72.8166 70.9112 73.0031 71.35 73.2664 71.6846C73.3487 71.7888 73.42 71.8766 73.3103 72.0357C73.2445 72.1234 73.2993 72.266 73.2225 72.3867C73.0415 72.6994 72.6191 72.7762 72.2297 72.3758C72.0597 72.2002 71.939 71.9918 71.8183 71.7888C71.5934 71.4049 71.3685 71.0319 71.3411 70.6041C71.3301 70.4505 71.2588 70.2914 71.204 70.1378C71.0559 69.71 70.9956 69.2657 70.9462 68.8214C70.9297 68.6788 70.9242 68.5307 70.8858 68.399C70.7761 68.048 70.7103 67.686 70.6774 67.313C70.661 67.1375 70.6335 66.9674 70.65 66.7864C70.661 66.5999 70.6116 66.4025 70.6006 66.205C70.5622 65.6894 70.5238 65.1628 70.6335 64.6472C70.5842 64.0219 70.8365 63.4076 70.8804 62.7713C70.8968 62.5738 70.9626 62.3435 71.0943 62.1515C71.4179 61.7072 71.5002 61.7017 71.769 62.0418C71.8348 62.1241 71.8896 62.2173 71.9445 62.3106C72.0158 62.4422 72.0268 62.5629 71.9719 62.7548C71.8896 63.0181 71.8951 63.3088 71.9006 63.5776C71.9171 63.797 71.928 64 71.8732 64.2249C71.8512 64.3071 71.8622 64.4004 71.8622 64.4827C71.8951 64.8776 71.8841 65.2561 71.8403 65.6729L71.8457 65.6674Z"
      fill="white"
    />
    <path
      d="M77.6161 64.7747C77.6435 65.0106 77.6326 65.2684 77.7862 65.4823C77.8245 65.5426 77.8245 65.6359 77.8191 65.7181C77.7971 65.954 77.7971 66.1899 77.8575 66.4148C77.8684 66.4477 77.8575 66.4861 77.8794 66.519C78.0604 66.7987 78.0549 67.1388 78.1975 67.4295C78.2963 67.6325 78.3566 67.8519 78.5431 68.0054C78.6473 68.0877 78.7022 68.2194 78.779 68.3291C78.9874 68.6691 79.2342 68.9818 79.5194 69.256C79.6072 69.3438 79.6785 69.4206 79.5798 69.5852C79.5194 69.6784 79.5633 69.81 79.492 69.9307C79.3055 70.2434 78.9654 70.3586 78.6199 70.1172C78.4718 70.0075 78.3401 69.8704 78.2085 69.7387C77.9617 69.4919 77.6764 69.256 77.5722 68.8995C77.5393 68.7734 77.4351 68.6582 77.3583 68.543C77.1389 68.2139 77.0237 67.8354 76.925 67.4679C76.8976 67.3472 76.8756 67.2211 76.8263 67.1169C76.6891 66.8316 76.6014 66.5299 76.5465 66.2228C76.5191 66.0747 76.4917 65.9375 76.4917 65.784C76.4917 65.6304 76.4368 65.4658 76.4149 65.3068C76.3545 64.8844 76.2777 64.4675 76.3271 64.0452C76.2064 63.557 76.36 63.0524 76.2284 62.5642C76.19 62.4106 76.1845 62.2351 76.2448 62.0651C76.3874 61.6701 76.4642 61.6372 76.8372 61.8182C76.925 61.8621 77.0127 61.917 77.095 61.9773C77.2102 62.0596 77.2651 62.1583 77.2705 62.3229C77.2705 62.5587 77.3473 62.7891 77.4132 63.0195C77.468 63.2005 77.5338 63.3705 77.5009 63.568C77.49 63.6393 77.5119 63.7161 77.5338 63.7874C77.6106 64.1165 77.649 64.4346 77.6271 64.7802L77.6161 64.7747Z"
      fill="white"
    />
    <path
      d="M82.1418 63.7598C82.1583 63.9463 82.1528 64.1492 82.2954 64.3193C82.3338 64.3631 82.3283 64.4399 82.3228 64.5057C82.2954 64.6977 82.2844 64.8897 82.3393 65.0707C82.3448 65.0981 82.3393 65.1311 82.3557 65.153C82.5258 65.3779 82.4929 65.6631 82.608 65.899C82.6793 66.069 82.7287 66.25 82.8768 66.3817C82.9701 66.4475 83.0085 66.5627 83.0578 66.6669C83.2114 66.9686 83.3814 67.2593 83.5789 67.539C83.6392 67.6268 83.6831 67.7091 83.546 67.8462C83.4692 67.923 83.4692 68.0491 83.3705 68.1479C83.1127 68.4112 82.8 68.466 82.5532 68.2466C82.449 68.1424 82.3667 68.0162 82.2734 67.901C82.1034 67.6761 81.8785 67.4842 81.8346 67.177C81.8182 67.0673 81.7359 66.974 81.6756 66.8808C81.5 66.6065 81.4287 66.2939 81.3574 65.9922C81.3355 65.8935 81.33 65.7893 81.2806 65.7015C81.16 65.4711 81.0887 65.2243 81.0393 64.9665C81.0174 64.8458 80.9899 64.7306 80.9899 64.6045C80.9954 64.4728 80.946 64.3412 80.9186 64.2095C80.8638 63.8585 80.7979 63.4965 80.8528 63.1399C80.7596 62.7121 80.9296 62.2843 80.8693 61.84C80.8473 61.6974 80.8693 61.5383 80.957 61.4012C81.1655 61.083 81.2422 61.0775 81.5768 61.3189C81.6591 61.3737 81.7304 61.4396 81.8017 61.5054C81.9005 61.5986 81.9389 61.6864 81.9169 61.8125C81.9005 61.999 81.9389 62.191 81.9937 62.3775C82.0376 62.5256 82.0815 62.6627 82.0486 62.8108C82.0376 62.8657 82.0595 62.926 82.0705 62.9809C82.1363 63.2387 82.1583 63.4855 82.1308 63.7543L82.1418 63.7598Z"
      fill="white"
    />
    <path
      d="M96.7539 58.7949C96.7868 58.8771 96.7813 58.9814 96.9294 59.0307C96.9678 59.0472 96.9623 59.0856 96.9514 59.1185C96.9184 59.2227 96.902 59.3214 96.9294 59.4092C96.9294 59.4202 96.9294 59.4421 96.9404 59.4476C97.0775 59.5354 97.0062 59.6944 97.0611 59.8096C97.0995 59.8919 97.0885 59.9906 97.1982 60.0455C97.2585 60.0729 97.264 60.1387 97.275 60.1881C97.3134 60.3472 97.3463 60.5062 97.3847 60.6763C97.3956 60.7256 97.3902 60.775 97.2146 60.8573C97.1159 60.9012 97.0556 60.9725 96.9294 61.0328C96.5948 61.1809 96.3151 61.2248 96.2218 61.1096C96.178 61.0602 96.167 60.9944 96.1341 60.9396C96.0792 60.8299 95.9585 60.7476 96.0134 60.594C96.0353 60.5391 95.9915 60.5007 95.964 60.4569C95.8818 60.3362 95.8872 60.1936 95.8763 60.051C95.8763 60.0071 95.8763 59.9577 95.8543 59.9193C95.7775 59.8261 95.7337 59.7219 95.7007 59.6176C95.6843 59.5683 95.6678 59.5189 95.6733 59.4586C95.6788 59.3982 95.6294 59.3489 95.6075 59.294C95.5472 59.1514 95.4484 59.0143 95.4594 58.8497C95.3003 58.7016 95.3826 58.4877 95.1851 58.3451C95.1248 58.3012 95.0974 58.2354 95.1303 58.1641C95.2071 57.9886 95.2729 57.9611 95.6678 57.9337C95.7611 57.9282 95.8543 57.9282 95.9421 57.9282C96.0628 57.9282 96.1341 57.9502 96.1615 58.005C96.1999 58.0873 96.3041 58.1476 96.3974 58.208C96.4741 58.2573 96.5564 58.3012 96.5509 58.378C96.5509 58.4054 96.5784 58.4274 96.6058 58.4493C96.7155 58.548 96.7758 58.6523 96.7649 58.7894L96.7539 58.7949Z"
      fill="white"
    />
    <path
      d="M101.147 43.7936C101.186 43.7881 101.219 43.8265 101.268 43.7058C101.279 43.6729 101.295 43.6948 101.301 43.7113C101.328 43.7771 101.356 43.8265 101.394 43.8265C101.394 43.8265 101.405 43.8429 101.411 43.8319C101.471 43.7387 101.509 43.8594 101.559 43.8484C101.592 43.8429 101.625 43.8923 101.668 43.8155C101.69 43.7716 101.712 43.7935 101.734 43.799C101.795 43.8265 101.855 43.8594 101.915 43.8978C101.932 43.9087 101.948 43.9307 101.932 44.1281C101.921 44.2378 101.932 44.3201 101.915 44.4572C101.877 44.8248 101.822 45.088 101.767 45.1264C101.74 45.1429 101.718 45.1264 101.696 45.1374C101.652 45.1429 101.597 45.2197 101.564 45.11C101.553 45.0716 101.531 45.0935 101.509 45.1045C101.455 45.1319 101.411 45.0771 101.367 45.0332C101.35 45.0222 101.339 44.9948 101.323 45.0112C101.279 45.0496 101.235 45.0551 101.197 45.0496C101.18 45.0496 101.158 45.0496 101.142 45.0222C101.125 44.9948 101.098 45.0277 101.076 45.0332C101.016 45.0442 100.955 45.0935 100.906 45.0332C100.829 45.1429 100.774 45.0003 100.692 45.1539C100.665 45.2032 100.643 45.2142 100.621 45.1593C100.577 45.0387 100.582 44.9674 100.643 44.5834C100.659 44.4902 100.676 44.4024 100.692 44.3201C100.714 44.2049 100.736 44.1391 100.758 44.1281C100.791 44.1117 100.835 44.0294 100.873 43.9581C100.906 43.8978 100.939 43.8319 100.961 43.8594C100.972 43.8703 100.983 43.8429 100.994 43.8265C101.049 43.7497 101.098 43.7277 101.147 43.7771V43.7936Z"
      fill="white"
    />
    <path
      d="M93.9956 31.3299C94.0394 31.2751 94.1162 31.2531 94.0833 31.116C94.0723 31.0776 94.1053 31.0721 94.1382 31.0721C94.2259 31.0721 94.3082 31.0611 94.3576 31.0118C94.3631 31.0063 94.385 31.0118 94.385 30.9953C94.385 30.8582 94.5386 30.8801 94.5989 30.8033C94.6428 30.7485 94.7251 30.743 94.7141 30.6333C94.7141 30.573 94.758 30.5565 94.7909 30.5346C94.8951 30.4687 95.0048 30.4029 95.1145 30.3426C95.1474 30.3261 95.1913 30.3206 95.323 30.4578C95.3997 30.5346 95.4765 30.5784 95.5698 30.6772C95.8221 30.9459 95.9647 31.1818 95.9208 31.2805C95.9044 31.3244 95.8605 31.3464 95.8331 31.3848C95.7782 31.4506 95.7727 31.5658 95.6356 31.5493C95.5862 31.5438 95.5753 31.5877 95.5588 31.6151C95.5094 31.7029 95.4052 31.7303 95.312 31.7687C95.2791 31.7797 95.2407 31.7852 95.2297 31.8181C95.2023 31.9004 95.1529 31.9662 95.0926 32.0155C95.0652 32.0375 95.0432 32.0704 94.9993 32.0759C94.9555 32.0814 94.9445 32.1417 94.9225 32.1746C94.8567 32.2624 94.8128 32.3776 94.6977 32.4105C94.6812 32.5805 94.4947 32.5695 94.5112 32.778C94.5112 32.8438 94.4892 32.8822 94.4234 32.8767C94.2698 32.8657 94.2095 32.8219 93.9791 32.5037C93.9243 32.4269 93.8749 32.3556 93.8255 32.2788C93.7597 32.1746 93.7378 32.1143 93.7597 32.0704C93.7926 32.0155 93.7816 31.9058 93.7761 31.8071C93.7761 31.7303 93.7597 31.6426 93.8145 31.6261C93.8365 31.6206 93.831 31.5877 93.8365 31.5603C93.8475 31.4396 93.8968 31.3573 93.9956 31.3244V31.3299Z"
      fill="white"
    />
    <path
      d="M81.188 24.6392C81.1771 24.6063 81.21 24.5679 81.0893 24.5295C81.0564 24.5185 81.0729 24.5075 81.0893 24.4966C81.1496 24.4636 81.199 24.4252 81.1935 24.3923C81.1935 24.3923 81.21 24.3814 81.1935 24.3759C81.0948 24.3265 81.21 24.2771 81.1935 24.2278C81.1826 24.1949 81.2319 24.1565 81.1497 24.1236C81.1058 24.1016 81.1222 24.0797 81.1277 24.0577C81.1496 23.9919 81.1771 23.9316 81.2045 23.8658C81.2155 23.8438 81.2374 23.8274 81.4294 23.8274C81.5391 23.8274 81.6214 23.8109 81.7585 23.8109C82.126 23.8109 82.4003 23.8383 82.4441 23.8877C82.4661 23.9096 82.4551 23.9316 82.4606 23.959C82.4716 24.0029 82.5538 24.0468 82.4496 24.0907C82.4112 24.1071 82.4387 24.1236 82.4496 24.1455C82.4825 24.1949 82.4332 24.2442 82.3948 24.2936C82.3838 24.3101 82.3619 24.3265 82.3783 24.343C82.4222 24.3814 82.4332 24.4252 82.4277 24.4636C82.4277 24.4856 82.4277 24.502 82.4057 24.524C82.3783 24.5459 82.4167 24.5679 82.4222 24.5898C82.4387 24.6447 82.499 24.6995 82.4441 24.7598C82.5593 24.8257 82.4277 24.8915 82.5868 24.9573C82.6361 24.9792 82.6526 25.0012 82.6032 25.0231C82.488 25.078 82.4167 25.0835 82.0273 25.0615C81.934 25.056 81.8463 25.0506 81.7585 25.0451C81.6378 25.0341 81.5775 25.0231 81.561 24.9957C81.5391 24.9628 81.4513 24.9299 81.3745 24.897C81.3142 24.8695 81.2429 24.8476 81.2703 24.8202C81.2813 24.8092 81.2539 24.7982 81.2374 24.7873C81.1551 24.7379 81.1277 24.694 81.1716 24.6447L81.188 24.6392Z"
      fill="white"
    />
    <path
      d="M66.3342 26.6241C66.2903 26.5857 66.2794 26.5199 66.1422 26.5473C66.1039 26.5528 66.1039 26.5254 66.1093 26.498C66.1203 26.4157 66.1258 26.3389 66.0874 26.295C66.0819 26.2895 66.0874 26.2731 66.0764 26.2731C65.9448 26.2676 65.9942 26.1304 65.9393 26.0756C65.8954 26.0372 65.9064 25.9604 65.8077 25.9604C65.7528 25.9604 65.7473 25.9165 65.7363 25.8836C65.698 25.7849 65.665 25.6807 65.6431 25.571C65.6376 25.5381 65.6431 25.4997 65.8077 25.4064C65.9009 25.3516 65.9612 25.2912 66.0819 25.2199C66.4055 25.0389 66.6578 24.9457 66.7292 24.9786C66.7621 24.995 66.7676 25.0279 66.7895 25.0499C66.8334 25.0938 66.9321 25.0992 66.8718 25.2089C66.8498 25.2473 66.8882 25.2583 66.9102 25.2693C66.976 25.3077 66.9705 25.39 66.976 25.4667C66.976 25.4887 66.976 25.5216 66.9979 25.5326C67.0638 25.5545 67.1076 25.5874 67.1405 25.6368C67.157 25.6587 67.1735 25.6752 67.1735 25.7136C67.1735 25.752 67.2173 25.752 67.2393 25.7684C67.2996 25.8178 67.3929 25.8397 67.4038 25.9275C67.5464 25.9165 67.5135 26.0756 67.6891 26.0262C67.7439 26.0098 67.7768 26.0262 67.7604 26.0811C67.7329 26.2127 67.6891 26.2731 67.3929 26.5254C67.3215 26.5857 67.2502 26.6406 67.1844 26.6954C67.0912 26.7667 67.0308 26.7996 66.9924 26.7832C66.9431 26.7612 66.8443 26.7832 66.7566 26.7996C66.6853 26.8106 66.603 26.8325 66.592 26.7777C66.592 26.7612 66.5591 26.7612 66.5317 26.7612C66.422 26.7612 66.3452 26.7174 66.3287 26.6241H66.3342Z"
      fill="white"
    />
    <path
      d="M63.8885 42.1478C63.9324 42.1258 63.9872 42.1478 63.9982 42.0106C63.9982 41.9777 64.0256 41.9887 64.0421 41.9997C64.1024 42.0435 64.1573 42.0764 64.2067 42.0655C64.2121 42.0655 64.2231 42.0764 64.2286 42.0655C64.2615 41.9503 64.3602 42.0435 64.4151 42.0161C64.4535 41.9942 64.5138 42.0271 64.5358 41.9338C64.5467 41.8845 64.5796 41.89 64.6125 41.89C64.7003 41.89 64.7881 41.89 64.8758 41.89C64.9033 41.89 64.9307 41.9064 64.9855 42.0929C65.0184 42.1971 65.0623 42.2684 65.1007 42.4056C65.2049 42.7566 65.2433 43.0309 65.194 43.0912C65.172 43.1186 65.1391 43.1186 65.1117 43.1296C65.0568 43.157 65.0239 43.2503 64.9417 43.168C64.9087 43.1351 64.8923 43.168 64.8758 43.1844C64.821 43.2338 64.7442 43.2009 64.6729 43.1844C64.6509 43.179 64.6235 43.1625 64.6071 43.1844C64.5687 43.2393 64.5193 43.2667 64.4645 43.2777C64.437 43.2777 64.4151 43.2941 64.3877 43.2777C64.3547 43.2612 64.3383 43.3051 64.3109 43.3161C64.245 43.3545 64.1902 43.4313 64.1024 43.3984C64.0531 43.53 63.9269 43.4313 63.8885 43.6068C63.8775 43.6616 63.8501 43.6836 63.8062 43.6452C63.702 43.5574 63.6801 43.4916 63.5978 43.1076C63.5813 43.0144 63.5649 42.9266 63.5484 42.8444C63.5265 42.7237 63.5265 42.6579 63.5484 42.6359C63.5868 42.603 63.6033 42.5098 63.6197 42.422C63.6362 42.3562 63.6472 42.2739 63.691 42.2904C63.7075 42.2904 63.713 42.2684 63.724 42.2465C63.7624 42.1532 63.8117 42.1039 63.8885 42.1313V42.1478Z"
      fill="white"
    />
    <path
      d="M84.5944 81.7048C84.6328 81.661 84.7041 81.65 84.6712 81.5129C84.6602 81.4745 84.6932 81.4745 84.7206 81.4799C84.8029 81.4909 84.8796 81.4909 84.9235 81.4525C84.929 81.447 84.9455 81.4525 84.9455 81.4415C84.9455 81.3099 85.0826 81.3538 85.1374 81.288C85.1758 81.2441 85.2526 81.2441 85.2472 81.1454C85.2472 81.0905 85.2855 81.0795 85.3185 81.0631C85.4172 81.0137 85.5159 80.9698 85.6147 80.9259C85.6476 80.915 85.6805 80.915 85.8066 81.0631C85.8779 81.1453 85.9492 81.1947 86.037 81.3044C86.2674 81.5896 86.4045 81.831 86.3606 81.9188C86.3442 81.9572 86.3058 81.9736 86.2783 82.001C86.229 82.0559 86.2235 82.1656 86.0973 82.1272C86.0535 82.1162 86.0425 82.1546 86.026 82.182C85.9822 82.2588 85.8834 82.2698 85.7957 82.2917C85.7682 82.2972 85.7298 82.2917 85.7244 82.3247C85.6969 82.4014 85.6531 82.4508 85.5982 82.4892C85.5708 82.5057 85.5488 82.5331 85.5104 82.5331C85.4666 82.5331 85.4611 82.5879 85.4391 82.6154C85.3788 82.6867 85.3404 82.7909 85.2362 82.8073C85.2197 82.9664 85.0497 82.9335 85.0661 83.1255C85.0661 83.1858 85.0497 83.2187 84.9839 83.2078C84.8412 83.1748 84.7919 83.1255 84.578 82.8019C84.5286 82.7196 84.4792 82.6428 84.4353 82.5715C84.375 82.4673 84.3531 82.4014 84.375 82.3685C84.4079 82.3192 84.3915 82.2149 84.386 82.1272C84.386 82.0559 84.3695 81.9736 84.4189 81.9626C84.4353 81.9626 84.4353 81.9297 84.4408 81.9023C84.4518 81.7871 84.4957 81.7158 84.5889 81.6993L84.5944 81.7048Z"
      fill="white"
    />
    <path
      d="M97.8679 75.9856C97.8898 75.9307 97.9502 75.9088 97.8844 75.7881C97.8679 75.7552 97.8953 75.7497 97.9227 75.7442C98.005 75.7388 98.0763 75.7278 98.1092 75.6839C98.1092 75.6784 98.1312 75.6839 98.1312 75.6675C98.1038 75.5413 98.2464 75.5632 98.2903 75.5029C98.3232 75.459 98.3945 75.459 98.378 75.3603C98.3725 75.3054 98.4109 75.2945 98.4384 75.278C98.5316 75.2286 98.6248 75.1903 98.7236 75.1573C98.7565 75.1464 98.7894 75.1519 98.8991 75.3109C98.9594 75.4042 99.0253 75.459 99.1021 75.5742C99.2995 75.8814 99.4257 76.1282 99.4147 76.1885C99.4147 76.216 99.3873 76.2215 99.3763 76.2379C99.3544 76.2708 99.3763 76.3586 99.2776 76.3037C99.2447 76.2873 99.2447 76.3147 99.2392 76.3366C99.2282 76.3915 99.1624 76.386 99.1021 76.3915C99.0856 76.3915 99.0527 76.3915 99.0582 76.4134C99.0637 76.4738 99.0472 76.5067 99.0253 76.5341C99.0143 76.5451 99.0088 76.567 98.9759 76.567C98.943 76.567 98.9649 76.6054 98.954 76.6274C98.9375 76.6822 98.9594 76.7535 98.8881 76.77C98.9594 76.8742 98.8113 76.8687 98.9375 76.9894C98.9759 77.0278 98.9814 77.0497 98.9265 77.0497C98.8059 77.0497 98.7345 77.0332 98.378 76.8742C98.2957 76.8358 98.2135 76.7974 98.1367 76.7535C98.0325 76.6987 97.9776 76.6548 97.9776 76.6219C97.9776 76.5725 97.9173 76.4957 97.8679 76.4244C97.8295 76.3641 97.7801 76.2982 97.824 76.2763C97.8405 76.2653 97.824 76.2434 97.8185 76.216C97.7801 76.1118 97.7966 76.0295 97.8789 75.9911L97.8679 75.9856Z"
      fill="white"
    />
    <path
      d="M84.7421 94.7378C84.7421 94.7378 84.7915 94.7598 84.8134 94.6391C84.8189 94.6062 84.8299 94.6227 84.8354 94.6391C84.8573 94.6995 84.8792 94.7488 84.9012 94.7433C84.9012 94.7433 84.9067 94.7598 84.9121 94.7433C84.9451 94.6446 84.978 94.7598 85.0054 94.7433C85.0273 94.7324 85.0493 94.7817 85.0712 94.6995C85.0822 94.6556 85.0986 94.672 85.1151 94.6775C85.159 94.6995 85.1974 94.7269 85.2413 94.7543C85.2522 94.7653 85.2632 94.7872 85.2687 94.9792C85.2687 95.0889 85.2742 95.1712 85.2797 95.3083C85.2797 95.6758 85.2632 95.9501 85.2303 95.9939C85.2138 96.0159 85.2029 96.0049 85.1864 96.0104C85.159 96.0214 85.1316 96.1036 85.0986 95.9994C85.0877 95.961 85.0767 95.9885 85.0657 95.9994C85.0328 96.0323 84.9999 95.983 84.967 95.9446C84.956 95.9336 84.9451 95.9117 84.9341 95.9281C84.9067 95.972 84.8792 95.983 84.8573 95.9775C84.8463 95.9775 84.8354 95.9775 84.8189 95.9555C84.8079 95.9281 84.7915 95.9665 84.775 95.972C84.7366 95.9885 84.7037 96.0488 84.6653 95.9939C84.6214 96.1091 84.5776 95.9775 84.5337 96.1366C84.5227 96.1859 84.5062 96.2024 84.4898 96.153C84.4514 96.0378 84.4514 95.9665 84.4624 95.5771C84.4624 95.4838 84.4733 95.3961 84.4733 95.3083C84.4788 95.1876 84.4898 95.1273 84.5062 95.1108C84.5282 95.0889 84.5501 95.0011 84.5666 94.9243C84.583 94.864 84.5995 94.7927 84.6159 94.8201C84.6214 94.8311 84.6269 94.8037 84.6379 94.7872C84.6708 94.7049 84.6982 94.6775 84.7311 94.7214L84.7421 94.7378Z"
      fill="white"
    />
    <path
      d="M99.0959 92.1282C99.0959 92.1282 99.1123 92.1502 99.1233 92.0295C99.1233 91.9966 99.1288 92.013 99.1343 92.0295C99.1453 92.0898 99.1507 92.1392 99.1617 92.1337C99.1727 92.035 99.1891 92.1502 99.2001 92.1337C99.2111 92.1227 99.2166 92.1721 99.2275 92.0898C99.2275 92.046 99.2385 92.0624 99.244 92.0679C99.2604 92.0898 99.2769 92.1173 99.2934 92.1447C99.2934 92.1557 99.3043 92.1776 99.3043 92.3696C99.3043 92.4793 99.3043 92.5616 99.3043 92.6987C99.3043 93.0662 99.2988 93.3404 99.2824 93.3843C99.2769 93.4063 99.2714 93.3953 99.2659 93.4008C99.255 93.4117 99.244 93.494 99.233 93.3898C99.233 93.3514 99.2221 93.3788 99.2221 93.3898C99.2111 93.4227 99.1946 93.3734 99.1837 93.335C99.1837 93.324 99.1727 93.3021 99.1727 93.3185C99.1617 93.3624 99.1507 93.3734 99.1398 93.3679C99.1398 93.3679 99.1288 93.3679 99.1233 93.3459C99.1233 93.3185 99.1123 93.3569 99.1069 93.3624C99.0904 93.3788 99.0794 93.4392 99.063 93.3843C99.0465 93.4995 99.0301 93.3679 99.0136 93.5269C99.0136 93.5763 99.0026 93.5928 98.9972 93.5434C98.9807 93.4282 98.9807 93.3569 98.9862 92.9675C98.9862 92.8742 98.9862 92.7864 98.9862 92.6987C98.9862 92.578 98.9917 92.5177 98.9972 92.5012C99.0081 92.4793 99.0136 92.3915 99.0246 92.3147C99.0301 92.2544 99.0356 92.1831 99.0465 92.2105C99.0465 92.2215 99.052 92.1941 99.0575 92.1776C99.0685 92.0953 99.0794 92.0679 99.0959 92.1118V92.1282Z"
      fill="white"
    />
    <path
      d="M64.3978 87.3942C64.3978 87.3942 64.4527 87.4162 64.4801 87.2955C64.4856 87.2626 64.4966 87.279 64.5075 87.2955C64.535 87.3558 64.5624 87.4052 64.5898 87.3997C64.5898 87.3997 64.6008 87.4162 64.6008 87.3997C64.6392 87.301 64.6776 87.4162 64.716 87.3997C64.7434 87.3887 64.7708 87.4381 64.7982 87.3558C64.8147 87.3119 64.8312 87.3284 64.8476 87.3339C64.897 87.3558 64.9463 87.3833 64.9957 87.4107C65.0122 87.4216 65.0231 87.4436 65.0286 87.6356C65.0286 87.7453 65.0396 87.8275 65.0451 87.9647C65.0451 88.3322 65.0231 88.6064 64.9847 88.6503C64.9683 88.6723 64.9463 88.6613 64.9299 88.6668C64.897 88.6777 64.8641 88.76 64.8257 88.6558C64.8147 88.6174 64.7982 88.6448 64.7873 88.6558C64.7489 88.6887 64.7105 88.6393 64.6721 88.601C64.6611 88.59 64.6447 88.568 64.6337 88.5845C64.6008 88.6284 64.5734 88.6393 64.5404 88.6339C64.524 88.6339 64.513 88.6339 64.4966 88.6119C64.4801 88.5845 64.4637 88.6229 64.4472 88.6284C64.4033 88.6448 64.3594 88.7052 64.3156 88.6503C64.2662 88.7655 64.2113 88.6339 64.162 88.7929C64.1455 88.8423 64.1291 88.8588 64.1071 88.8094C64.0632 88.6942 64.0578 88.6229 64.0742 88.2334C64.0742 88.1402 64.0852 88.0524 64.0907 87.9647C64.0961 87.844 64.1071 87.7837 64.1291 87.7672C64.1565 87.7453 64.1784 87.6575 64.2058 87.5807C64.2278 87.5204 64.2442 87.4491 64.2662 87.4765C64.2717 87.4875 64.2826 87.46 64.2881 87.4436C64.3265 87.3613 64.3594 87.3339 64.4033 87.3778L64.3978 87.3942Z"
      fill="white"
    />
    <path
      d="M45.2655 93.2584C45.3094 93.2639 45.3313 93.3132 45.4246 93.22C45.452 93.1926 45.4575 93.22 45.463 93.2419C45.4739 93.3132 45.4849 93.3736 45.5233 93.3955C45.5288 93.3955 45.5233 93.412 45.5398 93.4065C45.6385 93.3461 45.633 93.4778 45.6879 93.4942C45.7263 93.5052 45.7317 93.571 45.814 93.5272C45.8579 93.5052 45.8689 93.5326 45.8853 93.5546C45.9347 93.6149 45.9731 93.6862 46.0115 93.7575C46.0225 93.7795 46.0225 93.8124 45.8908 93.955C45.8195 94.0373 45.7756 94.1086 45.6769 94.2073C45.4246 94.4761 45.2216 94.6626 45.1723 94.6735C45.1503 94.679 45.1448 94.6626 45.1284 94.6571C45.101 94.6516 45.0296 94.6955 45.0735 94.5967C45.09 94.5584 45.0626 94.5748 45.0461 94.5748C45.0022 94.5858 45.0077 94.5254 44.9967 94.4761C44.9967 94.4596 44.9967 94.4322 44.9803 94.4432C44.9364 94.4651 44.909 94.4651 44.887 94.4486C44.8761 94.4432 44.8651 94.4432 44.8651 94.4157C44.8651 94.3883 44.8377 94.4157 44.8212 94.4157C44.7828 94.4157 44.728 94.4596 44.7115 94.3993C44.6347 94.4925 44.6347 94.3554 44.5579 94.5035C44.536 94.5474 44.5195 94.5584 44.5195 94.5035C44.5195 94.3883 44.5195 94.317 44.6183 93.9385C44.6457 93.8508 44.6676 93.763 44.6896 93.6807C44.728 93.5655 44.7554 93.5052 44.7773 93.4997C44.8157 93.4888 44.8706 93.412 44.9254 93.3461C44.9693 93.2968 45.0187 93.2364 45.0406 93.2693C45.0461 93.2803 45.068 93.2639 45.0845 93.2474C45.1613 93.1871 45.2271 93.1816 45.2655 93.2474V93.2584Z"
      fill="white"
    />
    <path
      d="M50.3615 87.5584C50.3615 87.5584 50.4163 87.5803 50.4437 87.4597C50.4492 87.4268 50.4602 87.4432 50.4712 87.4597C50.4986 87.52 50.526 87.5694 50.5534 87.5639C50.5534 87.5639 50.5644 87.5803 50.5644 87.5639C50.6028 87.4652 50.6412 87.5803 50.6796 87.5639C50.707 87.5529 50.7344 87.6023 50.7619 87.52C50.7783 87.4761 50.7948 87.4926 50.8112 87.4981C50.8606 87.52 50.91 87.5474 50.9593 87.5749C50.9758 87.5858 50.9868 87.6078 50.9922 87.7998C50.9922 87.9095 51.0032 87.9917 51.0087 88.1289C51.0087 88.4964 50.9868 88.7706 50.9484 88.8145C50.9319 88.8364 50.91 88.8255 50.8935 88.831C50.8606 88.8419 50.8277 88.9242 50.7893 88.82C50.7783 88.7816 50.7619 88.809 50.7509 88.82C50.7125 88.8529 50.6741 88.8035 50.6357 88.7651C50.6247 88.7542 50.6083 88.7322 50.5973 88.7487C50.5644 88.7926 50.537 88.8035 50.5041 88.798C50.4876 88.798 50.4766 88.798 50.4602 88.7761C50.4437 88.7487 50.4273 88.7871 50.4108 88.7926C50.3669 88.809 50.3231 88.8693 50.2792 88.8145C50.2298 88.9297 50.175 88.798 50.1256 88.9571C50.1091 89.0065 50.0927 89.0229 50.0707 88.9736C50.0269 88.8584 50.0214 88.7871 50.0378 88.3976C50.0378 88.3044 50.0488 88.2166 50.0543 88.1289C50.0653 88.0082 50.0707 87.9479 50.0927 87.9314C50.1201 87.9095 50.142 87.8217 50.1695 87.7449C50.1914 87.6846 50.2079 87.6133 50.2298 87.6407C50.2353 87.6517 50.2463 87.6242 50.2517 87.6078C50.2901 87.5255 50.3231 87.4981 50.3669 87.542L50.3615 87.5584Z"
      fill="white"
    />
    <path
      d="M17.895 74.4275C17.9827 74.4165 18.076 74.4495 18.1473 74.3178C18.1637 74.2849 18.1967 74.2959 18.2241 74.3178C18.3064 74.3672 18.3941 74.4001 18.4654 74.3782C18.4764 74.3782 18.4929 74.3836 18.4983 74.3782C18.5642 74.252 18.6958 74.3233 18.7836 74.263C18.8384 74.2191 18.9262 74.2191 18.9262 74.1094C18.9262 74.049 18.9646 74.0381 18.9975 74.0161C19.0907 73.9558 19.1675 73.8845 19.2279 73.8077C19.2498 73.7858 19.2717 73.7693 19.4637 73.8077C19.5679 73.8351 19.6612 73.8132 19.7983 73.8406C20.1548 73.9009 20.4126 74.071 20.3633 74.3123C20.3413 74.4165 20.281 74.5043 20.2316 74.5921C20.1384 74.7621 20.0671 74.9705 19.8587 75.0199C19.7819 75.0364 19.7435 75.1077 19.6886 75.157C19.546 75.3106 19.3431 75.3655 19.1511 75.4094C19.0907 75.4203 19.0249 75.4258 18.9755 75.4587C18.8439 75.552 18.6848 75.6068 18.5313 75.6288C18.4599 75.6397 18.3886 75.6562 18.3118 75.6397C18.2351 75.6233 18.1583 75.6672 18.0815 75.6726C17.8785 75.7001 17.6756 75.7439 17.4836 75.6726C17.2422 75.7659 17.0338 75.5958 16.798 75.7165C16.7266 75.7549 16.6444 75.7494 16.5785 75.6891C16.4305 75.541 16.4305 75.4697 16.5676 75.1022C16.6005 75.0144 16.6389 74.9322 16.6773 74.8499C16.7321 74.7402 16.7815 74.6853 16.8583 74.6853C16.957 74.6853 17.0722 74.614 17.1764 74.5592C17.2587 74.5098 17.341 74.4495 17.4123 74.4879C17.4397 74.4988 17.4726 74.4824 17.5055 74.4659C17.6427 74.4001 17.7633 74.3782 17.8895 74.4275H17.895Z"
      fill="white"
    />
    <path
      d="M30.6918 86.5705C30.6753 86.8228 30.5985 87.0806 30.6479 87.3658C30.6643 87.4426 30.615 87.5468 30.5711 87.6181C30.4394 87.8485 30.2913 88.0624 30.1816 88.2983C30.1652 88.3312 30.1323 88.3641 30.1213 88.397C30.039 88.7645 29.7319 89.0059 29.5179 89.2801C29.3644 89.4831 29.1504 89.6202 29.0243 89.8506C28.9475 89.9767 28.8049 90.07 28.6732 90.1578C28.2838 90.421 27.856 90.6185 27.4117 90.7556C27.2745 90.7995 27.1429 90.816 27.0716 90.635C27.0277 90.5307 26.9015 90.4869 26.8577 90.3552C26.748 89.9987 26.8302 89.7025 27.0606 89.5654C27.1648 89.4995 27.2855 89.4557 27.3952 89.3898C27.6036 89.2637 27.7517 89.0717 28.0041 89.0059C28.0918 88.9785 28.1631 88.8962 28.2235 88.8194C28.399 88.611 28.6129 88.4464 28.8049 88.2434C28.8652 88.1776 28.9365 88.1173 28.9639 88.035C29.0517 87.8156 29.134 87.6181 29.2163 87.4152C29.2601 87.3165 29.2821 87.2177 29.3369 87.1245C29.3918 87.0203 29.3863 86.8941 29.3973 86.7789C29.4357 86.4718 29.3973 86.1646 29.4412 85.8574C29.315 85.5119 29.3698 85.1169 29.1066 84.8317C29.0243 84.7494 28.9694 84.6397 28.9585 84.5081C28.9365 84.2009 28.9749 84.1241 29.3753 84.0528C29.4741 84.0364 29.5728 84.0309 29.666 84.0364C29.8087 84.0418 29.8964 84.0967 29.9732 84.2338C30.0719 84.4313 30.2255 84.6068 30.3517 84.8043C30.4559 84.9579 30.5601 85.1169 30.5601 85.3199C30.5601 85.3912 30.5985 85.468 30.6205 85.5393C30.7356 85.8629 30.7686 86.2194 30.6808 86.5815L30.6918 86.5705Z"
      fill="white"
    />
    <path
      d="M26.1059 42.6298C26.1937 42.7011 26.2431 42.8163 26.4131 42.8328C26.4625 42.8328 26.4734 42.8876 26.4789 42.9315C26.4954 43.0577 26.5118 43.1783 26.5667 43.2771C26.5777 43.2935 26.5667 43.3155 26.5886 43.3264C26.7532 43.4252 26.7038 43.6226 26.7642 43.7652C26.8135 43.8695 26.7861 43.9901 26.8958 44.0834C26.9507 44.1382 26.9452 44.2205 26.9452 44.2918C26.9452 44.5057 26.9232 44.7251 26.8629 44.9336C26.8464 44.9994 26.8135 45.0488 26.6215 45.0213C26.5118 45.0049 26.4241 45.0378 26.287 45.0049C25.9249 44.9281 25.6671 44.8184 25.6397 44.7361C25.6233 44.6977 25.6342 44.6593 25.6287 44.6209C25.6123 44.5441 25.5355 44.4838 25.6287 44.396C25.6616 44.3631 25.6342 44.3302 25.6123 44.3028C25.5629 44.226 25.5849 44.1273 25.5903 44.0285C25.5903 43.9956 25.5958 43.9572 25.5739 43.9408C25.5026 43.8914 25.4587 43.8311 25.4203 43.7652C25.4038 43.7323 25.3764 43.7104 25.3764 43.6665C25.3764 43.6226 25.3161 43.6117 25.2887 43.5842C25.2064 43.5129 25.0967 43.491 25.0583 43.3868C24.8992 43.4142 24.8718 43.2332 24.7072 43.3319C24.6579 43.3648 24.625 43.3593 24.6085 43.3045C24.5701 43.1729 24.5646 43.0906 24.7182 42.734C24.7566 42.6463 24.795 42.5695 24.8444 42.4927C24.9102 42.3885 24.965 42.3391 25.0309 42.3501C25.1186 42.372 25.2448 42.3227 25.3655 42.3062C25.4587 42.2952 25.5684 42.2678 25.6287 42.3446C25.6507 42.372 25.6946 42.372 25.7275 42.3775C25.8975 42.3994 26.0401 42.4872 26.1169 42.6353L26.1059 42.6298Z"
      fill="white"
    />
    <path
      d="M40.0117 36.646C40.0337 36.8545 40.0062 37.0739 40.1214 37.2933C40.1543 37.3536 40.1324 37.4414 40.1105 37.5127C40.0392 37.7211 39.9679 37.9241 39.9349 38.149C39.9295 38.1819 39.9075 38.2148 39.913 38.2477C39.9514 38.5713 39.7594 38.8456 39.6607 39.1308C39.5949 39.3392 39.4577 39.5148 39.4248 39.7506C39.4029 39.8823 39.3096 40.0029 39.2274 40.1126C38.975 40.4472 38.6624 40.7489 38.3004 40.9793C38.1907 41.0561 38.0755 41.0945 37.9603 40.9409C37.8945 40.8531 37.7683 40.8367 37.697 40.716C37.505 40.3979 37.4831 40.1071 37.6257 39.9426C37.686 39.8658 37.7683 39.8055 37.8341 39.7287C37.9603 39.5806 38.0097 39.3831 38.2016 39.2679C38.2675 39.224 38.2949 39.1363 38.3223 39.0595C38.3991 38.8401 38.5307 38.6481 38.635 38.4287C38.6679 38.3574 38.7118 38.2916 38.7118 38.2093C38.7118 38.0063 38.7337 37.8198 38.7556 37.6169C38.7666 37.5237 38.7666 37.4304 38.794 37.3317C38.8215 37.2329 38.794 37.1178 38.7776 37.0135C38.7556 36.7283 38.6788 36.4541 38.6953 36.1579C38.5417 35.8397 38.613 35.4612 38.3936 35.1431C38.3223 35.0444 38.2894 34.9237 38.3113 34.7866C38.3662 34.4684 38.4265 34.4246 38.8324 34.463C38.9312 34.4739 39.0244 34.4904 39.1122 34.5123C39.2383 34.5452 39.3151 34.6001 39.3535 34.7262C39.4084 34.9018 39.5236 35.0553 39.6333 35.2199C39.7155 35.3461 39.8088 35.4722 39.8088 35.6313C39.8088 35.6916 39.8417 35.7465 39.8691 35.8013C39.9843 36.0591 40.0446 36.3334 40.0172 36.6351L40.0117 36.646Z"
      fill="white"
    />
  </svg>
)

export default ScorpioSticker
