import { startCase } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { Modal, ModalBody } from 'components/modal'
import * as consts from 'registry/constants'
import { CANCEL_ORDER } from 'order/constants'
import { setActiveModal } from 'order/actions'
import { getActiveModal, getActiveModalProps } from 'order/reducers'

import AddressSuggestionForm from '../forms/address-suggestion-form'
import ConfirmAddressForm from '../forms/confirm-address-form'
import ConnectedCancelOrderForm from '../forms/connected-cancel-order-form'

class ModalManager extends Component {
  static propTypes = {
    activeModal: PropTypes.string,
    setActiveModal: PropTypes.func.isRequired,
    title: PropTypes.string,
  }

  static defaultProps = {
    activeModal: null,
    title: '',
  }

  render() {
    const { activeModal, activeModalProps, setActiveModal } = this.props
    const modalProps = {
      onHide: () => setActiveModal(null),
      show: !!activeModal,
      title: startCase(activeModal),
      ...activeModalProps,
    }
    let content
    switch (activeModal) {
      case consts.CONFIRM_SHIPPING_ADDRESS:
        content = (
          <ConfirmAddressForm
            enteredAddress={modalProps.enteredAddress}
            fieldErrors={modalProps.fieldErrors}
            onEdit={modalProps.onEdit}
          />
        )
        modalProps.title = 'Check your address'
        modalProps.closeButton = false
        break
      case consts.RECOMMEND_SHIPPING_ADDRESS:
        content = (
          <AddressSuggestionForm
            recommendedAddress={modalProps.recommendedAddress}
            enteredAddress={modalProps.enteredAddress}
            fieldErrors={modalProps.fieldErrors}
            onEdit={modalProps.onEdit}
          />
        )
        modalProps.title = 'Select an address'
        modalProps.closeButton = false
        break
      case CANCEL_ORDER:
        content = (
          <ConnectedCancelOrderForm
            onCancelNo={modalProps.onCancelNo}
            onCancelYes={modalProps.onCancelYes}
            orderItems={modalProps.orderItems}
          />
        )
        modalProps.title = `Cancel Order ${modalProps.reference}`
        modalProps.closeButton = false
        break
      default:
        return null // Don't interfere with other components using ActiveModal
    }

    return (
      <Modal {...modalProps}>
        <ModalBody>{content}</ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    activeModalProps: getActiveModalProps(state),
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  }
}

class ModalManagerDataFetcher extends Component {
  render() {
    return <ModalManager {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  {
    setActiveModal,
  },
  mergeProps
)(ModalManagerDataFetcher)
