import React from 'react'

interface PinterestProps {
  className?: string
}

const Pinterest: React.FC<PinterestProps> = ({ className }: PinterestProps) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C9.89809 21.75 8.8349 21.5842 7.83402 21.2762L9.29171 15.7164C12.8451 17.2362 17.25 14.7304 17.25 10.5C17.25 7.27186 14.4271 4.69446 10.9999 4.69446C9.03753 4.69446 7.20047 5.54931 6.02501 6.93568C4.83515 8.33904 4.33652 10.2778 5.07 12.3392C5.20886 12.7295 5.63778 12.9333 6.02803 12.7944C6.41827 12.6555 6.62207 12.2266 6.48321 11.8364C5.94009 10.31 6.30316 8.92709 7.16913 7.90573C8.04951 6.86738 9.46242 6.19446 10.9999 6.19446C13.7084 6.19446 15.75 8.20593 15.75 10.5C15.75 13.6623 12.3356 15.5593 9.67631 14.2425L10.7261 10.188C10.8299 9.787 10.589 9.37777 10.188 9.27395C9.78699 9.17013 9.37776 9.41104 9.27394 9.81203L8.08208 14.4155L8.0815 14.4177L6.4262 20.7313C2.77635 19.0128 0.25 15.3014 0.25 11Z"
      fill="#252324"
    />
  </svg>
)

export default Pinterest
