import { compact, find, get } from 'lodash'

import { track } from 'lib/analytics'
import * as Actions from '../actions'
import {
  ADD_BABYLIST_BUTTON_EVENT,
  INSTALLED_BABYLIST_APP_EVENT,
  INSTALLED_BABYLIST_EXTENSION_EVENT,
} from '../constants'

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.SET_TWO_FACTORS: {
      return {
        ...state,
        twoFactors: action.twoFactors,
      }
    }

    case Actions.SET_USER: {
      return action.user || {}
    }

    case Actions.MERGE_USER:
      // This is similar to SET_USER except that it will not override values that exist in state but not in action.user.
      return { ...state, ...action.user }

    case Actions.SAVE_USER_EVENT:
      const existingEvent = find(state.events, 'name', action.event.name)
      if (existingEvent) {
        return {
          ...state,
          events: state.events.map((event) => {
            if (event.name == action.event.name) {
              return action.event
            }
            return event
          }),
        }
      }
      return {
        ...state,
        events: [action.event, ...state.events],
      }

    case Actions.SET_BOOKMARKLET_AS_USED:
      return { ...state, isBookmarkletUsed: action.value }

    default:
      return state
  }
}

export const getAllRegistries = (state) => state.registries
export const getBabylistAppInstalled = (state) =>
  state &&
  get(find(state.events, ['name', INSTALLED_BABYLIST_APP_EVENT]), 'value')

export const getBabylistExtensionInstalled = (state) =>
  state &&
  get(find(state.events, ['name', INSTALLED_BABYLIST_EXTENSION_EVENT]), 'value')

export const getBabylistButtonAdded = (state) =>
  state && get(find(state.events, ['name', ADD_BABYLIST_BUTTON_EVENT]), 'value')

export const getDevicePlatform = (state) => state?.currentDevicePlatform
export const getEmail = (state) => get(state, 'email')
export const getId = (state) => get(state, 'id')
export const getIsAdmin = (state) => state.admin || state.adminId
export const getFirstName = (state) => state?.firstName
export const getFullName = (state) =>
  compact([get(state, 'firstName'), get(state, 'lastName')])
    .join(' ')
    .trim()
export const getCoregistrations = (state) => state.coregistrations

export const getIsBookmarkletUsed = (state) => state.isBookmarkletUsed
const isEventPresent = (eventName, state) =>
  state && get(find(state.events, ['name', eventName]), 'value')
export const getIsBookmarkletInstalled = (state) =>
  isEventPresent(ADD_BABYLIST_BUTTON_EVENT, state)
export const getIsBabylistAppInstalled = (state) =>
  isEventPresent(INSTALLED_BABYLIST_APP_EVENT, state)
export const getIsBabylistExtensionInstalled = (state) =>
  isEventPresent(INSTALLED_BABYLIST_EXTENSION_EVENT, state)

export default userReducer
