import React, { useState } from 'react'
import classnames from 'classnames'
import Image from 'components/image'
import css from './ProductImage.styles.scss'

interface Image {
  alt: string
  src: string
}

export interface Overlay {
  content: React.ReactNode
  key: string | number
  hideOnHover?: boolean
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
}

export interface ProductImageProps {
  images: Image[]
  overlays?: Overlay[]
}

const ProductImage: React.FC<ProductImageProps> = ({
  images,
  overlays = [],
}) => {
  const [isActive, setIsActive] = useState(false)
  const hasHoverImage = images.length > 1
  const hideOverlays = isActive && hasHoverImage // Hide overlays on hover unless there's no hover image

  return (
    <div
      className={css.ProductImage}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      tabIndex={0}
    >
      <Image
        alt={images[0]?.alt}
        src={images[0]?.src}
        className={classnames(
          css['ProductImage__img'],
          (!isActive || !hasHoverImage) && css['ProductImage__img--show'],
          isActive && css['ProductImage__img--elevation']
        )}
      />
      {hasHoverImage && (
        <Image
          alt={images[1]?.alt}
          src={images[1]?.src}
          className={classnames(
            css['ProductImage__img'],
            isActive && css['ProductImage__img--show'],
            isActive && css['ProductImage__img--elevation']
          )}
        />
      )}
      {overlays.map(({ key, position, content, hideOnHover }) => (
        <div
          key={key}
          className={classnames(
            css['ProductImage__overlay'],
            hideOverlays && hideOnHover && css[`ProductImage__overlay--hide`],
            css[`ProductImage__overlay--${position}`]
          )}
          data-testid={`overlay-${key}`}
        >
          {content}
        </div>
      ))}
    </div>
  )
}

export default ProductImage
