import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    hero: {
      background:
        'url(//res.cloudinary.com/babylist/image/upload/v1635541694/about-pages/jobs-banner.svg) no-repeat center/cover',
    },
    heading: {
      color: '#ffffff',
      textAlign: 'center',
      padding: '60px 0',
      margin: 0,
      fontWeight: 500,
    },
    subheading: {
      color: '#707070',
      fontSize: '20px',
      fontWeight: 500,
      padding: '20px 0',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '24px',
      },
    },
    faqSection: {
      backgroundColor: '#F9F9F9',
      padding: '42px 0',
    },
    faqWrapper: {
      maxWidth: '700px',
      margin: '0 auto',
    },
    sectionHeading: {
      color: '#6E3264',
      fontSize: '24px',
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        fontSize: '36px',
        textAlign: 'left',
      },
    },
    sectionDescription: {
      color: '#666666',
      fontSize: '16px',
      textAlign: 'center',
      margin: '20px 0',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        fontSize: '20px',
      },
    },
    shopHeading: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr auto',
      margin: '40px 0',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 150px',
        gridTemplateRows: '1fr',
      },
    },
    shopSection: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: 15,
      margin: '0 0 80px',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridGap: 30,
      },
    },
    quickJump: {
      display: 'flex',
      justifyContent: 'center',
      listStyle: 'none',
      textAlign: 'center',
      backgroundColor: '#F9F9F8',
      borderBottom: '1px solid #E6E7E6',
      '@media (max-width: 724px)': {
        justifyContent: 'left',
        overflowX: 'scroll',
        paddingLeft: '0px',
        '& li': {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    jumpLink: {
      color: '#666666',
      display: 'inline-block',
      padding: '10px 20px',
      fontWeight: 500,
    },
  })
)

export default useStyles
