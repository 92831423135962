import { checklistPath } from 'lib/urls'
import classNames from 'classnames'
import Image from 'components/image'
import Fade from 'react-reveal/Fade'
import { InfoButton } from '../shared/Buttons'
import css from './ChecklistSplash.scss'
import SectionText from '../shared/SectionText'
import { SectionHeading } from '../shared/Headings'

const ChecklistSplash = () => (
  <section className={css.ChecklistSplash}>
    <div className={css.ChecklistSplashContent}>
      <div className={css.ChecklistSplashTextContent}>
        <Fade bottom>
          <SectionHeading
            className={css.ChecklistSectionHeading}
            data-testid="checklistSectionHeading"
          >
            Add Anything
            <br />
            from Any Store
          </SectionHeading>
          <SectionText
            className={css.ChecklistSectionText}
            data-testid="checklistSplashCopy"
          >
            Eliminate guesswork with our interactive checklist and universal
            registry.
          </SectionText>
        </Fade>
        <div className={classNames(css.ButtonContainer, css.HiddenSmall)}>
          <InfoButton pill className={css.Button} href={checklistPath}>
            Get Started
          </InfoButton>
        </div>
      </div>
      <Image
        alt="Best baby registry checklist"
        className={css.ChecklistSplashImage}
        lazyLoad={false}
        src="//images.babylist.com/image/upload/f_auto,q_auto/v1690554600/AddAnything_Phone_rllgoq.png"
      />
    </div>
    <div className={classNames(css.ButtonContainer, css.VisibleSmall)}>
      <InfoButton pill className={css.Button} href={checklistPath}>
        Get Started
      </InfoButton>
    </div>
  </section>
)

export default ChecklistSplash
