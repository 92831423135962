import { Component } from 'react'
import { CompatRouter, Routes, Route } from 'react-router-dom-v5-compat'
import Router from 'shared/universal-router'
import {
  GiftReturnsInstructions,
  PurchaseReturnForm,
  ReturnsLandingPage,
} from './index'
import * as blUrls from 'lib/urls'
import ScrollToTop from 'components/scroll-to-top'

class ReturnsApp extends Component {
  state = {
    mounted: false,
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  render() {
    return (
      <Router location={{ pathname: blUrls.returnsPath }} useV6Router>
        <Routes>
          <Route element={<ScrollToTop useV6Outlet />}>
            <Route path={blUrls.returnsPath} element={<ReturnsLandingPage />} />
            <Route
              path={blUrls.returnGiftPath}
              element={<GiftReturnsInstructions />}
            />
            <Route
              path={blUrls.returnPurchasePath}
              element={<PurchaseReturnForm />}
            />
          </Route>
        </Routes>
      </Router>
    )
  }
}

export default ReturnsApp
