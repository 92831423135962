import Router from 'shared/universal-router'
import { Routes, Route, Outlet, useLocation } from 'react-router-dom-v5-compat'
import {
  track,
  TrackImpression,
  withContextualizedTracking,
} from 'lib/analytics'
import withReactQueryClientProvider from 'shared/HOC/withReactQueryClientProvider'

import { DiaperCalculatorShape, RoutingShape } from './prop-types'
import { DiaperCalculatorForm } from './diaper-calculator-form'
import { DiaperCalculatorResultGoBigOrGoHome } from './diaper-calculator-result-go-big-or-go-home'
import { DiaperCalculatorResultJustTheBasics } from './diaper-calculator-result-just-the-basics'
import { DiaperCalculatorResultSensibleStart } from './diaper-calculator-result-sensible-start'
import { DiaperCalculatorProducts } from './diaper-calculator-products'

const ImpressionTrackedResultPage = withContextualizedTracking()(({
  resultContent,
  ...restProps
}) => {
  const location = useLocation()
  const result = calculateResult(location.pathname, resultContent)

  return (
    <TrackImpression
      eventProperties={{
        event: track.diaperCalculatorResultViewed,
        eventLocation: track.EventLocation.DIAPER_CALCULATOR,
        diaperCalculatorResultSlug: result.slug,
        diaperCalculatorResultName: result.name,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      <Outlet />
    </TrackImpression>
  )
})

const ImpressionTrackedFormPage = withContextualizedTracking()(
  ({ children }) => (
    <TrackImpression
      eventProperties={{
        event: track.diaperCalculatorFormViewed,
        eventLocation: track.EventLocation.DIAPER_CALCULATOR,
      }}
    >
      {children}
    </TrackImpression>
  )
)

const calculateResult = (pathname, resultContent) => {
  const slug = pathname.split('/').at(-1)
  switch (slug) {
    case resultContent.goBigOrGoHome.slug:
      return resultContent.goBigOrGoHome
    case resultContent.justTheBasics.slug:
      return resultContent.justTheBasics
    default:
      return resultContent.sensibleStart
  }
}

export const DiaperCalculatorApp = withReactQueryClientProvider(
  ({ routing, diaperCalculator }) => {
    const { resultContent, questionContent, featuredProducts } =
      diaperCalculator

    const DiaperCalculatorProductsComponent = () => (
      <DiaperCalculatorProducts products={featuredProducts} />
    )

    return (
      <Router location={{ pathname: routing.path }} useV6Router>
        <Routes>
          <Route
            path={routing.basePath}
            element={
              <ImpressionTrackedFormPage>
                <DiaperCalculatorForm questions={questionContent} />
              </ImpressionTrackedFormPage>
            }
          />
          <Route
            path={routing.basePath}
            element={
              <ImpressionTrackedResultPage resultContent={resultContent} />
            }
          >
            <Route
              path={resultContent.goBigOrGoHome.slug}
              element={
                <DiaperCalculatorResultGoBigOrGoHome
                  DiaperCalculatorProducts={DiaperCalculatorProductsComponent}
                />
              }
            />
            <Route
              path={resultContent.justTheBasics.slug}
              element={
                <DiaperCalculatorResultJustTheBasics
                  DiaperCalculatorProducts={DiaperCalculatorProductsComponent}
                />
              }
            />
            <Route
              path={resultContent.sensibleStart.slug}
              element={
                <DiaperCalculatorResultSensibleStart
                  DiaperCalculatorProducts={DiaperCalculatorProductsComponent}
                />
              }
            />
          </Route>
        </Routes>
      </Router>
    )
  }
)

DiaperCalculatorApp.propTypes = {
  routing: RoutingShape.isRequired,
  diaperCalculator: DiaperCalculatorShape.isRequired,
}
