import { Component } from 'react'
import PropTypes from 'prop-types'
import Image from 'components/image'

export default class Finish extends Component {
  static propTypes = {
    name: PropTypes.string,
  }

  render() {
    return (
      <div className="text-center mtl">
        <Image
          role="presentation"
          src="https://res.cloudinary.com/babylist/image/upload/v1446241348/ThankYoupup-14_y8ovwv.png"
          width={200}
          className="center-block img-responsive"
        />
        <p className="h3">
          Thank you for sharing your thoughts{name ? ', ' + name : null}!
        </p>
        <p className="lead mbs">Have more advice to give?</p>
        <p className="lead mbs">Review more items!</p>
      </div>
    )
  }
}
