import { Component } from 'react'
import PropTypes from 'prop-types'
import FormHeader from './form-header'
import { Input } from 'components/forms'
import { getQuestionText } from './questions.js'

export default class Step5 extends Component {
  static propTypes = {
    productName: PropTypes.string,
    questionIds: PropTypes.array,
    onChange: PropTypes.func,
  }

  componentDidMount() {
    console.assert(
      this.props.questionIds[0] == 6,
      'first question id passed to this component was not 6!!'
    )
  }

  handleChange = (event) => {
    this.props.onChange(this.props.questionIds[0], event.target.value)
  }

  render() {
    const questionText = getQuestionText(
      this.props.questionIds[0],
      this.props.productName
    )
    return (
      <div>
        <FormHeader questionText={questionText} />
        <Input
          aria-label={questionText}
          placeholder="https://instagram.com/babylist"
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
