import * as React from 'react'
import classnames from 'classnames'

export interface PaperProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * @default 'white'
   */
  background?: 'white' | 'lightGray' | 'winterTwilight'
}

export default function Paper({
  background = 'white',
  className,
  ...rest
}: PaperProps) {
  return (
    <div
      {...rest}
      className={classnames(className, {
        'light-gray-bg': background === 'lightGray',
        'white-bg': background === 'white',
        'winter-twilight-bg': background === 'winterTwilight',
      })}
    />
  )
}
