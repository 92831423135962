// These single char values match those used in checklist.rb
export const QUESTION_TYPE_CHECKBOX = 'checkbox'
export const QUESTION_TYPE_DATE = 'date'
export const QUESTION_TYPE_RADIO = 'radio'
export const QUESTION_TYPE_TEXT = 'text'
export const QUESTION_TYPE_ADDRESS_FORM = 'address_form'
export const QUESTION_TYPE_ARRIVAL = 'arrival'
export const QUESTION_TYPE_COREGISTRATION = 'coregistration'
export const QUESTION_ID_COREGISTRATION = 20
export const ANSWER_COREGISTRATION_YES = '1'
