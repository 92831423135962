import * as blUrls from '../../lib/urls'
import fetch from '../../lib/fetch'

export const startChecklist = () => {
  return fetch(blUrls.checklistStartPath)
}

export const submitAnswer = (questionId, answer) => {
  return fetch(blUrls.checklistQuestionsPath(questionId), {
    body: JSON.stringify({
      checklist: {
        questionId,
        answer,
      },
    }),
    method: 'POST',
  })
}

export const updateChecklistAndTriggerEmail = (
  email,
  firstName,
  lastName,
  recaptchaToken
) => {
  return new Promise((resolve, reject) => {
    fetch(blUrls.checklistQuizPath, {
      body: JSON.stringify({
        checklist: { email, first_name: firstName, last_name: lastName },
        recaptcha_token: recaptchaToken,
      }),
      method: 'POST',
    })
      .then((resp) => {
        resolve(resp)
      })
      .catch((resp) => {
        reject(resp)
      })
  })
}
