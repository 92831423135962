import React from 'react'
import css from './Zodiac.scss'
import { getZodiacSign, getChineseZodiac, getBirthStone } from './utils'

interface ZodiacProps {
  dueDate: Date
}

type IconComponentType = React.FC<{ className?: string }>

const Zodiac: React.FC<ZodiacProps> = ({ dueDate }) => {
  const { component, name } = getZodiacSign(dueDate)
  const ZodiacComponent = React.createElement(component as IconComponentType, {
    className: css.Zodiac__iconModule__icon,
  })

  const { component: chineseZodiacComponent, name: chineseZodiacName } =
    getChineseZodiac(dueDate)
  const ChineseZodiacComponent = React.createElement(
    chineseZodiacComponent as IconComponentType,
    { className: css.Zodiac__iconModule__icon }
  )

  const { component: birthStoneComponent, name: birthStoneName } =
    getBirthStone(dueDate)
  const BirthStoneComponent = React.createElement(
    birthStoneComponent as IconComponentType,
    { className: css.Zodiac__iconModule__icon }
  )

  return (
    <div className={css.Zodiac}>
      <div className={css.Zodiac__iconModule}>
        {ZodiacComponent}
        <h3>Zodiac</h3>
        <h4>{name}</h4>
      </div>
      <div className={css.Zodiac__iconModule}>
        {BirthStoneComponent}
        <h3>Birth stone</h3>
        <h4>{birthStoneName}</h4>
      </div>
      <div className={css.Zodiac__iconModule}>
        {ChineseZodiacComponent}
        <h3>Chinese Zodiac</h3>
        <h4>{chineseZodiacName} </h4>
      </div>
    </div>
  )
}

export default Zodiac
