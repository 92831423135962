import PropTypes from 'prop-types'

import { DiaperCalculatorResultLayout } from './diaper-calculator-result-layout'
import { DiaperBreakdown } from './components/diaper-breakdown'
import { Heading, Subheading, ResultProTipBox } from './components/ui'

const PrimaryContent = () => (
  <>
    <Heading>Sensible Start!</Heading>
    <Subheading>
      There's a lot going on in the early days of parenthood. The fewer errands
      you have to run, the better! If you have the space, register for enough
      diapers to get you through the first three months—that's about five to six
      boxes:
    </Subheading>
    <div className="mbxl">
      <DiaperBreakdown
        items={[
          {
            title: '1-2 Boxes of Newborn Diapers',
            subtitle: '(128 count)*',
          },
          {
            title: '4 Boxes of Size 1 Diapers',
            subtitle: '(168 count)',
          },
        ]}
      />
    </div>
    <p className="mbxl">
      Struggling with diaper decision fatigue? The{' '}
      <a href="/hello-baby/babylist-diaper-box-brands" target="_blank">
        Babylist Diaper Box
      </a>{' '}
      lets you try out an assortment of diapers and wipes from three popular
      brands so you can decide for yourself what works best for your little one.
      Bonus: they're all eco-friendly and free of fragrances, chlorine and other
      things you likely don't want touching your baby's skin
    </p>

    <p className="mbxxl">
      And remember, many babies outgrow newborn sizes quickly. So if your baby
      is measuring big or you have a family history of 10-pounders, you may want
      to stick to just one box of newborn diapers or grab a smaller pack on your
      next errand run.
    </p>
  </>
)

const SecondaryContent = ({ DiaperCalculatorProducts }) => (
  <>
    <div className="mbxxl">
      <DiaperCalculatorProducts />
    </div>

    <div className="mbxxl text-center">
      <ResultProTipBox>
        <p>
          Those first months can fly by, so consider setting up an auto-delivery
          of diapers before the baby gets here. As an added bonus, when you
          subscribe to regular deliveries, you usually save a little cash in the
          process.
        </p>
      </ResultProTipBox>
    </div>
    <small>Boxes = based on Little Snugglers box size.</small>
  </>
)

SecondaryContent.propTypes = {
  DiaperCalculatorProducts: PropTypes.elementType.isRequired,
}

export const DiaperCalculatorResultSensibleStart = ({
  DiaperCalculatorProducts,
}) => (
  <DiaperCalculatorResultLayout
    primaryContent={<PrimaryContent />}
    secondaryContent={
      <SecondaryContent DiaperCalculatorProducts={DiaperCalculatorProducts} />
    }
  />
)

DiaperCalculatorResultSensibleStart.propTypes = {
  DiaperCalculatorProducts: PropTypes.elementType.isRequired,
}
