import { Component } from 'react'
import { bowserFlags } from 'lib/bowser'
import { Collapse } from 'react-bootstrap'

import { Checkbox } from 'components/forms'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import PurchaseInfoForm from './purchase-info-form'
import { tracking, track } from 'lib/analytics'

@tracking()
class CollapsablePurchaseInfo extends Component {
  state = {
    submitting: false,
  }

  render() {
    let { className, formKey, onDismiss, onSubmit, reservation, show } =
      this.props
    return (
      <div className={className}>
        {reservation.isPurchased &&
        reservation.storeName == 'Babylist' ? null : (
          <Checkbox
            checked={reservation.isPurchased || show}
            groupClassName={show ? 'mbl' : 'mbn'}
            label="I've purchased this"
            onChange={this.handleSubmitUnpurchased.bind(this)}
            disabled={this.state.submitting}
          />
        )}

        <Collapse
          in={show}
          className="mbl"
          key={`${reservation.id}-${reservation.isPurchased}`}
        >
          <TwoColumnResponsiveLayout className="mtn" key={reservation.id}>
            <PurchaseInfoForm
              formKey={formKey}
              onSubmit={onSubmit}
              onDismiss={onDismiss}
              reservation={reservation}
              submitBtnText={
                reservation.isPurchased ? 'Save' : 'Confirm Purchase'
              }
              autofillStore={true}
              storeName={reservation.storeName}
            />

            <div style={{ marginTop: bowserFlags.mobile ? 0 : 54 }}>
              <hr className="visible-xs visible-sm hidden" />
              <div className="h5">
                <b>Why do we need this info?</b>
              </div>
              <div className="h6">
                Having this information makes it easy on the parents to exchange
                a gift in case there are any problems.
              </div>
            </div>
          </TwoColumnResponsiveLayout>
        </Collapse>
      </div>
    )
  }

  @tracking((props, state) => {
    return {
      event: track.reservationUpdated,
      reservation: props.reservation,
      eventType: track.EventType.MARK_AS_UNPURCHASED,
    }
  })
  handleSubmitUnpurchased() {
    // Prevent double-clicks
    this.setState({ submitting: true }, () => {
      this.props
        .onShowPurchaseInfoForm(!this.props.show)
        .then(() => {
          this.setState({ submitting: false })
        })
        .catch(() => {
          this.setState({ submitting: false })
        })
    })
  }
}

export default CollapsablePurchaseInfo
