import Card from 'components/card'
import { InfoButton } from 'components/buttons'
import Link from 'components/link'
import useStyles from './CouponCard.styles'

interface CouponCardProps {
  title: string
  description: string
  ctaText: string
  ctaLink: string
  detailsLink: string
  thumbnail: string
}

export const CouponCard = ({
  title,
  description,
  ctaText,
  ctaLink,
  detailsLink,
  thumbnail,
}: CouponCardProps) => {
  const classes = useStyles()
  return (
    <>
      {/* @ts-ignore */}
      <Card className="mbl">
        <div className={classes.layout}>
          <img alt={title} className={classes.thumbnail} src={thumbnail} />
          <div className={classes.textSection}>
            <h3 className={classes.title}>{title}</h3>
            <p className={classes.description}>{description}</p>
          </div>
          <div className={classes.ctaSection}>
            <InfoButton block pill className="pvl" href={ctaLink}>
              {ctaText}
            </InfoButton>
            {detailsLink && (
              <Link className={classes.detailsLink} url={detailsLink}>
                Details
              </Link>
            )}
          </div>
        </div>
      </Card>
    </>
  )
}
