import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import order, * as fromOrder from './order-reducer'
import ui, * as fromUi from './ui-reducer'

const orderReducer = combineReducers({
  order,
  ui,
  form: formReducer,
})

export const getActiveModal = (state) => fromUi.getActiveModal(state.ui)
export const getActiveModalProps = (state) =>
  fromUi.getActiveModalProps(state.ui)
export const getIsHbbOrder = (state) => fromOrder.getIsHbbOrder(state.order)
export const getIsPremiumShipping = (state) =>
  fromOrder.getIsPremiumShipping(state.order)
export const getOrderCreatedAt = (state) =>
  fromOrder.getOrderCreatedAt(state.order)
export const getOrderHolds = (state) => fromOrder.getOrderHolds(state.order)
export const getOrderItems = (state) => fromOrder.getOrderItems(state.order)
export const getOrderCanCancel = (state) =>
  fromOrder.getOrderCanCancel(state.order)
export const getOrderReference = (state) =>
  fromOrder.getOrderReference(state.order)
export const getOrderStatus = (state) => fromOrder.getOrderStatus(state.order)
export const getOrderTotal = (state) => fromOrder.getOrderTotal(state.order)
export const getOrderUuid = (state) => fromOrder.getOrderUuid(state.order)
export const getPageMessage = (state) => fromUi.getPageMessage(state.ui)
export const getShippingAddress = (state) =>
  fromOrder.getShippingAddress(state.order)
export const getSubmittingCancelOrder = (state) =>
  fromUi.getSubmittingCancelOrder(state.ui)

export default orderReducer
