import { FeaturedGuideCard } from '../../../../components/card/featured-guide-card'
import Frame from '../../components/Frame'
import SectionHeader from '../../components/SectionHeader'
import { mobileAppDownloadPath } from '../../../../lib/urls'

export default function GuideSection() {
  return (
    <Frame>
      <SectionHeader>Guides & Consultants</SectionHeader>

      <div className="w-100 mtl">
        <FeaturedGuideCard
          guideLabel="Guide"
          imageUrl="https://images.babylist.com/image/upload/f_auto,q_auto:best,c_fill,w_750,h_500/v1568394193/hello-baby/first-year-basics-month-by-month-header.jpg"
          teaser="What to add and why, FAQs and how to build a registry with items you'll actually use."
          // @ts-ignore
          title={
            <strong>The Ultimate Guide to Creating Your Baby Registry</strong>
          }
        />
      </div>

      <a href={mobileAppDownloadPath()} className="mbl btn-pill btn btn-info">
        Get More Expert Advice
      </a>
    </Frame>
  )
}
