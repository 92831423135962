import React from 'react'

interface PigStickerProps {
  className?: string
}

const PigSticker: React.FC<PigStickerProps> = ({
  className,
}: PigStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1019_6469)">
      <path
        d="M32.3159 57.739C32.3159 57.739 38.2086 73.0215 36.84 78.8241L45.0062 99.5725L51.7355 94.3206C51.7355 94.3206 47.8889 81.1088 48.6073 79.7298C49.3257 78.3508 65.5307 71.3285 62.5525 60.9293C59.5743 50.5301 32.3159 57.7299 32.3159 57.7299V57.739Z"
        fill="#F3D7E8"
      />
      <path
        d="M82.7769 66.6363L85.2731 82.8518L91.1021 94.225L98.0907 92.1133L90.6975 60.6653L82.7769 66.6363Z"
        fill="#F3D7E8"
      />
      <path
        d="M39.8956 30.9878C39.8956 30.9878 24.2407 29.577 22.1037 48.4048C19.9667 67.2326 35.9216 79.8346 52.4904 81.4957C69.0591 83.1569 100.569 64.8524 108.876 54.5351C117.183 44.2178 116.378 43.3167 116.378 43.3167C116.378 43.3167 118.233 35.5525 109.94 33.7412C108.589 33.4454 106.821 35.7437 104.752 35.5798C100.169 35.2158 94.2851 32.7172 89.4881 33.6593C82.5224 35.0246 80.0716 36.8359 80.0716 36.8359C80.0716 36.8359 53.277 27.3333 39.891 30.9878H39.8956Z"
        fill="#F3D7E8"
      />
      <path
        d="M85.9235 22.236C85.9235 22.236 82.245 33.0539 86.4009 37.0862C90.5567 41.1184 89.4973 33.6547 89.4973 33.6547C89.4973 33.6547 93.3939 29.5541 85.928 22.2314L85.9235 22.236Z"
        fill="#F3D7E8"
      />
      <path
        d="M95.1627 21.0801C95.1627 21.0801 89.6838 34.5604 91.7662 35.8119C93.8487 37.0635 100.933 29.8773 95.1627 21.0801Z"
        fill="#F3D7E8"
      />
      <path
        d="M86.6738 24.8711C86.6738 24.8711 85.8463 33.2496 87.1649 34.1462C88.4835 35.0428 89.3838 33.5637 89.3747 30.0775C89.3656 26.5914 86.6738 24.8711 86.6738 24.8711Z"
        fill="#FFD43F"
      />
      <path
        d="M93.0347 32.3622L95.3354 24.4115C95.3354 24.4115 97.6452 30.428 96.3175 32.3895C94.9898 34.3511 93.0347 32.3622 93.0347 32.3622Z"
        fill="#FFD43F"
      />
      <path
        d="M111.935 42.3741C112.37 42.3282 112.605 41.1867 112.462 39.8244C112.318 38.4621 111.85 37.3949 111.415 37.4408C110.981 37.4866 110.745 38.6282 110.888 39.9905C111.032 41.3528 111.5 42.4199 111.935 42.3741Z"
        fill="#6F3264"
      />
      <path
        d="M114.963 42.058C115.397 42.0122 115.633 40.8706 115.49 39.5083C115.346 38.146 114.878 37.0788 114.443 37.1247C114.009 37.1706 113.773 38.3121 113.916 39.6744C114.06 41.0367 114.528 42.1039 114.963 42.058Z"
        fill="#6F3264"
      />
      <path
        d="M88.9561 43.4258C88.9561 43.4258 90.0018 37.8143 96.8812 41.0593C96.8812 41.0593 94.8079 46.6889 88.9561 43.4258Z"
        fill="#F9F6F3"
      />
      <path
        d="M92.4843 44.0721C93.5289 44.0721 94.3758 43.2245 94.3758 42.1789C94.3758 41.1333 93.5289 40.2856 92.4843 40.2856C91.4396 40.2856 90.5928 41.1333 90.5928 42.1789C90.5928 43.2245 91.4396 44.0721 92.4843 44.0721Z"
        fill="#6F3264"
      />
      <path
        d="M108.708 45.8652C108.94 45.8925 109.194 45.938 109.44 45.8334C109.503 45.8106 109.603 45.8243 109.676 45.8425C109.903 45.9062 110.135 45.9517 110.376 45.9335C110.413 45.9335 110.454 45.9471 110.485 45.9335C110.808 45.8152 111.145 45.8971 111.472 45.8288C111.704 45.7924 111.94 45.7697 112.15 45.6559C112.263 45.5831 112.418 45.5603 112.554 45.5284C112.973 45.4283 113.405 45.3419 113.864 45.2554C114.009 45.2326 114.141 45.2144 114.196 45.2736C114.228 45.3054 114.355 45.2918 114.382 45.3191C114.441 45.3874 114.355 45.6104 114.087 45.9289C113.959 46.0564 113.809 46.1519 113.664 46.2657C113.377 46.4523 113.095 46.6799 112.736 46.7026C112.609 46.7117 112.477 46.7891 112.35 46.8346C111.986 46.9802 111.599 47.0121 111.218 47.0394C111.095 47.0394 110.967 47.0349 110.849 47.0622C110.545 47.1395 110.226 47.1577 109.908 47.1486C109.758 47.1395 109.612 47.1395 109.462 47.1077C109.308 47.0667 109.135 47.0804 108.971 47.0667C108.539 47.0212 108.103 46.9666 107.694 46.7982C107.184 46.7254 106.712 46.4478 106.252 46.2384C106.107 46.1747 105.952 46.0609 105.839 45.8925C105.57 45.5148 105.548 45.4875 105.739 45.4738C105.798 45.4693 105.857 45.4602 105.911 45.4556C106.007 45.4465 106.107 45.4556 106.261 45.5148C106.489 45.5831 106.721 45.624 106.957 45.6331C107.148 45.6377 107.321 45.6422 107.498 45.7105C107.562 45.7378 107.644 45.7332 107.716 45.7378C107.88 45.7515 108.044 45.756 108.208 45.7742C108.367 45.797 108.53 45.8288 108.694 45.8652H108.708Z"
        fill="#6F3264"
      />
      <path
        d="M90.6754 40.3175C90.9255 40.1628 91.2119 40.0536 91.4575 39.8397C91.5211 39.7759 91.6484 39.7532 91.7485 39.7441C92.0486 39.7077 92.3441 39.6986 92.6396 39.6576C92.6851 39.6531 92.7351 39.6576 92.7715 39.6485C93.1807 39.512 93.6036 39.6394 94.0037 39.6849C94.2947 39.7122 94.5584 39.8306 94.8585 39.8397C95.0222 39.8533 95.1995 39.9352 95.3587 40.0126C95.8406 40.2402 96.268 40.586 96.5818 41.0184C96.6773 41.1549 96.7409 41.2869 96.7091 41.3552C96.6863 41.3961 96.75 41.5145 96.7273 41.5463C96.6727 41.6282 96.4317 41.5873 96.068 41.4963C95.9088 41.4507 95.7633 41.3825 95.6133 41.337C95.3223 41.2414 95.0267 41.2141 94.7767 41.0412C94.6857 40.9865 94.5539 40.9638 94.4357 40.9547C94.1037 40.9137 93.79 40.8318 93.4626 40.7863C93.3581 40.7772 93.2489 40.7499 93.1489 40.7726C92.8761 40.8227 92.6215 40.8728 92.3577 40.9228C92.2304 40.9456 92.1168 40.9865 91.9849 40.9956C91.8485 41.0138 91.7212 41.0912 91.5939 41.1504C91.2529 41.3051 90.9528 41.519 90.6163 41.6965C90.3162 42.0151 89.9206 42.2654 89.6706 42.634C89.5978 42.7478 89.475 42.857 89.325 42.9572C88.9749 43.1984 88.9431 43.2484 88.9158 43.0573C88.9067 43.0118 88.8976 42.9526 88.8885 42.8843C88.8749 42.7888 88.8885 42.6841 88.9612 42.5157C89.0704 42.2881 89.1522 42.0378 89.2613 41.8012C89.3432 41.61 89.4296 41.4143 89.6069 41.2733C89.6706 41.2232 89.716 41.1413 89.7706 41.073C90.007 40.759 90.3117 40.4905 90.6936 40.3175H90.6754Z"
        fill="#6F3264"
      />
      <path
        d="M25.146 55.8777C25.146 55.8777 27.7059 72.0568 25.146 77.4453L28.7789 99.4498L36.4586 95.7315C36.4586 95.7315 35.4674 82.0055 36.4586 80.8085C37.4498 79.6116 54.7688 76.1482 54.0367 65.3576C53.3047 54.567 25.146 55.8777 25.146 55.8777Z"
        fill="#F3D7E8"
      />
      <path
        d="M76.6935 65.353L74.4019 81.6003L76.6935 94.1704H83.9957L86.0009 61.9305L76.6935 65.353Z"
        fill="#F3D7E8"
      />
      <path
        d="M62.3752 42.675C62.1752 42.6249 61.9751 42.5294 61.7387 42.5794C61.675 42.5931 61.5977 42.5521 61.5386 42.5111C61.3658 42.3928 61.1885 42.2836 60.9884 42.2153C60.9612 42.2062 60.9339 42.1789 60.902 42.1789C60.5974 42.1744 60.3655 41.965 60.0927 41.8694C59.9017 41.8057 59.729 41.6874 59.5107 41.6783C59.3925 41.6783 59.2743 41.6055 59.1606 41.5509C58.8241 41.3825 58.5013 41.1686 58.2012 40.9001C58.1103 40.809 58.033 40.7271 58.0466 40.6498C58.0557 40.6042 57.983 40.5223 57.9875 40.4859C58.0057 40.3949 58.2058 40.313 58.5331 40.2811C58.6786 40.272 58.815 40.2993 58.9514 40.313C59.2152 40.3448 59.488 40.3403 59.7062 40.5041C59.7835 40.5633 59.9017 40.5769 60.0018 40.5951C60.2928 40.6498 60.5519 40.7817 60.8157 40.9092C60.8975 40.9501 60.9839 41.0002 61.0748 41.0184C61.3113 41.0548 61.5386 41.1276 61.7614 41.2141C61.866 41.255 61.9706 41.2869 62.0706 41.3506C62.1752 41.4143 62.307 41.428 62.4252 41.4689C62.739 41.5736 63.0573 41.651 63.3483 41.8239C63.7347 41.8877 64.0758 42.1243 64.444 42.2381C64.5577 42.2745 64.6805 42.3564 64.7805 42.4793C65.0215 42.766 65.0488 42.7933 64.8987 42.8525C64.8487 42.8752 64.8032 42.8934 64.7578 42.9116C64.6805 42.9389 64.5941 42.9617 64.4577 42.9344C64.2667 42.9025 64.0621 42.9116 63.862 42.9207C63.7029 42.9298 63.5438 42.9481 63.3937 42.8798C63.3392 42.857 63.271 42.857 63.2073 42.8525C62.9208 42.8388 62.6435 42.7888 62.3752 42.6659V42.675Z"
        fill="#6F3264"
      />
      <path
        d="M60.106 54.3894C59.8968 54.3803 59.6785 54.3303 59.4512 54.4258C59.3921 54.4531 59.3057 54.4258 59.242 54.3985C59.0465 54.3212 58.8465 54.2483 58.6328 54.221C58.6009 54.221 58.5691 54.1983 58.5373 54.2028C58.2326 54.2574 57.9598 54.1027 57.6688 54.0663C57.4642 54.039 57.2642 53.9616 57.0505 53.998C56.9322 54.0162 56.8004 53.9753 56.6776 53.9434C56.3048 53.8479 55.941 53.7068 55.5818 53.502C55.4727 53.4337 55.3772 53.3654 55.3727 53.2881C55.3727 53.2426 55.2817 53.1788 55.2817 53.1424C55.2817 53.0514 55.4636 52.9285 55.7819 52.8284C55.9228 52.792 56.0638 52.7874 56.2047 52.7738C56.473 52.751 56.7413 52.6919 56.9959 52.8057C57.0868 52.8466 57.2051 52.8375 57.3096 52.833C57.6097 52.833 57.8962 52.9058 58.1826 52.974C58.2736 52.9968 58.3691 53.0332 58.46 53.0287C58.7055 53.015 58.9465 53.0378 59.1875 53.0787C59.3012 53.0969 59.4148 53.1106 59.524 53.1515C59.6422 53.1925 59.774 53.1788 59.9013 53.197C60.2333 53.238 60.5697 53.2471 60.8926 53.3563C61.2881 53.3381 61.6837 53.502 62.0702 53.5384C62.193 53.5475 62.3294 53.6066 62.4567 53.7068C62.7568 53.9389 62.7931 53.9571 62.6522 54.0481C62.6204 54.0754 62.5749 54.1027 62.5249 54.1346C62.4567 54.1801 62.3748 54.2165 62.2293 54.221C62.0293 54.2256 61.8292 54.2802 61.6337 54.3303C61.4746 54.3712 61.3245 54.4213 61.1608 54.3849C61.1017 54.3712 61.0335 54.3849 60.9699 54.3985C60.6789 54.444 60.3924 54.4531 60.1014 54.3849L60.106 54.3894Z"
        fill="#6F3264"
      />
      <path
        d="M70.8958 59.1408C70.7094 59.2045 70.4957 59.2227 70.3275 59.3866C70.282 59.4321 70.2002 59.4366 70.132 59.4321C69.9319 59.423 69.7319 59.4184 69.5318 59.4639C69.5045 59.4685 69.4681 59.4639 69.4409 59.4776C69.1908 59.6323 68.8907 59.5732 68.6224 59.6323C68.4314 59.6778 68.2314 59.6642 68.0495 59.7689C67.9495 59.828 67.8176 59.828 67.6994 59.8417C67.3357 59.8735 66.9674 59.8599 66.5809 59.7871C66.4581 59.7598 66.3535 59.7279 66.3263 59.6551C66.3081 59.6141 66.208 59.5823 66.1944 59.5459C66.1626 59.4594 66.2853 59.2864 66.5354 59.0862C66.6491 59.0043 66.7718 58.9542 66.8946 58.895C67.1265 58.7858 67.3448 58.6402 67.6085 58.6675C67.704 58.6766 67.804 58.6265 67.8949 58.5901C68.1632 58.4854 68.4405 58.4672 68.7179 58.4399C68.8088 58.4308 68.8998 58.4308 68.9816 58.399C69.1953 58.308 69.4181 58.2488 69.6455 58.2078C69.7546 58.1896 69.8546 58.1623 69.9683 58.1669C70.0865 58.1669 70.2002 58.1123 70.3184 58.085C70.6276 58.0121 70.9277 57.912 71.2551 57.9075C71.6006 57.7618 72.0053 57.7846 72.3645 57.689C72.4736 57.6572 72.6191 57.6708 72.7646 57.7209C73.1102 57.8392 73.1465 57.8483 73.0511 57.9803C73.0238 58.0212 72.9919 58.0668 72.9647 58.1032C72.9192 58.1669 72.8555 58.2306 72.7328 58.2807C72.56 58.3535 72.3963 58.4672 72.2417 58.581C72.1144 58.672 71.9962 58.7676 71.8416 58.7904C71.787 58.7995 71.7279 58.8359 71.6779 58.8632C71.4369 58.9997 71.1823 59.1044 70.9004 59.1363L70.8958 59.1408Z"
        fill="#6F3264"
      />
      <path
        d="M76.6522 69.3989C76.6158 69.7312 76.534 70.0816 76.6022 70.4411C76.6204 70.5367 76.584 70.6732 76.5476 70.7779C76.443 71.0965 76.343 71.4196 76.2884 71.7564C76.2794 71.8065 76.2566 71.8611 76.2566 71.9066C76.2748 72.389 76.0747 72.835 76.002 73.2992C75.9474 73.6269 75.8429 73.9455 75.852 74.2868C75.852 74.4734 75.7928 74.6873 75.7474 74.8875C75.6019 75.4837 75.4109 76.0754 75.1563 76.6534C75.0699 76.8308 74.9926 76.9856 74.9108 76.9947C74.8653 76.9992 74.788 77.1449 74.7471 77.1494C74.6561 77.1494 74.5561 76.8718 74.497 76.3666C74.4788 76.1436 74.4924 75.9161 74.497 75.6931C74.5106 75.2653 74.4833 74.8375 74.6379 74.4324C74.6925 74.2868 74.697 74.1002 74.7107 73.9318C74.7471 73.4494 74.8607 72.9897 74.9744 72.5301C75.0108 72.3844 75.0562 72.2297 75.0653 72.0841C75.0881 71.6927 75.1381 71.3104 75.2108 70.9235C75.2472 70.7415 75.27 70.564 75.3291 70.382C75.3882 70.1954 75.3927 69.9815 75.4245 69.7812C75.5064 69.2487 75.5655 68.7117 75.7201 68.1883C75.7565 67.5557 75.9747 66.9231 76.0566 66.2996C76.0838 66.1085 76.1657 65.8855 76.2794 65.6716C76.5567 65.1801 76.5794 65.1255 76.6522 65.3439C76.6749 65.4122 76.7022 65.4804 76.7249 65.5442C76.7613 65.6534 76.7841 65.7808 76.7704 66.0084C76.7522 66.3224 76.775 66.641 76.8022 66.955C76.8204 67.2053 76.8523 67.4465 76.7977 67.7059C76.775 67.8015 76.7841 67.9062 76.7841 68.0109C76.7931 68.4705 76.7613 68.9256 76.6567 69.3989H76.6522Z"
        fill="#6F3264"
      />
      <path
        d="M88.6469 65.5533C88.606 65.7717 88.5151 65.9947 88.5742 66.2451C88.5878 66.3133 88.5514 66.3998 88.5151 66.4635C88.4059 66.6592 88.3014 66.8595 88.2423 67.0825C88.2332 67.1143 88.2104 67.1462 88.2104 67.178C88.215 67.5057 88.0149 67.7742 87.9331 68.0746C87.874 68.2885 87.7603 68.4842 87.7648 68.7163C87.7648 68.8437 87.7012 68.9757 87.6512 69.1031C87.4966 69.4809 87.292 69.845 87.0328 70.1954C86.9464 70.3001 86.8646 70.3911 86.7873 70.3865C86.7418 70.3865 86.6645 70.4685 86.6281 70.4639C86.5372 70.4503 86.4463 70.2455 86.4008 69.895C86.3871 69.7403 86.4099 69.5901 86.4144 69.4399C86.4372 69.1532 86.419 68.8619 86.5781 68.6116C86.6327 68.5206 86.6418 68.3977 86.66 68.2839C86.7054 67.9654 86.8282 67.6741 86.9464 67.3828C86.9828 67.2873 87.0328 67.1962 87.0464 67.0961C87.0737 66.8367 87.1374 66.5864 87.2147 66.3361C87.251 66.2178 87.2829 66.104 87.342 65.9902C87.4056 65.8719 87.4102 65.7308 87.4466 65.5988C87.5421 65.2529 87.6057 64.9025 87.7694 64.5748C87.8194 64.1561 88.0422 63.7647 88.1422 63.3551C88.1741 63.2277 88.2514 63.0912 88.3741 62.9683C88.656 62.6816 88.6788 62.6497 88.7424 62.809C88.7606 62.85 88.7833 62.9 88.8061 62.9546C88.8379 63.032 88.8606 63.1276 88.8424 63.2778C88.8152 63.4871 88.8334 63.7056 88.8515 63.9195C88.8652 64.0924 88.8925 64.2608 88.8288 64.4246C88.8061 64.4838 88.8106 64.5566 88.8106 64.6249C88.8106 64.9343 88.7697 65.2393 88.656 65.5396L88.6469 65.5533Z"
        fill="#6F3264"
      />
      <path
        d="M45.0201 75.2334C45.5839 74.619 46.2023 73.9591 46.5615 73.1808C46.6706 72.9806 46.8616 72.6939 47.0116 72.4663C47.4845 71.7973 47.8664 71.06 48.1574 70.259C48.1983 70.1407 48.2529 70.0133 48.2802 69.8995C48.5485 68.7572 48.8804 67.6285 48.9486 66.4498C49.0122 65.626 49.0122 64.7841 48.8758 63.9603C48.7758 63.5098 48.7303 62.9591 48.6257 62.463C48.3484 60.9566 47.8664 59.4866 47.1889 58.0803C46.9889 57.6343 46.7797 57.2657 46.8115 57.1701C46.8297 57.1155 46.6342 56.756 46.657 56.715C46.6842 56.6649 46.8116 56.7605 47.0571 56.9881C47.1753 57.1018 47.3299 57.2429 47.4936 57.4295C47.6482 57.6207 47.83 57.8437 48.0256 58.1076C48.3484 58.5946 48.6439 59.118 48.894 59.655C49.376 60.679 49.7897 61.753 49.9125 62.8999C49.9352 63.3095 50.058 63.7965 50.0944 64.2516C50.249 65.5395 50.1626 66.8275 49.9261 68.0791C49.8398 68.4795 49.7534 68.8937 49.6624 69.276C49.4624 70.3045 49.0986 71.3058 48.6121 72.2478C48.3939 72.703 48.1347 73.1171 47.8528 73.5403C47.5345 73.9591 47.2435 74.4506 46.8888 74.8693C45.9795 76.0025 44.9201 77.0219 43.6879 77.8047C42.3374 78.8378 40.6506 79.5023 39.041 79.9847C38.5317 80.1167 37.9134 80.2304 37.3041 80.235C36.5902 80.2577 36.1946 80.1986 36.0491 80.1849C35.9036 80.1576 35.9991 80.1303 36.2583 80.0438C36.4083 79.9983 36.5629 79.9483 36.7266 79.8937C36.9858 79.8254 37.2859 79.7071 37.8542 79.5978C38.6363 79.393 39.382 79.1109 40.0686 78.7513C40.3459 78.6148 40.6142 78.4737 40.8779 78.3144C41.1507 78.1688 41.4235 78.0186 41.7236 77.8593C41.9373 77.741 42.1647 77.609 42.3602 77.4497C43.2877 76.7898 44.1562 76.0617 45.0246 75.2288L45.0201 75.2334Z"
        fill="#6F3264"
      />
      <path
        d="M36.6721 37.7097C36.763 37.7142 36.8403 37.7688 36.9676 37.6778C36.9995 37.6551 37.0313 37.6778 37.054 37.7006C37.1177 37.7779 37.1859 37.8462 37.2723 37.8735C37.2859 37.8735 37.295 37.8963 37.3087 37.8917C37.4542 37.8371 37.536 37.9918 37.6497 38.0237C37.7315 38.051 37.7997 38.1238 37.8997 38.092C37.9589 38.0738 38.0043 38.1147 38.0498 38.1466C38.1907 38.2285 38.3044 38.3923 38.409 38.5744C38.4363 38.6427 38.459 38.7109 38.4454 38.7837C38.4363 38.8292 38.459 38.8839 38.4454 38.9203C38.4317 38.9703 38.3999 39.0113 38.3499 39.0613C38.2999 39.1069 38.2226 39.1842 38.1453 39.2161C38.0771 39.2434 38.0134 39.2525 37.9452 39.2707C37.8224 39.2889 37.6906 39.3481 37.6087 39.2343C37.5815 39.1933 37.5269 39.207 37.4814 39.207C37.3496 39.207 37.245 39.1342 37.1359 39.0659C37.0995 39.0431 37.0677 39.0113 37.0268 39.0158C36.9176 39.0249 36.8176 39.0067 36.7221 38.9658C36.6766 38.9476 36.6312 38.9339 36.5948 38.8975C36.5539 38.8566 36.4948 38.8702 36.4448 38.852C36.3083 38.811 36.1674 38.8019 36.0537 38.6973C35.8764 38.7109 35.7491 38.5562 35.5899 38.5152C35.5445 38.497 35.4945 38.4561 35.4626 38.3559C35.3944 38.1193 35.3808 38.1238 35.4626 38.0283C35.4899 38.0009 35.5172 37.9736 35.5399 37.9463C35.5809 37.9054 35.6263 37.869 35.69 37.8644C35.7764 37.8644 35.8809 37.8053 35.9764 37.7597C36.0583 37.7188 36.1356 37.6733 36.1992 37.7051C36.222 37.7188 36.2538 37.7051 36.2856 37.6915C36.422 37.6505 36.5493 37.6414 36.6585 37.7097H36.6721Z"
        fill="#6F3264"
      />
      <path
        d="M36.4308 48.1817C36.5036 48.1726 36.5718 48.2136 36.6673 48.1044C36.6945 48.0771 36.7173 48.0953 36.74 48.1135C36.7991 48.1772 36.8582 48.2364 36.931 48.2455C36.9401 48.2455 36.9492 48.2637 36.9628 48.2546C37.081 48.1772 37.1538 48.3137 37.2492 48.3319C37.3175 48.341 37.3766 48.4093 37.4584 48.3592C37.5039 48.3319 37.5448 48.3683 37.5812 48.3865C37.6994 48.4457 37.804 48.5868 37.904 48.7461C37.9313 48.8098 37.954 48.8735 37.9449 48.9463C37.9404 48.9918 37.9631 49.0419 37.954 49.0783C37.9449 49.1329 37.9222 49.1739 37.8858 49.233C37.8494 49.2831 37.7903 49.3741 37.7312 49.4151C37.6767 49.4515 37.6266 49.4743 37.5721 49.5016C37.4766 49.538 37.3675 49.6199 37.2993 49.5198C37.272 49.4834 37.2311 49.5061 37.1947 49.5107C37.0856 49.5334 36.9992 49.4788 36.9082 49.4287C36.881 49.4105 36.8491 49.3832 36.8173 49.3969C36.7309 49.4242 36.6491 49.4196 36.5672 49.3969C36.5308 49.3878 36.4899 49.3832 36.4581 49.3468C36.4217 49.3104 36.3763 49.3332 36.3308 49.3286C36.2171 49.3104 36.1034 49.3241 36.0034 49.2376C35.8625 49.2831 35.7442 49.1466 35.6124 49.1375C35.5715 49.1238 35.5305 49.092 35.4987 49.0009C35.4305 48.7825 35.4169 48.787 35.4805 48.6824C35.4987 48.6505 35.5214 48.6187 35.5396 48.5913C35.5715 48.5458 35.6078 48.5003 35.6579 48.4867C35.7261 48.473 35.8079 48.4002 35.8852 48.341C35.9488 48.291 36.008 48.2318 36.0625 48.2546C36.0807 48.2637 36.108 48.2455 36.1307 48.2273C36.2398 48.1635 36.3444 48.1362 36.4354 48.1817H36.4308Z"
        fill="#6F3264"
      />
      <path
        d="M34.0486 63.5372C34.0122 63.6236 33.9303 63.6919 33.9758 63.8421C33.9895 63.883 33.9531 63.9058 33.9213 63.9195C33.8258 63.965 33.7348 64.015 33.6803 64.0924C33.6712 64.1061 33.653 64.1061 33.653 64.1243C33.653 64.2881 33.4802 64.3245 33.4075 64.4337C33.3529 64.5111 33.2574 64.5566 33.2574 64.6704C33.2574 64.7341 33.1983 64.766 33.1528 64.8069C33.0255 64.9252 32.83 64.9935 32.6163 65.0527C32.539 65.0618 32.4663 65.0663 32.4026 65.0299C32.3617 65.0072 32.3026 65.0163 32.2707 64.9935C32.2253 64.9662 32.198 64.9252 32.1662 64.857C32.1389 64.7933 32.0889 64.6931 32.0889 64.6067C32.0889 64.5293 32.098 64.461 32.1071 64.3882C32.1298 64.2608 32.1207 64.1061 32.2571 64.0605C32.3071 64.0423 32.3162 63.9832 32.3299 63.9377C32.3708 63.8011 32.4799 63.7192 32.5845 63.6282C32.6163 63.6009 32.6618 63.5781 32.6709 63.5326C32.6981 63.4188 32.7527 63.3233 32.8255 63.2413C32.8573 63.2004 32.8846 63.1594 32.9391 63.1321C32.9937 63.1048 33.0028 63.0365 33.0346 62.991C33.1165 62.8636 33.1801 62.7271 33.3211 62.6406C33.3665 62.4586 33.562 62.3721 33.653 62.2219C33.6894 62.1809 33.7439 62.14 33.853 62.14C34.1031 62.14 34.1077 62.1218 34.1668 62.2356C34.185 62.272 34.2032 62.3038 34.2168 62.3357C34.2395 62.3903 34.2623 62.4449 34.2441 62.5086C34.2168 62.5951 34.235 62.718 34.2441 62.8272C34.2532 62.9182 34.2714 63.0138 34.2168 63.0639C34.1986 63.0821 34.1986 63.123 34.1986 63.1549C34.1941 63.3051 34.1531 63.437 34.0531 63.5281L34.0486 63.5372Z"
        fill="#6F3264"
      />
      <path
        d="M42.2922 66.6546C42.2785 66.7411 42.2194 66.8093 42.2967 66.9413C42.3194 66.9777 42.2922 67.0005 42.2649 67.0187C42.183 67.0733 42.1103 67.1279 42.0785 67.2098C42.0739 67.2235 42.0557 67.228 42.0603 67.2417C42.1012 67.3873 41.9421 67.4465 41.8966 67.5557C41.8648 67.6331 41.7829 67.6877 41.8102 67.7878C41.8238 67.8424 41.7784 67.8834 41.7465 67.9243C41.6511 68.0472 41.4828 68.1382 41.2919 68.2202C41.2191 68.2384 41.1509 68.252 41.0827 68.2293C41.0418 68.2156 40.9827 68.2293 40.9463 68.2156C40.8963 68.1974 40.8599 68.161 40.8144 68.1064C40.7735 68.0563 40.7053 67.9699 40.678 67.8925C40.6598 67.8242 40.6507 67.7605 40.6417 67.6968C40.6326 67.5785 40.5871 67.4419 40.7053 67.3782C40.7462 67.3555 40.7417 67.3009 40.7462 67.2599C40.7553 67.1325 40.8372 67.0414 40.9145 66.9459C40.9372 66.914 40.9736 66.8913 40.9736 66.8503C40.9736 66.7456 41.0009 66.65 41.0509 66.5681C41.0736 66.5272 41.0872 66.4862 41.1327 66.4543C41.1782 66.4225 41.1691 66.3588 41.1873 66.3133C41.2373 66.1904 41.2646 66.0539 41.3737 65.9583C41.3737 65.7853 41.5419 65.6807 41.5919 65.535C41.6147 65.4941 41.6602 65.4486 41.7602 65.4349C41.9966 65.3985 41.9966 65.3803 42.083 65.4759C42.1103 65.5032 42.133 65.535 42.1558 65.5578C42.1921 65.6033 42.2285 65.6534 42.224 65.7125C42.2194 65.7944 42.2649 65.8991 42.3013 65.9992C42.3331 66.0812 42.374 66.1631 42.3331 66.2177C42.3194 66.2359 42.3331 66.2723 42.3331 66.2996C42.3649 66.4361 42.3604 66.559 42.2831 66.6546H42.2922Z"
        fill="#6F3264"
      />
      <path
        d="M47.7249 42.4975C47.8477 42.5111 47.9659 42.5703 48.1205 42.4838C48.1614 42.4611 48.2069 42.4838 48.2433 42.5157C48.3433 42.5976 48.4479 42.675 48.5706 42.7068C48.5888 42.7114 48.6025 42.7296 48.6206 42.725C48.8116 42.6795 48.9435 42.8433 49.1117 42.8889C49.2299 42.9207 49.33 43.0072 49.4664 42.9753C49.5391 42.9617 49.6119 43.0072 49.6801 43.039C49.8847 43.1346 50.0665 43.3121 50.2393 43.5078C50.2893 43.5806 50.3303 43.6534 50.3212 43.7263C50.3121 43.7718 50.353 43.8309 50.3484 43.8673C50.3348 43.9174 50.303 43.9584 50.2439 44.0084C50.1848 44.0494 50.0893 44.1268 49.9892 44.1541C49.8983 44.1768 49.8119 44.1859 49.7255 44.195C49.5618 44.2041 49.3891 44.2587 49.2617 44.1359C49.2163 44.0949 49.1435 44.0994 49.0799 44.0949C48.898 44.0903 48.7434 44.0039 48.5888 43.9265C48.5388 43.8992 48.4888 43.8628 48.4342 43.8673C48.2842 43.8673 48.1478 43.84 48.0114 43.7945C47.9477 43.7718 47.884 43.7581 47.8249 43.7126C47.7658 43.6671 47.684 43.6762 47.6112 43.658C47.4203 43.6079 47.2248 43.5852 47.052 43.4668C46.811 43.4668 46.6109 43.2985 46.3881 43.2484C46.3199 43.2256 46.2472 43.1801 46.1926 43.0709C46.0653 42.8206 46.0426 42.8251 46.1471 42.7341C46.179 42.7068 46.2108 42.6795 46.2381 42.6568C46.2881 42.6158 46.3472 42.5794 46.4291 42.5839C46.5473 42.5885 46.6791 42.5384 46.8064 42.4975C46.911 42.4611 47.0111 42.4201 47.102 42.4565C47.1338 42.4702 47.1793 42.4565 47.2157 42.452C47.3975 42.4155 47.5703 42.4201 47.7295 42.4975H47.7249Z"
        fill="#6F3264"
      />
      <path
        d="M43.7879 79.5387C44.0107 79.5705 44.2471 79.6479 44.4972 79.5705C44.5654 79.5523 44.6563 79.5842 44.7245 79.6115C44.9337 79.7071 45.1474 79.7935 45.3747 79.8299C45.4065 79.8345 45.4429 79.8572 45.4747 79.8527C45.8067 79.8117 46.1022 79.9801 46.4205 80.0165C46.6433 80.0484 46.8615 80.1212 47.0934 80.0893C47.2207 80.0666 47.3662 80.1075 47.5026 80.1349C47.9119 80.2213 48.3165 80.3442 48.7257 80.5217C48.8531 80.5809 48.9622 80.64 48.9758 80.7174C48.9849 80.7629 49.0895 80.8175 49.094 80.8585C49.1031 80.9495 48.9167 81.086 48.5711 81.2089C48.4166 81.2544 48.2574 81.2635 48.0983 81.2908C47.7982 81.3227 47.4935 81.3955 47.2025 81.2817C47.098 81.2408 46.9661 81.2544 46.8479 81.2544C46.5069 81.2544 46.1795 81.177 45.8567 81.0951C45.7521 81.0678 45.6475 81.0314 45.5429 81.0314C45.2656 81.0314 44.9928 80.995 44.7245 80.9358C44.5972 80.9085 44.4699 80.8858 44.3471 80.8357C44.2198 80.7811 44.0652 80.7811 43.9288 80.7493C43.556 80.6719 43.1786 80.6173 42.8285 80.458C42.3829 80.4125 41.9646 80.1804 41.5372 80.0666C41.4053 80.0302 41.2598 79.9437 41.137 79.8163C40.846 79.5205 40.8142 79.4931 40.9824 79.4385C41.037 79.4203 41.087 79.3976 41.137 79.3794C41.2189 79.3521 41.3144 79.3339 41.469 79.3566C41.6872 79.3885 41.91 79.3794 42.1328 79.3657C42.3101 79.3521 42.4784 79.3293 42.6511 79.393C42.7148 79.4158 42.7875 79.4112 42.8603 79.4112C43.1786 79.4112 43.4878 79.4431 43.7969 79.5432L43.7879 79.5387Z"
        fill="#6F3264"
      />
      <path
        d="M88.0605 70.6459C88.2515 70.5003 88.4834 70.3728 88.6152 70.1316C88.6516 70.0679 88.7425 70.0179 88.8153 69.9905C89.0426 69.895 89.2609 69.7948 89.461 69.6401C89.4882 69.6174 89.5292 69.6037 89.5519 69.5764C89.7656 69.2942 90.1112 69.1805 90.3703 68.962C90.5567 68.8118 90.775 68.6935 90.9159 68.4887C90.9932 68.3749 91.1296 68.2884 91.2524 68.202C91.6116 67.9426 92.0026 67.7105 92.43 67.5102C92.5665 67.451 92.6892 67.4055 92.7574 67.4465C92.7983 67.4738 92.9166 67.4283 92.9438 67.4465C93.0166 67.5011 92.9848 67.7378 92.8211 68.0927C92.7392 68.2429 92.6347 68.3749 92.5346 68.516C92.3391 68.7709 92.1709 69.0576 91.8708 69.2078C91.7662 69.2624 91.6798 69.3761 91.5889 69.4672C91.3388 69.7357 91.0387 69.9314 90.7386 70.1271C90.6431 70.1908 90.534 70.2454 90.4567 70.3228C90.2521 70.5367 90.0202 70.7187 89.7747 70.8826C89.6565 70.9599 89.5473 71.0418 89.4155 71.101C89.2791 71.1602 89.1654 71.2739 89.0381 71.365C88.7062 71.5971 88.3788 71.8428 87.9969 71.9976C87.6286 72.3025 87.1421 72.4572 86.7328 72.6939C86.6055 72.7667 86.4327 72.8168 86.2463 72.8213C85.8053 72.835 85.7644 72.8395 85.8507 72.6757C85.878 72.6211 85.9008 72.571 85.9235 72.5209C85.9644 72.439 86.0235 72.3571 86.1599 72.2615C86.3464 72.1205 86.51 71.9475 86.6646 71.7746C86.7874 71.6335 86.9011 71.4924 87.0739 71.4014C87.1375 71.3695 87.1921 71.3104 87.2421 71.2557C87.4831 71.0191 87.7331 70.8052 88.0423 70.6368L88.0605 70.6459Z"
        fill="#6F3264"
      />
      <path
        d="M27.9189 94.2251V104.16L32.2476 99.4452L37.9448 104.16L35.9578 92.3046L27.9189 94.2251Z"
        fill="#6F3264"
      />
      <path
        d="M42.7783 93.9155L45.0063 99.5771L48.23 96.7463L52.6859 99.4451L49.7714 89.5465L42.7783 93.9155Z"
        fill="#6F3264"
      />
      <path
        d="M75.8569 89.5738L77.271 97.8431L80.8039 94.225C80.8039 94.225 84.8461 101.516 84.4232 97.8431C84.0004 94.1704 83.8958 88.213 83.8958 88.213L75.8569 89.5738Z"
        fill="#6F3264"
      />
      <path
        d="M88.3604 88.8775L92.1161 97.8432L94.5804 93.1737L99.1455 96.223L96.1582 86.2561L88.3604 88.8775Z"
        fill="#6F3264"
      />
      <path
        d="M11.8234 53.4384C11.3824 53.4384 10.9459 53.3792 10.523 53.2518C9.26811 52.874 8.18596 51.8182 7.70399 50.4984C7.26749 49.2969 7.37662 48.0499 8.01318 47.0805C8.29054 46.6618 8.8498 46.5435 9.27266 46.8165C9.69097 47.0896 9.80919 47.6539 9.53638 48.0772C9.2181 48.5596 9.17717 49.2149 9.41816 49.8749C9.70916 50.6667 10.3321 51.2948 11.0505 51.5087C12.2145 51.8591 13.624 51.3676 14.5697 50.3664C12.6783 48.8691 11.8371 46.1703 12.6873 43.8629C13.1466 42.6113 14.356 41.5145 15.7019 41.9696C16.8386 42.3519 17.1796 43.5944 17.2933 44.004C17.848 46.0292 17.7935 47.9816 17.1478 49.62C18.2527 49.8475 19.4849 49.5699 20.4079 48.91C19.6213 48.5459 18.9756 47.968 18.5664 47.2443C17.9935 46.2294 17.9071 44.9642 18.33 43.7719C18.7529 42.5795 19.6168 41.6511 20.7035 41.2232L21.19 41.0321L21.5992 41.3552C23.3224 42.7206 24.059 45.0598 23.5998 47.1579C23.6407 47.1306 23.6771 47.1032 23.718 47.0759C24.6274 46.3751 25.0002 45.1144 25.3594 43.6581C25.4822 43.1711 25.9733 42.8707 26.4598 42.9936C26.9463 43.1119 27.2464 43.608 27.1236 44.095C26.6962 45.8244 26.2006 47.4628 24.8229 48.5232C24.1909 49.0056 23.4134 49.2832 22.5904 49.3287C21.1218 51.2129 18.48 52.0048 16.2203 51.2584C15.1199 52.6283 13.4558 53.4475 11.8371 53.4475L11.8234 53.4384ZM15.0835 43.6899C14.9153 43.6899 14.5561 44.0222 14.3833 44.4909C13.8513 45.9336 14.3697 47.7267 15.5064 48.7735C15.9383 47.5356 15.952 46.0246 15.5291 44.4818C15.3427 43.7992 15.1517 43.7036 15.1154 43.6945C15.1063 43.6945 15.0972 43.6899 15.0835 43.6899ZM20.8808 43.1757C20.5079 43.4578 20.2078 43.872 20.0305 44.3726C19.785 45.0689 19.8214 45.7834 20.1351 46.3432C20.4261 46.8575 20.9535 47.2398 21.5992 47.4127C22.163 46.0428 21.8629 44.2861 20.8808 43.1802V43.1757Z"
        fill="#F3D7E8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1019_6469">
        <rect
          width="109.12"
          height="83.08"
          fill="white"
          transform="translate(7.43994 21.08)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default PigSticker
