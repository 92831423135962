/* eslint-disable complexity */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Image from 'components/image'
import { getLoggedIn } from 'cart/reducers'
import Link from 'components/link'
import InStockAlertButton from 'store/components/in-stock-alert-button'
import { track, useTracking } from 'lib/analytics'
import { ItemShape } from 'cart/lib/prop-types'
import { getGiftCardProductId } from 'cart/lib'
import { ProductCallouts } from 'components/ProductCallouts/ProductCallouts'
import {
  Dropdown,
  IconButton,
  PriceTag,
  PromoMessage,
  QuantityPicker,
} from 'baby-design'
import { GarbageBin } from 'baby-design/icons'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { CartItemsAPI } from 'src/routes/(shop)/api/cartItems'
import { numberToCurrency } from 'lib/money'
import css from './bl-cart-order-item.scss'

const BLCartOrderItem = ({
  cartItem,
  onRemoveCartItem,
  useRegistryDiscount,
  isOutOfStock,
  isLoggedIn,
}) => {
  const tracker = useTracking()
  const [localQuantity, setLocalQuantity] = useState(cartItem.quantity)
  const { mutate: updateCartItem } = CartItemsAPI.useUpdateMutation()

  const handleUpdateCartItem = (updatedCartItem) => {
    const newQuantity = updatedCartItem.quantity
    setLocalQuantity(newQuantity)

    tracker.trackEvent({
      event: track.cartModified,
      eventType: track.EventType.ITEM_QUANTITY_UPDATED,
      cartItem: updatedCartItem,
    })

    updateCartItem(updatedCartItem, {
      onError: () => {
        setLocalQuantity(cartItem.quantity)
      },
    })
  }

  const {
    callouts,
    giftCardOptions,
    productId,
    imageUrl,
    isGiftCard,
    isEligibleForRegistryDiscount,
    name,
    price,
    quantity,
    quantityLimit,
    registryDiscountPrice,
    extendedDeliveryTimeMessage,
    storeUrl,
  } = cartItem

  const {
    msrp,
    currentPrice,
    currentlyInActiveSale,
    callouts: saleCallouts,
  } = getPriceTagPropsFromPriceDetails(cartItem.priceDetails, {
    showCalloutTypes: ['text'],
  })

  const allowsQuantityAdjustment = !isGiftCard

  const usingRegistryDiscount = useRegistryDiscount && registryDiscountPrice

  const priceTagProps = {
    callouts: usingRegistryDiscount ? [] : saleCallouts,
    currentPrice: usingRegistryDiscount
      ? numberToCurrency(registryDiscountPrice, { unit: '' })
      : currentPrice,
    currentlyInActiveSale: usingRegistryDiscount ? true : currentlyInActiveSale,
    msrp,
  }

  // Formats options for gift card dropdown
  const giftCardOptionsObject =
    giftCardOptions?.map((option) => ({
      label: numberToCurrency(option.price, {
        unit: '$',
        truncateZeroCents: true,
      }),
      value: option.price,
    })) || []

  const removeComponent = (
    <IconButton
      aria-label="Remove"
      className="mrl"
      size="sm"
      variant="fill-secondary"
      onClick={() => onRemoveCartItem(cartItem)}
    >
      <GarbageBin />
    </IconButton>
  )

  const notEligibleForRegistryDiscountComp =
    useRegistryDiscount && !isEligibleForRegistryDiscount ? (
      <div className="tiny mvs text-muted">
        Not eligible for registry discount.
      </div>
    ) : null

  return (
    <div className={css.BLCartOrderItem} id={productId}>
      <div aria-hidden="true" className={css.BLCartOrderItem__image}>
        <Link url={storeUrl}>
          <Image height={100} role="presentation" src={imageUrl} width={100} />
        </Link>
      </div>
      <div className="flex col flex-grow">
        <Link className={css.BLCartOrderItem__name} url={storeUrl}>
          {name}
        </Link>
        <ProductCallouts callouts={callouts} textSize={13} />
        <div className={css.BLCartOrderItem__messaging}>
          <div className="visible-xs">
            <PriceTag {...priceTagProps} showSavings size="xs" />
            {usingRegistryDiscount && (
              <PromoMessage size="xs" text="Registry discount applied" />
            )}
            {extendedDeliveryTimeMessage && (
              <p className="h7 mtm text-muted">{extendedDeliveryTimeMessage}</p>
            )}
            {notEligibleForRegistryDiscountComp}
          </div>
        </div>
        <div className={css.BLCartOrderItem__actions}>
          <div>
            {isGiftCard && (
              <div className="flex">
                {removeComponent}
                <Dropdown
                  options={giftCardOptionsObject}
                  size="sm"
                  value={giftCardOptionsObject.find(
                    (option) => option.value === price
                  )}
                  onChange={(option) =>
                    handleUpdateCartItem({
                      ...cartItem,
                      productId: getGiftCardProductId(
                        option.value,
                        giftCardOptions
                      ),
                      price: option.value,
                    })
                  }
                />
              </div>
            )}
            {allowsQuantityAdjustment && (
              <QuantityPicker
                max={isOutOfStock ? 1 : Math.max(quantity, quantityLimit)}
                size="sm"
                type="cart"
                value={localQuantity}
                variant="fill"
                onMinusClick={() =>
                  handleUpdateCartItem({
                    ...cartItem,
                    quantity: localQuantity - 1,
                  })
                }
                onPlusClick={() =>
                  handleUpdateCartItem({
                    ...cartItem,
                    quantity: localQuantity + 1,
                  })
                }
                onTrashClick={() => onRemoveCartItem(cartItem)}
              />
            )}
          </div>
          {isOutOfStock && isLoggedIn && (
            <div className="mtl">
              <InStockAlertButton productId={productId} />
            </div>
          )}
        </div>
      </div>
      <div className="hidden-xs text-right">
        <PriceTag {...priceTagProps} showSavings align="right" size="xs" />
        {usingRegistryDiscount && (
          <PromoMessage
            align="right"
            size="xs"
            text="Registry discount applied"
          />
        )}
        {notEligibleForRegistryDiscountComp}
      </div>
    </div>
  )
}

BLCartOrderItem.propTypes = {
  cartItem: PropTypes.shape(ItemShape).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  onRemoveCartItem: PropTypes.func.isRequired,
  useRegistryDiscount: PropTypes.bool,
  isOutOfStock: PropTypes.bool,
  extendedDeliveryTimeMessage: PropTypes.string,
}

BLCartOrderItem.defaultProps = {
  isOutOfStock: false,
  useRegistryDiscount: false,
}

const mapStateToProps = (state) => ({
  isLoggedIn: getLoggedIn(state),
})

export default connect(mapStateToProps, {})(BLCartOrderItem)
