import { includes } from 'lodash'

export const getQuestionText = function (questionId, productName = null) {
  let question = questions[questionId]

  if (productName && includes(question, ':product_name:')) {
    question = question.replace(/:product_name:/, productName)
  }
  return question
}

// THESE IDS ARE REFERENCED IN THE DATABASE ON ReviewAnswer.question_id
// the current question 2 is dynamic based on the answer to question1
// we fall back to question 2 if we don't know what question to ask
export const questions = {
  1: "What's your overall rating for :product_name:?",
  2: 'Why did you give the answer that you did?',
  3: "Now that you've been using the :product_name:, which words would you use to describe it?",
  4: "Do you have any other thoughts about :product_name: for someone who's never used it before?",
  5: "If possible, we'd like to show our parents that you are a real person by linking your name to a page online (e.g. a social account). We don't import your data. Would you like your name to be linked?",
  6: "Please input a link to your Instagram, Pinterest, blog, Tumblr, Twitter, etc. This is a link we'll use along with your review of :product_name:.",
  7: 'Would you recommend it to a friend?',
}

export const question1Answers = [
  'Love it!',
  'Like it',
  "It's OK",
  'Meh',
  'Disappointed',
]
export const question7Answers = ['Yes', 'No', 'Not using it enough to know']
