import React from 'react'
import { Button, Image } from 'baby-design'
import { ArrowRight } from 'baby-design/icons'
import * as blUrls from 'lib/urls'
import { useNavigate } from 'react-router-dom-v5-compat'
import css from './instructions.styles.scss'

interface InstructionProps {
  additionalContent?: React.ReactNode
  imageUrl?: string
  text: string | React.ReactNode
  title?: string
}

const Instruction: React.FC<InstructionProps> = ({
  additionalContent,
  imageUrl,
  text,
  title,
}) => (
  <div>
    <div className={css.Instruction__textContainer}>
      {title && <h2 className={css.Instruction__title}>{title}</h2>}
      <span className={css.Instruction__text}>{text}</span>
    </div>
    {imageUrl && (
      <Image
        className={css.Instruction__image}
        role="presentation"
        src={imageUrl}
      />
    )}
    {additionalContent && (
      <div className={css.Instruction__additionalContent}>
        {additionalContent}
      </div>
    )}
  </div>
)

const Instructions: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className={css.Instructions}>
      <h3 className={css.Instructions__header}>
        How to enable the Babylist share extension to save items from anywhere
      </h3>
      <div className={css.Instructions__content}>
        <Instruction
          imageUrl="https://images.babylist.com/image/upload/v1730316227/extension-instructions/ext-step-1.png"
          text={
            <p>
              Find the <strong>Share</strong> button in your browser.
            </p>
          }
          title="Step 1"
        />
        <hr className={css.Instructions__divider} />
        <Instruction
          additionalContent={
            <Instruction
              imageUrl="https://images.babylist.com/image/upload/v1730316227/extension-instructions/ext-step-2b.png"
              text={
                <>
                  If you don't see it swipe
                  <ArrowRight />
                  <p>
                    and tap <strong>More.</strong>
                  </p>
                </>
              }
            />
          }
          imageUrl="https://images.babylist.com/image/upload/v1730316227/extension-instructions/ext-step-2a.png"
          text={
            <p>
              Find the <strong>Babylist app icon</strong>.
            </p>
          }
          title="Step 2"
        />
        <hr className={css.Instructions__divider} />
        <Instruction text="Click the icon to add your item!" title="Step 3" />
      </div>
      <Button
        size="lg"
        variant="primary"
        onClick={() => navigate(blUrls.shareExtensionHowItWorksPath)}
      >
        Watch a tutorial
      </Button>
    </div>
  )
}

export default Instructions
