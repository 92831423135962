import {
  babylistHealth,
  freeBabyStuffGuide,
  freeShippingFaqPath,
  newRegistryPath,
  registryDiscountPath,
  helloBabyBoxHowToGet,
  storePath,
  storeCategoryPath,
  storeSalePath,
  registryHomePath,
} from 'lib/urls'
import { CONFIG, FREE_SHIPPING_THRESHOLD } from 'shared/constants'
import { Question } from '../../store/components/FrequentlyAskedQuestions/FrequentlyAskedQuestions.types'
import { CurrentUser } from '../../global'

const salePath = storeCategoryPath('sale')

interface CouponCardData {
  thumbnail: string
  title: string
  description: string
  ctaText: string
  ctaLink: string
  detailsLink: string
}

export const DISCOUNTS = [
  {
    thumbnail:
      'https://images.babylist.com/image/upload/q_auto,f_auto,w_170,h_170,c_mpad/v1700064435/Sale_thumbnail_iduo2h.png',
    title: 'Babylist Sale and Deals',
    description: "Shop what's on sale right now.",
    ctaText: 'Shop Sale',
    ctaLink: storeSalePath,
    detailsLink: '',
  },
  {
    thumbnail:
      'https://images.babylist.com/image/upload/q_auto,f_auto,w_170,h_170,c_mpad/v1686776340/15_discount_yjhslj.svg',
    title: `${CONFIG.store.registryDiscountPctDisplay} Registry Discount`,
    description: `Get a ${CONFIG.store.registryDiscountPctDisplay} registry completion discount on the Babylist Shop.`,
    ctaText: 'Start Your Babylist Registry',
    ctaLink: newRegistryPath,
    detailsLink: registryDiscountPath,
  },
]

export const buildOffers = (
  currentUser: CurrentUser | undefined
): CouponCardData[] => [
  {
    thumbnail:
      'https://images.babylist.com/image/upload/q_auto,f_auto,w_170,h_170,c_mpad/v1686776039/free_thumbnail_zpfo1f.svg',
    title: 'Free Breast Pump',
    description:
      'Babylist Health works with most major insurance plans to help you get your free or discounted breast pump.',
    ctaText: 'Check Eligibility',
    ctaLink: `${babylistHealth}?utm_source=babylist&utm_medium=organic-referral&utm_campaign=babylist-health&utm_content=coupons`,
    detailsLink: '',
  },
  {
    thumbnail:
      'https://images.babylist.com/image/upload/q_auto,f_auto,w_170,h_170,c_mpad/v1686776039/free_thumbnail_zpfo1f.svg',
    title: 'Free Babylist Hello Baby Box',
    description:
      'Create a Babylist registry today to be eligible for a free baby box full of goodies for you and baby.',
    ctaText: 'Get Freebie',
    ctaLink: currentUser ? `${registryHomePath}?hbb=true` : newRegistryPath,
    detailsLink: helloBabyBoxHowToGet,
  },
  {
    thumbnail:
      'https://images.babylist.com/image/upload/q_auto,f_auto,w_170,h_170,c_mpad/v1686776039/free_thumbnail_zpfo1f.svg',
    title: 'More Free Baby Stuff',
    description:
      "From formula & diapers to postpartum recovery products, you'll only pay for shipping (or nothing!) for this stuff",
    ctaText: 'See All Offers',
    ctaLink: freeBabyStuffGuide,
    detailsLink: '',
  },
]

export const OFFERS = buildOffers(undefined)

export const OTHER = [
  {
    thumbnail:
      'https://images.babylist.com/image/upload/q_auto,f_auto,w_170,h_170,c_mpad/v1686776226/free_shipping_bpooe8.svg',
    title: 'Free Standard Shipping',
    description: `Get free standard shipping on the best baby gear orders over $${FREE_SHIPPING_THRESHOLD} in The Babylist Shop`,
    ctaText: 'Get Free Shipping',
    ctaLink: storePath,
    detailsLink: freeShippingFaqPath,
  },
]

export const FAQS: Question[] = [
  {
    id: '1',
    question: 'Does Babylist price match with other retailers?',
    answer: `
      <p>No, we are not able to price match with other retailers. Our prices are predetermined in our partnership agreements with our vendors and we are not authorized to sell their products at a reduced price.</p>
      <br />
      <p>However, Babylist gives you the freedom to purchase your gift from anywhere you'd like. If you find an item for a better price at a different retailer, feel free to buy it there instead!</p>
    `,
  },
  {
    id: '2',
    question: 'What can my Babylist registry discount be used on?',
    answer: `
      <p>The <a href="${registryDiscountPath}">Babylist registry discount</a> can be used on items sold in our <a href="${storePath}">Babylist Shop</a>. ${CONFIG.store.registryDiscountExclusionsText.replace(
        /\n/g,
        '<br>'
      )}</p>
      <br />
      <p>It expires 6 months after your baby's expected arrival date. <a href="${registryDiscountPath}">You can find this information, brand exclusions and more on registry discounts here.</a></p>
    `,
  },
  {
    id: '3',
    question: 'Does Babylist have sales?',
    answer: `
      <p>Absolutely! Our largest sales usually take place during key holidays such as Labor Day, Memorial Day, President's Day, and Black Friday/Cyber Monday. We also have marked down items on our <a href="${salePath}">Sale page</a> so be sure to check it regularly to snag great deals on a wide range of items.</p>
    `,
  },
]

export const GUIDE_SLUGS = [
  'free-baby-stuff-while-pregnant',
  'pampers-club-how-it-works',
  'how-to-save-money-on-baby-gear',
  'save-vs-splurge',
]
