import Link from 'components/link'
import * as blUrls from 'lib/urls'
import css from './styles.scss'

interface PdfLinkProps {
  displayText?: boolean
}

export const PdfLink = (props: PdfLinkProps) => {
  const { displayText } = props

  return (
    <div className={css.PdfLink}>
      <Link
        newWindow
        aria-label="download checklist"
        className={css.Link}
        url={blUrls.checklistPdfPath}
      >
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.42 1.00001H8.76001L3.02002 6.73002V21.8C3.02263 22.0989 3.1425 22.3848 3.35385 22.5962C3.5652 22.8075 3.85114 22.9274 4.15002 22.93H20.44C20.738 22.9274 21.0228 22.8072 21.2326 22.5955C21.4423 22.3839 21.56 22.098 21.56 21.8V2.13002C21.56 1.98078 21.5305 1.83302 21.4731 1.69527C21.4156 1.55751 21.3315 1.4325 21.2255 1.32744C21.1195 1.22237 20.9938 1.13934 20.8555 1.08314C20.7172 1.02695 20.5692 0.998693 20.42 1.00001ZM8.55002 3.66002V6.51002H5.67001L8.55002 3.66002ZM19.76 21.18H4.76001V8.29002H9.13C9.44296 8.29002 9.74309 8.1657 9.96439 7.94441C10.1857 7.72312 10.31 7.42299 10.31 7.11003V2.75001H19.76V21.18Z"
            fill="#6F3264"
          />
          <path
            clipRule="evenodd"
            d="M15.7612 14.8121L13.0885 17.5984C12.7701 17.9288 12.2539 17.9288 11.9355 17.5984L9.2388 14.8121C8.9204 14.4816 8.9204 13.9459 9.2388 13.6154C9.55719 13.285 10.0734 13.285 10.3918 13.6154L11.6967 14.9572L11.6967 8.84619C11.6967 8.37887 12.0617 8.00004 12.512 8.00004C12.9623 8.00004 13.3273 8.37887 13.3273 8.84619L13.3273 14.9572L14.6082 13.6154C14.9266 13.285 15.4428 13.285 15.7612 13.6154C16.0796 13.9459 16.0796 14.4816 15.7612 14.8121Z"
            fill="#6E3264"
            fillRule="evenodd"
          />
        </svg>
        {displayText && <span className={css.Text}>Download PDF</span>}
      </Link>
    </div>
  )
}
