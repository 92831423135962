import css from './SquiggleDivider.scss'

const SquiggleDivider = () => (
  <div className={css.squiggleDivider}>
    <img
      alt="divider"
      src="https://images.babylist.com/image/upload/f_auto,q_auto/v1691183576/Onboarding/gray_squiggle_divider.png"
    />
  </div>
)

export default SquiggleDivider
