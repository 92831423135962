import { openModal } from './modal-connect'
import {
  ADD_TO_CART_MODAL,
  CROWDFUND_RESERVATION_MODAL,
  FUND_THIS_GIFT_MODAL,
  ENABLE_GROUP_GIFT_MODAL,
  BOOKMARKLET_INSTALL_INSTRUCTIONS_MODAL,
  BOOKMARKLET_USAGE_INSTRUCTIONS_MODAL,
  DELETE_REG_ITEM_MODAL,
  EDIT_REG_ITEM_MODAL,
  GROUP_GIFT_REDEMPTION_MODAL,
  MOBILE_FILTERS_MODAL,
  OWNER_RESERVE_MODAL,
  OWNER_MARK_AS_PURCHASED_MODAL,
  REDIRECT_MODAL,
  RESERVE_PURCHASED_GIFT_MODAL,
  UNRESERVE_MODAL,
  REGISTRY_VIEW_PURCHASE_INFO_MODAL,
  RECOVER_RESERVATIONS_BY_EMAIL,
  INACCURACY_REPORT_MODAL,
  CONTACT_INFO_MODAL,
  GIFT_MESSAGE_MODAL,
  GIFT_TRACKER_MODAL,
  QUICK_VIEW_MODAL,
  THANK_YOU_ADDRESS_MODAL,
} from './index'

import {
  HELLO_BABY_BOX,
  INSTALL_BABYLIST_APP,
  INSTALL_BABYLIST_EXTENSION,
} from '../constants'

// These actions should be in a file separate from the modal components to avoid
// circular dependencies when needing an openModal action within another modal.

export const openReservePurchasedGiftModal = (
  regItem,
  registry,
  onSubmit,
  reservation,
  initialStep
) =>
  openModal(RESERVE_PURCHASED_GIFT_MODAL, {
    regItem,
    registry,
    onSubmit,
    reservation,
    initialStep,
  })
export const openUnreserveModal = (onSubmitSuccess, registry, reservation) =>
  openModal(UNRESERVE_MODAL, { onSubmitSuccess, registry, reservation })
export const openAddToCartModal = (regItem, onSubmit) =>
  openModal(ADD_TO_CART_MODAL, { regItem, onSubmit })
export const openGroupGiftRedemptionModal = (regItem, action) =>
  openModal(GROUP_GIFT_REDEMPTION_MODAL, { regItem, action })
export const openMobileFiltersModal = () => openModal(MOBILE_FILTERS_MODAL)
export const openRegItemEditModal = (regItemId) =>
  openModal(EDIT_REG_ITEM_MODAL, { regItemId })
export const openRegItemDeleteModal = (regItemId) =>
  openModal(DELETE_REG_ITEM_MODAL, { regItemId })
export const openInaccuracyReportModal = (offer, regItemId) =>
  openModal(INACCURACY_REPORT_MODAL, { offer, regItemId })
export const openRedirectModal = ({
  url,
  storeDisplayName,
  onRedirectSuccess,
  isShopRegistryView,
}) =>
  openModal(REDIRECT_MODAL, {
    url,
    storeDisplayName,
    onRedirectSuccess,
    isShopRegistryView,
  })
export const openContactInfoModal = (data) =>
  openModal(CONTACT_INFO_MODAL, data)
export const openOwnerReserveModal = (regItem) =>
  openModal(OWNER_RESERVE_MODAL, { regItem })
export const openOwnerMarkAsPurchasedModal = (
  regItemId,
  defaultQuantity,
  onSuccess
) =>
  openModal(OWNER_MARK_AS_PURCHASED_MODAL, {
    regItemId,
    defaultQuantity,
    onSuccess,
  })
export const openBookmarkletUsageModal = ({ url, storeDisplayName }) =>
  openModal(BOOKMARKLET_USAGE_INSTRUCTIONS_MODAL, { url, storeDisplayName })
export const openBookmarkletInstallModal = ({ url, storeDisplayName }) =>
  openModal(BOOKMARKLET_INSTALL_INSTRUCTIONS_MODAL, { url, storeDisplayName })
export const openCrowdfundReservationModal = (regItem) =>
  openModal(CROWDFUND_RESERVATION_MODAL, { regItem })
export const openFundThisGiftModal = (regItem) =>
  openModal(FUND_THIS_GIFT_MODAL, { regItem })
export const openEnableGroupGiftModal = (regItem, isFirstTime) =>
  openModal(ENABLE_GROUP_GIFT_MODAL, { regItem, isFirstTime })
export const openRecoverReservationModal = (regItemId) =>
  openModal(RECOVER_RESERVATIONS_BY_EMAIL, { regItemId })
export const openRegistryViewPurchaseInformationModal = ({
  regItem,
  reservation,
}) => openModal(REGISTRY_VIEW_PURCHASE_INFO_MODAL, { regItem, reservation })
export const openGiftMessageModal = (giftMessage, onSubmitGiftMessage) =>
  openModal(GIFT_MESSAGE_MODAL, { giftMessage, onSubmitGiftMessage })
export const openGiftTrackerModal = () => openModal(GIFT_TRACKER_MODAL)
export const openQuickViewModal = (productId, onAddToCartSuccess) =>
  openModal(QUICK_VIEW_MODAL, { productId, onAddToCartSuccess })
export const openThankYouAddressModal = ({ afterSubmit, giftGiverInfo }) =>
  openModal(THANK_YOU_ADDRESS_MODAL, { afterSubmit, giftGiverInfo })

// ModalManager managed modals
export const openHelloBabyBoxModal = () => openModal(HELLO_BABY_BOX)
export const openInstallBabylistAppModal = () => openModal(INSTALL_BABYLIST_APP)
export const openInstallBabylistExtensionModal = () =>
  openModal(INSTALL_BABYLIST_EXTENSION)
