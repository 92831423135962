import classNames from 'classnames'
import RouterLink from 'components/link'
import Tappable from 'react-tappable'
import css from './onboarding-buttons.scss'
import { InfoButton } from 'components/buttons'

const Button = ({ children, ...buttonProps }) => (
  <InfoButton {...buttonProps} empty block>
    {children}
  </InfoButton>
)
const Link = ({ children, to, url, onClick, ...buttonProps }) => (
  <RouterLink to={to} url={url} onClick={onClick} routerVersion={4}>
    <div
      {...buttonProps}
      className={classNames(
        'btn-empty btn btn-info btn-block',
        css.continueButton
      )}
    >
      {children}
    </div>
  </RouterLink>
)

export const NextButton = (props) =>
  props.to || props.url ? (
    <Link
      {...props}
      className={classNames(props.className, css.continueButton)}
    >
      Next
    </Link>
  ) : (
    <Button
      {...props}
      className={classNames(props.className, css.continueButton)}
    >
      Next
    </Button>
  )

export const ButtonGroup = ({ children }) => (
  <div
    role="radiogroup"
    aria-labelledby="onboardingQuestion"
    className={css.buttonGroup}
  >
    {children}
  </div>
)

export const ButtonRect = ({ children, className, onClick }) => (
  <Tappable
    role="radio"
    className={classNames(css.buttonRect, className)}
    classes={{ active: css.buttonActive }}
    onTap={onClick}
    component="button"
  >
    <span className={css.buttonContent}>{children}</span>
  </Tappable>
)

export const ButtonRound = ({ children, onClick }) => (
  <Tappable
    role="radio"
    className={css.buttonRound}
    classes={{ active: css.buttonActive }}
    onTap={onClick}
    component="button"
  >
    <span className={css.buttonContent}>{children}</span>
  </Tappable>
)
