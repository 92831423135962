import MultiItemSlider, {
  MultiItemSliderProps,
} from 'components/MultiItemSlider'
import { ProductCardData } from '../../../global'
import ProductCardAction from './ProductCardAction'
import ProductCardV2 from '../../../store/components/ProductCardV2'

export interface ProductSliderProps extends MultiItemSliderProps {
  originalCartSubTotal?: number | null
  /**
   * A  list of products containing the data to render a `<ProductCard />`.
   */
  products: ProductCardData[]
  /**
   * Any shared tracking metadata. This will be passed to the viewed and clicked event.
   * @default {}
   */
  trackingData?: any
  /**
   * The title of the section. This will be displayed above the slider.
   */
  title: string
  isMobile?: boolean
}

export default function ProductRecommendationSlider({
  originalCartSubTotal,
  products,
  title,
  trackingData = {},
  isMobile,
  ...rest
}: ProductSliderProps) {
  return (
    <MultiItemSlider
      aria-label={title}
      columnClassName="col-sm-3 col-xs-10"
      title={title}
      {...rest}
    >
      {products.map((product, idx) => (
        <ProductCardV2
          key={product.gpId}
          {...product}
          __forcePageLoad
          actionButton={
            <ProductCardAction
              useHeartbeatDS
              originalCartSubTotal={originalCartSubTotal}
              product={product}
            />
          }
          isMobile={isMobile}
          position={idx + 1}
          slugSet={null}
        />
      ))}
    </MultiItemSlider>
  )
}
