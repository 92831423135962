import PropTypes from 'prop-types'
import { forEach, get, groupBy, keys, uniq } from 'lodash'
import ExternalReservedItemsHeader from 'cart/components/external-reserved-items-header'
import StoreOrderWrapper from './store-order-wrapper'
import { ExternalStoreOrder } from './external-store-order'
import { getTotalQuantity, sortStoresByPriority } from '../lib'

const ExternalReservedItems = ({
  alerts,
  disableBabylistOffer,
  registry,
  onRemoveAlert,
  onSetPurchasedReservation,
  onSetReservationToRemove,
  uncompletedReservations,
}) => {
  const uncompletedReservationsByStore = groupBy(
    uncompletedReservations,
    'store'
  )
  const storeOrders = []

  const reservationStores = keys(uncompletedReservationsByStore)
  const storeNames = sortStoresByPriority(uniq(reservationStores))
  const totalQuantity = getTotalQuantity(uncompletedReservations)

  forEach(storeNames, (storeName) => {
    const reservations = uncompletedReservationsByStore[storeName] || []
    const storeOrder = (
      <ExternalStoreOrder
        disableBabylistOffer={disableBabylistOffer}
        registry={registry}
        reservations={reservations}
        storeName={storeName}
        onSetReservationToRemove={onSetReservationToRemove}
        onSetPurchasedReservation={onSetPurchasedReservation}
      />
    )

    storeOrders.push(
      <StoreOrderWrapper
        alert={get(alerts, storeName)}
        className="mbxl"
        key={storeName}
        storeName={storeName}
        showTitle={storeNames && storeNames.length > 1}
        onRemoveAlert={onRemoveAlert}
        reservationCount={reservations.length}
      >
        {storeOrder}
      </StoreOrderWrapper>
    )
  })

  return (
    <div id="reserved-items">
      <ExternalReservedItemsHeader
        className="mbl"
        count={totalQuantity}
        registry={registry}
        storeNames={storeNames}
      />
      <ul className="list-unstyled">{storeOrders}</ul>
    </div>
  )
}

ExternalReservedItems.propTypes = {
  alerts: PropTypes.object,
  disableBabylistOffer: PropTypes.bool,
  onRemoveAlert: PropTypes.func.isRequired,
  onSetReservationToRemove: PropTypes.func.isRequired,
  onSetPurchasedReservation: PropTypes.func.isRequired,
  registry: PropTypes.object.isRequired,
  uncompletedReservations: PropTypes.array.isRequired,
}

ExternalReservedItems.defaultProps = {
  alerts: undefined,
  disableBabylistOffer: false,
}

export default ExternalReservedItems
