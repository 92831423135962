import { Col, Image, Row } from 'react-bootstrap'
import { times, startCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { babylistBabesPath } from 'lib/urls'
import { format, previousMonday, nextMonday } from 'date-fns'
import css from './babylist-babes.scss'
import { CANONICAL_URL } from 'shared/constants'

interface Birthday {
  name: string
  date: string
}
interface BabylistBabesProps {
  date: string
  dateRange: string
  birthdays: Array<Birthday>
}

const BabylistBabes = ({ date, dateRange, birthdays }: BabylistBabesProps) => {
  const numColumns = birthdays.length > 100 ? 3 : 1
  const segmentSize = birthdays.length / numColumns
  const offset = numColumns > 1 ? 0 : 4

  const currentDate = new Date(date)
  // BornThisWeekDataUpdater always uses dange ranges starting on a Monday
  // so we want to make sure that the date links are always on a Monday
  const previousWeekStartDate = previousMonday(currentDate)
  // Hacky way to get the actual next Monday instead of the current Monday
  const nextWeekStartDate = nextMonday(nextMonday(currentDate))
  const weekUrl = (dateObject: Date) =>
    babylistBabesPath(format(dateObject, 'yyyy-MM-dd'))

  const canonicalUrl = `${CANONICAL_URL}${babylistBabesPath(date)}`
  const seoTitle = `Babylist Babes - Week of ${dateRange}`

  return (
    <div className="container mtxl">
      <Helmet>
        <title>{seoTitle}</title>
        <meta content={seoTitle} name="title" />
        <meta content={seoTitle} property="og:title" />
        <meta content={canonicalUrl} property="og:url" />
        <link href={canonicalUrl} rel="canonical" />
      </Helmet>
      <Row>
        <Col md={8} mdOffset={2} xs={12}>
          <div className="flex justify-center">
            <Image
              responsive
              alt="Born This Week"
              src="//images.babylist.com/image/upload/f_auto,q_auto/v1703101940/babylist-babes/babylist-babes-top.png"
            />
          </div>
          <br />
          <p className="text-center">
            Here are all the Babylist babies born between {dateRange}.
          </p>
          <p className="text-center">
            <span>
              <a className="link-info h6" href={weekUrl(previousWeekStartDate)}>
                <i className="h7 mrm fa fa-chevron-left" />
                Previous Week
              </a>
            </span>
            <span className="text-muted mhm">|</span>
            <span>
              <a className="link-info h6" href={weekUrl(nextWeekStartDate)}>
                Next Week
                <i className="h7 mlm fa fa-chevron-right" />
              </a>
            </span>
          </p>

          <hr className="mvxl" />

          <div>
            <Row>
              {times(numColumns, (n) => (
                <Col key={n} mdOffset={offset} sm={4} smOffset={offset}>
                  <ul className="list-unstyled">
                    {birthdays
                      .slice(n * segmentSize, (n + 1) * segmentSize)
                      .map((baby, index) => (
                        <li key={index}>
                          <b>{startCase(baby.name)}</b> - {baby.date}
                        </li>
                      ))}
                  </ul>
                </Col>
              ))}
            </Row>
          </div>

          <hr className="mvxl" />

          <div className={css.footerImage} />
        </Col>
      </Row>
    </div>
  )
}

export default BabylistBabes
