/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input } from 'components/forms'
import { InfoButton, LinkButton } from 'components/buttons'
import { forgotPasswordPath, newRegistryPath } from 'lib/urls'
import { track } from 'lib/analytics'
import css from './EmailForm.scss'
import { LoginFormState } from '../LoginForm/LoginForm.types'
import AppleSignInButton from '../AppleSignInButton'
import SquiggleDivider from '../SquiggleDivider'
import GoogleSignInButton from '../GoogleSignInButton/GoogleSignInButton'
import { EMAIL_REGEX } from '../../../lib/form-validation'

export interface EmailFormProps {
  handleBabylistResponse: (
    provider: 'Apple' | 'Email' | 'Google',
    response: unknown,
    setError: (error: string) => void
  ) => void
  nonce: string
  ssoEnabled: boolean
  state: LoginFormState
  togglePasswordForm: () => void
  updateState: (newState: LoginFormState) => void
}

const EmailForm = ({
  handleBabylistResponse,
  nonce,
  ssoEnabled,
  state,
  togglePasswordForm,
  updateState,
}: EmailFormProps) => {
  const handleContinueClick = () => {
    track.logInStepProcessed({
      eventLocation: track.EventLocation.LOG_IN_MINUS_LOG_IN_SCREEN,
      userAuthenticationMethod: track.UserAuthenticationMethod.EMAIL,
    })

    if (!state.email || !EMAIL_REGEX.test(state.email)) {
      updateState({ errors: { email: 'Please enter a valid email address' } })
    } else {
      togglePasswordForm()
    }
  }

  const handleGoogleButtonClickTracking = () => {
    track.logInStepProcessed({
      eventLocation: track.EventLocation.LOG_IN_MINUS_LOG_IN_SCREEN,
      userAuthenticationMethod: track.UserAuthenticationMethod.GOOGLE,
    })
  }

  const handleAppleButtonClickTracking = () => {
    track.logInStepProcessed({
      eventLocation: track.EventLocation.LOG_IN_MINUS_LOG_IN_SCREEN,
      userAuthenticationMethod: track.UserAuthenticationMethod.APPLE,
    })
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateState({ email: event.target.value.trim(), errors: { email: '' } })

  const handlePressEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key !== 'Enter') return

    event.preventDefault()
    event.stopPropagation()
    handleContinueClick()
  }

  return (
    <div className={css.emailFormContentContainer}>
      <div className={css.emailFormContentHeaderContainer}>
        <h1 className={css.emailFormContentHeaderText}>Log in to Babylist</h1>
      </div>
      <div className={css.emailFormFieldContainer}>
        <Input
          autoComplete="username"
          error={state.errors?.email}
          label="Email Address"
          name="email"
          type="email"
          value={state.email}
          onBlur={handleEmailChange}
          onChange={handleEmailChange}
          onKeyDown={handlePressEnter}
        />
        <div className="btn-group btn-group--stretch" style={{ width: '100%' }}>
          <InfoButton pill onClick={handleContinueClick}>
            Continue
          </InfoButton>
        </div>
      </div>
      {ssoEnabled && <SquiggleDivider />}
      {ssoEnabled && (
        <div className={css.emailFormContentHeaderContainer}>
          <GoogleSignInButton
            clickTracking={handleGoogleButtonClickTracking}
            handleBabylistResponse={handleBabylistResponse}
            label="Log in With Google"
          />
          <AppleSignInButton
            clickTracking={handleAppleButtonClickTracking}
            handleBabylistResponse={handleBabylistResponse}
            nonce={nonce}
            type="sign-in"
          />
        </div>
      )}
      <div>
        <div className="btn-group btn-group--stretch" style={{ width: '100%' }}>
          <LinkButton
            className={css.emailFormLinkButton}
            href={forgotPasswordPath}
          >
            Forgot your password?
          </LinkButton>
        </div>
        <div className="btn-group btn-group--stretch" style={{ width: '100%' }}>
          <LinkButton
            className={css.emailFormLinkButton}
            href={newRegistryPath}
          >
            Need to create an account?
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default EmailForm
