import { connect } from 'react-redux'
import { getIsAdmin, getRegistryId } from '../../../reducers'
import { useResetRegistryChecklistCategories } from '../api/hooks'

interface State {
  registry: {
    id: number
  }
  user: {
    admin: boolean
  }
}

interface Props {
  isAdmin: boolean
  registryId: number
}

export const BaseChecklistAdminBar = ({ isAdmin, registryId }: Props) => {
  const { mutate: reset } = useResetRegistryChecklistCategories(registryId)

  const handleReset = () => {
    reset()
    window.scrollTo(0, 0)
  }

  if (!isAdmin) {
    return null
  }

  return (
    <div className="mvl text-muted h6">
      <h6>Admin Tools</h6>
      <button
        className="btn btn-primary xsmall btn-pill"
        type="button"
        onClick={handleReset}
      >
        Reset Checklist
      </button>
    </div>
  )
}

const mapStateToProps = (state: State) => ({
  isAdmin: getIsAdmin(state),
  registryId: getRegistryId(state),
})

export const ChecklistAdminBar = connect(mapStateToProps)(BaseChecklistAdminBar)
