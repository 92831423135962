import { expectfulUrl, healthPath } from 'lib/urls'
import { HealthNavItem } from './types'

export const DEFAULT_BL_HEALTH_NAV_ITEMS: Array<HealthNavItem> = [
  {
    buttonText: 'Check Eligibility',
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691702693/Health_BLHealth_4_y7vevz.png',
    link: healthPath,
    newWindow: true,
    subtext:
      "We'll take care of contacting your provider and billing your insurance.",
    title: 'Order your free breast pump through insurance',
  },
  {
    buttonText: 'Meet Expectful',
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691189394/Health_Expectful_2_ez2lho.png',
    link: expectfulUrl,
    newWindow: true,
    subtext: 'A trusted resource for all moms—hopeful, expecting, and new.',
    title: 'Health & News Hub from Fertility to Postpartum',
  },
]
