import PropTypes from 'prop-types'
import Image from 'components/image'

const Start = ({ name, productName, productImage }) => (
  <div>
    <div className="h1 h-article text-center">{`Hi${name && ` ${name}`}!`}</div>
    <p className="lead">
      {`Thanks for sharing what you've learned about ${productName} with other new parents!`}
    </p>
    <Image
      alt={productName}
      src={productImage}
      width={200}
      className="center-block img-responsive"
    />
    <p className="text-center mtl">Ready to get started?</p>
  </div>
)

Start.propTypes = {
  updateStep: PropTypes.func,
  name: PropTypes.string,
  productName: PropTypes.string,
  productImage: PropTypes.string,
}

export default Start
