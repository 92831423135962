import Frame from '../../components/Frame'
import SectionHeader from '../../components/SectionHeader'

export default function CongratsSection() {
  return (
    <Frame>
      <SectionHeader
        className="text-center mhxl"
        subHeading={
          <>
            Your Checklist has been created! <br />
            <span className="text-bold eggplant-color">Check your email</span>,
            we sent you the results!
          </>
        }
      >
        Congrats!
      </SectionHeader>
    </Frame>
  )
}
