import Image from 'components/image'
import Link from 'components/link'
import Fade from 'react-reveal/Fade'
import { storePath } from 'lib/urls'
import { InfoButton } from '../shared/Buttons'
import css from './ShopSection.scss'
import { CategoryHeading, SectionHeading } from '../shared/Headings'
import SectionText from '../shared/SectionText'

interface ShopCategory {
  image: string
  title: string
  link: string
}

interface ShopSectionProps {
  shopCategories?: Array<ShopCategory>
}

const defaultShopCategories: Array<ShopCategory> = [
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691612234/Shop_Clothing_2_z4tgbt.png',
    title: 'Clothing',
    link: '/store/clothing',
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691612234/Shop_Nursery_2_axwl7n.png',
    title: 'Nursery',
    link: '/store/nursery',
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691612234/Shop_Gear_2_zrfo9f.png',
    title: 'Gear',
    link: '/store/gear',
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691612242/Shop_TryItKits_2_jh5fox.png',
    title: 'Try-It Kits',
    link: '/store/try-it-kits',
  },
]

const ShopSection = ({
  shopCategories = defaultShopCategories,
}: ShopSectionProps) => {
  const shopCategoryNodes = shopCategories.map((category) => (
    <div
      className={css.Category}
      key={`shopCategory${category.title}`}
      title={category.title}
    >
      <Link title={category.title} url={category.link}>
        <Image alt={category.title} src={category.image} />
        <CategoryHeading className={css.CategoryTitle}>
          {category.title}
        </CategoryHeading>
        <p className={css.CategoryLink}>Shop Now</p>
      </Link>
    </div>
  ))

  return (
    <section className={css.ShopSection}>
      <Fade bottom>
        <SectionHeading>Shop the Best for Baby</SectionHeading>
        <SectionText
          className={css.ShopSectionText}
          data-testid="shopSectionSubtext"
        >
          Find expert-selected, real-parent-tested products, gear, and our
          exclusive curated kits.
        </SectionText>
      </Fade>
      <div className={css.CategoryList}>{shopCategoryNodes}</div>
      <div className={css.ButtonGroup}>
        <InfoButton empty pill className={css.Button} href={storePath}>
          Shop All
        </InfoButton>
      </div>
    </section>
  )
}

export default ShopSection
