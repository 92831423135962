import PropTypes from 'prop-types'

import { Wave } from 'components/curves'

import css from './wave.scss'

// defined in _variables.scss
const salmonColor = '#ff7a5e'

export const FullPageWave = ({ children, className }) => {
  return (
    <>
      <div className={className}>{children}</div>
      <div className={css.wave}>
        <Wave color={salmonColor} />
      </div>
    </>
  )
}

FullPageWave.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}

export const FullWidthWave = ({ children, className }) => {
  return (
    <>
      <div className={css.waveFullWidth}>
        <Wave color={salmonColor} />
        <div className={className}>{children}</div>
      </div>
    </>
  )
}

FullWidthWave.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}
