import { values, find } from 'lodash'
import { parseErrorMessage, parseErrors } from 'lib/api-utils'
import { errorMessage, successMessage } from 'lib/flash-message'
import { GIFT_MESSAGE_SEPARATOR } from 'cart/constants'
import { submitReservation } from 'cart/actions'
import * as api from '../api'
import {
  getIsContributorView,
  getRegistryCategoriesHash,
  getRegistryId,
  getRegItem,
  getRegItemsById,
  getReservationsByCurrentVisitor,
  getInitialRegItemsCount,
} from '../reducers'

export const REG_ITEM_RESERVATION_STATUS_PURCHASED =
  'REG_ITEM_RESERVATION_STATUS_PURCHASED'
export const REG_ITEM_RESERVATION_STATUS_RESERVED =
  'REG_ITEM_RESERVATION_STATUS_RESERVED'
export const REG_ITEM_RESERVATION_STATUS_AVAILABLE =
  'REG_ITEM_RESERVATION_STATUS_AVAILABLE'
export const FAVORITE_REG_ITEM = 'FAVORITE_REG_ITEM'

export const SET_REGISTRY_CATEGORIES = 'SET_REGISTRY_CATEGORIES'
export const setRegistryCategories = (registryCategories) => ({
  type: SET_REGISTRY_CATEGORIES,
  registryCategories,
})

export const UPDATE_REGISTRY_CATEGORY = 'UPDATE_REGISTRY_CATEGORY'
export const updateRegistryCategory = (
  registryCategoryId,
  registryCategory
) => ({
  type: UPDATE_REGISTRY_CATEGORY,
  registryCategoryId,
  registryCategory,
})

export const SET_IS_ORGANIZING = 'SET_IS_ORGANIZING'
export const setIsOrganizing = (isOrganizing) => ({
  type: SET_IS_ORGANIZING,
  isOrganizing,
})

export const SET_REG_ITEMS = 'SET_REG_ITEMS'
export const setRegItems = (regItems, isPartialSet = false) => ({
  type: SET_REG_ITEMS,
  regItems,
  isPartialSet,
})

export const UPDATE_REG_ITEM = 'UPDATE_REG_ITEM'
export const updateRegItem = (regItemId, data) => ({
  type: UPDATE_REG_ITEM,
  regItemId,
  data,
})

export const ADD_REG_ITEM = 'ADD_REG_ITEM'
export const addRegItem = (regItemId, regItem) => ({
  type: ADD_REG_ITEM,
  regItemId,
  regItem,
})

export const REMOVE_REG_ITEM = 'REMOVE_REG_ITEM'
export const removeRegItem = (regItemId) => ({
  type: REMOVE_REG_ITEM,
  regItemId,
})

export const SET_IS_FETCHING_CATEGORIZED_REG_ITEMS =
  'SET_IS_FETCHING_CATEGORIZED_REG_ITEMS'
export const startFetchingCategorizedRegItems = {
  type: SET_IS_FETCHING_CATEGORIZED_REG_ITEMS,
  isFetchingCategorizedRegItems: true,
}
export const finishFetchingCategorizedRegItems = {
  type: SET_IS_FETCHING_CATEGORIZED_REG_ITEMS,
  isFetchingCategorizedRegItems: false,
}

export const SET_IS_FETCHING_RESERVED_REG_ITEMS =
  'SET_IS_FETCHING_RESERVED_REG_ITEMS'
export const startFetchingReservedRegItems = {
  type: SET_IS_FETCHING_RESERVED_REG_ITEMS,
  isFetchingReservedRegItems: true,
}
export const finishFetchingReservedRegItems = {
  type: SET_IS_FETCHING_RESERVED_REG_ITEMS,
  isFetchingReservedRegItems: false,
}

export const ADD_FILTER = 'ADD_FILTER'
export const addFilter = (filter) => ({ type: ADD_FILTER, filter })

export const SET_FILTERS = 'SET_FILTERS'
export const setFilters = (filters) => ({ type: SET_FILTERS, filters })

export const createRegistryCategory =
  (registryCategory) => (dispatch, getState) => {
    const state = getState()
    return api
      .createRegistryCategory(getRegistryId(state), registryCategory)
      .then(({ category }) => {
        dispatch(updateRegistryCategory(category.id, category))
        return category
      })
      .catch((resp) => Promise.reject(parseErrorMessage(resp)))
  }

export const updateCategory = (registryCategory) => (dispatch, getState) => {
  const state = getState()
  return api
    .updateRegistryCategory(getRegistryId(state), registryCategory)
    .then(({ category, categories }) => {
      dispatch(setRegistryCategories(categories))
      return category
    })
    .catch((resp) => Promise.reject(parseErrorMessage(resp)))
}

export const deleteCategory = (categoryId) => (dispatch, getState) => {
  const state = getState()
  const regItems = getRegItemsById(state)
  const categories = getRegistryCategoriesHash(state)
  const targetCategory = categories[categoryId]

  // remove the category from category list
  Object.keys(categories).forEach((key) => {
    const category = categories[key]
    if (category.position > targetCategory.position) {
      category.position -= 1
    }
  })

  // update category positions
  delete categories[categoryId]

  // add regItems from the category to the top of General
  const generalCategory = categories[0]
  const maxGeneralRegItemPosition = Object.values(regItems).reduce(
    (max, { categoryId, position }) =>
      categoryId === 0 && position > max ? position : max,
    0
  )

  Object.keys(regItems).forEach((key) => {
    const item = regItems[key]
    if (item.categoryId === categoryId) {
      item.categoryId = generalCategory.id
      item.position += maxGeneralRegItemPosition + 1
    }
  })

  // optimistically update
  dispatch(setRegistryCategories(categories))
  dispatch(setRegItems(regItems))

  return api
    .deleteRegistryCategory(getRegistryId(state), categoryId)
    .then(() =>
      successMessage(
        `Your category has been removed. Items in this category are now under ${generalCategory.title}`
      )
    )
    .catch((resp) => {
      // Revert if delete fails server-side
      dispatch(fetchCategorizedRegItems())
      return Promise.reject(parseErrors(resp))
    })
}

export const moveCategorizedRegItemToNewCategory =
  (regItem, oldCategoryId, oldPosition, newCategoryId, newPosition = 0) =>
  (dispatch, getState) => {
    const state = getState()
    const regItems = getRegItemsById(state)
    const activeRegItem = regItems[regItem.id]
    let regItemCount = 0
    Object.keys(regItems).forEach((key) => {
      const item = regItems[key]
      if (item.categoryId == newCategoryId) {
        regItemCount += 1
        if (item.position >= newPosition) {
          // increment position of all items above where new item is being inserted
          item.position += 1
        }
      } else if (item.categoryId == oldCategoryId) {
        if (item.position > oldPosition) {
          // shift down all items above where the item used to be
          item.position -= 1
        }
      }
    })
    activeRegItem.categoryId = newCategoryId
    activeRegItem.position = newPosition
    activeRegItem.row = regItemCount - newPosition
    // optimistically update reg item positions
    dispatch(setRegItems(regItems))
    // persist changes
    dispatch(saveRegItem(activeRegItem.id, activeRegItem))
  }

export const updateRegItemsPositions =
  (categoryId, oldPosition, newPosition) => (dispatch, getState) => {
    const state = getState()
    const movedUp = oldPosition < newPosition
    let regItemCount = 0
    let activeRegItem = null

    const regItems = getRegItemsById(state)

    Object.keys(regItems).forEach((key) => {
      const item = regItems[key]
      if (item.categoryId == categoryId) {
        regItemCount += 1
        if (item.position == oldPosition) {
          item.position = newPosition
          activeRegItem = item
        } else if (
          movedUp &&
          item.position > oldPosition &&
          item.position <= newPosition
        ) {
          item.position -= 1
        } else if (
          !movedUp &&
          item.position < oldPosition &&
          item.position >= newPosition
        ) {
          item.position += 1
        }
      }
    })

    // optimistically update reg item positions
    dispatch(setRegItems(regItems))

    // persist changes
    activeRegItem.row = regItemCount - newPosition - 1
    return dispatch(saveRegItem(activeRegItem.id, activeRegItem))
  }

export const submitInaccuracyReport = (
  reason,
  regItemId,
  url,
  suggestedPrice
) =>
  api
    .submitInaccuracyReport(reason, regItemId, url, suggestedPrice)
    .then((resp) => successMessage('Thank you for your feedback!'))
    .catch((resp) => errorMessage())

export const saveRegItem = (regItemId, data) => (dispatch, getState) => {
  dispatch(updateRegItem(regItemId, data)) // Optimistically update reg item for user

  const state = getState()
  return api
    .saveRegItem(getRegistryId(state), regItemId, data)
    .then((resp) => dispatch(updateRegItem(regItemId, resp.regItem))) // Update with server validated changes
    .catch((resp) => Promise.reject(parseErrorMessage(resp)))
}

export const createRegItem = (data) => (dispatch, getState) => {
  const state = getState()
  return api
    .createRegItem({ registryId: getRegistryId(state), fields: data })
    .then((resp) => {
      successMessage(`Your "${data.title}" has been added.`)
      return dispatch(addRegItem(resp.regItem.id, resp.regItem))
    })
    .catch((resp) => Promise.reject(parseErrorMessage(resp)))
}

export const deleteRegItem = (regItemId) => (dispatch, getState) => {
  const state = getState()
  const regItemToDelete = getRegItem(state, regItemId)

  dispatch(removeRegItem(regItemId)) // Optimistically remove the reg item

  return api
    .deleteRegItem(getRegistryId(state), regItemId)
    .then(() => successMessage('Your item has been removed.'))
    .catch((resp) => {
      dispatch(updateRegItem(regItemId, regItemToDelete)) // Replace if delete fails server-side
      return Promise.reject(parseErrors(resp))
    })
}

export const fetchRegItem = (regItemId) => (dispatch, getState) => {
  const state = getState()
  return api
    .fetchRegItem(getRegistryId(state), regItemId)
    .then((resp) => dispatch(updateRegItem(regItemId, resp)))
    .then(({ data }) => data) // Provide caller with data
    .catch((resp) => Promise.reject(parseErrors(resp)))
}

export const SET_RESERVED_REG_ITEMS = 'SET_RESERVED_REG_ITEMS'
export const setReservedRegItems = (reservedRegItems) => ({
  type: SET_RESERVED_REG_ITEMS,
  reservedRegItems,
})

export const REMOVE_RESERVATION = 'REMOVE_RESERVATION'
export const removeReservation = (reservationId) => ({
  type: REMOVE_RESERVATION,
  reservationId,
})

export const SET_RESERVATIONS_BY_CURRENT_VISITOR =
  'SET_RESERVATIONS_BY_CURRENT_VISITOR'
export const setReservationsByCurrentVisitor = (
  reservationsByCurrentVisitor
) => ({
  type: SET_RESERVATIONS_BY_CURRENT_VISITOR,
  reservationsByCurrentVisitor,
})

export const ADD_RESERVATION_BY_CURRENT_VISITOR =
  'ADD_RESERVATION_BY_CURRENT_VISITOR'
export const addReservationByCurrentVisitor = (reservation) => ({
  type: ADD_RESERVATION_BY_CURRENT_VISITOR,
  reservation,
})

export const REMOVE_RESERVATION_BY_CURRENT_VISITOR =
  'REMOVE_RESERVATION_BY_CURRENT_VISITOR'
export const removeReservationByCurrentVisitor = (regItemId) => ({
  type: REMOVE_RESERVATION_BY_CURRENT_VISITOR,
  regItemId,
})

const REG_ITEMS_PAGE_SIZE = 50
/**
 * Prepare an array of API requests to fetch registry items
 * @param {Object} state - Redux state object
 * @param {number} [limit=-1] - Limit of items to fetch, -1 for no limit
 * @param {Object} regItemParams - Additional request parameters
 * @return {Array} Array of API request promises
 */
const prepareRegItemRequests = (state, limit = -1, regItemParams) => {
  const initialStateCount = getInitialRegItemsCount(state)
  const totalCount = limit > 0 ? limit : Math.max(initialStateCount, 1)
  const pageSize = Math.min(totalCount, REG_ITEMS_PAGE_SIZE)

  const requests = new Array(Math.ceil(totalCount / pageSize))
    .fill(null)
    .map((_, index) => {
      const offset = index * pageSize
      return api.getRegistryRegItems(getRegistryId(state), {
        ...regItemParams,
        limit: Math.min(pageSize, totalCount - offset),
        offset,
      })
    })

  return requests
}

/**
 * Fetch categorized reg items in batches
 * @param {Object} options - Fetching options
 * @param {boolean} [options.onlyRegItems=false] - Only fetch reg items, otherwise include categories and reservations
 * @param {boolean} [options.fetchAvailableOnly=false] - Whether to fetch only available items
 * @param {number} [options.limit=-1] - Limit on the number of items to fetch, -1 for no limit
 * @return {Function} Redux action
 */
export const fetchCategorizedRegItems =
  ({ onlyRegItems = false, fetchAvailableOnly = false, limit = -1 } = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch(startFetchingCategorizedRegItems)

      // Determine view and initial params based on state
      const state = getState()
      const isContributorView = getIsContributorView(state)
      const params = isContributorView ? {} : { view: 'guest' }
      const regItemParams = { ...params, fetchAvailableOnly }

      // Initialize fetch list
      let toFetch = []

      // Fetch additional things if needed
      if (!onlyRegItems) {
        toFetch.push(api.getVisitorReservations(getRegistryId(state)))
        toFetch.push(api.getRegistryCategories(getRegistryId(state), params))
      }

      // Prepare reg item fetches
      const regItemRequests = prepareRegItemRequests(
        state,
        limit,
        regItemParams
      )
      toFetch = [...toFetch, ...regItemRequests]

      // Execute otherfetches first, if any
      if (!onlyRegItems) {
        const [reservations, categories] = await Promise.all(
          toFetch.slice(0, 2)
        )
        dispatch(setReservationsByCurrentVisitor(reservations))
        dispatch(setRegistryCategories(categories.categories))
      }

      // Initialize an empty object to hold all the regItems
      let allRegItems = {}

      // Fetch each batch of reg items in sequence and dispatch them as soon as the requests finish
      for (let i = onlyRegItems ? 0 : 2; i < toFetch.length; i++) {
        /* eslint-disable no-await-in-loop */
        const response = await toFetch[i]
        dispatch(setRegItems(response.regItems, true)) // true for partial update

        // Aggregate all regItems
        allRegItems = { ...allRegItems, ...response.regItems }
      }

      // Final dispatch with all the fetched regItems
      dispatch(setRegItems(allRegItems, fetchAvailableOnly))
    } catch (error) {
      errorMessage(
        "Sorry, we're having trouble loading the items on this registry. Please contact Babylist support if this problem continues."
      )
    } finally {
      dispatch(finishFetchingCategorizedRegItems)
    }
  }

export const fetchCategories = () => (dispatch, getState) => {
  const state = getState()

  const params = {}
  if (!getIsContributorView(state)) {
    params.view = 'guest' // Allow the client to force guest view
  }

  return api
    .getRegistryCategories(getRegistryId(state), params)
    .then(({ categories }) => dispatch(setRegistryCategories(categories)))
    .catch((resp) => {
      errorMessage(
        "Sorry, we're having trouble loading the items on this registry. Please contact Babylist support if this problem continues."
      )
      return Promise.reject(parseErrors(resp))
    })
}

export const fetchReservedRegItems = (options = {}) => {
  const { isGuestView } = options
  return (dispatch, getState) => {
    const state = getState()
    dispatch(startFetchingReservedRegItems)
    return api
      .getReservedRegItems(getRegistryId(state), isGuestView)
      .then((resp) => {
        dispatch(setReservedRegItems(resp))
        dispatch(finishFetchingReservedRegItems)
      })
      .catch((resp) =>
        Promise.reject(
          parseErrorMessage(resp) ||
            "Sorry, we're having trouble loading the items on this registry. Please contact Babylist support if this problem continues."
        )
      )
  }
}

export const createReservation = (reservation, params) => (dispatch) =>
  api
    .createReservation(reservation, params)
    .then(({ reservation }) =>
      dispatch(addReservationByCurrentVisitor(reservation))
    )
    .then(({ reservation }) => reservation) // Provide caller with data
    .catch((resp) => Promise.reject(parseErrors(resp)))

export const cancelReservation = (reservation) => (dispatch) =>
  api
    .removeReservation(reservation.token)
    .then(() => {
      dispatch(removeReservation(reservation.id))
      successMessage(
        `${reservation.name}'s reservation for ${reservation.regItem?.title} has been cancelled.`
      )
    })
    .catch((resp) => {
      errorMessage(
        "Sorry, we're having trouble cancelling this reservation. Please contact Babylist support if this problem continues."
      )
      return Promise.reject(parseErrors(resp))
    })

export const createReservationAsOwner = (reservation) =>
  createReservation(reservation, { asOwner: true })

export const markAsPurchasedByCurrentVisitor = (reservation) => (dispatch) =>
  dispatch(
    addReservationByCurrentVisitor({ ...reservation, isPurchased: true })
  )

export const sendReservationRecoveryEmail = (email, regItemId) => (dispatch) =>
  api.recoverReservation(email, regItemId)

export const setCashGiftDescriptionWithOptions = (description, options) =>
  `${description}${CROWDFUND_OPTIONS_SEPARATOR}${JSON.stringify(options)}`

export const updateGiftMessage = (giftMessage) => {
  const formattedGiftMessage = giftMessage.note
    ? `${giftMessage.note} ${GIFT_MESSAGE_SEPARATOR} ${giftMessage.name}`
    : null
  return (dispatch, getState) => {
    const reservations = values(getReservationsByCurrentVisitor(getState()))
    let reservationWithGiftMessage = find(
      reservations,
      (res) => res.giftMessage
    )
    if (!reservationWithGiftMessage) {
      reservationWithGiftMessage = reservations.shift()
    }
    return submitReservation({
      ...reservationWithGiftMessage,
      note: formattedGiftMessage,
    }).then((resp) => {
      dispatch(addReservationByCurrentVisitor(resp))
    })
  }
}
