import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'components/forms'

import FormHeader from './form-header'
import { getQuestionText } from './questions.js'

export default class Step6 extends Component {
  static propTypes = {
    questionIds: PropTypes.array,
    productName: PropTypes.string,
    onChange: PropTypes.func,
  }

  componentDidMount() {
    console.assert(
      this.props.questionIds[0] == 4,
      'first question id passed to this component was not 4!!'
    )
  }

  handleChange = (event) => {
    this.props.onChange(this.props.questionIds[0], event.target.value)
  }

  render() {
    const questionText = getQuestionText(
      this.props.questionIds[0],
      this.props.productName
    )
    return (
      <div>
        <FormHeader questionText={questionText} />
        <Input
          aria-label={questionText}
          onChange={this.handleChange}
          type="textarea"
        />
      </div>
    )
  }
}
