//@ts-ignore
import { track } from 'lib/analytics'

export interface Question {
  answer: string
  answers: { title: string; value: string }[]
  id: number
  title: string
  type: 'arrival' | 'radio'
}

//@ts-ignore
type QuestionEventLocationMap = { [key: number]: track.EventLocation }

export default class ChecklistQuestion {
  private readonly question: Question

  private readonly evLocationMap: QuestionEventLocationMap = {
    1: track.EventLocation.CHECKLIST_QUIZ_MINUS_EXPECTING_STATUS,
    2: track.EventLocation.CHECKLIST_QUIZ_MINUS_GENDER,
    3: track.EventLocation.CHECKLIST_QUIZ_MINUS_FEELING,
    4: track.EventLocation.CHECKLIST_QUIZ_MINUS_BUDGET,
    5: track.EventLocation.CHECKLIST_QUIZ_MINUS_BREASTFEEDING,
    6: track.EventLocation.CHECKLIST_QUIZ_MINUS_PUMPING,
    7: track.EventLocation.CHECKLIST_QUIZ_MINUS_SLEEP_AREA,
    8: track.EventLocation.CHECKLIST_QUIZ_MINUS_DIAPERS,
    9: track.EventLocation.CHECKLIST_QUIZ_MINUS_LAUNDRY_PLANS,
    10: track.EventLocation.CHECKLIST_QUIZ_MINUS_NURSERY,
    11: track.EventLocation.CHECKLIST_QUIZ_MINUS_GENDER_COLOR,
    12: track.EventLocation.CHECKLIST_QUIZ_MINUS_LAUNDRY_FREQUENCY,
    13: track.EventLocation.CHECKLIST_QUIZ_MINUS_CELEBRITY,
    14: track.EventLocation.CHECKLIST_QUIZ_MINUS_SEASON,
    15: track.EventLocation.CHECKLIST_QUIZ_MINUS_PLANNING,
    16: track.EventLocation.CHECKLIST_QUIZ_MINUS_FOOD,
    17: track.EventLocation.CHECKLIST_QUIZ_MINUS_CARS,
    18: track.EventLocation.CHECKLIST_QUIZ_MINUS_KNOW_US,
    19: track.EventLocation.CHECKLIST_QUIZ_MINUS_HAVE_REGISTRY,
    20: track.EventLocation.CHECKLIST_QUIZ_MINUS_COREGISTRATION,
  }

  public get eventLocation() {
    return this.evLocationMap[this.question.id]
  }

  public get answer() {
    const answerIsADate = !Number.isNaN(Date.parse(this.question.answer))

    if (answerIsADate) {
      // It's possible for the due date answer to be a date string
      return this.question.answer
    }

    const a = this.question.answers.filter(
      ({ value }) => value === this.question.answer
    )
    return a.pop()?.title
  }

  public get isAnswered() {
    return Boolean(this.question.answer)
  }

  constructor(question: Question) {
    this.question = question
  }
}
