import PropTypes from 'prop-types'

import css from './diaper-breakdown.scss'

export const DiaperBreakdown = ({ items }) => {
  return (
    <div className={css.grid} data-item-count={items.length}>
      {items.map((item, idx) => {
        // eslint-disable-next-line react/no-array-index-key
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${idx}${item.title}`} className={css.gridItem}>
            <div className={css.title}>{item.title}</div>
            <div className={css.subtitle}>{item.subtitle}</div>
          </div>
        )
      })}
    </div>
  )
}

DiaperBreakdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
}
