import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Checkbox } from 'components/forms'
import { getUserFullName, getRegistryId } from 'registry/reducers'
import { createReservationAsOwner } from 'registry/actions'
import { openOwnerMarkAsPurchasedModal } from 'registry/modals/actions'
import { successMessage, errorMessage } from 'lib/flash-message'

export const OwnerMarkAsPurchasedAction = ({
  afterMarkAsPurchased,
  createReservationAsOwner,
  name,
  regItemId,
  registryId,
  openOwnerMarkAsPurchasedModal,
  quantity,
}) => {
  const [isPurchased, setIsPurchased] = useState(false)
  const onSuccess = () => {
    successMessage('Item marked as purchased!')
    setIsPurchased(true)
    afterMarkAsPurchased()
  }
  const handleMarkAsPurchased = (e) => {
    const reservation = {
      regItemId,
      registryId,
      name,
      quantity,
      isPurchased: e.target.checked,
    }
    return createReservationAsOwner(reservation)
      .then(() => {
        onSuccess()
      })
      .catch(() => {
        errorMessage()
      })
    // Handle unreserve if unchecked?
  }

  return (
    <div>
      <Checkbox
        className="man pan"
        defaultChecked={isPurchased}
        checked={isPurchased}
        disabled={isPurchased}
        groupClassName="man pan"
        label="Purchased"
        onChange={(e) => {
          if (quantity > 1) {
            openOwnerMarkAsPurchasedModal(regItemId, quantity, onSuccess)
          } else {
            handleMarkAsPurchased(e)
          }
        }}
      />
    </div>
  )
}

OwnerMarkAsPurchasedAction.propTypes = {
  afterMarkAsPurchased: PropTypes.func,
  createReservationAsOwner: PropTypes.func.isRequired,
  regItemId: PropTypes.number.isRequired,
  registryId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  openOwnerMarkAsPurchasedModal: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
}

OwnerMarkAsPurchasedAction.defaultProps = {
  afterMarkAsPurchased: () => {},
}

const mapStateToProps = (state) => ({
  name: getUserFullName(state),
  registryId: getRegistryId(state),
})

export default connect(mapStateToProps, {
  createReservationAsOwner,
  openOwnerMarkAsPurchasedModal,
})(OwnerMarkAsPurchasedAction)
