import {
  SET_ACTIVE_MODAL,
  SET_PAGE_MESSAGE,
  SET_SUBMITTING_CANCEL_ORDER,
} from '../actions'

const initialState = {
  activeModal: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.activeModal,
        modalProps: action.modalProps,
      }
    case SET_PAGE_MESSAGE: {
      return { ...state, pageMessage: action.message }
    }
    case SET_SUBMITTING_CANCEL_ORDER: {
      return { ...state, submittingCancelOrder: action.submittingCancelOrder }
    }
    default:
      return state
  }
}

export const getActiveModal = (state) => state.activeModal
export const getActiveModalProps = (state) => state.modalProps
export const getPageMessage = (state) => state.pageMessage
export const getSubmittingCancelOrder = (state) => state.submittingCancelOrder
