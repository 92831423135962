import React from 'react'

interface InstagramProps {
  className?: string
}

const Instagram: React.FC<InstagramProps> = ({ className }: InstagramProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75Z"
      fill="#252324"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.96644 1.25H14.0336C15.4053 1.25 16.4807 1.24999 17.3451 1.32061C18.2252 1.39252 18.9523 1.54138 19.6104 1.87671C20.6924 2.42798 21.572 3.30762 22.1233 4.38956C22.4586 5.04768 22.6075 5.77479 22.6794 6.65494C22.75 7.51926 22.75 8.59471 22.75 9.96637V14.0336C22.75 15.4053 22.75 16.4807 22.6794 17.3451C22.6075 18.2252 22.4586 18.9523 22.1233 19.6104C21.572 20.6924 20.6924 21.572 19.6104 22.1233C18.9523 22.4586 18.2252 22.6075 17.3451 22.6794C16.4807 22.75 15.4053 22.75 14.0336 22.75H9.96637C8.59471 22.75 7.51926 22.75 6.65494 22.6794C5.77479 22.6075 5.04768 22.4586 4.38956 22.1233C3.30762 21.572 2.42798 20.6924 1.87671 19.6104C1.54138 18.9523 1.39252 18.2252 1.32061 17.3451C1.24999 16.4807 1.25 15.4053 1.25 14.0336V9.96644C1.25 8.59472 1.24999 7.51929 1.32061 6.65494C1.39252 5.77479 1.54138 5.04768 1.87671 4.38955C2.42798 3.30762 3.30762 2.42798 4.38955 1.87671C5.04769 1.54138 5.77479 1.39252 6.65494 1.32061C7.51929 1.24999 8.59472 1.25 9.96644 1.25ZM16.25 6C16.25 5.30964 16.8096 4.75 17.5 4.75C18.1904 4.75 18.75 5.30964 18.75 6C18.75 6.69036 18.1904 7.25 17.5 7.25C16.8096 7.25 16.25 6.69036 16.25 6ZM7.25 12C7.25 9.37665 9.37665 7.25 12 7.25C14.6234 7.25 16.75 9.37665 16.75 12C16.75 14.6234 14.6234 16.75 12 16.75C9.37665 16.75 7.25 14.6234 7.25 12Z"
      fill="#252324"
    />
  </svg>
)

export default Instagram
