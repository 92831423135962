import React, { useEffect, useState } from 'react'
import css from './FrequentlyAskedQuestions.scss'
import classNames from 'classnames'
import { faqItems } from './faqItems'
import Link from 'components/link'
import { helloBabyAuthors } from 'lib/urls'
import BabylistHeart from '../../svg/BabylistHeart.svg'

interface FrequentlyAskedQuestionsProps {
  className?: string
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({
  className,
}) => {
  const [activeId, setActiveId] = useState<string>(faqItems[0].id)

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id)
        }
      })
    }

    const options = {
      root: null,
      rootMargin: '0px 0px -90% 0px', // Adjust this value to ensure the entry closest to the top is highlighted
      threshold: 0,
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    const elements = faqItems.map((item) => document.getElementById(item.id))
    elements.forEach((el) => {
      if (el) observer.observe(el)
    })

    return () => {
      elements.forEach((el) => {
        if (el) observer.unobserve(el)
      })
    }
  }, [])

  return (
    <div className={classNames(css.FrequentlyAskedQuestions, className)}>
      <div className={css.FrequentlyAskedQuestions__wrapper}>
        <div className={css.FrequentlyAskedQuestions__header}>
          <BabylistHeart />
          <div className={css.FrequentlyAskedQuestions__author}>
            <p>
              by{' '}
              <Link url={helloBabyAuthors('babylist-staff')}>
                Babylist Staff
              </Link>
              <span className={css.FrequentlyAskedQuestions__separator}>|</span>
            </p>
            <p>
              Medically reviewed by{' '}
              <Link url={helloBabyAuthors('alyssa-dweck')}>
                Dr. Alyssa Dweck
              </Link>
              , OB/GYN
            </p>
            <p className={css.FrequentlyAskedQuestions__header__date}>
              April 16, 2024
            </p>
          </div>
        </div>
        {faqItems.map((faq) => (
          <div
            key={faq.id}
            id={faq.id}
            className={css.FrequentlyAskedQuestions__entry}
          >
            <h2 className={css.FrequentlyAskedQuestions__question}>
              {faq.question}
            </h2>
            <p className={css.FrequentlyAskedQuestions__answer}>{faq.answer}</p>
          </div>
        ))}
      </div>
      <div className={css.FrequentlyAskedQuestions__toc}>
        <h2>In this article</h2>
        <ul>
          {faqItems.map((faq) => (
            <li key={faq.id} className={faq.id === activeId ? css.active : ''}>
              <a href={`#${faq.id}`}>{faq.question}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default FrequentlyAskedQuestions
