import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find, filter } from 'lodash'
import classNames from 'classnames'
import { getHistory } from 'lib/history'

import { TwoColumnResponsiveLayout } from 'components/presentation'
import { Modal, ModalBody } from 'components/modal'

import PurchaseInfoModal from 'shared/purchase-info-modal'
import GiftMessageModal from 'shared/gift-message-modal'
import GiftMessageCard from 'registry/gift-reservations/gift-message-card'
import { PrimaryButton } from 'components/buttons'

import { CANCEL_RESERVATION } from '../../constants'
import {
  getCrowdfundReservations,
  getFavorReservations,
  purchasedReservations as getPurchasedReservations,
  uncompletedExternalReservations,
} from '../../lib'
import {
  markAsPurchased,
  removeAlert,
  removeReservation,
  setActiveModal,
  updateGiftMessage,
  updateQuantity,
  updateReservation,
} from '../../actions'
import { getGiftMessage } from '../../reducers'
import PurchasedItems from '../purchased-items'
import RegItem from '../reg-item'
import ExternalReservedItems from 'cart/components/external-reserved-items'
import CrowdfundItems from '../crowdfund-items'
import HelpAndFavorItems from '../help-and-favor-items'
import css from './reservations-list.scss'

import { tracking, track } from 'lib/analytics'
import OrderHistoryHeader from 'cart/components/order-history-header'
import DidNotBuyCartForm from '../did-not-buy-cart-form'

const history = getHistory()
const location = history.getCurrentLocation()

@tracking(({ registry }) => ({ registry }))
export class ReservationsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reservationPurchased: null,
      reservationUnpurchased: null,
      showGiftMessageModal: null,
      giftMessage: props.giftMessage,
    }
  }

  componentDidMount() {
    const token = location.pathname.split('/reservations/')[1]
    const reservation = find(this.props.reservations, { token })

    // check if the user is coming in from email Yes/No click
    // if so and they are indicating that an item is not purchased
    // load that into state to display the modal asking for reasons
    // why it was not purchased
    if (this.props.showPurchaseCancelModal && reservation) {
      this.setState({ reservationUnpurchased: reservation })
    } else if (this.props.showPurchaseConfirmationModal && reservation) {
      this.setState({ reservationPurchased: reservation })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.giftMessage.note &&
      this.props.giftMessage.note !== this.state.giftMessage.note
    ) {
      this.setState({ giftMessage: this.props.giftMessage })
    }
  }

  render() {
    const {
      className,
      disableBabylistOffer,
      maintenanceMode,
      onSetReservationToRemove,
      processingReservations,
      registry,
      reservations,
      updateQuantity,
      updateReservation,
    } = this.props

    const { giftMessage } = this.state

    const crowdfundReservations = getCrowdfundReservations(reservations)
    const uncompletedReservations = filter(
      uncompletedExternalReservations(
        reservations,
        maintenanceMode,
        disableBabylistOffer
      ),
      'store'
    )
    // for now we are considering all reservations for reg items that don't have links to be "favors"
    const favorReservations = getFavorReservations(reservations)
    const purchasedReservations = getPurchasedReservations(reservations)

    const confirmedPurchasedCount =
      purchasedReservations.length +
      processingReservations.length +
      favorReservations.length +
      crowdfundReservations.length
    const needsGiftMessage = !giftMessage.note && confirmedPurchasedCount > 0
    const showOrderHistory =
      crowdfundReservations.length > 0 ||
      favorReservations.length > 0 ||
      purchasedReservations.length > 0 ||
      processingReservations.length > 0

    return (
      <div className={classNames(className, 'pbxl')}>
        <div className={css.content}>
          {uncompletedReservations.length ? (
            <ExternalReservedItems
              {...this.props}
              uncompletedReservations={uncompletedReservations}
              onRemoveAlert={this.props.removeAlert}
              onSetReservationToRemove={onSetReservationToRemove}
              onSetPurchasedReservation={this.handleSetPurchasedReservation.bind(
                this
              )}
            />
          ) : null}

          {/* Start Order History */}

          {showOrderHistory && (
            <OrderHistoryHeader
              className="mtxl mbl"
              registry={registry}
              showAddress={crowdfundReservations.length > 0}
            />
          )}
          {giftMessage.note ? (
            <GiftMessageCard
              onEdit={() => this.setState({ showGiftMessageModal: true })}
            >
              <p>
                {giftMessage.note}
                <div className="mtl">{`—${giftMessage.name}`}</div>
              </p>
            </GiftMessageCard>
          ) : needsGiftMessage ? (
            <PrimaryButton
              pill
              className={classNames({
                'animated bounce mbxl hidden-sm hidden-xs': !giftMessage.note,
              })}
              onClick={() => {
                this.setState({ showGiftMessageModal: true })
              }}
            >
              {`${giftMessage.note ? 'Edit' : 'Add a'} Gift Message`}
            </PrimaryButton>
          ) : null}

          {needsGiftMessage ? (
            <PrimaryButton
              block
              pill
              className={classNames({
                'animated bounce mbl visible-sm visible-xs': !giftMessage.note,
              })}
              onClick={() => {
                this.setState({ showGiftMessageModal: true })
              }}
            >
              {giftMessage.note ? 'Edit' : 'Add'} Gift Message
            </PrimaryButton>
          ) : null}
          {purchasedReservations.length || processingReservations.length ? (
            <PurchasedItems
              {...this.props}
              processingReservations={processingReservations}
              purchasedReservations={purchasedReservations}
              onSetPurchasedReservation={this.handleSetPurchasedReservation.bind(
                this
              )}
              onRemoveAlert={this.props.removeAlert}
              registry={registry}
            />
          ) : null}

          {favorReservations.length ? (
            <HelpAndFavorItems
              onSetReservationToRemove={onSetReservationToRemove}
              onUpdateQuantity={updateQuantity}
              favorReservations={favorReservations}
              onUpdateReservation={updateReservation}
            />
          ) : null}

          {crowdfundReservations.length ? (
            <CrowdfundItems
              onSetReservationToRemove={onSetReservationToRemove}
              crowdfundReservations={crowdfundReservations}
              onUpdateReservation={updateReservation}
            />
          ) : null}

          {this.state.reservationPurchased ? (
            <PurchaseInfoModal
              show={!!this.state.reservationPurchased}
              giftMessage={this.state.giftMessage}
              needsGiftMessage={!this.state.giftMessage.note}
              onHide={() => {
                this.setState({ reservationPurchased: null })
              }}
              reservation={this.state.reservationPurchased}
              autofillStore={true}
              registry={registry}
              regItem={
                this.state.reservationPurchased &&
                this.state.reservationPurchased.regItem
              }
              onMarkAsPurchased={this.handleSubmitPurchaseConfirmation.bind(
                this,
                this.state.reservationPurchased
              )}
              onSubmitGiftMessage={this.handleUpdateGiftMessage.bind(this)}
            />
          ) : null}

          {this.state.reservationUnpurchased ? (
            <Modal
              show={this.state.reservationUnpurchased}
              title="I didn't buy this gift because:"
              onHide={() => this.setState({ reservationUnpurchased: null })}
            >
              <ModalBody>
                <TwoColumnResponsiveLayout>
                  <div>
                    <RegItem
                      className="mbl"
                      regItem={
                        this.state.reservationUnpurchased &&
                        this.state.reservationUnpurchased.regItem
                      }
                      registry={registry}
                    />
                  </div>
                  <div>
                    <DidNotBuyCartForm
                      reservation={this.state.reservationUnpurchased}
                      onSubmitSuccess={(reservation) => {
                        // remove the query param from url. if they refresh the page and haven't removed the item from their cart
                        // we don't want to keep prompting them with the modal
                        history.replace({
                          pathname: location.pathname,
                          state: null,
                        })
                        this.setState({ reservationUnpurchased: null })
                      }}
                    />
                  </div>
                </TwoColumnResponsiveLayout>
              </ModalBody>
            </Modal>
          ) : null}

          {this.state.showGiftMessageModal ? (
            <GiftMessageModal
              show={this.state.showGiftMessageModal}
              onHide={() => this.setState({ showGiftMessageModal: false })}
              giftMessage={giftMessage}
              onSubmitGiftMessage={this.handleUpdateGiftMessage.bind(this)}
            />
          ) : null}
        </div>
      </div>
    )
  }

  handleSetPurchasedReservation(reservationPurchased) {
    this.setState({ reservationPurchased })
  }

  // reservation is the result of the promise (updatedReservation)
  @tracking((state, props, args, [reservation, err]) => {
    if (err) {
      return undefined
    }

    const orderInfoEntered =
      Array.isArray(args) &&
      args.length > 1 &&
      (args[1].orderNumber || args[1].orderNumberNotAvailable)

    if (orderInfoEntered) {
      return {
        event: track.reservationUpdated,
        reservation,
        regItemQuantity: Number(reservation.quantity) || 1,
        eventType: track.EventType.MARK_AS_UNPURCHASED,
      }
    }

    return {
      event: track.purchaseConfirmed,
      regItemIds: [reservation.regItemId],
      regItemQuantity: Number(reservation.quantity) || 1,
      eventType: track.EventType.MARK_AS_PURCHASED,
    }
  })
  handleSubmitPurchaseConfirmation(reservation, purchaseInfo) {
    return this.props
      .markAsPurchased(reservation, purchaseInfo, {
        showSuccessMessage: !!this.state.giftMessage.note,
      })
      .then((updatedReservation) => {
        window.scrollTo(0, 0)
        return updatedReservation
      })
      .catch(() => {})
  }

  handleUpdateGiftMessage(giftMessage) {
    return this.props
      .updateGiftMessage(giftMessage)
      .then(() => {
        this.setState({
          showGiftMessageModal: false,
          giftMessage,
          reservationPurchased: null,
        })
      })
      .catch(() => {})
  }
}

ReservationsList.propTypes = {
  registry: PropTypes.object.isRequired,
  removeAlert: PropTypes.func.isRequired,
  reservations: PropTypes.array.isRequired,
  processingReservations: PropTypes.array.isRequired,
  markAsPurchased: PropTypes.func.isRequired,
  showPurchaseConfirmationModal: PropTypes.bool,
  showPurchaseCancelModal: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const giftMessage = getGiftMessage(state)
  return {
    giftMessage,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    markAsPurchased: (...args) => dispatch(markAsPurchased(...args)),
    onSetReservationToRemove: (reservation) =>
      dispatch(
        setActiveModal(CANCEL_RESERVATION, { reservation: reservation })
      ),
    removeAlert: (...args) => dispatch(removeAlert(...args)),
    removeReservation: (...args) => dispatch(removeReservation(...args)),
    updateQuantity: (...args) => dispatch(updateQuantity(...args)),
    updateReservation: (...args) => dispatch(updateReservation(...args)),
    updateGiftMessage: (...args) => dispatch(updateGiftMessage(...args)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsList)
