import { useEffect, useState } from 'react'
import TrackVisibility from 'react-on-screen'
import { SIGNUP_MODAL_PUBSUB_KEY } from '../../registry/modules/checklist/api/hooks'
import { ChecklistThemesList } from '../../registry/modules/checklist/components'
import {
  HeaderSection,
  FAQSection,
  HeroSection,
  BottomCTA,
  StickyCTA,
  EmailSignup,
  SignupModal,
  GuidesSection,
} from './components'
import { Question } from '../../store/components/FrequentlyAskedQuestions/FrequentlyAskedQuestions.types'

export const BabyRegistryChecklist = ({
  questions,
}: {
  questions: Array<Question>
}) => {
  const [isBottomCTAVisible, setIsBottomCTAVisible] = useState(false)
  const [isHeaderSectionVisible, setIsHeaderSectionVisible] = useState(false)
  const [showSignupModal, setShowSignupModal] = useState(false)
  const showStickyCTA = !isBottomCTAVisible && !isHeaderSectionVisible

  useEffect(() => {
    PubSub.subscribe(SIGNUP_MODAL_PUBSUB_KEY, () => {
      setShowSignupModal(true)
    })
  }, [])

  const handleHideSignupModal = () => {
    setShowSignupModal(false)
  }

  return (
    <>
      <HeroSection />
      <div className="container-md">
        <TrackVisibility partialVisibility>
          {({ isVisible }) => {
            setIsHeaderSectionVisible(isVisible)
            return <HeaderSection />
          }}
        </TrackVisibility>
        <ChecklistThemesList />
        <EmailSignup />
        <FAQSection questions={questions} />
      </div>
      <div className="container">
        <GuidesSection />
      </div>
      <TrackVisibility>
        {({ isVisible }) => {
          setIsBottomCTAVisible(isVisible)
          return <BottomCTA />
        }}
      </TrackVisibility>
      <div className="visible-xs">
        <StickyCTA show={showStickyCTA} />
      </div>
      <SignupModal show={showSignupModal} onHide={handleHideSignupModal} />
    </>
  )
}
