import Link from 'components/link'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { newRegistryPath } from 'lib/urls'
import css from './RegistryCta.scss'
import InsightSticker from '../../svg/InsightSticker.svg'
import ExpertSticker from '../../svg/ExpertSticker.svg'
import RegistrySticker from '../../svg/RegistrySticker.svg'

const RegistryCta = () => (
  <div className={css.RegistryCta}>
    <h2>We take care of growing families</h2>
    <div className={css.RegistryCta__modules}>
      <div className={css.RegistryCta__module}>
        <InsightSticker className={css.RegistryCta__module__icon} />
        <h3>Insights on your pregnancy</h3>
        <p>
          Get week-by-week updates and personalized info tailored to your due
          date.
        </p>
      </div>
      <div className={css.RegistryCta__module}>
        <ExpertSticker className={css.RegistryCta__module__icon} />
        <h3>Expert advice</h3>
        <p>
          Discover 100s of editorial guides that offer answers to burning
          questions like, "Should I buy a bottle warmer?"
        </p>
      </div>
      <div className={css.RegistryCta__module}>
        <RegistrySticker className={css.RegistryCta__module__icon} />
        <h3>The best universal baby registry</h3>
        <p>
          Add anything to your registry from any store, including favorites like
          Amazon, Target, Etsy, Nordstrom, H&M and Walmart.
        </p>
      </div>
    </div>
    <div>
      <Link url={newRegistryPath}>
        <div className={css.RegistryCta__desktopOnly}>
          <Button
            className={css.RegistryCta__button}
            size="lg"
            variant="primary"
          >
            Start Your Registry
          </Button>
        </div>
        <div className={css.RegistryCta__mobileOnly}>
          <Button
            className={css.RegistryCta__button}
            size="md"
            variant="primary"
          >
            Start Your Registry
          </Button>
        </div>
      </Link>
    </div>
    <div className={css.RegistryCta__curve} />
  </div>
)

export default RegistryCta
