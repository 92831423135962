/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { find, map } from 'lodash'
import { tracking } from 'lib/analytics'
import { openRedirectModal as openModal } from 'registry/modals/actions'
import CheckoutButton from 'cart/components/checkout-button'
import { ReservationShape, RegistryShape } from 'cart/lib/prop-types'
import BorderedOrderWrapper from './bordered-order-wrapper'
import StoreOrderItem from './store-order-item'

@tracking((props) => {
  const { reservations, storeName: externalStoreName } = props
  const productIds = map(reservations, (r) => r.productId)
  const regItemIds = map(reservations, (r) => r.regItemId)
  return { productIds, regItemIds, externalStoreName }
})
export class BaseExternalStoreOrder extends Component {
  render() {
    const {
      disableBabylistOffer,
      openRedirectModal,
      onSetPurchasedReservation,
      onSetReservationToRemove,
      reservations,
      registry,
      storeName,
    } = this.props

    return (
      <div>
        {map(reservations, (reservation) => {
          const currentOffer = find(
            reservation.regItem.offers,
            (o) => o.storeName === storeName
          )

          const checkoutClick = () => {
            openRedirectModal({
              url: currentOffer.affiliateUrl,
              storeDisplayName: storeName,
              onRedirectSuccess: () => onSetPurchasedReservation(reservation),
            })
          }
          return (
            <BorderedOrderWrapper
              className="mbl"
              key={`${storeName}-${reservation.token}`}
            >
              <StoreOrderItem
                className="mrxl-md"
                disableBabylistOffer={disableBabylistOffer}
                onSetReservationToRemove={() =>
                  onSetReservationToRemove(reservation)
                }
                onSetPurchasedReservation={() =>
                  onSetPurchasedReservation(reservation)
                }
                storeName={storeName}
                currentOffer={currentOffer}
                reservation={reservation}
              />
              <div className="mvl phs">
                <CheckoutButton
                  registry={registry}
                  reservations={reservations}
                  storeName={storeName}
                  text={`Buy on ${storeName}`}
                  onCheckoutClick={checkoutClick}
                />
              </div>
            </BorderedOrderWrapper>
          )
        })}
      </div>
    )
  }
}

BaseExternalStoreOrder.propTypes = {
  disableBabylistOffer: PropTypes.bool,
  registry: PropTypes.shape(RegistryShape),
  reservations: PropTypes.arrayOf(PropTypes.shape(ReservationShape)),
  storeName: PropTypes.string.isRequired,
  openRedirectModal: PropTypes.func.isRequired,
  onSetReservationToRemove: PropTypes.func.isRequired,
  onSetPurchasedReservation: PropTypes.func.isRequired,
}

BaseExternalStoreOrder.defaultProps = {
  disableBabylistOffer: false,
  registry: null,
  reservations: null,
}

export const ExternalStoreOrder = connect(null, {
  openRedirectModal: openModal,
})(BaseExternalStoreOrder)
