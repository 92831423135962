import Frame from '../../components/Frame'
import SectionHeader from '../../components/SectionHeader'
import Image from '../../../../components/image'
import { mobileAppDownloadPath } from '../../../../lib/urls'
import * as BLConstants from '../../../../shared/constants'

export default function RegistryDiscountSection() {
  return (
    <Frame>
      <SectionHeader
        className="text-center"
        subHeading="15% completion discount to the Babylist Shop"
      >
        {BLConstants.CONFIG.store.registryDiscountPctDisplay} Registry Discount
      </SectionHeader>

      <div className="mam">
        <Image
          className="wh-100 no-pointer-events"
          src="//images.babylist.com/image/upload/f_auto,q_auto/v1645228442/discount_1_ta5wnu.png"
          alt="Add any item from any store"
        />
      </div>

      <a href={mobileAppDownloadPath()} className="mtm btn-pill btn btn-info">
        Get My Discount Now
      </a>
    </Frame>
  )
}
