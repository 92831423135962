import LinkButton from 'components/link'
import { InfoAlert } from 'components/alerts'

const ProcessingReservationsAlert = () => {
  return (
    <InfoAlert className="processing-alert">
      <p>
        <strong>We're still processing your order.</strong> While an order is
        processing:
        <ul>
          <li>Items cannot be changed or cancelled</li>
          <li>Gift cards cannot yet be printed</li>
          <li>
            Orders usually complete after a few minutes.{' '}
            <LinkButton onClick={() => window.location.reload()}>
              Click here to refresh the page.
            </LinkButton>
          </li>
        </ul>
      </p>
    </InfoAlert>
  )
}
export default ProcessingReservationsAlert
