import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bowserFlags } from 'lib/bowser'
import classnames from 'classnames'

import EUBlockedContent from 'shared/EUBlockedContent/EUBlockedContent'
import { SectionHeading } from '../../components/headings'
import { Outlet, useParams, useLocation } from 'react-router-dom-v5-compat'

const ChecklistWrapper = ({ isEuIp }) => {
  const params = useParams()
  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <div
      className={classnames('mtxl', {
        'checklist-wrapper': !(bowserFlags.mobile && params.questionId),
      })}
    >
      <div className="container">
        {/* override the minWidth on question 1. it needs to be wider than the rest */}
        <div
          className="checklist-container"
          style={
            bowserFlags.mobile && params.questionId === 1
              ? { maxWidth: 'none' }
              : null
          }
        >
          <div className="text-center">
            <SectionHeading className="mvn" style={{ fontSize: 45 }}>
              <span className="text-uppercase h4">Baby Registry</span>
              <br />
              Checklist
            </SectionHeading>
            <p className="h6 mtn">
              <a href="/" className="link-lowlight">
                by Babylist
              </a>
            </p>
          </div>
          {isEuIp ? <EUBlockedContent className="mtxl" /> : <Outlet />}
        </div>
      </div>
    </div>
  )
}

ChecklistWrapper.propTypes = {
  isEuIp: PropTypes.bool,
}

export default ChecklistWrapper
