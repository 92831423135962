import React, { useEffect } from 'react'
import { Meter } from 'baby-design'
import css from './DueDateResults.scss'
import classNames from 'classnames'
import Wave from '../DueDateForm/components/Wave'
import BabySizer from './components/BabySizer/BabySizer'
import Guides from '../Guides/Guides'
import Zodiac from './components/Zodiac/Zodiac'
import RegistryCta from './components/RegistryCta/RegistryCta'
import EmailCapture from './components/EmailCapture/EmailCapture'
import SocialLinks from './components/SocialLinks/SocialLinks'
import { Navigate, useParams } from 'react-router-dom-v5-compat'
import { parse, format, differenceInCalendarDays } from 'date-fns'
import Chevron from './svg/Chevron.svg'
import Squiggle from './svg/Squiggle.svg'

interface DueDateResultsProps {
  emailUrls: string[]
  posts: any[]
}

const DueDateResults: React.FC<DueDateResultsProps> = ({
  emailUrls,
  posts,
}: DueDateResultsProps) => {
  const { dueDate } = useParams() as { dueDate: string }
  const date = parse(dueDate.split('?')[0], 'yyyy-MM-dd', new Date())
  if (isNaN(date.getTime())) {
    return <Navigate to=".." relative="path" />
  }
  const monthName = format(date, 'MMMM')
  const day = format(date, 'd')
  const year = format(date, 'yyyy')

  const numWeeksPregnant =
    40 - Math.ceil(differenceInCalendarDays(date, new Date()) / 7)
  const percentage = (numWeeksPregnant / 40) * 100

  if (numWeeksPregnant < 0 || numWeeksPregnant > 42) {
    return <Navigate to=".." relative="path" />
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const TrimesterPointer: React.FC = () => (
    <div style={{ color: 'white', fontWeight: 700 }}>
      <div style={{ fontSize: '8px', lineHeight: 1 }}>WEEK</div>
      <div style={{ fontSize: '23px', lineHeight: 1 }}>{numWeeksPregnant}</div>
    </div>
  )

  return (
    <div className={classNames(css.DueDateResults)}>
      <div className={css.DueDateResults__wrapper}>
        <div className={css.DueDateResults__dateDisplay}>
          <p className={css.DueDateResults__dateDisplay__text}>
            Your baby's due date
          </p>
          <h1>
            {monthName} {day}, {year}
          </h1>
          <Squiggle />
          {numWeeksPregnant >= 4 && (
            <div className={css.DueDateResults__meter}>
              <h2 className={css.DueDateResults__desktopOnly}>
                You're currently {numWeeksPregnant} weeks pregnant
              </h2>
              <p
                className={classNames(
                  css.DueDateResults__weeksLabel,
                  css.DueDateResults__mobileOnly
                )}
              >
                You're currently
              </p>
              <p
                data-testid="weeks-pregnant"
                className={classNames(
                  css.DueDateResults__weeks,
                  css.DueDateResults__mobileOnly
                )}
              >
                {numWeeksPregnant} weeks pregnant
              </p>
              <div className={css.DueDateResults__desktopOnly}>
                <Meter
                  totalSegments={3}
                  percentage={percentage}
                  labels={['1st trimester', '2nd trimester', '3rd trimester']}
                  fillClass={css.DueDateResults__meterFill}
                  PointerComponent={<TrimesterPointer />}
                  pointerClass={css.DueDateResults__pointer}
                  pointerRightClass={css.DueDateResults__pointerRight}
                />
              </div>
              <div className={css.DueDateResults__mobileOnly}>
                <Meter
                  totalSegments={3}
                  percentage={percentage}
                  fillClass={css.DueDateResults__meterFill}
                  PointerComponent={<TrimesterPointer />}
                  pointerClass={css.DueDateResults__pointer}
                  pointerRightClass={css.DueDateResults__pointerRight}
                />
              </div>
            </div>
          )}
          <Zodiac dueDate={date} />
        </div>
        <div className={css.DueDateResults__exploreLink}>
          <a href="#explore">
            More to explore <Chevron className={css.DueDateResults__chevron} />
          </a>
        </div>
      </div>
      <Wave className={css.DueDateResults__wave} />
      {numWeeksPregnant >= 4 && (
        <>
          <a id="explore" />
          <BabySizer
            numWeeksPregnant={numWeeksPregnant}
            emailUrls={emailUrls}
          />
        </>
      )}
      <Guides posts={posts} />
      <RegistryCta />
      <EmailCapture dueDate={date} />
      <SocialLinks />
    </div>
  )
}

export default DueDateResults
