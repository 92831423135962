import { Component } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, isObject, omit, range, set } from 'lodash'
import { Collapse } from 'react-bootstrap'
import classNames from 'classnames'

import * as BLConstants from 'shared/constants'
import PregnancyEmailLayout from './pregnancy-email-layout'
import DueDateSelect from 'components/DueDateSelect/DueDateSelect'
import Image from 'components/image'
import { createValidator, dateString, required } from 'lib/form-validation'
import { parseErrors } from 'lib/api-utils'
import { today } from 'lib/date'

import css from './pregnancy-email-birthday.scss'
import * as api from './api'
import { TextInput, Checkbox, TextLink, Button } from 'baby-design'
import { privacyPolicyPath, registryHomePath, termsOfUsePath } from 'lib/urls'

const currentYear = today().getFullYear()
const years = range(currentYear - 20, currentYear + 1).reverse()

const DEFAULT_BG_IMAGE =
  'https://images.babylist.com/image/upload/q_auto:eco/v1727374959/Email%20birthday%20landing%20page/arrival_1200x1600.png'
const DEFAULT_BG_IMAGE_MOBILE =
  'https://images.babylist.com/image/upload/q_auto:eco/v1727378997/Email%20birthday%20landing%20page/arrival_880x320.png'
const CONFIRMATION_BG_IMAGE =
  'https://images.babylist.com/image/upload/q_auto:eco/v1727374968/Email%20birthday%20landing%20page/confirmation_1200x1600.png'
const CONFIRMATION_BG_IMAGE_MOBILE =
  'https://images.babylist.com/image/upload/q_auto:eco/v1727379062/Email%20birthday%20landing%20page/confirmation_880x320.png'

export const getValidator = function (twins) {
  let rules = {
    birthDate: [required, dateString],
    'names[0].firstName': [required],
  }

  if (twins) {
    rules['names[1].firstName'] = [required]
  }
  return createValidator(rules)
}

export class PregnancyEmailBirthday extends Component {
  static propTypes = {
    familyId: PropTypes.number.isRequired,
    restrictedAccessToken: PropTypes.string.isRequired,
    userFirstName: PropTypes.string,
  }

  state = {
    submitting: false,
    submitted: false,
    fields: {
      names: [
        { firstName: '', middleName: '' },
        { firstName: '', middleName: '' },
      ],
      shareInBornThisWeek: false,
    },
    errors: {},
    twins: false,
  }

  render() {
    return (
      <PregnancyEmailLayout
        heroImage={this.getHeroImage()}
        heroImageMobile={this.getMobileHeroImage()}
        heroTextClassName={
          this.state.submitted ? css.heroTextConfirmation : null
        }
        heroText={this.renderHeroText()}
      >
        <div className={css.contentBody}>
          <div>
            {this.state.submitted
              ? this.renderConfirmation()
              : this.renderHighlightsText()}

            {!this.state.submitted && this.renderForm()}
          </div>

          <div className={css.termsAndPoliciesContainer}>
            <div>
              <TextLink
                className={css.termsAndPolicies}
                href={termsOfUsePath}
                size="sm"
                target="_blank"
                weight="regular"
              >
                Terms of Use
              </TextLink>
              <span className={css.termsDivider} />
              <TextLink
                className={css.termsAndPolicies}
                href={privacyPolicyPath}
                size="sm"
                target="_blank"
                weight="regular"
              >
                Privacy Policy
              </TextLink>
            </div>
          </div>
        </div>

        {/* Preload all of our css background images so we can transition smoothly */}
        <Image
          role="presentation"
          className="hidden"
          src={CONFIRMATION_BG_IMAGE}
          lazyLoad={false}
        />
        <Image
          role="presentation"
          className="hidden"
          src={CONFIRMATION_BG_IMAGE_MOBILE}
          lazyLoad={false}
        />
      </PregnancyEmailLayout>
    )
  }

  renderConfirmation() {
    return (
      <div>
        <div className={classNames('mtm', css.highlightsText)}>
          You're everything they need and more. Head to your account to get
          support, advice and inspo for the next stage of the journey.
        </div>
        <div className="mtn">
          <Button
            className={css.ctaButton}
            href={registryHomePath}
            size="lg"
            variant="primary"
          >
            Get inspired
          </Button>
        </div>
      </div>
    )
  }

  renderHighlightsText() {
    return (
      <div>
        <div className={classNames('mtm', css.highlightsText)}>
          <p>
            Tell us their name and arrival day to get personalized emails about
            their growth and advice to support you along the way.
          </p>
          <p>Plus, opt in below to be added to our 'Born This Week' email.</p>
        </div>
      </div>
    )
  }

  renderForm() {
    let { errors, fields } = this.state

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <DueDateSelect
          className={css.dateSelect}
          error={errors.birthDate}
          label={`${this.state.twins ? "Twin's" : "Baby's"} birth date`}
          onChange={this.handleChange.bind(this, 'birthDate')}
          preferFutureDates={false}
          years={years}
        />

        {this.renderNameFieldset(0)}
        <Collapse in={this.state.twins}>{this.renderNameFieldset(1)}</Collapse>

        <div className={classNames('h6', css.twinsToggle)}>
          <TextLink
            onClick={() => this.setState({ twins: !this.state.twins })}
            variant="eggplant"
            size="md"
          >
            {this.state.twins ? 'Reset' : 'I had twins'}
          </TextLink>
        </div>

        <div className={css.optInContainer}>
          <Checkbox
            checked={fields.shareInBornThisWeek}
            className={css.checkBox}
            onChange={() =>
              this.handleChange(
                'shareInBornThisWeek',
                !fields.shareInBornThisWeek
              )
            }
            size="md"
            id="bornThisWeekOptIn"
          />
          <label htmlFor="bornThisWeekOptIn">
            Include my baby's name and birth date in the 'Born This Week' email
          </label>
        </div>

        <Button
          className={css.ctaButton}
          loading={this.state.submitting}
          size="lg"
          type="submit"
          variant="primary"
        >
          Submit
        </Button>
      </form>
    )
  }

  renderHeroText() {
    if (this.state.submitted && this.state.twins) {
      return `Hooray! Baby ${this.state.fields.names[0].firstName} and ${this.state.fields.names[1].firstName} have arrived!`
    } else if (this.state.submitted) {
      return `Hooray! Baby ${this.state.fields.names[0].firstName} has arrived!`
    } else {
      const userNameString = this.props.userFirstName
        ? `, ${this.props.userFirstName}`
        : ''
      return `Congrats on your new baby${userNameString}!`
    }
  }

  renderNameFieldset(idx) {
    let labelPrefix = this.state.twins ? `Baby ${idx + 1}` : "Baby's"
    let name = this.state.fields.names[idx]

    return (
      <fieldset className={css.childNameInputs} key={idx}>
        <label className="control-label">{labelPrefix} name</label>
        <TextInput
          className={css.nameInput}
          inputProps={{
            value: name.firstName,
            onChange: this.handleChange.bind(this, `names[${idx}].firstName`),
            placeholder: 'First name',
          }}
          size="md"
          status={this.getFormStatus(`names[${idx}].firstName`)}
        />
        <TextInput
          className={css.nameInput}
          inputProps={{
            value: name.middleName,
            onChange: this.handleChange.bind(this, `names[${idx}].middleName`),
            placeholder: 'Middle name (optional)',
          }}
          size="md"
          status={this.getFormStatus(`names[${idx}].middleName`)}
        />
      </fieldset>
    )
  }

  getHeroImage() {
    if (this.state.submitted) {
      return CONFIRMATION_BG_IMAGE
    }

    return DEFAULT_BG_IMAGE
  }

  getMobileHeroImage() {
    if (this.state.submitted) {
      return CONFIRMATION_BG_IMAGE_MOBILE
    }

    return DEFAULT_BG_IMAGE_MOBILE
  }

  getFormStatus(errorKey) {
    const message = get(this.state.errors, errorKey)

    return message ? { message, type: 'error' } : undefined
  }

  handleChange(field, eventOrValue) {
    let value = isObject(eventOrValue)
      ? eventOrValue.target.value
      : eventOrValue
    this.setFormValue(field, value)
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.validateForm()) {
      this.setState({ submitting: true })
      let formData = {
        family: {
          id: this.props.familyId,
          arrival_date: this.state.fields.birthDate,
          share_babes: this.state.fields.shareInBornThisWeek,
          births: {
            names: this.state.twins
              ? this.state.fields.names
              : [this.state.fields.names[0]],
            bornThisWeekOptIn: this.state.fields.shareInBornThisWeek,
          },
        },
      }

      api
        .saveBirthday(formData, this.props.restrictedAccessToken)
        .then((resp) => {
          this.setState({ submitting: false, submitted: true })
        })
        .catch((resp) => {
          this.setState({ submitting: false })
          PubSub.publish(BLConstants.FLASH_MESSAGE, {
            message: parseErrors(resp)._error,
            variant: 'danger',
          })
        })
    }
  }

  setFormValue(name, value) {
    let fields = { ...this.state.fields }
    set(fields, name, value)
    this.setState({
      fields,
      errors: omit(this.state.errors, name),
    })
  }

  validateForm() {
    let errors = getValidator(this.state.twins)(this.state.fields)
    this.setState({ errors })
    return isEmpty(errors)
  }
}

export default PregnancyEmailBirthday
