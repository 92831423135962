import { Component } from 'react'
import PropTypes from 'prop-types'

import { get, mapKeys } from 'lodash'
import { Input, StateSelector } from 'components/forms'
import {
  maxLength,
  notEmail,
  notPoBox,
  required,
  usZip,
} from 'lib/form-validation'
import { getCityStateByZip } from 'registry/api'
import { Col, Row } from 'react-bootstrap'

export function createValidationRules(context, props) {
  const labelLength = 30
  let fields = {
    city: [required],
    country: [required],
    name: [required, notEmail, maxLength(labelLength)],
    line1: [required, notEmail, maxLength(labelLength)],
    line2: [maxLength(labelLength)],
    zip: [required, usZip],
    state: [
      (state, data) => {
        if (get(data, context ? `${context}.country` : 'country'))
          return required(state, data)
      },
    ],
  }

  if (props.isHBBOrder || props.isPremiumShipping) {
    fields.line1.push(notPoBox)
    fields.line2.push(notPoBox)
  }

  // Context is used to determine the full path of the key in the state
  if (context) {
    fields = mapKeys(fields, (value, key) => `${context}.${key}`)
  }
  return fields
}

class OrderAddressFields extends Component {
  static propTypes = {
    city: PropTypes.object,
    country: PropTypes.object,
    name: PropTypes.object,
    phone: PropTypes.object,
    states: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    state: PropTypes.object,
    line1: PropTypes.object,
    line2: PropTypes.object,
    zip: PropTypes.object,
  }

  render() {
    const {
      city,
      country,
      name: fullName,
      phone,
      states,
      state,
      line1,
      line2,
      zip,
    } = this.props

    return (
      <>
        <Input
          {...fullName}
          error={fullName.error}
          label="Full Name"
          type="text"
        />

        <Input {...line1} error={line1.error} label="Line 1" type="text" />

        <Input {...line2} error={line2.error} label="Line 2" type="text" />

        <Row>
          <Col sm={3} xs={3}>
            <Input
              {...zip}
              error={zip.error}
              label={country.value == 'United States' ? 'Zip' : 'Postal Code'}
              type="text"
              onChange={this.handleZipChange}
            />
          </Col>

          <Col xs={6}>
            <Input {...city} error={city.error} label="City" type="text" />
          </Col>

          <Col sm={3} xs={3}>
            <StateSelector
              {...state}
              country="United States"
              error={state.error}
              states={states}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Input
              {...country}
              readOnly
              error={country.error}
              label="Country"
              type="text"
            />
          </Col>
          <Col xs={6}>
            <Input {...phone} label="Phone" type="text" />
          </Col>
        </Row>
      </>
    )
  }

  handleZipChange = (e) => {
    const { city, state, zip } = this.props

    // Only autofill city and state for correct looking US zip
    const fiveDigitZip = e.target.value.match(/\d{5}/)
    if (fiveDigitZip) {
      getCityStateByZip(fiveDigitZip[0]).then((resp) => {
        resp.city && city.onChange(resp.city)
        resp.state && state.onChange(resp.state)
      })
    }

    zip.onChange(e.target.value)
  }
}

export default OrderAddressFields
