import React from 'react'

interface MailboxProps {
  className?: string
}

const Mailbox: React.FC<MailboxProps> = ({ className }: MailboxProps) => (
  <svg
    className={className}
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1238_5278)">
      <path
        d="M69.5009 49.7918C69.2237 49.0701 68.4603 47.0569 62.6559 44.0345C62.7082 43.6894 62.7448 43.3443 62.7448 42.9834V29.074C62.7605 21.3662 58.3524 15.0338 52.2081 13.1931C49.7714 12.8323 47.3085 12.6493 44.8455 12.6493H27.9554L26.1096 7.14823C25.5134 5.37032 23.8454 4.17285 21.9733 4.17285C21.5027 4.17285 21.0321 4.25129 20.5876 4.3977L6.31731 9.18236C5.26626 9.53271 4.33547 10.2177 3.78119 11.1799C3.14323 12.2832 3.01773 13.5696 3.41515 14.7618L5.02572 19.5622C3.16938 22.3807 2.16016 25.7587 2.16016 29.2831V43.2397C2.16016 46.6752 4.90022 49.4728 8.26778 49.4728H23.2806V62.3574C23.2806 65.3798 25.7278 67.827 28.7503 67.827H33.0748C36.0867 67.827 38.5287 65.385 38.5287 62.3731V50.4663C42.9787 53.0652 51.8735 57.876 57.2386 58.1898C57.8242 58.2264 58.4047 58.242 58.959 58.242C63.8273 58.242 67.268 56.987 68.8995 54.613C69.8931 53.1698 70.1127 51.4076 69.4957 49.7918H69.5009Z"
        fill="white"
      />
    </g>
    <path
      d="M17.3772 17.12C10.9976 17.12 5.8208 22.9296 5.8208 30.0935V44.05C5.8208 45.4776 6.92415 46.6227 8.26803 46.6227H49.9703L48.5428 17.12H17.3772Z"
      fill="url(#paint0_linear_1238_5278)"
      stroke="#792E68"
      stroke-width="1.59212"
      stroke-miterlimit="10"
    />
    <path
      d="M48.4907 17.12C42.6445 17.12 37.8965 22.6315 37.8965 29.8843V44.0186C37.8965 45.4619 38.9057 46.6227 40.1398 46.6227H56.6429C57.992 46.6227 59.0797 45.3573 59.0797 43.7938V29.8843C59.0901 22.6315 54.3473 17.12 48.4855 17.12H48.4907Z"
      fill="url(#paint1_linear_1238_5278)"
      stroke="#792E68"
      stroke-width="1.59212"
      stroke-miterlimit="10"
    />
    <path
      d="M30.2616 32.9225L22.2192 7.02258"
      stroke="#792D68"
      stroke-width="1.59212"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M21.7538 8.6819L7.33584 13.5145C6.96618 13.6384 6.76695 14.0385 6.89085 14.4082L9.93199 23.4814C10.0559 23.851 10.456 24.0503 10.8257 23.9264L25.2436 19.0938C25.6133 18.9699 25.8125 18.5698 25.6886 18.2001L22.6475 9.12689C22.5236 8.75723 22.1235 8.558 21.7538 8.6819Z"
      fill="url(#paint2_linear_1238_5278)"
      stroke="#792D68"
      stroke-width="1.07468"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M19.7671 14.3063C19.7933 14.4423 19.8142 14.5783 19.8247 14.709C19.8299 14.8136 19.8351 14.9129 19.8351 15.0123C19.8247 15.4777 19.7149 15.9221 19.5371 16.3562C19.4952 16.4555 19.4534 16.5601 19.4011 16.6542C19.354 16.7588 19.2965 16.8634 19.2442 16.968C19.2442 16.9732 19.2338 16.9837 19.2338 16.9889C18.7788 17.8151 18.1723 18.5262 17.4872 19.1799C16.5512 19.0701 15.6361 18.8714 14.7733 18.4844C13.9053 18.0922 13.1523 17.5589 12.7235 16.6647C12.3941 15.9744 12.4202 15.2946 12.8647 14.6672C13.1314 14.2959 13.4556 14.0553 13.8425 13.9351C13.8635 13.9298 13.8948 13.9194 13.9158 13.9142C14.0256 13.888 14.1354 13.8671 14.2504 13.8566C14.3289 13.8514 14.4073 13.8462 14.4962 13.8462C14.5746 13.8462 14.6531 13.8566 14.7315 13.8619C15.2126 13.9194 15.5943 14.1495 15.9185 14.4894C15.9708 14.024 16.1329 13.6109 16.4833 13.271C17.048 12.7376 17.7173 12.5598 18.4546 12.7899C19.1867 13.02 19.6155 13.5481 19.7671 14.3011V14.3063Z"
      fill="url(#paint3_linear_1238_5278)"
      stroke="#6F3365"
      stroke-width="1.07468"
      stroke-linejoin="round"
    />
    <path
      d="M9.37646 36.3477V41.6343C9.37646 42.2461 9.87323 42.7481 10.4903 42.7481H15.39"
      stroke="#6F3365"
      stroke-width="1.07468"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.875 19.9752H37.5618"
      stroke="#6F3365"
      stroke-width="1.07468"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.8965 46.6227C37.8965 46.6227 51.236 54.9841 57.4534 55.3449C63.6709 55.7057 66.8136 53.8285 66.0815 51.9042C65.3494 49.9798 58.3267 46.8162 58.3267 46.8162L37.9017 46.6227H37.8965Z"
      fill="url(#paint4_linear_1238_5278)"
      stroke="#792E68"
      stroke-width="1.59212"
      stroke-miterlimit="10"
    />
    <path
      d="M26.9414 46.6227H34.8688V63.1834C34.8688 64.1717 34.0635 64.977 33.0752 64.977H28.7507C27.7519 64.977 26.9414 64.1665 26.9414 63.1677V46.6227Z"
      fill="url(#paint5_linear_1238_5278)"
      stroke="#6F3365"
      stroke-width="1.59212"
      stroke-linejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_1238_5278"
        x="0.42558"
        y="4.17285"
        width="71.1493"
        height="67.1233"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.73458" />
        <feGaussianBlur stdDeviation="0.867288" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1238_5278"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1238_5278"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1238_5278"
        x1="36.9204"
        y1="21.7937"
        x2="9.2544"
        y2="48.7504"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A0DCFF" />
        <stop offset="0.7359" stop-color="#56BB6C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1238_5278"
        x1="38.1033"
        y1="54.189"
        x2="58.8772"
        y2="29.3314"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E3264" />
        <stop offset="1" stop-color="#A0DCFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1238_5278"
        x1="6.88905"
        y1="18.483"
        x2="25.5301"
        y2="15.676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FED208" />
        <stop offset="1" stop-color="#F47960" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1238_5278"
        x1="15.4301"
        y1="19.637"
        x2="16.5924"
        y2="12.1856"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0795732" stop-color="#FF6D4E" />
        <stop offset="0.772297" stop-color="#FED7FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1238_5278"
        x1="69.3163"
        y1="50.8785"
        x2="43.3055"
        y2="50.8785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A0DCFF" />
        <stop offset="0.7359" stop-color="#56BB6C" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1238_5278"
        x1="25.3339"
        y1="57.263"
        x2="38.6823"
        y2="53.9955"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0795732" stop-color="#FF6D4E" />
        <stop offset="0.772297" stop-color="#FED7FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default Mailbox
