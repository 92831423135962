import { get, has } from 'lodash'
import { successMessage } from 'lib/flash-message'
import { parseErrors } from '../../lib/api-utils'
import * as api from '../api'
import { apiV3RestrictedThankYouNoteAllowedPath, registryPath } from 'lib/urls'
import fetch from 'lib/fetch'
import {
  getRegistryDescription,
  getRegistryId,
  getRegistryUIState,
  getRegistryUrlSlug,
} from '../reducers'
import { setActiveModal } from './ui-actions'
import {
  UPDATE_NAVBAR_USER_ICON,
  HELLO_BABY_BOX,
} from '../../registry/constants'

export {
  setCashFundsConfig,
  setCatalogCategoriesConfig,
  fetchCashFundsConfig,
  fetchCatalogCategoriesConfig,
  setBookmarkletJS,
  setShowExtensionInstall,
} from './application-configuration'
export {
  setActiveModal,
  setIsAllCategoriesCollapsed,
  setIsContributorView,
  setIsMobile,
  setIsRegistryDiscountView,
  setIsShippingAddressButtonVisible,
} from './ui-actions'
export {
  createReservation,
  cancelReservation,
  createReservationAsOwner,
  fetchCategorizedRegItems,
  fetchCategories,
  fetchRegItem,
  fetchReservedRegItems,
  updateRegItem,
  addFilter,
  setFilters,
  setIsOrganizing,
  sendReservationRecoveryEmail,
  removeReservationByCurrentVisitor,
  updateGiftMessage,
} from './reg-items-actions'
export {
  fetchLatestFeedSession,
  fetchFeedSession,
  setFeedItems,
} from './feed-actions'
export { fetchUserOrders } from './orders-actions'

export const SET_USER = 'SET_USER'
export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  }
}

export const MERGE_USER = 'MERGE_USER'
export const mergeUser = (user) => ({ type: MERGE_USER, user })

export const SET_USER_CREDIT = 'SET_USER_CREDIT'

export const MERGE_REGISTRY = 'MERGE_REGISTRY'
export const mergeRegistry = (registry) => ({ type: MERGE_REGISTRY, registry })

export const SET_REGISTRY = 'SET_REGISTRY'
export const setRegistry = (registry) => {
  return {
    type: SET_REGISTRY,
    registry,
  }
}

export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS'
export const setShippingAddress = (shippingAddress) => {
  return {
    type: SET_SHIPPING_ADDRESS,
    shippingAddress,
  }
}

export const SET_ARRIVAL_DATE = 'SET_ARRIVAL_DATE'
export const setArrivalDate = (arrivalDate) => ({
  type: SET_ARRIVAL_DATE,
  arrivalDate,
})

export const SET_IS_ARRIVAL_DATE_KNOWN = 'SET_IS_ARRIVAL_DATE_KNOWN'
export const setIsArrivalDateKnown = (isArrivalDateKnown) => ({
  type: SET_IS_ARRIVAL_DATE_KNOWN,
  isArrivalDateKnown,
})

export const SET_PLANNING_STAGE = 'SET_PLANNING_STAGE'
export const setPlanningStage = (planningStage) => ({
  type: SET_PLANNING_STAGE,
  planningStage,
})

export const SET_INSERT_CARD_ORDERS = 'SET_INSERT_CARD_ORDERS'
export const setInsertCardOrders = (insertCardOrders) => {
  return {
    type: SET_INSERT_CARD_ORDERS,
    insertCardOrders: insertCardOrders || [],
  }
}

export const SET_BOOKMARKLET_AS_USED = 'SET_BOOKMARKLET_AS_USED'
export const setBookmarkletAsUsed = (value) => ({
  type: SET_BOOKMARKLET_AS_USED,
  value,
})

export const SET_GIFT_GIVER_ADDRESS = 'SET_GIFT_GIVER_ADDRESS'
export const setGiftGiverAddress = (giftGiverAddress) => {
  return {
    type: SET_GIFT_GIVER_ADDRESS,
    giftGiverAddress,
  }
}

export const UPDATE_SETUP_PROGRESS_GOALS = 'UPDATE_SETUP_PROGRESS_GOALS'
export const updateSetupProgressGoals = (goals) => {
  return {
    type: UPDATE_SETUP_PROGRESS_GOALS,
    goals,
  }
}

export const saveCashFundPreferences = (data) => {
  return (dispatch) => {
    return api
      .saveCashFundPreferences(data)
      .then((resp) => {
        dispatch(setCashFundPreferences(resp))
        successMessage('Your cash fund preferences have been updated!')
      })
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}

export const SET_CASH_FUND_PREFERENCES = 'SET_CASH_FUND_PREFERENCES'
export const setCashFundPreferences = (preferences) => {
  return {
    type: SET_CASH_FUND_PREFERENCES,
    preferences,
  }
}

export const SET_TWO_FACTORS = 'SET_TWO_FACTORS'
export const setTwoFactors = (twoFactors) => ({
  type: SET_TWO_FACTORS,
  twoFactors,
})

export const getTwoFactors = () => (dispatch) => {
  api.getTwoFactors().then((resp) => {
    dispatch(setTwoFactors(resp))
  })
}

export const deleteTwoFactor = (id) => (dispatch) => {
  api.deleteTwoFactor(id).then(() => {
    dispatch(getTwoFactors())
    successMessage('Two-factor authentication removed.')
  })
}

export const saveShippingAddress = (data) => {
  return (dispatch, getState) => {
    const state = getState()
    data.registryId = getRegistryId(state)
    return api
      .saveAddress(data)
      .then((resp) => dispatch(setShippingAddress(resp)))
      .catch((resp) => {
        // exit if server error or throttled
        if (!resp.fieldErrors) return Promise.reject(resp)

        // replacing backend validation messages with 'Invalid' to not clutter up the UI
        const fieldErrors = {
          streetAddress1: has(resp.fieldErrors, 'address1') ? 'Invalid' : '',
          streetAddress2: has(resp.fieldErrors, 'address2') ? 'Invalid' : '',
          city: has(resp.fieldErrors, 'city') ? 'Invalid' : '',
          state: has(resp.fieldErrors, 'state') ? 'Invalid' : '',
          zip: has(resp.fieldErrors, 'zip') ? 'Invalid' : '',
          base: get(resp.fieldErrors, 'base'),
        }

        // spread fieldErrors so reduxForm can attach error to correct form field
        return Promise.reject({ ...resp, ...fieldErrors })
      })
  }
}

export const saveRegistry = (
  values,
  opts = { hideModal: true, redirectToHbbModal: false }
) => {
  return (dispatch) => {
    return api
      .saveRegistry(values)
      .then((resp) => {
        dispatch({ type: SET_REGISTRY, registry: resp.registry })
        if (resp.user) {
          dispatch({ type: SET_USER, user: resp.user })
        }
        if (opts.redirectToHbbModal) {
          dispatch(setActiveModal(HELLO_BABY_BOX))
        } else if (opts.hideModal) {
          dispatch(setActiveModal(null))
        }
        PubSub.publish(UPDATE_NAVBAR_USER_ICON, {
          newUrl: resp.registry.photoUrl,
        })
      })
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}

export const saveRegistryUIState = (
  nextUIState,
  opts = { hideModal: true }
) => {
  return (dispatch, getState) => {
    const id = getRegistryId(getState())
    const uiState = getRegistryUIState(getState())
    return dispatch(
      saveRegistry(
        {
          registry: {
            id,
            uiState: {
              ...uiState,
              ...nextUIState,
            },
          },
        },
        opts
      )
    )
  }
}

export const saveUser = (values) => {
  return (dispatch) => {
    return api
      .saveUser(values)
      .then((resp) => {
        dispatch({ type: SET_USER, user: resp.user })
      })
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}

export const deleteUser = (values) => {
  return (dispatch) => {
    return api
      .deleteUser(values)
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}

// special actionCreator for personalize form
// needs to reload page when URL slug changes
// and notify user of how to see their note
export const savePersonalizeRegistry = (values) => {
  return (dispatch, getState) => {
    const oldDescription = getRegistryDescription(getState())
    const oldUrl = getRegistryUrlSlug(getState())
    return dispatch(saveRegistry(values)).then((resp) => {
      if (oldUrl != values.registry.urlSlug) {
        window.location = registryPath(values.registry.urlSlug)
      }
      if (oldDescription != values.registry.description) {
        successMessage(
          "Note updated! Click 'Guest View' to see it the way your guests will."
        )
      }
    })
  }
}

// special actionCreator for registry info form
// needs to reload page when URL slug changes
export const saveRegistryInfo = (values) => {
  return (dispatch, getState) => {
    const oldUrl = getRegistryUrlSlug(getState())
    return dispatch(saveRegistry(values)).then((resp) => {
      if (oldUrl != values.registry.urlSlug) {
        window.location.reload()
      }
    })
  }
}

export const SAVE_USER_EVENT = 'SAVE_USER_EVENT'
export const saveUserEvent = (eventName, value) => {
  return function (dispatch) {
    return api
      .saveUserEvent(eventName, value)
      .then((resp) => {
        dispatch({ type: SAVE_USER_EVENT, event: { ...resp.event, value } })
      })
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}

export const saveInsertCardOrder = (values) => {
  return function (dispatch) {
    return api
      .saveInsertCardOrder(values)
      .then((resp) => {
        dispatch(setInsertCardOrders(resp.insertCardOrders))
      })
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}

export const cancelInsertCardOrder = (insertCardOrder) => {
  return function (dispatch) {
    return api
      .cancelInsertCardOrder(insertCardOrder)
      .then((resp) => {
        dispatch(setInsertCardOrders(resp.insertCardOrders))
      })
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}

export const saveIsThankYouNoteAllowed = (data) =>
  fetch(apiV3RestrictedThankYouNoteAllowedPath, {
    body: JSON.stringify(data),
    method: 'POST',
  })

export const saveGiftGiverAddress = (data) => {
  return (dispatch, getState) => {
    const state = getState()
    data.registryId = getRegistryId(state)
    return api
      .saveGiftGiverAddress(data)
      .then((resp) => dispatch(setGiftGiverAddress(resp)))
      .catch((resp) => {
        // exit if server error or throttled
        if (!resp.fieldErrors) return Promise.reject(resp)

        // replacing backend validation messages with 'Invalid' to not clutter up the UI
        const fieldErrors = {
          streetAddress1: has(resp.fieldErrors, 'address1') ? 'Invalid' : '',
          streetAddress2: has(resp.fieldErrors, 'address2') ? 'Invalid' : '',
          city: has(resp.fieldErrors, 'city') ? 'Invalid' : '',
          state: has(resp.fieldErrors, 'state') ? 'Invalid' : '',
          zip: has(resp.fieldErrors, 'zip') ? 'Invalid' : '',
          base: get(resp.fieldErrors, 'base'),
        }

        // spread fieldErrors so reduxForm can attach error to correct form field
        return Promise.reject({ ...resp, ...fieldErrors })
      })
  }
}

export const saveConsolidationChoice = (consolidationChoice) => {
  return (dispatch, getState) => {
    const id = getRegistryId(getState())
    return dispatch(
      saveRegistry(
        {
          registry: {
            id,
            shipping_consolidation_attributes: {
              consolidation_choice: consolidationChoice,
            },
          },
        },
        { hideModal: false }
      )
    )
  }
}
