import * as React from 'react'
import classnames from 'classnames'

export type FrameProps = React.HTMLAttributes<HTMLElement>

export default function Frame({ className, ...rest }: FrameProps) {
  return (
    <section className={classnames('flex col center', className)} {...rest} />
  )
}
