import { sortBy, values } from 'lodash'
import {
  ADD_FILTER,
  ADD_REG_ITEM,
  ADD_RESERVATION_BY_CURRENT_VISITOR,
  REMOVE_RESERVATION_BY_CURRENT_VISITOR,
  REMOVE_REG_ITEM,
  REMOVE_RESERVATION,
  SET_FILTERS,
  SET_IS_ORGANIZING,
  SET_IS_FETCHING_CATEGORIZED_REG_ITEMS,
  SET_REG_ITEMS,
  SET_RESERVED_REG_ITEMS,
  SET_REGISTRY_CATEGORIES,
  SET_RESERVATIONS_BY_CURRENT_VISITOR,
  UPDATE_REG_ITEM,
  UPDATE_REGISTRY_CATEGORY,
  SET_IS_FETCHING_RESERVED_REG_ITEMS,
} from '../actions/reg-items-actions'
import { formatRegistryCategory, formatStore } from '../lib/formats'
import {
  filterByBabylistStoreView,
  filterByFavorites,
  filterByGroupGifts,
  filterByGiftView,
  filterByPriceRange,
  filterByStoreNames,
} from '../lib/filters'
import { SET_GIFT_GIVER_ADDRESS } from '../actions'

export const initialState = {
  filters: {},
  reservedRegItems: {}, // Note: This collection is indexed by reservation id
  reservationsByCurrentVisitor: {}, // Note: This collection is indexed by its regItemId
  registryCategories: {}, // Note: This collection is indexed by its id
  regItems: {}, // Note: This collection is indexed by its id
  isOrganizing: false,
}

export const RegItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_ORGANIZING:
      return { ...state, isOrganizing: action.isOrganizing }

    case SET_REGISTRY_CATEGORIES:
      return { ...state, registryCategories: action.registryCategories }

    case UPDATE_REGISTRY_CATEGORY:
      return {
        ...state,
        registryCategories: {
          ...state.registryCategories,
          [action.registryCategoryId]: action.registryCategory,
        },
      }

    case SET_REG_ITEMS:
      if (state.isPartialSet) {
        Object.keys(state.regItems).forEach((key) => {
          // Preserve object reference to prevent visual flicker
          action.regItems[key] = state.regItems[key]
        })
      }
      return {
        ...state,
        regItems: action.regItems,
        hasReceivedRegItems: true,
        isPartialSet: action.isPartialSet || false,
      }

    case SET_RESERVED_REG_ITEMS:
      return {
        ...state,
        reservedRegItems: action.reservedRegItems,
        hasReceivedReservedRegItems: true,
      }

    case REMOVE_RESERVATION:
      const reservedRegItems = { ...state.reservedRegItems }
      delete reservedRegItems[action.reservationId]

      return { ...state, reservedRegItems }

    case REMOVE_REG_ITEM:
      const regItems = { ...state.regItems }
      delete regItems[action.regItemId]

      return { ...state, regItems }

    case ADD_REG_ITEM:
      return {
        ...state,
        regItems: { ...state.regItems, [action.regItemId]: action.regItem },
      }

    case UPDATE_REG_ITEM:
      // Use the action.regItemId instead of the action.regItem.id to preserve a single source of truth
      const updatedRegItem = {
        ...state.regItems[action.regItemId],
        ...action.data,
        id: action.regItemId,
      }
      return {
        ...state,
        regItems: { ...state.regItems, [action.regItemId]: updatedRegItem },
      }

    case SET_IS_FETCHING_CATEGORIZED_REG_ITEMS:
      return {
        ...state,
        isFetchingCategorizedRegItems: action.isFetchingCategorizedRegItems,
      }

    case SET_IS_FETCHING_RESERVED_REG_ITEMS:
      return {
        ...state,
        isFetchingReservedRegItems: action.isFetchingReservedRegItems,
      }

    case SET_RESERVATIONS_BY_CURRENT_VISITOR:
      return {
        ...state,
        reservationsByCurrentVisitor: action.reservationsByCurrentVisitor,
      }

    case ADD_RESERVATION_BY_CURRENT_VISITOR:
      if (!action.reservation.isActive) return state
      return {
        ...state,
        reservationsByCurrentVisitor: {
          ...state.reservationsByCurrentVisitor,
          [action.reservation.regItemId]: action.reservation,
        },
      }

    case REMOVE_RESERVATION_BY_CURRENT_VISITOR:
      const reservationsByCurrentVisitor = {
        ...state.reservationsByCurrentVisitor,
      }
      delete reservationsByCurrentVisitor[action.regItemId]

      return { ...state, reservationsByCurrentVisitor }

    case ADD_FILTER:
      return { ...state, filters: { ...state.filters, ...action.filter } }

    case SET_FILTERS:
      return { ...state, filters: action.filters }

    case SET_GIFT_GIVER_ADDRESS: {
      const reservationsByCurrentVisitor = {
        ...state.reservationsByCurrentVisitor,
      }

      Object.keys(reservationsByCurrentVisitor).forEach((key) => {
        reservationsByCurrentVisitor[key] = {
          ...reservationsByCurrentVisitor[key],
          giftGiverThankYouAddressProvided: true,
        }
      })

      return { ...state, reservationsByCurrentVisitor }
    }

    default:
      return state
  }
}

export const getFavoriteRegItemsCount = (state) =>
  getRegItems(state).filter((regItem) => regItem.isFavorite).length

export const getGroupGiftRegItemsCount = (state) =>
  getRegItems(state).filter((regItem) => regItem.isGroupGift).length

const getRegItemIdsForCategoryId = (state, categoryId) =>
  getRegItems(state)
    .filter((regItem) => regItem.categoryId === categoryId)
    .map((regItem) => regItem.id)

export const getFilters = (state) => state.filters

export const getIsOrganizing = (state) => state.isOrganizing

export const getRegItem = (state, id) => state.regItems[id]
export const getRegItems = (state) => Object.values(state.regItems)
export const getRegItemsById = (state) => state.regItems

export const getReservedRegItems = (state) =>
  Object.values(state.reservedRegItems)

export const getReservationsByCurrentVisitor = (state) =>
  state.reservationsByCurrentVisitor

export const getReservedRegItemsByCurrentVisitor = (state) => {
  const reservationsByCurrentVisitor = getReservationsByCurrentVisitor(state)
  return getRegItems(state)
    .filter((regItem) => reservationsByCurrentVisitor[regItem.id])
    .map((regItem) => ({ ...regItem, isReservedByCurrentVisitor: true }))
}

export const getRegItemsCount = (state) =>
  state.hasReceivedRegItems ? getRegItems(state).length : 0

export const getCategorizedRegItemsCount = (state) =>
  getCategorizedRegItems(state).reduce(
    (acc, category) => acc + category.regItems.length,
    0
  )

// export const getCategorizedRegItems = state => state.categorizedRegItems
export const getCategorizedRegItems = (state, onlyRegistryDiscountEligible) => {
  const isOrganizing = getIsOrganizing(state)
  let categories = getRegistryCategories(state)
  const filters = isOrganizing ? {} : getFilters(state)
  let regItems = applyFiltersToRegItems(state, filters)

  if (!isOrganizing) {
    categories = Number.isInteger(filters.categoryId)
      ? [categories.find((c) => c.id == filters.categoryId)]
      : categories

    // filter out regitems that dont have at least one babylist offer
    if (onlyRegistryDiscountEligible) {
      regItems = regItems.filter((regItem) =>
        regItem.offers.some((offer) => offer.isBabylist)
      )
    }
  }

  return categories
    .sort((c1, c2) => c1.position - c2.position) // RegItem Categories are sorted by position asc
    .map((category) => ({
      ...category,
      regItems: regItems
        .filter((regItem) => regItem.categoryId === category.id)
        .sort((r1, r2) => r2.position - r1.position),
    }))
}

export const getIsFetchingCategorizedRegItems = (state) =>
  state.isFetchingCategorizedRegItems
export const getIsPartialSetOfRegItems = (state) => state.isPartialSet
export const getReservationCount = (state) =>
  state.hasReceivedReservedRegItems ? getReservedRegItems(state).length : 0
export const getPurchasedReservationCount = (state) =>
  state.hasReceivedReservedRegItems
    ? getReservedRegItems(state).filter((res) => res.isPurchased).length
    : 0

export const getReservedRegItemsCount = (state) =>
  getRegItems(state).filter(
    (regItem) => regItem.isReserved || regItem.isSomewhatReserved
  ).length

export const isPageReady = (state) =>
  !state.isFetchingCategorizedRegItems && state.hasReceivedRegItems

export const isGiftTrackerPageReady = (state) =>
  !state.isFetchingReservedRegItems && state.hasReceivedReservedRegItems

export const getPopulatedCategories = (state) => {
  return Object.values(state.registryCategories)
    .filter(
      (category) => getRegItemIdsForCategoryId(state, category.id).length > 0
    )
    .map(formatRegistryCategory)
}

export const getRegistryCategories = (state) =>
  Object.values(state.registryCategories).map(formatRegistryCategory)

export const getRegistryCategoriesHash = (state) => state.registryCategories

export const getRegistryCategory = (state, id) => state.registryCategories[id]

export const getDistinctStores = (state) => {
  const stores = {}

  const presentCategoryIds = Object.keys(state.registryCategories).map((id) =>
    parseInt(id)
  )

  Object.values(state.regItems).forEach((regItem) => {
    if (presentCategoryIds.includes(regItem.categoryId)) {
      regItem.offers.forEach((offer) => {
        stores[offer.storeName] = formatStore(offer)
      })
    }
  })

  return sortBy(values(stores), (store) => store.displayName.toLowerCase())
}

const applyFiltersToRegItems = (state, filters) => {
  let filteredRegItems = getRegItems(state)
  if (filters.babylistStoreView) {
    filteredRegItems = filterByBabylistStoreView(filteredRegItems)
  }

  if (filters.storeNames && filters.storeNames.length > 0) {
    filteredRegItems = filterByStoreNames(filteredRegItems, filters.storeNames)
  }

  if (filters.priceRange) {
    filteredRegItems = filterByPriceRange(filteredRegItems, filters)
  }

  if (filters.giftView) {
    filteredRegItems = filterByGiftView(filteredRegItems, filters.giftView)
  }

  if (filters.favoritesView) {
    filteredRegItems = filterByFavorites(filteredRegItems)
  }

  if (filters.groupGiftView) {
    filteredRegItems = filterByGroupGifts(filteredRegItems)
  }

  return filteredRegItems
}

export default RegItemsReducer
