import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google'
import { InfoButton } from 'components/buttons'
import { GOOGLE_LOGIN_API_CLIENT_ID } from 'shared/constants'
import { useState } from 'react'
import css from './GoogleSignInButton.scss'

interface GoogleSignInButtonProps {
  clickTracking: () => void
  handleBabylistResponse: (
    provider: 'Google',
    response: unknown,
    setError: (error: string) => void
  ) => void
  label: string
}

const GoogleSignInButton = ({
  clickTracking,
  handleBabylistResponse,
  label,
}: GoogleSignInButtonProps) => {
  const [error, setError] = useState('')

  const handleGoogleButtonMouseEnter = () => {
    const el = window.document.getElementsByClassName(
      css.googleBlSignInButton
    )[0]

    el.classList.add(css.googleBlSignInButtonHover)
  }

  const handleGoogleButtonMouseLeave = () => {
    const el = window.document.getElementsByClassName(
      css.googleBlSignInButton
    )[0]

    el.classList.remove(css.googleBlSignInButtonHover)
  }

  const handleSuccessResponse = async (
    credentialResponse: CredentialResponse
  ) => {
    const raw = await fetch(`/auth/google/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Key-Inflection': 'camel',
      },
      body: JSON.stringify(credentialResponse),
    })

    const response = await raw.json()

    handleBabylistResponse('Google', response, setError)
  }

  return (
    <div className={css.googleSignInButtonContainer}>
      <div
        onMouseEnter={handleGoogleButtonMouseEnter}
        onMouseLeave={handleGoogleButtonMouseLeave}
      >
        <GoogleOAuthProvider clientId={GOOGLE_LOGIN_API_CLIENT_ID}>
          <GoogleLogin
            click_listener={clickTracking}
            logo_alignment="center"
            shape="pill"
            size="large"
            ux_mode="popup"
            width="380px"
            onError={() => {
              setError(error)
            }}
            onSuccess={handleSuccessResponse}
          />
        </GoogleOAuthProvider>
      </div>
      <InfoButton pill aria-label={label} className={css.googleBlSignInButton}>
        <img
          alt="G"
          className={css.googleBlSignInButtonImage}
          src="https://images.babylist.com/image/upload/v1695676201/icons/btn_google_light_normal_ios_borderless.svg"
        />
        <div className={css.googleSignInButtonText}>{label}</div>
      </InfoButton>
      {error && (
        <span className={`h7 help-block ${css.googleSignInButtonError}`}>
          {error}
        </span>
      )}
    </div>
  )
}

export default GoogleSignInButton
