import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getIsHbbOrder,
  getIsPremiumShipping,
  getOrderCreatedAt,
  getOrderHolds,
  getOrderStatus,
  getPageMessage,
  getShippingAddress,
} from '../reducers'
import { ORDER_STATUS, ORDER_HOLDS } from '../constants'
import { SUPPORT_EMAIL } from 'shared/constants'
import dateformat from 'dateformat'
import { SectionHeading } from '../../components/headings'

const OrderStatus = (props) => {
  const {
    isHbbOrder,
    isPremiumShipping,
    orderStatus,
    orderCreatedAt,
    orderHolds,
    pageMessage,
    shippingAddress,
  } = props
  let content
  let secondary_title
  let title

  switch (orderStatus) {
    case ORDER_STATUS.HOLD:
      title = 'Your order is on hold'
      if (orderHolds.includes(ORDER_HOLDS.ADDRESS)) {
        if ((isHbbOrder || isPremiumShipping) && shippingAddress?.isPoBox) {
          secondary_title = <strong>Please update the shipping address</strong>
          content = (
            <p>
              Sorry, we can't ship{' '}
              {isHbbOrder
                ? 'your Hello Baby Box'
                : 'orders with expedited shipping'}{' '}
              to a P.O. Box.
              <br />
              Update the shipping address below, so we can ship your order.
            </p>
          )
        } else {
          secondary_title = <strong>Please confirm the shipping address</strong>
          content = (
            <div>
              <p>
                The order you placed on {dateformat(orderCreatedAt, 'longDate')}
                , is on hold because the shipping address provided does not
                match postal records.
              </p>
              <p>
                Confirm the shipping address below, so we can ship your order.
              </p>
            </div>
          )
        }
      }
      break
    case ORDER_STATUS.OPEN:
      title = 'Your order is on its way!'
      content = <p>{pageMessage}</p>
      break
    case ORDER_STATUS.CANCELLED:
      title = 'Your order has been canceled'
      if (orderHolds.includes(ORDER_HOLDS.ADDRESS)) {
        content = (
          <div>
            Your order has been canceled because the shipping address was
            invalid and we didn't get an update from you. Please place your
            order again or reach out to{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> for help.
          </div>
        )
      }
      break
    case ORDER_STATUS.COMPLETED:
      title = 'Your order has been completed'
      break
    case ORDER_STATUS.SHIPPED:
      title = 'Your order has been shipped'
      break
    default:
      return null
  }

  return (
    <div className="pbl">
      <SectionHeading className="h2 text-center">{title}</SectionHeading>
      {secondary_title ? (
        <div className="h2 text-center pbm">{secondary_title}</div>
      ) : null}
      {content}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isHbbOrder: getIsHbbOrder(state),
    isPremiumShipping: getIsPremiumShipping(state),
    orderCreatedAt: getOrderCreatedAt(state),
    orderHolds: getOrderHolds(state),
    orderStatus: getOrderStatus(state),
    pageMessage: getPageMessage(state),
    shippingAddress: getShippingAddress(state),
  }
}

export default connect(mapStateToProps)(OrderStatus)
