import Frame from '../../components/Frame'
import SectionHeader from '../../components/SectionHeader'
import Image from '../../../../components/image'
import { Bowed } from '../../../../components/curves'
import { mobileAppDownloadPath } from '../../../../lib/urls'

export default function AppDownloadSection() {
  return (
    <Frame>
      <div className="flex" style={{ marginBottom: -50 }}>
        <div className="mobile-image-wrapper">
          <Image
            lazyLoad={false}
            className="wh-100 no-pointer-events"
            src="//images.babylist.com/image/upload/q_auto,f_auto,w_800/v1634334378/babylist-app-screenshot_els800.png"
            alt="Babylist is available on iPhone"
          />
        </div>

        <div className="flex col center">
          <SectionHeader>Ready to Start Building Your Registry?</SectionHeader>

          <p className="mvl">
            You'll need our 5-star rated app to add items from any online store
            (from your mobile device.)
          </p>
        </div>
      </div>

      {/* @ts-ignore */}
      <Bowed position="top" />

      <div className="white-bg w-100 flex center pbl app-download-button-wrapper">
        <a href={mobileAppDownloadPath()} className="btn-pill btn btn-info">
          Download The App
        </a>
      </div>
    </Frame>
  )
}
