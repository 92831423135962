import PropTypes from 'prop-types'

import { DiaperCalculatorResultLayout } from './diaper-calculator-result-layout'
import { DiaperBreakdown } from './components/diaper-breakdown'
import { Heading, Subheading, ResultProTipBox } from './components/ui'

const PrimaryContent = () => (
  <>
    <Heading>Go Big or Go Home!</Heading>
    <Subheading>
      The nice thing about registering for diapers is you will need them. So
      don't be afraid to put a lot on your registry. Here's what you're going to
      need for the first year:
    </Subheading>
    <div className="mbxl">
      <DiaperBreakdown
        items={[
          {
            title: 'Newborn Diapers',
            subtitle: '1-2 boxes, 128 count',
          },
          { title: 'Size 1 Diapers', subtitle: '4 boxes, 168 count' },
          { title: 'Size 2 Diapers', subtitle: '5 boxes, 148 count' },
          { title: 'Size 3 Diapers', subtitle: '8 boxes, 136 count' },
        ]}
      />
    </div>

    <p className="mbxxl">
      Since you're looking to stock up ahead of time, it's even more important
      that you choose a diaper with a good fit for your baby and one that won't
      irritate their skin if it's more on the sensitive side.
    </p>

    <p className="mbxxl">
      If you want to try out a few different brands before you commit, the{' '}
      <a href="/hello-baby/babylist-diaper-box-brands" target="_blank">
        Babylist Diaper Box
      </a>{' '}
      is a good place to start. You'll get an assortment of diapers and wipes
      from three popular brands so you can decide for yourself what works best
      for your little one. Bonus: they're all eco-friendly and free of
      fragrances, chlorine and other things you likely don't want touching your
      baby's skin.
    </p>
  </>
)

const SecondaryContent = ({ DiaperCalculatorProducts }) => (
  <>
    <div className="mbxxl">
      <DiaperCalculatorProducts />
    </div>

    <div className="mbxxl text-center">
      <ResultProTipBox>
        <p>
          Your baby might outgrow newborn diapers before they are even born! So
          if your baby is measuring big or you have a family history of
          10-pounders, you may want to skip registering for newborn diapers and
          grab a smaller pack (like a 31 count bag) on your next errand run. You
          can always stock up on more after the baby is born.
        </p>
        <p>
          On the flipside, if you think there's a chance your baby might show up
          early (hello, twins), grab some Preemie diapers just in case too.
        </p>
      </ResultProTipBox>
    </div>
  </>
)

SecondaryContent.propTypes = {
  DiaperCalculatorProducts: PropTypes.elementType.isRequired,
}

export const DiaperCalculatorResultGoBigOrGoHome = ({
  DiaperCalculatorProducts,
}) => (
  <DiaperCalculatorResultLayout
    primaryContent={<PrimaryContent />}
    secondaryContent={
      <SecondaryContent DiaperCalculatorProducts={DiaperCalculatorProducts} />
    }
  />
)

DiaperCalculatorResultGoBigOrGoHome.propTypes = {
  DiaperCalculatorProducts: PropTypes.elementType.isRequired,
}
