import { Component } from 'react'
import { Form, Input } from 'components/forms'
import { InfoButton } from 'components/buttons'
import * as BLConstants from 'shared/constants'
import * as api from '../api'
import { SectionHeading } from '../../components/headings'

export default class PurchaseReturnForm extends Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  handleChange = (fieldName, value) => {
    this.setState({ [fieldName]: value, error: false })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ submitting: true, error: false })

    return api
      .getPurchaseReturnLink(this.state)
      .then(({ webUrl }) => {
        if (webUrl) {
          window.location.href = webUrl
        } else {
          this.setState({
            error: `This order is not eligible for return. Please contact ${BLConstants.SUPPORT_EMAIL} for assistance.`,
          })
        }
      })
      .catch(({ error }) => {
        if (error && error.message) {
          this.setState({
            error:
              'We could not find any orders for the order and zip code combination you entered.',
          })
        } else {
          PubSub.publish(BLConstants.FLASH_MESSAGE, {
            message: BLConstants.GENERIC_FAILURE_MESSAGE,
            variant: 'danger',
          })
        }
      })
      .then(() => this.setState({ submitting: false }))
  }

  render() {
    const { error, orderReference, submitting, zipcode } = this.state
    return (
      <div className="container container-xs pbxl">
        <div className="mbxl text-center">
          <SectionHeading className="text-bold">Returns Center</SectionHeading>
          <div className="h4 text-bold">Start your return</div>
        </div>
        <Form className="mbxl" error={error} onSubmit={this.handleSubmit}>
          <Input
            value={orderReference}
            name="orderReference"
            label="Enter your order number"
            placeholder="BLS-00000"
            type="text"
            required
            onChange={(e) =>
              this.handleChange('orderReference', e.target.value)
            }
          />
          <Input
            value={zipcode}
            name="zipcode"
            label="Enter the zip code of the address where the item was delivered"
            placeholder="99999"
            type="text"
            required
            onChange={(e) => this.handleChange('zipcode', e.target.value)}
          />
          <div className="btn-group btn-group--stretch">
            <InfoButton
              block
              pill
              className="mbl"
              type="submit"
              disabled={submitting}
            >
              Next
            </InfoButton>
          </div>
        </Form>
      </div>
    )
  }
}
