import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'components/link'
import Image from 'components/image'
import { phoneNumber } from 'lib/format-strings'
import { NoAddressProvidedDisclosure } from 'registry/components/modals/ContactInfoModal'
import { pluralizeWithFirstNames } from '../../lib'
import css from './shipping-address.scss'

const ShippingAddress = ({ className, registry, alwaysShowAddress }) => {
  const [showAddress, setShowAddress] = useState(alwaysShowAddress)
  return (
    <div id="shipping-address" className={className}>
      {registry.shippingAddress && registry.shippingAddress.streetAddress1 ? (
        <div>
          <div className={css.heading}>
            <Image
              className="mbm"
              src="//res.cloudinary.com/babylist/image/upload/v1553898480/registry-icons/shipping_address.svg"
              alt="Shipping Icon"
              lazyLoad={false}
              height={55}
            />
            <div className={classNames('h6 mtn mbn text-center text-bold')}>
              Need{' '}
              {`${pluralizeWithFirstNames(registry.firstNames, "'s", 'the')}`}{' '}
              address?
            </div>
          </div>
          {showAddress ? (
            <div className={css.address}>
              <address
                id="address"
                className={classNames('lh-base mtm mbn text-center small')}
              >
                {registry.shippingAddress.name}
                <br />
                {registry.shippingAddress.streetAddress1}{' '}
                {registry.shippingAddress.streetAddress2 ? (
                  <span>, {registry.shippingAddress.streetAddress2}</span>
                ) : null}
                <br />
                {registry.shippingAddress.city},{' '}
                {registry.shippingAddress.state} {registry.shippingAddress.zip}
              </address>
              <span className="text-center small">
                {registry.shippingAddress.phone
                  ? phoneNumber(registry.shippingAddress.phone)
                  : null}
              </span>
            </div>
          ) : (
            <div className="text-center">
              <Link className="small" onClick={() => setShowAddress(true)}>
                Show Address
              </Link>
            </div>
          )}
        </div>
      ) : (
        <p className="text-center mbn">
          <Image
            className="mbm"
            src="//res.cloudinary.com/babylist/image/upload/v1553898480/registry-icons/shipping_address.svg"
            alt="Shipping Icon"
            lazyLoad={false}
            height={55}
          />
          <NoAddressProvidedDisclosure name={registry.firstNames} />
        </p>
      )}
    </div>
  )
}

ShippingAddress.propTypes = {
  alwaysShowAddress: PropTypes.bool,
  className: PropTypes.string,
  registry: PropTypes.object.isRequired,
}

ShippingAddress.defaultProps = {
  alwaysShowAddress: false,
  className: undefined,
}

ShippingAddress.contextTypes = {
  isMobile: PropTypes.bool,
}

export default ShippingAddress
