import { Component } from 'react'
import PropTypes from 'prop-types'
import { Media, Panel } from 'react-bootstrap'
import { map } from 'lodash'

import Image from 'components/image'
import Card from 'components/card'

import RegItemNote from 'shared/reg-item-note'
import { LinkButton } from 'components/buttons'
import QuantitySelect from './quantity-select'
import { PrintableDownloadButton } from './printable-download-button'

const HelpAndFavorItems = ({
  favorReservations,
  onSetReservationToRemove,
  onUpdateQuantity,
}) => (
  <Card id="help-and-favor-items" className="mbl">
    {map(favorReservations, (reservation, idx) => {
      const { regItem } = reservation
      return (
        <div key={idx} className="pal">
          <Media id={`${reservation.id}`}>
            <Media.Left className="prl">
              <Image
                width={100}
                height={100}
                src={regItem.imgUrl}
                alt={regItem.title}
              />
            </Media.Left>

            <Media.Body>
              <div className="media-heading h5 mbm">{regItem.title}</div>
              <div>
                {regItem.description ? (
                  <RegItemNote
                    description={regItem.description}
                    className="h6 mtn mbm"
                  />
                ) : null}

                {reservation.quantity + regItem.quantityNeeded > 1 ? (
                  <QuantitySelect
                    quantity={reservation.quantity}
                    quantityNeeded={regItem.quantityNeeded}
                    onUpdateQuantity={(e) =>
                      onUpdateQuantity(reservation.token, e.target.value)
                    }
                  />
                ) : null}

                {reservation.printableUrl ? (
                  <>
                    <p className="h6">
                      This gift is a favor you promise to do for the registrant!
                      We have a handy coupon you can print out and bring to the
                      shower or send to the lucky family.
                    </p>
                    <div className="pts">
                      <PrintableDownloadButton url={reservation.printableUrl} />
                    </div>
                  </>
                ) : null}

                <p className="h7">
                  <LinkButton
                    className="xsmall pan mtxl"
                    onClick={() => onSetReservationToRemove(reservation)}
                  >
                    Remove
                  </LinkButton>
                </p>
              </div>
            </Media.Body>
          </Media>
        </div>
      )
    })}
  </Card>
)

HelpAndFavorItems.propTypes = {
  onSetReservationToRemove: PropTypes.func.isRequired,
  favorReservations: PropTypes.array.isRequired,
  onUpdateQuantity: PropTypes.func.isRequired,
}

export default HelpAndFavorItems
