import PropTypes from 'prop-types'
import Media from 'components/media'
import Image from 'components/image'
import Link from 'components/link'
import { ReviewButton } from 'store/components/product-reviews'

const OrderItem = ({ imageUrl, name, productUrl, quantity, reviewSku }) => {
  return (
    <Media className="ptl pbl">
      <Media.Left aria-hidden="true" className="prl">
        <Link url={productUrl}>
          <Image width={60} height={60} src={imageUrl} alt={name} />
        </Link>
      </Media.Left>
      <Media.Body>
        <Link url={productUrl} className="media-heading h4 mbm">
          {name}
        </Link>
        <Media.List>
          <Media.ListItem className="text-uppercase h6">
            {`Qty: ${quantity}`}
          </Media.ListItem>
          <Media.ListItem className="text-uppercase h6">
            <ReviewButton sku={reviewSku} />
          </Media.ListItem>
        </Media.List>
      </Media.Body>
    </Media>
  )
}

OrderItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  productUrl: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  reviewSku: PropTypes.string.isRequired,
}

export default OrderItem
