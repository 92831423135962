import { useState } from 'react'
import PropTypes from 'prop-types'
import { BundleComponentShape } from 'cart/lib/prop-types'
import { LinkButton } from 'components/buttons'
import Collapse from 'components/collapse'
import ProductListItem from 'shared/product-list/product-list-item'

const BundleComponentsList = ({ className, products, showShippingMsg }) => {
  const [showAll, setShowAll] = useState(false)

  return products.length === 0 ? null : (
    <div className={className}>
      {showShippingMsg && (
        <div className="h7 pan">Items may ship separately.</div>
      )}
      <LinkButton
        onClick={() => {
          setShowAll(!showAll)
        }}
        className="h7 pan"
      >
        {showAll ? '- Hide ' : '+ Show '}Bundle Items
      </LinkButton>
      <Collapse expanded={showAll}>
        <div className="mts">
          <hr />
          <ul className="list-bordered mbn">
            {products.map((product) => (
              <li key={product.id}>
                <ProductListItem
                  imageUrl={product.image.thumbnailUrl}
                  name={product.name}
                />
              </li>
            ))}
          </ul>
          <hr />
        </div>
      </Collapse>
    </div>
  )
}

BundleComponentsList.defaultProps = {
  className: '',
  showShippingMsg: true,
}

BundleComponentsList.propTypes = {
  className: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(BundleComponentShape)).isRequired,
  showShippingMsg: PropTypes.bool,
}

export default BundleComponentsList
