import { Component } from 'react'
import PropTypes from 'prop-types'

import FormHeader from './form-header'
import { getQuestionText } from './questions.js'

export default class Step4 extends Component {
  static propTypes = {
    questionIds: PropTypes.array,
    onChange: PropTypes.func,
  }

  componentDidMount() {
    console.assert(
      this.props.questionIds[0] == 5,
      'first question id passed to this component was not 5!!'
    )
  }

  handleChange = (event) => {
    //TODO: There is nothing setting state, what is this supposed to do?
    this.props.onChange(this.props.questionIds[0], event.target.value)
  }

  render() {
    return (
      <div>
        <FormHeader questionText={getQuestionText(this.props.questionIds[0])} />
      </div>
    )
  }
}
