import PropTypes from 'prop-types'
import { InfoButton } from 'components/buttons'

export const PrintableDownloadButton = ({ url }) => (
  <InfoButton
    className="h7 text-brand-info"
    href={url}
    newWindow
    empty
    pill
    size="small"
  >
    Download PDF
  </InfoButton>
)

PrintableDownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
}
