import css from '../styles.scss'

export const DesktopBackGroundArch = () => (
  <>
    <svg
      className={css.desktopBGArch}
      fill="none"
      height="286"
      viewBox="0 0 106 286"
      width="106"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-12.0001 0C-77.1384 0 -130 56.3317 -130 125.774L-130 261.076C-130 274.907 -118.734 286 -105.014 286H78.8466C93.8674 286 106 273.898 106 258.915L106 125.774C106.144 56.3317 53.2825 0 -12.0001 0Z"
        fill="#DBCCD8"
        fillOpacity="0.2"
      />
    </svg>
  </>
)
