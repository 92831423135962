import {
  helloBabyBestBassinetsPath,
  helloBabyBestHighChairsPath,
  helloBabyBestStrollersPath,
  helloBabyGiftGuidesPath,
  helloBabyHospitalBagPath,
  helloBabyHowManyDiapersPath,
  helloBabyNamesPath,
  helloBabyParentingPath,
  helloBabyPregnancyWeekByWeekPath,
  helloBabyProductsAndGearPath,
  howToCreateABabyRegistryPath,
} from 'lib/urls'
import { Guide, GuideCategory } from './types'

export const DEFAULT_GUIDES: Array<Guide> = [
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1690916550/Guides_2_CreatingAReg_biqfms.png',
    title: 'The Ultimate Guide for Creating Your Baby Registry',
    link: howToCreateABabyRegistryPath,
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691186160/Guides_HowManyDiapers_uzwdc6.png',
    title: 'How Many Diapers Do I Need?',
    link: helloBabyHowManyDiapersPath,
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691186281/Guides_BestBassinets_mgtw5x.png',
    title: 'Best Bassinets',
    link: helloBabyBestBassinetsPath,
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691186160/Guides_HospitalBag_ybe8wj.png',
    title: 'What to Pack in Your Hospital Bag',
    link: helloBabyHospitalBagPath,
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691186228/Guides_3_BestStrollers_1_lgvnxc.png',
    title: 'Best Strollers',
    link: helloBabyBestStrollersPath,
  },
  {
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691186160/Guides_HighChairs_lyixg5.png',
    title: 'Best High Chairs',
    link: helloBabyBestHighChairsPath,
  },
]

export const DEFAULT_GUIDE_CATEGORIES: Array<GuideCategory> = [
  {
    link: helloBabyProductsAndGearPath,
    title: 'Products & Gear',
  },
  {
    link: helloBabyPregnancyWeekByWeekPath,
    title: 'Pregnancy Week by Week',
  },
  {
    link: helloBabyNamesPath,
    title: 'Baby Names',
  },
  {
    link: helloBabyParentingPath,
    title: 'Parenting',
  },
  {
    link: helloBabyGiftGuidesPath,
    title: 'Gift Guides',
  },
]
