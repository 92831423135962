import React from 'react'

interface BackgroundSmallProps {
  className?: string
}

const BackgroundSmall: React.FC<BackgroundSmallProps> = ({
  className,
}: BackgroundSmallProps) => (
  <svg
    className={className}
    width="390"
    height="124"
    viewBox="0 0 390 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M344.43 45.5643C347.429 48.7804 352.467 48.9566 355.683 45.9578C358.899 42.9589 359.075 37.9207 356.076 34.7046C353.078 31.4885 348.039 31.3124 344.823 34.3112C341.607 37.31 341.431 42.3482 344.43 45.5643Z"
      fill="url(#paint0_linear_1468_10300)"
    />
    <path
      d="M271 67C267.688 67 265 69.987 265 73.6692V85.6784C265 86.4118 265.573 87 266.27 87H275.619C276.383 87 277 86.3583 277 85.5638V73.6692C277.007 69.987 274.319 67 271 67Z"
      fill="#56BB6C"
    />
    <path
      d="M100.497 74C93.3838 74 87.5428 79.2995 87.003 85.9984C86.9587 86.5383 87.4097 87 87.979 87H93.7461C94.1602 87 94.5077 86.7087 94.5594 86.318C94.9143 83.4694 97.443 81.253 100.497 81.253C103.38 81.253 105.798 83.2279 106.36 85.8421C106.5 86.5098 107.107 87 107.816 87H113.147C113.643 87 114.035 86.5951 113.998 86.1191C113.524 79.3634 107.646 74 100.497 74Z"
      fill="#A0DCFF"
    />
    <path
      d="M206.497 33C199.384 33 193.543 38.2995 193.003 44.9984C192.959 45.5383 193.41 46 193.979 46H199.746C200.16 46 200.508 45.7087 200.559 45.318C200.914 42.4694 203.443 40.253 206.497 40.253C209.38 40.253 211.798 42.2279 212.36 44.8421C212.5 45.5098 213.107 46 213.816 46H219.147C219.643 46 220.035 45.5951 219.998 45.1191C219.524 38.3634 213.646 33 206.497 33Z"
      fill="url(#paint1_linear_1468_10300)"
    />
    <path
      d="M33.5721 31.7285L26.1966 44.7903C25.6433 45.7724 26.3231 47 27.4219 47H42.576C43.6906 47 44.3704 45.7397 43.7854 44.7576L35.9989 31.6958C35.4377 30.7546 34.1096 30.771 33.5721 31.7285Z"
      fill="#FFD43E"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1468_10300"
        x1="342.855"
        y1="46.977"
        x2="356.051"
        y2="34.677"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E3264" />
        <stop offset="1" stop-color="#A0DCFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1468_10300"
        x1="193"
        y1="45.9429"
        x2="216.177"
        y2="32.9163"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0795732" stop-color="#FF6D4E" />
        <stop offset="0.772297" stop-color="#FED7FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default BackgroundSmall
