import { get, has } from 'lodash'
import { parseErrors } from 'lib/api-utils'
import { errorMessage, successMessage } from 'lib/flash-message'
import { numberToCurrency } from 'lib/money'
import { cancelOrder as saveCancelOrder, saveOrderAddress } from '../api'
import {
  getOrderTotal,
  getOrderUuid,
  getSubmittingCancelOrder,
} from '../reducers'
import { ADDRESS_VALIDATION_ERROR } from '../constants'

export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const setActiveModal = (activeModal, modalProps = {}) => ({
  type: SET_ACTIVE_MODAL,
  activeModal,
  modalProps: activeModal ? modalProps : {},
}) // Clear ModalProps if clearing active modal
export const SET_ORDER = 'SET_ORDER'
export const setOrder = (order) => {
  return {
    type: SET_ORDER,
    order,
  }
}
export const SET_ORDER_CAN_CANCEL = 'SET_ORDER_CAN_CANCEL'
export const setOrderCanCancel = (canCancel) => {
  return {
    type: SET_ORDER_CAN_CANCEL,
    canCancel,
  }
}
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const setOrderStatus = (status) => {
  return {
    type: SET_ORDER_STATUS,
    status,
  }
}
export const SET_PAGE_MESSAGE = 'SET_PAGE_MESSAGE'
export const setPageMessage = (message) => {
  return {
    type: SET_PAGE_MESSAGE,
    message,
  }
}
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS'
export const setShippingAddress = (address) => {
  return {
    type: SET_SHIPPING_ADDRESS,
    address,
  }
}

export const SET_SUBMITTING_CANCEL_ORDER = 'SET_SUBMITTING_CANCEL_ORDER'
export const setSubmittingCancelOrder = (submittingCancelOrder) => {
  return {
    type: SET_SUBMITTING_CANCEL_ORDER,
    submittingCancelOrder,
  }
}

export const cancelOrder = () => {
  return (dispatch, getState) => {
    const state = getState()
    const uuid = getOrderUuid(state)
    const submitting = getSubmittingCancelOrder(state)
    const originalOrderTotal = getOrderTotal(state)

    if (!submitting) {
      dispatch(setSubmittingCancelOrder(true))
      return saveCancelOrder(uuid)
        .then((resp) => {
          dispatch(setOrder(resp.order))
          dispatch(setActiveModal(null))
          dispatch(setSubmittingCancelOrder(false))
          const amount = numberToCurrency(originalOrderTotal)
          successMessage(
            `Your order has been successfully canceled. A refund was issued to the original payment method in the amount of ${amount}. Refunds will post within 10 business days, depending on the bank.`
          )
        })
        .catch((resp) => {
          dispatch(setOrderCanCancel(false))
          dispatch(setActiveModal(null))
          dispatch(setSubmittingCancelOrder(false))
          const error = parseErrors(resp)
          errorMessage(error._error)
        })
    }
  }
}

export const saveShippingAddress = (values, opts = { hideModal: true }) => {
  return (dispatch, getState) => {
    const state = getState()
    values.orderUuid = getOrderUuid(state)
    return saveOrderAddress(values)
      .then((resp) => {
        dispatch(setShippingAddress(resp.orderAddress))
        if (opts.hideModal) {
          dispatch(setActiveModal(null))
        }
      })
      .catch((resp) => {
        // replacing backend validation messages with 'Invalid' to not clutter up the UI
        if (resp.fieldErrors) {
          const fieldErrors = {
            line1: has(resp.fieldErrors, 'address1')
              ? ADDRESS_VALIDATION_ERROR
              : '',
            line2: has(resp.fieldErrors, 'address2')
              ? ADDRESS_VALIDATION_ERROR
              : '',
            city: has(resp.fieldErrors, 'city') ? ADDRESS_VALIDATION_ERROR : '',
            state: has(resp.fieldErrors, 'state')
              ? ADDRESS_VALIDATION_ERROR
              : '',
            zip: has(resp.fieldErrors, 'zip') ? ADDRESS_VALIDATION_ERROR : '',
            base: get(resp.fieldErrors, 'base'),
          }

          // spread fieldErrors so reduxForm can attach error to correct form field
          return Promise.reject({ ...resp, ...fieldErrors })
        }
        dispatch(setActiveModal(null))
        return Promise.reject(parseErrors(resp))
      })
  }
}
