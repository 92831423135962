import PropTypes from 'prop-types'
import UnpurchasableItem from 'cart/components/unpurchasable-item'
import BLCartOrderItem from '../bl-cart-order-item'
import { CartItemShape, ReservationShape } from '../../lib/prop-types'

const OutOfStockCart = ({ items, onRemoveItem, unpurchasableReservations }) => (
  <>
    <div className="bold h5 man pan">Currently Out of Stock</div>
    <ul className="mhn list-bordered inline-block">
      {items.map((item) => (
        <li key={item.uuid}>
          <BLCartOrderItem
            isOutOfStock
            cartItem={item}
            onRemoveCartItem={onRemoveItem}
          />
        </li>
      ))}
      {unpurchasableReservations.map((reservation) => (
        <li key={reservation.token}>
          <UnpurchasableItem
            reservation={reservation}
            onSetPurchasedReservation={null}
            onSetReservationToRemove={null}
          />
        </li>
      ))}
    </ul>
  </>
)

OutOfStockCart.propTypes = {
  items: PropTypes.shape(CartItemShape).isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  unpurchasableReservations: PropTypes.arrayOf(
    PropTypes.shape(ReservationShape)
  ).isRequired,
}

// eslint-disable-next-line import/no-default-export
export default OutOfStockCart
