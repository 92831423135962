import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'components/image'
import Link from 'components/link'
import { LinkButton } from 'components/buttons'
import { Bowed } from 'components/curves'
import Fade from 'react-reveal/Fade'
import { CONFIG } from 'shared/constants'
import {
  findPath,
  helloBabyPostPath,
  loginPathWithRedirect,
  mobileAppDownloadPath,
  newRegistryPath,
} from 'lib/urls'
import css from './home.scss'
import RebrandedHome from './RebrandedHome'

const Heading = ({ pinterest, ovia }) => {
  if (pinterest) {
    return (
      <div className={css['cta__tagline--pinterest']}>
        Add anything to your <h1>baby registry</h1>.
        <span> Babylist works just like Pinterest.</span>
      </div>
    )
  }
  if (ovia) {
    return (
      <div className={css['cta__tagline--pinterest']}>
        Ovia is your favorite pregnancy app.
        <span>
          We'll be your favorite <h1>baby registry</h1>.
        </span>
      </div>
    )
  }
  return (
    <>
      Put anything onto your <h1>baby registry</h1> from any store.
    </>
  )
}

Heading.propTypes = {
  pinterest: PropTypes.bool,
  ovia: PropTypes.bool,
}

Heading.defaultProps = {
  pinterest: false,
  ovia: false,
}

const DefaultHome = ({ isPaidAcquisition, ovia, pinterest }) => {
  return (
    <div className={css.home}>
      <section className={css.hero}>
        <div />
        <div className={css.hero__cta}>
          <div className={css.cta__tagline}>
            <Heading ovia={ovia} pinterest={pinterest} />
          </div>
          <div className={css['btn-group']}>
            <a
              className={classNames(
                css['btn-group__button'],
                css['btn-group__button--primary']
              )}
              href={newRegistryPath}
            >
              Start Your Babylist{' '}
              <span className="hidden-xs hidden-md">Registry</span>
            </a>
            {!isPaidAcquisition && (
              <a
                className={classNames(
                  css['btn-group__button'],
                  css['btn-group__button--outline-primary']
                )}
                href={findPath}
              >
                Find a{' '}
                <span className="hidden-xs hidden-md hidden-lg">Babylist</span>{' '}
                Registry
              </a>
            )}
          </div>
          <div className={css.cta__login}>
            Already registered?{' '}
            <LinkButton
              className="man pan ban link-info xsmall"
              onClick={() =>
                (window.location.href = loginPathWithRedirect(
                  window.location.pathname
                ))
              }
            >
              Sign in →
            </LinkButton>
          </div>
        </div>
        <Bowed position="bottom" />
      </section>

      <section className={css.hbb}>
        <div className={css.hbb__wrapper}>
          <div className={css.hbb__cta}>
            <h3 className={css.secondaryHeader}>
              <span className={css.outlineText}>FREE</span> Hello Baby Box
            </h3>
            <p>
              Create a Babylist registry today to be eligible for a free baby
              box full of goodies for you and baby!
            </p>
            <div className={css['btn-group']}>
              <a
                className={classNames(
                  css['btn-group__button'],
                  css['btn-group__button--primary']
                )}
                href={newRegistryPath}
              >
                Get Started
              </a>
            </div>
            <small>
              Some{' '}
              <Link
                newWindow
                url="https://help.babylist.com/hc/en-us/articles/360054057353-How-do-I-get-a-Hello-Baby-Box-"
              >
                restrictions apply
              </Link>
              .
            </small>
          </div>
          <div className={css['hbb__box-image']}>
            <Image
              src="//images.babylist.com/image/upload/f_auto,q_auto,c_scale,w_800/v1634250920/hbb_y09ybf.png"
              alt="Get Your Free* Hello Baby Box"
              lazyLoad={false}
            />
          </div>
        </div>
      </section>

      <section className={css.feature}>
        <div className={css.feature__image}>
          <Fade>
            <Image
              src="//images.babylist.com/image/upload/f_auto,q_auto/v1634928008/anything-from-any-store_yzpdff.png"
              alt="Add any item from any store"
            />
          </Fade>
        </div>
        <div className={css.feature__text}>
          <Fade bottom>
            <h3>
              <span className={css.outlineText}>Anything </span>
              From Any Store
            </h3>
            <p className="mtl text-center">Add any item from any store.</p>
          </Fade>
        </div>
      </section>

      <section className={classNames(css.feature, css.feature__right)}>
        <div className={classNames(css.feature__image)}>
          <Fade>
            <Image
              src="//images.babylist.com/image/upload/f_auto,q_auto/v1634928269/registry-discount_h9tjfd.png"
              alt="15% completion discount to the Babylist shop"
            />
          </Fade>
        </div>
        <div className={css.feature__text}>
          <Fade bottom>
            <h3>
              <span className={css.outlineText}>
                {CONFIG.store.registryDiscountPctDisplay}{' '}
              </span>
              Discount
            </h3>
            <p className="mtl text-center">
              {CONFIG.store.registryDiscountPctDisplay} completion discount
              <br /> to the Babylist shop.
            </p>
          </Fade>
        </div>
      </section>

      <section className={css.feature}>
        <div
          className={classNames(
            css.feature__image,
            css['feature__image--shadow'],
            'mtl'
          )}
        >
          <Fade>
            <Image
              src="//images.babylist.com/image/upload/f_auto,q_auto/v1549044836/home-landing/add-meaningful-gifts_v3.png"
              alt="Add meaningful gifts you can't buy in stores"
            />
          </Fade>
        </div>
        <div className={css.feature__text}>
          <Fade bottom>
            <h3>
              <span className={css.outlineText}>Personal </span>and Meaningful
            </h3>
            <p className="mtl text-center">
              Add meaningful gifts
              <br /> you can't buy in stores.
            </p>
          </Fade>
        </div>
      </section>

      <section className={css.community}>
        <div className={css.community__cta}>
          <span className={css.community__title}>Congratulations!</span>
          <p>
            We're on this journey with you. Join millions of families who have
            used Babylist as their baby registry.
          </p>
          <div className="text-center">
            <a
              className={classNames(
                css['btn-group__button'],
                css['btn-group__button--outline'],
                'mtl'
              )}
              href={newRegistryPath}
            >
              <span className="xsmall text-bold">Create Your Babylist</span>
            </a>
          </div>
        </div>
        <div className={classNames(css.community__photo, css.p1)} />
        <div className={classNames(css.community__photo, css.p2)} />
        <div className={classNames(css.community__photo, css.p3)} />
        <div className={classNames(css.community__photo, css.p4)} />
        <div className={classNames(css.community__photo, css.p5)} />
        <div className={classNames(css.community__photo, css.p6)} />
        <div className={classNames(css.community__photo, css.p7)} />
        <div className={classNames(css.community__photo, css.p8)} />
        <div className={classNames(css.community__photo, css.p9)} />
        <div className={classNames(css.community__photo, css.p10)} />
        <div className={classNames(css.community__photo, css.p11)} />
        <div className={classNames(css.community__photo, css.p12)} />
        <div className={classNames(css.community__photo, css.p13)} />
        <div className={classNames(css.community__photo, css.p14)} />
        <div className={classNames(css.community__photo, css.p15)} />
        <div className={classNames(css.community__photo, css.p16)} />
        <div className={classNames(css.community__photo, css.p17)} />
        <div className={classNames(css.community__photo, css.p18)} />
        <div className={classNames(css.community__photo, css.p19)} />
        <div className={classNames(css.community__photo, css.p20)} />
      </section>

      <section className={css.download}>
        <div className={css.download__wrapper}>
          <div className={css.download__cta}>
            <h3 className={classNames(css.secondaryHeader, 'text-inverse')}>
              Use Babylist on all your devices.
            </h3>
            <p>
              Get started from the comfort of your own home. Prefer using your
              mobile device to surf the interwebs? Use our free iPhone, iPad,
              and Android apps!
            </p>
            <div className="mtl">
              <a
                className={classNames(
                  css['btn-group__button'],
                  css['btn-group__button--outline']
                )}
                href={mobileAppDownloadPath()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-apple" /> Apple Store
              </a>
              <a
                className={classNames(
                  css['btn-group__button'],
                  css['btn-group__button--outline']
                )}
                href={mobileAppDownloadPath()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-android" /> Google Play
              </a>
            </div>
          </div>
          <div className={css.download__devices}>
            <Image
              className={css.iphone}
              src="//images.babylist.com/image/upload/q_auto,f_auto,w_800/v1634334378/babylist-app-screenshot_els800.png"
              alt="Babylist is available on iPhone"
            />
          </div>
        </div>
      </section>
      <Bowed />

      <section className={css.humblebrag}>
        <div className={css.humblebrag__wrapper}>
          <div className={css.humblebrag__testimonial}>
            <div className={css.testimonial__reviewer}>
              <Image
                src="//images.babylist.com/image/upload/q_auto,f_auto,c_scale,w_174/v1469538997/lisa-m_nnxwd0.png"
                alt="Lisa M."
              />
              <div className={css.reviewer__name}>Lisa M.</div>
            </div>
            <blockquote>
              "The guides and reviews are so helpful. I had no idea what I truly
              needed and your site helped me navigate it all."
            </blockquote>
          </div>
          <div className={css.humblebrag__testimonial}>
            <div className={css.testimonial__reviewer}>
              <Image
                src="//images.babylist.com/image/upload/q_auto,f_auto,c_scale,w_174/v1469539098/caitlin-c_e9okyo.png"
                alt="Caitlin C."
              />
              <div className={css.reviewer__name}>Caitlin C.</div>
            </div>
            <blockquote>
              "Babylist is so much more than a registry, they become a part of
              your journey into parenthood."
            </blockquote>
          </div>
          <div className={css.humblebrag__testimonial}>
            <div className={css.testimonial__reviewer}>
              <Image
                src="//images.babylist.com/image/upload/q_auto,f_auto,c_scale,w_174/v1469539153/emily-b_smzns9.png"
                alt="Emily B."
              />
              <div className={css.reviewer__name}>Emily B.</div>
            </div>
            <blockquote>
              "It has been SO much easier giving everyone ONE registry to look
              at that even compares prices for you!"
            </blockquote>
          </div>
        </div>
      </section>

      {!isPaidAcquisition && (
        <>
          <Bowed inverted />
          <section className={css.guides}>
            <div className={css.guides__wrapper}>
              <div className={css.guides__image}>
                <Image
                  src="//images.babylist.com/image/upload/f_auto,q_auto,w_960/v1634258832/babylist-couple_hunoqz.png"
                  alt="Pregnancy guides"
                />
              </div>
              <div className={css.guides__text}>
                <div>
                  <a href={helloBabyPostPath('pregnancy')}>
                    <h2 className={css.secondaryHeader}>Pregnancy</h2>
                    <h4>
                      Expert-backed advice and information to help guide you
                      through your pregnancy journey.{' '}
                      <span className="no-wrap">Let us help you out.</span>
                      <i
                        className={classNames(
                          css.guides__arrow,
                          'fa fa-chevron-right'
                        )}
                      />
                    </h4>
                  </a>
                </div>
                <div className={css.guides__text__group}>
                  <a href={helloBabyPostPath('pregnancy-week-by-week')}>
                    <i
                      className={classNames(
                        css.guides__icon,
                        css['guides__icon--week-by-week']
                      )}
                    />
                    <span className={css.guides__title}>Week by Week</span>
                    <p>
                      Learn about your pregnancy symptoms, body changes and
                      ultrasounds for every week of pregnancy.
                      <i
                        className={classNames(
                          css.guides__arrow,
                          'fa fa-chevron-right'
                        )}
                      />
                    </p>
                  </a>
                  <a href={helloBabyPostPath('first-trimester')}>
                    <i
                      className={classNames(
                        css.guides__icon,
                        css['guides__icon--first-trimester']
                      )}
                    />
                    <span className={css.guides__title}>First Trimester</span>
                    <p>
                      Congratulations, you're pregnant! Now what? From what gear
                      you might want to add to your registry to what you need
                      for <i>you</i>, we're here to help.
                      <i
                        className={classNames(
                          css.guides__arrow,
                          'fa fa-chevron-right'
                        )}
                      />
                    </p>
                  </a>
                </div>
                <div className={css.guides__text__group}>
                  <a href={helloBabyPostPath('second-trimester')}>
                    <i
                      className={classNames(
                        css.guides__icon,
                        css['guides__icon--second-trimester']
                      )}
                    />
                    <span className={css.guides__title}>Second Trimester</span>
                    <p>
                      You should see an end to the extreme fatigue and morning
                      sickness that made those first weeks tough.
                      <i
                        className={classNames(
                          css.guides__arrow,
                          'fa fa-chevron-right'
                        )}
                      />
                    </p>
                  </a>
                  <a href={helloBabyPostPath('third-trimester')}>
                    <i
                      className={classNames(
                        css.guides__icon,
                        css['guides__icon--third-trimester']
                      )}
                    />
                    <span className={css.guides__title}>Third Trimester</span>
                    <p>
                      It's almost go-time! Babylist experts give you the
                      guidance to help you feel more prepared, mentally and
                      physically.
                      <i
                        className={classNames(
                          css.guides__arrow,
                          'fa fa-chevron-right'
                        )}
                      />
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <section className={css['footer-cta']}>
        <div className={css['footer-cta__create']}>
          <i />
          <h2 className={css.secondaryHeader}>Create a Babylist</h2>
          <p>Just a couple steps to start adding gifts.</p>
          <div className="text-center">
            <a
              className={classNames(
                css['btn-group__button'],
                css['btn-group__button--outline-secondary'],
                'pvm mtl'
              )}
              href={newRegistryPath}
            >
              <span className="small text-bold">Get Started</span>
            </a>
          </div>
        </div>
        <div className={css['footer-cta__find']}>
          <i />
          <h2 className={css.secondaryHeader}>Find a Babylist</h2>
          <p>Give the perfect gift to the new family.</p>
          <div className="text-center">
            <a
              className={classNames(
                css['btn-group__button'],
                css['btn-group__button--outline-secondary'],
                'pvm mtl'
              )}
              href={findPath}
            >
              <span className="small text-bold">Find A Registry</span>
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}

DefaultHome.propTypes = {
  pinterest: PropTypes.bool,
  ovia: PropTypes.bool,
  isPaidAcquisition: PropTypes.bool,
}

DefaultHome.defaultProps = {
  pinterest: false,
  ovia: false,
  isPaidAcquisition: false,
}

const Home = ({ rebrand, ...otherProps }) =>
  rebrand ? <RebrandedHome {...otherProps} /> : <DefaultHome {...otherProps} />

Home.propTypes = {
  ...DefaultHome.propTypes,
  rebrand: PropTypes.bool,
}

Home.defaultProps = {
  ...DefaultHome.defaultProps,
  rebrand: false,
}

export default Home
