import GuideSection from './sections/GuideSection'
import Paper from './components/Paper'
import FromAnyStoreSection from './sections/FromAnyStoreSection'
import HelloBabyBoxSection from './sections/HelloBabyBoxSection'
import RegistryDiscountSection from './sections/RegistryDiscountSection'
import AppDownloadSection from './sections/AppDownloadSection'
import CongratsSection from './sections/CongratsSection'
import FeaturedItemsSection from './sections/FeaturedItemsSection'

export default function AppInstallPromptApp() {
  return (
    <div>
      <Paper background="white" className="mtxl">
        <CongratsSection />
      </Paper>

      <Paper background="white" className="ptl pbxl">
        <FeaturedItemsSection />
      </Paper>

      <Paper background="lightGray" className="pbl">
        <FromAnyStoreSection />
      </Paper>

      <Paper id="hbb" className="pbxl">
        <HelloBabyBoxSection />
      </Paper>

      <Paper className="mbxl">
        <RegistryDiscountSection />
      </Paper>

      <Paper background="lightGray" className="pvxl phm">
        <GuideSection />
      </Paper>

      <Paper background="winterTwilight" className="ptxl">
        <AppDownloadSection />
      </Paper>
    </div>
  )
}
