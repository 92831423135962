import { useState } from 'react'
import EmailSignupForm from 'components/email-signup-form'
import css from './EmailSignup.scss'

export const EmailSignup = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  return (
    <div
      className={`
      ${css.EmailSignup}
      ${isSubmitted ? css.isSubmitted : ''}
    `}
    >
      <div className={css.InnerWrapper}>
        {!isSubmitted && (
          <>
            <h2 className={css.Header}>Looking for more registry advice?</h2>
            <h3 className={css.SubHeader}>
              Get the scoop on best baby gear, parenting and more — straight to
              your inbox.
            </h3>
          </>
        )}

        <EmailSignupForm
          className={css.EmailSignupForm}
          emailType={BLConstants.EMAIL_USER_NEWSLETTER}
          emailUrlsByWeek={[]}
          submitCallback={(isSuccessful: boolean) => {
            if (isSuccessful) setIsSubmitted(true)
          }}
          userSource={BLConstants.USER_SOURCE_EMAIL_NEWSLETTER_WIDGET}
        />
      </div>
    </div>
  )
}
