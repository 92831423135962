import PropTypes from 'prop-types'
import Media from 'components/media'
import Image from 'components/image'
import Link from 'components/link'

const ProductListItem = ({ imageUrl, name, storeUrl }) => {
  return (
    <Media className="ptl pbl">
      <Media.Left aria-hidden="true" className="prl">
        {storeUrl ? (
          <Link url={storeUrl}>
            <Image width={60} height={60} src={imageUrl} alt={name} />
          </Link>
        ) : (
          <Image width={60} height={60} src={imageUrl} alt={name} />
        )}
      </Media.Left>
      <Media.Body>
        {storeUrl ? (
          <Link url={storeUrl} className="media-heading h6 mbm">
            {name}
          </Link>
        ) : (
          <div className="media-heading h6 mbm">{name}</div>
        )}
      </Media.Body>
    </Media>
  )
}

ProductListItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  storeUrl: PropTypes.string,
}

ProductListItem.defaultProps = {
  storeUrl: null,
}

export default ProductListItem
