/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'components/modal'
import CancelReservationForm from 'cart/components/cancel-reservation-form'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import PurchaseInfoForm from 'cart/components/purchase-info-form'
import RegItem from './reg-item'
import { cancelReservation, markAsPurchased, setActiveModal } from '../actions'
import { getActiveModal, getActiveModalProps, getRegistry } from '../reducers'
import { CANCEL_RESERVATION, MARK_AS_PURCHASED } from '../constants'

export const ModalManager = ({
  activeModal,
  activeModalProps,
  cancelReservation,
  markAsPurchased,
  registry,
  setActiveModal,
}) => {
  const modalProps = {
    onHide: () => setActiveModal(null),
    show: !!activeModal,
    title: startCase(activeModal),
    ...activeModalProps,
  }

  let content
  switch (activeModal) {
    case CANCEL_RESERVATION:
      content = (
        <CancelReservationForm
          onHide={modalProps.onHide}
          onSubmit={cancelReservation}
          registry={registry}
          reservation={modalProps.reservation}
        />
      )
      modalProps.title = 'Could you tell us why?'
      modalProps.closeButton = true
      break
    case MARK_AS_PURCHASED:
      content = (
        <TwoColumnResponsiveLayout>
          <div>
            <RegItem
              className="mbl"
              regItem={modalProps.reservation.regItem}
              registry={registry}
            />
          </div>
          <PurchaseInfoForm
            autofillStore
            formKey={`${modalProps.reservation.id}-reserved`}
            reservation={modalProps.reservation}
            registry={registry}
            onSubmit={(data) => {
              markAsPurchased(modalProps.reservation, data)
              setActiveModal(null)
            }}
            submitBtnText="Save"
            storeName={modalProps.reservation.storeName || null}
            hideStoreInput={false}
          />
        </TwoColumnResponsiveLayout>
      )
      modalProps.title = 'Did you already purchase this gift?'
      modalProps.closeButton = true
      modalProps.size = 'large'
      break
    default:
      return null
  }

  return (
    <Modal {...modalProps}>
      <ModalBody>{content}</ModalBody>
    </Modal>
  )
}

ModalManager.propTypes = {
  activeModal: PropTypes.string,
  activeModalProps: PropTypes.object,
  cancelReservation: PropTypes.func.isRequired,
  markAsPurchased: PropTypes.func.isRequired,
  registry: PropTypes.object,
  setActiveModal: PropTypes.func.isRequired,
  title: PropTypes.string,
}

ModalManager.defaultProps = {
  activeModal: null,
  activeModalProps: {},
  title: '',
  registry: undefined,
}

const mapStateToProps = (state) => ({
  activeModal: getActiveModal(state),
  activeModalProps: getActiveModalProps(state),
  registry: getRegistry(state),
})

const ModalManagerDataFetcher = (props) => <ModalManager {...props} />

export default connect(mapStateToProps, {
  cancelReservation,
  markAsPurchased,
  setActiveModal,
})(ModalManagerDataFetcher)
