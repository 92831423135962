import {
  SET_ACTIVE_MODAL,
  SET_IS_REGISTRY_DISCOUNT_VIEW,
  SET_COLLAPSE_ALL_REG_ITEM_CATEGORIES,
  SET_IS_CONTRIBUTOR_VIEW,
  SET_IS_MOBILE,
  SET_IS_SHIPPING_ADDRESS_BUTTON_VISIBLE,
} from '../actions/ui-actions'

const initialState = {
  activeModal: null,
  isAllCategoriesCollapsed: false,
  isShippingAddressButtonVisible: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.activeModal,
        modalProps: action.modalProps,
      }

    case SET_IS_REGISTRY_DISCOUNT_VIEW:
      return { ...state, isRegistryDiscountView: action.isRegistryDiscountView }

    case SET_COLLAPSE_ALL_REG_ITEM_CATEGORIES:
      return {
        ...state,
        isAllCategoriesCollapsed: action.isAllCategoriesCollapsed,
      }

    case SET_IS_CONTRIBUTOR_VIEW:
      return { ...state, isContributorView: action.isContributorView }

    case SET_IS_MOBILE:
      return { ...state, isMobile: action.isMobile }

    case SET_IS_SHIPPING_ADDRESS_BUTTON_VISIBLE:
      return {
        ...state,
        isShippingAddressButtonVisible: action.isShippingAddressButtonVisible,
      }

    default:
      return state
  }
}

export const getIsShippingAddressButtonVisible = (state) =>
  state.isShippingAddressButtonVisible
export const getActiveModal = (state) => state.activeModal
export const getIsRegistryDiscountView = (state) => state.isRegistryDiscountView
export const getActiveModalProps = (state) => state.modalProps
export const getIsAllCategoriesCollapsed = (state) =>
  state.isAllCategoriesCollapsed
export const getIsContributorView = (state) => state.isContributorView
export const getIsMobile = (state) => state.isMobile
