import PropTypes from 'prop-types'
import { NumberSelect } from 'components/forms'

const QuantitySelect = ({
  quantity,
  quantityNeeded,
  onUpdateQuantity,
  purchaseQuantityLimit,
  showPurchaseQuantityLimit,
}) => (
  <div className="change-quantity-wrapper mbm">
    <NumberSelect
      size="small"
      help={
        showPurchaseQuantityLimit
          ? `Limit ${purchaseQuantityLimit} per customer`
          : quantityNeeded > 0 && `Still needs: ${quantity + quantityNeeded}`
      }
      min={1}
      max={
        showPurchaseQuantityLimit
          ? Math.min(purchaseQuantityLimit, quantity + quantityNeeded)
          : quantity + quantityNeeded
      }
      value={quantity}
      optionPrefix="QTY: "
      onChange={onUpdateQuantity}
    />
  </div>
)

QuantitySelect.propTypes = {
  quantity: PropTypes.number.isRequired,
  quantityNeeded: PropTypes.number.isRequired,
  onUpdateQuantity: PropTypes.func.isRequired,
  purchaseQuantityLimit: PropTypes.number.isRequired,
  showPurchaseQuantityLimit: PropTypes.bool.isRequired,
}

export default QuantitySelect
