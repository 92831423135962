import css from './styles.scss'

interface ProgressRingDecorationProps {
  className?: string
}

export const ProgressRingDecoration = ({
  className,
}: ProgressRingDecorationProps) => (
  <div className={className}>
    <span className={css.progressRingDecoration}>
      <svg
        className={css.progressStarBurst}
        fill="none"
        viewBox="0 0 174 174"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#starburst)">
          {/* Starburst */}
          <path
            d="M82.4227 2.20226L69.6697 24.0174L47.1994 11.9092C44.2556 10.3235 40.6807 12.4446 40.6711 15.7926L40.5343 41.0626L15.0204 41.8117C11.6781 41.9104 9.64276 45.5347 11.3084 48.439L23.8245 70.3912L2.1033 83.797C-0.741853 85.5535 -0.692374 89.71 2.20226 91.3923L24.018 104.146L11.9099 126.616C10.3242 129.559 12.4453 133.134 15.7933 133.144L41.0624 133.281L41.8115 158.795C41.9102 162.137 45.5345 164.172 48.4388 162.507L70.3911 149.99L83.7972 171.712C85.5538 174.557 89.7102 174.508 91.3926 171.613L104.146 149.797L126.616 161.905C129.56 163.491 133.135 161.37 133.145 158.022L133.281 132.752L158.795 132.003C162.137 131.905 164.173 128.28 162.507 125.376L149.991 103.424L171.712 90.018C174.557 88.2614 174.508 84.105 171.613 82.4227L149.797 69.6694L161.906 47.199C163.491 44.2552 161.37 40.6804 158.022 40.6707L132.753 40.5339L132.004 15.0201C131.905 11.6779 128.281 9.6425 125.376 11.3081L103.424 23.8244L90.0181 2.1033C88.2615 -0.741853 84.1051 -0.692374 82.4227 2.20226Z"
            fill="white"
          />
          {/* Progress Track */}
          <g clipPath="url(#track)">
            <path
              d="M128.038 102.954C130.59 96.2664 131.481 89.0582 130.633 81.9497C129.785 74.8413 127.225 68.0459 123.171 62.1489C119.118 56.2519 113.694 51.4302 107.366 48.0991C101.038 44.7681 93.9967 43.0276 86.8479 43.0276C79.6992 43.0276 72.6576 44.7681 66.3299 48.0991C60.0022 51.4302 54.578 56.2519 50.5247 62.1489C46.4714 68.0459 43.9105 74.8413 43.0627 81.9497C42.2149 89.0582 43.1055 96.2664 45.6578 102.954"
              stroke="#E9E8E8"
              strokeMiterlimit="10"
              strokeWidth="6"
            />
          </g>
          {/* Progress */}
          <path
            d="M109.659 41.1427C102.742 37.7593 95.1392 36.0096 87.4383 36.0287C79.1944 36.0316 71.0745 38.0361 63.7772 41.8696C56.4798 45.703 50.2233 51.2509 45.5457 58.0359C40.8681 64.821 37.9095 72.6402 36.9243 80.821C35.9391 89.0017 36.9569 97.2993 39.89 105L52.3865 100.207C49.7124 93.1877 49.2099 85.527 50.9441 78.2185C52.6784 70.91 56.5694 64.2909 62.1126 59.2193C67.6559 54.1477 74.5957 50.8576 82.0323 49.7755C89.4689 48.6935 97.0592 49.8695 103.819 53.151C105.418 53.9268 107.259 54.0361 108.938 53.4549C110.617 52.8737 111.997 51.6496 112.773 50.0519C113.549 48.4541 113.658 46.6137 113.077 44.9353C112.495 43.257 111.271 41.8783 109.672 41.1025L109.659 41.1427Z"
            fill="url(#gradient)"
          />
        </g>
        {/* Arrow */}
        <path
          d="M24.569 38.0024C25.1199 38.0407 25.5355 38.5185 25.4971 39.0694C25.4587 39.6204 24.981 40.0359 24.43 39.9975L24.569 38.0024ZM8.26718 0.207259C8.70495 -0.129445 9.33279 -0.0475081 9.6695 0.390269L15.1564 7.52427C15.4931 7.96204 15.4112 8.58988 14.9734 8.92659C14.5356 9.26329 13.9078 9.18136 13.5711 8.74358L8.69382 2.40225L2.35249 7.27949C1.91471 7.6162 1.28687 7.53426 0.950169 7.09648C0.613467 6.65871 0.695402 6.03086 1.13318 5.69416L8.26718 0.207259ZM24.43 39.9975C18.7049 39.5988 13.3123 38.1129 10.0326 32.3047C6.83018 26.6335 5.77501 17.0402 7.88524 0.870517L9.86842 1.12933C7.76562 17.2421 8.91519 26.2584 11.7741 31.3213C14.5556 36.2472 19.081 37.6201 24.569 38.0024L24.43 39.9975Z"
          fill="#A884A2"
          transform="translate(78,105)"
        />

        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="gradient"
            x1="36.5596"
            x2="125.391"
            y1="36.0286"
            y2="39.6878"
          >
            <stop stopColor="#6E3264" />
            <stop offset="0.70917" stopColor="#FF7AAB" />
            <stop offset="1" stopColor="#FF7A5E" />
          </linearGradient>
          <clipPath id="starburst">
            <rect fill="white" height="174" width="174" />
          </clipPath>
          <clipPath id="track">
            <rect
              fill="white"
              height="65"
              transform="translate(39.4077 40)"
              width="95"
            />
          </clipPath>
        </defs>
      </svg>
      <div className={css.progressText}>60%</div>
      <div className={css.progressLabel}>Essentials</div>
      <div className={css.arrowCallout}>Track Your Progress</div>
    </span>
  </div>
)
