import classNames from 'classnames'
import css from './CategoryHeading.scss'
import { HeadingProps } from '../types'

const CategoryHeading = ({ children, className, ...rest }: HeadingProps) => (
  <h3 className={classNames(css.CategoryHeading, className)} {...rest}>
    {children}
  </h3>
)

export default CategoryHeading
