import { ProgressRing } from 'baby-ui/compounds/ProgressRing'
import { SyncChecklistSection } from 'pages/BabyRegistryChecklist/components/SyncChecklistSection/SyncChecklistSection'
import { useRegistryChecklistCategories } from '../../../../registry/modules/checklist/api/hooks'
import css from './style.scss'

const ArrowCallout = () => (
  <div className={css.arrowCallout}>
    <div>What you really need</div>
    <svg
      fill="none"
      height="48"
      viewBox="0 0 23 48"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50973 1.00892C8.51465 0.456656 8.07095 0.00496566 7.51868 3.97844e-05C6.96642 -0.00488614 6.51473 0.438818 6.50981 0.991081L8.50973 1.00892ZM22.0931 40.2505C22.453 39.8316 22.4052 39.2003 21.9863 38.8404L15.1597 32.9755C14.7407 32.6156 14.1094 32.6634 13.7495 33.0823C13.3896 33.5012 13.4374 34.1326 13.8563 34.4925L19.9245 39.7057L14.7112 45.7739C14.3513 46.1928 14.3992 46.8241 14.8181 47.184C15.237 47.5439 15.8683 47.4961 16.2282 47.0772L22.0931 40.2505ZM6.50981 0.991081C6.49832 2.27871 6.20245 3.88587 5.72198 5.75751C5.24362 7.62092 4.60134 9.67714 3.92589 11.8599C2.58694 16.1869 1.1177 21.0025 0.731706 25.4066C0.345815 29.8095 1.0215 34.049 4.25008 36.9759C7.4447 39.8721 12.8834 41.2422 21.4102 40.596L21.2591 38.6017C12.9105 39.2344 8.17079 37.8308 5.59338 35.4942C3.04992 33.1883 2.35992 29.7359 2.72407 25.5812C3.08811 21.4276 4.48463 16.8199 5.83651 12.4511C6.50648 10.286 7.1659 8.17629 7.65916 6.25481C8.15032 4.34154 8.49603 2.54487 8.50973 1.00892L6.50981 0.991081Z"
        fill="#A884A2"
      />
    </svg>
  </div>
)

export const HeaderSection = () => {
  const { isLoading, data } = useRegistryChecklistCategories(false)
  const {
    completedCategoriesCount,
    completedEssentialsCategoryCount,
    totalCategoriesCount,
    totalEssentialsCategoryCount,
  } = data

  let percentEssentialsCompleted = 0
  let percentEverythingCompleted = 0

  if (!isLoading && data) {
    percentEssentialsCompleted = Math.round(
      (completedEssentialsCategoryCount / totalEssentialsCategoryCount) * 100
    )
    percentEverythingCompleted = Math.round(
      (completedCategoriesCount / totalCategoriesCount) * 100
    )
  }

  return (
    <section className="flex col align-center pal">
      <SyncChecklistSection acqexPage={false} />
      <p className="small text-center man pvl phxl-md lh-lg">
        Create your baby registry with ease using our interactive checklist. Add
        items from any site and it will track your progress so you have
        everything you need.
      </p>
      <p className="small text-center text-brand-info">
        Here's a preview of your checklist <br />
        <span aria-hidden className="fa fa-chevron-down pts" />
      </p>
      <div className={css.progressContainer}>
        {!isLoading && (
          <>
            <ArrowCallout />
            <div>
              <ProgressRing
                label="Essentials"
                progress={percentEssentialsCompleted}
              />
            </div>
            <div className="mlxl">
              <ProgressRing
                label="Everything"
                progress={percentEverythingCompleted}
                variant="secondary"
              />
            </div>
          </>
        )}
      </div>
    </section>
  )
}
