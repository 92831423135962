import Router from 'shared/universal-router'
import { Route, Routes } from 'react-router-dom-v5-compat'
import * as blUrls from 'lib/urls'

import Container from './container'
import Instructions from './instructions'
import HowItWorks from './how-it-works'

interface ShareExtensionProps {
  isSupportedDevice: boolean
  path: string
}

const ShareExtension = ({ isSupportedDevice, path }: ShareExtensionProps) => (
  <>
    {/* @ts-ignore */}
    <Router useV6Router location={{ pathname: path }}>
      <Routes>
        <Route
          element={<Container showDesktopAlert={!isSupportedDevice} />}
          path={blUrls.shareExtensionPath}
        >
          <Route index element={<Instructions />} />
          <Route element={<HowItWorks />} path="how-it-works" />
        </Route>
      </Routes>
    </Router>
  </>
)

export default ShareExtension
