import { connect } from 'react-redux'
import { StyledSubHeading } from 'components/headings'
import OrderAddressForm from '../forms/order-address-form'
import { getShippingAddress } from '../reducers'

const OrderAddress = ({
  editable,
  city,
  className,
  country,
  name,
  state,
  line1,
  line2,
  zip,
}) => {
  if (editable) {
    return (
      <div className={className}>
        <StyledSubHeading>Shipping Address</StyledSubHeading>
        <OrderAddressForm />
      </div>
    )
  }
  return (
    <div className={className}>
      <StyledSubHeading>Shipping to</StyledSubHeading>
      <div>{name}</div>
      <div>{line1}</div>
      {line2 && <div>{line2}</div>}
      <div>{`${city}, ${state} ${zip}`}</div>
      <div>{country}</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const address = getShippingAddress(state)
  return {
    editable: address.editable,
    city: address.city,
    country: address.country,
    name: address.name,
    phone: address.phone,
    state: address.state,
    line1: address.line1,
    line2: address.line2,
    zip: address.zip,
  }
}

export default connect(mapStateToProps)(OrderAddress)
