import RegistryViewPurchaseInfoModalComponent from 'registry/components/modals/registry-view-purchase-info-modal'
import AddToCartModalComponent from 'cart/components/add-to-cart-modal'
import ReservePurchasedGiftModalComponent from 'cart/components/reserve-purchased-gift-modal'
import GiftMessageModalComponent from 'shared/gift-message-modal'
import GiftTrackerModalComponent from 'registry/components/modals/gift-tracker-modal'
import QuickViewModalComponent from 'store/components/quick-view-modal'
import ThankYouAddressModalComponent from '../components/modals/ThankYouAddressModal'
import { modalConnect } from './modal-connect'

import CrowdfundReservationModalComponent from '../components/modals/crowdfund-reservation-modal'
import FundThisGiftModalComponent from '../components/modals/fund-this-gift-modal'
import EnableGroupGiftModalComponent from '../components/modals/enable-group-gift-modal'
import MobileFiltersModalComponent from '../components/modals/mobile-filters-modal'
import GroupGiftRedemptionModalComponent from '../components/modals/group-gift-redemption-modal'
import RegItemEditModalComponent from '../components/modals/reg-item-edit-modal'
import RegItemDeleteModalComponent from '../components/modals/reg-item-delete-modal'
import RedirectModalComponent from '../components/modals/redirect-modal'
import OwnerReserveModalComponent from '../components/modals/owner-reserve-modal'
import OwnerMarkAsPurchasedModalComponent from '../components/modals/owner-mark-as-purchased-modal'
import BookmarkletUsageInstructionsComponent from '../components/modals/bookmarklet-usage-instructions-modal'
import BookmarkletInstallInstructionsComponent from '../components/modals/bookmarklet-install-instructions-modal'
import { RecoverReservationModalComponent } from '../components/modals/recover-reservation-modal'
import InaccuracyReportModalComponent from '../components/modals/inaccuracy-report-modal'
import ContactInfoModalComponent from '../components/modals/ContactInfoModal'
import UnreserveModalComponent from '../components/modals/unreserve-modal'
export { UnhideAddressModal } from '../components/modals/UnhideAddressModal'

export const ADD_TO_CART_MODAL = 'ADD_TO_CART_MODAL'
export const AddToCartModal = modalConnect(
  AddToCartModalComponent,
  ADD_TO_CART_MODAL
)

export const RESERVE_PURCHASED_GIFT_MODAL = 'RESERVE_PURCHASED_GIFT_MODAL'
export const ReservePurchasedGiftModal = modalConnect(
  ReservePurchasedGiftModalComponent,
  RESERVE_PURCHASED_GIFT_MODAL
)

export const UNRESERVE_MODAL = 'UNRESERVE_MODAL'
export const UnreserveModal = modalConnect(
  UnreserveModalComponent,
  UNRESERVE_MODAL
)

export { DismissableAboutShopRegistryModal } from '../components/modals/about-shop-registry-modal'

export const CROWDFUND_RESERVATION_MODAL = 'CROWDFUND_RESERVATION_MODAL'
export const CrowdfundReservationModal = modalConnect(
  CrowdfundReservationModalComponent,
  CROWDFUND_RESERVATION_MODAL
)

export const FUND_THIS_GIFT_MODAL = 'FUND_THIS_GIFT_MODAL'
export const FundThisGiftModal = modalConnect(
  FundThisGiftModalComponent,
  FUND_THIS_GIFT_MODAL
)

export const ENABLE_GROUP_GIFT_MODAL = 'ENABLE_GROUP_GIFT_MODAL'
export const EnableGroupGiftModal = modalConnect(
  EnableGroupGiftModalComponent,
  ENABLE_GROUP_GIFT_MODAL
)

export const MOBILE_FILTERS_MODAL = 'MOBILE_FILTERS_MODAL'
export const MobileFiltersModal = modalConnect(
  MobileFiltersModalComponent,
  MOBILE_FILTERS_MODAL
)

export const EDIT_REG_ITEM_MODAL = 'EDIT_REG_ITEM_MODAL'
export const RegItemEditModal = modalConnect(
  RegItemEditModalComponent,
  EDIT_REG_ITEM_MODAL
)

export const DELETE_REG_ITEM_MODAL = 'DELETE_REG_ITEM_MODAL'
export const RegItemDeleteModal = modalConnect(
  RegItemDeleteModalComponent,
  DELETE_REG_ITEM_MODAL
)

export const GIFT_MESSAGE_MODAL = 'GIFT_MESSAGE_MODAL'
export const GiftMessageModal = modalConnect(
  GiftMessageModalComponent,
  GIFT_MESSAGE_MODAL
)

export const GROUP_GIFT_REDEMPTION_MODAL = 'GROUP_GIFT_REDEMPTION_MODAL'
export const GroupGiftRedemptionModal = modalConnect(
  GroupGiftRedemptionModalComponent,
  GROUP_GIFT_REDEMPTION_MODAL
)

export const INACCURACY_REPORT_MODAL = 'INACCURACY_REPORT_MODAL'
export const InaccuracyReportModal = modalConnect(
  InaccuracyReportModalComponent,
  INACCURACY_REPORT_MODAL
)

export const REDIRECT_MODAL = 'REDIRECT_MODAL'
export const RedirectModal = modalConnect(
  RedirectModalComponent,
  REDIRECT_MODAL
)

export const CONTACT_INFO_MODAL = 'CONTACT_INFO_MODAL'
export const ContactInfoModal = modalConnect(
  ContactInfoModalComponent,
  CONTACT_INFO_MODAL
)

export const OWNER_RESERVE_MODAL = 'OWNER_RESERVE_MODAL'
export const OwnerReserveModal = modalConnect(
  OwnerReserveModalComponent,
  OWNER_RESERVE_MODAL
)

export const OWNER_MARK_AS_PURCHASED_MODAL = 'OWNER_MARK_AS_PURCHASED_MODAL'
export const OwnerMarkAsPurchasedModal = modalConnect(
  OwnerMarkAsPurchasedModalComponent,
  OWNER_MARK_AS_PURCHASED_MODAL
)

export const BOOKMARKLET_USAGE_INSTRUCTIONS_MODAL =
  'BOOKMARKLET_USAGE_INSTRUCTIONS_MODAL'
export const BookmarkletUsageModal = modalConnect(
  BookmarkletUsageInstructionsComponent,
  BOOKMARKLET_USAGE_INSTRUCTIONS_MODAL
)

export const BOOKMARKLET_INSTALL_INSTRUCTIONS_MODAL =
  'BOOKMARKLET_INSTALL_INSTRUCTIONS_MODAL'
export const BookmarkletInstallModal = modalConnect(
  BookmarkletInstallInstructionsComponent,
  BOOKMARKLET_INSTALL_INSTRUCTIONS_MODAL
)

export const RECOVER_RESERVATIONS_BY_EMAIL = 'RECOVER_RESERVATIONS_BY_EMAIL'
export const RecoverReservationModal = modalConnect(
  RecoverReservationModalComponent,
  RECOVER_RESERVATIONS_BY_EMAIL
)

export const REGISTRY_VIEW_PURCHASE_INFO_MODAL =
  'REGISTRY_VIEW_PURCHASE_INFO_MODAL'
export const RegistryViewPurchaseInfoModal = modalConnect(
  RegistryViewPurchaseInfoModalComponent,
  REGISTRY_VIEW_PURCHASE_INFO_MODAL
)

export const GIFT_TRACKER_MODAL = 'GIFT_TRACKER_MODAL'
export const GiftTrackerModal = modalConnect(
  GiftTrackerModalComponent,
  GIFT_TRACKER_MODAL
)

export const QUICK_VIEW_MODAL = 'QUICK_VIEW_MODAL'
export const QuickViewModal = modalConnect(
  QuickViewModalComponent,
  QUICK_VIEW_MODAL
)

export const THANK_YOU_ADDRESS_MODAL = 'THANK_YOU_ADDRESS_MODAL'
export const ThankYouAddressModal = modalConnect(
  ThankYouAddressModalComponent,
  THANK_YOU_ADDRESS_MODAL
)
