export const CANCEL_ORDER = 'CANCEL_ORDER'

export const ORDER_STATUS = {
  HOLD: 'hold',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  OPEN: 'open',
  SHIPPED: 'shipped',
}

export const ORDER_HOLDS = {
  ADDRESS: 'address',
}

export const ADDRESS_VALIDATION_ERROR = 'Invalid'
