import { InfoButton as DefaultInfoButton } from 'components/buttons'
import classNames from 'classnames'
import { ButtonProps } from '../types'
import css from '../Buttons.scss'

const InfoButton = ({ children, className, ...otherProps }: ButtonProps) => (
  <DefaultInfoButton
    className={classNames(css.Button, className)}
    {...otherProps}
  >
    {children}
  </DefaultInfoButton>
)

export default InfoButton
