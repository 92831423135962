/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Question } from '../../../../store/components/FrequentlyAskedQuestions/FrequentlyAskedQuestions.types'

export const FAQSection = ({ questions }: { questions: Array<Question> }) => (
  <section className="paxl">
    <h2 className="h5 text-bold text-brand-info">Frequently Asked Questions</h2>
    {questions.map((question, index: number) => (
      <div>
        <h3
          className={classNames('small text-bold', {
            mtl: index === 0,
            mtxl: index !== 0,
          })}
        >
          {question.question}
        </h3>
        <div dangerouslySetInnerHTML={{ __html: question.answer }} />
      </div>
    ))}
  </section>
)

FAQSection.defaultProps = {
  questions: [],
}

FAQSection.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
}
