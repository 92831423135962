import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import Link from 'components/link'
import {
  storePath,
  editArrivalDateHelpPath,
  bestBabyProductsPath,
} from 'lib/urls'
import css from './registry-discount.scss'
import { SectionHeading, StyledSubHeading } from '../../components/headings'

const RegistryDiscount = () => (
  <div>
    <div className={classNames('mbxl', css.heroWrapper)}>
      <div className={css.heroImage} />
    </div>
    <div className="container">
      <Row>
        <Col xs={12} md={8} mdOffset={2}>
          <SectionHeading className="text-center mtn mbl">
            {`Babylist Shop Discount`}
          </SectionHeading>

          <p style={{ marginBottom: '70px' }} className="text-center">
            {`Save on the things you want (and need!) for baby's arrival with ${BLConstants.CONFIG.store.registryDiscountPctDisplay} off* in the `}
            <Link url={storePath}>Babylist Shop!</Link>
            {` Thoughtfully curated with over 8 thousand expert-selected and real-parent-tested items, the Babylist Shop features the best baby gear and exclusive items you'll only find here.`}
          </p>

          <StyledSubHeading>
            <strong>Where can I find my discount code?</strong>
          </StyledSubHeading>
          <p className="mbxl">
            {`A link to redeem your ${BLConstants.CONFIG.store.registryDiscountPctDisplay} registry discount will be displayed on your registry 60 days before your baby's expected arrival date. Please note, that your registry must be active for at least 30 days before you are eligible.`}
          </p>

          <StyledSubHeading>
            <strong>How do I add my baby's arrival date to my registry?</strong>
          </StyledSubHeading>
          <p className="mbxl">
            {`To add your baby's arrival date go to Settings> Registry Info. For detailed instructions, visit our `}
            <Link url={editArrivalDateHelpPath}>Help Center.</Link>
          </p>

          <StyledSubHeading>
            <strong>When can I use my discount?</strong>
          </StyledSubHeading>
          <p className="mbxl">
            {`You can use your discount 60 days before and up to 6 months after your baby's expected arrival date. Codes expire after six months but don't worry, we'll notify you to remind you to use your discount. `}
          </p>
          <p className="mbxl">
            {`Need help deciding what to spend your discount code on? Start by shopping our list of the `}
            <Link url={bestBabyProductsPath}>
              best baby products of the year.
            </Link>
          </p>

          <StyledSubHeading>
            <strong>Does my discount apply to any purchase?</strong>
          </StyledSubHeading>
          <p className="mbxl">
            {`The registry discount only applies to items sold in the Babylist Shop and can not be used on items added to your registry from other online stores. `}
          </p>
          <p className="mbxl">
            {`This one-time-use discount code can only and does not apply to gift cards, or shipping and handling fees. Discount is not valid on the following brands and products: Cradlewise, Guava Family, Lovevery (subscription play kits), Million Dollar Baby (Carter's by daVinci and daVinci), Mockingbird, Nuna, Nurture&, Pampers, UPPAbaby, Diapers, and Wipes.`}
          </p>

          <StyledSubHeading>
            <strong>
              I'm buying a gift for someone– can I get $
              {BLConstants.CONFIG.store.registryDiscountPctDisplay} off?
            </strong>
          </StyledSubHeading>
          <p className="mbxl">
            {`The Babylist Shop discount is exclusively for registry owners only. But did you know?: Babylist gives you the freedom to purchase your gift from anywhere you'd like. If you find an item for a better price at a different retailer, feel free to buy it there instead– just be sure to mark the gift as purchased on the registry!`}
          </p>

          <div className="pvxl" />

          <hr className="mvxl" />

          <div className="h7 mbm">
            <strong>Terms and Conditions:</strong>
          </div>
          <ol className={css.termsList}>
            <li>
              The Registry Discount begins 60 days prior to the Expected Arrival
              Date you entered in your Baby Registry and expires 180 days after
              the Expected Arrival Date. Babylist reserves the right to cancel
              the Registry Discount at any time.
            </li>
            <li>
              The Registry Discount may be used only one time for one order and
              applies only to items sold by Babylist.com. It cannot be used on
              items at other stores or websites.
            </li>
            <li>
              Items sold and fulfilled by Babylist.com can only be shipped to
              addresses within the US.
            </li>
            <li>
              Babylist reserves the right to exclude certain products from sales
              and promotions, including but not limited to: Babylist Gift Cards
              and the following brands and products: Cradlewise, Guava Family,
              Lovevery (subscription play kits), Million Dollar Baby (Carter's
              by daVinci and daVinci), Mockingbird, Nuna, Nurture&, Pampers,
              UPPAbaby, Diapers, and Wipes. Exclusions may change at any time,
              without notice.
            </li>
            <li>
              The Registry Discount may only be redeemed through the Babylist
              account of the primary registrant.
            </li>
            <li>
              If you return any of the items purchased using the Registry
              Discount, we will subtract the value of the Registry Discount from
              your return credit.
            </li>
            <li>Void where prohibited.</li>
            <li>This promotion may not be combined with other offers.</li>
            <li>
              Your registry must be active for at least 30 days before you are
              eligible.
            </li>
            <li>
              The registry discount cannot be combined with any other offer or
              applied to a previous purchase and is not redeemable for cash.
              Additional exclusions may apply.
            </li>
          </ol>

          <div className="pvxl" />
        </Col>
      </Row>
    </div>
  </div>
)

export default RegistryDiscount
