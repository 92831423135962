import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import Image from 'components/image'

import css from './pregnancy-email-layout.scss'
import { SectionHeading } from '../../components/headings'

const PregnancyEmailLayout = ({
  children,
  heroImage,
  heroImageMobile,
  heroText,
  heroTextClassName,
}) => {
  const [resizing, setResizing] = useState(false)

  useEffect(() => {
    let timer
    const setTimedResizing = () => {
      if (timer) {
        clearTimeout(timer)
      }

      setResizing(true)
      timer = setTimeout(() => {
        setResizing(false)
      }, 1000)
    }
    window.addEventListener('resize', setTimedResizing)

    return () => window.removeEventListener('resize', setTimedResizing)
  }, [])

  return (
    <div className="container-fluid">
      <Row className={css.flexContainer}>
        <Col className={css.content} lg={6} md={6} sm={6}>
          <>
            <Image
              className={css.logo}
              lazyLoad={false}
              role="presentation"
              src="//images.babylist.com/image/upload/f_auto,q_auto/v1547580354/Babylist_logo_horizontal_oawglq.svg"
            />
            {heroText ? (
              <SectionHeading
                className={classNames(css.heroText, heroTextClassName)}
              >
                {heroText}
              </SectionHeading>
            ) : null}
          </>
          {children}
        </Col>
        <Col
          className={classNames(
            css.heroImage,
            resizing ? css.noTransition : null
          )}
          lg={6}
          md={6}
          sm={6}
          style={{
            '--heroImage': `url('${heroImage}')`,
            '--heroImageMobile': `url('${heroImageMobile}')`,
          }}
        />
      </Row>
    </div>
  )
}

export default PregnancyEmailLayout
