import fetch from 'lib/fetch'
import {
  apiV3BabyRegistryChecklistCategoriesPath,
  apiV3RegistriesChecklistCategoriesPath,
} from 'lib/urls'

export const fetchBabyRegistryChecklistCategories = () =>
  fetch(apiV3BabyRegistryChecklistCategoriesPath, {
    method: 'GET',
  })

export const fetchRegistryChecklistCategories = (
  registryId: number | false
) => {
  // [User is logged in] Fetch the categories for the registry with completion statuses.
  if (registryId) {
    return fetch(apiV3RegistriesChecklistCategoriesPath(registryId), {
      method: 'GET',
    })
  }
  // [User is logged out] Fetch just the categories to display the checklist.
  return fetch(apiV3BabyRegistryChecklistCategoriesPath, {
    method: 'GET',
  })
}

export const updateRegistryChecklistCategoryChecked = (
  registryId: number | false,
  categoryId: number,
  isChecked: boolean
) => {
  // [User is logged in] Update the category completion status.
  if (registryId) {
    fetch(apiV3RegistriesChecklistCategoriesPath(registryId, categoryId), {
      body: JSON.stringify({ isChecked }),
      method: 'PATCH',
    })
  }
  // [User is logged out] Don't make any api calls, just return a resolved promise.
  return new Promise((resolve) => {
    resolve(true)
  })
}

export const resetRegistryChecklistCategories = (registryId: number) =>
  fetch(apiV3RegistriesChecklistCategoriesPath(registryId), {
    method: 'DELETE',
  })
