import * as React from 'react'
import Image from 'components/image'
import css from './styles.scss'

export interface PageHeaderProps {
  content: React.ReactNode
}

export default function PageHeader({ content }: PageHeaderProps) {
  return (
    <div className="text-center mvl">
      <Image
        src="https://images.babylist.com/image/upload/v1648661923/Mark_ys1inz.svg"
        alt="Babylist Logo"
        className="mvm"
      />
      <h1 className={css.headerText}>{content}</h1>
    </div>
  )
}
