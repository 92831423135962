import React from 'react'

interface LibraStickerProps {
  className?: string
}

const LibraSticker: React.FC<LibraStickerProps> = ({
  className,
}: LibraStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9494 47.6875H36.3502C37.3484 47.6875 38.1007 48.605 37.8988 49.5811C37.0951 53.4602 34.3831 61.9559 25.9902 62.0587C17.2779 62.1614 13.6631 53.7281 12.4374 49.7353C12.1254 48.7187 12.8851 47.6875 13.9494 47.6875Z"
      fill="#A0DCFF"
    />
    <path
      d="M87.1305 47.6875H109.531C110.53 47.6875 111.282 48.605 111.08 49.5811C110.276 53.4602 107.564 61.9559 99.1713 62.0587C90.4627 62.1651 86.8443 53.7281 85.6222 49.7353C85.3103 48.7187 86.0699 47.6875 87.1342 47.6875H87.1305Z"
      fill="#A0DCFF"
    />
    <path
      d="M16.236 40.795C16.3974 40.2592 16.6103 39.6977 16.68 39.1216C16.6984 38.9674 16.7865 38.7472 16.8562 38.5784C17.0764 38.0683 17.2856 37.5509 17.4617 37.004C17.4874 36.9233 17.5278 36.8389 17.5424 36.7618C17.7002 35.9802 18.0379 35.2645 18.2654 34.5085C18.4269 33.9764 18.6361 33.459 18.7351 32.9048C18.7902 32.5966 18.9186 32.2516 19.0287 31.9286C19.359 30.9598 19.6967 29.991 20.0343 29.0221C20.1407 28.7212 20.2435 28.4643 20.4196 28.479C20.5187 28.4863 20.6692 28.2588 20.7903 28.2881C21.1095 28.3579 21.2086 28.846 20.9958 29.6313C20.9004 29.9836 20.7793 30.3359 20.6728 30.6882C20.4673 31.3635 20.3242 32.0497 20.0159 32.6993C19.9058 32.9305 19.8324 33.2314 19.7554 33.503C19.5388 34.2737 19.2563 35.0113 18.981 35.7563C18.893 35.9948 18.7939 36.237 18.7315 36.4756C18.57 37.1068 18.3828 37.727 18.1773 38.3472C18.0819 38.6445 17.9938 38.9307 17.8764 39.2243C17.7553 39.5289 17.6782 39.8739 17.5828 40.1968C17.3223 41.0592 17.0984 41.929 16.7791 42.7767C16.5553 43.808 16.1075 44.8245 15.9204 45.8668C15.8617 46.1897 15.7552 46.564 15.6011 46.9127C15.2341 47.7274 15.146 47.7971 14.9185 47.3677C14.8634 47.265 14.8231 47.1402 14.7864 47.0007C14.7387 46.8062 14.746 46.5934 14.8488 46.2337C14.9919 45.7273 15.0763 45.2135 15.1644 44.7108C15.2378 44.3071 15.2928 43.9181 15.4543 43.4997C15.513 43.3492 15.5424 43.1731 15.5791 43.0116C15.7405 42.274 15.9461 41.54 16.2323 40.784L16.236 40.795Z"
      fill="#6F3264"
    />
    <path
      d="M33.2161 41.1582C33.0363 40.6298 32.8822 40.0462 32.6033 39.5398C32.5299 39.404 32.4711 39.1728 32.4308 38.993C32.3133 38.4499 32.1849 37.9067 32.0051 37.3599C31.9794 37.2792 31.9647 37.1874 31.9317 37.1177C31.605 36.3911 31.4656 35.6094 31.2124 34.8644C31.0326 34.3396 30.9041 33.7965 30.6619 33.2864C30.5298 33.0038 30.4307 32.6478 30.3316 32.3212C30.0344 31.3413 29.7444 30.3578 29.4582 29.3743C29.3701 29.0697 29.304 28.7981 29.4582 28.7101C29.5426 28.6587 29.5316 28.3871 29.649 28.3394C29.9499 28.211 30.3133 28.5486 30.5995 29.3119C30.728 29.6532 30.8344 30.0129 30.9518 30.3615C31.1793 31.0294 31.4583 31.6716 31.5904 32.3799C31.6381 32.6331 31.7518 32.9194 31.8473 33.1836C32.1188 33.9359 32.317 34.7029 32.5262 35.4663C32.5922 35.7121 32.6546 35.9654 32.7427 36.1966C32.9776 36.8021 33.1868 37.4186 33.3813 38.0425C33.4767 38.3398 33.5721 38.626 33.6492 38.9306C33.7262 39.2499 33.8657 39.5728 33.9758 39.8958C34.2657 40.7472 34.5886 41.5876 34.8198 42.4647C35.2382 43.4335 35.4621 44.5198 35.9208 45.4776C36.0639 45.7749 36.1924 46.1419 36.2658 46.5125C36.4419 47.3896 36.4089 47.4961 35.9758 47.2795C35.8731 47.2282 35.763 47.1511 35.6566 47.0593C35.5024 46.9309 35.3887 46.7511 35.2602 46.3988C35.0841 45.9033 34.8529 45.4373 34.6327 44.9749C34.4565 44.6042 34.2767 44.2556 34.1629 43.8225C34.1226 43.6647 34.0455 43.5069 33.9794 43.3528C33.6785 42.6592 33.4216 41.9436 33.2161 41.1619V41.1582Z"
      fill="#6F3264"
    />
    <path
      d="M89.1818 40.795C89.3432 40.2592 89.5561 39.6977 89.6258 39.1216C89.6442 38.9674 89.7323 38.7472 89.802 38.5784C90.0222 38.0683 90.2314 37.5509 90.4075 37.004C90.4332 36.9233 90.4736 36.8389 90.4882 36.7618C90.646 35.9802 90.9837 35.2645 91.2112 34.5085C91.3727 33.9764 91.5819 33.459 91.6809 32.9048C91.736 32.5966 91.8644 32.2516 91.9745 31.9286C92.3048 30.9598 92.6425 29.991 92.9801 29.0221C93.0865 28.7212 93.1893 28.4643 93.3654 28.479C93.4645 28.4863 93.615 28.2588 93.7361 28.2881C94.0553 28.3579 94.1544 28.846 93.9416 29.6313C93.8462 29.9836 93.7251 30.3359 93.6186 30.6882C93.4131 31.3635 93.27 32.0497 92.9617 32.6993C92.8516 32.9305 92.7782 33.2314 92.7012 33.503C92.4846 34.2737 92.2021 35.0113 91.9268 35.7563C91.8388 35.9948 91.7397 36.237 91.6773 36.4756C91.5158 37.1068 91.3286 37.727 91.1231 38.3472C91.0277 38.6445 90.9396 38.9307 90.8222 39.2243C90.7011 39.5289 90.624 39.8739 90.5286 40.1968C90.2681 41.0592 90.0442 41.929 89.7249 42.7767C89.5011 43.808 89.0533 44.8245 88.8662 45.8668C88.8074 46.1897 88.701 46.564 88.5469 46.9127C88.1799 47.7274 88.0918 47.7971 87.8643 47.3677C87.8093 47.265 87.7689 47.1402 87.7322 47.0007C87.6845 46.8062 87.6918 46.5934 87.7946 46.2337C87.9377 45.7273 88.0221 45.2135 88.1102 44.7108C88.1836 44.3071 88.2386 43.9181 88.4001 43.4997C88.4588 43.3492 88.4882 43.1731 88.5249 43.0116C88.6863 42.274 88.8919 41.54 89.1781 40.784L89.1818 40.795Z"
      fill="#6F3264"
    />
    <path
      d="M106.162 41.1582C105.982 40.6298 105.828 40.0462 105.549 39.5398C105.475 39.404 105.417 39.1728 105.376 38.993C105.259 38.4499 105.13 37.9067 104.951 37.3599C104.925 37.2792 104.91 37.1874 104.877 37.1177C104.551 36.3911 104.411 35.6094 104.158 34.8644C103.978 34.3396 103.85 33.7965 103.607 33.2864C103.475 33.0038 103.376 32.6478 103.277 32.3212C102.98 31.3413 102.69 30.3578 102.404 29.3743C102.316 29.0697 102.25 28.7981 102.404 28.7101C102.488 28.6587 102.477 28.3871 102.595 28.3394C102.896 28.211 103.259 28.5486 103.545 29.3119C103.674 29.6532 103.78 30.0129 103.897 30.3615C104.125 31.0294 104.404 31.6716 104.536 32.3799C104.584 32.6331 104.697 32.9194 104.793 33.1836C105.064 33.9359 105.263 34.7029 105.472 35.4663C105.538 35.7121 105.6 35.9654 105.688 36.1966C105.923 36.8021 106.132 37.4186 106.327 38.0425C106.422 38.3398 106.518 38.626 106.595 38.9306C106.672 39.2499 106.811 39.5728 106.921 39.8958C107.211 40.7472 107.534 41.5876 107.765 42.4647C108.184 43.4335 108.408 44.5198 108.866 45.4776C109.009 45.7749 109.138 46.1419 109.211 46.5125C109.387 47.3896 109.354 47.4961 108.921 47.2795C108.819 47.2282 108.709 47.1511 108.602 47.0593C108.448 46.9309 108.334 46.7511 108.206 46.3988C108.03 45.9033 107.795 45.4373 107.578 44.9749C107.402 44.6042 107.222 44.2556 107.108 43.8225C107.068 43.6647 106.991 43.5069 106.925 43.3528C106.624 42.6592 106.367 41.9436 106.162 41.1619V41.1582Z"
      fill="#6F3264"
    />
    <path
      d="M62.3329 28.7651C56.6226 28.2697 49.2939 100.056 49.2939 100.056H75.06C75.06 100.056 68.9167 29.3376 62.3293 28.7651H62.3329Z"
      fill="#A0DCFF"
    />
    <path
      d="M62.1789 36.34C67.5195 36.34 71.8489 32.0106 71.8489 26.67C71.8489 21.3293 67.5195 16.9999 62.1789 16.9999C56.8382 16.9999 52.5088 21.3293 52.5088 26.67C52.5088 32.0106 56.8382 36.34 62.1789 36.34Z"
      fill="#A0DCFF"
    />
    <path
      d="M106.863 24.0532H17.6378C16.3447 24.0532 15.2964 25.1007 15.2964 26.3928C15.2964 27.6848 16.3447 28.7323 17.6378 28.7323H106.863C108.156 28.7323 109.204 27.6848 109.204 26.3928C109.204 25.1007 108.156 24.0532 106.863 24.0532Z"
      fill="#A0DCFF"
    />
    <path
      d="M49.2978 100.056C49.2978 100.056 45.9875 100.192 45.9875 101.656C45.9875 103.12 47.7858 103.069 49.6611 103.069H75.985C76.807 103.069 79.2805 102.676 79.2805 101.583C79.2805 100.357 75.7905 100.067 75.0675 100.056C73.6656 100.034 49.3014 100.056 49.3014 100.056H49.2978Z"
      fill="#A0DCFF"
    />
    <path
      d="M44.8389 102.982C44.8389 102.982 40.4241 103.165 40.4241 105.117C40.4241 107.07 42.8242 107 45.3233 107H80.4439C81.5412 107 84.8404 106.475 84.8404 105.015C84.8404 103.378 80.1833 102.996 79.2181 102.982C77.3502 102.952 44.8389 102.982 44.8389 102.982Z"
      fill="#A0DCFF"
    />
    <path
      d="M58.4099 102.728C59.0962 102.717 59.8301 102.75 60.5274 102.64C60.7146 102.611 61.0045 102.625 61.2284 102.64C61.9073 102.691 62.5862 102.736 63.2908 102.732C63.3936 102.732 63.5073 102.743 63.6028 102.736C64.5716 102.647 65.5331 102.75 66.4983 102.736C67.1772 102.728 67.8598 102.769 68.5424 102.695C68.9204 102.655 69.3718 102.669 69.7901 102.677L73.5517 102.747C73.9407 102.754 74.2784 102.776 74.3261 102.945C74.3518 103.04 74.6747 103.114 74.6894 103.238C74.7298 103.561 74.1976 103.8 73.1994 103.84C72.7517 103.859 72.2967 103.848 71.8453 103.855C70.9828 103.866 70.1278 103.936 69.2543 103.844C68.9424 103.811 68.5644 103.833 68.2194 103.844C67.2396 103.873 66.2744 103.829 65.3056 103.796C64.9973 103.785 64.6744 103.763 64.3734 103.778C63.5807 103.818 62.7844 103.825 61.9843 103.822C61.6027 103.822 61.2357 103.825 60.8504 103.803C60.4504 103.781 60.021 103.814 59.6063 103.822C58.5053 103.836 57.4044 103.888 56.2997 103.844C55.0153 103.947 53.6611 103.829 52.3767 103.972C51.9766 104.016 51.5032 104.027 51.0408 103.987C49.9546 103.888 49.8408 103.825 50.2555 103.481C50.3546 103.4 50.4867 103.319 50.6335 103.246C50.8427 103.139 51.0922 103.084 51.5509 103.073C52.1932 103.055 52.8244 102.978 53.4446 102.912C53.94 102.857 54.4171 102.794 54.9639 102.816C55.1621 102.824 55.3749 102.802 55.5768 102.787C56.4979 102.714 57.4301 102.688 58.4173 102.728H58.4099Z"
      fill="#6F3264"
    />
    <path
      d="M57.8924 99.3184C58.4282 99.3074 59.0007 99.3404 59.5475 99.2303C59.6943 99.201 59.9181 99.2156 60.0943 99.2303C60.6264 99.2817 61.1549 99.3257 61.7054 99.3221C61.7861 99.3221 61.8742 99.3331 61.9476 99.3257C62.7036 99.2377 63.4596 99.3404 64.2119 99.3257C64.744 99.3147 65.2761 99.3588 65.8119 99.2854C66.1092 99.245 66.4615 99.2597 66.7844 99.267L69.724 99.3368C70.0286 99.3441 70.2928 99.3661 70.3295 99.5349C70.3515 99.6303 70.6011 99.7037 70.6121 99.8285C70.6415 100.151 70.2268 100.39 69.4488 100.43C69.1001 100.449 68.7441 100.438 68.3918 100.445C67.7166 100.456 67.0487 100.526 66.3661 100.434C66.1239 100.401 65.8266 100.423 65.5587 100.434C64.7917 100.463 64.0357 100.419 63.2797 100.386C63.0375 100.375 62.788 100.353 62.5531 100.368C61.9329 100.408 61.3127 100.416 60.6851 100.412C60.3879 100.412 60.098 100.416 59.797 100.394C59.4851 100.372 59.1475 100.405 58.8245 100.412C57.9621 100.427 57.1034 100.478 56.2373 100.434C55.2317 100.537 54.1748 100.419 53.1693 100.562C52.8573 100.607 52.4867 100.618 52.1234 100.577C51.2756 100.478 51.1839 100.416 51.5105 100.071C51.5876 99.99 51.6903 99.9093 51.8041 99.8359C51.9656 99.7294 52.1637 99.6744 52.5234 99.6634C53.0261 99.645 53.5216 99.568 54.006 99.5019C54.395 99.4468 54.7657 99.3845 55.195 99.4065C55.3492 99.4138 55.518 99.3918 55.6758 99.3771C56.3951 99.3037 57.1254 99.278 57.896 99.3184H57.8924Z"
      fill="#6F3264"
    />
    <path
      d="M59.2071 24.6394C59.2915 24.6321 59.3832 24.6578 59.4677 24.5624C59.4897 24.5367 59.5264 24.5514 59.5521 24.5624C59.6365 24.6064 59.7209 24.6431 59.8053 24.6431C59.8163 24.6431 59.831 24.6541 59.842 24.6431C59.9594 24.566 60.0805 24.6541 60.198 24.6431C60.2824 24.6358 60.3668 24.6725 60.4512 24.6064C60.4989 24.5697 60.5539 24.5844 60.6053 24.5917C60.7594 24.6064 60.9136 24.6284 61.0677 24.6504C61.1154 24.6578 61.1558 24.6761 61.1631 24.8229C61.1631 24.9073 61.2072 24.9697 61.2072 25.0762C61.2108 25.3551 61.1484 25.5606 61.0237 25.5973C60.9686 25.6119 60.9136 25.6046 60.8585 25.6083C60.7521 25.6193 60.6457 25.678 60.5392 25.6009C60.5026 25.5716 60.4548 25.5899 60.4108 25.6009C60.2897 25.6266 60.1723 25.5863 60.0512 25.5606C60.0145 25.5532 59.9741 25.5349 59.9374 25.5459C59.8383 25.5789 59.7429 25.5863 59.6438 25.5863C59.5961 25.5863 59.5521 25.5863 59.5044 25.5716C59.4566 25.5532 59.4016 25.5789 59.3502 25.5863C59.2144 25.6009 59.0786 25.645 58.9429 25.6046C58.7851 25.6927 58.6199 25.5936 58.4621 25.7147C58.4144 25.7514 58.3557 25.7624 58.297 25.7257C58.1649 25.6376 58.1502 25.5863 58.2016 25.289C58.2126 25.2193 58.2309 25.1495 58.2493 25.0872C58.2749 24.9954 58.3043 24.9477 58.363 24.9367C58.4438 24.922 58.5208 24.856 58.5942 24.7972C58.6566 24.7495 58.7153 24.6982 58.7814 24.7165C58.8071 24.7238 58.8328 24.7055 58.8585 24.6908C58.9722 24.6284 59.086 24.6064 59.2071 24.6394Z"
      fill="#6F3264"
    />
    <path
      d="M60.9496 24.0124C60.9643 23.928 61.012 23.8472 60.9423 23.7371C60.9239 23.7078 60.9423 23.6784 60.968 23.6527C61.034 23.583 61.0927 23.5133 61.1148 23.4289C61.1148 23.4178 61.1331 23.4068 61.1294 23.3922C61.0891 23.2564 61.2065 23.1646 61.2285 23.0472C61.2432 22.9628 61.3056 22.8931 61.2689 22.794C61.2505 22.7389 61.2799 22.6875 61.2982 22.6398C61.3606 22.4967 61.4267 22.3536 61.4964 22.2141C61.5184 22.1701 61.5478 22.1371 61.6909 22.1774C61.7717 22.1994 61.8414 22.1811 61.9441 22.2141C62.212 22.2985 62.3882 22.4233 62.3809 22.5481C62.3809 22.6031 62.3552 22.6545 62.3442 22.7059C62.3221 22.8086 62.3515 22.9224 62.2451 22.9995C62.2084 23.0252 62.212 23.0765 62.2084 23.1169C62.1974 23.238 62.1276 23.3371 62.0689 23.4399C62.0506 23.4729 62.0212 23.5059 62.0249 23.5426C62.0322 23.6417 62.0139 23.7371 61.9845 23.8289C61.9698 23.8729 61.9625 23.9169 61.9331 23.9573C61.9038 23.9977 61.9148 24.0564 61.9074 24.1041C61.8854 24.2362 61.8964 24.3757 61.8267 24.4931C61.8744 24.6619 61.7386 24.7977 61.8267 24.9739C61.8524 25.0289 61.8487 25.0876 61.801 25.1317C61.6872 25.2381 61.6322 25.2418 61.3533 25.1317C61.2872 25.106 61.2248 25.0729 61.1661 25.0436C61.0817 24.9996 61.0414 24.9592 61.045 24.9005C61.045 24.8197 61.001 24.728 60.9606 24.6436C60.9313 24.5738 60.8909 24.5041 60.9239 24.4417C60.9349 24.4197 60.9239 24.3904 60.9166 24.361C60.8835 24.2362 60.8909 24.1188 60.9533 24.0087L60.9496 24.0124Z"
      fill="#6F3264"
    />
    <path
      d="M62.9097 22.9486C62.9537 23.0183 62.9684 23.1101 63.0932 23.1431C63.1262 23.1504 63.1299 23.1908 63.1299 23.2202C63.1262 23.3156 63.1299 23.4037 63.1702 23.4807C63.1776 23.4917 63.1702 23.5101 63.1849 23.5174C63.306 23.5872 63.2803 23.7303 63.3464 23.8294C63.3904 23.8991 63.3978 23.9872 63.4932 24.0312C63.5446 24.0532 63.5592 24.1083 63.5813 24.156C63.6363 24.2954 63.695 24.4386 63.7537 24.5817C63.7721 24.6257 63.7758 24.6698 63.651 24.7505C63.5813 24.7945 63.5446 24.8606 63.4565 24.9156C63.2179 25.0588 63.0051 25.1065 62.9133 25.0147C62.873 24.9744 62.851 24.9193 62.8179 24.8716C62.7555 24.7835 62.6491 24.7175 62.6675 24.5817C62.6748 24.534 62.6344 24.501 62.6051 24.4679C62.5243 24.3725 62.5023 24.2477 62.4693 24.1266C62.4583 24.0863 62.4583 24.0459 62.4289 24.0165C62.3519 23.9431 62.3005 23.8587 62.2564 23.767C62.2344 23.7229 62.2124 23.6862 62.2051 23.6349C62.2014 23.5798 62.15 23.5468 62.1243 23.5028C62.0509 23.3853 61.9518 23.2789 61.9262 23.1394C61.7794 23.0293 61.7977 22.8348 61.6179 22.7394C61.5628 22.7101 61.5298 22.6587 61.5371 22.5926C61.5592 22.4312 61.6032 22.3981 61.8931 22.3247C61.9629 22.3064 62.0326 22.2954 62.0986 22.2844C62.1904 22.2697 62.2491 22.277 62.2821 22.3247C62.3298 22.3908 62.4216 22.4312 62.506 22.4789C62.572 22.5156 62.6454 22.5449 62.6565 22.611C62.6601 22.6367 62.6895 22.6513 62.7115 22.666C62.8143 22.7394 62.884 22.8312 62.906 22.9559L62.9097 22.9486Z"
      fill="#6F3264"
    />
    <path
      d="M64.4842 24.4557C64.5723 24.4484 64.664 24.4741 64.7558 24.3823C64.7815 24.3566 64.8182 24.3713 64.8438 24.3823C64.9319 24.43 65.0163 24.4667 65.1081 24.4631C65.1228 24.4631 65.1374 24.4741 65.1484 24.4667C65.2732 24.3897 65.3943 24.4814 65.5191 24.4704C65.6072 24.4631 65.6916 24.4998 65.7833 24.4374C65.831 24.4043 65.8898 24.419 65.9448 24.4227C66.1063 24.441 66.2641 24.4631 66.4256 24.4851C66.4769 24.4924 66.5173 24.5108 66.5173 24.6576C66.5173 24.742 66.554 24.8043 66.554 24.9108C66.5467 25.1897 66.4733 25.3952 66.3448 25.4282C66.2898 25.4429 66.231 25.4356 66.176 25.4392C66.0659 25.4466 65.9595 25.509 65.8531 25.4282C65.8164 25.3989 65.765 25.4172 65.7246 25.4282C65.5998 25.4539 65.4824 25.4135 65.3613 25.3842C65.3209 25.3732 65.2842 25.3548 65.2439 25.3695C65.1448 25.4025 65.0457 25.4099 64.9429 25.4062C64.8952 25.4062 64.8475 25.4062 64.7998 25.3915C64.7484 25.3732 64.697 25.3989 64.642 25.4062C64.5025 25.4209 64.3668 25.4613 64.2273 25.4209C64.0658 25.5053 63.897 25.4062 63.7392 25.5273C63.6915 25.564 63.6328 25.575 63.5704 25.5383C63.4309 25.4502 63.4163 25.3952 63.4603 25.1016C63.4713 25.0319 63.486 24.9622 63.5043 24.8998C63.53 24.808 63.5594 24.7603 63.6181 24.7493C63.6988 24.7346 63.7796 24.6686 63.8567 24.6098C63.919 24.5658 63.9778 24.5108 64.0512 24.5291C64.0768 24.5365 64.1025 24.5181 64.1282 24.5034C64.2457 24.441 64.3631 24.419 64.4915 24.4557H64.4842Z"
      fill="#6F3264"
    />
    <path
      d="M66.1352 26.0489C66.0801 26.1113 65.9957 26.148 65.9957 26.2728C65.9957 26.3058 65.9627 26.3205 65.9333 26.3242C65.8416 26.3425 65.7572 26.3682 65.6985 26.4269C65.6911 26.4343 65.6728 26.4343 65.6691 26.449C65.6361 26.5811 65.4929 26.5921 65.4159 26.6765C65.3645 26.7352 65.2801 26.7646 65.2654 26.8637C65.2581 26.9187 65.2104 26.9444 65.17 26.9738C65.0562 27.0582 64.9388 27.1462 64.8177 27.2343C64.781 27.26 64.7406 27.2783 64.6342 27.1793C64.5718 27.1242 64.5021 27.1059 64.425 27.0325C64.2195 26.8416 64.1204 26.6508 64.1828 26.5407C64.2122 26.4893 64.2562 26.4563 64.2929 26.4159C64.3626 26.3352 64.3957 26.2178 64.5278 26.2031C64.5755 26.1994 64.5975 26.1517 64.6195 26.1187C64.6892 26.0196 64.803 25.9682 64.9094 25.9095C64.9425 25.8911 64.9828 25.8801 65.0012 25.8434C65.0489 25.7554 65.1149 25.6856 65.1883 25.6232C65.225 25.5939 65.2544 25.5609 65.3021 25.5425C65.3498 25.5242 65.3718 25.4691 65.4049 25.4324C65.4929 25.3333 65.5627 25.2159 65.6874 25.1572C65.7425 24.992 65.9297 24.959 65.9553 24.7682C65.9627 24.7094 65.9957 24.6654 66.0618 24.6544C66.2122 24.6324 66.2599 24.6617 66.4288 24.9076C66.4691 24.9663 66.5058 25.0287 66.5352 25.0874C66.5792 25.1719 66.5902 25.2269 66.5572 25.2709C66.5095 25.3333 66.4985 25.4361 66.4838 25.5278C66.4691 25.6012 66.4655 25.682 66.4031 25.7113C66.381 25.7223 66.3737 25.7554 66.3664 25.781C66.326 25.9022 66.2563 25.9939 66.1462 26.0453L66.1352 26.0489Z"
      fill="#6F3264"
    />
    <path
      d="M59.3464 25.5754C59.4161 25.6305 59.4712 25.7149 59.5996 25.7039C59.6327 25.7039 59.6547 25.7369 59.6657 25.7663C59.6987 25.858 59.7428 25.9461 59.8125 26.0048C59.8235 26.0121 59.8272 26.0305 59.8418 26.0342C59.985 26.0599 60.0217 26.2103 60.1244 26.28C60.1978 26.3314 60.2419 26.4158 60.3483 26.4232C60.407 26.4268 60.4437 26.4745 60.4841 26.5149C60.5978 26.6324 60.7116 26.7498 60.8254 26.8672C60.8621 26.9039 60.8841 26.9443 60.7997 27.0654C60.752 27.1315 60.7446 27.2085 60.6822 27.2929C60.5134 27.5168 60.3299 27.6342 60.2052 27.5792C60.1501 27.5535 60.1061 27.5094 60.0547 27.4764C59.9593 27.4104 59.8308 27.3847 59.7905 27.2489C59.7758 27.2012 59.7244 27.1828 59.684 27.1571C59.5666 27.0911 59.4932 26.9773 59.4125 26.8709C59.3868 26.8379 59.3648 26.7938 59.3281 26.7791C59.2253 26.7351 59.1409 26.6691 59.0602 26.5957C59.0235 26.559 58.9831 26.5296 58.9574 26.4819C58.9317 26.4305 58.8693 26.4122 58.8253 26.3791C58.7079 26.2874 58.5684 26.2177 58.4877 26.0892C58.3005 26.0305 58.2418 25.8323 58.0326 25.7993C57.9665 25.7883 57.9152 25.7516 57.8968 25.6782C57.8564 25.513 57.8821 25.4653 58.1317 25.2965C58.1904 25.2562 58.2528 25.2195 58.3115 25.1901C58.3959 25.1461 58.4546 25.1351 58.5023 25.1718C58.5721 25.2195 58.6785 25.2305 58.7776 25.2451C58.8547 25.2562 58.9391 25.2598 58.9758 25.3259C58.9904 25.3479 59.0235 25.3552 59.0528 25.3626C59.1813 25.3993 59.284 25.4653 59.3538 25.5791L59.3464 25.5754Z"
      fill="#6F3264"
    />
    <path
      d="M60.9461 28.6254C60.935 28.7171 60.8873 28.8089 60.9571 28.919C60.9754 28.9483 60.9571 28.985 60.9314 29.0107C60.8653 29.0878 60.8103 29.1685 60.7883 29.2639C60.7883 29.2786 60.7699 29.2896 60.7736 29.3043C60.8176 29.4474 60.7038 29.5538 60.6818 29.6823C60.6671 29.774 60.6121 29.8548 60.6525 29.9575C60.6745 30.0162 60.6488 30.0713 60.6304 30.1263C60.5754 30.2878 60.5167 30.4456 60.458 30.6034C60.4396 30.6511 60.4139 30.6915 60.2671 30.6621C60.1864 30.6438 60.1167 30.6731 60.0102 30.6475C59.7387 30.5851 59.5552 30.464 59.5515 30.3282C59.5515 30.2658 59.5699 30.2107 59.5809 30.1484C59.5992 30.0346 59.5625 29.9098 59.6689 29.8144C59.7056 29.7814 59.6983 29.7263 59.702 29.6823C59.7056 29.5502 59.7754 29.4327 59.8304 29.3153C59.8488 29.2786 59.8745 29.2382 59.8708 29.1979C59.8634 29.0878 59.8781 28.9813 59.9038 28.8786C59.9148 28.8309 59.9222 28.7795 59.9515 28.7355C59.9809 28.6878 59.9662 28.6254 59.9735 28.5703C59.9919 28.4235 59.9846 28.2731 60.0543 28.1373C60.0066 27.9501 60.146 27.7997 60.0653 27.6051C60.0396 27.5464 60.0433 27.4804 60.0947 27.429C60.2121 27.3079 60.2671 27.3042 60.546 27.418C60.6121 27.4473 60.6745 27.4767 60.7332 27.5097C60.8139 27.5574 60.8543 27.5978 60.8506 27.6602C60.847 27.7483 60.891 27.8437 60.9277 27.9391C60.9571 28.0125 60.9974 28.0896 60.9607 28.1556C60.9461 28.1813 60.9607 28.2107 60.9681 28.24C61.0011 28.3758 60.9938 28.5006 60.9314 28.6217L60.9461 28.6254Z"
      fill="#6F3264"
    />
    <path
      d="M60.4803 29.6021C60.5427 29.5287 60.6307 29.4773 60.6344 29.3452C60.6344 29.3085 60.6711 29.2902 60.7041 29.2792C60.8032 29.2461 60.895 29.2058 60.961 29.136C60.972 29.125 60.9904 29.1214 60.994 29.1067C61.0307 28.9599 61.1885 28.9269 61.273 28.8204C61.3317 28.747 61.4234 28.703 61.4418 28.5929C61.4528 28.5305 61.5042 28.4938 61.5482 28.4571C61.6766 28.3434 61.8088 28.2296 61.9409 28.1158C61.9812 28.0791 62.0253 28.0571 62.139 28.1525C62.2051 28.2039 62.2785 28.2149 62.3592 28.2846C62.5721 28.4681 62.6748 28.659 62.6051 28.7874C62.5721 28.8425 62.5244 28.8865 62.484 28.9379C62.4106 29.0333 62.3702 29.1654 62.2308 29.2021C62.1794 29.2131 62.1574 29.2682 62.1317 29.3085C62.0546 29.426 61.9335 29.4994 61.8161 29.5764C61.7794 29.6021 61.7354 29.6205 61.7133 29.6608C61.6583 29.7636 61.5886 29.8517 61.5042 29.9287C61.4638 29.9654 61.4308 30.0058 61.3794 30.0315C61.3243 30.0572 61.3023 30.1196 61.2656 30.1673C61.1665 30.2884 61.0821 30.4278 60.9427 30.5086C60.8729 30.6994 60.6638 30.7581 60.616 30.971C60.6014 31.037 60.5573 31.0921 60.4839 31.1068C60.3115 31.1471 60.2637 31.1178 60.1059 30.8609C60.0692 30.7985 60.0362 30.7361 60.0069 30.6774C59.9665 30.5893 59.9591 30.5306 59.9995 30.4792C60.0546 30.4058 60.0729 30.2994 60.0949 30.1966C60.1133 30.1159 60.1243 30.0315 60.1903 29.9948C60.216 29.9801 60.2234 29.9471 60.2344 29.9177C60.2821 29.7856 60.3592 29.6792 60.4803 29.6095V29.6021Z"
      fill="#6F3264"
    />
    <path
      d="M63.5884 28.6872C63.6581 28.7459 63.7095 28.8303 63.8416 28.8266C63.8783 28.8266 63.8966 28.8633 63.904 28.8927C63.937 28.9881 63.9737 29.0762 64.0397 29.1386C64.0508 29.1496 64.0508 29.1679 64.0654 29.1679C64.2086 29.201 64.2379 29.3551 64.337 29.4358C64.4067 29.4909 64.4434 29.5826 64.5535 29.5973C64.6122 29.6083 64.6453 29.6597 64.682 29.7001C64.7884 29.8285 64.8911 29.9569 64.9939 30.0891C65.0269 30.1294 65.0453 30.1735 64.9499 30.2872C64.8948 30.3496 64.8838 30.4267 64.8104 30.5038C64.6233 30.7129 64.4324 30.812 64.315 30.746C64.2636 30.7166 64.2232 30.6689 64.1792 30.6285C64.0911 30.5551 63.9663 30.5184 63.937 30.3826C63.926 30.3313 63.8783 30.3129 63.8379 30.2836C63.7278 30.2102 63.6618 30.0927 63.5884 29.9826C63.5663 29.9459 63.548 29.9056 63.5113 29.8872C63.4122 29.8358 63.3315 29.7698 63.2581 29.6927C63.2214 29.656 63.1847 29.6267 63.159 29.5753C63.1333 29.5239 63.0746 29.5056 63.0305 29.4689C62.9168 29.3771 62.781 29.3037 62.7039 29.1716C62.5204 29.1129 62.4617 28.9184 62.2525 28.8817C62.1865 28.8707 62.1351 28.834 62.1167 28.7642C62.0764 28.6028 62.1021 28.5551 62.3553 28.3862C62.414 28.3459 62.4764 28.3092 62.5351 28.2798C62.6195 28.2358 62.6782 28.2248 62.7296 28.2615C62.7993 28.3092 62.9094 28.3202 63.0085 28.3385C63.0893 28.3532 63.17 28.3569 63.2104 28.4229C63.225 28.445 63.2581 28.4523 63.2874 28.4596C63.4195 28.5 63.5223 28.5697 63.5884 28.6835V28.6872Z"
      fill="#6F3264"
    />
    <path
      d="M64.1649 29.4434C64.1465 29.3443 64.1575 29.2379 64.0548 29.1462C64.0254 29.1205 64.0364 29.0801 64.0474 29.0434C64.0804 28.9407 64.1061 28.8379 64.0915 28.7388C64.0915 28.7241 64.0988 28.7058 64.0915 28.6948C63.9997 28.5627 64.0731 28.4159 64.0437 28.2764C64.0254 28.181 64.0474 28.0782 63.974 27.9865C63.9336 27.9351 63.941 27.8691 63.9373 27.8103C63.9337 27.6268 63.93 27.447 63.9336 27.2635C63.9336 27.2085 63.9447 27.1571 64.0915 27.1351C64.1722 27.1241 64.2309 27.069 64.3373 27.058C64.6162 27.025 64.8291 27.08 64.8805 27.2195C64.9025 27.2819 64.9025 27.3479 64.9172 27.414C64.9429 27.5388 65.0163 27.6525 64.9539 27.7883C64.9318 27.836 64.9539 27.8874 64.9686 27.9388C65.0089 28.0782 64.9869 28.2214 64.9759 28.3608C64.9722 28.4049 64.9612 28.4526 64.9759 28.4966C65.0236 28.6067 65.0456 28.7205 65.0566 28.8342C65.0603 28.8893 65.0713 28.9407 65.0603 28.9994C65.0493 29.0581 65.0823 29.1168 65.097 29.1755C65.13 29.3333 65.1924 29.4875 65.1704 29.6489C65.2805 29.8251 65.2034 30.0306 65.3465 30.1994C65.3906 30.2545 65.4089 30.3205 65.3796 30.3903C65.3135 30.5554 65.2621 30.5774 64.9612 30.5517C64.8878 30.5444 64.8181 30.5334 64.752 30.5187C64.6566 30.5003 64.6052 30.4673 64.5869 30.4013C64.5612 30.3095 64.4841 30.2251 64.4144 30.1444C64.3594 30.0783 64.297 30.0159 64.308 29.9352C64.3116 29.9058 64.2896 29.8765 64.2713 29.8508C64.1942 29.726 64.1538 29.5939 64.1722 29.4471L64.1649 29.4434Z"
      fill="#6F3264"
    />
    <path
      d="M65.3972 21.5914C65.4853 21.5143 65.6028 21.4629 65.6321 21.3051C65.6395 21.2611 65.6872 21.2464 65.7275 21.2354C65.8523 21.2097 65.9697 21.173 66.0615 21.0996C66.0762 21.0886 66.0982 21.0886 66.1055 21.0703C66.1826 20.9014 66.3734 20.8831 66.4945 20.7693C66.5789 20.6923 66.6964 20.6519 66.7441 20.5235C66.7698 20.4501 66.8395 20.417 66.8982 20.3767C67.078 20.2629 67.2579 20.1455 67.4377 20.028C67.4927 19.9913 67.5514 19.973 67.6652 20.0978C67.7313 20.1711 67.8194 20.1895 67.9001 20.2849C68.1093 20.5308 68.19 20.773 68.0836 20.9125C68.0359 20.9748 67.9661 21.0189 67.9111 21.0703C67.801 21.173 67.7313 21.3198 67.5551 21.3418C67.4927 21.3492 67.456 21.4079 67.412 21.4519C67.2982 21.5804 67.1368 21.6464 66.9826 21.7235C66.9349 21.7492 66.8762 21.7639 66.8432 21.8079C66.7588 21.9217 66.656 22.0134 66.5422 22.0942C66.4872 22.1345 66.4395 22.1749 66.3734 22.1969C66.3037 22.2189 66.2633 22.2923 66.212 22.34C66.0688 22.4685 65.9441 22.6189 65.7642 22.696C65.6431 22.9089 65.3862 22.9529 65.2871 23.1951C65.2578 23.2722 65.1954 23.3272 65.1073 23.3419C64.8945 23.3676 64.8431 23.3272 64.7036 23.0079C64.6706 22.9309 64.6449 22.8538 64.6229 22.7767C64.5899 22.6703 64.5935 22.6006 64.6523 22.5455C64.733 22.4685 64.7734 22.3437 64.8211 22.2263C64.8578 22.1345 64.8871 22.0354 64.9752 21.9987C65.0082 21.9841 65.0229 21.9474 65.0413 21.9143C65.1257 21.7639 65.2358 21.6501 65.3972 21.5804V21.5914Z"
      fill="#6F3264"
    />
    <path
      d="M58.025 20.8279C58.113 20.905 58.1791 21.0151 58.3406 21.0224C58.3846 21.0224 58.4066 21.0701 58.4213 21.1105C58.4653 21.2316 58.513 21.3417 58.5975 21.4261C58.6085 21.4371 58.6158 21.4628 58.6305 21.4665C58.8103 21.5215 58.847 21.7124 58.9718 21.8188C59.0599 21.8958 59.1076 22.0059 59.2434 22.039C59.3167 22.0573 59.3608 22.1234 59.4048 22.1784C59.5406 22.3436 59.6727 22.5124 59.8012 22.6849C59.8415 22.7362 59.8672 22.7913 59.7498 22.9161C59.6837 22.9858 59.6691 23.0739 59.5846 23.1656C59.3571 23.3968 59.1222 23.4996 58.9755 23.4042C58.9094 23.3638 58.8617 23.3014 58.803 23.25C58.6929 23.1546 58.5387 23.0996 58.502 22.9271C58.4874 22.8647 58.4286 22.8353 58.3809 22.7986C58.2451 22.6995 58.1607 22.5528 58.069 22.4096C58.0396 22.3656 58.0176 22.3105 57.9699 22.2849C57.8488 22.2151 57.746 22.127 57.6543 22.0243C57.6103 21.9766 57.5626 21.9362 57.5332 21.8738C57.5002 21.8114 57.4268 21.7821 57.3754 21.7344C57.2323 21.6133 57.0671 21.5142 56.968 21.3454C56.7442 21.261 56.6671 21.0151 56.4139 20.9564C56.3368 20.938 56.2708 20.8903 56.2451 20.8022C56.19 20.6004 56.2194 20.5417 56.513 20.3545C56.5827 20.3105 56.6561 20.2701 56.7258 20.2371C56.8249 20.1894 56.8946 20.1784 56.9607 20.2261C57.0488 20.2921 57.1809 20.3141 57.302 20.3435C57.4011 20.3655 57.5002 20.3802 57.5479 20.4646C57.5662 20.494 57.6066 20.505 57.6433 20.5196C57.8048 20.582 57.9332 20.6775 58.0213 20.8242L58.025 20.8279Z"
      fill="#6F3264"
    />
    <path
      d="M62.9127 32.6226C62.9237 32.729 62.8907 32.8427 63.0008 32.9492C63.0301 32.9785 63.0155 33.0226 63.0008 33.0593C62.9494 33.1657 62.9054 33.2685 62.909 33.3785C62.909 33.3932 62.898 33.4116 62.9054 33.4263C62.9934 33.5767 62.8907 33.7272 62.9054 33.874C62.9127 33.9804 62.8723 34.0832 62.9457 34.1896C62.9861 34.2483 62.9714 34.318 62.9641 34.3841C62.9457 34.5786 62.9237 34.7731 62.8944 34.9676C62.887 35.0263 62.865 35.0814 62.6962 35.0887C62.6008 35.0924 62.5274 35.1437 62.4026 35.1437C62.0796 35.1511 61.8411 35.0667 61.8007 34.9125C61.7824 34.8428 61.7934 34.7731 61.7861 34.7034C61.7751 34.5713 61.7053 34.4355 61.7971 34.2997C61.8301 34.252 61.8081 34.1933 61.7971 34.1382C61.7677 33.9877 61.8118 33.8373 61.8448 33.6868C61.8558 33.6391 61.8778 33.5877 61.8631 33.5437C61.8228 33.4189 61.8154 33.2978 61.8191 33.173C61.8191 33.1143 61.8154 33.0556 61.8374 32.9969C61.8595 32.9345 61.8264 32.8684 61.8191 32.8024C61.8044 32.6299 61.753 32.4611 61.7971 32.2886C61.6943 32.0904 61.8117 31.8812 61.6686 31.6794C61.6246 31.617 61.6136 31.5436 61.6539 31.4702C61.753 31.3014 61.8154 31.2831 62.1604 31.3491C62.2411 31.3638 62.3219 31.3858 62.3953 31.4078C62.5017 31.4409 62.5567 31.4776 62.5677 31.551C62.5861 31.65 62.6632 31.7491 62.7292 31.8445C62.7843 31.9216 62.8466 31.995 62.8246 32.0794C62.8173 32.1088 62.8393 32.1418 62.854 32.1748C62.9274 32.318 62.9531 32.4611 62.9127 32.6152V32.6226Z"
      fill="#6F3264"
    />
    <path
      d="M67.9406 28.0722C68.0323 28.1163 68.1094 28.197 68.2525 28.1603C68.2929 28.1493 68.3186 28.186 68.3369 28.2154C68.3957 28.3108 68.458 28.4025 68.5461 28.4539C68.5608 28.4612 68.5681 28.4833 68.5828 28.4796C68.7516 28.4796 68.8177 28.6411 68.9461 28.7035C69.0379 28.7475 69.1003 28.8356 69.225 28.8246C69.2948 28.8172 69.3425 28.8686 69.3902 28.9053C69.537 29.0154 69.6838 29.1328 69.8269 29.2503C69.8709 29.287 69.904 29.331 69.8196 29.4778C69.7719 29.5622 69.7755 29.6466 69.7131 29.7567C69.548 30.0356 69.3572 30.2008 69.214 30.1567C69.148 30.1384 69.0966 30.0943 69.0379 30.065C68.9241 30.0099 68.781 30.0026 68.7186 29.8595C68.6966 29.8081 68.6379 29.7971 68.5902 29.7787C68.4507 29.7273 68.3553 29.6172 68.2489 29.5145C68.2158 29.4815 68.1865 29.4411 68.1424 29.4301C68.0213 29.4007 67.9186 29.3493 67.8195 29.287C67.7718 29.2576 67.7241 29.2319 67.6874 29.1842C67.6507 29.1328 67.5773 29.1292 67.5222 29.1035C67.3754 29.0337 67.2103 28.9897 67.0965 28.8686C66.8837 28.8576 66.7736 28.6557 66.5387 28.6778C66.4653 28.6851 66.3993 28.6594 66.3626 28.586C66.2781 28.4172 66.2965 28.3548 66.5277 28.0943C66.5827 28.0319 66.6415 27.9732 66.6965 27.9218C66.7772 27.8484 66.8396 27.819 66.902 27.8447C66.9901 27.8777 67.1112 27.8631 67.225 27.852C67.3167 27.8447 67.4085 27.8264 67.4635 27.8887C67.4855 27.9108 67.5222 27.9071 67.5553 27.9108C67.7094 27.9181 67.8378 27.9695 67.9406 28.0796V28.0722Z"
      fill="#6F3264"
    />
    <path
      d="M57.5069 29.0817C57.4262 29.1257 57.3234 29.1367 57.2794 29.2762C57.2684 29.3129 57.228 29.3129 57.1913 29.3092C57.0886 29.2982 56.9858 29.2945 56.9014 29.3349C56.8904 29.3422 56.8684 29.3349 56.861 29.3496C56.7766 29.4817 56.6188 29.4413 56.5051 29.5074C56.4243 29.5551 56.3252 29.5551 56.2739 29.6579C56.2445 29.7166 56.1821 29.7276 56.1307 29.7459C55.9693 29.7973 55.8078 29.8487 55.6463 29.9037C55.5949 29.9184 55.5472 29.9221 55.4665 29.7716C55.4225 29.6872 55.3491 29.6395 55.294 29.5294C55.1472 29.2395 55.1068 28.9936 55.2133 28.8982C55.261 28.8542 55.3234 28.8358 55.3747 28.8028C55.4775 28.7404 55.5546 28.6266 55.7014 28.6597C55.7527 28.6707 55.7931 28.6303 55.8298 28.6009C55.9399 28.5165 56.0794 28.5018 56.2151 28.4762C56.2592 28.4688 56.3069 28.4688 56.3399 28.4395C56.4243 28.3587 56.5197 28.3073 56.6225 28.2633C56.6702 28.245 56.7142 28.2193 56.773 28.2156C56.8317 28.2156 56.8757 28.1606 56.9234 28.1312C57.0555 28.0578 57.1766 27.9514 57.3381 27.933C57.4629 27.7715 57.6794 27.8082 57.7932 27.6101C57.8299 27.5477 57.8849 27.5147 57.962 27.5293C58.1381 27.566 58.1748 27.6211 58.2482 27.9587C58.2666 28.0394 58.2776 28.1202 58.2849 28.1973C58.296 28.3073 58.2849 28.3697 58.2336 28.4028C58.1602 28.4505 58.1088 28.5532 58.0537 28.6486C58.0097 28.722 57.9767 28.8064 57.8996 28.8138C57.8702 28.8138 57.8519 28.8468 57.8335 28.8725C57.7455 28.9863 57.6427 29.0597 57.5033 29.0743L57.5069 29.0817Z"
      fill="#6F3264"
    />
    <path
      d="M59.2357 57.3245C59.0705 58.7557 58.854 60.286 58.7843 61.754C58.7659 62.1503 58.6779 62.7485 58.6045 63.2146C58.3843 64.6238 58.1751 66.0404 58.0173 67.5047C57.9953 67.7212 57.9549 67.956 57.9402 68.1542C57.8118 70.1836 57.5108 72.1874 57.3604 74.2058C57.2466 75.626 57.1218 77.0499 57.1035 78.4849C57.1072 79.2776 57.0484 80.2207 57.0007 81.0941C56.9494 82.4079 56.909 83.7181 56.8833 85.0319L56.8723 86.9989L56.8649 87.9825L56.887 88.966C56.9053 89.7807 56.909 90.489 56.7402 90.5954C56.6448 90.6541 56.597 91.333 56.4723 91.3697C56.153 91.4688 55.8741 90.3605 55.7677 88.254C55.731 87.3109 55.7493 86.3457 55.7346 85.3915C55.7126 84.4814 55.7346 83.5713 55.742 82.6612C55.7493 82.2061 55.7567 81.751 55.775 81.2923C55.8007 80.8372 55.8374 80.3785 55.8814 79.9198C55.9475 79.2665 55.9512 78.4628 55.9915 77.7399C56.0502 76.705 56.1163 75.6811 56.2044 74.6609C56.3071 73.6443 56.4099 72.6278 56.5163 71.6112C56.5934 70.9617 56.6815 70.2937 56.7292 69.6589C56.8539 67.9891 57.0374 66.3266 57.2356 64.6495C57.331 63.8531 57.4154 63.0825 57.5329 62.2751C57.6576 61.442 57.7347 60.5429 57.8338 59.6732C58.1017 57.3685 58.3292 55.0675 58.6412 52.7628C58.8393 50.0692 59.265 47.258 59.3421 44.557C59.3715 43.7203 59.4449 42.7258 59.5476 41.7606C59.8045 39.4963 59.8852 39.2541 60.1642 40.1495C60.2302 40.3624 60.2926 40.6413 60.344 40.9569C60.4174 41.401 60.4394 41.9294 60.3843 42.8983C60.2889 44.2488 60.2559 45.5809 60.2008 46.8911C60.1495 47.937 60.1164 48.9388 59.9733 50.0875C59.9183 50.4985 59.8926 50.9536 59.8596 51.3756C59.7238 53.3133 59.5109 55.262 59.2247 57.3208L59.2357 57.3245Z"
      fill="#6F3264"
    />
    <path
      d="M64.6489 55.2318C64.7003 56.3584 64.7223 57.5695 64.8801 58.7108C64.9205 59.0191 64.9278 59.4925 64.9278 59.8632C64.9278 60.9825 64.9352 62.1054 64.9939 63.2614C65.0012 63.4339 64.9976 63.6211 65.0159 63.7752C65.1847 65.3643 65.1627 66.957 65.2655 68.5424C65.3352 69.6617 65.3609 70.781 65.5003 71.904C65.5811 72.5242 65.6104 73.2655 65.6435 73.9517C65.7572 76.0142 65.8747 78.0767 65.9884 80.1391C66.0251 80.7813 66.0472 81.3355 65.882 81.4272C65.7903 81.4786 65.7536 82.0107 65.6325 82.0438C65.3132 82.1318 65.0123 81.2768 64.8581 79.6363C64.7884 78.9024 64.748 78.1501 64.693 77.4087C64.5866 75.9885 64.4251 74.5829 64.4287 73.1407C64.4287 72.6269 64.37 72.003 64.326 71.4379C64.2012 69.8231 64.1572 68.2304 64.1021 66.634C64.0875 66.1239 64.0801 65.5955 64.0397 65.1C63.9333 63.7936 63.8599 62.4871 63.8012 61.166C63.7755 60.5384 63.7425 59.9292 63.7351 59.2943C63.7278 58.6337 63.6654 57.9291 63.6324 57.2429C63.5406 55.4263 63.4232 53.6134 63.4049 51.7895C63.2324 49.672 63.2764 47.4407 63.0819 45.3232C63.0195 44.6663 62.9865 43.8846 63.0122 43.1176C63.0709 41.323 63.1296 41.1322 63.4893 41.8111C63.5737 41.9726 63.6581 42.1891 63.7388 42.4277C63.8526 42.769 63.9186 43.18 63.948 43.936C63.992 44.9929 64.0948 46.0351 64.1939 47.0517C64.2709 47.8701 64.3553 48.6481 64.3627 49.5509C64.3627 49.8775 64.3994 50.2298 64.4251 50.5601C64.5462 52.0757 64.6269 53.606 64.6526 55.2318H64.6489Z"
      fill="#6F3264"
    />
  </svg>
)

export default LibraSticker
