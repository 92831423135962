import { useQuery } from '@tanstack/react-query'
import { InfoButton } from 'components/buttons'
import { apiV3PostsPath, storePath } from 'lib/urls'
import BabyUIThemeProvider from 'baby-ui/foundation/BabyUIThemeProvider/BabyUIThemeProvider'
import PostCardRow from 'hello_baby/components/post-card-row'
import withReactQueryClientProvider from 'shared/HOC/withReactQueryClientProvider'
import { getCurrentUser } from 'shared/utils/getCurrentUser/getCurrentUser'
import { Category } from '../../store/components/CategoryGrid/CategoryGrid'
import { DISCOUNTS, OTHER, FAQS, GUIDE_SLUGS, buildOffers } from './constants'
import { CouponCard } from './components/CouponCard'
import useStyles from './CouponsPage.styles'
import FrequentlyAskedQuestions from '../../store/components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'
import LandingPageTheme from '../../landing_pages/LandingPage.theme'

export const CouponsPage = withReactQueryClientProvider(() => {
  const currentUser = getCurrentUser()
  const classes = useStyles()

  const { data } = useQuery({
    queryKey: ['coupons-page-guides', apiV3PostsPath],
    queryFn: () =>
      fetch(`${apiV3PostsPath}?slugs[]=${GUIDE_SLUGS.join('&slugs[]=')}`).then(
        (res) => res.json()
      ),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  const theme = new LandingPageTheme()
  return (
    <BabyUIThemeProvider theme={theme}>
      <div className={classes.hero}>
        <h1 className={classes.heading}>Babylist Coupons &amp; Promotions</h1>
      </div>
      <ul className={classes.quickJump}>
        <li>
          <a className={classes.jumpLink} href="#discounts">
            Discounts
          </a>
        </li>
        <li>
          <a className={classes.jumpLink} href="#offers">
            Offers &amp; Freebies
          </a>
        </li>
        <li>
          <a className={classes.jumpLink} href="#freeShipping">
            Free Shipping
          </a>
        </li>
        <li>
          <a className={classes.jumpLink} href="#faq">
            FAQs
          </a>
        </li>
        <li>
          <a className={classes.jumpLink} href="#shop">
            Shop
          </a>
        </li>
        <li>
          <a className={classes.jumpLink} href="#guides">
            Guides
          </a>
        </li>
      </ul>
      <div className="container">
        <h2 className={classes.subheading} id="discounts">
          Discounts
        </h2>
        {DISCOUNTS.map((discount) => (
          <CouponCard key={discount.title} {...discount} />
        ))}
        <h2 className={classes.subheading} id="offers">
          Offers &amp; Freebies
        </h2>
        {buildOffers(currentUser).map((discount) => (
          <CouponCard key={discount.title} {...discount} />
        ))}
        <h2 className={classes.subheading} id="freeShipping">
          Free Shipping
        </h2>
        {OTHER.map((discount) => (
          <CouponCard key={discount.title} {...discount} />
        ))}
      </div>
      <section className={classes.faqSection} id="faq">
        <div className="container-lg">
          <div className="bg-white">
            <div className={classes.faqWrapper}>
              <FrequentlyAskedQuestions
                accordion
                headerVariant="primary"
                questions={FAQS}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className={classes.shopHeading}>
          <div>
            <h2 className={classes.sectionHeading} id="shop">
              Shop the Best for Baby
            </h2>
            <h3 className={classes.sectionDescription}>
              Expert-selected, real-parent-tested gear for baby.
            </h3>
          </div>
          <div className="flex align-center justify-end">
            <InfoButton block empty pill href={storePath}>
              Shop All
            </InfoButton>
          </div>
        </div>
        <section className={classes.shopSection}>
          <Category
            imageUrl="https://images.ctfassets.net/50gzycvace50/EAXK4ZAP95cfLRytOUJaW/a4b85374977e98c36e1f054db41ee456/LY11172_BLB_ALT2.jpg?bg=rgb:ffffff&fl=progressive&fm=jpg&w=750&h=750"
            title="Sale"
            urlSlug="sale"
            useRouter={false}
          />
          <Category
            imageUrl="https://images.ctfassets.net/50gzycvace50/a80fdc27052863d0f3a71e97857717ff8a2c975bfa7a8d87d4221bcc62acd600/32bda8cb8bac3099df8d6c3418c4a0ae/a80fdc27052863d0f3a71e97857717ff8a2c975bfa7a8d87d4221bcc62acd600.png?fl=progressive&fm=jpg&bg=rgb:f9f9f9&w=620&h=620"
            title="Top Registered"
            urlSlug="top-registered"
            useRouter={false}
          />
          <Category
            imageUrl="https://images.ctfassets.net/50gzycvace50/f8d52087add25e419441c197fbcb4286477be196eeed29ab9db1a6d8c3f0ac5a/5aeb5cf27ed0b052afd07e6b99a7f02d/f8d52087add25e419441c197fbcb4286477be196eeed29ab9db1a6d8c3f0ac5a.png?bg=rgb:ffffff&fl=progressive&fm=jpg&w=750&h=750"
            title="Newborn Must-Haves"
            urlSlug="newborn-must-haves"
            useRouter={false}
          />
          <Category
            imageUrl="https://images.ctfassets.net/50gzycvace50/38d49f0458394a7d5834e83664fde46da80ff7f61dcd6f9e4650de6bbae34cf7/b62c552174e54895a8a9288e4c4d5b6f/38d49f0458394a7d5834e83664fde46da80ff7f61dcd6f9e4650de6bbae34cf7.png?bg=rgb:ffffff&fl=progressive&fm=jpg&w=750&h=750"
            title="Babylist Exclusives"
            urlSlug="exclusives"
            useRouter={false}
          />
        </section>
        <section className="pbxl">
          <h2 className={classes.sectionHeading} id="guides">
            More Ways to Save
          </h2>
          <div className="mtxl">
            {data && <PostCardRow columns={3} posts={data.posts} />}
          </div>
        </section>
      </div>
    </BabyUIThemeProvider>
  )
})
