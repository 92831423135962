import { useQuery } from '@tanstack/react-query'
import { apiV3PostsPath } from 'lib/urls'
import PostCardRow from 'hello_baby/components/post-card-row'

const FEATURED_POST_SLUGS = [
  'how-to-create-a-baby-registry',
  'how-many-baby-clothes-do-i-need',
  'free-baby-stuff-while-pregnant',
  'babylist-registry-hacks',
]

export const GuidesSection = () => {
  const { data } = useQuery({
    queryKey: ['checklist-featured-guides'],
    queryFn: () =>
      fetch(
        `${apiV3PostsPath}?slugs[]=${FEATURED_POST_SLUGS.join('&slugs[]=')}`
      ).then((res) => res.json()),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  return (
    <section className="pbxl">
      <h2 className="h5 text-bold text-brand-info">Also For You</h2>
      {data && <PostCardRow columns={3} posts={data.posts} />}
    </section>
  )
}
