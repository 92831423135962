import { LightButton as DefaultLightButton } from 'components/buttons'
import classNames from 'classnames'
import { ButtonProps } from '../types'
import css from '../Buttons.scss'
import localCss from './LightButton.scss'

const LightButton = ({ children, className, ...otherProps }: ButtonProps) => (
  <DefaultLightButton
    className={classNames(css.Button, localCss.LightButton, className)}
    {...otherProps}
  >
    {children}
  </DefaultLightButton>
)

export default LightButton
