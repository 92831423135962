import { Component } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'

import { Checkbox } from 'components/forms'
import CheckboxData from './product-traits.js'
import FormHeader from './form-header'
import { getQuestionText } from './questions.js'

export default class Step3 extends Component {
  static propTypes = {
    productName: PropTypes.string,
    onChange: PropTypes.func,
  }

  componentDidMount() {
    console.assert(
      this.props.questionIds[0] == 3,
      'first question id passed to this component was not 3!!'
    )
  }

  handleChange = (event) => {
    this.props.onChange(
      this.props.questionIds[0],
      event.target.value,
      event.target.checked
    )
  }

  render() {
    return (
      <div>
        <FormHeader
          questionText={getQuestionText(
            this.props.questionIds[0],
            this.props.productName
          )}
        />
        {map(CheckboxData, (data, idx) => {
          return (
            <Checkbox
              key={idx}
              onChange={this.handleChange}
              value={data['value']}
              label={data['label']}
            />
          )
        })}
      </div>
    )
  }
}
