import PropTypes from 'prop-types'
import { map } from 'lodash'
import { EaseInOutAlert } from 'components/alerts'
import { ALERT_FADE_ANIMATION_DURATION, PURCHASED_ALERT } from '../constants'
import PurchasedItem from './purchased-item'
import ProcessingReservationsAlert from './processing-reservations-alert'

const PurchasedItems = (props) => {
  const { alert, processingReservations, purchasedReservations } = props

  return (
    <div id="purchased-items">
      {alert && (
        <EaseInOutAlert
          animationDuration={ALERT_FADE_ANIMATION_DURATION}
          variant={alert.variant}
          id={alert.id}
          onExited={props.onRemoveAlert.bind(this, PURCHASED_ALERT)}
        >
          {alert.message}
        </EaseInOutAlert>
      )}

      {processingReservations.length > 0 && <ProcessingReservationsAlert />}

      <div className="mbn">
        {map(
          processingReservations.concat(purchasedReservations),
          (reservation) => {
            return (
              <PurchasedItem
                key={`${reservation.id}-${reservation.isPurchased}`}
                reservation={reservation}
                onSetPurchasedReservation={() => {
                  props.onSetPurchasedReservation(reservation)
                }}
              />
            )
          }
        )}
      </div>
    </div>
  )
}

PurchasedItems.propTypes = {
  alert: PropTypes.object,
  processingReservations: PropTypes.array.isRequired,
  purchasedReservations: PropTypes.array.isRequired,
  onSetPurchasedReservation: PropTypes.func.isRequired,
  onRemoveAlert: PropTypes.func.isRequired,
  registry: PropTypes.object.isRequired,
}

export default PurchasedItems
