import React from 'react'

interface BackgroundRightProps {
  className?: string
}

const BackgroundRight: React.FC<BackgroundRightProps> = ({
  className,
}: BackgroundRightProps) => (
  <svg
    className={className}
    width="310"
    height="380"
    viewBox="0 0 310 380"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.3704 212.646C62.4411 217.011 69.2799 217.25 73.6455 213.18C78.0111 209.109 78.2502 202.27 74.1796 197.905C70.109 193.539 63.2701 193.3 58.9045 197.371C54.5389 201.441 54.2998 208.28 58.3704 212.646Z"
      fill="#56BB6C"
    />
    <path
      d="M204.106 307C199.631 307 196 310.877 196 315.657V331.246C196 332.198 196.774 332.962 197.716 332.962H210.346C211.378 332.962 212.212 332.129 212.212 331.098V315.657C212.221 310.877 208.59 307 204.106 307Z"
      fill="#FFD43E"
    />
    <path
      d="M252.087 127C242.555 127 234.727 134.375 234.004 143.697C233.945 144.449 234.549 145.091 235.312 145.091H243.04C243.595 145.091 244.061 144.686 244.13 144.142C244.606 140.178 247.995 137.094 252.087 137.094C255.951 137.094 259.191 139.842 259.944 143.48C260.132 144.409 260.945 145.091 261.896 145.091H269.04C269.704 145.091 270.229 144.528 270.179 143.865C269.545 134.464 261.668 127 252.087 127Z"
      fill="url(#paint0_linear_1468_7511)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M126.117 31.476C126.155 32.8206 125.609 34.0471 124.707 34.9156C125.263 34.6581 125.879 34.5046 126.532 34.4816C129.113 34.3906 131.279 36.3787 131.368 38.9222C131.458 41.4656 129.438 43.6013 126.856 43.6922C126.413 43.7079 125.982 43.6622 125.571 43.5629C126.905 44.346 127.814 45.7743 127.852 47.4256C127.911 49.97 125.88 52.0804 123.317 52.1393C121.097 52.1904 119.206 50.6866 118.7 48.6305C118.592 51.0027 116.64 52.9266 114.192 52.9963C111.629 53.0694 109.493 51.0818 109.421 48.5569C109.379 47.079 110.053 45.7446 111.134 44.8775C110.465 45.2859 109.682 45.5335 108.839 45.5632C106.258 45.6542 104.092 43.6661 104.002 41.1227C103.913 38.5792 105.933 36.4436 108.515 36.3526C108.99 36.3358 109.452 36.3897 109.889 36.5047C108.56 35.7266 107.656 34.3118 107.618 32.6765C107.56 30.1513 109.591 28.0564 112.155 27.9975C114.488 27.9438 116.458 29.5956 116.837 31.797C116.836 31.7782 116.836 31.7594 116.835 31.7405C116.763 29.1965 118.782 27.075 121.345 27.0019C123.908 26.9289 126.044 28.932 126.117 31.476Z"
      fill="url(#paint1_linear_1468_7511)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1468_7511"
        x1="234"
        y1="145.012"
        x2="265.611"
        y2="127.903"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0795732" stop-color="#FF6D4E" />
        <stop offset="0.772297" stop-color="#FED7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1468_7511"
        x1="117.685"
        y1="52.9981"
        x2="117.685"
        y2="24.7268"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.239141" stop-color="#FFD43E" />
        <stop offset="1" stop-color="#FF6D4E" />
      </linearGradient>
    </defs>
  </svg>
)

export default BackgroundRight
