import { InfoButton } from 'components/buttons'
import { newRegistryPath } from 'lib/urls'
import css from './styles.scss'

export const BottomCTA = () => (
  <>
    <div className={css.background}>
      <p className="h2 text-center">
        One registry.
        <br />
        Add items from <span className="text-underline">any</span> store.
        <br />
        Automatically track progress.
      </p>
      <InfoButton pill className="mtl phxl" href={newRegistryPath}>
        Start Your Babylist
      </InfoButton>
    </div>
  </>
)
