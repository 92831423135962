import classNames from 'classnames'

interface EUBlockedContentProps {
  className?: String
}

const EUBlockedContent = ({ className }: EUBlockedContentProps) => (
  <div className={classNames(className)}>
    <p className="man text-center" style={{ fontSize: 72, lineHeight: 1 }}>
      🇪🇺
    </p>
    <p className="h3 mtn text-center">
      <strong>Babylist is not currently available in EU countries.</strong>
    </p>
    <p className="text-center">
      We have a small team and are working towards European privacy
      requirements.
    </p>
  </div>
)

export default EUBlockedContent
