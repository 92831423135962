import css from '../styles.scss'

export const DesktopBackGroundFlower = () => (
  <>
    <svg
      className={css.desktopBGFlower}
      fill="none"
      height="154"
      viewBox="0 0 157 154"
      width="157"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M130.444 29.61C130.045 37.6935 126.382 44.8152 120.864 49.6341C124.136 48.3302 127.706 47.6772 131.419 47.8224C146.118 48.3976 157.5 61.2655 156.842 76.5638C156.184 91.8621 143.735 103.798 129.036 103.222C126.512 103.124 124.086 102.662 121.801 101.889C129.016 107.166 133.519 116.13 132.977 126.053C132.142 141.343 119.645 153.123 105.064 152.364C92.4385 151.706 82.3908 141.863 80.465 129.308C78.7631 143.493 66.7982 154.188 52.8692 153.543C38.2839 152.868 27.0683 140.016 27.8184 124.837C28.2575 115.952 32.6989 108.239 39.2319 103.506C35.2451 105.666 30.6867 106.812 25.8887 106.624C11.1897 106.049 -0.192575 93.1807 0.465585 77.8824C1.12375 62.5841 13.5731 50.6487 28.2721 51.2238C30.9815 51.3298 33.5781 51.8535 36.0075 52.733C28.8189 47.4881 24.3327 38.6074 24.8696 28.7799C25.6986 13.6047 38.1907 1.91835 52.7716 2.67777C66.044 3.36904 76.4721 14.1344 77.6115 27.5065C77.6158 27.3932 77.6207 27.2798 77.6264 27.1663C78.3821 11.8722 90.8185 0.0209205 105.404 0.695724C119.989 1.37053 131.2 14.3159 130.444 29.61ZM77.6929 31.0717L77.6894 31.1596L77.7004 31.1595L77.6929 31.0717Z"
        fill="#DBCCD8"
        fillOpacity="0.2"
        fillRule="evenodd"
      />
    </svg>
  </>
)
