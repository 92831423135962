import { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Input } from 'components/forms'
import { PrimaryButton } from 'components/buttons'
import MailcheckedInput from 'components/mailchecked-input'
import Image from 'components/image'
import splitName from 'lib/split-name'
import { Helmet } from 'react-helmet'

import { checklistQuizPath } from 'lib/urls'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import {
  createValidator,
  email,
  fullName,
  MSG_EMAIL_REQUIRED,
  required,
} from 'lib/form-validation'
import { track } from 'lib/analytics'
import { ASSETS_BASE_URL, FLASH_MESSAGE } from 'shared/constants'
import { updateChecklistAndTriggerEmail } from '../api'
import { EMAIL_SUBMIT_SUCCESS } from '../actions'
import { getCoregistrationAnswer } from '../reducers'
import { ANSWER_COREGISTRATION_YES } from '../constants'

const validate = (data, props) => {
  const rules = {
    email: [{ rule: required, msg: MSG_EMAIL_REQUIRED }, email],
  }

  // Dependent rules
  if (props.coregistrationApproved) {
    rules.name = [required, fullName]
  }
  return createValidator(rules)(data)
}

export class EmailCapture extends Component {
  handleVerifyRecaptcha = async () => {
    const {
      googleReCaptchaProps: { executeRecaptcha },
    } = this.props

    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded')

      return
    }

    return await executeRecaptcha('checklist_quiz')
  }

  onSave = async (data) => {
    const { currentUser } = this.props
    const token = await this.handleVerifyRecaptcha()
    track.checklistQuestionAnswered({
      eventLocation: track.EventLocation.CHECKLIST_QUIZ_MINUS_EMAIL_COLLECTION,
      userLoggedIn: Boolean(currentUser),
    })
    const fullName = splitName(data.name)
    const firstName = fullName.firstName || null
    const lastName = fullName.lastName || null
    return updateChecklistAndTriggerEmail(
      data.email,
      firstName,
      lastName,
      token
    )
      .then((response) => {
        const { dispatch } = this.props
        track.emailCaptured({
          eventLocation:
            track.EventLocation.CHECKLIST_QUIZ_MINUS_EMAIL_COLLECTION,
          eventUrl: window.location.href,
          email: data.email,
          userId: response.userId,
        })
        // facebook tracking event
        window.fbq('trackCustom', 'ChecklistCompleted')
        dispatch({ type: EMAIL_SUBMIT_SUCCESS })
        // we've sent the checklist, if we successfully created the user account and logged the user in redirect them to the registry
        setTimeout(() => {
          this.redirectToResults(response.checklist.userKey)
        }, 20)
      })
      .catch(() => {
        PubSub.publish(FLASH_MESSAGE, {
          message:
            'Sorry, there was an error creating your checklist. Please contact us if the problem continues.',
          variant: 'danger',
        })
      })
  }

  redirectToResults = (checklistId) => {
    window.location.href = `${checklistQuizPath}/${checklistId}`
  }

  render() {
    const {
      coregistrationApproved,
      fields,
      handleSubmit,
      showEmailSuccess,
      submitting,
    } = this.props

    return (
      <div className="checklist-email-capture text-center mtxl">
        <Helmet
          meta={[
            {
              name: 'robots',
              content: 'noindex',
            },
          ]}
        />
        <Image
          alt="Success!"
          className="center-block img-icon-lg img-responsive"
          src={`${ASSETS_BASE_URL}/assets/icons/bl-circle-check-o-success.png`}
        />
        <p className="mtl mbm">
          <b>Your checklist is ready!</b>
        </p>

        {!showEmailSuccess ? (
          <div>
            <p>We'll send your personalized checklist PDF to your inbox.</p>
            <form onSubmit={handleSubmit(this.onSave)} className="text-left">
              <Input type="hidden" {...fields.checklistId} />
              {coregistrationApproved && (
                <Input
                  {...fields.name}
                  id="name-field"
                  name="name"
                  placeholder="First and Last Name"
                  type="text"
                  error={fields.name.touched ? fields.name.error : null}
                />
              )}
              <MailcheckedInput
                {...fields.email}
                placeholder="Email Address"
                groupClassName="text-left mbn"
                mailcheckOnChange
                mailcheckDelay={300}
                error={fields.email.touched ? fields.email.error : null}
              />

              <p className="h6 mtn mbl text-left">
                <span className="fa fa-lock" />{' '}
                <i>We keep your information private.</i>
              </p>

              <div className="text-center">
                <PrimaryButton submitting={submitting} type="submit">
                  Send Checklist
                </PrimaryButton>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    )
  }
}

EmailCapture.propTypes = {
  coregistrationApproved: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showEmailSuccess: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired,
}

export default reduxForm(
  {
    form: 'checklistEmail',
    fields: ['email', 'name', 'checklistId'],
    validate,
  },
  (state) => {
    const coregistrationAnswer = getCoregistrationAnswer(state)
    return {
      coregistrationApproved: coregistrationAnswer
        ? coregistrationAnswer === ANSWER_COREGISTRATION_YES
        : false,
      showEmailSuccess: state.checklist.showEmailSuccess,
      initialValues: {
        checklistId: state.checklist.id,
        email: '',
        name: '',
      },
    }
  }
)(withCurrentUser(withGoogleReCaptcha(EmailCapture)))
