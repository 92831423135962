import { apiV3RestrictedEmailPreferencesPath } from 'lib/urls'

import fetch from 'lib/fetch'

export const saveEmailPreferences = (data, restrictedAccessToken) => {
  return fetch(`${apiV3RestrictedEmailPreferencesPath}/${data.id}`, {
    body: JSON.stringify({ emailPreferences: data }),
    headers: { token: restrictedAccessToken },
    method: 'PUT',
  })
}
