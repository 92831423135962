import PropTypes from 'prop-types'

const BLMaintenanceMessage = (
  { className, message },
  { maintenanceModeMessage }
) => {
  return (
    <p
      className={className}
      dangerouslySetInnerHTML={{ __html: maintenanceModeMessage }}
    ></p>
  )
}

BLMaintenanceMessage.contextTypes = {
  maintenanceModeMessage: PropTypes.string,
}

export default BLMaintenanceMessage
