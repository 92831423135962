export const PrivateAddressDisclaimer = () => (
  <div className="has-error mbm">
    <div className="flex align-center danger">
      <i className="fa fa-icn fa-exclamation-circle large prm" />{' '}
      <span className="small">
        I understand that my gift givers won't be able to ship to my address
        unless I personally provide it to them.
      </span>
    </div>
  </div>
)
