import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useMutation,
  useQuery,
  QueryClient,
  useQueryClient,
} from '@tanstack/react-query'
import { partition } from 'lodash'
import { successMessage, errorMessage } from 'lib/flash-message'
import { Category } from '../types'
import {
  fetchRegistryChecklistCategories,
  resetRegistryChecklistCategories,
  updateRegistryChecklistCategoryChecked,
} from '.'

export const SIGNUP_MODAL_PUBSUB_KEY = 'checklist.showSignupModal'
const REGISTRY_CHECKLIST_CATEGORIES_STALE_TIME = 3000

interface QueryData {
  data: {
    categories: Category[]
  }
}

interface UseExpandedStateOptions {
  storageKey: string
  defaultValue?: boolean
}

/**
 * Helper that is used to build a shared react-query key.
 */
export const registryChecklistCategoriesKey = (registryId: number | false) => [
  'registry-checklist-categories',
  registryId,
]

const useQuerySettings = (registryId: number | false) => {
  // [User is logged in] Keep the data fresh.
  if (registryId) {
    return {
      staleTime: REGISTRY_CHECKLIST_CATEGORIES_STALE_TIME,
      keepPreviousData: true,
    }
  }
  // [User is not logged in] Data is static, so we can keep it around forever.
  return {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  }
}

const handleChecklistCategoryCheckedMutationSettled = (
  registryId: number | false,
  queryClient: QueryClient
) => {
  // [User is logged in] Invalidate the query so that all checklist categories are up to date
  if (registryId) {
    setTimeout(() => {
      queryClient.invalidateQueries(registryChecklistCategoriesKey(registryId))
    }, 1000)
  }
  // [User is logged out] Open the signup cta modal.
  PubSub.publish(SIGNUP_MODAL_PUBSUB_KEY)
}

/**
 * Hook that will fetch the registry checklist categories and transform the data.
 */
export const useRegistryChecklistCategories = (registryId: number | false) => {
  const query = useQuery({
    queryKey: registryChecklistCategoriesKey(registryId),
    queryFn: () => fetchRegistryChecklistCategories(registryId),
    ...useQuerySettings(registryId),
  })

  // First we partition the categories into themes and subcategories.
  const [themes, subcategories] = partition(
    query.data?.data?.categories,
    (category) => !category.parentId
  )

  // Transform the data to make it easier to work with in the UI.
  const decoratedParentCategories = useMemo(() => {
    if (!query.data) return []

    return themes.map((theme) => {
      // Then we map each subcategory to a parent theme.
      const childCategories = subcategories.filter(
        (category: Category) => category.parentId === theme.id
      )
      // Then we add some extra data to the theme.
      return {
        ...theme,
        categories: childCategories,
        completedCategoriesCount: childCategories.filter(
          (category: Category) => category.isComplete
        ).length,
        totalCategoriesCount: childCategories.length,
      }
    })
  }, [query.data])

  // Then we calculate some totals.
  const [
    completedCategoriesCount,
    completedEssentialsCategoryCount,
    totalEssentialsCategoryCount,
  ] = useMemo(() => {
    if (!query.data) {
      return [0, 0, 0]
    }

    let completedCount = 0
    let essentialsCompletedCount = 0
    let essentialsCount = 0

    query.data.data.categories.forEach((category: Category) => {
      if (category.isComplete) {
        completedCount += 1
        if (category.isEssential) {
          essentialsCompletedCount += 1
        }
      }
      if (category.isEssential) {
        essentialsCount += 1
      }
    })

    return [completedCount, essentialsCompletedCount, essentialsCount]
  }, [query.data])

  const totalCategoriesCount = subcategories?.length ?? 0

  return {
    ...query,
    data: {
      themes: decoratedParentCategories,
      completedCategoriesCount,
      completedEssentialsCategoryCount,
      totalCategoriesCount,
      totalEssentialsCategoryCount,
    },
  }
}

/**
 * Hook that will update whether a registry checklist category is marked as complete.
 */
export const useRegistryChecklistCategoryChecked = (
  registryId: number,
  categoryId: number
) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (isChecked: boolean) =>
      updateRegistryChecklistCategoryChecked(registryId, categoryId, isChecked),
    // When mutate is called:
    onMutate: (isChecked) => {
      const queryKey = registryChecklistCategoriesKey(registryId)
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(queryKey)

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(queryKey)

      // Optimistically update the category completion status.
      queryClient.setQueryData<QueryData>(queryKey, (old: any) => ({
        ...old,
        data: {
          ...old.data,
          categories: old.data.categories.map((category: Category) => {
            // Update the category that was updated.
            if (category.id === categoryId) {
              return {
                ...category,
                isComplete: isChecked,
              }
            }
            return category
          }),
        },
      }))

      // Return a context object with the snapshotted value
      return { previousData }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _variables, context) => {
      const previousData = context?.previousData
      queryClient.setQueryData(
        registryChecklistCategoriesKey(registryId),
        previousData
      )
      errorMessage('An error occurred while updating your checklist.')
    },
    // Always refetch after error or success:
    onSettled: () => {
      handleChecklistCategoryCheckedMutationSettled(registryId, queryClient)
    },
  })

  return mutation
}

export const useResetRegistryChecklistCategories = (registryId: number) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: () => resetRegistryChecklistCategories(registryId),
    // When mutate is called:
    onMutate: () => {
      const queryKey = registryChecklistCategoriesKey(registryId)
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(queryKey)

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(queryKey)

      // Optimistically update all the checklist categories to be incomplete.
      queryClient.setQueryData<QueryData>(queryKey, (old: any) => ({
        ...old,
        data: {
          ...old.data,
          categories: old.data.categories.map((category: Category) => ({
            ...category,
            isComplete: false,
          })),
        },
      }))

      // Return a context object with the snapshotted value
      return { previousData }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _variables, context) => {
      const previousData = context?.previousData
      queryClient.setQueryData(
        registryChecklistCategoriesKey(registryId),
        previousData
      )
      errorMessage('An error occurred while reseting your checklist.')
    },
    onSuccess: () => {
      successMessage('Checklist categories reset!')
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(registryChecklistCategoriesKey(registryId))
    },
  })

  return mutation
}

/**
 * Remembers the checklist theme expanded state for the current session.
 */
export const useExpandedState = ({
  storageKey,
  defaultValue = false,
}: UseExpandedStateOptions) => {
  const [expanded, setExpanded] = useState(
    defaultValue || sessionStorage?.getItem(storageKey) === 'true'
  )

  const toggleExpanded = useCallback(() => {
    setExpanded((prevExpanded) => {
      const newExpanded = !prevExpanded
      sessionStorage?.setItem(storageKey, String(newExpanded))
      return newExpanded
    })
  }, [storageKey])

  useEffect(
    // Clear the expanded state when the component unmounts.
    // For example, when the user navigates away via react-router.
    () => () => {
      sessionStorage?.removeItem(storageKey)
    },
    [storageKey]
  )

  return [expanded, toggleExpanded] as const
}
