import css from './Guides.scss'
import PostCardRow from 'hello_baby/components/post-card-row'

interface GuidesProps {
  posts: Post[]
}

const Guides: React.FC<GuidesProps> = ({ posts }) => {
  return (
    <div className={css.Guides}>
      <h2>Pregnancy guides for you</h2>
      <div className={css.Guides__wrapper}>
        <PostCardRow posts={posts.slice(0, 4)} showTopics={false} />
      </div>
    </div>
  )
}

export default Guides
