import React from 'react'

interface InsightStickerProps {
  className?: string
}

const InsightSticker: React.FC<InsightStickerProps> = ({
  className,
}: InsightStickerProps) => (
  <svg
    className={className}
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1176_5083)">
      <g filter="url(#filter0_d_1176_5083)">
        <path
          d="M16.896 80.7704C16.7056 80.7704 16.5104 80.7704 16.3152 80.7704C15.2733 80.6889 14.2598 80.3908 13.3396 79.8954C12.4195 79.3999 11.6128 78.718 10.9711 77.8931C10.3294 77.0682 9.86696 76.1185 9.61319 75.1047C9.35942 74.0909 9.31995 73.0353 9.49732 72.0053L11.0018 63.1497L4.60295 56.8413C3.59993 55.8638 2.8901 54.6255 2.55359 53.2659C2.21707 51.9064 2.26727 50.4799 2.69852 49.1474C3.13434 47.8161 3.93372 46.6331 5.00638 45.732C6.07905 44.831 7.38226 44.2477 8.76887 44.0482L17.6483 42.758L21.6238 34.6976C22.0881 33.7578 22.744 32.9256 23.5492 32.2545C24.3544 31.5834 25.2911 31.0883 26.2992 30.8008C27.3072 30.5134 28.3642 30.44 29.4023 30.5856C30.4404 30.7311 31.4365 31.0922 32.3266 31.6458C32.5076 31.7553 32.6837 31.879 32.8551 32.0076C40.9861 25.4062 50.7752 21.1668 61.1526 19.7528C71.53 18.3389 82.0964 19.8046 91.6971 23.9899H91.7447C93.1301 24.6074 94.3745 25.5017 95.4013 26.618C96.9436 28.2954 97.9329 30.4064 98.2352 32.6649C98.5376 34.9235 98.1383 37.2204 97.0914 39.2444V39.2778L80.1468 71.6006C78.8627 74.0713 76.6943 75.967 74.0742 76.9095C71.454 77.852 68.5749 77.7719 66.0112 76.6854C60.1235 74.131 53.7372 72.9319 47.324 73.1765C47.3362 74.4705 47.014 75.7457 46.3886 76.8786C45.7631 78.0114 44.8557 78.9636 43.7542 79.6427C42.6526 80.3218 41.3944 80.7049 40.1013 80.7548C38.8082 80.8048 37.5242 80.5198 36.3735 79.9277L28.3845 75.7236L20.4287 79.9039C19.3397 80.4772 18.1267 80.7747 16.896 80.7704ZM48.9761 59.2218C55.8081 59.2229 62.5833 60.4634 68.9726 62.883L83.2558 35.6355C75.1709 32.7791 66.4186 32.3943 58.1142 34.5302C49.8099 36.666 42.3294 41.2258 36.6259 47.6286L29.7557 55.3464L28.3511 52.4897C27.8249 53.3638 27.1264 54.1218 26.2981 54.7176C25.4698 55.3135 24.529 55.7347 23.5329 55.9557C24.2123 56.7199 24.7279 57.6152 25.0481 58.5863C25.3683 59.5575 25.4862 60.5839 25.3945 61.6023C26.3406 61.196 27.3596 60.9865 28.3892 60.9865C29.4189 60.9865 30.4378 61.196 31.3839 61.6023L37.9733 60.3026C41.597 59.5856 45.2821 59.2237 48.9761 59.2218Z"
          fill="white"
        />
        <path
          d="M90.9065 36.0694L86.174 45.1155C78.1498 41.2759 69.2479 39.6425 60.3833 40.383C51.5187 41.1235 43.0112 44.2113 35.7353 49.3289L34.7497 49.1813C34.6621 49.1673 34.579 49.1331 34.5069 49.0814C34.4363 49.0276 34.3779 48.9594 34.3355 48.8814L31.436 43.011C38.4599 35.1115 47.803 29.6368 58.1278 27.3705C68.4526 25.1042 79.2298 26.1626 88.9164 30.3942C89.6938 30.7391 90.3456 31.3158 90.7828 32.0453C91.22 32.7748 91.4211 33.6215 91.3588 34.4697C91.3214 35.0317 91.1674 35.5797 90.9065 36.0789V36.0694Z"
          fill="url(#paint0_linear_1176_5083)"
        />
        <path
          d="M86.1743 45.1059L78.509 59.7224C67.4092 54.1864 54.6464 52.9796 42.7057 56.3372L47.2669 51.8809C47.3382 51.81 47.3892 51.7212 47.4144 51.6238C47.4407 51.5255 47.4407 51.422 47.4144 51.3238C47.3826 51.225 47.3231 51.1374 47.2431 51.0714C47.1649 51.0082 47.0711 50.9671 46.9717 50.9524L36.9496 49.4956C36.8516 49.4825 36.7601 49.4393 36.6878 49.3718C36.6331 49.3224 36.5906 49.261 36.5637 49.1925C36.5368 49.1239 36.5261 49.0501 36.5326 48.9767C36.5391 48.9033 36.5624 48.8324 36.6009 48.7696C36.6394 48.7068 36.692 48.6538 36.7544 48.6148C43.9832 43.7971 52.3399 40.9407 61.0052 40.3256C69.6705 39.7104 78.3469 41.3578 86.1838 45.1059H86.1743Z"
          fill="url(#paint1_linear_1176_5083)"
        />
        <path
          d="M78.509 59.7224L73.9527 68.3875C73.4813 69.3073 72.6794 70.0148 71.708 70.368C70.7367 70.7213 69.6676 70.6941 68.7155 70.2919C59.4562 66.2931 49.2016 65.1974 39.3064 67.1496L38.2494 60.9888C38.2319 60.9026 38.2371 60.8133 38.2647 60.7298C38.2922 60.6463 38.3411 60.5715 38.4065 60.5127L42.6915 56.342C54.6408 52.9747 67.416 54.1816 78.5233 59.7271L78.509 59.7224Z"
          fill="url(#paint2_linear_1176_5083)"
        />
        <path
          d="M47.2956 51.8806L42.7392 56.3369L38.4543 60.5076C38.3926 60.5695 38.3455 60.6443 38.3162 60.7266C38.295 60.8126 38.295 60.9025 38.3162 60.9885L39.3684 67.1351L40.4063 73.1673C40.4226 73.2689 40.4093 73.3729 40.3682 73.4672C40.3314 73.5645 40.2665 73.6486 40.1817 73.7088C40.0969 73.769 39.9961 73.8025 39.8921 73.8052C39.7918 73.8151 39.6908 73.7953 39.6017 73.7482L28.6512 67.9968C28.572 67.9551 28.4837 67.9338 28.3941 67.9349C28.3061 67.9338 28.2193 67.9551 28.1418 67.9968L17.1914 73.7482C17.1001 73.7944 16.9983 73.8157 16.8962 73.81C16.7946 73.8019 16.6973 73.7656 16.6153 73.7053C16.5332 73.644 16.469 73.5618 16.4296 73.4672C16.3914 73.3721 16.3815 73.268 16.401 73.1673L18.4721 60.9885C18.4909 60.9022 18.4909 60.8129 18.4721 60.7266C18.4428 60.6443 18.3957 60.5695 18.334 60.5076L9.48795 51.8806C9.41549 51.8087 9.3644 51.718 9.34036 51.6187C9.31428 51.5221 9.31428 51.4202 9.34036 51.3235C9.37014 51.2253 9.42787 51.1379 9.50649 51.072C9.58512 51.006 9.68125 50.9643 9.78314 50.9521L22.0096 49.1715C22.0972 49.1575 22.1803 49.1232 22.2524 49.0715C22.323 49.0177 22.3814 48.9496 22.4238 48.8716L27.8895 37.7973C27.9368 37.7073 28.0075 37.6317 28.0942 37.5784C28.1799 37.5226 28.28 37.4929 28.3822 37.4929C28.4845 37.4929 28.5846 37.5226 28.6703 37.5784C28.757 37.6317 28.8277 37.7073 28.875 37.7973L31.446 43.0107L34.3455 48.8811C34.3864 48.9601 34.4451 49.0285 34.5169 49.081C34.5885 49.1336 34.6719 49.1679 34.7597 49.181L35.7452 49.3286L46.9861 50.9616C47.0866 50.976 47.1808 51.019 47.2575 51.0854C47.3335 51.1522 47.3909 51.2375 47.4241 51.333C47.4527 51.4301 47.4545 51.5332 47.4294 51.6313C47.4042 51.7293 47.3531 51.8188 47.2813 51.8901L47.2956 51.8806Z"
          fill="url(#paint3_linear_1176_5083)"
        />
        <path
          d="M70.3108 72.0772C69.5613 72.0759 68.8197 71.9237 68.1303 71.6297C59.1399 67.7315 49.1748 66.6668 39.5639 68.5778L38.0832 68.8682L36.7739 61.2505C36.7177 60.9248 36.7427 60.5901 36.8465 60.2762C36.9503 59.9624 37.1297 59.6789 37.369 59.4508L41.9015 55.0326L42.2634 54.9278C54.5657 51.4497 67.7235 52.6927 79.1569 58.413L80.5043 59.089L75.2671 69.0872C74.6219 70.3315 73.53 71.2857 72.2105 71.7582C71.6003 71.973 70.9577 72.0808 70.3108 72.0772ZM48.9812 64.7071C55.9683 64.7084 62.8809 66.1425 69.292 68.9207C69.8989 69.1699 70.577 69.1836 71.1935 68.959C71.8099 68.7345 72.3203 68.2878 72.6247 67.7066L76.4764 60.384C66.1237 55.6005 54.4124 54.6341 43.4156 57.6559L39.7591 61.2172L40.478 65.4117C43.2881 64.9435 46.1323 64.7094 48.9812 64.7118V64.7071ZM39.4591 61.5457C39.4591 61.5457 39.4353 61.5552 39.4401 61.5504L39.4591 61.5457Z"
          fill="#6E3264"
        />
        <path
          d="M36.102 50.8617L34.5356 50.6331C34.2139 50.5834 33.9087 50.4578 33.6453 50.2665C33.3809 50.0751 33.1656 49.8238 33.0168 49.5333L29.6841 42.7631L30.3459 42.0204C37.5774 33.8902 47.1952 28.2555 57.8231 25.9227C68.451 23.5898 79.5445 24.6783 89.5164 29.0322C90.2161 29.3437 90.8448 29.7947 91.3639 30.3578C91.8831 30.9209 92.2817 31.5841 92.5353 32.3067C92.789 33.0294 92.8924 33.7963 92.8391 34.5603C92.7858 35.3244 92.577 36.0695 92.2255 36.7499L86.8121 47.048L85.5314 46.4338C77.7429 42.7098 69.1036 41.1258 60.5005 41.8446C51.8974 42.5633 43.6407 45.5589 36.5781 50.5236L36.102 50.8617ZM33.2025 43.2439L35.4212 47.7384C42.6875 42.7651 51.1059 39.7346 59.8749 38.9354C68.6438 38.1362 77.4714 39.5949 85.5171 43.1726L89.5974 35.3834C89.7638 35.0611 89.8628 34.7083 89.8881 34.3465C89.9135 33.9846 89.8648 33.6214 89.7449 33.2791C89.626 32.9363 89.4378 32.6216 89.192 32.3546C88.9463 32.0876 88.6484 31.874 88.3166 31.727C79.0703 27.6921 68.798 26.6331 58.923 28.6967C49.048 30.7602 40.0593 35.8441 33.2025 43.2439Z"
          fill="#6E3264"
        />
        <path
          d="M79.1467 61.689L77.8517 61.0415C67.0798 55.6559 54.6853 54.486 43.096 57.7611L37.5398 59.3228L44.9147 52.1384L36.7495 50.9575C36.3529 50.8982 35.984 50.7188 35.6925 50.4434C35.477 50.2408 35.3103 49.992 35.2048 49.7157C35.0994 49.4393 35.0579 49.1427 35.0836 48.8481C35.1092 48.5534 35.2013 48.2684 35.353 48.0145C35.5046 47.7606 35.7119 47.5443 35.9591 47.382C43.3973 42.4282 51.9948 39.4916 60.9093 38.8598C69.8238 38.228 78.7495 39.9227 86.8121 43.7779L88.1928 44.4349L79.1467 61.689ZM56.246 52.9953C63.7133 52.9903 71.0916 54.6152 77.866 57.7563L84.1459 45.7775C77.0796 42.6647 69.3736 41.2795 61.6656 41.7366C53.9576 42.1936 46.4693 44.4797 39.8203 48.4057L47.1714 49.4721C47.5469 49.5342 47.8979 49.699 48.1855 49.9482C48.4737 50.1906 48.6881 50.509 48.8045 50.8671C48.9183 51.2246 48.9331 51.6064 48.8473 51.9717C48.7527 52.3343 48.56 52.6638 48.2903 52.9239L47.4619 53.7333C50.3631 53.2341 53.3021 52.9872 56.246 52.9953Z"
          fill="#6E3264"
        />
        <path
          d="M39.8299 75.2861C39.5081 75.2855 39.1912 75.2071 38.9062 75.0575L28.3938 69.5251L17.8861 75.0528C17.5489 75.2275 17.1698 75.305 16.7911 75.2765C16.4159 75.2512 16.0557 75.119 15.7532 74.8956C15.448 74.6735 15.2115 74.37 15.0709 74.0197C14.9303 73.6694 14.8912 73.2866 14.9581 72.9151L16.9434 61.2219L8.44969 52.9376C8.18173 52.6769 7.99066 52.3475 7.89741 51.9854C7.82931 51.7112 7.81952 51.4257 7.8687 51.1475C7.91787 50.8693 8.0249 50.6045 8.18287 50.3702C8.34084 50.136 8.54622 49.9375 8.78573 49.7876C9.02525 49.6377 9.29355 49.5398 9.5733 49.5002L21.3141 47.7909L26.5513 37.1451C26.6748 36.894 26.8495 36.6715 27.0641 36.4917C27.2787 36.312 27.5285 36.1791 27.7974 36.1015C28.0664 36.024 28.3486 36.0036 28.6259 36.0414C28.9032 36.0793 29.1696 36.1746 29.4079 36.3215C29.7332 36.5193 29.9945 36.807 30.1602 37.1499L35.4545 47.7957L35.9591 47.867L47.2 49.5002C47.4787 49.5422 47.7455 49.642 47.9834 49.7931C48.2212 49.9442 48.4249 50.1433 48.5813 50.3778C48.7378 50.6122 48.8435 50.8767 48.8918 51.1543C48.94 51.432 48.9298 51.7167 48.8616 51.9902C48.7682 52.3522 48.5771 52.6815 48.3093 52.9424L39.8299 61.2266L41.8343 72.9246C41.8981 73.2962 41.8565 73.6782 41.7143 74.0275C41.5721 74.3767 41.3349 74.6791 41.0297 74.9004C40.7261 75.1219 40.3665 75.2539 39.9918 75.2813L39.8299 75.2861ZM28.3938 66.459C28.7253 66.4547 29.0526 66.5333 29.346 66.6876L38.6301 71.5676L36.8209 61.2409C36.7622 60.9144 36.7859 60.5783 36.8898 60.2633C36.9937 59.9482 37.1746 59.664 37.416 59.4365L44.8909 52.1378L34.5451 50.6523C34.2222 50.6018 33.9156 50.4762 33.65 50.2857C33.3856 50.0937 33.1716 49.8405 33.0263 49.5477L28.3843 40.1446L23.747 49.5429C23.5993 49.8355 23.3838 50.0885 23.1185 50.2809C22.8613 50.4705 22.5627 50.5962 22.2473 50.6475L11.8539 52.1568L19.3525 59.4698C19.5837 59.6995 19.7582 59.9798 19.862 60.2887C19.9598 60.5962 19.9843 60.9223 19.9334 61.2409L18.1765 71.5772L27.4511 66.6971C27.7426 66.5457 28.0654 66.4642 28.3938 66.459Z"
          fill="#6E3264"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1176_5083"
        x="0.227536"
        y="19.2297"
        width="100.212"
        height="65.7527"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.10596" />
        <feGaussianBlur stdDeviation="1.05298" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1176_5083"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1176_5083"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1176_5083"
        x1="55.375"
        y1="50.9581"
        x2="57.2169"
        y2="23.8828"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0795732" stop-color="#FF6D4E" />
        <stop offset="0.772297" stop-color="#FED7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1176_5083"
        x1="61.5421"
        y1="59.9319"
        x2="61.5421"
        y2="34.6604"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.13" stop-color="#56BB6C" />
        <stop offset="0.69417" stop-color="#E1EB3B" />
        <stop offset="1" stop-color="#FFD43E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1176_5083"
        x1="58.3812"
        y1="70.6148"
        x2="58.3812"
        y2="54.4709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A0DCFF" />
        <stop offset="0.7359" stop-color="#56BB6C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1176_5083"
        x1="28.384"
        y1="73.8107"
        x2="28.384"
        y2="34.3174"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.239141" stop-color="#FFD43E" />
        <stop offset="1" stop-color="#FF6D4E" />
      </linearGradient>
      <clipPath id="clip0_1176_5083">
        <rect
          width="100"
          height="100"
          fill="white"
          transform="translate(0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default InsightSticker
