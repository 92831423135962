export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const setActiveModal = (activeModal, modalProps = {}) => ({
  type: SET_ACTIVE_MODAL,
  activeModal,
  modalProps: activeModal ? modalProps : {},
}) // Clear ModalProps if clearing active modal

export const SET_IS_REGISTRY_DISCOUNT_VIEW = 'SET_IS_REGISTRY_DISCOUNT_VIEW'
export const setIsRegistryDiscountView = (isRegistryDiscountView) => ({
  type: SET_IS_REGISTRY_DISCOUNT_VIEW,
  isRegistryDiscountView,
})

export const SET_COLLAPSE_ALL_REG_ITEM_CATEGORIES =
  'SET_COLLAPSE_ALL_REG_ITEM_CATEGORIES'
export const setIsAllCategoriesCollapsed = (isAllCategoriesCollapsed) => ({
  type: SET_COLLAPSE_ALL_REG_ITEM_CATEGORIES,
  isAllCategoriesCollapsed,
})

export const SET_IS_CONTRIBUTOR_VIEW = 'SET_IS_CONTRIBUTOR_VIEW'
export const setIsContributorView = (isContributorView) => ({
  type: SET_IS_CONTRIBUTOR_VIEW,
  isContributorView,
})

export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const setIsMobile = (isMobile) => ({ type: SET_IS_MOBILE, isMobile })

export const SET_IS_SHIPPING_ADDRESS_BUTTON_VISIBLE =
  'SET_IS_SHIPPING_ADDRESS_BUTTON_VISIBLE'
export const setIsShippingAddressButtonVisible = (
  isShippingAddressButtonVisible
) => ({
  type: SET_IS_SHIPPING_ADDRESS_BUTTON_VISIBLE,
  isShippingAddressButtonVisible,
})
