import Image from 'components/image'
import css from './styles.scss'

export default function ExpectfulGuideLabel() {
  return (
    <span className={css.expectfulLabel}>
      by{' '}
      <Image
        height={18}
        src="https://images.babylist.com/image/upload/v1648848928/Expectful_Logo_kf8lqr.svg"
        alt="Expectful logo"
      />
    </span>
  )
}
