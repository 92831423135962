import { Component } from 'react'
import PropTypes from 'prop-types'

import { Radio } from 'components/forms'

import FormHeader from './form-header'
import {
  getQuestionText,
  question1Answers,
  question7Answers,
} from './questions.js'

export default class Step1 extends Component {
  static propTypes = {
    productName: PropTypes.string,
    questionIds: PropTypes.array,
    onChange: PropTypes.func,
  }

  componentDidMount() {
    console.assert(
      this.props.questionIds[0] == 1,
      'first question id passed to this component was not 1!!'
    )
    console.assert(
      this.props.questionIds[1] == 7,
      'second question id passed to this component was not 7!!'
    )
  }

  handleChange = (event) => {
    this.props.onChange(event.target.name, event.target.value)
  }

  render() {
    return (
      <div>
        <FormHeader
          questionText={getQuestionText(
            this.props.questionIds[0],
            this.props.productName
          )}
        />
        {question1Answers.map((text, idx) => (
          <Radio
            key={idx}
            onChange={this.handleChange}
            label={text}
            value={text}
            name={this.props.questionIds[0]}
          />
        ))}

        <FormHeader questionText={getQuestionText(this.props.questionIds[1])} />
        {question7Answers.map((text, idx) => (
          <Radio
            key={idx}
            onChange={this.handleChange}
            label={text}
            value={text}
            name={this.props.questionIds[1]}
          />
        ))}
      </div>
    )
  }
}
