import { TwoColumnResponsiveLayout } from 'components/presentation'
import RegItem from './reg-item'
import DidNotBuyCartForm from './did-not-buy-cart-form'
import { successMessage } from 'lib/flash-message'

const CancelReservationForm = ({ onHide, registry, reservation }) => {
  return (
    <TwoColumnResponsiveLayout>
      <div>
        <RegItem
          className="mbl"
          regItem={reservation.regItem}
          registry={registry}
        />
      </div>
      <div>
        <DidNotBuyCartForm
          registry={registry}
          reservation={reservation}
          onSubmitSuccess={() => {
            successMessage(
              `${reservation.regItem.title} has been removed from your cart.`
            )
          }}
        />
      </div>
    </TwoColumnResponsiveLayout>
  )
}

export default CancelReservationForm
