import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DangerButton } from 'components/buttons'
import { CANCEL_ORDER } from 'order/constants'
import { setActiveModal } from '../actions'
import { getOrderCanCancel } from '../reducers'
import withExpiration from 'shared/with-expiration'

const CancelOrderButton = ({ canCancel, showCancelOrderModal }) => {
  return (
    canCancel && (
      <DangerButton onClick={showCancelOrderModal}>Cancel Order</DangerButton>
    )
  )
}

CancelOrderButton.propTypes = {
  reference: PropTypes.string.isRequired,
  showCancelOrderModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showCancelOrderModal: () => {
      dispatch(setActiveModal(CANCEL_ORDER, { reference: ownProps.reference }))
    },
  }
}

const mapStateToProps = (state) => {
  return {
    canCancel: getOrderCanCancel(state),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withExpiration(CancelOrderButton))
