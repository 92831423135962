import PropTypes from 'prop-types'

export const ProductShape = PropTypes.shape({
  product_id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
})

export const ResultShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

export const OptionShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
})

export const QuestionShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(OptionShape).isRequired,
})

export const DiaperCalculatorShape = PropTypes.shape({
  resultContent: PropTypes.shape({
    goBigOrGoHome: ResultShape.isRequired,
    justTheBasics: ResultShape.isRequired,
    sensibleStart: ResultShape.isRequired,
  }).isRequired,
  answers: PropTypes.shape({
    diaper_type: PropTypes.string,
    planning_ahead: PropTypes.string,
    storage: PropTypes.string,
  }).isRequired,
  order: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  questionContent: PropTypes.arrayOf(QuestionShape).isRequired,
  featuredProducts: PropTypes.arrayOf(ProductShape).isRequired,
  result: ResultShape,
})

export const RoutingShape = PropTypes.shape({
  path: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
})
