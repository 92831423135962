import { connect } from 'react-redux'
import { saveShippingAddress, setOrderStatus, setPageMessage } from '../actions'
import { ConfirmAddressForm } from 'shared/address-forms/confirm-address-form'
import { errorMessage } from 'lib/flash-message'

const mapDispatchToProps = (dispatch, props) => {
  return {
    onConfirm: () => {
      return dispatch(
        saveShippingAddress({ ...props.enteredAddress, userConfirmed: true })
      )
        .then(() => {
          dispatch(
            setPageMessage(
              "Thank you for confirming your shipping address. We've released the shipping hold on your order."
            )
          )
          dispatch(setOrderStatus('open'))
          window.scrollTo(0, 0)
          PubSub.publish(BLConstants.FLASH_MESSAGE, {
            variant: 'success',
            message: 'Your shipping address has been updated!',
          })
        })
        .catch(() => {
          errorMessage(
            'Oops something went wrong. We could not update your address.'
          )
        })
    },
  }
}

export default connect(null, mapDispatchToProps)(ConfirmAddressForm)
