import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import pluralize from 'pluralize'
import CartSectionHeader from './cart-section-header'
import ShippingAddress from './shipping-address'

const ExternalReservedItemsHeader = ({
  className,
  count,
  registry,
  storeNames,
}) => {
  let title = storeNames.join(', ')
  if (storeNames && storeNames.length > 2) {
    const firstTwoStores = storeNames.slice(0, 2).join(', ')
    const otherStoresCount = storeNames.length - 2
    title = `${firstTwoStores}, and ${otherStoresCount} other ${pluralize(
      'store',
      otherStoresCount
    )}`
  }

  return (
    <div className={className}>
      <CartSectionHeader className="mtn mbm h2" title={title} count={count} />
      <Row>
        <Col xs={12} md={8}>
          <div className="mvl">
            <p className="xsmall text-uppercase text-bold text-muted">
              How these purchases work
            </p>
            <ul className="h6 mtm mbl pll">
              <li className="mbs">
                Purchase gifts from retailer online or in-store.
              </li>
              <li className="mbs">
                After purchase, return to Babylist to mark the item as
                purchased.
              </li>
              <li className="mbs">
                If you change your mind about a gift, remove it so others can
                purchase it.
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <ShippingAddress registry={registry} alwaysShowAddress />
        </Col>
      </Row>
    </div>
  )
}

ExternalReservedItemsHeader.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  storeNames: PropTypes.arrayOf([PropTypes.string]).isRequired,
  registry: PropTypes.object.isRequired,
}

ExternalReservedItemsHeader.defaultProps = {
  className: undefined,
}

export default ExternalReservedItemsHeader
