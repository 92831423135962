import { Component } from 'react'
import PropTypes from 'prop-types'

import RegItemForm from 'shared/reg-item-form'
import { unableToAddRegItemPath } from 'lib/urls'
import Link from 'components/link'
import { createRegItem } from '../api'
import { errorMessage } from 'lib/flash-message'

class SuggestRegItem extends Component {
  static propTypes = {
    registry: PropTypes.object.isRequired,
    regItem: PropTypes.object.isRequired,
    store: PropTypes.string.isRequired,
  }

  state = { itemAdded: false }

  handleSubmit = (fields) => {
    createRegItem({
      registryId: this.props.registry.id,
      fields,
    })
      .then((res) => {
        this.setState({ itemAdded: true })
      })
      .catch((res) => {
        this.form.clearSubmitStatus()
        errorMessage(
          'Oops, something went wrong. Please try again, or press "No, please add it for me" and a Happiness Hero will add it for you.'
        )
      })
  }

  handleCancel = () => {
    window.location.pathname = unableToAddRegItemPath
  }

  render() {
    if (this.state.itemAdded) {
      return (
        <div className="text-center">
          <p>
            This item has been added to{' '}
            <Link url={this.props.registry.url_slug}>
              {this.props.registry.title}
            </Link>
            .
          </p>
          <Link onClick={() => window.location.replace(this.props.regItem.url)}>
            back to{' '}
            <strong className="text-overflow-ellipsis-fw">
              {this.props.store}
            </strong>
          </Link>
        </div>
      )
    }

    return (
      <div className="mbxl">
        <div className="h3 text-center mtn mbxl">
          Is this the item you are trying to add?
        </div>
        <RegItemForm
          ref={(el) => (this.form = el)}
          submitBtnText="Save Item"
          cancelBtnText="No, please add it for me"
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          regItem={this.props.regItem}
          categories={this.props.registry.categories}
        />
      </div>
    )
  }
}

export default SuggestRegItem
