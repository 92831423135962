import PropTypes from 'prop-types'
import { Fade } from 'react-reveal'

import { useAnimatedScroll } from 'lib/hooks/useAnimatedScroll'

import { FullWidthWave } from './components/wave'

export const DiaperCalculatorResultLayout = ({
  primaryContent,
  secondaryContent,
}) => {
  useAnimatedScroll()

  return (
    <Fade bottom>
      <main>
        <article className="text-center">
          <div className="container-sm">{primaryContent}</div>
          <FullWidthWave className="pbxxl">
            <div className="container-sm">{secondaryContent}</div>
          </FullWidthWave>
        </article>
      </main>
    </Fade>
  )
}

DiaperCalculatorResultLayout.propTypes = {
  primaryContent: PropTypes.node.isRequired,
  secondaryContent: PropTypes.node.isRequired,
}
