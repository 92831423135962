import { Modal } from 'components/modal'
import { ModalBody, ModalHeader } from 'react-bootstrap'
import { InfoButton } from 'components/buttons'
import { newRegistryPath } from 'lib/urls'

interface SignupModalProps {
  show: boolean
  onHide(): void
}

export const SignupModal = ({ show, onHide }: SignupModalProps) => (
  <Modal show={show} size="small">
    <ModalHeader closeButton className="pbn with-title" onHide={onHide}>
      <div className="h2 mtn ptn phl flex-grow text-center text-bold">
        Sign up to save your checklist progress
      </div>
    </ModalHeader>
    <ModalBody className="text-center">
      <div className="h5 mbl text-muted phxl-md">
        Start your Babylist to easily add items from any store and automatically
        track your progress as you go.
      </div>
      <InfoButton pill className="phxl" href={newRegistryPath}>
        Start your Babylist
      </InfoButton>
    </ModalBody>
  </Modal>
)
