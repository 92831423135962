import PropTypes from 'prop-types'
import BSCollapse from 'react-bootstrap/lib/Collapse'

const Collapse = ({
  expanded,
  timeout,
  mountOnEnter,
  unmountOnExit,
  appear,
  dimension,
  children,
}) => {
  return (
    <BSCollapse
      in={expanded}
      timeout={timeout}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      appear={appear}
      dimension={dimension}
    >
      {children}
    </BSCollapse>
  )
}

Collapse.defaultProps = {
  expanded: false,
  timeout: 300,
  mountOnEnter: false,
  unmountOnExit: false,
  appear: false,
  dimension: 'height',
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  timeout: PropTypes.number,
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  appear: PropTypes.bool,
  dimension: PropTypes.string,
}

export default Collapse
