import classNames from 'classnames'
import { InfoButton } from 'components/buttons'
import { newRegistryPath } from 'lib/urls'
import css from './style.scss'

interface StickyCTAProps {
  show: boolean
}

export const StickyCTA = ({ show }: StickyCTAProps) => (
  <div
    className={classNames(
      css.root,
      'animated animate-250ms',
      show ? 'slideInUp' : 'slideOutDown'
    )}
  >
    <div className="text-center">
      <InfoButton empty pill className="phl" href={newRegistryPath}>
        Start your Babylist
      </InfoButton>
    </div>
  </div>
)
