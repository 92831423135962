import React from 'react'

interface OxStickerProps {
  className?: string
}

const OxSticker: React.FC<OxStickerProps> = ({ className }: OxStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1019_6487)">
      <path
        d="M24.5369 61.8545L26.9089 60.4722L30.8347 64.8214L25.0452 64.582L28.3429 63.3689L24.5369 61.8545Z"
        fill="#A4DAF6"
      />
      <path
        d="M96.2888 65.9313L101.711 64.2147L98.9584 63.6948L102.248 61.8544L100.777 61.5697L97.6236 62.6054L96.2888 65.9313Z"
        fill="#A4DAF6"
      />
      <path
        d="M44.2898 45.2912C44.2898 45.2912 24.1441 48.9183 25.0449 38.8747C25.9458 28.8311 40.7731 31.9382 41.3434 17.2896C41.6161 10.2789 37.2026 4.95996 37.2026 4.95996C37.2026 4.95996 39.4548 16.976 30.1485 19.6788C20.8422 22.3815 7.48197 35.8212 14.2385 48.287C20.9951 60.7528 41.3681 57.9014 41.3681 57.9014L44.2898 45.2871V45.2912Z"
        fill="#FFD43F"
      />
      <path
        d="M78.4736 45.2954C78.4736 45.2954 98.6194 48.9225 97.7185 38.8789C96.8176 28.8353 81.9903 31.9424 81.4201 17.2938C81.1432 10.2872 85.5567 4.96826 85.5567 4.96826C85.5567 4.96826 83.3045 16.9843 92.6108 19.6871C101.917 22.3898 115.277 35.8253 108.521 48.2953C101.764 60.7652 81.3911 57.9097 81.3911 57.9097L78.4695 45.2954H78.4736Z"
        fill="#FFD43F"
      />
      <path
        d="M63.7911 42.3574C63.3654 42.3656 62.948 42.2955 62.5224 42.2955C39.8393 42.3244 39.269 41.8333 39.269 54.5302C39.269 65.0236 44.8685 75.3932 47.8108 83.7243C49.2241 87.7352 43.7404 92.1257 44.29 94.1063C45.9802 100.201 77.5852 102.706 81.6392 96.6358C83.1599 94.358 76.7918 88.8122 79.0729 83.6418C82.8748 75.0218 88.2139 63.8558 88.2139 55.0336C88.2139 39.7825 88.3007 41.953 63.7911 42.3574Z"
        fill="#A4DAF6"
      />
      <path
        d="M49.8315 88.9731C45.1991 89.5343 41.7113 93.5328 41.8518 98.1915C41.922 100.535 42.484 102.904 44.1081 104.447C47.9223 108.066 58.7453 108.058 60.9809 108.016C61.2537 108.012 61.5223 108.016 61.795 108.037C64.4274 108.219 79.0728 108.957 82.4656 103.436C82.5606 103.283 82.6474 103.131 82.7301 102.978C85.8088 97.3951 82.0482 90.4834 75.709 89.7324C68.7954 88.9112 57.9436 87.9869 49.8315 88.9731Z"
        fill="#F3D7E8"
      />
      <path
        d="M25.0452 59.3414C25.0452 59.3414 38.8063 60.6908 45.3603 59.3414C45.3603 59.3414 39.608 76.5773 25.0452 59.3414Z"
        fill="#A4DAF6"
      />
      <path
        d="M84.1352 60.2411C83.6847 59.3085 93.9456 62.3084 101.711 60.2411C101.711 60.2411 91.8464 76.1978 84.1352 60.2411Z"
        fill="#A4DAF6"
      />
      <path
        d="M46.6621 65.4321C46.3067 64.9287 52.7244 61.0581 58.8198 65.4321C58.8198 65.4321 51.9557 72.9586 46.6621 65.4321Z"
        fill="#F9F6F3"
      />
      <path
        d="M52.7326 68.7745C54.2275 68.7745 55.4394 67.5644 55.4394 66.0717C55.4394 64.579 54.2275 63.3689 52.7326 63.3689C51.2377 63.3689 50.0259 64.579 50.0259 66.0717C50.0259 67.5644 51.2377 68.7745 52.7326 68.7745Z"
        fill="#6F3264"
      />
      <path
        d="M67.6013 65.4321C67.2459 64.9287 73.6636 61.0581 79.759 65.4321C79.759 65.4321 72.895 72.9586 67.6013 65.4321Z"
        fill="#F9F6F3"
      />
      <path
        d="M73.676 68.7745C75.1709 68.7745 76.3828 67.5644 76.3828 66.0717C76.3828 64.579 75.1709 63.3689 73.676 63.3689C72.1811 63.3689 70.9692 64.579 70.9692 66.0717C70.9692 67.5644 72.1811 68.7745 73.676 68.7745Z"
        fill="#6F3264"
      />
      <path
        d="M50.6706 62.6755C51.0797 62.5971 51.5218 62.5683 51.9434 62.4486C52.0591 62.4115 52.2368 62.4238 52.3731 62.4403C52.7946 62.4981 53.2038 62.5559 53.6211 62.6136C53.6831 62.6219 53.7492 62.6467 53.8071 62.6467C54.4063 62.6467 54.9559 62.8942 55.5097 63.0758C55.9023 63.1996 56.2618 63.4018 56.6668 63.5173C56.8858 63.5875 57.1255 63.7278 57.3362 63.8639C57.985 64.2642 58.5594 64.78 59.0181 65.3783C59.1628 65.564 59.2661 65.7414 59.2371 65.8075C59.2206 65.8487 59.3198 66.0097 59.3033 66.0385C59.2578 66.1169 58.9561 65.9808 58.4561 65.7167C58.233 65.597 58.0222 65.4567 57.8032 65.3371C57.3858 65.1019 56.9478 64.9368 56.5634 64.6479C56.4229 64.553 56.2287 64.4746 56.0469 64.4169C55.5427 64.2353 55.0592 64.0331 54.5551 63.8722C54.3939 63.8268 54.2286 63.7608 54.0633 63.7484C53.6294 63.6989 53.2038 63.6576 52.7698 63.6287C52.5632 63.6081 52.3649 63.6164 52.1541 63.5875C51.9351 63.571 51.7037 63.604 51.4764 63.6287C50.8772 63.6865 50.2945 63.8227 49.6953 63.93C49.0382 64.1775 48.3192 64.3674 47.7034 64.7222C47.5175 64.8336 47.2737 64.9285 47.0175 65.0111C46.4224 65.2009 46.3563 65.2463 46.4926 65.0276C46.534 64.9616 46.5753 64.8914 46.6166 64.8254C46.6827 64.714 46.786 64.6067 47.0009 64.4416C47.3067 64.2271 47.596 63.9836 47.9018 63.769C48.1456 63.5916 48.3812 63.4224 48.6952 63.3234C48.8068 63.2904 48.9184 63.2202 49.03 63.1707C49.53 62.9272 50.0755 62.7498 50.6706 62.6838V62.6755Z"
        fill="#6F3264"
      />
      <path
        d="M71.4318 62.9149C71.7996 62.8365 72.2005 62.8077 72.5807 62.6756C72.684 62.6385 72.8451 62.6426 72.9691 62.6591C73.3493 62.6962 73.7212 62.754 74.1055 62.7829C74.1634 62.787 74.2254 62.8077 74.275 62.8035C74.8163 62.7746 75.3246 62.981 75.837 63.113C76.2007 63.2038 76.5354 63.373 76.9115 63.4473C77.1181 63.4968 77.3371 63.6082 77.5396 63.7196C78.1512 64.0414 78.705 64.4665 79.1637 64.9781C79.3042 65.1391 79.4158 65.2917 79.391 65.3578C79.3786 65.399 79.4777 65.5393 79.4653 65.5682C79.424 65.6466 79.1348 65.5641 78.6513 65.3784C78.4364 65.2917 78.2339 65.1886 78.0272 65.0978C77.6305 64.9204 77.2173 64.8131 76.8536 64.5738C76.7214 64.4954 76.5396 64.4376 76.3743 64.3963C75.9073 64.2684 75.461 64.1075 74.994 63.9837C74.8453 63.9465 74.6924 63.8929 74.5436 63.8929C74.1469 63.8723 73.7584 63.8558 73.3658 63.8351C73.1799 63.8227 72.998 63.8351 72.8038 63.8145C72.6055 63.8021 72.3947 63.8393 72.1922 63.8681C71.6509 63.93 71.126 64.0621 70.5806 64.157C69.9896 64.3922 69.3326 64.549 68.7705 64.8585C68.597 64.9575 68.3738 65.0359 68.1383 65.0978C67.5845 65.2422 67.5225 65.2794 67.6465 65.0772C67.6837 65.0153 67.7209 64.9493 67.7581 64.8915C67.8201 64.7883 67.9151 64.6893 68.1093 64.5449C68.3903 64.3509 68.6507 64.1322 68.9276 63.9342C69.1466 63.7732 69.3615 63.6164 69.6466 63.5256C69.7499 63.4968 69.8491 63.4307 69.9524 63.3854C70.4029 63.1584 70.8946 62.9892 71.436 62.9232L71.4318 62.9149Z"
        fill="#6F3264"
      />
      <path
        d="M63.8157 50.5276C63.8157 50.8907 63.7744 51.2745 63.8612 51.65C63.8859 51.749 63.8612 51.9017 63.8405 52.0172C63.7702 52.3721 63.7083 52.7228 63.6876 53.0901C63.6876 53.1437 63.6669 53.2015 63.6711 53.251C63.7289 53.7627 63.5884 54.2578 63.5678 54.7613C63.5512 55.1161 63.4892 55.4669 63.5347 55.8259C63.5595 56.0239 63.5264 56.2591 63.5099 56.4737C63.452 57.1257 63.3529 57.7776 63.2082 58.4337C63.1586 58.6359 63.109 58.8134 63.0388 58.8381C62.9975 58.8505 62.9479 59.0197 62.9148 59.0279C62.8322 59.0486 62.7041 58.768 62.5801 58.2439C62.5305 58.0087 62.514 57.7694 62.4892 57.5301C62.4437 57.0762 62.3693 56.6181 62.4561 56.1601C62.4892 55.995 62.4726 55.797 62.4644 55.6154C62.4437 55.0955 62.5057 54.588 62.5594 54.0804C62.576 53.9195 62.609 53.7503 62.6008 53.5935C62.5842 53.1726 62.6008 52.7558 62.6379 52.3391C62.6545 52.141 62.6627 51.9471 62.6999 51.7449C62.7371 51.5386 62.7206 51.3116 62.733 51.0929C62.7578 50.5193 62.7619 49.9416 62.8404 49.3681C62.8032 48.6955 62.919 47.994 62.9107 47.3214C62.9107 47.1151 62.9479 46.8675 63.0223 46.624C63.2 46.0587 63.2124 45.9968 63.3115 46.2155C63.3446 46.2815 63.3735 46.3476 63.4025 46.4136C63.452 46.5209 63.4934 46.6529 63.5099 46.8964C63.5388 47.2347 63.6008 47.569 63.6669 47.895C63.7165 48.1591 63.7744 48.4108 63.7537 48.6996C63.7454 48.8028 63.7661 48.9183 63.7785 49.0256C63.8364 49.5125 63.8612 50.0077 63.8074 50.5276H63.8157Z"
        fill="#6F3264"
      />
      <path
        d="M51.4392 73.3754C51.7409 73.4043 52.0673 73.4703 52.3814 73.363C52.464 73.3383 52.588 73.3507 52.6872 73.363C52.9847 73.4084 53.2864 73.4208 53.5963 73.3754C53.6418 73.3672 53.6914 73.3754 53.7327 73.3548C54.1336 73.1939 54.5716 73.1856 54.9766 73.0288C55.2576 72.9257 55.5551 72.8307 55.7989 72.6368C55.9312 72.5254 56.113 72.447 56.2742 72.3562C56.7659 72.088 57.2453 71.7909 57.7412 71.4649C57.9024 71.37 58.0346 71.2751 58.1049 71.304C58.1462 71.3205 58.2784 71.2338 58.3115 71.2462C58.3569 71.2668 58.3528 71.3411 58.3197 71.4732C58.2867 71.6052 58.2206 71.7991 58.0842 72.0178C57.9602 72.2118 57.8115 72.3892 57.6544 72.5584C57.3569 72.8844 57.0469 73.2186 56.6213 73.3878C56.4684 73.4414 56.3155 73.5652 56.1626 73.656C55.7328 73.9242 55.2535 74.081 54.7658 74.1924C54.6088 74.2255 54.4476 74.2543 54.2989 74.2997C53.9104 74.4318 53.5013 74.5019 53.088 74.5184C52.8897 74.5308 52.6996 74.5308 52.5012 74.5102C52.2987 74.4772 52.0756 74.5019 51.8607 74.4772C51.2987 74.4277 50.7284 74.341 50.2077 74.1099C49.5548 73.9696 48.9639 73.5776 48.4018 73.2475C48.2283 73.1402 48.0464 72.971 47.9059 72.7688C47.7365 72.5378 47.6663 72.3933 47.6456 72.3315C47.6291 72.2696 47.6704 72.2778 47.782 72.3026C47.8481 72.3191 47.9183 72.3314 47.9803 72.348C48.0878 72.3768 48.2117 72.4099 48.3936 72.5254C48.6622 72.6739 48.9473 72.7771 49.2325 72.8472C49.4597 72.9091 49.6829 72.9422 49.9061 73.0494C49.9887 73.0866 50.0837 73.1072 50.1788 73.1196C50.5962 73.1939 51.0053 73.264 51.4309 73.3672L51.4392 73.3754Z"
        fill="#6F3264"
      />
      <path
        d="M70.5766 74.3037C70.8452 74.4729 71.1303 74.671 71.4609 74.6957C71.5477 74.704 71.6593 74.7659 71.7461 74.8154C72.0064 74.9681 72.2916 75.0836 72.5932 75.1208C72.6387 75.1249 72.6842 75.1455 72.7255 75.1414C73.1387 75.0919 73.5561 75.1579 73.9776 75.03C74.2669 74.9475 74.5768 74.8691 74.833 74.671C74.9735 74.5637 75.1678 74.4812 75.3413 74.3821C75.8661 74.1015 76.3992 73.7797 76.9695 73.4785C77.1513 73.3877 77.3125 73.3134 77.3745 73.3464C77.4117 73.367 77.5646 73.2969 77.5935 73.3175C77.6596 73.367 77.5522 73.6435 77.2423 74.0727C77.0976 74.2583 76.9323 74.4275 76.7629 74.5967C76.4406 74.9144 76.1141 75.2569 75.6637 75.4302C75.5066 75.4963 75.3331 75.6118 75.1719 75.7067C74.7008 75.9749 74.1594 76.1193 73.6098 76.1689C73.4363 76.1895 73.2503 76.173 73.0809 76.1977C72.6263 76.2431 72.1635 76.1854 71.7337 76.0492C71.5271 75.9873 71.3328 75.9172 71.1427 75.8099C70.9485 75.6985 70.7254 75.6201 70.5229 75.5045C69.9939 75.2074 69.4939 74.8484 69.1096 74.3904C68.5682 73.953 68.1922 73.3299 67.7831 72.81C67.655 72.6449 67.5434 72.4262 67.4649 72.191C67.2872 71.6381 67.2665 71.5845 67.4649 71.7C67.5268 71.733 67.593 71.7701 67.6508 71.8032C67.7541 71.8609 67.8574 71.9393 68.0145 72.1168C68.2294 72.3643 68.4773 72.5789 68.7211 72.777C68.9195 72.9379 69.1137 73.0782 69.2831 73.2845C69.3451 73.3588 69.4319 73.4207 69.5104 73.4826C69.8741 73.7549 70.2212 74.019 70.5766 74.3037Z"
        fill="#6F3264"
      />
      <path
        d="M58.0799 59.7417C59.0675 60.0636 60.0593 60.5257 61.0263 60.7032C61.2867 60.7527 61.6668 60.8641 61.9602 60.9384C62.4065 61.0539 62.8529 61.1076 63.3116 61.1158C63.7703 61.1158 64.2414 61.0539 64.7166 60.9425C64.8571 60.9054 65.0141 60.8806 65.1381 60.8393C66.415 60.3854 67.7292 59.8696 69.097 59.3621C70.0557 58.9949 71.1012 58.7638 72.1509 58.5905C72.7336 58.4832 73.4402 58.4956 74.0807 58.5368C76.0189 58.6771 77.9074 59.3415 79.4695 60.4391C79.7174 60.6041 79.9282 60.7816 80.0852 60.9219C80.2422 61.0622 80.3414 61.1736 80.3456 61.2231C80.3538 61.2809 80.701 61.611 80.6968 61.6522C80.6803 61.7678 80.0232 61.3056 78.7091 60.7321C78.1264 60.4639 77.5107 60.2452 76.895 60.0677C75.7089 59.7335 74.4981 59.6427 73.2708 59.5808C73.0518 59.5767 72.808 59.5849 72.56 59.6056C72.3121 59.6427 72.06 59.6675 71.8203 59.717C70.4648 59.9563 69.1797 60.4597 67.8407 60.9796C67.4068 61.1447 66.9605 61.3139 66.539 61.4748C65.4315 61.9163 64.2166 62.2795 62.9272 62.2258C62.3198 62.2093 61.7371 62.1103 61.1668 61.937C60.5759 61.7595 59.9519 61.5656 59.3733 61.3428C58.6047 61.0539 57.8608 60.7527 57.1129 60.5422C56.3649 60.3153 55.5963 60.1668 54.8152 60.0677C52.9969 59.9522 51.0381 60.0925 49.3066 60.7238C48.7653 60.9054 48.1372 61.153 47.5132 61.3923C46.0751 62.0154 45.9387 62.168 46.3891 61.7306C46.4966 61.6316 46.6371 61.4872 46.8106 61.3551C47.0503 61.1571 47.352 60.9343 47.9636 60.6207C48.8231 60.1956 49.7034 59.8284 50.6042 59.5602C51.3233 59.3539 52.0341 59.164 52.8688 59.1393C53.1705 59.1228 53.497 59.1021 53.8028 59.0815C55.2078 59.0361 56.6624 59.2218 58.0881 59.7459L58.0799 59.7417Z"
        fill="#6F3264"
      />
      <path
        d="M53.1461 98.1997C52.3691 98.3813 51.9146 99.2272 51.8815 100.024C51.8319 101.105 52.4311 102.202 53.4023 102.689C54.3734 103.176 55.6545 102.97 56.3776 102.169C57.1008 101.369 57.1463 100.015 56.4314 99.2065C55.6297 98.2987 54.2494 98.241 53.0469 98.3565"
        fill="#6F3264"
      />
      <path
        d="M53.46 102.083C53.7451 102.256 54.1088 102.38 54.4518 102.277C54.5427 102.248 54.6791 102.26 54.7865 102.256C55.113 102.264 55.456 102.173 55.7205 101.971C55.7576 101.938 55.8072 101.914 55.8362 101.881C55.9725 101.695 56.0924 101.526 56.1957 101.323C56.299 101.121 56.3775 100.89 56.3775 100.655C56.3775 100.329 56.3403 99.97 56.113 99.743C55.9973 99.6234 55.8734 99.4583 55.7163 99.3304C55.2576 98.9425 54.5303 98.7445 53.7699 98.6207C53.5302 98.5835 53.3195 98.5464 53.2823 98.4762C53.2616 98.435 53.055 98.4061 53.0426 98.3731C53.0261 98.3277 53.1005 98.2782 53.2575 98.1998C53.4145 98.1296 53.6584 98.0347 53.9931 97.9893C54.2906 97.9439 54.6047 97.9439 54.9188 97.9728C55.5097 98.0223 56.204 98.1956 56.6503 98.7362C56.8073 98.9136 57.0098 99.1488 57.142 99.3841C57.5346 100.065 57.5222 100.878 57.2577 101.567C57.1709 101.79 57.047 102 56.9271 102.202C56.6131 102.743 56.0758 103.127 55.4932 103.304C55.2163 103.391 54.9394 103.44 54.646 103.428C54.3485 103.416 54.022 103.411 53.7162 103.329C52.8939 103.123 52.1872 102.537 51.8401 101.798C51.3153 100.997 51.2698 99.8833 51.6624 98.9879C51.7822 98.7114 52.0219 98.402 52.3484 98.2245C52.7327 98.0182 52.9806 98.0347 53.0633 98.043C53.1501 98.0554 53.0963 98.0884 52.9806 98.1833C52.9269 98.2328 52.8608 98.2906 52.7988 98.369C52.712 98.4762 52.6087 98.5918 52.4558 98.8105C52.2409 99.1241 52.1789 99.5161 52.2037 99.8627C52.2244 100.139 52.307 100.395 52.3566 100.688C52.3773 100.795 52.431 100.903 52.4847 100.997C52.7286 101.435 53.0591 101.802 53.4517 102.087L53.46 102.083Z"
        fill="#6F3264"
      />
      <path
        d="M69.3659 98.8557C68.56 99.0208 67.9773 99.8296 67.9567 100.651C67.936 101.472 68.4237 102.256 69.1096 102.71C69.7956 103.164 70.6469 103.316 71.4651 103.25C72.5602 103.164 73.738 102.578 74.0273 101.517C74.3 100.519 73.6595 99.4458 72.771 98.9094C71.8825 98.373 70.8122 98.2615 69.775 98.212C69.4526 98.1955 69.1055 98.1914 68.8245 98.3523C68.5435 98.5133 68.3617 98.9011 68.5394 99.1735"
        fill="#6F3264"
      />
      <path
        d="M70.5683 102.673C71.0105 102.722 71.5312 102.722 71.9279 102.504C72.0312 102.442 72.2089 102.405 72.337 102.363C72.7378 102.248 73.1098 102.029 73.3081 101.707C73.337 101.658 73.3784 101.616 73.3908 101.563C73.461 101.315 73.4899 101.084 73.4693 100.824C73.4445 100.564 73.3494 100.288 73.18 100.044C72.9362 99.7059 72.6387 99.3964 72.2048 99.2561C71.9733 99.1777 71.7006 99.0622 71.4237 98.9838C71.0105 98.8641 70.5683 98.7816 70.1096 98.7073C69.6344 98.6371 69.1922 98.5381 68.9401 98.6495C68.7665 98.7197 68.6798 98.893 68.6178 98.9136C68.5971 98.9177 68.5723 98.9714 68.5641 99.0291C68.5558 99.0869 68.5723 99.1447 68.5599 99.1612C68.5393 99.1983 68.4112 99.1612 68.345 98.9095C68.3202 98.7898 68.3244 98.6248 68.4029 98.4515C68.4731 98.2823 68.655 98.0842 68.8781 97.981C69.2955 97.7912 69.6964 97.8242 70.0311 97.8077C70.7005 97.7954 71.4196 97.7788 72.1014 98.0801C72.3411 98.1832 72.6511 98.2864 72.9155 98.4226C73.6966 98.8187 74.2834 99.5491 74.4859 100.428C74.5479 100.709 74.5561 101.014 74.5355 101.299C74.5148 101.678 74.3867 102.058 74.1759 102.372C73.9652 102.689 73.6842 102.941 73.3825 103.135C73.0932 103.321 72.7957 103.457 72.4734 103.539C72.1386 103.622 71.7832 103.717 71.4237 103.741C70.5104 103.807 69.498 103.642 68.7211 103.007C68.2376 102.689 67.8574 102.198 67.6549 101.629C67.4483 101.068 67.4483 100.399 67.6673 99.8379C67.7954 99.4872 68.0847 99.1241 68.4607 98.9136C68.9029 98.6701 69.1881 98.6867 69.2872 98.6949C69.3905 98.7073 69.3244 98.7403 69.1839 98.8393C69.1178 98.8888 69.0352 98.959 68.9566 99.0415C68.8492 99.1653 68.7128 99.2891 68.5227 99.5532C68.2541 99.9246 68.188 100.399 68.2789 100.783C68.3533 101.101 68.5062 101.365 68.6798 101.666C68.7418 101.777 68.8451 101.872 68.9401 101.955C69.3864 102.339 69.936 102.549 70.5724 102.673H70.5683Z"
        fill="#6F3264"
      />
      <path
        d="M55.8775 115.675C57.1255 116.405 58.6421 117.041 60.2001 117.131C60.6133 117.16 61.2332 117.309 61.7167 117.416C63.1754 117.738 64.7003 117.887 66.2252 117.688C66.4525 117.66 66.7004 117.647 66.8988 117.598C67.9195 117.317 68.9113 117.028 69.8659 116.645C70.8205 116.257 71.7048 115.757 72.4817 115.085C73.5479 114.144 74.5562 113.03 74.9901 111.668C75.2298 110.929 75.5356 110.075 75.6307 109.25C75.9695 106.844 74.8083 104.158 72.7917 102.73C72.1718 102.276 71.5685 102.054 71.5933 101.645C71.6263 101.398 70.9816 101.092 70.9899 100.799C71.0106 100.436 71.3577 100.164 72.0768 100.135C72.2544 100.135 72.4528 100.147 72.6718 100.176C72.8867 100.222 73.1181 100.283 73.3702 100.366C73.6182 100.453 73.8702 100.581 74.143 100.717C74.3992 100.873 74.6802 101.047 74.9447 101.261C75.9075 102.029 76.6679 103.04 77.2465 104.117C77.8002 105.144 78.2011 106.267 78.354 107.447C78.511 108.635 78.387 109.852 77.9944 110.958C77.7176 111.747 77.3911 112.704 76.9696 113.513C75.7629 115.827 73.7339 117.556 71.4527 118.613C70.7296 118.955 69.9403 119.203 69.2171 119.459C67.2955 120.14 65.2458 120.362 63.2292 120.251C62.2704 120.197 61.3447 120.098 60.3984 119.871C59.4273 119.64 58.3611 119.442 57.3528 119.1C56.0263 118.642 54.7204 118.039 53.5344 117.185C52.3525 116.339 51.307 115.209 50.6127 113.896C49.6458 112.46 49.1209 110.703 49.0341 108.932C48.935 107.15 49.2821 105.359 49.8772 103.712C50.2367 102.681 50.9309 101.538 51.869 100.675C52.4104 100.168 52.8815 99.8295 53.2493 99.6108C53.617 99.388 53.8857 99.2848 54.051 99.2642C54.3898 99.2229 54.3361 99.5407 54.146 100.151C54.0634 100.453 53.927 100.783 53.7865 101.142C53.5923 101.649 53.2658 102.12 52.6707 102.97C51.8525 104.166 51.4599 105.582 51.3814 106.915C51.3194 107.987 51.4723 108.982 51.6459 110.117C51.7037 110.529 51.8855 110.954 52.0632 111.338C52.8815 113.104 54.1295 114.515 55.8775 115.679V115.675Z"
        fill="#FFD43F"
      />
      <path
        d="M33.5497 55.4916C33.5538 55.2935 33.6076 55.0872 33.529 54.8685C33.5084 54.8108 33.529 54.7282 33.558 54.6663C33.6365 54.4806 33.7109 54.295 33.7522 54.0969C33.7563 54.068 33.777 54.0391 33.7729 54.0102C33.7439 53.7173 33.9134 53.4697 33.9753 53.2015C34.0167 53.0117 34.12 52.8342 34.1117 52.6279C34.1117 52.5124 34.1696 52.3968 34.215 52.2854C34.3597 51.9512 34.5539 51.6334 34.806 51.3446C34.8886 51.2579 34.9671 51.1837 35.0374 51.196C35.0787 51.2043 35.1531 51.1341 35.1862 51.1424C35.2647 51.163 35.3391 51.3487 35.3638 51.6499C35.3721 51.782 35.3473 51.9099 35.3391 52.0337C35.3143 52.2772 35.3267 52.5247 35.1903 52.7311C35.1407 52.8053 35.1324 52.9126 35.1242 53.0075C35.0911 53.2757 34.9961 53.5233 34.9051 53.775C34.8762 53.8576 34.839 53.936 34.8349 54.0226C34.8266 54.2454 34.7936 54.4559 34.7481 54.6705C34.7275 54.7736 34.7109 54.8726 34.6696 54.9717C34.6283 55.0748 34.6407 55.1945 34.6241 55.31C34.5869 55.6113 34.5787 55.9084 34.4919 56.2055C34.5208 56.5603 34.3969 56.9235 34.3969 57.2742C34.3969 57.3856 34.3555 57.5135 34.2811 57.6373C34.1034 57.9344 34.0911 57.9674 33.9919 57.8519C33.9671 57.823 33.934 57.7859 33.901 57.7446C33.8555 57.6868 33.81 57.6126 33.7935 57.4847C33.7729 57.3031 33.7067 57.1215 33.6489 56.94C33.6034 56.7955 33.5456 56.6552 33.5704 56.4984C33.5786 56.4407 33.5621 56.3788 33.5497 56.321C33.496 56.0528 33.4836 55.7763 33.5497 55.4999V55.4916Z"
        fill="#6F3264"
      />
      <path
        d="M19.8673 52.368C19.9375 52.2318 20.0532 52.1163 20.0574 51.9265C20.0574 51.8728 20.107 51.8316 20.1483 51.8027C20.2764 51.7119 20.4045 51.6211 20.4995 51.5015C20.5119 51.485 20.5409 51.4726 20.545 51.4519C20.6153 51.2332 20.8384 51.1425 20.9789 50.9815C21.0781 50.866 21.2145 50.7958 21.2764 50.6432C21.3136 50.5606 21.4004 50.507 21.4707 50.4492C21.6897 50.2759 21.9624 50.1645 22.2517 50.0779C22.3509 50.0613 22.4377 50.0531 22.4914 50.0944C22.5244 50.1232 22.5988 50.1067 22.6278 50.1315C22.6608 50.1645 22.6691 50.214 22.6691 50.2924C22.665 50.3667 22.665 50.4864 22.6195 50.5937C22.5782 50.6886 22.5286 50.7711 22.4831 50.8577C22.3881 51.0187 22.3261 51.2044 22.1484 51.2786C22.0864 51.3075 22.0451 51.3777 22.0079 51.4396C21.8963 51.6129 21.7393 51.7325 21.5864 51.8646C21.5368 51.9058 21.4789 51.943 21.45 52.0007C21.3756 52.1493 21.2806 52.2813 21.169 52.4051C21.1194 52.4629 21.074 52.5248 21.0078 52.5743C20.9376 52.6238 20.9087 52.7105 20.8591 52.7806C20.7268 52.9663 20.6235 53.1644 20.45 53.3212C20.359 53.577 20.1235 53.7545 19.9995 53.9897C19.9541 54.0598 19.8879 54.13 19.7722 54.1754C19.4995 54.2826 19.4954 54.3074 19.4458 54.1795C19.4334 54.1465 19.421 54.1093 19.4044 54.0639C19.3838 54.002 19.3714 53.9319 19.4044 53.837C19.454 53.7049 19.454 53.5481 19.4664 53.3996C19.4747 53.2799 19.4747 53.1561 19.5491 53.0612C19.578 53.0282 19.5821 52.9746 19.5945 52.9292C19.64 52.7187 19.7226 52.5248 19.8714 52.3639L19.8673 52.368Z"
        fill="#6F3264"
      />
      <path
        d="M30.2191 47.8208C30.1488 47.9569 30.0372 48.0766 30.0414 48.2705C30.0414 48.3242 29.9959 48.3696 29.9546 48.3985C29.8306 48.4975 29.7108 48.5965 29.624 48.7245C29.6116 48.7451 29.5868 48.7575 29.5827 48.7781C29.5331 49.005 29.3182 49.1206 29.1984 49.298C29.1116 49.4218 28.9876 49.5208 28.9504 49.6776C28.9297 49.7643 28.8512 49.8344 28.7892 49.9046C28.6033 50.115 28.3553 50.2842 28.0909 50.441C27.9999 50.4823 27.9132 50.5153 27.8512 50.4864C27.814 50.4699 27.7396 50.5029 27.7107 50.4864C27.6693 50.4617 27.6487 50.4163 27.6363 50.3379C27.6239 50.2636 27.5991 50.1398 27.6239 50.0201C27.6487 49.917 27.6817 49.8138 27.7107 49.7148C27.7809 49.5291 27.814 49.3187 27.9875 49.2031C28.0495 49.1618 28.0785 49.0793 28.1115 49.0092C28.2024 48.807 28.3553 48.6502 28.5 48.4892C28.5454 48.4397 28.6033 48.3902 28.6281 48.3283C28.6942 48.1591 28.7892 48.0106 28.8967 47.8662C28.9463 47.8001 28.9917 47.73 29.062 47.6722C29.1322 47.6145 29.1653 47.5195 29.2149 47.4411C29.3513 47.239 29.467 47.0203 29.6571 46.8511C29.7686 46.5787 30.0207 46.3889 30.1736 46.1455C30.2273 46.0753 30.3059 46.001 30.4298 45.9598C30.7191 45.8649 30.7274 45.8401 30.7604 45.9763C30.7687 46.0093 30.777 46.0506 30.7894 46.0959C30.8018 46.162 30.8059 46.2321 30.7646 46.327C30.7026 46.4591 30.686 46.62 30.6654 46.7727C30.6489 46.8965 30.6406 47.0203 30.5621 47.111C30.5331 47.144 30.5249 47.1977 30.5125 47.2431C30.4588 47.4535 30.372 47.6475 30.2273 47.8166L30.2191 47.8208Z"
        fill="#6F3264"
      />
      <path
        d="M23.1896 42.4441C23.0656 42.4977 22.9251 42.5101 22.8218 42.6545C22.7929 42.6917 22.7351 42.6958 22.6896 42.6875C22.5491 42.671 22.4127 42.6628 22.2805 42.6875C22.2598 42.6917 22.235 42.6875 22.2226 42.6958C22.0573 42.8196 21.8507 42.7494 21.6689 42.7824C21.5408 42.8072 21.4044 42.7824 21.2804 42.8608C21.2143 42.9021 21.1234 42.8938 21.0407 42.8897C20.7969 42.8897 20.5448 42.8113 20.2969 42.7082C20.2184 42.6628 20.1523 42.6174 20.1357 42.5514C20.1275 42.5101 20.0655 42.4771 20.0572 42.4399C20.049 42.3904 20.0696 42.3492 20.1109 42.2873C20.1523 42.2295 20.2142 42.1346 20.301 42.081C20.3795 42.0314 20.4581 41.9943 20.5366 41.953C20.6853 41.8829 20.83 41.7756 20.9953 41.8292C21.0531 41.8457 21.1193 41.8127 21.1771 41.7921C21.3465 41.7261 21.5242 41.7302 21.7019 41.7261C21.7598 41.7261 21.8176 41.7343 21.8714 41.7055C22.0036 41.6394 22.1441 41.5982 22.2846 41.5734C22.3507 41.561 22.4169 41.5445 22.4912 41.5486C22.5656 41.5569 22.6359 41.5074 22.7103 41.4909C22.9045 41.4372 23.0863 41.3547 23.2929 41.3547C23.4996 41.2268 23.7599 41.2474 23.9789 41.1608C24.0492 41.1401 24.136 41.1319 24.2434 41.1773C24.4913 41.2846 24.512 41.2681 24.4748 41.3918C24.4666 41.4249 24.4542 41.462 24.4418 41.5033C24.4211 41.561 24.3922 41.6188 24.3178 41.6683C24.2103 41.7302 24.1236 41.8457 24.0326 41.9448C23.9624 42.0273 23.8921 42.114 23.7888 42.1346C23.7516 42.1428 23.7186 42.1759 23.6855 42.2006C23.5367 42.3244 23.3756 42.4152 23.1855 42.4399L23.1896 42.4441Z"
        fill="#6F3264"
      />
      <path
        d="M15.466 35.3507C15.6519 35.3466 15.8503 35.3878 16.0487 35.2929C16.1024 35.2682 16.1809 35.2888 16.2387 35.3094C16.4164 35.3755 16.5983 35.4332 16.7884 35.458C16.8173 35.458 16.8462 35.4786 16.871 35.4704C17.1438 35.4167 17.3876 35.557 17.6479 35.5942C17.8297 35.6189 18.0074 35.6973 18.1975 35.6643C18.305 35.6478 18.4207 35.6932 18.5323 35.7262C18.8629 35.8252 19.1811 35.9738 19.4827 36.176C19.5736 36.2461 19.6522 36.3121 19.6522 36.3823C19.6522 36.4236 19.7265 36.4855 19.7224 36.5226C19.7141 36.6051 19.5488 36.7042 19.2637 36.7743C19.1397 36.7991 19.0158 36.7949 18.8918 36.8032C18.6562 36.8114 18.4207 36.8568 18.2099 36.7454C18.1355 36.7083 18.0322 36.7124 17.9413 36.7124C17.681 36.7124 17.4372 36.6381 17.1851 36.5762C17.1066 36.5556 17.0239 36.5267 16.9454 36.5308C16.7346 36.5432 16.5239 36.5308 16.3173 36.4978C16.2181 36.4855 16.123 36.4772 16.0239 36.4442C15.9205 36.4112 15.8048 36.4277 15.6974 36.4194C15.4081 36.3988 15.1188 36.4071 14.8296 36.3287C14.4866 36.374 14.1395 36.2544 13.8006 36.2626C13.6973 36.2626 13.5733 36.2255 13.4493 36.1512C13.1642 35.9738 13.1353 35.9614 13.2468 35.8665C13.2716 35.8417 13.3088 35.8087 13.3502 35.7757C13.408 35.7303 13.4741 35.6849 13.5981 35.6684C13.7717 35.6437 13.9411 35.5777 14.1147 35.5158C14.251 35.4662 14.3833 35.4043 14.5279 35.425C14.5816 35.4332 14.6395 35.4126 14.6973 35.4002C14.9494 35.3383 15.2056 35.3136 15.4701 35.359L15.466 35.3507Z"
        fill="#6F3264"
      />
      <path
        d="M27.252 32.2271C27.1446 32.1982 27.0454 32.128 26.8966 32.1817C26.8553 32.1982 26.8181 32.1652 26.7933 32.1363C26.7148 32.0455 26.6321 31.963 26.5288 31.9176C26.5123 31.9093 26.504 31.8928 26.4834 31.8928C26.3057 31.9052 26.2106 31.7402 26.066 31.6783C25.9668 31.6329 25.8883 31.5462 25.7602 31.5503C25.6899 31.5503 25.6362 31.5008 25.5783 31.4637C25.4089 31.3481 25.2725 31.1666 25.1403 30.9685C25.1072 30.8942 25.0783 30.8241 25.099 30.7622C25.1114 30.7251 25.0825 30.6632 25.099 30.6302C25.1155 30.5848 25.1527 30.5559 25.2147 30.5187C25.2725 30.4899 25.3676 30.4321 25.4626 30.4238C25.5453 30.4156 25.6238 30.4238 25.7065 30.4238C25.8552 30.4362 26.0164 30.4115 26.1114 30.5394C26.1445 30.5848 26.2106 30.5889 26.2685 30.5971C26.4296 30.626 26.5536 30.7251 26.6858 30.8158C26.7272 30.8447 26.7644 30.886 26.8181 30.8901C26.9503 30.9066 27.0702 30.952 27.1859 31.0139C27.2396 31.0428 27.2975 31.0634 27.3429 31.1129C27.3925 31.1625 27.4669 31.1666 27.5289 31.1913C27.6942 31.2615 27.8636 31.311 28.0041 31.4389C28.219 31.4678 28.376 31.6494 28.5703 31.7278C28.6281 31.7567 28.686 31.8103 28.7232 31.9135C28.8058 32.1528 28.8265 32.1528 28.719 32.2229C28.686 32.2436 28.6529 32.2642 28.624 32.2807C28.5744 32.3096 28.5165 32.3344 28.4421 32.3178C28.3388 32.2972 28.2107 32.3261 28.0909 32.3426C27.9958 32.3591 27.8967 32.3839 27.8223 32.3385C27.7933 32.322 27.7562 32.3261 27.719 32.3261C27.5537 32.3343 27.3966 32.3055 27.2644 32.2188L27.252 32.2271Z"
        fill="#6F3264"
      />
      <path
        d="M24.1402 24.3333C24.2931 24.3581 24.446 24.4282 24.6279 24.3622C24.6775 24.3457 24.7353 24.3746 24.7808 24.4035C24.913 24.4901 25.0494 24.5727 25.1982 24.6139C25.2188 24.6222 25.2395 24.6387 25.2643 24.6387C25.4957 24.618 25.6734 24.7831 25.88 24.8409C26.0246 24.8821 26.1569 24.9729 26.3222 24.9564C26.4131 24.9482 26.504 25.0018 26.5867 25.0389C26.847 25.1504 27.0867 25.3319 27.3181 25.5341C27.3884 25.6084 27.4462 25.6785 27.4379 25.7446C27.4338 25.7858 27.4875 25.8436 27.4834 25.8807C27.4751 25.9302 27.4338 25.9591 27.3636 26.0004C27.2933 26.0334 27.1859 26.0953 27.066 26.1118C26.9586 26.1242 26.8511 26.1242 26.7478 26.1242C26.5495 26.1159 26.3387 26.1489 26.1734 26.021C26.1156 25.9756 26.0246 25.9756 25.9461 25.9674C25.723 25.9426 25.5288 25.8477 25.3263 25.7611C25.2643 25.7322 25.1982 25.695 25.132 25.6909C24.9502 25.6785 24.7766 25.6373 24.6072 25.5795C24.5246 25.5506 24.446 25.53 24.3717 25.4846C24.2931 25.4351 24.194 25.4351 24.1031 25.4103C23.8634 25.3443 23.6196 25.303 23.4005 25.1792C23.1071 25.1586 22.8427 24.977 22.5658 24.9069C22.4831 24.878 22.3839 24.8285 22.3096 24.7212C22.1277 24.4778 22.1029 24.4778 22.2228 24.3994C22.2517 24.3787 22.2889 24.3581 22.3302 24.3333C22.3881 24.3003 22.4542 24.2714 22.5575 24.2838C22.7021 24.3003 22.8592 24.2673 23.0121 24.2384C23.1361 24.2137 23.2559 24.1848 23.3675 24.2302C23.4088 24.2467 23.4625 24.2384 23.508 24.2343C23.727 24.2178 23.9378 24.2384 24.1444 24.3251L24.1402 24.3333Z"
        fill="#6F3264"
      />
      <path
        d="M35.6079 26.3759C35.5046 26.3099 35.4219 26.2067 35.2525 26.2067C35.207 26.2067 35.1781 26.1655 35.1574 26.1242C35.0996 26.0087 35.0376 25.8972 34.9426 25.8147C34.9302 25.8023 34.9219 25.7776 34.9054 25.7734C34.7194 25.7239 34.6657 25.53 34.5334 25.4186C34.4425 25.3402 34.3847 25.2246 34.2524 25.1875C34.1822 25.1669 34.1367 25.0967 34.0913 25.039C33.9466 24.8656 33.8516 24.6387 33.7731 24.3952C33.7565 24.3086 33.7441 24.2343 33.7855 24.1765C33.8103 24.1435 33.7937 24.0734 33.8144 24.0486C33.8433 24.0115 33.8888 23.995 33.959 23.9826C34.0293 23.9743 34.1367 23.9537 34.2359 23.9743C34.3227 23.995 34.4012 24.028 34.4838 24.0569C34.6326 24.1229 34.8062 24.1559 34.8723 24.3168C34.8971 24.3746 34.9632 24.3994 35.0169 24.4324C35.174 24.519 35.2773 24.6593 35.3889 24.7955C35.4219 24.8409 35.455 24.8904 35.5046 24.9152C35.6368 24.9812 35.7484 25.0678 35.8476 25.1669C35.8972 25.2164 35.9467 25.2576 35.9798 25.3195C36.017 25.3856 36.0914 25.4145 36.1492 25.464C36.298 25.5919 36.4633 25.6992 36.5707 25.8725C36.7856 25.9756 36.8972 26.2108 37.0749 26.3553C37.1245 26.4048 37.1741 26.4791 37.1823 26.5905C37.203 26.8546 37.2237 26.8628 37.0956 26.8917C37.0584 26.9 37.0212 26.9082 36.984 26.9165C36.9261 26.9247 36.86 26.933 36.7856 26.8917C36.6823 26.8339 36.5459 26.8174 36.422 26.7927C36.3187 26.7762 36.2153 26.7679 36.1492 26.6936C36.1244 26.6689 36.0831 26.6565 36.0459 26.6482C35.8724 26.5946 35.7236 26.5162 35.6079 26.38V26.3759Z"
        fill="#6F3264"
      />
      <path
        d="M35.4134 17.8508C35.5126 17.8508 35.6077 17.9004 35.7316 17.8137C35.7647 17.7889 35.8019 17.8137 35.8308 17.8343C35.9135 17.9004 35.9961 17.9623 36.0953 17.9829C36.1118 17.9829 36.1242 18.0035 36.1366 17.9953C36.2895 17.9416 36.397 18.0778 36.5333 18.1067C36.6284 18.1273 36.711 18.1975 36.8185 18.1603C36.8763 18.1397 36.9342 18.181 36.9879 18.2057C37.1532 18.28 37.302 18.4244 37.4425 18.5853C37.4838 18.6472 37.5169 18.7091 37.5086 18.7752C37.5045 18.8164 37.5375 18.8659 37.5293 18.8989C37.521 18.9485 37.4921 18.9815 37.4466 19.031C37.4012 19.0723 37.3268 19.1465 37.2441 19.1795C37.1739 19.2043 37.1036 19.2167 37.0334 19.2373C36.9011 19.2579 36.7648 19.3198 36.6614 19.2167C36.6243 19.1795 36.5664 19.1919 36.5168 19.196C36.3722 19.2043 36.2482 19.1383 36.1201 19.0764C36.0788 19.0557 36.0416 19.0269 35.9961 19.031C35.8763 19.0434 35.7647 19.031 35.6573 18.9939C35.6035 18.9773 35.554 18.9691 35.5044 18.932C35.4548 18.8948 35.3887 18.9072 35.3308 18.8948C35.1779 18.8618 35.0209 18.8577 34.8804 18.7628C34.6861 18.7834 34.525 18.6431 34.3431 18.6142C34.2894 18.5977 34.2274 18.5606 34.182 18.4698C34.0745 18.2552 34.058 18.2594 34.1406 18.1686C34.1654 18.1438 34.1902 18.1149 34.215 18.0902C34.2564 18.053 34.3018 18.0159 34.3679 18.0118C34.463 18.0118 34.5663 17.954 34.6696 17.9086C34.7523 17.8715 34.8308 17.8261 34.9052 17.855C34.9299 17.8673 34.9671 17.855 34.9961 17.8426C35.1407 17.7972 35.2771 17.7889 35.4093 17.8426L35.4134 17.8508Z"
        fill="#6F3264"
      />
      <path
        d="M38.0544 12.7052C38.1453 12.6845 38.2403 12.7052 38.3354 12.5938C38.3602 12.5649 38.3974 12.5731 38.4263 12.5938C38.5172 12.6392 38.604 12.6804 38.699 12.6804C38.7114 12.6804 38.728 12.6928 38.7404 12.6845C38.8685 12.5979 38.9966 12.7052 39.1247 12.7011C39.2156 12.7011 39.3065 12.7464 39.3933 12.6887C39.4429 12.6557 39.5049 12.6804 39.5586 12.6887C39.7239 12.7217 39.8892 12.829 40.0545 12.9528C40.1041 13.0064 40.1495 13.0559 40.1578 13.1219C40.1578 13.1632 40.2033 13.2045 40.2074 13.2375C40.2074 13.287 40.195 13.3283 40.1619 13.386C40.1289 13.4397 40.0793 13.5263 40.0132 13.5758C39.9553 13.6171 39.8933 13.646 39.8355 13.679C39.7198 13.7285 39.6082 13.8193 39.4925 13.745C39.4512 13.7203 39.4016 13.745 39.3561 13.7574C39.228 13.7987 39.0999 13.7615 38.9718 13.7368C38.9305 13.7285 38.8891 13.7079 38.8478 13.7244C38.7445 13.7657 38.6371 13.7739 38.5296 13.7698C38.48 13.7698 38.4304 13.7698 38.3808 13.745C38.3271 13.7203 38.2693 13.745 38.2155 13.7492C38.0709 13.7533 37.9221 13.7822 37.7775 13.7285C37.6081 13.7945 37.4304 13.6955 37.2568 13.7079C37.2031 13.7079 37.1411 13.6831 37.0791 13.6047C36.9345 13.419 36.9221 13.4314 36.9758 13.3241C36.9923 13.2911 37.0089 13.2622 37.0254 13.2334C37.0543 13.188 37.0874 13.1385 37.1452 13.1219C37.232 13.0972 37.3147 13.0188 37.3973 12.9528C37.4634 12.895 37.5254 12.8331 37.5998 12.8455C37.6246 12.8496 37.6535 12.829 37.6825 12.8125C37.8064 12.7382 37.9263 12.6928 38.0585 12.7176L38.0544 12.7052Z"
        fill="#6F3264"
      />
      <path
        d="M83.3169 12.21C83.4078 12.21 83.5029 12.243 83.6103 12.1481C83.6393 12.1234 83.6765 12.1399 83.7013 12.1564C83.7839 12.2141 83.8666 12.2678 83.9575 12.276C83.9699 12.276 83.9864 12.2925 83.9988 12.2843C84.1352 12.21 84.2509 12.3338 84.379 12.3379C84.4699 12.3462 84.5567 12.3957 84.6476 12.3421C84.7013 12.3132 84.7592 12.3421 84.8129 12.3544C84.9782 12.3957 85.1393 12.503 85.3088 12.6309C85.3625 12.6846 85.408 12.7341 85.4162 12.8001C85.4162 12.8414 85.4658 12.8826 85.4658 12.9156C85.4658 12.9651 85.4534 13.0064 85.4162 13.0642C85.3832 13.1178 85.3294 13.2086 85.2551 13.254C85.1931 13.2953 85.127 13.3241 85.0608 13.3572C84.9369 13.4025 84.8088 13.4933 84.6848 13.4108C84.6393 13.3819 84.5856 13.4067 84.536 13.4108C84.3914 13.4438 84.255 13.3984 84.1186 13.3572C84.0732 13.3448 84.0318 13.32 83.9864 13.3283C83.8666 13.3572 83.7508 13.353 83.6393 13.3283C83.5855 13.3159 83.5318 13.3118 83.4822 13.2829C83.4285 13.2499 83.3624 13.2664 83.3045 13.2581C83.1475 13.2375 82.9863 13.2375 82.8376 13.1508C82.6433 13.1756 82.4739 13.0353 82.2921 13.0023C82.2384 12.9858 82.1764 12.9486 82.1351 12.8537C82.0359 12.635 82.0193 12.6392 82.102 12.5525C82.1268 12.5278 82.1516 12.503 82.1764 12.4782C82.2177 12.4411 82.2632 12.404 82.3293 12.404C82.4202 12.404 82.5235 12.3462 82.6227 12.3008C82.7012 12.2595 82.7756 12.2141 82.8458 12.243C82.8706 12.2513 82.9037 12.2389 82.9326 12.2265C83.069 12.177 83.1971 12.1605 83.3211 12.2059L83.3169 12.21Z"
        fill="#6F3264"
      />
      <path
        d="M82.879 19.2866C82.9699 19.2701 83.0649 19.2949 83.1641 19.1835C83.1889 19.1546 83.2261 19.167 83.255 19.1835C83.3418 19.233 83.4327 19.2784 83.5236 19.2784C83.536 19.2784 83.5526 19.2907 83.565 19.2825C83.6972 19.2 83.8212 19.3114 83.9493 19.3114C84.0402 19.3114 84.127 19.3609 84.222 19.3073C84.2716 19.2784 84.3336 19.3031 84.3873 19.3155C84.5526 19.3526 84.7138 19.4641 84.8749 19.5961C84.9245 19.6497 84.9659 19.7034 84.97 19.7694C84.97 19.8107 85.0113 19.8519 85.0155 19.8891C85.0155 19.9386 84.9989 19.9799 84.9617 20.0335C84.9245 20.083 84.8708 20.1738 84.8047 20.2192C84.7427 20.2563 84.6807 20.2852 84.6229 20.3182C84.5072 20.3636 84.3914 20.4544 84.2757 20.3719C84.2344 20.343 84.1848 20.3677 84.1394 20.3801C84.0071 20.4173 83.8832 20.3801 83.755 20.343C83.7137 20.3306 83.6724 20.31 83.6311 20.3265C83.5236 20.3636 83.4203 20.3677 83.3129 20.3595C83.2633 20.3554 83.2137 20.3595 83.1641 20.3306C83.1104 20.3058 83.0525 20.3306 82.9988 20.3306C82.8542 20.3306 82.7054 20.3554 82.5608 20.2935C82.3872 20.3512 82.2136 20.2481 82.0442 20.2563C81.9905 20.2522 81.9285 20.2274 81.8706 20.149C81.7343 19.9592 81.7177 19.9675 81.7756 19.8643C81.7921 19.8313 81.8128 19.8024 81.8293 19.7735C81.8582 19.7281 81.8913 19.6828 81.9533 19.6663C82.0401 19.6415 82.1269 19.5672 82.2095 19.5053C82.2756 19.4517 82.3417 19.3898 82.4161 19.4022C82.4409 19.4063 82.4699 19.3857 82.4988 19.3692C82.6228 19.299 82.7467 19.2577 82.879 19.2866Z"
        fill="#6F3264"
      />
      <path
        d="M91.8837 21.3623C91.7804 21.4325 91.6481 21.4655 91.5737 21.6182C91.5531 21.6594 91.4994 21.6677 91.458 21.6718C91.3258 21.676 91.1977 21.6842 91.0779 21.7337C91.0613 21.742 91.0365 21.7337 91.0241 21.7502C90.8919 21.8947 90.6894 21.8575 90.5282 21.9194C90.4125 21.9648 90.2803 21.9607 90.1811 22.0556C90.1274 22.1092 90.0406 22.1133 89.9662 22.1257C89.7389 22.167 89.491 22.134 89.2389 22.0762C89.1563 22.0473 89.086 22.0143 89.0571 21.9524C89.0406 21.9153 88.9744 21.8905 88.962 21.8575C88.9455 21.8121 88.9538 21.7667 88.9827 21.6966C89.0116 21.6347 89.0488 21.5274 89.1191 21.4573C89.1811 21.3954 89.2472 21.3417 89.3133 21.2881C89.4414 21.1973 89.553 21.0653 89.7183 21.0859C89.7761 21.0941 89.8299 21.0487 89.8836 21.0157C90.0282 20.9208 90.1976 20.8961 90.3629 20.8672C90.4167 20.8548 90.4745 20.8548 90.5158 20.8218C90.6274 20.7351 90.7514 20.6774 90.8836 20.6279C90.9456 20.6072 91.0035 20.5783 91.0737 20.5742C91.1481 20.5701 91.2018 20.5123 91.268 20.4834C91.4415 20.4009 91.5985 20.2977 91.7969 20.2689C91.9705 20.1162 92.2225 20.1038 92.4126 19.9883C92.4746 19.9594 92.5573 19.9429 92.6647 19.9718C92.9168 20.046 92.9333 20.0295 92.9168 20.1574C92.9127 20.1946 92.9044 20.2358 92.9003 20.2689C92.8879 20.3266 92.8713 20.3885 92.8094 20.4463C92.7184 20.5206 92.6565 20.6444 92.5862 20.7516C92.5325 20.8424 92.4829 20.9373 92.3878 20.9703C92.3548 20.9827 92.33 21.0199 92.3011 21.0487C92.1854 21.189 92.049 21.3005 91.8754 21.35L91.8837 21.3623Z"
        fill="#6F3264"
      />
      <path
        d="M88.2265 27.3003C88.3381 27.2425 88.4745 27.2136 88.553 27.0609C88.5737 27.0197 88.6274 27.0114 88.6687 27.0114C88.8009 27.0114 88.9332 26.9949 89.0489 26.9372C89.0654 26.9289 89.0902 26.933 89.1026 26.9165C89.2266 26.7597 89.4332 26.7804 89.5902 26.6937C89.7018 26.6359 89.8341 26.6112 89.9126 26.4956C89.9539 26.4296 90.0366 26.409 90.1109 26.3801C90.3258 26.2852 90.5779 26.2439 90.8424 26.2068C90.9333 26.2068 91.0159 26.2068 91.0614 26.2563C91.0903 26.2852 91.1647 26.2811 91.1895 26.3058C91.2226 26.343 91.2267 26.3883 91.2226 26.4667C91.2143 26.5369 91.206 26.6566 91.1523 26.7556C91.1027 26.8422 91.0449 26.9165 90.987 26.9991C90.8713 27.1393 90.7721 27.3127 90.5779 27.3415C90.5076 27.3539 90.4581 27.4158 90.4002 27.4612C90.2473 27.5974 90.0531 27.6634 89.863 27.7294C89.801 27.7501 89.7349 27.7624 89.6853 27.8037C89.5572 27.911 89.4125 27.9894 89.2555 28.0472C89.1811 28.076 89.115 28.1049 89.0324 28.1132C88.9456 28.1214 88.8753 28.1792 88.7968 28.2122C88.5861 28.2947 88.3794 28.3979 88.1439 28.4103C87.9083 28.5464 87.6149 28.5258 87.3587 28.5918C87.2761 28.6042 87.1769 28.6083 87.0694 28.5464C86.8215 28.4061 86.8008 28.4185 86.8628 28.2989C86.8835 28.2617 86.9 28.2287 86.9207 28.1957C86.9537 28.1421 86.9909 28.0884 87.0736 28.0513C87.1893 27.9976 87.2926 27.8986 87.3918 27.8037C87.4703 27.7253 87.5405 27.6469 87.6439 27.6263C87.681 27.6221 87.7141 27.5891 87.7472 27.5602C87.8918 27.4406 88.0406 27.3498 88.2224 27.3127L88.2265 27.3003Z"
        fill="#6F3264"
      />
      <path
        d="M101.206 27.6303C101.111 27.6963 100.991 27.7293 100.934 27.882C100.917 27.9233 100.872 27.9315 100.83 27.9356C100.706 27.9439 100.587 27.9563 100.483 28.0099C100.467 28.0182 100.446 28.014 100.434 28.0306C100.326 28.1791 100.132 28.1502 99.9873 28.2245C99.884 28.274 99.7641 28.2823 99.6856 28.3854C99.6443 28.4432 99.5658 28.4514 99.4955 28.4721C99.293 28.534 99.0575 28.5298 98.8137 28.5051C98.731 28.4845 98.6608 28.4638 98.6236 28.4061C98.6029 28.373 98.5368 28.3565 98.5203 28.3277C98.4955 28.2864 98.4955 28.241 98.512 28.1667C98.5285 28.1007 98.5451 27.9934 98.6029 27.915C98.6525 27.8449 98.7104 27.7871 98.7641 27.7252C98.8715 27.6179 98.9624 27.4735 99.1277 27.4776C99.1856 27.4776 99.2311 27.4281 99.2806 27.391C99.4129 27.2837 99.5699 27.2465 99.7311 27.2012C99.7807 27.1888 99.8385 27.1805 99.8757 27.1475C99.979 27.0526 100.095 26.9866 100.219 26.9371C100.277 26.9123 100.33 26.8834 100.401 26.8752C100.471 26.8669 100.525 26.8133 100.587 26.7803C100.752 26.6977 100.905 26.5904 101.095 26.5616C101.26 26.4089 101.508 26.4006 101.698 26.2975C101.76 26.2727 101.843 26.2562 101.946 26.2975C102.19 26.3841 102.202 26.3676 102.186 26.4955C102.182 26.5327 102.173 26.5698 102.169 26.607C102.157 26.6647 102.14 26.7225 102.078 26.7761C101.992 26.8463 101.93 26.9618 101.868 27.0691C101.818 27.1558 101.773 27.2465 101.686 27.2754C101.653 27.2837 101.632 27.3208 101.607 27.3497C101.5 27.4859 101.38 27.589 101.219 27.6385L101.206 27.6303Z"
        fill="#6F3264"
      />
      <path
        d="M98.6898 34.121C98.8551 34.0508 99.0452 34.0178 99.1898 33.8569C99.227 33.8156 99.3056 33.8032 99.3634 33.8032C99.5452 33.7991 99.7271 33.7867 99.9048 33.7331C99.9296 33.7248 99.9626 33.7331 99.9833 33.7125C100.202 33.5556 100.475 33.5887 100.715 33.5144C100.884 33.4607 101.07 33.4566 101.223 33.3493C101.31 33.2916 101.43 33.2792 101.537 33.2586C101.864 33.2049 102.202 33.1925 102.554 33.2338C102.665 33.2503 102.764 33.2709 102.793 33.3369C102.81 33.3741 102.905 33.3947 102.917 33.4277C102.95 33.502 102.855 33.6712 102.64 33.8651C102.541 33.9477 102.434 34.0013 102.331 34.0632C102.128 34.1787 101.938 34.3273 101.698 34.319C101.611 34.319 101.525 34.3685 101.442 34.4057C101.206 34.5171 100.954 34.5584 100.706 34.5996C100.628 34.612 100.541 34.6203 100.471 34.6574C100.285 34.7564 100.087 34.8224 99.8841 34.8761C99.7891 34.9008 99.6981 34.9339 99.5907 34.938C99.4833 34.9421 99.3841 35.004 99.2808 35.037C99.008 35.1237 98.7394 35.2351 98.446 35.2598C98.1402 35.4166 97.7765 35.4249 97.4583 35.5322C97.3592 35.5652 97.2311 35.5693 97.0947 35.5322C96.7724 35.4455 96.7393 35.4455 96.8178 35.3176C96.8426 35.2764 96.8633 35.2392 96.8839 35.1979C96.9211 35.1361 96.9707 35.0742 97.0823 35.0205C97.2352 34.9462 97.3716 34.8307 97.5079 34.7152C97.6154 34.6244 97.7187 34.5295 97.8592 34.5006C97.9088 34.4882 97.9584 34.4511 98.0038 34.4222C98.2146 34.2819 98.4336 34.1705 98.6898 34.121Z"
        fill="#6F3264"
      />
      <path
        d="M108.319 40.4428C108.17 40.4799 108.009 40.4675 107.864 40.5913C107.823 40.6243 107.761 40.6161 107.711 40.6037C107.558 40.5666 107.409 40.5336 107.252 40.5377C107.228 40.5377 107.203 40.5253 107.182 40.5377C106.971 40.6285 106.757 40.5212 106.546 40.5171C106.397 40.5171 106.248 40.4593 106.095 40.5171C106.009 40.5459 105.913 40.5171 105.823 40.4964C105.55 40.4428 105.285 40.3107 105.033 40.1498C104.955 40.0879 104.893 40.026 104.893 39.96C104.893 39.9187 104.831 39.8692 104.835 39.8321C104.835 39.7826 104.872 39.7454 104.93 39.6959C104.992 39.6505 105.083 39.5721 105.19 39.5391C105.285 39.5102 105.385 39.4896 105.48 39.469C105.661 39.4359 105.843 39.3617 106.013 39.4483C106.075 39.4772 106.153 39.4607 106.223 39.4483C106.426 39.4194 106.62 39.4566 106.818 39.4896C106.88 39.4978 106.947 39.5185 107.009 39.502C107.17 39.4607 107.331 39.4483 107.496 39.4483C107.575 39.4483 107.649 39.4401 107.728 39.4607C107.81 39.4813 107.897 39.4442 107.98 39.4359C108.203 39.4112 108.422 39.3617 108.653 39.3906C108.905 39.2915 109.191 39.3493 109.451 39.2833C109.534 39.2709 109.633 39.275 109.74 39.3287C109.996 39.4607 110.021 39.4483 109.959 39.568C109.943 39.601 109.926 39.634 109.901 39.6753C109.868 39.7289 109.827 39.7867 109.732 39.8279C109.604 39.8775 109.484 39.9806 109.364 40.0714C109.269 40.1457 109.174 40.2241 109.054 40.2323C109.013 40.2323 108.967 40.2612 108.926 40.286C108.736 40.3891 108.538 40.4552 108.319 40.451V40.4428Z"
        fill="#6F3264"
      />
      <path
        d="M97.9709 43.8346C98.1073 43.88 98.2354 43.9626 98.4131 43.9213C98.4626 43.9089 98.5081 43.946 98.5453 43.9791C98.6527 44.0781 98.7643 44.173 98.8966 44.2308C98.9172 44.239 98.9337 44.2596 98.9544 44.2596C99.1734 44.2596 99.3139 44.4371 99.4999 44.5114C99.628 44.565 99.7437 44.6599 99.8966 44.6599C99.9834 44.6599 100.058 44.7136 100.136 44.7589C100.368 44.8827 100.57 45.0767 100.773 45.283C100.831 45.3614 100.88 45.4315 100.868 45.4976C100.859 45.5388 100.909 45.6007 100.901 45.6337C100.888 45.6791 100.851 45.7121 100.781 45.7493C100.715 45.7782 100.607 45.8359 100.492 45.8483C100.388 45.8524 100.285 45.8483 100.186 45.8483C99.9958 45.8318 99.7892 45.8483 99.6445 45.708C99.5908 45.6585 99.5082 45.6544 99.4338 45.6379C99.223 45.5966 99.0453 45.4852 98.8676 45.3779C98.8098 45.3449 98.756 45.2995 98.6899 45.2871C98.5164 45.2582 98.3593 45.1963 98.2064 45.1179C98.132 45.0808 98.0618 45.0519 97.9957 44.9941C97.9296 44.9364 97.8345 44.924 97.7519 44.8869C97.537 44.792 97.3138 44.7136 97.1237 44.5567C96.8469 44.4907 96.6361 44.272 96.3882 44.1524C96.3138 44.107 96.2352 44.041 96.1857 43.9254C96.07 43.6531 96.0452 43.6489 96.1774 43.5994C96.2187 43.5829 96.2559 43.5705 96.2931 43.5582C96.3551 43.5375 96.4212 43.521 96.5163 43.554C96.6485 43.5953 96.8014 43.5912 96.946 43.5871C97.0659 43.5871 97.1816 43.5747 97.2766 43.6366C97.3097 43.6572 97.3634 43.6572 97.4047 43.6613C97.6114 43.6778 97.8015 43.7273 97.975 43.8388L97.9709 43.8346Z"
        fill="#6F3264"
      />
      <path
        d="M99.9254 53.8122C99.7849 53.7049 99.665 53.5604 99.4584 53.5151C99.4006 53.5027 99.3592 53.4408 99.3303 53.3913C99.2394 53.2345 99.1485 53.0818 99.0245 52.9539C99.0038 52.9332 98.9956 52.9044 98.9708 52.892C98.7352 52.7723 98.6484 52.5165 98.4831 52.3349C98.3633 52.207 98.2889 52.0419 98.1319 51.9512C98.0451 51.8975 97.9873 51.7944 97.9294 51.7036C97.7517 51.4354 97.636 51.1176 97.5451 50.7916C97.5285 50.6802 97.5162 50.5812 97.5657 50.5358C97.5947 50.5069 97.5781 50.4161 97.6071 50.3955C97.6443 50.3625 97.6939 50.3625 97.7806 50.379C97.8591 50.3996 97.9873 50.4203 98.103 50.4863C98.2021 50.5482 98.2972 50.6183 98.3881 50.6844C98.5575 50.8205 98.76 50.9237 98.8385 51.1341C98.8675 51.2084 98.946 51.2662 99.0121 51.3198C99.1981 51.4766 99.3262 51.6747 99.4667 51.8686C99.5121 51.9305 99.5493 52.0007 99.6113 52.0461C99.7725 52.1616 99.913 52.2936 100.045 52.4381C100.107 52.5082 100.173 52.566 100.223 52.6526C100.277 52.7393 100.372 52.7929 100.442 52.8631C100.64 53.0488 100.855 53.2056 101.016 53.4325C101.293 53.5935 101.475 53.8864 101.727 54.0762C101.802 54.1423 101.876 54.233 101.917 54.361C102.012 54.6704 102.041 54.6828 101.892 54.7035C101.855 54.7076 101.814 54.7117 101.76 54.7158C101.69 54.72 101.611 54.7158 101.508 54.6581C101.368 54.5755 101.194 54.5343 101.033 54.4848C100.901 54.4476 100.768 54.4146 100.669 54.3156C100.636 54.2784 100.578 54.2578 100.529 54.2372C100.301 54.1381 100.099 54.0102 99.9336 53.8163L99.9254 53.8122Z"
        fill="#6F3264"
      />
      <path
        d="M89.3752 47.7422C89.4413 47.8784 89.4785 48.0393 89.6397 48.1466C89.681 48.1755 89.6934 48.2374 89.6975 48.291C89.7058 48.4478 89.7264 48.6046 89.7843 48.7449C89.7926 48.7656 89.7926 48.7944 89.805 48.811C89.9661 48.976 89.9496 49.2153 90.0405 49.4093C90.1025 49.5454 90.1232 49.7022 90.243 49.8178C90.3091 49.8797 90.3298 49.9787 90.3587 50.0695C90.4496 50.3336 90.4827 50.6307 90.5033 50.936C90.4992 51.0392 90.4909 51.13 90.4372 51.1712C90.4042 51.196 90.4042 51.2785 90.3752 51.2992C90.3339 51.328 90.2843 51.3198 90.2058 51.2992C90.1314 51.2744 90.0116 51.2373 89.9124 51.1589C89.8298 51.0887 89.7512 51.0062 89.6769 50.9278C89.5405 50.771 89.3711 50.6348 89.3504 50.4161C89.3421 50.3377 89.2801 50.2717 89.2347 50.2057C89.1024 50.0159 89.0405 49.7972 88.9785 49.5785C88.9578 49.5083 88.9495 49.434 88.9082 49.3721C88.8008 49.2195 88.7223 49.0503 88.6603 48.8728C88.6313 48.7903 88.5983 48.7119 88.5859 48.617C88.5776 48.5221 88.5156 48.4396 88.4784 48.3488C88.3875 48.1095 88.2759 47.8784 88.247 47.6143C88.0941 47.3502 88.0941 47.0201 88.0032 46.7354C87.9825 46.6446 87.9702 46.5332 88.0197 46.4094C88.1355 46.1205 88.1189 46.0999 88.247 46.1618C88.2801 46.1783 88.3173 46.199 88.3586 46.2237C88.4165 46.2567 88.4743 46.3021 88.5198 46.397C88.5859 46.5291 88.6975 46.6487 88.8008 46.7601C88.8876 46.8509 88.9743 46.9334 89.0033 47.0531C89.0115 47.0944 89.0487 47.1356 89.0777 47.1728C89.214 47.3461 89.3173 47.5276 89.3669 47.7422H89.3752Z"
        fill="#6F3264"
      />
      <path
        d="M58.6874 89.8684C59.3238 89.8148 60.0056 89.8189 60.6544 89.691C60.828 89.6539 61.0966 89.6621 61.3073 89.6786C61.9396 89.7199 62.5719 89.7612 63.2248 89.7735C63.324 89.7735 63.4273 89.79 63.5141 89.7818C64.4191 89.7158 65.3117 89.8602 66.2043 89.9138C66.8366 89.9427 67.4606 90.05 68.1011 90.0376C68.4565 90.0376 68.8697 90.1037 69.2541 90.1532C70.4111 90.3141 71.56 90.5493 72.684 90.867C73.0353 90.9661 73.3328 91.0692 73.3659 91.1435C73.3824 91.1889 73.6675 91.2797 73.6758 91.3168C73.6965 91.3993 73.1882 91.4612 72.2666 91.4365C71.8534 91.4241 71.436 91.3911 71.0228 91.3622C70.2293 91.3168 69.4442 91.3044 68.6549 91.1559C68.3738 91.1022 68.0267 91.0899 67.7085 91.0816C66.8076 91.0445 65.9316 90.9578 65.0431 90.8877C64.7579 90.867 64.4687 90.8381 64.1918 90.8423C63.4645 90.8547 62.7372 90.8588 62.0016 90.8464C61.6545 90.8464 61.3156 90.8464 60.9602 90.8258C60.5924 90.8093 60.1998 90.8464 59.8197 90.8588C58.8113 90.8959 57.8071 90.9784 56.7906 90.9949C55.6211 91.1517 54.3772 91.1683 53.2118 91.3416C52.8482 91.3911 52.4143 91.4076 51.9804 91.4035C50.9679 91.3787 50.8563 91.3828 51.2241 91.2302C51.3316 91.1848 51.4432 91.1352 51.5547 91.0857C51.7366 91.0073 51.9597 90.933 52.3771 90.8505C52.9639 90.7474 53.5342 90.5947 54.0962 90.4668C54.5466 90.3677 54.9805 90.2481 55.4888 90.2151C55.6707 90.2027 55.869 90.1655 56.055 90.1325C56.9021 89.984 57.7658 89.8808 58.6832 89.8684H58.6874Z"
        fill="#6F3264"
      />
      <path
        d="M56.0056 84.8055C56.3858 84.5744 56.8114 84.3475 57.1048 83.9843C57.1875 83.8936 57.3404 83.7863 57.4602 83.7079C57.8322 83.4768 58.1793 83.2003 58.4934 82.8702C58.5388 82.8207 58.5967 82.7753 58.6339 82.7258C58.9851 82.2059 59.4438 81.7726 59.7703 81.232C60.0058 80.8565 60.2414 80.4769 60.3778 80.0519C60.4439 79.8126 60.5802 79.5526 60.6753 79.3009C60.9852 78.5458 61.2456 77.7659 61.4605 76.9489C61.5348 76.6972 61.5886 76.4702 61.663 76.4413C61.7043 76.4248 61.7621 76.2102 61.7952 76.1979C61.8448 76.1855 61.882 76.268 61.9357 76.4496C61.9894 76.627 62.0473 76.8993 62.0638 77.2542C62.0721 77.5719 62.0473 77.8979 62.0059 78.2198C61.9316 78.8305 61.8365 79.4536 61.5431 80.0106C61.4315 80.2046 61.3572 80.4687 61.258 80.6956C60.9935 81.3517 60.6133 81.9377 60.1835 82.4865C60.043 82.6598 59.8943 82.8331 59.7662 83.0064C59.4397 83.4768 59.0471 83.8977 58.6091 84.2732C58.4066 84.4589 58.1958 84.6198 57.9644 84.7766C57.7165 84.9252 57.485 85.1315 57.233 85.2924C56.5718 85.7216 55.8692 86.1053 55.1047 86.3323C54.2617 86.7201 53.2823 86.8439 52.3732 86.9636C52.088 86.9925 51.745 86.9842 51.4144 86.9182C51.026 86.8481 50.8194 86.7738 50.745 86.7408C50.6665 86.6995 50.7202 86.6871 50.869 86.6335L51.1293 86.5468C51.2698 86.5056 51.4434 86.4519 51.7533 86.423C52.1872 86.3611 52.6046 86.2497 52.9972 86.1053C53.3154 85.9939 53.6047 85.8619 53.9559 85.7752C54.0799 85.7422 54.2163 85.6885 54.332 85.6267C54.8857 85.3708 55.4271 85.0985 56.0056 84.8138V84.8055Z"
        fill="#6F3264"
      />
      <path
        d="M66.969 81.3516C67.1467 81.6982 67.345 82.0696 67.6467 82.346C67.7211 82.4244 67.8037 82.5647 67.8657 82.6762C67.9566 82.8453 68.0475 83.0145 68.1467 83.1837C68.25 83.3488 68.3699 83.5056 68.4939 83.6582C68.531 83.7036 68.56 83.7614 68.6013 83.7985C69.0228 84.1575 69.3203 84.6279 69.7377 84.9869C70.0146 85.2551 70.308 85.511 70.6427 85.7132C70.8411 85.8081 71.0394 85.969 71.2254 86.1093C71.5105 86.3198 71.8122 86.5096 72.1056 86.7241C72.4156 86.9016 72.7048 87.1327 73.023 87.3142C73.2173 87.4298 73.3867 87.5494 73.3908 87.6237C73.3908 87.6691 73.5561 87.7764 73.552 87.8135C73.552 87.896 73.2131 87.9249 72.5891 87.8259C72.3205 87.7599 72.056 87.6608 71.7916 87.57C71.2998 87.372 70.7791 87.1904 70.3783 86.8149C70.2336 86.6829 70.0187 86.5838 69.8493 86.4559C69.6014 86.2785 69.3534 86.1052 69.1344 85.8947C68.9195 85.6843 68.7046 85.4738 68.5021 85.2551C68.3781 85.1107 68.2583 84.9498 68.1178 84.826C67.9318 84.6651 67.7665 84.4794 67.6095 84.2937C67.4525 84.1039 67.2872 83.9182 67.1549 83.7078C67.0227 83.5138 66.8905 83.3281 66.7747 83.1177C66.6673 82.8949 66.5103 82.6803 66.378 82.4575C66.1962 82.1686 66.0557 81.855 65.8987 81.5538C65.7499 81.2484 65.6425 80.9224 65.535 80.5965C65.2127 79.8661 65.0722 79.0284 64.9482 78.2444C64.9069 78.001 64.8945 77.7039 64.9565 77.415C65.0887 76.7383 65.0928 76.6805 65.2085 76.924C65.2457 76.9983 65.2829 77.0725 65.316 77.1427C65.3738 77.2623 65.4234 77.4068 65.4648 77.675C65.5557 78.0422 65.659 78.4012 65.8119 78.7355C65.94 79.0037 66.0433 79.2554 66.1301 79.5566C66.159 79.6639 66.221 79.7753 66.2706 79.8826C66.378 80.1261 66.502 80.3571 66.626 80.6006C66.6838 80.7203 66.7417 80.844 66.7913 80.972C66.8491 81.0958 66.907 81.2195 66.969 81.3475V81.3516Z"
        fill="#6F3264"
      />
      <path
        d="M29.2603 61.5697C29.2603 61.5697 32.3224 62.7457 34.3514 62.8282L31.8059 63.3688C31.8059 63.3688 35.306 63.8227 36.3764 63.3688C36.3764 63.3688 34.0704 64.5902 34.2109 64.582C34.3514 64.5737 39.1947 64.0497 40.0914 63.3688C40.0914 63.3688 36.4879 66.509 34.3225 65.5847C32.1571 64.6604 29.9504 63.5174 29.2603 61.5697Z"
        fill="#F3D7E8"
      />
      <path
        d="M88.2139 62.6055C88.2139 62.6055 91.5157 64.0662 93.3175 64.0373L91.9662 62.9397C91.9662 62.9397 95.3176 64.0373 96.4705 63.3193L94.4167 62.6014C94.4167 62.6014 97.7475 62.5601 98.7558 62.23C98.7558 62.23 93.739 67.2188 90.7843 65.5847C87.8296 63.9507 88.2139 62.6014 88.2139 62.6014V62.6055Z"
        fill="#F3D7E8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1019_6487">
        <rect
          width="97.96"
          height="115.32"
          fill="white"
          transform="translate(12.3999 4.95996)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default OxSticker
