import { Bowed } from 'components/curves'
import Fade from 'react-reveal/Fade'
import Link from 'components/link'
import { helloBabyBoxHowToGet, newRegistryPath } from 'lib/urls'
import classNames from 'classnames'
import { InfoButton } from '../shared/Buttons'
import css from './HelloBabyBoxSection.scss'
import { SectionHeading } from '../shared/Headings'

const HelloBabyBoxSection = () => (
  <section className={css.HelloBabyBoxSection}>
    <div className={css.HelloBabyContentContainer}>
      <div className={css.HelloBabyContent}>
        <div className={css.HelloBabyContentText}>
          <Fade bottom>
            <SectionHeading className={css.HelloBabyBoxSectionHeading}>
              Celebrate the Moment!
            </SectionHeading>
            <p className={css.SectionText} data-testid="hbbCopy">
              Create a Babylist registry today to be eligible for a FREE box of
              goodies for you and your baby.
            </p>
            <aside data-testid="restrictionsAside">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              Some{' '}
              <Link newWindow url={helloBabyBoxHowToGet}>
                restrictions apply
              </Link>
              .
            </aside>
          </Fade>
        </div>
        <div className={classNames(css.ButtonGroup, css.HiddenSmall)}>
          <InfoButton pill className={css.Button} href={newRegistryPath}>
            Get Started
          </InfoButton>
        </div>
      </div>
    </div>
    <div className={classNames(css.ButtonGroup, css.HiddenLarge)}>
      <InfoButton pill className={css.Button} href={newRegistryPath}>
        Get Started
      </InfoButton>
    </div>
    <Bowed position="bottom" />
  </section>
)

export default HelloBabyBoxSection
