import React from 'react'

interface BabylistHeartProps {
  className?: string
}

const BabylistHeart: React.FC<BabylistHeartProps> = ({
  className,
}: BabylistHeartProps) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#6E3264" />
    <g clip-path="url(#clip0_319_3388)">
      <path
        d="M15.9985 12.4938C15.5756 11.5931 14.9652 10.856 14.02 10.419C12.5089 9.71972 11.0333 9.81528 9.68298 10.7879C8.35112 11.7464 7.84149 13.0997 8.04223 14.7227C8.30223 16.8212 9.45779 18.4079 10.9615 19.7849C12.4548 21.1531 14.1733 22.1797 16 23.0353C17.8259 22.1797 19.5452 21.1531 21.0385 19.7849C22.5415 18.4079 23.6978 16.8212 23.9578 14.7227C24.1585 13.0997 23.6489 11.7464 22.3171 10.7879C20.9667 9.81528 19.4904 9.71972 17.98 10.419C17.0341 10.8568 16.4245 11.5938 16.0015 12.4938H15.9993H15.9985Z"
        fill="url(#paint0_linear_319_3388)"
      />
      <path
        d="M12.172 9.96442C12.172 9.96442 8.93575 11.9422 10.0506 14.9985C11.1654 18.0548 15.4802 16.4007 15.4802 16.4007L15.4765 16.3992C15.4765 16.3992 19.7913 14.7452 20.9061 17.8015C21.1639 18.5081 21.1891 19.1578 21.075 19.7385L21.0772 19.7489C22.5617 18.38 23.7002 16.8029 23.958 14.7222C24.1587 13.0992 23.6491 11.7459 22.3172 10.7874C20.9669 9.81479 19.4906 9.71923 17.9802 10.4185C17.0343 10.8563 16.4246 11.5933 16.0017 12.4933H15.9994C15.5765 11.5926 14.9661 10.8555 14.0209 10.4185C13.3876 10.1266 12.7617 9.97256 12.1483 9.96442H12.1728H12.172Z"
        fill="#FF7BAC"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_319_3388"
        x1="16"
        y1="23.2501"
        x2="16"
        y2="10.2997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF3B40" />
        <stop offset="1" stop-color="#FF7AAB" />
      </linearGradient>
      <clipPath id="clip0_319_3388">
        <rect
          width="16"
          height="13.0711"
          fill="white"
          transform="translate(8 9.96436)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default BabylistHeart
