import PropTypes from 'prop-types'
import BSMedia from 'react-bootstrap/lib/Media'

const Media = ({ children, className }) => {
  return <BSMedia className={className}>{children}</BSMedia>
}

Media.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Media.defaultProps = {
  className: '',
}

// the Media.Heading component from our current version of react-bootstrap (0.32.4)
// unfortunately renders an h4, which causes accessibility issues, this is a workaround
export const MediaHeading = ({ children, ...props }) => (
  <div {...props} className="modal-title">
    {children}
  </div>
)

MediaHeading.propTypes = {
  children: PropTypes.node.isRequired,
}

Media.Heading = MediaHeading
Media.Body = BSMedia.Body
Media.Left = BSMedia.Left
Media.Right = BSMedia.Right
Media.List = BSMedia.List
Media.ListItem = BSMedia.ListItem

export default Media
