import { TextLink } from 'baby-design'
import * as blUrls from 'lib/urls'
import { InfoAlert } from 'components/alerts'
import { Outlet, useLocation } from 'react-router-dom-v5-compat'
import { useEffect } from 'react'

interface ContainerPropTypes {
  showDesktopAlert: boolean
}

const Container = ({ showDesktopAlert }: ContainerPropTypes) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <div className="mtxl">
      {showDesktopAlert ? (
        <InfoAlert>
          <p>
            To use the share extension, the Babylist app must be installed on
            your phone or tablet.
          </p>
        </InfoAlert>
      ) : null}
      <div className="center-block" style={{ maxWidth: 440 }}>
        <Outlet />
        <div className="text-center mvxl">
          <TextLink underline href={blUrls.contactUsPath} size="md">
            Need help?
          </TextLink>
        </div>
      </div>
    </div>
  )
}

export default Container
