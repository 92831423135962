import { parseErrors } from 'lib/api-utils'
import * as api from '../api'

export const SET_USER = 'SET_USER'
export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  }
}

export const saveEmailPreferences = (values, restrictedAccessToken) => {
  return (dispatch) => {
    return api
      .saveEmailPreferences(values, restrictedAccessToken)
      .then((resp) => {
        dispatch({ type: SET_USER, user: resp.user })
      })
      .catch((resp) => Promise.reject(parseErrors(resp)))
  }
}
