import { Input } from 'components/forms'
import { InfoButton } from 'components/buttons'
import { track, withContextualizedTracking } from 'lib/analytics'
import { useEffect } from 'react'
import { passwordResetsPath } from 'lib/urls'
import css from './ForgotPasswordPage.scss'

interface ForgotPasswordPageProps {
  onPasswordForm: boolean
}

const ForgotPasswordPage = ({ onPasswordForm }: ForgotPasswordPageProps) => {
  const pageContent = {
    enterEmailForm: {
      buttonLabel: 'Email Address',
      buttonText: 'Reset Password',
      fieldName: 'email',
      fieldType: 'email',
      formAction: passwordResetsPath,
      headerText: 'Reset your password',
      subHeaderText:
        "Enter your email and we'll send you a link to reset your password.",
    },
    submitPasswordForm: {
      buttonLabel: 'New Password',
      buttonText: 'Submit',
      fieldName: 'user[password]',
      fieldType: 'password',
      formAction:
        typeof window !== 'undefined'
          ? window.location.pathname.replace('/edit', '')
          : passwordResetsPath,
      headerText: 'Reset your password',
      subHeaderText:
        'Fill out the form below to update your password and log in.',
    },
  }

  const {
    buttonLabel,
    buttonText,
    fieldName,
    fieldType,
    formAction,
    headerText,
    subHeaderText,
  } = onPasswordForm
    ? pageContent.submitPasswordForm
    : pageContent.enterEmailForm

  const handleSubmit = () => {
    if (!onPasswordForm) {
      track.forgotPasswordSubmitted({
        eventLocation: track.EventLocation.LOG_IN_MINUS_FORGOT_PASSWORD,
        eventType: track.EventType.SUCCESSFUL,
        userAuthenticationMethod: track.UserAuthenticationMethod.EMAIL,
      })
    }
  }

  useEffect(() => {
    if (!onPasswordForm) {
      track.forgotPasswordViewed({
        eventLocation: track.EventLocation.LOG_IN_MINUS_FORGOT_PASSWORD,
        eventType: track.EventType.PAGE_VIEWED,
        userAuthenticationMethod: track.UserAuthenticationMethod.EMAIL,
      })
    }
  }, [])

  return (
    <div className={css.pageBackground}>
      <div className={css.formContainer}>
        <div className={css.contentContainer}>
          <div className={css.contentHeaderContainer}>
            <h1 className={css.contentHeaderText}>{headerText}</h1>
            <div className={css.contentSubHeaderTextContainer}>
              <span className={css.contentSubHeaderText}>{subHeaderText}</span>
            </div>
          </div>
          <div className={css.fieldContainer}>
            <form action={formAction} method="post" onSubmit={handleSubmit}>
              <Input
                required
                label={buttonLabel}
                name={fieldName}
                type={fieldType}
              />
              {onPasswordForm && (
                <>
                  <p className="h7 text-muted mts">
                    Must be at least 8 characters
                  </p>
                  <input name="_method" type="hidden" value="put" />
                </>
              )}
              <div
                className={`${css.buttonContainer} btn-group btn-group--stretch`}
              >
                <InfoButton pill type="submit">
                  {buttonText}
                </InfoButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withContextualizedTracking()(ForgotPasswordPage)
