import React from 'react'

interface SquiggleProps {
  className?: string
}

const Squiggle: React.FC<SquiggleProps> = ({ className }: SquiggleProps) => (
  <svg
    className={className}
    width="88"
    height="10"
    viewBox="0 0 88 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="88" height="10" fill="#1E1E1E" />
    <path
      d="M-3475 -7255C-3475 -7256.1 -3474.1 -7257 -3473 -7257H17443C17444.1 -7257 17445 -7256.1 17445 -7255V6613C17445 6614.1 17444.1 6615 17443 6615H-3473C-3474.1 6615 -3475 6614.1 -3475 6613V-7255Z"
      fill="#404040"
    />
    <path
      d="M-3473 -7256H17443V-7258H-3473V-7256ZM17444 -7255V6613H17446V-7255H17444ZM17443 6614H-3473V6616H17443V6614ZM-3474 6613V-7255H-3476V6613H-3474ZM-3473 6614C-3473.55 6614 -3474 6613.55 -3474 6613H-3476C-3476 6614.66 -3474.66 6616 -3473 6616V6614ZM17444 6613C17444 6613.55 17443.6 6614 17443 6614V6616C17444.7 6616 17446 6614.66 17446 6613H17444ZM17443 -7256C17443.6 -7256 17444 -7255.55 17444 -7255H17446C17446 -7256.66 17444.7 -7258 17443 -7258V-7256ZM-3473 -7258C-3474.66 -7258 -3476 -7256.66 -3476 -7255H-3474C-3474 -7255.55 -3473.55 -7256 -3473 -7256V-7258Z"
      fill="white"
      fill-opacity="0.1"
    />
    <g clip-path="url(#clip0_0_1)">
      <rect
        width="1440"
        height="3998"
        transform="translate(-676 -262)"
        fill="white"
      />
      <g clip-path="url(#clip1_0_1)">
        <g clip-path="url(#clip2_0_1)">
          <path
            d="M764 -198H-676V995H764V-198Z"
            fill="url(#paint0_linear_0_1)"
          />
        </g>
        <g filter="url(#filter0_dd_0_1)">
          <rect
            x="-520"
            y="-150"
            width="1128"
            height="680.76"
            rx="24"
            fill="white"
            shape-rendering="crispEdges"
          />
          <mask
            id="path-5-outside-1_0_1"
            maskUnits="userSpaceOnUse"
            x="0.375"
            y="-8.74228e-08"
            width="87"
            height="10"
            fill="black"
          >
            <rect
              fill="white"
              x="0.375"
              y="-8.74228e-08"
              width="87"
              height="10"
            />
            <path d="M13.7674 8.5C14.2363 8.5 14.6992 8.46726 15.1622 8.40833C17.7202 8.07439 19.299 6.74519 20.8243 5.46182C22.5811 3.98202 24.237 2.58733 27.436 2.52186C30.3442 2.46293 31.917 3.64153 33.7391 5.01002C35.5849 6.39161 37.68 7.96308 41.4488 8.3494C41.9354 8.40178 42.4043 8.42142 42.8554 8.42142C46.8022 8.42142 49.5146 6.63387 51.942 5.03621C54.8799 3.09806 57.4201 1.42837 61.6281 2.82305C63.6104 3.48438 65.0467 4.47965 66.4355 5.44217C68.4119 6.81721 70.2755 8.10713 73.3618 8.22499C78.8814 8.42797 81.386 7.24282 86.1223 3.58914C86.3893 3.37961 86.4546 2.97365 86.2706 2.679C86.0748 2.3778 85.7068 2.30577 85.4397 2.50876C80.8756 6.03147 78.6203 7.09876 73.4033 6.90888C70.6376 6.81066 68.9876 5.65825 67.0706 4.32905C65.6165 3.32068 64.1149 2.27304 61.9723 1.56587C59.8535 0.86526 57.8949 0.812878 55.9897 1.42182C54.2982 1.95874 52.856 2.90817 51.3307 3.90999C48.6599 5.67135 45.8941 7.48509 41.5556 7.03984C38.0895 6.68626 36.214 5.27848 34.4038 3.92308C32.5402 2.5284 30.7834 1.20575 27.6496 1.20575C27.5725 1.20575 27.4894 1.20575 27.4122 1.20575C23.8215 1.27777 21.9341 2.87544 20.1061 4.41417C18.6461 5.64516 17.2632 6.81067 15.0257 7.09877C11.0907 7.6095 6.91834 5.68445 2.61539 1.36945C2.37206 1.12718 1.99814 1.14682 1.77854 1.41528C1.55894 1.68374 1.57675 2.09625 1.82009 2.33852C5.89156 6.43744 9.90369 8.5 13.7674 8.5Z" />
          </mask>
          <path
            d="M13.7674 8.5C14.2363 8.5 14.6992 8.46726 15.1622 8.40833C17.7202 8.07439 19.299 6.74519 20.8243 5.46182C22.5811 3.98202 24.237 2.58733 27.436 2.52186C30.3442 2.46293 31.917 3.64153 33.7391 5.01002C35.5849 6.39161 37.68 7.96308 41.4488 8.3494C41.9354 8.40178 42.4043 8.42142 42.8554 8.42142C46.8022 8.42142 49.5146 6.63387 51.942 5.03621C54.8799 3.09806 57.4201 1.42837 61.6281 2.82305C63.6104 3.48438 65.0467 4.47965 66.4355 5.44217C68.4119 6.81721 70.2755 8.10713 73.3618 8.22499C78.8814 8.42797 81.386 7.24282 86.1223 3.58914C86.3893 3.37961 86.4546 2.97365 86.2706 2.679C86.0748 2.3778 85.7068 2.30577 85.4397 2.50876C80.8756 6.03147 78.6203 7.09876 73.4033 6.90888C70.6376 6.81066 68.9876 5.65825 67.0706 4.32905C65.6165 3.32068 64.1149 2.27304 61.9723 1.56587C59.8535 0.86526 57.8949 0.812878 55.9897 1.42182C54.2982 1.95874 52.856 2.90817 51.3307 3.90999C48.6599 5.67135 45.8941 7.48509 41.5556 7.03984C38.0895 6.68626 36.214 5.27848 34.4038 3.92308C32.5402 2.5284 30.7834 1.20575 27.6496 1.20575C27.5725 1.20575 27.4894 1.20575 27.4122 1.20575C23.8215 1.27777 21.9341 2.87544 20.1061 4.41417C18.6461 5.64516 17.2632 6.81067 15.0257 7.09877C11.0907 7.6095 6.91834 5.68445 2.61539 1.36945C2.37206 1.12718 1.99814 1.14682 1.77854 1.41528C1.55894 1.68374 1.57675 2.09625 1.82009 2.33852C5.89156 6.43744 9.90369 8.5 13.7674 8.5Z"
            fill="#743363"
          />
          <path
            d="M13.7674 8.5C14.2363 8.5 14.6992 8.46726 15.1622 8.40833C17.7202 8.07439 19.299 6.74519 20.8243 5.46182C22.5811 3.98202 24.237 2.58733 27.436 2.52186C30.3442 2.46293 31.917 3.64153 33.7391 5.01002C35.5849 6.39161 37.68 7.96308 41.4488 8.3494C41.9354 8.40178 42.4043 8.42142 42.8554 8.42142C46.8022 8.42142 49.5146 6.63387 51.942 5.03621C54.8799 3.09806 57.4201 1.42837 61.6281 2.82305C63.6104 3.48438 65.0467 4.47965 66.4355 5.44217C68.4119 6.81721 70.2755 8.10713 73.3618 8.22499C78.8814 8.42797 81.386 7.24282 86.1223 3.58914C86.3893 3.37961 86.4546 2.97365 86.2706 2.679C86.0748 2.3778 85.7068 2.30577 85.4397 2.50876C80.8756 6.03147 78.6203 7.09876 73.4033 6.90888C70.6376 6.81066 68.9876 5.65825 67.0706 4.32905C65.6165 3.32068 64.1149 2.27304 61.9723 1.56587C59.8535 0.86526 57.8949 0.812878 55.9897 1.42182C54.2982 1.95874 52.856 2.90817 51.3307 3.90999C48.6599 5.67135 45.8941 7.48509 41.5556 7.03984C38.0895 6.68626 36.214 5.27848 34.4038 3.92308C32.5402 2.5284 30.7834 1.20575 27.6496 1.20575C27.5725 1.20575 27.4894 1.20575 27.4122 1.20575C23.8215 1.27777 21.9341 2.87544 20.1061 4.41417C18.6461 5.64516 17.2632 6.81067 15.0257 7.09877C11.0907 7.6095 6.91834 5.68445 2.61539 1.36945C2.37206 1.12718 1.99814 1.14682 1.77854 1.41528C1.55894 1.68374 1.57675 2.09625 1.82009 2.33852C5.89156 6.43744 9.90369 8.5 13.7674 8.5Z"
            stroke="#6E3264"
            stroke-width="1.5"
            mask="url(#path-5-outside-1_0_1)"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_0_1"
        x="-548"
        y="-164"
        width="1184"
        height="736.76"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.286275 0 0 0 0 0.27451 0 0 0 0 0.278431 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_1"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_0_1"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="16" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.286275 0 0 0 0 0.27451 0 0 0 0 0.278431 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_0_1"
          result="effect2_dropShadow_0_1"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_0_1"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_0_1"
        x1="-100.832"
        y1="1079.05"
        x2="99.8925"
        y2="-294.997"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0795732" stop-color="#A0DCFF" />
        <stop offset="0.772297" stop-color="#FED7FF" />
      </linearGradient>
      <clipPath id="clip0_0_1">
        <rect
          width="1440"
          height="3998"
          fill="white"
          transform="translate(-676 -262)"
        />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect
          width="1440"
          height="811"
          fill="white"
          transform="translate(-676 -198)"
        />
      </clipPath>
      <clipPath id="clip2_0_1">
        <rect
          width="1440"
          height="811"
          fill="white"
          transform="translate(-676 -198)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Squiggle
