import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    layout: {
      padding: '30px',
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: '150px 1fr 300px',
        gridGap: '40px',
        padding: '30px',
      },
    },
    thumbnail: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        width: 150,
        height: 150,
        borderRadius: '50%',
        background: '#F9F9F9',
        border: '1px solid #E6E7E6',
      },
    },
    textSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      fontSize: '24px',
      fontWeight: 400,
      color: '#6E3264',
      marginTop: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '32px',
      },
    },
    description: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#707070',
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    ctaSection: {
      display: 'flex',
      flexAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    detailsLink: {
      fontWeight: 500,
      fontSize: '16px',
      textDecoration: 'underline',
      color: '#6E3264',
      marginTop: '10px',
      textAlign: 'center',
    },
  })
)

export default useStyles
