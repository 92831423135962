import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import Image from 'components/image'
import Link from 'components/link'
import { isTouchDevice } from 'lib/touch'
import { storeCategoryPath } from 'lib/urls'
import Card from 'components/card/card'
import FaIcon from 'components/fa-icon'
import { track, useTracking } from 'lib/analytics'
import css from './CategoryGrid.module.scss'

interface CategoryProps {
  children?: React.ReactNode
  fullBleedImage?: boolean
  imageUrl: string
  hidden?: boolean
  title: string
  urlSlug: string
  slugSet?: string[]
  useRouter?: boolean
}

interface CalloutProps {
  message: string
  title: string
  small?: boolean
}

export interface CategoryGridProps {
  callout?: CalloutProps
  categories: CategoryProps[]
  parentSlugSet?: string[]
  useRouter?: boolean
  smallGutter?: boolean
}

const CategoryGrid: React.FC<CategoryGridProps> = ({
  callout,
  categories,
  parentSlugSet = [],
  useRouter = true,
  smallGutter = false,
}) => {
  const className = classNames({ phm: smallGutter })
  return (
    <Row>
      {categories.map((cat) => {
        if (cat.hidden) return null
        return (
          <Col
            className={className}
            key={cat.urlSlug}
            md={4}
            xs={6}
            style={{ padding: '2%' }}
          >
            <Category
              {...cat}
              slugSet={[...parentSlugSet, cat.urlSlug]}
              useRouter={useRouter}
            />
          </Col>
        )
      })}
      {callout && (
        <Col className={className} key="callout" md={4} xs={6}>
          <CategoryCallout {...callout} />
        </Col>
      )}
    </Row>
  )
}

const Category: React.FC<CategoryProps> = ({
  imageUrl,
  title,
  urlSlug,
  slugSet,
  useRouter,
  fullBleedImage = false,
}) => {
  const [isTouch, setIsTouch] = useState(false)
  const tracker = useTracking()

  useEffect(() => {
    setIsTouch(isTouchDevice())
  }, [])

  const trackCategoryClick = () => {
    tracker.trackEvent({
      event: track.storeCategoryClicked,
      storeCategoryName: title,
      isLeafCategory: !slugSet || slugSet.length === 0,
      storeCategorySlug: urlSlug,
      eventLocation: track.EventLocation.SHOP_MINUS_IMAGE_NAV,
    })
  }

  const path = storeCategoryPath(urlSlug)
  const linkProps = useRouter ? { to: path, state: { slugSet } } : { url: path }

  return (
    <Card>
      <Link {...linkProps} onClick={trackCategoryClick}>
        <div
          className={classNames(css.wrapper, { [css.hoverEnabled]: !isTouch })}
          data-testid="category-wrapper"
        >
          <div
            className={classNames(
              'hover-overlay hover-darken',
              css.fullHeightPlaceholder
            )}
          >
            <div
              className={classNames({
                [css.categoryImageWrapper]: !fullBleedImage,
              })}
            >
              <Image
                alt={title}
                className="img-responsive center-block"
                lazyLoad={false}
                src={imageUrl}
              />
            </div>
          </div>
          <div className={css.titleWrapper}>
            <div className={css.title}>{title}</div>
            <FaIcon className={css.chevron} icon="chevron-right" />
          </div>
        </div>
      </Link>
    </Card>
  )
}

const CategoryCallout: React.FC<CalloutProps> = ({
  message,
  title,
  small = false,
}) => (
  <div className={css.wrapper}>
    <div className={classNames(css.categoryCallout, { [css.small]: small })}>
      <div className="center-y full-width text-center">
        <div className={classNames(css.heartIcon, 'mtn')}>
          <i className="fa fa-heart icon-filled-primary" />
        </div>
        <div
          className={classNames(css.heading, 'mas', { 'hidden-xs': !small })}
        >
          {title}
        </div>
        <div className={classNames(css.message, 'mas mbm')}>{message}</div>
      </div>
    </div>
  </div>
)

export default CategoryGrid
export { Category, CategoryCallout }
