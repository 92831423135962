/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-default-export */
import { forwardRef } from 'react'
import { childrenOfType } from 'airbnb-prop-types'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ListItem from '../ListItem'
import css from './styles.scss'

/**
 *  Represents a list of items.
 */
const List = forwardRef(function List(props, ref) {
  const { children, className, as: Component, ...rest } = props

  return (
    <Component {...rest} className={classnames(css.root, className)} ref={ref}>
      {children}
    </Component>
  )
})

List.propTypes = {
  children: childrenOfType(ListItem).isRequired,
  className: PropTypes.string,
  as: PropTypes.oneOf(['ul', 'ol']),
}

List.defaultProps = {
  className: undefined,
  as: 'ul',
}

export default List
