import { useEffect } from 'react'
import { useLocation, Outlet } from 'react-router-dom-v5-compat'

interface ScrollToTopProps {
  children?: JSX.Element
  useV6Outlet?: boolean
}

export default function ScrollToTop({
  children = <></>,
  useV6Outlet = false,
}: ScrollToTopProps) {
  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return useV6Outlet ? <Outlet /> : children
}
