import { InfoButton, LinkButton } from 'components/buttons'
import {
  loginPath,
  logoutPath,
  newRegistryPath,
  registryHomePath,
} from 'lib/urls'
import { track } from 'lib/analytics'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import css from './InfoModal.scss'

interface InfoModalProps {
  closeModal: () => void
  email?: string
  provider?: 'Apple' | 'Email' | 'Google'
  type: 'create' | 'existing'
}

const InfoModal = ({ closeModal, email, provider, type }: InfoModalProps) => {
  const [currentUser] = useCurrentUser()

  const handleExistingUserLogin = (provider?: 'Apple' | 'Email' | 'Google') => {
    let userAuthenticationMethod = ''
    switch (provider) {
      case 'Apple':
        userAuthenticationMethod = track.UserAuthenticationMethod.APPLE
        break
      case 'Email':
        userAuthenticationMethod = track.UserAuthenticationMethod.EMAIL
        break
      case 'Google':
        userAuthenticationMethod = track.UserAuthenticationMethod.GOOGLE
        break
    }

    track.signUpStepProcessed({
      eventLocation: track.EventLocation.SIGN_UP_MINUS_EXISTING_ACCOUNT,
      eventCta: provider,
      userAuthenticationMethod: userAuthenticationMethod,
    })

    if (currentUser) {
      track.logInCompleted({
        eventLocation: track.EventLocation.SIGN_UP_MINUS_EXISTING_ACCOUNT,
        userAuthenticationMethod: userAuthenticationMethod,
        userId_: currentUser.id.toString(),
        userEmail: currentUser.email,
        primaryRegistryId: currentUser.registryId || 0,
        userCreatedAt: currentUser.createdAt || '',
        arrivalDate: currentUser.family?.arrivalDate || '',
        isPartner: currentUser.currentRegistry?.ownerId !== currentUser.id,
      })

      window.location.href = registryHomePath
    } else if (provider === 'Email') {
      window.location.href = `${loginPath}?redirect_to_password_form=true`
    } else {
      window.location.href = `${loginPath}?verification_required=true`
    }
  }

  const handleExistingUserLogout = () => {
    track.signUpStepProcessed({
      eventLocation: track.EventLocation.SIGN_UP_MINUS_EXISTING_ACCOUNT,
      eventCta: 'Try Different Email',
      userAuthenticationMethod: track.UserAuthenticationMethod.EMAIL,
    })

    fetch(logoutPath)
    closeModal()
  }

  const handleNoUserSignupClick = () => {
    track.logInStepProcessed({
      eventCta: 'Sign Up',
      eventLocation: track.EventLocation.LOG_IN_MINUS_NO_ACCOUNT,
      userAuthenticationMethod:
        provider || track.UserAuthenticationMethod.EMAIL,
    })

    window.location.href = newRegistryPath
  }

  const handleNoUserBackClick = () => {
    track.logInStepProcessed({
      eventCta: 'Try Different Email',
      eventLocation: track.EventLocation.LOG_IN_MINUS_NO_ACCOUNT,
      userAuthenticationMethod:
        provider || track.UserAuthenticationMethod.EMAIL,
    })

    closeModal()
  }

  return (
    <div className={css.infoModalContainer}>
      <div className={css.infoModalContentContainer}>
        <img
          alt="Babylist Logo"
          className={css.infoModalBabylistLogo}
          src="https://images.babylist.com/image/upload/v1576186809/babylist_3x_snrpbn.png"
        />
        {type === 'create' && (
          <>
            <h1 className={css.infoModalContentHeaderText}>
              Looks like you need to create a Babylist account.
            </h1>
            <div className={css.infoModalEmailText}>
              <span>{email}</span>
            </div>
            <InfoButton
              empty
              pill
              className={css.infoModalSignupButton}
              onClick={handleNoUserSignupClick}
            >
              Get Started!
            </InfoButton>
            <LinkButton
              className={css.infoModalLinkButton}
              onClick={handleNoUserBackClick}
            >
              Try Another Email Address
            </LinkButton>
          </>
        )}
        {type === 'existing' && (
          <>
            <h1 className={css.infoModalContentHeaderText}>
              Looks like you already have an account.
            </h1>
            <div className={css.infoModalEmailText}>
              <span>{email}</span>
            </div>
            <InfoButton
              empty
              pill
              className={css.infoModalSignupButton}
              onClick={() => handleExistingUserLogin(provider)}
            >
              Continue to my Account
            </InfoButton>
            <LinkButton
              className={css.infoModalLinkButton}
              onClick={handleExistingUserLogout}
            >
              Try Another Email Address
            </LinkButton>
          </>
        )}
      </div>
    </div>
  )
}

export default InfoModal
