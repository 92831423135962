import { InfoButton } from 'components/buttons'
import * as blUrls from 'lib/urls'
import { giftTrackerPath } from 'lib/urls'
import Link from 'components/link'
import Image from 'components/image'

export default function GiftReturnsInstructions() {
  return (
    <div className="container container-xs pbxl">
      <div align="center">
        <div className="h1">
          <strong>Open Gift Tracker to Return</strong>
        </div>
        <p>
          The fastest way to return a gift that was purchased from the Babylist
          Shop is via Gift Tracker
        </p>
      </div>
      <Image
        role="presentation"
        className="img-responsive"
        src="https://res.cloudinary.com/babylist/image/upload/c_scale,q_auto,w_auto/v1573672795/Returns/example_reservation.png"
      />
      <div className="mvl text-center">
        <InfoButton pill href={giftTrackerPath()} className="mbl" block>
          Open Gift Tracker
        </InfoButton>
        <Link url={blUrls.loopGiftPath} className="h6 text-underline">
          Return a Babylist Shop item that doesn't appear in Gift Tracker
        </Link>
      </div>
    </div>
  )
}
