import { InfoButton } from 'components/buttons'
import { newRegistryPath } from 'lib/urls'

// @ts-ignore

import css from './styles.scss'
import { MobileBackGroundCircle } from './svg/MobileBackgroundCircle'
import { DesktopBackGroundCircle } from './svg/DesktopBackgroundCircle'
import { DesktopBackGroundFlower } from './svg/DesktopBackgroundFlower'
import { DesktopBackGroundArch } from './svg/DesktopBackgroundArch'

interface SyncChecklistSectionProps {
  acqexPage?: boolean
}

export const SyncChecklistSection = ({
  acqexPage = true,
}: SyncChecklistSectionProps) => (
  <div className={css.sectionContainer}>
    <MobileBackGroundCircle />
    <DesktopBackGroundCircle />
    <DesktopBackGroundFlower />
    <DesktopBackGroundArch />

    <div className={css.contentContainer}>
      <h1 className={css.contentHeader}>
        Sync{acqexPage ? '' : 's'} your checklist with your registry
      </h1>
      <p className={css.contentDesc}>
        Add products from <b>any</b> store and we'll check it off as you go!
      </p>
      <InfoButton
        pill
        className={css.tryButtonCTA}
        href={
          acqexPage
            ? newRegistryPath
            : `${newRegistryPath}?lp_content=checklist`
        }
      >
        Try it now
      </InfoButton>
      {acqexPage && (
        <p className={css.buttonSubtext}>
          It's quick to create a Babylist registry. Then you'll begin checking
          things off your list!
        </p>
      )}
    </div>
    <div className={css.imageContainer}>
      <picture>
        <source srcSet="https://images.babylist.com/image/upload/v1685089036/Checklist_-_raw_design_wr9oqv.png" />
        <img
          alt="Registry Checklist Mobile Asset"
          className={css.registryImage}
        />
      </picture>
    </div>
  </div>
)
