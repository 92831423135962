import Frame from '../../components/Frame'
import SectionHeader from '../../components/SectionHeader'
import Image from '../../../../components/image'
import Link from '../../../../components/link'
import { mobileAppDownloadPath } from '../../../../lib/urls'

export default function HelloBabyBoxSection() {
  return (
    <Frame id="hbb-frame" className="mbxl">
      <div className="strip light-gray-bg" />

      <div className="hbb-content">
        <div className="mlm flex col">
          <SectionHeader>
            <span>FREE*</span>
            <br /> Hello Baby Box
          </SectionHeader>

          <p className="mtl gray-font">
            Create a Babylist registry today to be elligible for a free baby box
            full of goodies for you and baby!
          </p>

          <div>
            <a
              className="mvl btn-pill btn btn-info"
              href={mobileAppDownloadPath()}
            >
              Get Started
            </a>
          </div>

          <small className="tiny-font">
            * Some{' '}
            <Link
              className="eggplant-color"
              newWindow
              url="https://help.babylist.com/hc/en-us/articles/360054057353-How-do-I-get-a-Hello-Baby-Box-"
            >
              restrictions apply
            </Link>
            .
          </small>
        </div>
        <div style={{ width: 150 }} />
      </div>

      <Image
        className="no-pointer-events"
        src="//images.babylist.com/image/upload/f_auto,q_auto,c_scale,w_800/v1634250920/hbb_y09ybf.png"
        alt="Get Your Free* Hello Baby Box"
        lazyLoad={false}
      />
    </Frame>
  )
}
