import { Component } from 'react'
import PropTypes from 'prop-types'
import { autofill, reduxForm, reset } from 'redux-form'
import { values } from 'lodash'

import { Form } from 'components/forms'
import { SaveButton } from 'components/buttons'
import { ModalFooter } from 'components/modal'
import { createValidator } from 'lib/form-validation'
import OrderAddressFields, {
  createValidationRules,
} from './order-address-fields'
import { STATES_AND_PROVINCES } from 'shared/constants'
import { ADDRESS_VALIDATION_ERROR } from '../constants'

import {
  getIsPremiumShipping,
  getIsHbbOrder,
  getShippingAddress,
} from '../reducers'
import {
  saveShippingAddress,
  setActiveModal,
  setOrderStatus,
  setPageMessage,
} from '../actions'
import {
  CONFIRM_SHIPPING_ADDRESS,
  RECOMMEND_SHIPPING_ADDRESS,
} from 'registry/constants'

class OrderAddressForm extends Component {
  static propTypes = {
    errors: PropTypes.object,
    fields: PropTypes.shape({
      city: PropTypes.object,
      country: PropTypes.object,
      name: PropTypes.object,
      phone: PropTypes.object,
      state: PropTypes.object,
      line1: PropTypes.object,
      line2: PropTypes.object,
      zip: PropTypes.object,
    }),
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitFail: PropTypes.func,
  }

  render() {
    const { fields, handleSubmit, invalid, submitting, errors } = this.props
    const hasValidationRuleErrors = !values(errors).every(
      (v) => v === ADDRESS_VALIDATION_ERROR
    )

    return (
      <Form onSubmit={handleSubmit(this._handleSubmit.bind(this))}>
        <OrderAddressFields {...fields} states={STATES_AND_PROVINCES} />
        <ModalFooter>
          <SaveButton
            disabled={invalid && hasValidationRuleErrors}
            submitting={submitting}
            className="btn-pill"
          >
            Confirm Address
          </SaveButton>
        </ModalFooter>
      </Form>
    )
  }

  _handleSubmit(values, dispatch) {
    values.userConfirmed = false
    return dispatch(saveShippingAddress(values))
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    autofillCityState: ({ city, state, zip }) => {
      if (city || state) {
        dispatch(autofill('orderAddressForm', 'city', city))
        dispatch(autofill('orderAddressForm', 'state', state))
        dispatch(autofill('orderAddressForm', 'zip', zip))
      }
    },
    onSubmitSuccess: () => {
      dispatch(reset('orderAddressForm'))
      dispatch(
        setPageMessage(
          "Thank you for confirming your shipping address. We've released the shipping hold on your order."
        )
      )
      dispatch(setOrderStatus('open'))
      window.scrollTo(0, 0)
      PubSub.publish(BLConstants.FLASH_MESSAGE, {
        variant: 'success',
        message: 'Your shipping address has been updated!',
      })
    },
    onSubmitFail: (resp) => {
      let { address, recommendedAddress, fieldErrors } = resp
      if (address) {
        let modalProps = {
          enteredAddress: {
            ...address,
          },
          fieldErrors,
          recommendedAddress,
          onEdit: () => dispatch(setActiveModal(null)),
        }

        let modalForm = recommendedAddress
          ? RECOMMEND_SHIPPING_ADDRESS
          : CONFIRM_SHIPPING_ADDRESS
        dispatch(setActiveModal(modalForm, modalProps))
      } else if (resp.error) {
        PubSub.publish(BLConstants.FLASH_MESSAGE, {
          variant: 'danger',
          message: 'Oops! Something went wrong. Try again in a moment!',
        })
      }
    },
  }
}

export default reduxForm(
  {
    form: 'orderAddressForm',
    fields: [
      'city',
      'country',
      'name',
      'phone',
      'state',
      'line1',
      'line2',
      'zip',
    ],
    validate: (data, props) => {
      const validationRules = createValidationRules(null, {
        isPremiumShipping: props.isPremiumShipping,
        isHBBOrder: props.isHBBOrder,
      })
      return createValidator(validationRules)(data)
    },
  },
  (state) => {
    const address = getShippingAddress(state) || {}
    const isHBBOrder = getIsHbbOrder(state)
    const isPremiumShipping = getIsPremiumShipping(state)
    return {
      initialValues: {
        city: address.city,
        country: address.country || 'United States',
        name: address.name,
        phone: address.phone,
        state: address.state,
        line1: address.line1,
        line2: address.line2,
        zip: address.zip,
      },
      isPremiumShipping: isPremiumShipping,
      isHBBOrder: isHBBOrder,
    }
  },
  mapDispatchToProps
)(OrderAddressForm)
