import React from 'react'

interface VirgoStickerProps {
  className?: string
}

const VirgoSticker: React.FC<VirgoStickerProps> = ({
  className,
}: VirgoStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.9862 94.9297C99.9862 94.9297 115.51 8.20601 61.2446 7.44485C6.98417 6.68369 23.4194 97.0095 23.4194 97.0095C23.4194 97.0095 31.8309 117.74 62.2579 114.7C92.6849 111.656 99.9813 94.9297 99.9813 94.9297H99.9862Z"
      fill="#FFD43E"
    />
    <mask
      id="mask0_1019_6472"
      maskUnits="userSpaceOnUse"
      x="21"
      y="7"
      width="82"
      height="109"
    >
      <path
        d="M99.9859 94.9297C99.9859 94.9297 115.51 8.20601 61.2444 7.44485C6.98392 6.68369 23.4191 97.0095 23.4191 97.0095C23.4191 97.0095 31.8307 117.74 62.2576 114.7C92.6846 111.656 99.9811 94.9297 99.9811 94.9297H99.9859Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1019_6472)">
      <path
        d="M61.8602 21.2847C61.9329 22.6083 61.9669 24.0288 62.1366 25.3717C62.1802 25.7305 62.1948 26.2928 62.1948 26.7243C62.2044 28.0382 62.2238 29.352 62.2869 30.7095C62.2966 30.9131 62.2917 31.1313 62.3111 31.3107C62.4856 33.1772 62.4662 35.0438 62.5681 36.9054C62.6359 38.2193 62.6553 39.5331 62.7911 40.8518C62.8638 41.5791 62.888 42.4517 62.922 43.2565C63.0092 45.6806 63.1014 48.1047 63.1886 50.5287C63.2177 51.2802 63.2274 51.9347 63.0626 52.0365C62.9705 52.0947 62.9268 52.7152 62.8056 52.7492C62.4905 52.8413 62.2044 51.8232 62.0784 49.8985C62.0202 49.0355 61.9911 48.1531 61.9426 47.2853C61.8554 45.6176 61.7099 43.9692 61.7196 42.2772C61.7245 41.676 61.6711 40.9439 61.6275 40.2798C61.5112 38.3841 61.4675 36.5176 61.4142 34.6462C61.3948 34.0499 61.39 33.4245 61.3463 32.8475C61.2348 31.3155 61.1572 29.7835 61.0894 28.2321C61.0554 27.4952 61.0166 26.7825 61.007 26.0359C60.9924 25.265 60.9245 24.436 60.8809 23.6312C60.7645 21.5029 60.6191 19.3794 60.5658 17.2414C60.3525 14.764 60.3476 12.146 60.0907 9.66855C60.0131 8.8977 59.9549 7.9814 59.9598 7.08449C59.9598 4.9804 60.0131 4.75253 60.3912 5.54278C60.4834 5.73186 60.5706 5.97911 60.6579 6.26031C60.7791 6.65785 60.8567 7.13782 60.91 8.02988C60.9827 9.27101 61.1185 10.4879 61.2397 11.6854C61.3366 12.6453 61.4433 13.5568 61.4675 14.6185C61.4772 15.0015 61.5209 15.4136 61.5548 15.8063C61.7099 17.5856 61.8166 19.3794 61.8651 21.2896L61.8602 21.2847Z"
        fill="white"
      />
      <path
        d="M73.4853 27.3548C73.0829 28.0578 72.6369 28.7704 72.2345 29.5073C71.8321 30.2491 71.4394 30.9957 71.0903 31.7423C70.8964 32.1399 70.5667 32.7362 70.3097 33.2016C69.9219 33.9046 69.5292 34.6076 69.1365 35.3154C68.7535 36.0281 68.3996 36.765 68.036 37.5116C67.9293 37.7347 67.7984 37.9674 67.7111 38.171C66.8142 40.2751 65.7864 42.3113 64.9283 44.4251C64.6132 45.162 64.3077 45.9086 64.0217 46.6601C63.7308 47.4116 63.4448 48.163 63.1878 48.9339C62.926 49.792 62.5479 50.7859 62.2133 51.7119C61.7091 53.1081 61.2583 54.5189 60.7831 55.9201C60.3613 57.3406 59.8862 58.7465 59.5032 60.1767C59.256 61.0639 59.0281 61.8299 58.839 61.8978C58.7275 61.9366 58.4657 62.6541 58.3348 62.6541C58.0003 62.6638 58.0973 61.3888 58.679 59.081C58.9602 58.0532 59.2851 57.0157 59.5856 55.9831C60.1965 54.0196 60.7492 52.0512 61.554 50.1314C61.84 49.4478 62.1067 48.58 62.4073 47.8188C62.8242 46.728 63.2266 45.642 63.6872 44.5802C64.1477 43.5185 64.6035 42.4616 65.0834 41.4095C65.3986 40.7405 65.7331 40.0521 66.0143 39.3976C66.3779 38.5249 66.7851 37.6668 67.1924 36.8135C67.6045 35.9602 68.002 35.0973 68.4529 34.2537C68.8747 33.4489 69.2722 32.6683 69.7134 31.8587C70.1885 31.0345 70.6588 30.1279 71.1339 29.2552C71.7593 28.1014 72.4381 26.9766 73.0877 25.8373C73.7519 24.7077 74.4695 23.6023 75.1821 22.4921C76.7723 19.8692 78.6776 17.2658 80.4133 14.7399C80.9466 13.9496 81.6108 13.0285 82.338 12.1946C84.0203 10.2214 84.2385 10.0469 83.8894 11.0408C83.807 11.2783 83.6761 11.5692 83.5161 11.8892C83.2931 12.3401 82.9683 12.8394 82.2992 13.7024C81.4072 14.9338 80.549 16.1652 79.7588 17.4064C79.1334 18.4051 78.5128 19.3408 77.7807 20.4074C77.5141 20.7904 77.2523 21.2267 77.0002 21.634C76.7141 22.0994 76.4233 22.56 76.1324 23.0254C75.8463 23.4956 75.5603 23.9659 75.2743 24.4459C74.9785 24.921 74.6828 25.3961 74.3822 25.8761C74.0864 26.3609 73.7907 26.8554 73.4853 27.3548Z"
        fill="white"
      />
      <path
        d="M81.0198 29.0522C80.1325 29.9297 79.1775 30.8703 78.3727 31.8593C78.1545 32.1211 77.7812 32.4944 77.4903 32.7804C76.6031 33.6434 75.7207 34.5161 74.8675 35.4712C74.7414 35.6118 74.5911 35.7572 74.4796 35.8881C73.3645 37.2601 72.1331 38.511 71.0083 39.8684C70.2084 40.8138 69.4036 41.7592 68.6618 42.7676C68.2643 43.3349 67.7407 43.97 67.2656 44.5566C66.5529 45.4487 65.8548 46.3553 65.1518 47.257C64.4682 48.1733 63.7652 49.0751 63.0962 50.0011C62.6792 50.5732 62.3059 51.0628 62.1168 51.0386C62.0102 51.024 61.6126 51.4604 61.4963 51.4119C61.1908 51.2858 61.5593 50.3598 62.592 48.8424C63.0622 48.1733 63.5664 47.5043 64.0561 46.8352C65.0015 45.565 65.8935 44.2706 66.965 43.0779C67.348 42.6513 67.7552 42.0889 68.1528 41.5944C69.263 40.1787 70.4411 38.8503 71.6289 37.5268C72.0119 37.105 72.4143 36.6784 72.7682 36.2566C73.7039 35.1367 74.6784 34.0555 75.682 32.9841C76.1619 32.475 76.6225 31.9805 77.1219 31.4811C77.6455 30.9721 78.1739 30.3855 78.7023 29.8328C80.1083 28.3783 81.5191 26.9336 83.022 25.5664C84.6753 23.8793 86.5854 22.2891 88.2823 20.6455C88.8059 20.1316 89.4604 19.5499 90.144 19.0408C91.739 17.8385 91.9378 17.7512 91.5693 18.5027C91.4821 18.682 91.3463 18.8954 91.1863 19.1232C90.9633 19.448 90.6482 19.7826 90.0082 20.3304C89.1356 21.111 88.3017 21.9158 87.5017 22.7205C86.8618 23.3702 86.2509 23.9811 85.5043 24.6453C85.2328 24.8828 84.9613 25.1689 84.7044 25.4307C84.108 26.027 83.5117 26.6233 82.9008 27.2293C82.2803 27.8256 81.6549 28.4317 81.0052 29.0571L81.0198 29.0522Z"
        fill="white"
      />
      <path
        d="M87.0213 33.6285C86.0711 34.1521 85.0578 34.7145 84.1658 35.369C83.9234 35.5386 83.5258 35.7665 83.2204 35.941C82.2799 36.4598 81.349 36.9931 80.4279 37.6185C80.2921 37.7106 80.1321 37.7979 80.0158 37.8851C78.7989 38.8111 77.4899 39.6014 76.273 40.5274C75.4052 41.1625 74.5471 41.8121 73.7326 42.5248C73.2962 42.9369 72.7339 43.3732 72.2199 43.7853C71.4491 44.4107 70.6976 45.0555 69.9413 45.6955C69.2044 46.3597 68.4481 46.9997 67.7257 47.6784C67.2748 48.1002 66.8773 48.4541 66.7028 48.3862C66.6058 48.3474 66.1889 48.648 66.087 48.5753C65.8155 48.3911 66.2616 47.6348 67.3912 46.5003C67.9051 46.0058 68.4529 45.521 68.9814 45.0313C70.0092 44.1102 70.9982 43.1551 72.1424 42.3503C72.5496 42.0642 73.0005 41.6522 73.432 41.3128C74.644 40.3189 75.9142 39.4317 77.1893 38.5542C77.6014 38.2778 78.0329 38.0015 78.4207 37.7155C78.9249 37.3324 79.4534 36.9737 79.9818 36.6198C80.5103 36.2659 81.0387 35.9022 81.5866 35.5726C82.1053 35.2526 82.5998 34.9375 83.138 34.6272C83.7004 34.3218 84.2724 33.9484 84.8445 33.6042C86.362 32.7073 87.8892 31.8298 89.4842 31.0638C91.2829 30.0554 93.2803 29.2263 95.1274 28.3052C95.6995 28.0143 96.3928 27.6992 97.1006 27.4762C98.7539 26.9283 98.9478 26.9186 98.4484 27.481C98.3321 27.6168 98.1624 27.7622 97.9733 27.9174C97.7067 28.1355 97.3527 28.3343 96.6788 28.6155C95.748 29.0324 94.8511 29.4785 93.9833 29.9487C93.29 30.3269 92.6258 30.6808 91.8404 31.0444C91.5544 31.1753 91.2586 31.345 90.9823 31.5001C90.3375 31.8492 89.6975 32.2031 89.0479 32.557C88.3885 32.9061 87.7195 33.26 87.031 33.6236L87.0213 33.6285Z"
        fill="white"
      />
      <path
        d="M85.8533 40.969C84.8352 41.2356 83.7395 41.5119 82.736 41.9046C82.4645 42.0065 82.0378 42.118 81.7033 42.2004C80.69 42.4428 79.6768 42.7094 78.649 43.044C78.4938 43.0924 78.329 43.1312 78.1884 43.1846C76.8018 43.7324 75.3668 44.1106 73.9802 44.6342C72.996 44.9881 72.0119 45.342 71.0519 45.7783C70.5283 46.0304 69.8787 46.2777 69.2824 46.5104C67.4934 47.2231 65.7141 47.9697 63.9542 48.7551C63.4064 49.0023 62.9313 49.206 62.7907 49.0799C62.7131 49.012 62.2331 49.1575 62.1604 49.0557C61.9665 48.7939 62.6065 48.2315 63.993 47.5382C64.6184 47.2376 65.2681 46.9613 65.9032 46.6704C67.1249 46.1322 68.3224 45.5408 69.612 45.1287C70.0726 44.9832 70.6059 44.736 71.1053 44.542C72.5112 43.9651 73.9366 43.4948 75.3619 43.0294C75.8177 42.884 76.2976 42.7434 76.734 42.5834C77.8878 42.1519 79.0611 41.7737 80.2489 41.4101C80.8161 41.2404 81.3591 41.0756 81.9409 40.9205C82.542 40.775 83.1771 40.5666 83.7977 40.3872C85.4412 39.9218 87.0847 39.4612 88.767 39.1121C90.6918 38.574 92.7619 38.2491 94.7012 37.7595C95.3024 37.6043 96.0247 37.4589 96.7423 37.391C98.4246 37.2165 98.6088 37.2504 98.0222 37.6916C97.8816 37.7983 97.6973 37.9049 97.484 38.0164C97.1834 38.1716 96.8101 38.2879 96.112 38.414C95.1424 38.6128 94.1921 38.8503 93.271 39.1024C92.5341 39.3109 91.8262 39.5 90.9972 39.6648C90.7015 39.723 90.3815 39.8151 90.0809 39.8975C89.3925 40.0817 88.704 40.266 88.0059 40.4502C87.3029 40.6199 86.5854 40.7944 85.8485 40.9738L85.8533 40.969Z"
        fill="white"
      />
      <path
        d="M88.2289 53.4926C86.8472 53.5363 85.3636 53.5557 83.9577 53.7108C83.5795 53.7496 82.9977 53.7593 82.542 53.7593C81.17 53.7593 79.798 53.769 78.3775 53.8369C78.169 53.8465 77.9363 53.8417 77.7472 53.8611C75.7982 54.0405 73.8493 54.0356 71.9052 54.1568C70.5332 54.2392 69.1611 54.2877 67.7843 54.4429C67.0231 54.535 66.1116 54.5786 65.2729 54.6271C62.7422 54.7822 60.2163 54.9519 57.6904 55.1362C56.905 55.1943 56.2214 55.2331 56.1148 55.0731C56.0517 54.981 55.4021 54.9665 55.3585 54.8453C55.2518 54.5301 56.3038 54.2053 58.311 53.9968C59.2079 53.9096 60.1339 53.8465 61.0405 53.769C62.781 53.6284 64.5021 53.4296 66.2716 53.4054C66.9019 53.3957 67.6679 53.3229 68.3612 53.2696C70.3392 53.1145 72.293 53.0515 74.2565 52.9787C74.8819 52.9593 75.5316 52.9448 76.1376 52.9012C77.7424 52.7848 79.3471 52.7024 80.9664 52.6442C81.7372 52.6151 82.4838 52.5812 83.2644 52.5715C84.074 52.5666 84.9418 52.5085 85.7854 52.4745C88.0156 52.3873 90.2409 52.2854 92.4807 52.2854C95.0793 52.14 97.8185 52.2176 100.422 52.0721C101.232 52.0236 102.192 52.0091 103.132 52.0527C105.328 52.1545 105.566 52.2176 104.722 52.5569C104.519 52.6394 104.257 52.7169 103.961 52.7897C103.539 52.8915 103.035 52.9496 102.104 52.9593C100.805 52.9836 99.5299 53.0612 98.2742 53.1387C97.2707 53.2017 96.3108 53.2696 95.2054 53.2648C94.8078 53.2648 94.3715 53.2939 93.9642 53.3133C92.1026 53.4151 90.2263 53.4781 88.2289 53.4926Z"
        fill="white"
      />
      <path
        d="M89.6782 59.8812C88.5535 59.7503 87.3511 59.5951 86.1924 59.5854C85.8821 59.5806 85.407 59.5224 85.0434 59.4739C83.9332 59.3188 82.8181 59.183 81.6594 59.1054C81.4897 59.0909 81.3007 59.0667 81.1455 59.0618C79.5456 59.0424 77.9603 58.8533 76.3701 58.8049C75.2502 58.7612 74.1254 58.7031 72.9958 58.7418C72.3704 58.7758 71.6237 58.7515 70.9402 58.7418C68.87 58.7128 66.795 58.7127 64.7248 58.7564C64.08 58.7709 63.5225 58.7709 63.4352 58.6061C63.3868 58.514 62.8535 58.4606 62.8244 58.3394C62.7468 58.0195 63.6146 57.7528 65.2727 57.6559C66.0144 57.6219 66.7708 57.6171 67.5222 57.5977C68.9573 57.5734 70.3826 57.4862 71.8322 57.6025C72.351 57.6413 72.9812 57.6219 73.5533 57.6316C75.1871 57.6365 76.787 57.7431 78.3966 57.8546C78.9105 57.8934 79.439 57.9467 79.9432 57.9613C81.2619 58.0001 82.5806 58.0922 83.909 58.2037C84.5392 58.2619 85.1501 58.3104 85.79 58.3879C86.4494 58.4752 87.1621 58.5188 87.8505 58.5867C89.6734 58.7661 91.4963 58.9503 93.3095 59.2509C95.4427 59.4642 97.6438 59.9248 99.7721 60.1817C100.431 60.2593 101.212 60.3854 101.959 60.5841C103.714 61.035 103.888 61.132 103.142 61.3308C102.962 61.3792 102.734 61.4132 102.482 61.4374C102.123 61.4713 101.706 61.4471 100.955 61.3065C99.9078 61.132 98.8655 61.0108 97.8425 60.9041C97.0232 60.8217 96.2426 60.7441 95.3506 60.589C95.0306 60.5308 94.6718 60.5017 94.3422 60.4678C93.581 60.3805 92.8247 60.2981 92.049 60.2108C91.2781 60.109 90.4927 60.0024 89.6831 59.8957L89.6782 59.8812Z"
        fill="white"
      />
      <path
        d="M89.8917 72.4729C88.5682 72.1529 87.1525 71.7941 85.7756 71.5808C85.4072 71.5226 84.8496 71.382 84.4181 71.2657C83.114 70.9166 81.8001 70.5772 80.433 70.2863C80.2294 70.2427 80.0112 70.1845 79.827 70.1506C77.9265 69.8355 76.0696 69.3458 74.1837 68.987C72.8553 68.7252 71.5318 68.4441 70.184 68.2598C69.4374 68.1629 68.555 67.9883 67.7405 67.8332C65.2874 67.3775 62.8294 66.9411 60.3713 66.529C59.6053 66.403 58.946 66.2818 58.8781 66.0976C58.8393 65.9957 58.2188 65.8261 58.2091 65.7C58.18 65.3703 59.2611 65.2976 61.2343 65.5643C62.1167 65.6903 63.0136 65.8454 63.9008 65.986C65.6025 66.2624 67.2945 66.4806 68.9913 66.8829C69.5974 67.0284 70.344 67.1351 71.0227 67.2563C72.9523 67.5908 74.8334 68.0029 76.7241 68.4198C77.3253 68.5556 77.9507 68.7059 78.5373 68.8125C80.0984 69.0986 81.645 69.4282 83.2061 69.7773C83.9479 69.947 84.6654 70.1021 85.412 70.2912C86.1829 70.4948 87.0264 70.6597 87.8361 70.8439C89.979 71.3336 92.1219 71.8184 94.2502 72.405C96.7615 72.9528 99.3359 73.7528 101.847 74.3152C102.628 74.4897 103.544 74.7321 104.417 75.0327C106.472 75.7308 106.676 75.852 105.784 75.949C105.571 75.9732 105.299 75.9732 104.999 75.9635C104.572 75.949 104.077 75.8617 103.195 75.6193C101.959 75.2945 100.727 75.0181 99.5201 74.7612C98.5505 74.5576 97.6245 74.3636 96.5773 74.0631C96.1991 73.9564 95.7774 73.8691 95.3847 73.7819C93.5908 73.3843 91.7922 72.9577 89.8917 72.4535V72.4729Z"
        fill="white"
      />
      <path
        d="M93.0043 83.0842C92.0977 82.7884 91.1183 82.4733 90.1584 82.3085C89.9014 82.26 89.5184 82.1388 89.2179 82.0467C88.3209 81.7509 87.4095 81.4697 86.4544 81.2516C86.3138 81.2176 86.1635 81.1691 86.0326 81.1449C84.6994 80.9316 83.4049 80.5583 82.0765 80.3401C81.1457 80.1704 80.21 80.0056 79.2549 79.9232C78.7265 79.8941 78.1059 79.802 77.529 79.7195C76.6612 79.6032 75.7933 79.5159 74.9255 79.4141C74.0529 79.3462 73.185 79.2444 72.3124 79.1959C71.7694 79.162 71.2991 79.1184 71.2409 78.9487C71.207 78.8517 70.761 78.7596 70.7464 78.6336C70.7028 78.3087 71.4591 78.11 72.8699 78.139C73.5002 78.1633 74.1401 78.2263 74.7801 78.2651C75.997 78.3621 77.209 78.4057 78.421 78.6675C78.8525 78.7596 79.3907 78.7935 79.8706 78.8663C81.2475 79.0408 82.5856 79.322 83.9188 79.6129C84.3455 79.7147 84.7818 79.8262 85.2036 79.8989C85.7563 79.991 86.3041 80.1074 86.852 80.2334C87.3998 80.3595 87.9525 80.4758 88.4955 80.631C89.0142 80.7716 89.5184 80.8976 90.042 81.0576C90.5753 81.237 91.1668 81.3776 91.734 81.5376C93.2273 81.9691 94.7205 82.42 96.1749 82.992C97.9154 83.535 99.6511 84.3398 101.362 84.9604C101.896 85.1495 102.516 85.4064 103.088 85.7361C104.431 86.4876 104.553 86.6136 103.874 86.6669C103.714 86.6815 103.51 86.6669 103.292 86.6475C102.982 86.6185 102.642 86.5166 102.056 86.25C101.227 85.9058 100.393 85.6003 99.5638 85.3288C98.8996 85.1155 98.2693 84.9071 97.5615 84.6162C97.3094 84.5095 97.0185 84.4222 96.7519 84.3398C96.1362 84.1411 95.5253 83.9423 94.8999 83.7387C94.2793 83.5253 93.6491 83.3072 92.9994 83.0793L93.0043 83.0842Z"
        fill="white"
      />
      <path
        d="M90.5893 93.0767C89.6876 92.8537 88.7277 92.5773 87.7871 92.4464C87.535 92.4125 87.1568 92.301 86.866 92.2089C85.9884 91.9277 85.1061 91.6562 84.1801 91.4235C84.0443 91.3896 83.8989 91.3362 83.7728 91.312C82.4784 91.0696 81.2373 90.6429 79.967 90.3327C79.0701 90.1096 78.1878 89.843 77.2715 89.683C76.7624 89.5957 76.1758 89.4261 75.628 89.2806L70.7022 87.9425C70.1932 87.8019 69.752 87.671 69.7326 87.4916C69.7229 87.3898 69.3157 87.2104 69.3302 87.0892C69.3593 86.7644 70.1156 86.7159 71.4343 87.0165C72.0258 87.1523 72.6221 87.3123 73.2184 87.4577C74.3529 87.7389 75.4971 87.9571 76.617 88.3401C77.0194 88.4758 77.5187 88.5825 77.9745 88.6891C79.2689 88.9946 80.5246 89.363 81.7899 89.7218C82.1923 89.8381 82.6093 89.9642 83.0068 90.0515C84.0589 90.2842 85.1061 90.5411 86.1581 90.8175C86.6575 90.9484 87.1423 91.0647 87.6465 91.2199C88.1653 91.375 88.7373 91.4914 89.2852 91.6223C90.7348 91.981 92.1941 92.301 93.634 92.7179C95.3454 93.0573 97.0956 93.6245 98.8166 93.9203C99.3499 94.0124 99.9802 94.1627 100.577 94.3615C101.978 94.8269 102.113 94.9239 101.483 95.1129C101.333 95.1566 101.139 95.1905 100.93 95.2099C100.63 95.239 100.29 95.2099 99.6796 95.0644C98.8312 94.8657 97.9828 94.7251 97.1489 94.5796C96.4847 94.4633 95.8447 94.3615 95.1321 94.1578C94.8751 94.0851 94.5842 94.0318 94.3176 93.9785C93.091 93.7361 91.8596 93.4452 90.5748 93.0767H90.5893Z"
        fill="white"
      />
      <path
        d="M51.911 20.2096C52.6528 21.3731 53.4285 22.6337 54.2769 23.7536C54.5048 24.0542 54.8054 24.5487 55.0381 24.9365C55.7362 26.1098 56.4537 27.2782 57.2391 28.4563C57.3555 28.6308 57.4718 28.8296 57.5833 28.9847C58.7372 30.5604 59.7359 32.2281 60.8413 33.8183C61.617 34.9479 62.3685 36.0921 63.212 37.1829C63.6823 37.7841 64.1914 38.5356 64.6665 39.224C66.1064 41.299 67.5608 43.3643 69.0298 45.4151C69.4855 46.0502 69.8734 46.6126 69.7958 46.792C69.7522 46.8938 70.077 47.4562 69.9946 47.5531C69.7813 47.8052 68.9571 47.0829 67.7354 45.483C67.1924 44.7654 66.6591 44.014 66.1209 43.2771C65.098 41.8662 64.0362 40.5039 63.1005 39.0058C62.766 38.4725 62.3054 37.8617 61.9079 37.2944C60.754 35.6849 59.6923 34.051 58.6209 32.4124C58.2815 31.8888 57.9373 31.3409 57.5882 30.8416C56.6622 29.5326 55.775 28.1993 54.8878 26.8467C54.4708 26.2019 54.0587 25.5813 53.6515 24.9171C53.2346 24.2287 52.74 23.516 52.2795 22.813C51.0674 20.9465 49.8457 19.0945 48.7064 17.1746C47.2616 15.0172 45.9381 12.6271 44.4934 10.4696C44.0425 9.80061 43.5431 8.98127 43.1116 8.15224C42.0887 6.20813 42.0256 5.97542 42.7432 6.53295C42.9129 6.66385 43.1165 6.85293 43.3298 7.0711C43.6304 7.38138 43.9358 7.78863 44.4109 8.58372C45.0897 9.68425 45.7975 10.746 46.5005 11.7786C47.0629 12.6077 47.6059 13.3979 48.1634 14.3482C48.3622 14.6924 48.6094 15.0511 48.8373 15.3857C49.8699 16.9322 50.8784 18.5079 51.9062 20.2096H51.911Z"
        fill="white"
      />
      <path
        d="M68.8511 19.0165C68.8511 20.1316 68.8123 21.3291 68.9142 22.4684C68.9432 22.7738 68.9239 23.2441 68.9045 23.6077C68.8463 24.7131 68.793 25.8233 68.7881 26.9675C68.7881 27.1372 68.7736 27.3214 68.7784 27.4765C68.856 29.057 68.7396 30.6278 68.7396 32.1986C68.7396 33.3089 68.6911 34.4142 68.7542 35.5293C68.7881 36.145 68.7687 36.8819 68.7542 37.5607L68.6427 43.6936C68.6281 44.3287 68.6039 44.8814 68.4342 44.9541C68.3372 44.9977 68.2645 45.5213 68.1385 45.5407C67.8185 45.6038 67.5858 44.7359 67.5615 43.107C67.5518 42.3797 67.5615 41.638 67.5615 40.9011C67.5615 39.4951 67.4985 38.0988 67.6003 36.6783C67.6342 36.1741 67.6197 35.5536 67.6149 34.9912C67.5955 33.3913 67.6536 31.8205 67.6973 30.24C67.7118 29.7358 67.7361 29.2122 67.7264 28.7225C67.6973 27.4281 67.6973 26.1336 67.7118 24.8295C67.7167 24.2089 67.7167 23.6077 67.7409 22.9775C67.77 22.3278 67.7409 21.6248 67.7409 20.9461C67.7409 19.1474 67.7021 17.3585 67.7603 15.5549C67.6779 13.4606 67.8088 11.2546 67.6827 9.16024C67.6439 8.51059 67.6391 7.73489 67.6827 6.97858C67.7942 5.20901 67.8573 5.01993 68.1918 5.70352C68.2694 5.86835 68.3469 6.08167 68.4196 6.31923C68.5215 6.6586 68.5748 7.06585 68.5796 7.81731C68.5893 8.86451 68.6572 9.89716 68.7154 10.9056C68.7639 11.7152 68.822 12.4909 68.7881 13.383C68.7784 13.703 68.7978 14.0569 68.8123 14.3865C68.8705 15.8895 68.8899 17.4069 68.8366 19.0165H68.8511Z"
        fill="white"
      />
      <path
        d="M56.4928 16.7134C56.7062 17.9545 56.9098 19.2926 57.2346 20.5385C57.3219 20.8731 57.3995 21.3967 57.4576 21.8088C57.6273 23.0499 57.8067 24.2862 58.0443 25.5564C58.0782 25.7455 58.1024 25.9539 58.1461 26.1236C58.563 27.8592 58.7957 29.6191 59.1545 31.3596C59.4017 32.5862 59.6199 33.8176 59.9399 35.0394C60.1241 35.7132 60.2792 36.5277 60.4295 37.2792C60.8901 39.5433 61.3604 41.8025 61.8452 44.0569C61.9955 44.7599 62.1167 45.3708 61.9713 45.492C61.8888 45.5598 61.9519 46.1562 61.8355 46.2095C61.5398 46.3501 61.0889 45.4532 60.6429 43.6642C60.4489 42.8643 60.2744 42.0401 60.0853 41.2304C59.7363 39.679 59.329 38.1519 59.0915 36.5568C59.0042 35.9896 58.8393 35.306 58.7036 34.6903C58.3109 32.9256 58.0055 31.1705 57.6952 29.4058C57.5982 28.8434 57.5061 28.2568 57.3898 27.7138C57.0746 26.2836 56.8031 24.8437 56.5413 23.3893C56.4201 22.696 56.2941 22.0269 56.1874 21.3239C56.0808 20.5967 55.9159 19.821 55.7802 19.0647C55.4214 17.0624 55.0433 15.0698 54.7621 13.0482C54.2966 10.721 54.03 8.2388 53.5597 5.91169C53.4143 5.18931 53.2737 4.32149 53.201 3.46822C53.0264 1.47078 53.0604 1.24777 53.5015 1.96529C53.6082 2.13498 53.7197 2.36284 53.8264 2.62464C53.9815 2.98825 54.0979 3.43913 54.2288 4.27786C54.413 5.44626 54.6506 6.59043 54.8881 7.71035C55.0772 8.60725 55.2614 9.46053 55.3972 10.4641C55.4456 10.8229 55.5281 11.2107 55.6008 11.5792C55.9305 13.2469 56.231 14.9292 56.4928 16.7279V16.7134Z"
        fill="white"
      />
      <path
        d="M44.1497 27.8883C45.3181 29.1246 46.5205 30.4918 47.7664 31.6941C48.101 32.0189 48.5712 32.5571 48.93 32.9837C50.016 34.2685 51.1117 35.5532 52.2607 36.8622C52.4303 37.0561 52.6097 37.2743 52.7697 37.444C54.4181 39.1893 55.9065 41.0656 57.4676 42.8788C58.573 44.149 59.6153 45.4774 60.7692 46.7282C61.3994 47.4263 62.0976 48.299 62.7521 49.0989C64.7156 51.5133 66.65 53.9519 68.5504 56.4051C69.1419 57.1711 69.6461 57.8402 69.5831 58.0389C69.5492 58.1504 69.9855 58.8195 69.9128 58.9213C69.7188 59.1928 68.7201 58.3056 67.1639 56.3906C66.4706 55.5324 65.7628 54.6501 65.0598 53.7823C63.7071 52.129 62.3254 50.5291 61.0164 48.8081C60.551 48.1972 59.9401 47.4797 59.3826 46.8397C57.8021 44.9974 56.2895 43.1503 54.7526 41.308C54.2581 40.7213 53.7539 40.1105 53.2545 39.5578C51.9407 38.0937 50.6317 36.6295 49.3081 35.1557C48.6779 34.4527 48.067 33.7788 47.4465 33.0516C46.7968 32.305 46.055 31.5438 45.3569 30.7875C43.5049 28.7852 41.6093 26.8314 39.7621 24.8146C37.4932 22.5796 35.2582 20.0925 32.9166 17.9351C32.1942 17.266 31.3603 16.437 30.5749 15.6031C28.7423 13.6396 28.5775 13.3923 29.5471 13.8772C29.7798 13.9935 30.0658 14.168 30.3713 14.3717C30.8076 14.6577 31.2876 15.0601 32.1069 15.8552C33.2365 16.9751 34.4001 18.0272 35.52 19.0841C36.4169 19.9325 37.2896 20.7227 38.2107 21.7215C38.5453 22.0802 38.9283 22.4487 39.287 22.8026C40.9208 24.4025 42.5256 26.0654 44.1594 27.8883H44.1497Z"
        fill="white"
      />
      <path
        d="M36.3007 32.8245C37.4352 33.3675 38.623 33.9977 39.8108 34.4874C40.1307 34.6183 40.5962 34.8752 40.9501 35.0789C42.0312 35.7043 43.1124 36.32 44.2468 36.9308C44.4165 37.023 44.591 37.1296 44.7462 37.2072C46.3461 37.9829 47.8441 38.9283 49.381 39.8009C50.467 40.4167 51.5239 41.0809 52.639 41.6626C53.2595 41.9826 53.9577 42.4238 54.6073 42.8262C56.5611 44.0431 58.4955 45.289 60.4105 46.5641C61.002 46.9617 61.5111 47.3204 61.4868 47.5095C61.4771 47.6162 61.928 47.9992 61.8795 48.1155C61.7583 48.4258 60.8226 48.0816 59.2761 47.1216C58.5876 46.6853 57.8847 46.2393 57.1865 45.7981C55.8533 44.9642 54.4861 44.2031 53.1723 43.3013C52.702 42.9765 52.096 42.6468 51.553 42.3317C49.9967 41.4445 48.4938 40.5233 46.9666 39.6313C46.477 39.3452 45.9776 39.0398 45.4879 38.7877C44.2032 38.1089 42.9233 37.435 41.624 36.7563C41.0083 36.4315 40.4168 36.1164 39.7914 35.7818C39.1514 35.4279 38.4387 35.0982 37.7552 34.7637C35.9516 33.8717 34.1239 33.0378 32.3155 32.1409C30.1581 31.244 27.9425 30.1628 25.6833 29.6877C24.9851 29.5374 24.1707 29.358 23.361 29.1835C22.4205 28.9799 21.892 28.912 21.6981 28.8199C21.5042 28.7326 21.6399 28.6454 22.0326 28.5096C22.2217 28.4417 22.4641 28.3884 22.7404 28.3545C23.1331 28.3011 23.6034 28.306 24.4373 28.4708C25.6009 28.6987 26.7499 28.9556 27.8552 29.2756C28.7425 29.5277 29.5812 29.7992 30.5023 30.2162C30.8369 30.3665 31.2053 30.507 31.5544 30.6428C33.1349 31.2779 34.6814 31.9954 36.2862 32.8293L36.3007 32.8245Z"
        fill="white"
      />
      <path
        d="M38.5401 44.59C40.1885 44.8227 41.9435 45.1233 43.6307 45.2833C44.0864 45.3269 44.7748 45.4578 45.3081 45.5596C46.9274 45.8796 48.5418 46.1996 50.2241 46.4905C50.4714 46.5341 50.7429 46.5923 50.9708 46.6262C53.2979 46.951 55.5813 47.4746 57.8794 47.8964C59.4986 48.1922 61.1131 48.5461 62.7566 48.7933C63.6632 48.9291 64.7346 49.1569 65.7237 49.3606C68.7004 49.9811 71.6772 50.6017 74.6491 51.2271C75.5751 51.421 76.3702 51.6052 76.4526 51.7943C76.4962 51.901 77.2477 52.124 77.2574 52.2549C77.2865 52.5894 75.9775 52.5652 73.6019 52.1192C72.5402 51.9107 71.459 51.6925 70.3827 51.484C68.332 51.0816 66.286 50.7423 64.2304 50.2478C63.4984 50.0684 62.5918 49.9132 61.7724 49.763C59.4356 49.3363 57.1473 48.8563 54.8444 48.4006C54.1075 48.2552 53.3512 48.0903 52.6288 47.9788C50.7283 47.6637 48.8376 47.3437 46.9226 47.0237C46.0159 46.8638 45.1336 46.728 44.2124 46.5583C43.2622 46.3789 42.2295 46.2529 41.2308 46.1026C38.5886 45.7147 35.956 45.3608 33.309 45.0312C30.2255 44.7936 26.9724 44.4979 23.9181 44.8372C22.9679 44.939 21.8576 45.0651 20.7571 45.1863C19.4772 45.3366 18.7742 45.4723 18.493 45.453C18.2167 45.4384 18.3621 45.3075 18.8178 45.036C19.036 44.91 19.3269 44.7597 19.6663 44.6239C20.1462 44.4397 20.7377 44.2652 21.8528 44.1246C23.409 43.9258 24.9459 43.7755 26.4634 43.7028C27.6754 43.6397 28.839 43.6252 30.177 43.7319C30.657 43.7755 31.1855 43.7852 31.6751 43.8143C33.9247 43.9355 36.1791 44.207 38.545 44.5948L38.5401 44.59Z"
        fill="white"
      />
      <path
        d="M23.7336 57.8451C24.5287 57.8936 25.382 57.9857 26.2013 57.942C26.4195 57.9275 26.754 57.9711 27.0158 58.0051C27.8012 58.1166 28.5866 58.2232 29.406 58.2863C29.5272 58.296 29.6581 58.3202 29.7696 58.3202C30.8992 58.3202 32.0143 58.5141 33.1342 58.5965C33.9244 58.6547 34.7098 58.7614 35.5098 58.7565C35.951 58.7565 36.4746 58.8147 36.9594 58.8632L41.3178 59.3238C41.7687 59.3723 42.1614 59.4304 42.2002 59.605C42.2245 59.7019 42.5881 59.8086 42.5929 59.9346C42.6075 60.2595 41.9723 60.4388 40.8088 60.3661C40.29 60.337 39.7616 60.2789 39.238 60.2352C38.2344 60.1577 37.2406 60.1383 36.237 59.9589C35.8782 59.8959 35.437 59.8765 35.0395 59.8522C33.9002 59.7843 32.7851 59.6437 31.6603 59.5225C31.3016 59.4838 30.9331 59.4304 30.5792 59.4207C29.6532 59.3819 28.7321 59.3238 27.8012 59.2462C27.36 59.2123 26.9334 59.1832 26.4825 59.125C26.0219 59.0668 25.5177 59.062 25.0378 59.0329C23.7579 58.9553 22.478 58.9117 21.1932 58.7759C19.6951 58.7759 18.1292 58.5481 16.6262 58.5917C16.1608 58.6062 15.6081 58.582 15.07 58.5044C13.8143 58.3251 13.6834 58.2572 14.1876 57.9469C14.3088 57.8742 14.464 57.8063 14.6385 57.7433C14.8858 57.656 15.1815 57.6172 15.7196 57.6366C16.4662 57.6706 17.208 57.6366 17.9352 57.6221C18.517 57.6075 19.0746 57.5833 19.7097 57.6463C19.9375 57.6706 20.1896 57.6657 20.4272 57.6657C21.5035 57.6657 22.5895 57.719 23.7336 57.8402V57.8451Z"
        fill="white"
      />
      <path
        d="M34.9041 64.0951C36.3973 64.003 37.9972 63.9787 39.5147 63.8284C39.9219 63.7848 40.5522 63.7848 41.0418 63.7993C42.5254 63.8187 44.0089 63.843 45.5409 63.8624C45.7688 63.8624 46.016 63.8769 46.2197 63.8672C48.3334 63.7993 50.4327 63.9496 52.5319 64.0272C54.0155 64.0611 55.4942 64.2114 56.9874 64.2163C57.8116 64.2357 58.7957 64.3229 59.6975 64.3957C61.0598 64.5072 62.4222 64.6526 63.7845 64.7884C65.142 64.9532 66.5043 65.089 67.8569 65.2829C68.7005 65.3992 69.4277 65.5107 69.5101 65.6901C69.5538 65.7919 70.2422 65.9519 70.2568 66.078C70.2955 66.4076 69.1223 66.4998 66.9746 66.2671C66.0098 66.1652 65.0256 66.0634 64.056 65.9616C62.1943 65.7919 60.3423 65.6853 58.4612 65.4671C57.7922 65.3895 56.968 65.3411 56.2214 65.312C54.0979 65.2005 52.0083 65.0938 49.9091 64.9823C49.24 64.958 48.5419 64.9241 47.8923 64.9241C46.1663 64.9338 44.4452 64.9435 42.7096 64.9484C41.8854 64.9581 41.0855 64.9774 40.2467 64.9677C39.3789 64.9677 38.4481 65.0356 37.5463 65.0696C35.1562 65.1714 32.7806 65.3604 30.3856 65.4865C27.6173 65.8065 24.6842 65.9568 21.9353 66.4367C21.082 66.5822 20.0639 66.7131 19.0603 66.8149C16.7138 67.0379 16.4569 67.0088 17.3005 66.5531C17.5041 66.4416 17.7756 66.3252 18.081 66.2089C18.5125 66.044 19.0458 65.9131 20.0397 65.7628C21.4262 65.5835 22.7837 65.3265 24.1218 65.1229C25.1932 64.9629 26.2162 64.7787 27.4088 64.7059C27.8403 64.6769 28.3057 64.6138 28.7421 64.5653C29.7408 64.4393 30.7444 64.3423 31.7722 64.2696C32.7951 64.1872 33.8423 64.1242 34.9186 64.0999L34.9041 64.0951Z"
        fill="white"
      />
      <path
        d="M24.1168 75.6714C25.1834 75.2399 26.3615 74.8618 27.4329 74.3769C27.719 74.2412 28.1844 74.1055 28.5529 74.0085C29.6582 73.7031 30.7588 73.4025 31.8981 73.0922C32.0678 73.0437 32.252 73.0049 32.4023 72.9516C33.9489 72.4377 35.5487 72.142 37.1292 71.788C38.2395 71.5165 39.3739 71.3711 40.489 71.0705C41.1096 70.9299 41.861 70.8184 42.5543 70.7117C43.5967 70.5566 44.6439 70.4403 45.6911 70.3094C46.7431 70.2172 47.7855 70.0912 48.8375 70.0379C49.492 69.9991 50.0592 69.9748 50.1514 70.1348C50.2047 70.2269 50.7428 70.256 50.7768 70.3772C50.8592 70.6924 49.9962 70.9978 48.3479 71.1578C47.6109 71.2402 46.8595 71.3226 46.1129 71.405C44.6924 71.5893 43.2961 71.8365 41.8562 71.9965C41.3423 72.0498 40.7217 72.1613 40.1642 72.2874C39.3691 72.4619 38.574 72.5977 37.7837 72.7577L35.4227 73.2473C34.9233 73.3637 34.3997 73.4752 33.9198 73.6158C33.2847 73.7903 32.6544 73.9842 32.0193 74.1733C31.389 74.3624 30.7491 74.5369 30.114 74.7454C29.5128 74.9345 28.931 75.1236 28.3105 75.2981C27.6754 75.492 27.016 75.7587 26.3615 75.9962C25.4985 76.3017 24.6598 76.6653 23.8065 76.9901C22.9581 77.3295 22.1194 77.6979 21.2613 78.0228C19.3511 78.973 17.2615 79.8069 15.4435 80.9316C14.8762 81.2759 14.183 81.6443 13.4994 81.9934C11.8801 82.7836 11.6765 82.8273 12.1128 82.197C12.2146 82.0467 12.3698 81.8722 12.5443 81.688C12.7964 81.4262 13.1358 81.174 13.8 80.7862C14.7453 80.2771 15.6423 79.7002 16.5489 79.1863C17.2761 78.7791 17.9597 78.3573 18.8081 78.0034C19.1135 77.8773 19.4335 77.7076 19.7389 77.5525C20.4274 77.1937 21.1352 76.8689 21.8624 76.5586C22.5897 76.2386 23.3363 75.938 24.1217 75.6714H24.1168Z"
        fill="white"
      />
      <path
        d="M31.7186 84.9362C32.8385 84.3932 34.0554 83.8648 35.1608 83.2442C35.4565 83.0794 35.9413 82.8758 36.3195 82.7255C37.4636 82.2697 38.6078 81.8092 39.7713 81.3001C39.9459 81.2225 40.1398 81.1547 40.2901 81.0771C41.8657 80.2965 43.5141 79.7099 45.1188 79.0263C46.2533 78.5415 47.4072 78.11 48.5222 77.5767C49.1428 77.281 49.904 76.9853 50.6021 76.7089C52.7159 75.8847 54.8297 75.0557 56.9386 74.2315C57.598 73.9794 58.1701 73.7709 58.3155 73.8921C58.3979 73.9649 58.9603 73.8146 59.033 73.9164C59.2173 74.1879 58.4125 74.7648 56.7544 75.4726C56.0126 75.7878 55.2418 76.0884 54.4903 76.3986C53.0456 76.9901 51.6396 77.6349 50.1464 78.144C49.6131 78.3233 48.9877 78.6045 48.4156 78.8518C46.7866 79.5499 45.1625 80.1802 43.5384 80.8347C43.0196 81.0432 42.4766 81.2516 41.9869 81.4795C40.6828 82.0807 39.3738 82.6624 38.0503 83.2442C37.42 83.5206 36.814 83.7969 36.174 84.0684C35.505 84.3447 34.8165 84.6987 34.1378 85.0186C32.3391 85.8671 30.5696 86.764 28.7612 87.6075C26.7298 88.7226 24.4803 89.6971 22.8028 91.1952C22.2841 91.6558 21.6878 92.2036 21.1448 92.7757C20.5048 93.4399 20.2285 93.8762 20.0636 93.9974C19.8988 94.1235 19.8794 93.9683 19.9327 93.5659C19.9521 93.3671 20.0297 93.1344 20.1267 92.8775C20.2721 92.5139 20.5145 92.1066 21.106 91.5006C21.935 90.657 22.8368 89.9104 23.7628 89.2414C24.5094 88.7081 25.2414 88.233 26.1384 87.7869C26.4632 87.6269 26.8026 87.4282 27.1225 87.2536C28.5867 86.444 30.0896 85.678 31.7283 84.9508L31.7186 84.9362Z"
        fill="white"
      />
      <path
        d="M40.2708 91.2929C41.2405 90.7839 42.307 90.3039 43.2621 89.7221C43.5191 89.5622 43.9409 89.3828 44.2754 89.247C45.279 88.8349 46.2825 88.418 47.3055 87.9623C47.4558 87.8944 47.6255 87.8314 47.7612 87.7635C49.1381 87.0605 50.5974 86.5515 52.013 85.96C53.0118 85.5333 54.0396 85.1891 55.0286 84.7189C55.5813 84.4668 56.26 84.2292 56.8854 84.0013C58.7714 83.3274 60.667 82.6875 62.5771 82.0912C63.1735 81.9069 63.6922 81.7615 63.8134 81.8972C63.8813 81.9748 64.3904 81.8875 64.4485 81.9942C64.6037 82.2802 63.8716 82.7651 62.3832 83.2887C61.7142 83.5214 61.0306 83.7589 60.3567 83.9916C59.0719 84.4571 57.8163 84.971 56.4927 85.3782C56.0225 85.5237 55.4601 85.7467 54.9607 85.96C53.5208 86.5466 52.0906 87.0847 50.6604 87.6471C50.2047 87.8314 49.7296 88.0107 49.2981 88.2144C48.1539 88.7428 47.0097 89.2664 45.8607 89.7997C45.3129 90.0518 44.7845 90.3039 44.2221 90.5415C43.6403 90.7984 43.044 91.1233 42.4525 91.4141C40.8914 92.1947 39.3642 93.0383 37.7934 93.814C36.0481 94.866 34.1137 95.7969 32.4314 96.9507C31.9078 97.3046 31.263 97.6925 30.6182 98.0416C29.0958 98.8464 28.9068 98.89 29.2946 98.2597C29.3867 98.1094 29.5225 97.9349 29.6873 97.7507C29.9152 97.4889 30.2303 97.2368 30.8509 96.8441C31.7284 96.3156 32.5526 95.7338 33.3816 95.1909C34.0458 94.7594 34.6712 94.323 35.4518 93.9255C35.733 93.7849 36.0238 93.6007 36.3002 93.431C36.9256 93.0431 37.5704 92.6795 38.2298 92.3207C38.8891 91.962 39.5727 91.6129 40.2854 91.2784L40.2708 91.2929Z"
        fill="white"
      />
    </g>
    <path
      d="M76.6325 109.591C81.0395 110.095 85.4464 105.058 86.9591 99.6421C88.4717 94.2268 82.426 84.5305 82.426 84.5305C82.426 84.5305 88.4717 81.258 89.9843 74.456C91.4969 67.6541 86.3336 64.0034 86.3336 64.0034C86.3336 64.0034 87.9723 62.869 90.2364 51.534C92.5053 40.199 87.5214 33.0383 80.3947 26.4836L37.9782 26.3091C30.8515 32.8638 25.8676 40.0245 28.1365 51.3595C30.4054 62.6944 32.0392 63.8289 32.0392 63.8289C32.0392 63.8289 26.876 67.4795 28.3886 74.2815C29.9012 81.0834 35.9469 84.3559 35.9469 84.3559C35.9469 84.3559 29.9012 94.0522 31.4138 99.4676C32.9265 104.883 37.3334 109.92 41.7404 109.416L76.6422 109.591H76.6325Z"
      fill="#FF7A5E"
    />
    <path
      d="M68.3186 80.2105C68.3186 80.2105 68.1683 88.9808 70.9948 91.3613C73.8213 93.7417 99.9867 94.9295 99.9867 94.9295C99.9867 94.9295 91.3328 114.017 59.6695 115.65C28.0015 117.284 23.4248 97.0094 23.4248 97.0094C23.4248 97.0094 43.6416 94.6289 47.3601 90.765C51.0786 86.901 50.6326 79.3185 50.6326 79.3185L68.3235 80.2105H68.3186Z"
      fill="#6B3C1C"
    />
    <path
      d="M61.6326 82.1302C67.9206 81.1024 73.2778 79.0226 77.1709 70.6207C81.064 62.2189 83.4977 59.4312 83.1826 44.0238C82.8675 28.6164 68.4394 18.8522 55.5142 19.9673C42.5891 21.0824 32.7376 35.9807 34.9096 48.7895C37.0767 61.5983 41.2413 73.1321 47.3306 77.8057C53.4198 82.4793 56.9251 82.9011 61.6326 82.1302Z"
      fill="#7F4D2A"
    />
    <path
      d="M53.1725 69.9613L56.5808 68.3226C57.1868 68.0317 57.9092 68.1239 58.4182 68.565L59.2279 69.2583C59.989 69.9128 61.1526 69.7625 61.7198 68.9383C62.2289 68.2014 63.2228 67.9881 63.9888 68.4535L66.9752 70.2667C67.9594 70.8631 68.0709 72.2496 67.1982 73.0011C65.6565 74.3246 63.0046 75.9682 59.3297 75.9682C55.6548 75.9682 53.4925 73.9562 52.489 72.4193C51.9314 71.5612 52.2465 70.4073 53.1677 69.9662L53.1725 69.9613Z"
      fill="#F6491D"
    />
    <path
      d="M59.2957 19.9671C59.2957 19.9671 60.5465 28.2769 55.6838 32.4657C50.8211 36.6593 41.7066 35.8739 41.4012 42.6856C41.0958 49.4972 42.9187 51.0729 40.1843 54.2145C37.45 57.3609 39.142 65.8209 39.142 65.8209C39.142 65.8209 26.2071 48.9736 34.4102 31.9421C42.6132 14.9105 59.2957 19.9671 59.2957 19.9671Z"
      fill="#FF7A5E"
    />
    <path
      d="M59.0432 19.9672C59.0432 19.9672 57.7681 28.277 62.7181 32.4658C67.668 36.6594 76.9474 35.874 77.2577 42.6856C77.5679 49.4973 75.7111 51.0729 78.4939 54.2145C81.2768 57.361 79.4636 66.1652 79.4636 66.1652C79.4636 66.1652 92.7232 48.9737 84.3699 31.9373C76.0165 14.9058 59.0383 19.9624 59.0383 19.9624L59.0432 19.9672Z"
      fill="#FF7A5E"
    />
    <path
      d="M74.7176 46.1958C74.7176 46.1958 71.1008 37.2801 64.9292 41.881C56.4643 48.1884 72.5601 62.4565 72.5601 62.4565C72.5601 62.4565 91.1431 53.9916 86.7167 44.9838C83.5994 38.6424 74.7176 46.1958 74.7176 46.1958Z"
      fill="#F6491D"
    />
    <path
      d="M74.4267 60.3569L73.0692 59.0624C72.1287 58.1267 70.8245 56.7547 69.5446 55.1694C66.7085 51.6496 65.1958 48.5662 65.2831 46.4863C65.3267 45.5118 65.734 44.7895 66.6067 44.1398C67.2854 43.6356 67.8866 43.3787 68.3956 43.3787C69.9761 43.3787 71.6148 46.05 72.119 47.2669L73.5831 50.8691L76.5405 48.3432C76.5405 48.3432 77.5392 47.5044 78.9016 46.6996C80.4578 45.7785 81.8492 45.2694 82.8237 45.2694C83.4249 45.2694 83.7982 45.4197 84.2006 46.2342C84.6757 47.2038 84.7193 48.1638 84.3315 49.2546C83.5558 51.4266 81.0832 54.001 77.3598 56.5123C75.7454 57.6031 74.1892 58.4806 73.0741 59.0721L74.4267 60.3569Z"
      fill="#030500"
    />
    <path
      d="M44.45 46.0313C44.45 46.0313 48.0667 37.1155 54.2384 41.7164C62.7033 48.0238 46.6074 62.2919 46.6074 62.2919C46.6074 62.2919 28.0245 53.8271 32.4508 44.8192C35.5682 38.4778 44.45 46.0313 44.45 46.0313Z"
      fill="#F6491D"
    />
    <path
      d="M46.0991 58.898C44.984 58.3065 43.4278 57.429 41.8133 56.3381C38.09 53.8268 35.6174 51.2524 34.8417 49.0805C34.4539 47.9896 34.4926 47.0345 34.9726 46.0601C35.375 45.2456 35.7532 45.0953 36.3495 45.0953C38.3615 45.0953 41.4497 47.1654 42.6278 48.1739L45.5901 50.69L47.0542 47.0879C47.5535 45.8807 49.1971 43.2094 50.7727 43.2094C51.2818 43.2094 51.8829 43.4663 52.5617 43.9705C53.4343 44.6202 53.8464 45.3474 53.8852 46.317C53.9773 48.3969 52.4599 51.4803 49.6237 55C48.3438 56.5902 47.0396 57.9623 46.0991 58.8931V58.898Z"
      fill="#030500"
    />
    <path
      d="M57.5841 72.0216C58.0737 72.104 58.5973 72.201 59.1064 72.1573C59.2421 72.1428 59.4458 72.167 59.6057 72.1913C60.0906 72.2495 60.5802 72.2834 61.0893 72.2543C61.1668 72.2543 61.2493 72.2543 61.3171 72.2446C62.0104 72.1283 62.7134 72.1282 63.4067 71.9973C63.8915 71.9052 64.3909 71.8374 64.866 71.6725C65.1278 71.5804 65.4526 71.5174 65.7483 71.4447C66.6453 71.2313 67.5373 70.9889 68.4342 70.7417C68.7106 70.6641 68.9578 70.6059 69.0305 70.7223C69.0693 70.7853 69.3166 70.7708 69.3554 70.858C69.4523 71.0859 69.1324 71.3768 68.4294 71.6192C68.1142 71.7259 67.7846 71.818 67.4646 71.9149C66.8489 72.0992 66.2429 72.3173 65.5884 72.4094C65.3556 72.4385 65.0841 72.521 64.832 72.584C64.1145 72.7682 63.3921 72.8603 62.6601 72.933C62.4274 72.9573 62.185 72.9718 61.9571 73.0009C61.3608 73.093 60.7596 73.1221 60.1487 73.1173C59.8578 73.1076 59.5767 73.1124 59.2858 73.0688C58.9755 73.0446 58.6555 73.0155 58.3404 72.9912C57.5065 72.8943 56.6823 72.7634 55.8824 72.5306C54.9273 72.3804 53.977 72.0216 53.0317 71.9052C52.7408 71.8664 52.4014 71.7986 52.0766 71.6968C51.3203 71.4641 51.2378 71.4059 51.5869 71.2023C51.6693 71.1538 51.776 71.115 51.8972 71.081C52.062 71.0326 52.256 71.0277 52.5953 71.0956C53.0656 71.1877 53.5359 71.2459 53.9964 71.3138C54.3649 71.3671 54.7188 71.4107 55.1067 71.5271C55.2473 71.5707 55.4072 71.5901 55.5527 71.6192C56.2266 71.7307 56.8908 71.8713 57.5938 72.0313L57.5841 72.0216Z"
      fill="black"
    />
    <path
      d="M58.5881 63.0577C58.7724 63.1934 58.9954 63.3243 59.2233 63.2855C59.2814 63.2758 59.3638 63.3001 59.4269 63.3146C59.616 63.3631 59.8438 63.3486 60.0377 63.2467C60.0668 63.2322 60.1008 63.2225 60.1202 63.2031C60.2268 63.0964 60.3238 63.0043 60.4062 62.9025C60.4886 62.8007 60.542 62.6941 60.5856 62.5001C60.6486 62.2432 60.7116 61.8747 61.0025 61.4917C61.1577 61.2832 61.4389 61.1475 61.691 61.1039C62.0691 61.0263 62.4764 61.1039 62.8012 61.3172C63.1163 61.5305 63.3296 61.8359 63.4411 62.1608C63.5139 62.3644 63.5236 62.5535 63.3975 62.5922C63.3296 62.6165 63.286 62.7765 63.189 62.7813C62.9369 62.7959 62.7769 62.6068 62.617 62.3644C62.5442 62.2529 62.4667 62.1511 62.3697 62.0832C62.1855 61.962 61.9673 61.9959 61.754 62.0202C61.6764 62.0299 61.6134 62.0977 61.5843 62.1608C61.531 62.2529 61.4825 62.3983 61.434 62.5922C61.3807 62.7813 61.3128 63.0286 61.1674 63.2564C61.0801 63.4019 60.9589 63.5231 60.8571 63.6394C60.5759 63.9497 60.1929 64.1388 59.7808 64.2018C59.5869 64.2309 59.3881 64.2358 59.1942 64.1921C58.9954 64.1485 58.7821 64.1146 58.5881 64.0321C58.0791 63.8237 57.6379 63.4649 57.3761 62.9946C57.1822 62.7571 57.061 62.5292 56.9689 62.4662C56.9252 62.4323 56.901 62.4323 56.8671 62.4419C56.8331 62.4516 56.7749 62.4856 56.7265 62.5486C56.6974 62.5874 56.6586 62.631 56.615 62.6747C56.5762 62.7086 56.5034 62.7862 56.4986 62.7668C56.3919 62.7765 56.5131 62.7862 56.2077 62.7862C56.135 62.7765 56.0332 62.7377 55.9653 62.6553C55.8683 62.5486 55.9217 62.3595 56.0623 62.2092C56.261 61.9959 56.4889 61.739 56.8961 61.6856C57.221 61.6372 57.57 61.8311 57.7252 62.0735C57.7834 62.1608 57.8561 62.2383 57.9143 62.3111C58.0452 62.4807 58.1373 62.5971 58.2488 62.7183C58.3554 62.8395 58.4718 62.9559 58.5978 63.0674L58.5881 63.0577Z"
      fill="black"
    />
    <path
      d="M57.6135 55.1023C57.6232 54.7387 57.6619 54.3509 57.5941 53.9776C57.5747 53.8758 57.5941 53.7255 57.6135 53.6043C57.6668 53.2455 57.7153 52.8867 57.725 52.5134C57.725 52.4601 57.7395 52.3971 57.7347 52.3486C57.6813 51.8347 57.7734 51.3208 57.7783 50.8117C57.7783 50.4481 57.8171 50.0893 57.7686 49.7257C57.7395 49.5221 57.754 49.2846 57.7637 49.0615L57.8219 47.0641C57.8268 46.8556 57.8413 46.6763 57.9722 46.652C58.0449 46.6375 58.0983 46.4678 58.1952 46.4581C58.4425 46.4338 58.6267 46.7199 58.6558 47.2532C58.6704 47.4907 58.6607 47.7332 58.6655 47.9756C58.6703 48.4361 58.7237 48.8967 58.6461 49.3621C58.617 49.527 58.6316 49.7306 58.6364 49.9148C58.651 50.4384 58.6073 50.9523 58.5685 51.4711C58.554 51.6359 58.5346 51.8056 58.5443 51.9656C58.5637 52.3922 58.554 52.814 58.5395 53.2406C58.5298 53.4443 58.5298 53.643 58.5055 53.8467C58.4813 54.06 58.4958 54.2878 58.491 54.5109C58.4764 55.0975 58.4861 55.689 58.4182 56.2756C58.457 56.964 58.3261 57.6815 58.3843 58.3748C58.4037 58.5881 58.394 58.8451 58.3455 59.0875C58.2243 59.6596 58.1758 59.7178 57.9286 59.4754C57.8704 59.4172 57.8171 59.3445 57.7637 59.262C57.691 59.1457 57.6571 59.0099 57.6668 58.7627C57.6765 58.4185 57.6425 58.0791 57.6135 57.7494C57.5892 57.4828 57.5601 57.2307 57.5941 56.9398C57.6086 56.8331 57.5941 56.7216 57.5941 56.6101C57.5698 56.1156 57.5747 55.6211 57.628 55.1023H57.6135Z"
      fill="black"
    />
    <path
      d="M57.4006 81.8881C57.7884 81.8978 58.215 81.9269 58.6126 81.8445C58.7193 81.8203 58.8889 81.83 59.015 81.8445C59.4077 81.8833 59.8052 81.8881 60.2173 81.8687C60.2804 81.8687 60.3434 81.8687 60.3967 81.8639C60.9591 81.7669 61.5215 81.8203 62.0839 81.7863C62.4766 81.7621 62.879 81.7718 63.2717 81.6991C63.4898 81.6554 63.7516 81.6554 63.994 81.6506C64.731 81.6312 65.4388 81.6021 66.0206 81.3839C66.1999 81.3161 66.3405 81.2433 66.4327 81.3355C66.486 81.3936 66.6557 81.3355 66.7235 81.3985C66.8108 81.4857 66.8254 81.6166 66.7332 81.7766C66.6411 81.9366 66.4375 82.1112 66.1272 82.2324C65.8509 82.3439 65.5648 82.3924 65.2885 82.4408C64.7552 82.5281 64.2607 82.6008 63.7419 82.5523C63.5577 82.5378 63.3347 82.562 63.1359 82.5814C62.559 82.6299 61.9918 82.6251 61.4245 82.6251C61.2403 82.6251 61.0512 82.6202 60.8767 82.6396C60.4113 82.6929 59.9458 82.722 59.4756 82.7414C59.2525 82.7463 59.0344 82.756 58.8065 82.7414C58.569 82.7269 58.3169 82.7511 58.0696 82.7511C57.4151 82.7511 56.7509 82.756 56.0867 82.6299C55.2965 82.6008 54.5208 82.3051 53.7596 82.1742C53.522 82.1354 53.2505 82.053 53.0033 81.9318C52.4215 81.6506 52.3779 81.5827 52.693 81.4179C52.7706 81.3791 52.8627 81.3452 52.9597 81.3209C53.1003 81.2821 53.2554 81.2918 53.5123 81.3694C53.8711 81.476 54.2396 81.5294 54.5983 81.5779C54.8844 81.6166 55.1559 81.636 55.4565 81.7185C55.5631 81.7475 55.6892 81.7524 55.8007 81.7621C56.3194 81.8009 56.843 81.83 57.4006 81.8881Z"
      fill="black"
    />
    <path
      d="M56.3626 47.3689C56.3626 47.3689 57.114 46.0162 59.2133 46.0162C61.3125 46.0162 63.0385 47.3689 63.0385 47.3689L63.1112 49.0948C63.1112 49.0948 60.309 47.7034 58.9709 47.9361C57.6328 48.1688 56.125 49.2742 56.125 49.2742L56.3577 47.3737L56.3626 47.3689Z"
      fill="#F6491D"
    />
    <path
      d="M33.3921 80.0798C33.0625 79.6531 32.8104 79.1053 32.5098 78.6447C32.4177 78.5187 32.374 78.2956 32.3401 78.126C32.2528 77.6024 32.1995 77.0885 32.1316 76.5649C32.1219 76.4873 32.1316 76.4 32.1122 76.3321C31.9377 75.6146 32.0589 74.8971 32.0783 74.199C32.0928 73.7044 32.185 73.2196 32.151 72.7251C32.1316 72.4488 32.1898 72.1336 32.2286 71.8428C32.2868 71.4113 32.3692 70.9168 32.5146 70.4416C32.6698 69.9665 32.8734 69.4526 33.3097 69.0114C33.5764 68.7351 33.9206 68.5945 34.0369 68.7593C34.1097 68.8369 34.3909 68.8321 34.4345 68.9678C34.4975 69.1472 34.449 69.3266 34.386 69.4866C34.3181 69.6514 34.2406 69.7968 34.1145 70.0102C34.003 70.1992 33.8867 70.4319 33.8139 70.6937C33.6685 71.198 33.6976 71.7652 33.5182 72.3712C33.4552 72.5845 33.4503 72.8463 33.4406 73.0839C33.4067 73.7626 33.3097 74.4123 33.2709 75.0668C33.2564 75.2752 33.2418 75.4934 33.2661 75.6922C33.334 76.2206 33.4115 76.7394 33.523 77.2533C33.5812 77.4909 33.6394 77.7187 33.7024 77.9708C33.7606 78.2229 33.9012 78.475 34.0224 78.6932C34.3424 79.3041 34.8466 79.7937 35.3411 80.2688C35.695 80.4725 36.0295 80.6906 36.398 80.8506C36.7616 81.0058 37.1543 81.093 37.5179 81.0445C37.7409 81.0058 37.9882 80.9912 38.2403 80.9815C38.8221 80.9476 38.9578 80.9379 38.856 81.4082C38.8269 81.5197 38.7736 81.636 38.7009 81.7572C38.5894 81.8978 38.4197 82.0433 38.0706 82.0772C37.5858 82.1451 37.0913 82.1839 36.6016 82.1257C36.2089 82.0869 35.8308 82.0045 35.4623 81.7815C35.3362 81.6942 35.1763 81.6312 35.0308 81.5633C34.386 81.2336 33.8091 80.7343 33.3873 80.0749L33.3921 80.0798Z"
      fill="#F6491D"
    />
    <path
      d="M42.9238 79.726C43.079 80.3272 43.1178 80.972 43.3069 81.578C43.3505 81.7428 43.3456 81.9998 43.3359 82.1986C43.2875 82.8094 43.2438 83.4057 43.1953 84.0215C43.1953 84.1136 43.1614 84.2105 43.1663 84.293C43.1953 85.1559 42.9045 85.9801 42.7057 86.7994C42.5602 87.3812 42.3324 87.9339 42.1821 88.5254C42.1094 88.8551 41.9251 89.2138 41.7603 89.5435C41.2609 90.5325 40.6501 91.4682 39.9422 92.3166C39.7241 92.5833 39.5059 92.7869 39.3314 92.6899C39.2296 92.6366 38.992 92.7869 38.8756 92.6996C38.5702 92.4621 38.6769 91.9676 39.1229 91.3034C39.3314 91.0125 39.535 90.7022 39.7338 90.4016C40.0877 89.8004 40.3495 89.1653 40.7276 88.5642C40.8731 88.3557 40.9507 88.0503 41.0476 87.7933C41.29 87.037 41.5567 86.3049 41.7506 85.5438C41.8088 85.3014 41.8718 85.0444 41.896 84.802C41.9348 84.1572 41.9833 83.5172 41.9833 82.8676C41.9833 82.5622 41.9833 82.2664 41.9785 81.9513C41.9979 81.6265 41.9009 81.2871 41.8621 80.9526C41.7167 80.0751 41.4549 79.246 41.1349 78.4703C40.8682 78.0631 40.6016 77.6413 40.2719 77.3116C39.9471 76.9723 39.535 76.6959 39.1035 76.5892C38.832 76.5214 38.5363 76.4487 38.2502 76.3662C37.906 76.2693 37.7072 76.2693 37.62 76.1917C37.5327 76.119 37.5521 75.9978 37.6927 75.7845C37.7606 75.6778 37.8624 75.5857 37.9884 75.4936C38.1678 75.3821 38.4102 75.3093 38.8223 75.3918C39.3944 75.513 39.9568 75.6826 40.4658 75.9687C40.8731 76.1917 41.2609 76.4584 41.5567 76.8898C41.6633 77.0401 41.7991 77.1904 41.9106 77.3456C42.439 78.034 42.7687 78.8582 42.9238 79.7357V79.726Z"
      fill="#F6491D"
    />
    <path
      d="M87.3812 74.1064C87.3521 74.5815 87.26 75.0809 87.2697 75.5803C87.2794 75.716 87.2116 75.9099 87.1485 76.0554C86.9691 76.5014 86.7558 76.928 86.5667 77.3789C86.5377 77.4468 86.4892 77.5098 86.4698 77.5728C86.2759 78.2322 85.8201 78.7509 85.442 79.2939C85.1753 79.6866 84.8263 80.0018 84.5596 80.4042C84.4045 80.6223 84.1669 80.8308 83.9391 81.0102C83.2652 81.5677 82.5137 82.0283 81.7186 82.3822C81.4713 82.4986 81.2435 82.5616 81.1368 82.3919C81.0738 82.2949 80.8459 82.3046 80.7878 82.1737C80.6278 81.8247 80.8411 81.4659 81.355 81.1508C81.5829 81.0053 81.8301 80.8793 82.0531 80.7241C82.4846 80.4284 82.8385 80.0502 83.2991 79.7739C83.4591 79.6672 83.6142 79.4976 83.7451 79.3327C84.1281 78.877 84.5208 78.4455 84.8699 77.9655C84.9766 77.8104 85.1026 77.6553 85.1705 77.4807C85.3644 77.0347 85.5389 76.5887 85.6941 76.1281C85.7717 75.9099 85.8201 75.6918 85.9074 75.4736C85.985 75.2409 86.0044 74.9791 86.0335 74.727C86.1159 74.0579 86.0868 73.3937 86.0916 72.7247C85.9219 71.9635 85.8832 71.1297 85.4759 70.4509C85.345 70.2473 85.2335 69.9903 85.1608 69.7188C84.9863 69.0934 85.0008 68.9819 85.4711 69.0304C85.5826 69.0401 85.7038 69.074 85.825 69.1225C85.9995 69.1855 86.1207 69.3164 86.2613 69.5928C86.4358 69.9806 86.6686 70.354 86.8479 70.7418C86.9982 71.0521 87.1388 71.3575 87.1728 71.7357C87.1825 71.8714 87.2406 72.012 87.2697 72.1478C87.4297 72.7683 87.483 73.4228 87.3812 74.1064Z"
      fill="#F6491D"
    />
    <path
      d="M78.0145 81.9903C77.9418 82.383 77.8594 82.8096 77.9369 83.2266C77.9612 83.3381 77.9321 83.5029 77.8981 83.6338C77.8012 84.0217 77.743 84.4192 77.743 84.8313C77.743 84.8943 77.7285 84.9574 77.743 85.0155C77.8448 85.5779 77.7721 86.1452 77.8545 86.7075C77.9127 87.1002 77.9321 87.5026 78.0969 87.8808C78.1939 88.0844 78.2181 88.3511 78.2812 88.5838C78.4363 89.3013 78.6593 89.9994 78.9066 90.6879C78.989 90.9012 79.052 91.0903 78.8823 91.1969C78.7854 91.26 78.7902 91.4684 78.6642 91.5411C78.3393 91.7254 77.9466 91.5411 77.6557 90.9739C77.5394 90.717 77.4327 90.4455 77.3358 90.174C77.1467 89.6601 76.914 89.1559 76.8897 88.5886C76.8849 88.385 76.8122 88.1571 76.754 87.9438C76.6085 87.3378 76.5746 86.7172 76.5649 86.1064C76.5649 85.9076 76.5746 85.7088 76.5455 85.5197C76.4825 85.0204 76.4679 84.5162 76.497 84.0071C76.5019 83.7647 76.5261 83.532 76.5698 83.2896C76.6134 83.0375 76.6134 82.766 76.6425 82.5042C76.7152 81.8109 76.8122 81.1079 77.0449 80.4437C77.1031 80.0365 77.2291 79.6293 77.3745 79.2366C77.5054 78.8487 77.6703 78.4172 77.8594 77.9712C77.9806 77.6948 78.2133 77.3555 78.5963 77.147C79.0472 76.8997 79.372 76.9143 79.4738 76.9725C79.5902 77.0355 79.5223 77.1664 79.4253 77.3894C79.3817 77.5009 79.3332 77.6124 79.2944 77.7288C79.2411 77.8936 79.1732 78.0003 79.0132 78.1845C78.7902 78.4463 78.6981 78.8051 78.6302 79.1493C78.5769 79.4256 78.5623 79.7262 78.4217 80.0171C78.3684 80.1238 78.3636 80.2547 78.349 80.371C78.2715 80.914 78.1648 81.4425 78.0145 82V81.9903Z"
      fill="#F6491D"
    />
    <path
      d="M70.0242 31.1135C70.6593 31.3122 71.3332 31.5692 72.0216 31.6613C72.2059 31.6856 72.4725 31.7922 72.6761 31.8795C73.2919 32.1461 73.9124 32.4079 74.5669 32.6406C74.6639 32.6746 74.7657 32.7279 74.8578 32.7473C75.7935 32.9897 76.6468 33.4454 77.5291 33.8381C78.1545 34.1145 78.7412 34.4684 79.386 34.735C79.7447 34.8805 80.1229 35.152 80.4768 35.3847C81.5337 36.1119 82.5082 36.9846 83.3178 38.0027C83.5699 38.3178 83.7493 38.6233 83.6233 38.7735C83.5505 38.8608 83.6766 39.1662 83.5651 39.2584C83.4196 39.3796 83.216 39.3747 82.9736 39.2584C82.7312 39.142 82.4694 38.8947 82.13 38.5748C81.8488 38.2693 81.5434 37.9784 81.2331 37.6924C80.6174 37.1737 79.9387 36.7519 79.2939 36.238C79.066 36.044 78.746 35.884 78.4503 35.7435C77.6212 35.3217 76.8068 34.8902 75.9583 34.4975C75.6868 34.3763 75.4105 34.2308 75.1342 34.1484C74.4021 33.9157 73.6749 33.6684 72.9379 33.4163C72.5889 33.2951 72.2447 33.1885 71.8956 33.0479C71.5368 32.9024 71.1199 32.8055 70.732 32.6891C69.7042 32.3643 68.6425 32.1122 67.6195 31.5886C66.359 31.1523 65.1712 30.1729 64.3228 28.9803C64.0561 28.6118 63.8186 28.0882 63.7652 27.5307C63.7119 26.8762 63.8137 26.5174 63.901 26.3962C63.9931 26.2702 64.0998 26.3865 64.284 26.6144C64.3761 26.7211 64.4634 26.852 64.5603 26.9877C64.6961 27.1768 64.7834 27.3901 64.924 27.7828C65.1324 28.3306 65.5251 28.7815 65.9469 29.1403C66.2911 29.4263 66.6499 29.6348 67.0474 29.9257C67.188 30.0323 67.3723 30.1099 67.542 30.1826C68.3177 30.5123 69.137 30.779 70.0291 31.128L70.0242 31.1135Z"
      fill="#F6491D"
    />
    <path
      d="M48.5324 32.1212C48.0427 32.4703 47.4852 32.7951 47.0343 33.2121C46.9131 33.3284 46.6901 33.4399 46.5107 33.5175C45.9725 33.7647 45.4392 34.0023 44.9302 34.3029C44.8526 34.3465 44.7654 34.3805 44.7023 34.4289C44.0527 34.9283 43.3012 35.2434 42.6516 35.7088C42.1958 36.0337 41.7159 36.3343 41.3426 36.756C41.1438 36.9936 40.8529 37.2166 40.6202 37.4493C39.893 38.1378 39.2724 38.9135 38.8506 39.791C38.71 40.0576 38.6179 40.3 38.4143 40.2758C38.3028 40.2564 38.1379 40.4843 37.9973 40.4552C37.8131 40.4164 37.6871 40.2758 37.6483 40.0188C37.5949 39.7619 37.658 39.408 37.8325 38.9765C38.007 38.6032 38.2252 38.2444 38.4579 37.9051C38.8991 37.2554 39.3694 36.6154 40.0142 36.1646C40.2469 36.0094 40.4796 35.7622 40.7123 35.5634C41.3668 34.9865 42.094 34.5501 42.8261 34.1332C43.0588 34.0023 43.3061 33.8714 43.5242 33.726C44.0866 33.3333 44.6829 32.989 45.2696 32.6545C45.5508 32.4994 45.8126 32.3345 46.1083 32.1891C46.4089 32.0388 46.6998 31.8206 46.9858 31.6267C47.7518 31.1128 48.4596 30.5456 49.1772 29.9977C49.8947 29.2414 50.7189 28.4997 51.0922 27.5543C51.194 27.2585 51.3491 26.9385 51.5188 26.6283C51.897 25.8962 51.9794 25.7895 52.2703 26.2259C52.3382 26.3325 52.3915 26.4634 52.44 26.6089C52.5127 26.8222 52.4739 27.0452 52.3333 27.4331C52.1006 27.947 51.9018 28.4851 51.6206 28.9748C51.5188 29.1784 51.4073 29.3675 51.2764 29.5517C51.1504 29.7408 51.0049 29.9202 50.8207 30.085C50.6946 30.2062 50.5783 30.3662 50.4571 30.4971C49.9141 31.1273 49.279 31.6703 48.5421 32.1261L48.5324 32.1212Z"
      fill="#F6491D"
    />
    <path
      d="M42.0075 27.5201C41.5664 27.811 41.0912 28.1261 40.7422 28.5479C40.6452 28.6594 40.4658 28.7855 40.3204 28.8825C40.1022 29.0279 39.8841 29.1733 39.6659 29.3285C39.4526 29.4885 39.2538 29.6678 39.0599 29.8521C39.0017 29.9054 38.929 29.9539 38.8805 30.0121C38.4199 30.6084 37.8236 31.0641 37.3582 31.6507C37.0188 32.0483 36.6843 32.4555 36.4273 32.921C36.3013 33.1876 36.0928 33.464 35.9086 33.7306C35.6322 34.1281 35.3898 34.5451 35.1329 34.9523C34.9099 35.379 34.6481 35.7911 34.4493 36.2274C34.3184 36.4989 34.1923 36.7268 33.9984 36.6831C33.8869 36.6589 33.7075 36.8577 33.5718 36.814C33.213 36.7025 33.1257 36.2032 33.4166 35.4371C33.5621 35.1026 33.7463 34.7729 33.9063 34.4433C34.2408 33.8227 34.522 33.1634 35.0214 32.6349C35.1959 32.4458 35.3413 32.1695 35.5062 31.9416C35.9522 31.2726 36.5001 30.6957 37.0576 30.1333C37.2418 29.9587 37.4406 29.7842 37.5957 29.5951C37.7994 29.3382 38.0272 29.1103 38.2599 28.8825C38.4926 28.6546 38.7205 28.4219 38.9775 28.2183C39.215 28.0195 39.4429 27.8207 39.6998 27.6365C39.9762 27.4571 40.2331 27.2195 40.5046 27.0111C40.8585 26.7299 41.2318 26.4826 41.6003 26.2208C41.9736 25.9687 42.3712 25.7505 42.7736 25.5421C43.6511 24.9409 44.7031 24.5773 45.6437 24.0682C45.9345 23.9034 46.2982 23.7483 46.6812 23.6949C47.5732 23.5446 47.675 23.5883 47.4617 24.0682C47.4132 24.1846 47.3357 24.301 47.2484 24.4173C47.1272 24.5821 46.9575 24.7033 46.6133 24.8197C46.1479 25.0185 45.7067 25.256 45.2946 25.5227C44.9649 25.7409 44.6546 25.9348 44.2668 26.1093C44.1262 26.1723 43.9904 26.2693 43.8595 26.3566C43.5541 26.5553 43.2584 26.7493 42.9578 26.948C42.6475 27.1371 42.3324 27.3262 42.0027 27.525L42.0075 27.5201Z"
      fill="#F6491D"
    />
    <path
      d="M74.7221 25.8428C75.1924 26.1337 75.6627 26.4876 76.1911 26.7252C76.3366 26.7882 76.5111 26.9434 76.6372 27.0694C77.0347 27.4524 77.4177 27.8403 77.8443 28.2087C77.9074 28.2621 77.9655 28.3348 78.0286 28.3784C78.6734 28.8196 79.1485 29.4402 79.6963 29.9783C80.0842 30.3565 80.4139 30.7831 80.8453 31.1225C81.0829 31.3115 81.3108 31.5879 81.5192 31.84C82.164 32.5963 82.7798 33.3623 83.3906 34.1332C83.5797 34.3707 83.7348 34.5889 83.6039 34.744C83.5312 34.8313 83.6282 35.0785 83.5264 35.1804C83.2597 35.447 82.7798 35.3064 82.2416 34.7392C81.9992 34.4871 81.781 34.2107 81.5435 33.9441C81.0975 33.4447 80.5981 32.9987 80.2296 32.4218C80.0939 32.2181 79.8806 32.0145 79.6915 31.8303C79.1533 31.297 78.6734 30.7298 78.1643 30.1819C77.9995 30.0074 77.8395 29.8183 77.6553 29.6729C77.1801 29.2802 76.7147 28.8972 76.2396 28.5093C76.0166 28.3202 75.7887 28.1554 75.5706 27.9566C75.3427 27.753 75.0567 27.5882 74.79 27.4136C74.087 26.9482 73.3501 26.5652 72.6326 26.1289C71.726 25.7846 70.8291 25.2707 69.8594 25.1398C69.5589 25.1059 69.2195 25.0235 68.8995 24.9023C68.1432 24.6259 68.0511 24.5435 68.4341 24.209C68.5262 24.1314 68.6426 24.0587 68.7735 23.9957C68.9577 23.9036 69.1662 23.8939 69.5298 23.9617C70.0291 24.0781 70.5382 24.1314 71.0327 24.2381C71.4302 24.3157 71.8132 24.3932 72.2108 24.6017C72.3514 24.6793 72.5211 24.7229 72.6762 24.7811C73.384 25.0283 74.0628 25.3726 74.7124 25.838L74.7221 25.8428Z"
      fill="#F6491D"
    />
    <path
      d="M30.8027 33.2086C30.8027 33.2086 30.9724 37.4943 34.0607 39.1233C34.0607 39.1233 34.6619 36.6362 33.2026 34.4061C31.7433 32.1759 30.8027 33.2037 30.8027 33.2037V33.2086Z"
      fill="#00B496"
    />
    <path
      d="M32.0347 33.7866C32.0347 33.7866 34.2648 37.4518 37.7555 37.379C37.7555 37.379 37.0719 34.9113 34.7157 33.6702C32.3595 32.4291 32.0347 33.7866 32.0347 33.7866Z"
      fill="#00B496"
    />
    <path
      d="M31.3844 33.2627C31.3844 33.2627 35.3745 34.8432 38.1137 32.6761C38.1137 32.6761 36.0823 31.1198 33.4546 31.5513C30.8269 31.9828 31.3844 33.2627 31.3844 33.2627Z"
      fill="#00B496"
    />
    <path
      d="M88.6906 35.9768C88.6906 35.9768 87.241 40.0153 83.8037 40.6407C83.8037 40.6407 83.9782 38.0906 86.0387 36.3986C88.0992 34.7066 88.6906 35.9768 88.6906 35.9768Z"
      fill="#00B496"
    />
    <path
      d="M87.3473 36.1615C87.3473 36.1615 84.1184 38.988 80.812 37.8681C80.812 37.8681 82.2034 35.7204 84.8214 35.2452C87.4443 34.7701 87.3425 36.1615 87.3425 36.1615H87.3473Z"
      fill="#00B496"
    />
    <path
      d="M88.1231 35.8566C88.1231 35.8566 83.8422 36.1621 81.8787 33.2774C81.8787 33.2774 84.2833 32.4047 86.6589 33.6022C89.0345 34.7997 88.1182 35.8566 88.1182 35.8566H88.1231Z"
      fill="#00B496"
    />
  </svg>
)

export default VirgoSticker
