/* eslint-disable */
import { Component } from 'react'
import PropTypes from 'prop-types'
import { without } from 'lodash'

import { Col, Row } from 'react-bootstrap'
import {
  Checkbox,
  CheckboxGroup,
  Input,
  Radio,
  RadioGroup,
  StateSelector,
} from 'components/forms'
import DateSelect from 'components/date-select'

import {
  QUESTION_TYPE_ADDRESS_FORM,
  QUESTION_TYPE_ARRIVAL,
  QUESTION_TYPE_CHECKBOX,
  QUESTION_TYPE_COREGISTRATION,
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_RADIO,
  QUESTION_TYPE_TEXT,
} from '../constants'

class Question extends Component {
  static propTypes = {
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    answers: PropTypes.array, // TODO - validate shape of objects in array
    id: PropTypes.number.isRequired,
    onUpdateAnswer: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      QUESTION_TYPE_CHECKBOX,
      QUESTION_TYPE_DATE,
      QUESTION_TYPE_RADIO,
      QUESTION_TYPE_TEXT,
      QUESTION_TYPE_ADDRESS_FORM,
      QUESTION_TYPE_ARRIVAL,
    ]).isRequired,
  }

  constructor() {
    super()
    this.handleAddressFormUpdate = this._handleAddressFormUpdate.bind(this)
  }

  render() {
    let { answers, answer, id, onUpdateAnswer, title, type } = this.props
    let returnComp = null

    switch (type) {
      case QUESTION_TYPE_CHECKBOX:
        returnComp = (
          <CheckboxGroup className="text-left">
            {answers.map((option, idx) => (
              <Checkbox
                key={idx}
                tappable
                checked={answer && answer.indexOf(option.value) != -1}
                label={option.title}
                onChange={this.handleUpdateCheckboxAnswer.bind(
                  this,
                  option.value
                )}
                value={option.value}
              />
            ))}
          </CheckboxGroup>
        )
        break
      case QUESTION_TYPE_DATE:
        returnComp = <DateSelect value={answer} onChange={onUpdateAnswer} />
        break
      case QUESTION_TYPE_RADIO:
        returnComp = (
          <RadioGroup className="text-left">
            {answers.map((option, idx) => (
              <Radio
                key={idx}
                tappable
                checked={option.value == answer}
                label={option.title}
                onChange={() => onUpdateAnswer(option.value)}
                value={option.value}
              />
            ))}
          </RadioGroup>
        )
        break
      case QUESTION_TYPE_TEXT:
        returnComp = (
          <Input
            onChange={this.handleUpdateTextAnswer.bind(this)}
            value={answer}
          />
        )
        break
      case QUESTION_TYPE_ADDRESS_FORM:
        returnComp = (
          <form className="text-left">
            <Row>
              <Col xs={12}>
                <Input
                  ref={(c) => {
                    this.nameInput = c && c._input
                  }}
                  onChange={this.handleAddressFormUpdate}
                  label="Name"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={9}>
                <Input
                  ref={(c) => {
                    this.addr1Input = c && c._input
                  }}
                  onChange={this.handleAddressFormUpdate}
                  label="Street Address"
                  type="text"
                />
              </Col>
              <Col xs={12} sm={3}>
                <Input
                  ref={(c) => {
                    this.addr2Input = c && c._input
                  }}
                  onChange={this.handleAddressFormUpdate}
                  label="Apt/Suite"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Input
                  ref={(c) => {
                    this.cityInput = c && c._input
                  }}
                  onChange={this.handleAddressFormUpdate}
                  label="City"
                  type="text"
                />
              </Col>
              <Col xs={12} sm={3}>
                <StateSelector
                  ref={(c) => {
                    this.stateInput = c && c._input.refs.input
                  }}
                  states={BLConstants.STATES_AND_PROVINCES}
                  country="United States"
                  onChange={this.handleAddressFormUpdate}
                />
              </Col>
              <Col xs={12} sm={3}>
                <Input
                  ref={(c) => {
                    this.zipInput = c && c._input
                  }}
                  onChange={this.handleAddressFormUpdate}
                  label="Zip"
                  type="text"
                />
              </Col>
            </Row>
          </form>
        )
        break
      case QUESTION_TYPE_ARRIVAL:
        returnComp = (
          <RadioGroup className="text-left">
            {answers.map((option, idx) => {
              return (
                <div key={idx}>
                  <Radio
                    tappable
                    checked={
                      option.value == answer ||
                      (idx == 0 &&
                        answer &&
                        answer != '2' &&
                        answer != '3' &&
                        answer != '4')
                    }
                    label={option.title}
                    onChange={() => onUpdateAnswer(option.value)}
                    value={option.value}
                  />
                  {idx == 0 &&
                    answer &&
                    answer != '2' &&
                    answer != '3' &&
                    answer != '4' && (
                      <DateSelect
                        className="mlxl"
                        onChange={onUpdateAnswer}
                        label="Due Date"
                        value={answer}
                      />
                    )}
                </div>
              )
            })}
          </RadioGroup>
        )
        break
      default:
        break
    }

    return (
      <div>
        <div className="h4 mbl">{title}</div>
        {returnComp}
      </div>
    )
  }

  handleUpdateCheckboxAnswer(value, event) {
    let currentAnswer = this.props.answer || []
    let newAnswer
    if (event.target.checked) {
      newAnswer = [...currentAnswer, value]
    } else {
      newAnswer = without(currentAnswer, value)
    }
    this.props.onUpdateAnswer(newAnswer)
  }

  handleUpdateTextAnswer(event) {
    this.props.onUpdateAnswer(event.target.value)
  }

  _handleAddressFormUpdate(event) {
    let name = this.nameInput.value.trim()
    let addr1 = this.addr1Input.value.trim()
    let addr2 = this.addr2Input.value.trim()
    let city = this.cityInput.value.trim()
    let state = this.stateInput.value.trim()
    let zip = this.zipInput.value.trim()
    let address = `${name}\n${addr1}\n${addr2}\n${city}\n${state}\n${zip}`
    this.props.onUpdateAnswer(address)
  }
}

export default Question
