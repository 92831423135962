import { Routes, Route } from 'react-router-dom-v5-compat'
import Router from 'shared/universal-router'
import * as blUrls from 'lib/urls'
import DueDateForm from '../components/DueDateForm/DueDateForm'
import DueDateResults from '../components/DueDateResults/DueDateResults'

interface DueDateCalculatorAppProps {
  emailUrls: string[]
  posts: Post[]
  path: string
}

const DueDateCalculatorApp = ({
  emailUrls,
  posts,
  path,
}: DueDateCalculatorAppProps) => (
  // @ts-ignore
  <Router location={{ pathname: path }} useV6Router>
    <Routes>
      <Route
        path={blUrls.dueDateCalculatorPath}
        element={<DueDateForm posts={posts} longForm={true} />}
      />
      <Route
        path={blUrls.dueDateCalculatorAcqexPath}
        element={<DueDateForm posts={posts} longForm={false} />}
      />
      <Route
        path={`${blUrls.dueDateCalculatorPath}/:dueDate`}
        element={<DueDateResults emailUrls={emailUrls} posts={posts} />}
      />
    </Routes>
  </Router>
)

export default DueDateCalculatorApp
