import css from '../styles.scss'

export const DesktopBackGroundCircle = () => (
  <>
    <svg
      className={css.desktopBGCircle}
      fill="none"
      height="163"
      viewBox="0 0 163 163"
      width="163"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M81.5 163C126.511 163 163 126.511 163 81.5C163 36.4888 126.511 0 81.5 0C36.4888 0 0 36.4888 0 81.5C0 126.511 36.4888 163 81.5 163Z"
        fill="#DBCCD8"
        fillOpacity="0.2"
      />
    </svg>
  </>
)
