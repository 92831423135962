import PropTypes from 'prop-types'
import { EaseInOutAlert } from 'components/alerts'
import { ALERT_FADE_ANIMATION_DURATION } from '../constants'

const StoreOrderWrapper = ({
  alert,
  className,
  children,
  onRemoveAlert,
  reservationCount,
  showTitle,
  storeName,
}) => {
  return (
    <li className={className}>
      {showTitle && <div className="mtn h4 text-bold">{storeName}</div>}
      {alert && onRemoveAlert && (
        <EaseInOutAlert
          animationDuratin={ALERT_FADE_ANIMATION_DURATION}
          variant={alert.variant}
          id={alert.id}
          onExited={() => onRemoveAlert(storeName)}
        >
          {alert.message}
        </EaseInOutAlert>
      )}

      {reservationCount > 0 && children}
    </li>
  )
}

StoreOrderWrapper.propTypes = {
  alert: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  onRemoveAlert: PropTypes.func,
  reservationCount: PropTypes.number.isRequired,
  showTitle: PropTypes.bool,
  storeName: PropTypes.string.isRequired,
}

StoreOrderWrapper.defaultProps = {
  showTitle: true,
}

export default StoreOrderWrapper
