import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Col } from 'react-bootstrap'

const BorderedOrderWrapper = ({ className, children, footerComponent }) => {
  let content = (
    <>
      <Col className="phn" md={children[1] ? 8 : 12}>
        {children[0]}
      </Col>
      {children[1] ? (
        <Col className="phn" md={4}>
          <hr className="visible-xs visible-sm hidden" />
          {children[1]}
        </Col>
      ) : null}
    </>
  )

  if (footerComponent) {
    content = <div className="clearfix">{content}</div>
  }
  return (
    <div className={classnames(className, 'mhn grid-border-between-sm')}>
      {content}
      {footerComponent ?? null}
    </div>
  )
}

BorderedOrderWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  footerComponent: PropTypes.node,
}

BorderedOrderWrapper.defaultProps = {
  footerComponent: null,
  className: '',
}

// eslint-disable-next-line import/no-default-export
export default BorderedOrderWrapper
export { BorderedOrderWrapper }
