import Frame from '../../components/Frame'
import SectionHeader from '../../components/SectionHeader'
import Image from '../../../../components/image'
import { mobileAppDownloadPath } from '../../../../lib/urls'

export default function FeaturedItemsSection() {
  return (
    <Frame>
      <SectionHeader className="text-center mhxl mvl">
        The Most Popular Product Based On Your Quiz
      </SectionHeader>

      <Image
        alt="Bottle box"
        className="no-pointer-events"
        width="100%"
        src="//images.babylist.com/image/upload/v1645146761/bottle-box-v9-alt1-gradient_zmusqr.jpg"
        lazyLoad={false}
      />

      <a href={mobileAppDownloadPath()} className="mtl btn-pill btn btn-info">
        Check It Out
      </a>
    </Frame>
  )
}
