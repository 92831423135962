import PropTypes from 'prop-types'

import { DiaperCalculatorResultLayout } from './diaper-calculator-result-layout'
import { Heading, Subheading, ResultProTipBox } from './components/ui'

const PrimaryContent = () => (
  <>
    <Heading>Just the Basics!</Heading>
    <Subheading>
      The outside world will still be there after you bring the baby home. So
      you don't need a year's worth of diapers on day one—just enough to get you
      through the early days. Your baby will go through approximately 250-300
      diapers in their first month. So register for one or two boxes and you'll
      be ready to roll when you get home.
    </Subheading>

    <p className="mbxxl">
      Diapers are just a click away, so you don't need a year's worth
      immediately—just enough to get you through the early days.
    </p>

    <p className="mbxxl">
      Your baby will go through approximately 250-300 diapers in their first
      month. Register for one or two boxes and you'll be ready to roll when you
      get home. Just remember that many babies outgrow newborn sizes quickly, so
      try a smaller pack of newborn sizes and a larger box of size ones. If your
      baby stays in newborn size for longer, you'll have the next size up at the
      ready.
    </p>

    <p className="mbxxl">
      If you want to try out a few different brands before you commit, the{' '}
      <a href="/hello-baby/babylist-diaper-box-brands" target="_blank">
        Babylist Diaper Box
      </a>{' '}
      is a good place to start. You'll get an assortment of diapers and wipes
      from three popular brands so you can decide for yourself what works best
      for your little one. Bonus: they're all eco-friendly and free of
      fragrances, chlorine and other things you likely don't want touching your
      baby's skin.
    </p>
  </>
)

const SecondaryContent = ({ DiaperCalculatorProducts }) => (
  <>
    <div className="mbxxl">
      <DiaperCalculatorProducts />
    </div>

    <div className="mbxxl text-center">
      <ResultProTipBox>
        <p>
          Those first few weeks can fly by, so consider setting up an
          auto-delivery of diapers before your baby gets here. As an added
          bonus, when you subscribe to regular deliveries, you usually save a
          little cash in the process.
        </p>
      </ResultProTipBox>
    </div>
  </>
)

SecondaryContent.propTypes = {
  DiaperCalculatorProducts: PropTypes.elementType.isRequired,
}

export const DiaperCalculatorResultJustTheBasics = ({
  DiaperCalculatorProducts,
}) => (
  <DiaperCalculatorResultLayout
    primaryContent={<PrimaryContent />}
    secondaryContent={
      <SecondaryContent DiaperCalculatorProducts={DiaperCalculatorProducts} />
    }
  />
)

DiaperCalculatorResultJustTheBasics.propTypes = {
  DiaperCalculatorProducts: PropTypes.elementType.isRequired,
}
