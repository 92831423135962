module.exports = [
  { value: 'budget_friendly', label: "Won't break the bank" },
  { value: 'eco_conscious', label: 'Eco-conscious' },
  { value: 'easy_to_use', label: 'Easy to use' },
  { value: 'pretty', label: 'Instagram-ready' },
  { value: 'everything_included', label: 'All the bells and whistles' },
  { value: 'reliable', label: 'Reliable' },
  { value: 'easy_to_clean', label: 'Easy to clean' },
  { value: 'space_saver', label: 'Space-saving' },
]
