import React from 'react'
import Link from 'components/link'

interface FAQItem {
  id: string
  question: string
  answer: React.ReactNode
}

export const faqItems: FAQItem[] = [
  {
    id: 'how-to-use-calculator',
    question: 'How do I use the Due Date Calculator?',
    answer: (
      <>
        Select your calculation method–either conception date or last menstrual
        period–and enter a date. We'll crunch the numbers to determine your
        estimated due date.
      </>
    ),
  },
  {
    id: 'how-due-date-calculated',
    question: 'How is my due date calculated?',
    answer: (
      <>
        Your due date is calculated based on the first day of your last
        menstrual period (LMP) or, if known, your conception date. The basic
        formula is LMP + 280 days = EDD (estimated due date), and pregnancy week
        counting starts on the first day of your last period, starting with Week
        0 (not 1!) and counting all the way up to Week 40 (your due date) and as
        high as Week 42.
      </>
    ),
  },
  {
    id: 'how-conception-calculated',
    question: 'How is the date of conception calculated?',
    answer: (
      <>
        Your conception date is an estimated timeframe based on the first day of
        your last period. If you're not in the habit of{' '}
        <Link url="https://www.babylist.com/hello-baby/best-fertility-apps">
          tracking your cycle with apps
        </Link>{' '}
        or calendars, don't worry; some healthcare providers will perform an{' '}
        <Link url="https://www.webmd.com/parenting/baby/features/first-pregnancy-ultrasound#1">
          ultrasound
        </Link>{' '}
        (called the "dating" or "viability" ultrasound—you can request one if
        your provider doesn't automatically do it) when you're around seven to{' '}
        <Link url="https://www.babylist.com/hello-baby/8-weeks-pregnant">
          eight weeks pregnant
        </Link>{' '}
        to get an early measurement of your baby.
      </>
    ),
  },
  {
    id: 'already-know-due-date',
    question: 'What if I already know my due date?',
    answer: (
      <>
        If you already know your due date, it's important to continue regular
        prenatal care appointments with your healthcare provider. They will
        monitor your progress throughout your pregnancy and make any adjustments
        to your due date if needed based on your health and the baby's
        development.
      </>
    ),
  },
  {
    id: 'due-date-change',
    question: 'Can my due date change?',
    answer: (
      <>
        Yes, due dates can change based on various factors such as ultrasound
        measurements, changes in menstrual cycle length, or new information
        about the date of conception. In fact, the earlier the ultrasound is
        done, the more accurate the calculated due date will be. Your healthcare
        provider will adjust your due date if necessary to ensure the most
        accurate estimation.
      </>
    ),
  },
  {
    id: 'likely-birth-due-date',
    question: 'How likely am I to give birth on my due date?',
    answer: (
      <>
        There's certainly some level of accuracy to your EDD, but only about
        five percent of babies are born on their due date. According to{' '}
        <Link url="https://www.cdc.gov/nchs/data/nvsr/nvsr67/nvsr67_08-508.pdf">
          birth data collected by the CDC
        </Link>
        , in 2021 over 10% of babies in the US were born three or more weeks
        before their due date, and nearly 56% were born either the week of or
        the week before their EDD. So rather than basing baby's arrival around a
        single day, it might be more accurate to consider it your due week, or
        even your due month.
      </>
    ),
  },
  {
    id: 'pregnancy-test-time',
    question: 'How soon can I take a pregnancy test?',
    answer: (
      <>
        You can take a pregnancy test as soon as you miss your period or
        experience other early pregnancy symptoms. Most home pregnancy tests can
        detect pregnancy hormones in your urine about one to two weeks after
        conception.
      </>
    ),
  },
  {
    id: 'pregnancy-test-accuracy',
    question: 'How accurate are pregnancy tests?',
    answer: (
      <>
        In general, home pregnancy tests are more accurate the longer you wait
        to take them. By testing on the day of your expected period, or after,
        you're likely to get a more accurate answer. If you test too early (just
        days after ovulation), you could get a false negative and have to test
        again later.
      </>
    ),
  },
  {
    id: 'positive-pregnancy-test-next',
    question: 'My pregnancy test is positive, what should I do next?',
    answer: (
      <>
        If you receive a positive{' '}
        <Link url="https://www.babylist.com/hello-baby/best-pregnancy-tests">
          pregnancy test result
        </Link>
        , the next step is to schedule an appointment with your healthcare
        provider. They will confirm the pregnancy, discuss prenatal care options
        and provide guidance on maintaining a healthy pregnancy.
      </>
    ),
  },
]
