import * as React from 'react'
import classnames from 'classnames'

export interface SectionHeaderProps
  extends React.HTMLAttributes<HTMLHeadingElement> {
  subHeading?: React.ReactNode
}

export default function SectionHeader({
  children,
  subHeading,
  className,
  ...rest
}: SectionHeaderProps) {
  return (
    <div className={classnames('section', className)}>
      <h2 className="primary-header h2 text-bold" {...rest}>
        {children}
      </h2>
      {subHeading && (
        <p className="sub-header" data-testid="section-header-subtitle">
          {subHeading}
        </p>
      )}
    </div>
  )
}
