/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-default-export */
import PropTypes from 'prop-types'
import classnames from 'classnames'
import css from './styles.scss'

/**
 * Used to represent an item in a list. It must be contained in a parent component `List`.
 */
export default function ListItem({ children, className, variant, ...rest }) {
  return (
    <li className={classnames(css.root, css[variant], className)} {...rest}>
      {children}
    </li>
  )
}

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'plain']),
}

ListItem.defaultProps = {
  className: undefined,
  variant: 'outlined',
}
