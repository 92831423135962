import { Component } from 'react'
import PropTypes from 'prop-types'
import { indexOf, isArray } from 'lodash'

import { DefaultButton, PrimaryButton } from 'components/buttons'

import * as blUrls from '../lib/urls'

import Start from './start'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
import Step5 from './step5'
import Step6 from './step6'
import Finish from './finish'
// Temporary until new review collection flow is in place
import { question1Answers } from './questions.js'

export default class UserReviewContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    generic_product: PropTypes.object,
  }

  state = {
    user_review_id: null,
    currentStep: 0,
    answers: {},
  }

  handleFormChange = (questionId, value) => {
    let answers = { ...this.state.answers }
    answers[questionId] = value
    this.setState({ answers })
  }

  handleProductTraitsChange = (questionId, trait, checked) => {
    let answers = { ...this.state.answers }
    answers[questionId] = answers[questionId] || {}
    answers[questionId][trait] = checked
    this.setState({ answers })
  }

  fetchUserReviewId = () => {
    $.ajax({
      url: blUrls.reviewsPath,
      type: 'POST',
      data: {
        user_id: this.props.user.id,
        user_name: this.props.user.name,
        generic_product_id: this.props.generic_product.id,
      },
    })
      .done((data) => {
        let user_review_id = data.user_review_id
        this.setState({ user_review_id })
      })
      .fail((data) => {
        console.log(data)
      })

    this.nextStep()
  }

  handleSubmit = (questionIds = []) => {
    if (!isArray(questionIds)) {
      questionIds = [questionIds] // meh
    }

    questionIds.forEach((qId) => {
      // Temporary until new review collection flow is in place
      if (qId === 1) {
        var answer = 5 - indexOf(question1Answers, this.state.answers[qId])
      } else {
        var answer = this.state.answers[qId]
      }

      let data = {
        question_id: qId,
        answer: answer,
        user_review_id: this.state.user_review_id,
      }

      $.ajax({
        url: blUrls.reviewAnswersPath,
        type: 'POST',
        data: data,
      })
        .done((data) => {})
        .fail((data) => {
          console.log(data)
        })
    })

    this.nextStep()
  }

  nextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  render() {
    let { user, generic_product } = this.props
    let { currentStep } = this.state
    let question, questionIds, buttons

    switch (currentStep) {
      case 0:
        question = (
          <Start
            name={user.name}
            productName={generic_product.name}
            productImage={generic_product.image}
          />
        )

        buttons = (
          <PrimaryButton onClick={this.fetchUserReviewId}>
            Let&apos;s Go!
          </PrimaryButton>
        )
        break
      case 1:
        questionIds = [1, 7]
        question = (
          <Step1
            onChange={this.handleFormChange}
            questionIds={questionIds}
            productName={generic_product.name}
          />
        )
        break
      case 2:
        questionIds = [2]
        question = (
          <Step2
            onChange={this.handleFormChange}
            question1Answer={this.state.answers[1]}
            questionIds={questionIds}
          />
        )
        break
      case 3:
        questionIds = [3]
        question = (
          <Step3
            questionIds={questionIds}
            productName={generic_product.name}
            onChange={this.handleProductTraitsChange}
          />
        )
        break
      case 4:
        questionIds = [5]
        question = (
          <Step4
            onChange={this.handleFormChange}
            productName={generic_product.name}
            questionIds={questionIds}
          />
        )

        buttons = (
          <div>
            <DefaultButton
              className="mrm"
              onClick={() => {
                this.setState({ currentStep: this.state.currentStep + 2 })
              }}
            >
              No
            </DefaultButton>
            <PrimaryButton onClick={this.nextStep}>Yes</PrimaryButton>
          </div>
        )
        break
      case 5:
        questionIds = [6]
        question = (
          <Step5
            onChange={this.handleFormChange}
            productName={generic_product.name}
            questionIds={questionIds}
          />
        )
        break
      case 6:
        questionIds = [4]
        question = (
          <Step6
            onChange={this.handleFormChange}
            productName={generic_product.name}
            questionIds={questionIds}
          />
        )
        break
      default:
        question = <Finish name={user.name} />
        buttons = (
          <PrimaryButton href={blUrls.catalogPath}>Go to Catalog</PrimaryButton>
        )
    }

    // these can be overridden above if custom buttons are needed
    if (typeof buttons === 'undefined') {
      buttons = (
        <div>
          <PrimaryButton onClick={this.handleSubmit.bind(this, questionIds)}>
            Next
          </PrimaryButton>
          <div className="mtm">
            <a className="question-skip text-muted" onClick={this.nextStep}>
              Skip
            </a>
          </div>
        </div>
      )
    }

    return (
      <div key={currentStep} className="user-review-container">
        <div className="mtl">
          {currentStep > 0 && currentStep < 7 ? (
            <div className="h6 question-counter mbl text-center">
              Question {currentStep} of 6
            </div>
          ) : null}
          <div className="question-container">
            {question}
            <div className="text-center mvl">{buttons}</div>
          </div>
        </div>
      </div>
    )
  }
}
