import PropTypes from 'prop-types'
import { useMutation } from '@tanstack/react-query'
import qs from 'querystring'
import fetch from 'lib/fetch'

import Card from 'components/card'
import { Radio, RadioGroup } from 'components/forms'
import { InfoButton } from 'components/buttons'
import { track } from 'lib/analytics'

import { Heading, Subheading } from './components/ui'
import { FullPageWave } from './components/wave'
import { QuestionShape } from './prop-types'
import { useNavigate } from 'react-router-dom-v5-compat'

function questionInputName(question) {
  return `answers[${question.key}]`
}

const DiaperQuestionCard = ({ question, onChange }) => {
  return (
    <Card className="pal">
      <p className="text-center h3 mbxl">{question.prompt}</p>
      <RadioGroup className="phl">
        {question.options.map((o) => {
          return (
            <Radio
              key={o.key}
              label={o.prompt}
              name={questionInputName(question)}
              value={o.key}
              size="large"
              onChange={(event) => {
                onChange(event, o)
              }}
            />
          )
        })}
      </RadioGroup>
    </Card>
  )
}

DiaperQuestionCard.propTypes = {
  question: QuestionShape.isRequired,
  onChange: PropTypes.func.isRequired,
}

export const DiaperCalculatorForm = ({ questions }) => {
  const navigate = useNavigate()

  const { mutate: sendAnswers } = useMutation({
    mutationFn: async (data) => {
      const diaperCalculator = await fetch(
        `/diaper-calculator.json?${qs.stringify(data)}`
      )

      navigate(`/diaper-calculator/${diaperCalculator.result.slug}`)
    },
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    const data = new FormData(event.target)

    const answers = {}
    questions.forEach((q) => {
      const name = questionInputName(q)
      answers[name] = data.get(name)
    })

    sendAnswers(answers)
  }

  return (
    <FullPageWave className="container-sm mbxxl">
      <div className="text-center mbxxl">
        <Heading>Diaper Calculator</Heading>
        <Subheading>
          Babies go through an average of 2,200 diapers in the first year (and
          700 of those will be in the first three months alone). Use this quiz
          to figure out how many to register for.
        </Subheading>
      </div>
      <form method="get" action="/diaper-calculator" onSubmit={handleSubmit}>
        {questions.map((q, idx) => {
          return (
            <div key={q.key} className="mbxl">
              <DiaperQuestionCard
                question={q}
                onChange={(_event, option) => {
                  track.diaperCalculatorOptionSelected({
                    eventLocation: track.EventLocation.DIAPER_CALCULATOR,
                    diaperCalculatorOptionText: option.prompt,
                    diaperCalculatorOptionKey: option.key,
                    diaperCalculatorPromptText: q.prompt,
                    diaperCalculatorPromptKey: q.key,
                    diaperCalculatorPromptIndex: idx + 1,
                    diaperCalculatorPromptTotalCount: questions.length,
                  })
                }}
              />
            </div>
          )
        })}

        <div className="text-center">
          <InfoButton pill type="submit">
            See My Results!
          </InfoButton>
        </div>
      </form>
    </FullPageWave>
  )
}

DiaperCalculatorForm.propTypes = {
  questions: PropTypes.arrayOf(QuestionShape).isRequired,
}
