import React from 'react'

interface GoatStickerProps {
  className?: string
}

const GoatSticker: React.FC<GoatStickerProps> = ({
  className,
}: GoatStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1019_6471)">
      <path
        d="M31.8206 86.1083C31.8206 86.1083 34.6746 92.8371 41.1407 93.7549C47.6067 94.6726 52.3041 90.0087 58.1157 87.5058C63.9273 85.0028 79.1874 85.9664 82.0953 74.749C85.0031 63.5356 82.4805 42.1352 58.8447 47.8462C46.3682 50.8622 45.2456 64.1822 44.0527 67.3193C42.8597 70.4605 32.2016 77.2769 31.8247 86.1041L31.8206 86.1083Z"
        fill="#F3D7E8"
      />
      <path
        d="M60.6797 102.465C60.3897 101.998 60.6797 82.6583 60.6797 82.6583L82.6917 62.0088C82.6917 62.0088 91.349 92.4574 93.8799 97.8388C96.4109 103.22 84.8746 105.122 80.9395 102.474C77.0002 99.8245 68.3428 114.742 60.6838 102.465H60.6797Z"
        fill="#F3D7E8"
      />
      <path
        d="M79.0713 52.8189C79.0713 52.8189 82.3437 48.4596 92.194 49.3481C92.194 49.3481 92.3224 60.1776 82.6916 62.009L79.0713 52.8231V52.8189Z"
        fill="#F3D7E8"
      />
      <path
        d="M58.8447 47.8504C58.8447 47.8504 50.1418 43.3659 46.1279 43.9666C46.1279 43.9666 48.1991 48.5804 52.3289 50.9666C56.4587 53.3528 58.8447 47.8504 58.8447 47.8504Z"
        fill="#F3D7E8"
      />
      <path
        d="M79.0713 60.1651C79.0713 60.1651 80.7282 58.1836 82.178 56.8153C83.0064 56.031 84.1621 55.6973 85.2805 55.9017L87.2233 56.2521L84.6592 54.3624H88.5364L86.1587 53.04H88.9713L87.7203 51.6133L91.0342 50.9625C91.0342 50.9625 91.4898 60.745 79.0713 60.1693V60.1651Z"
        fill="#FFD43F"
      />
      <path
        d="M38.5723 93.0332C38.5723 93.0332 40.4529 99.6327 40.0801 102.782C40.0801 102.782 41.5796 100.517 41.7038 98.3103V107.88C41.7038 107.88 43.7045 100.2 43.8288 97.9307L44.9555 106.053C44.9555 106.053 47.2379 95.4361 47.3166 93.0332C47.3166 93.0332 41.3352 94.7394 38.5723 93.0332Z"
        fill="#FFD43F"
      />
      <path
        d="M47.5198 62.0422C47.5198 62.0422 46.5464 70.4146 43.4397 72.6214C43.4397 72.6214 46.5837 71.3616 47.5198 69.8514C47.5198 69.8514 46.0203 73.9438 44.8315 74.5111C44.8315 74.5111 48.5223 72.7465 50.7715 67.3943C53.0208 62.0422 54.7564 61.2871 54.7564 61.2871C54.7564 61.2871 49.1477 60.8449 48.2074 58.8926L47.5198 62.0422Z"
        fill="#FFD43F"
      />
      <path
        d="M55.2203 49.9154C55.2203 49.9154 49.5164 35.5776 60.9449 17.3601C60.9449 17.3601 56.0321 41.4512 62.2745 48.4804C62.2745 48.4804 57.4902 52.0054 55.2203 49.9154Z"
        fill="#A4DAF6"
      />
      <path
        d="M67.2495 48.6265C67.2495 48.6265 71.7397 33.2373 92.6292 23.9471C92.6292 23.9471 73.057 41.5347 74.4405 51.5007C74.4405 51.5007 68.0324 51.8386 67.2537 48.6265H67.2495Z"
        fill="#A4DAF6"
      />
      <path
        d="M58.8447 65.3462C58.8447 65.3462 64.8262 59.6269 70.5094 66.9105C70.5094 66.9105 64.6978 73.4099 58.8447 65.3462Z"
        fill="#F9F6F3"
      />
      <path
        d="M64.677 69.3176C66.3333 69.3176 67.676 67.9654 67.676 66.2974C67.676 64.6293 66.3333 63.2771 64.677 63.2771C63.0207 63.2771 61.678 64.6293 61.678 66.2974C61.678 67.9654 63.0207 69.3176 64.677 69.3176Z"
        fill="#6F3264"
      />
      <path
        d="M32.1477 83.8348C32.1477 83.8348 32.827 86.4087 33.7632 86.8801C34.6993 87.3515 38.8789 86.1834 38.5724 84.69C38.2658 83.1966 30.5322 80.7895 32.1477 83.8348Z"
        fill="#6F3264"
      />
      <path
        d="M62.6222 62.0673C63.0779 61.9588 63.5708 61.9421 64.0555 61.7168C64.188 61.6501 64.391 61.6793 64.5442 61.7168C65.0165 61.8295 65.4721 61.9755 65.9443 62.0714C66.0147 62.0881 66.0852 62.1298 66.1514 62.1298C66.8515 62.1173 67.4148 62.5553 67.9989 62.8348C68.4214 63.0309 68.7611 63.3563 69.2167 63.5148C69.4653 63.6149 69.6889 63.836 69.8878 64.0404C70.4967 64.6453 70.9523 65.4171 71.1719 66.2514C71.2464 66.5059 71.2547 66.752 71.1512 66.8479C71.089 66.9063 71.1056 67.1233 71.0517 67.1608C70.973 67.2192 70.8612 67.1942 70.6789 67.1316C70.505 67.0607 70.2564 66.9647 70.0162 66.8021C69.7884 66.6644 69.602 66.4975 69.399 66.3557C69.0096 66.0762 68.5747 65.9093 68.3096 65.5089C68.206 65.3712 68.0196 65.2836 67.8498 65.2127C67.3776 64.9957 66.9675 64.7079 66.5118 64.4701C66.3668 64.3992 66.2177 64.2991 66.0562 64.2866C65.6337 64.2407 65.2319 64.1781 64.8218 64.1239C64.6271 64.0905 64.4365 64.0947 64.2377 64.0363C64.0306 63.9904 63.8028 64.0446 63.5874 64.0696C63.0116 64.128 62.4648 64.2991 61.8766 64.3742C61.2843 64.7079 60.5594 64.8497 60.0126 65.2794C59.8469 65.4129 59.6025 65.4963 59.3208 65.5589C58.9936 65.6298 58.7948 65.6757 58.6954 65.6674C58.5959 65.659 58.6001 65.6048 58.6084 65.4379C58.6084 65.3587 58.6249 65.2586 58.6415 65.1418C58.6622 64.9791 58.7285 64.8205 58.919 64.5994C59.1966 64.3074 59.4368 63.957 59.7144 63.6358C59.9339 63.3772 60.1534 63.1102 60.4972 62.985C60.6215 62.9433 60.7292 62.8348 60.8452 62.7598C61.3588 62.3885 61.9595 62.1257 62.6305 62.0673H62.6222Z"
        fill="#6F3264"
      />
      <path
        d="M38.4191 91.6066C38.9286 91.7776 39.5002 91.932 40.0553 91.8736C40.2044 91.8652 40.4281 91.8861 40.602 91.9111C41.1322 91.9778 41.6832 91.9862 42.2382 91.8944C42.3211 91.8819 42.4122 91.8777 42.4868 91.8527C43.2282 91.6274 43.9987 91.4856 44.7112 91.1602C45.2124 90.9308 45.7219 90.7013 46.1651 90.3467C46.4054 90.1507 46.7243 89.963 47.006 89.7627C47.8551 89.1703 48.6795 88.5237 49.5038 87.8479C49.7606 87.6352 49.9926 87.46 50.0713 87.4767C50.1168 87.4892 50.3322 87.314 50.3695 87.3265C50.4482 87.3515 50.2659 87.7937 49.7564 88.5071C49.5203 88.8199 49.2552 89.1203 48.986 89.4081C48.4723 89.963 47.938 90.5178 47.2628 90.9016C47.0267 91.0392 46.7574 91.2478 46.5048 91.4147C45.784 91.9069 44.9846 92.2573 44.1561 92.5118C43.891 92.5952 43.6135 92.6536 43.3566 92.7412C42.6815 92.9582 41.969 93.075 41.2441 93.1C40.9003 93.1125 40.5648 93.1042 40.2209 93.0583C39.8647 93.0041 39.4753 92.9832 39.1025 92.8998C38.1291 92.6995 37.1515 92.3074 36.3686 91.6566C35.3745 91.01 34.6206 89.9546 34.1401 88.8742C33.9785 88.5404 33.8874 88.0982 33.8626 87.6686C33.8501 87.1638 33.9081 86.8926 33.933 86.7883C33.962 86.6882 33.9868 86.755 34.0573 86.9302C34.0945 87.0136 34.1235 87.1263 34.1815 87.2431C34.2561 87.4099 34.3265 87.6143 34.4508 87.9773C34.6289 88.4862 34.923 88.9367 35.2502 89.3205C35.5153 89.6292 35.7929 89.8962 36.0911 90.2132C36.1905 90.3342 36.3438 90.4302 36.4681 90.5303C37.0728 90.9558 37.7107 91.3104 38.4232 91.5982L38.4191 91.6066Z"
        fill="#6F3264"
      />
      <path
        d="M68.0778 84.7318C68.6619 84.64 69.2957 84.5816 69.8673 84.3814C70.0206 84.3271 70.2691 84.3021 70.4596 84.2896C71.0395 84.2479 71.6153 84.1853 72.1994 84.0601C72.2864 84.0393 72.3816 84.0309 72.4562 84.0059C73.235 83.7389 74.0427 83.6305 74.809 83.3551C75.3475 83.1716 75.8943 82.988 76.3872 82.696C76.6565 82.525 77.0044 82.3831 77.3068 82.2163C78.2305 81.7365 79.1128 81.1817 79.9371 80.5101C80.2022 80.3098 80.4176 80.1138 80.4963 80.1305C80.5419 80.1388 80.7573 79.9553 80.7904 79.9678C80.8402 79.9845 80.807 80.0929 80.7117 80.3057C80.6206 80.5226 80.4508 80.823 80.1774 81.1817C79.9206 81.4946 79.6348 81.7908 79.3282 82.0619C78.7524 82.5834 78.1394 83.0798 77.4104 83.3843C77.1494 83.4845 76.8594 83.668 76.5819 83.8015C75.799 84.202 74.9706 84.4606 74.138 84.6734C73.8729 84.7401 73.5953 84.8027 73.3385 84.8778C72.6675 85.0947 71.9798 85.2449 71.2839 85.3575C70.9526 85.4159 70.6336 85.466 70.2981 85.491C69.9501 85.516 69.5815 85.5995 69.2211 85.6454C68.2684 85.7788 67.3239 85.9165 66.3712 85.9791C65.2777 86.1835 64.1178 86.2502 63.045 86.4838C62.7136 86.5589 62.3118 86.6048 61.91 86.634C60.9697 86.6966 60.8661 86.7216 61.1851 86.5297C61.2804 86.4713 61.3756 86.4129 61.4751 86.3504C61.6366 86.2502 61.8396 86.1668 62.2248 86.0458C62.7633 85.8956 63.2894 85.7163 63.8154 85.5536C64.2338 85.4243 64.6356 85.2949 65.1078 85.2407C65.2777 85.2198 65.4641 85.1698 65.6339 85.1322C66.4209 84.9487 67.2204 84.811 68.0695 84.7318H68.0778Z"
        fill="#6F3264"
      />
      <path
        d="M78.0358 78.6244C78.2595 78.5118 78.5204 78.4033 78.6944 78.178C78.74 78.1196 78.8394 78.0737 78.9222 78.0445C79.1666 77.9528 79.4069 77.8443 79.6305 77.6858C79.6637 77.6608 79.7092 77.6482 79.7341 77.619C79.9909 77.327 80.3596 77.1852 80.662 76.9474C80.8774 76.7764 81.1259 76.6471 81.304 76.4301C81.4034 76.3092 81.565 76.2174 81.7058 76.1214C82.1325 75.8419 82.584 75.5958 83.0686 75.3997C83.2219 75.3413 83.3627 75.2954 83.4166 75.3413C83.4497 75.3664 83.5823 75.3246 83.6071 75.3497C83.6693 75.4039 83.5823 75.6417 83.342 75.9921C83.2302 76.1423 83.0976 76.2716 82.9734 76.4093C82.729 76.6637 82.5094 76.9474 82.1863 77.1059C82.0703 77.1643 81.9626 77.277 81.8632 77.3729C81.5733 77.6482 81.246 77.861 80.9105 78.0821C80.8028 78.153 80.6868 78.2156 80.5957 78.299C80.3554 78.5285 80.0862 78.7245 79.7962 78.8997C79.6595 78.9832 79.527 79.0666 79.3737 79.1292C79.2163 79.1917 79.0672 79.3044 78.9056 79.3878C78.4873 79.6047 78.0399 79.8133 77.5553 79.8759C77.0209 80.0845 76.4037 80.0553 75.8321 80.0219C75.654 80.0136 75.4427 79.9468 75.2605 79.83C75.0451 79.6923 74.9415 79.6006 74.9084 79.5505C74.8752 79.5004 74.9084 79.4921 75.0119 79.4671C75.0699 79.4504 75.1279 79.4337 75.1859 79.4212C75.2812 79.392 75.3847 79.3795 75.5628 79.3878C75.8155 79.4003 76.0641 79.3419 76.296 79.2668C76.4824 79.2084 76.6481 79.125 76.8511 79.1083C76.9256 79.1 77.0002 79.0666 77.0665 79.0332C77.3854 78.8914 77.692 78.7496 78.0399 78.6327L78.0358 78.6244Z"
        fill="#6F3264"
      />
      <path
        d="M81.2833 73.0803C81.3578 73.0177 81.4531 72.9885 81.4738 72.8383C81.478 72.8008 81.5194 72.7883 81.5525 72.7841C81.6561 72.7758 81.7555 72.7591 81.8301 72.6965C81.8425 72.6881 81.8632 72.6882 81.8673 72.6756C81.9212 72.5255 82.091 72.5338 82.1863 72.4462C82.2567 72.3878 82.3561 72.3544 82.3851 72.2501C82.3976 72.1917 82.4597 72.1625 82.5094 72.1333C82.6502 72.0332 82.8449 71.979 83.0562 71.9206C83.1307 71.9081 83.2012 71.9039 83.2591 71.9373C83.2923 71.9581 83.3544 71.9456 83.3834 71.9665C83.4248 71.9957 83.4455 72.0332 83.4663 72.1C83.4828 72.1625 83.516 72.2668 83.5035 72.3586C83.4911 72.4379 83.4621 72.5129 83.4414 72.5922C83.3876 72.7299 83.3544 72.9009 83.197 72.9468C83.1432 72.9635 83.1142 73.0261 83.081 73.0719C82.9899 73.2138 82.8449 73.2889 82.7041 73.3681C82.6585 73.3932 82.6047 73.4098 82.5757 73.4516C82.497 73.5642 82.3934 73.6476 82.2816 73.7144C82.2277 73.7477 82.178 73.7811 82.1117 73.7978C82.0413 73.8103 81.9958 73.8729 81.9378 73.9063C81.7845 74.0022 81.6354 74.1107 81.449 74.1399C81.2833 74.2859 81.043 74.2817 80.8483 74.361C80.7862 74.3777 80.7075 74.386 80.6122 74.3318C80.3927 74.2025 80.3844 74.215 80.4134 74.094C80.4217 74.0564 80.4341 74.0231 80.4424 73.9897C80.459 73.9355 80.4797 73.8812 80.5377 73.8437C80.6205 73.7853 80.6827 73.6852 80.7406 73.5892C80.7862 73.51 80.8235 73.4265 80.8981 73.4057C80.927 73.3973 80.9436 73.364 80.9643 73.3389C81.0513 73.2179 81.1424 73.122 81.275 73.0803H81.2833Z"
        fill="#6F3264"
      />
      <path
        d="M68.2517 94.2597C68.2517 94.4474 68.2062 94.6435 68.2932 94.8479C68.318 94.9021 68.2932 94.9814 68.2683 95.0398C68.1938 95.215 68.1275 95.3944 68.0943 95.5821C68.0902 95.6113 68.0695 95.6363 68.0736 95.6655C68.1109 95.9409 67.9535 96.1787 67.8997 96.4373C67.8624 96.6208 67.7712 96.7877 67.7878 96.9838C67.792 97.0922 67.7381 97.2049 67.7008 97.3133C67.5724 97.6346 67.3943 97.9391 67.1582 98.2144C67.0795 98.2978 67.0049 98.3688 66.9345 98.3604C66.8931 98.3521 66.8227 98.4188 66.7895 98.4105C66.7067 98.3938 66.628 98.2144 66.5866 97.9307C66.5741 97.8056 66.5866 97.6846 66.5948 97.5636C66.6073 97.3342 66.5824 97.1006 66.7108 96.9003C66.7564 96.8294 66.7605 96.7293 66.7647 96.6375C66.7854 96.3831 66.8724 96.1495 66.9511 95.9075C66.9759 95.8324 67.0091 95.7531 67.0091 95.6739C67.0091 95.4653 67.0339 95.2609 67.0712 95.0565C67.0878 94.9605 67.1002 94.8646 67.1375 94.7686C67.1748 94.6685 67.1623 94.5559 67.1748 94.4474C67.2038 94.1638 67.2038 93.8801 67.2825 93.6006C67.241 93.2669 67.3612 92.9206 67.3529 92.5869C67.3529 92.4826 67.3902 92.3616 67.4647 92.2406C67.6387 91.957 67.6511 91.9236 67.7505 92.0362C67.7795 92.0613 67.8085 92.0988 67.8458 92.1364C67.8955 92.1906 67.9369 92.2573 67.9576 92.3825C67.9825 92.5535 68.0529 92.7246 68.1151 92.8956C68.1648 93.0333 68.2269 93.1626 68.202 93.3128C68.1938 93.367 68.2145 93.4254 68.2269 93.4796C68.2849 93.7341 68.3056 93.9927 68.2517 94.2555V94.2597Z"
        fill="#6F3264"
      />
      <path
        d="M85.6578 89.55C85.7904 89.7878 85.8939 90.0589 86.1135 90.2592C86.1715 90.3134 86.2087 90.4219 86.2336 90.5095C86.304 90.7723 86.3827 91.0309 86.5028 91.2812C86.5194 91.3188 86.5277 91.3646 86.5525 91.3939C86.7969 91.7026 86.8591 92.0947 87.0248 92.4368C87.1407 92.6787 87.2112 92.9415 87.381 93.1626C87.4721 93.2836 87.5301 93.4546 87.5881 93.609C87.7621 94.0762 87.8988 94.5601 87.9816 95.069C88.0023 95.2276 88.0148 95.3694 87.9609 95.4195C87.9278 95.4487 87.9402 95.5822 87.9112 95.603C87.8449 95.6531 87.6337 95.5196 87.352 95.2192C87.2319 95.0816 87.1366 94.9272 87.0331 94.777C86.8425 94.4892 86.6147 94.2222 86.536 93.8801C86.507 93.7592 86.42 93.634 86.3496 93.5172C86.1466 93.1835 86.0182 92.8247 85.8815 92.466C85.8359 92.3533 85.8028 92.2282 85.7365 92.1281C85.5667 91.8611 85.4259 91.5816 85.2933 91.2937C85.2312 91.1561 85.169 91.0267 85.1235 90.8766C85.0779 90.7222 84.9785 90.5804 84.9081 90.4344C84.7175 90.0422 84.5063 89.6626 84.3737 89.2413C84.1003 88.8074 83.9595 88.2818 83.7317 87.8313C83.6612 87.6894 83.6115 87.5059 83.6033 87.3098C83.5784 86.8468 83.5701 86.8009 83.7275 86.9052C83.7689 86.9302 83.8186 86.9636 83.8766 87.0011C83.9553 87.0554 84.0382 87.1263 84.1335 87.2806C84.2702 87.4976 84.44 87.6936 84.6057 87.8855C84.7382 88.0399 84.8791 88.1817 84.9578 88.3819C84.9868 88.4529 85.0448 88.5196 85.0945 88.5864C85.3181 88.8825 85.5128 89.1954 85.6578 89.5625V89.55Z"
        fill="#6F3264"
      />
      <path
        d="M85.5414 94.7769C85.6036 94.8812 85.6284 95.0105 85.7776 95.0856C85.819 95.1065 85.8231 95.1565 85.8231 95.1982C85.8231 95.3276 85.8231 95.4527 85.8728 95.5695C85.8811 95.5862 85.8728 95.6112 85.8894 95.6237C86.0344 95.7531 85.993 95.9491 86.0551 96.1077C86.1007 96.2203 86.0965 96.3496 86.1959 96.4414C86.2498 96.4914 86.2581 96.5749 86.2705 96.65C86.3202 96.8711 86.2995 97.113 86.2581 97.3675C86.2332 97.4509 86.2084 97.526 86.1462 97.5552C86.1089 97.5761 86.0924 97.6428 86.0592 97.6553C86.0137 97.6762 85.9681 97.672 85.8977 97.6512C85.8314 97.6261 85.7237 97.5969 85.6491 97.5344C85.5829 97.4759 85.5249 97.4092 85.4669 97.3466C85.3675 97.2215 85.2266 97.113 85.2432 96.942C85.2473 96.8794 85.2018 96.8252 85.1686 96.7751C85.0692 96.6291 85.0402 96.4539 85.0071 96.287C84.9947 96.2328 84.9947 96.1744 84.9615 96.1285C84.8745 96.0117 84.8165 95.8824 84.771 95.7489C84.7503 95.6863 84.7254 95.6237 84.7213 95.5528C84.7213 95.4777 84.6674 95.4152 84.6384 95.3484C84.5639 95.169 84.4686 94.998 84.452 94.7936C84.3112 94.6059 84.3195 94.3431 84.2242 94.1387C84.2035 94.0719 84.1911 93.9843 84.2366 93.88C84.3402 93.6339 84.3236 93.6172 84.452 93.6464C84.4893 93.6547 84.5266 93.6673 84.5639 93.6756C84.6219 93.6923 84.6799 93.7173 84.7254 93.7882C84.7917 93.8842 84.9035 93.9634 85.0029 94.0385C85.0858 94.1011 85.1769 94.1553 85.2018 94.2513C85.2101 94.2847 85.2432 94.3139 85.2722 94.3389C85.4006 94.464 85.5 94.6017 85.5373 94.7727L85.5414 94.7769Z"
        fill="#6F3264"
      />
      <path
        d="M82.1076 55.7808C82.2899 55.639 82.5177 55.5513 82.6751 55.3386C82.7165 55.2802 82.8118 55.251 82.8864 55.2343C83.1142 55.1842 83.3379 55.1342 83.5615 55.0591C83.5947 55.0466 83.6361 55.0466 83.661 55.0299C83.9426 54.8463 84.2864 54.863 84.5971 54.8005C84.8166 54.7462 85.0486 54.7754 85.2682 54.6795C85.3924 54.6378 85.5457 54.6503 85.6824 54.6545C86.1049 54.6753 86.5233 54.7754 86.9085 54.9673C87.0286 55.0341 87.128 55.0966 87.128 55.1676C87.128 55.2093 87.2192 55.2718 87.2192 55.3052C87.2192 55.3887 87.0286 55.4971 86.7262 55.5806C86.5895 55.6181 86.457 55.6306 86.3244 55.6556C86.0718 55.7015 85.8315 55.785 85.5788 55.7349C85.4877 55.7182 85.38 55.7432 85.2847 55.7724C85.0113 55.8475 84.7379 55.8642 84.4563 55.9018C84.3693 55.9185 84.274 55.9226 84.1953 55.9602C83.9882 56.0603 83.7769 56.1521 83.5615 56.2313C83.458 56.273 83.3627 56.3189 83.2509 56.3398C83.139 56.369 83.0396 56.4524 82.9361 56.5066C82.6585 56.661 82.41 56.8571 82.12 56.9989C81.8674 57.2742 81.507 57.4494 81.2667 57.7373C81.1922 57.8249 81.072 57.9 80.9312 57.9584C80.5957 58.096 80.5584 58.1252 80.5625 57.9625C80.5625 57.9208 80.5625 57.8708 80.5625 57.8124C80.5625 57.7331 80.5791 57.6413 80.6578 57.5162C80.7738 57.3493 80.8525 57.1449 80.9519 56.9572C81.0348 56.8112 81.101 56.6485 81.2502 56.5442C81.304 56.5066 81.3454 56.4441 81.3869 56.3898C81.478 56.2605 81.5857 56.1479 81.7058 56.0436C81.8259 55.9393 81.9543 55.8434 82.1035 55.7724L82.1076 55.7808Z"
        fill="#6F3264"
      />
      <path
        d="M55.2408 49.494C55.2988 49.5858 55.3112 49.6984 55.452 49.7652C55.4893 49.7819 55.4935 49.8278 55.4893 49.8695C55.4769 49.9863 55.4645 50.0947 55.4935 50.199C55.4976 50.2157 55.4935 50.2324 55.5018 50.2449C55.6219 50.3701 55.5515 50.5369 55.5805 50.6829C55.6053 50.7831 55.5722 50.8957 55.6467 50.9875C55.6882 51.0417 55.6716 51.1126 55.6674 51.1794C55.6592 51.3754 55.568 51.5757 55.4479 51.7634C55.3982 51.8218 55.3485 51.8719 55.2822 51.8802C55.2408 51.8844 55.2035 51.9303 55.1662 51.9344C55.1165 51.9344 55.0751 51.9219 55.0171 51.8885C54.9633 51.8552 54.8721 51.8051 54.8224 51.7384C54.7769 51.68 54.7437 51.6216 54.7064 51.5632C54.6484 51.4547 54.5449 51.3546 54.607 51.2336C54.6277 51.1919 54.5987 51.1418 54.578 51.1001C54.52 50.9791 54.5283 50.854 54.5325 50.7205C54.5325 50.6788 54.5407 50.6329 54.52 50.5995C54.4579 50.5077 54.4206 50.4118 54.3999 50.3075C54.3875 50.2574 54.3709 50.2157 54.3792 50.1573C54.3875 50.0989 54.3419 50.0572 54.3212 50.003C54.2715 49.8695 54.1887 49.7485 54.1887 49.59C54.0644 49.4648 54.081 49.2604 53.9857 49.1144C53.965 49.0644 53.9567 49.0018 53.9898 48.91C54.0768 48.6931 54.0602 48.6764 54.1804 48.6764C54.2176 48.6764 54.2508 48.6764 54.2881 48.6764C54.3419 48.6764 54.3999 48.6847 54.4496 48.7348C54.5159 48.8057 54.6277 48.8474 54.7271 48.8975C54.81 48.9392 54.9011 48.9726 54.9218 49.056C54.9301 49.0852 54.9633 49.1061 54.9923 49.1269C55.1207 49.2229 55.2118 49.3397 55.2408 49.4899V49.494Z"
        fill="#6F3264"
      />
      <path
        d="M57.8671 50.0989C57.892 50.1865 57.8754 50.2825 57.9914 50.3701C58.0245 50.3951 58.0121 50.4327 57.9955 50.4619C57.95 50.5536 57.9127 50.6412 57.9127 50.733C57.9127 50.7455 57.9003 50.7622 57.9085 50.7747C57.9955 50.9041 57.8837 51.025 57.8837 51.1502C57.8837 51.2378 57.8298 51.3254 57.8837 51.4172C57.9127 51.4672 57.8837 51.5256 57.8671 51.5799C57.8216 51.7384 57.6973 51.8927 57.5523 52.0304C57.4943 52.0721 57.4363 52.1055 57.3701 52.1055C57.3286 52.1055 57.2831 52.1347 57.2499 52.1347C57.2002 52.1305 57.1629 52.1097 57.1091 52.0679C57.0635 52.0304 56.9765 51.972 56.9393 51.9052C56.9061 51.8468 56.8813 51.7884 56.8564 51.7342C56.815 51.6257 56.7363 51.5215 56.8191 51.4255C56.8481 51.3921 56.8274 51.3462 56.8191 51.3045C56.786 51.1877 56.8274 51.0793 56.8564 50.9666C56.8647 50.9291 56.8854 50.8915 56.873 50.8582C56.8357 50.7664 56.8233 50.6704 56.8316 50.5787C56.8316 50.5328 56.8316 50.4911 56.8523 50.4452C56.8771 50.3993 56.844 50.3492 56.8398 50.2992C56.8274 50.1698 56.7901 50.0447 56.8398 49.9112C56.7611 49.7694 56.844 49.5983 56.8108 49.4523C56.8108 49.4064 56.8233 49.348 56.8896 49.2813C57.0511 49.1269 57.0428 49.1103 57.1547 49.1436C57.1878 49.1561 57.2209 49.1645 57.2541 49.177C57.3038 49.1937 57.3535 49.2187 57.3825 49.273C57.4198 49.348 57.5068 49.4148 57.5855 49.4857C57.6476 49.5399 57.718 49.5942 57.7139 49.6651C57.7139 49.6901 57.7387 49.7151 57.7553 49.7402C57.8423 49.8528 57.892 49.9696 57.8754 50.0989H57.8671Z"
        fill="#6F3264"
      />
      <path
        d="M60.6922 49.0394C60.7129 49.1229 60.688 49.2105 60.7957 49.3022C60.8247 49.3273 60.8123 49.3606 60.7957 49.3898C60.746 49.4733 60.7004 49.5525 60.6963 49.636C60.6963 49.6485 60.6797 49.661 60.688 49.6735C60.7667 49.7987 60.6466 49.9071 60.6383 50.0239C60.6342 50.1074 60.572 50.1824 60.6217 50.2742C60.6466 50.3243 60.6176 50.3743 60.5969 50.4244C60.543 50.5704 60.4105 50.7039 60.2614 50.829C60.1992 50.8666 60.1412 50.8916 60.075 50.8874C60.0335 50.8833 59.988 50.9125 59.9507 50.9041C59.901 50.8958 59.8637 50.8749 59.814 50.8332C59.7684 50.7957 59.6897 50.7331 59.6524 50.6705C59.6193 50.6121 59.5986 50.5579 59.5779 50.5037C59.5447 50.3994 59.466 50.2951 59.5572 50.2116C59.5862 50.1824 59.5696 50.1366 59.5572 50.099C59.5323 49.9864 59.5779 49.8863 59.6152 49.782C59.6276 49.7486 59.6483 49.7152 59.6359 49.6819C59.6027 49.5901 59.5986 49.5025 59.611 49.419C59.6152 49.3773 59.611 49.3356 59.64 49.2939C59.6649 49.2522 59.64 49.2021 59.64 49.1562C59.64 49.0352 59.611 48.9143 59.6649 48.7933C59.5986 48.6515 59.6939 48.5013 59.6732 48.3594C59.6732 48.3136 59.6939 48.2635 59.7684 48.2051C59.9424 48.0716 59.9341 48.0549 60.046 48.0966C60.0791 48.1091 60.1122 48.1217 60.1412 48.1342C60.1909 48.155 60.2406 48.1801 60.2614 48.2343C60.2904 48.3094 60.3732 48.3761 60.4436 48.447C60.5016 48.5055 60.5679 48.5597 60.5596 48.6264C60.5596 48.6515 60.5803 48.6765 60.5969 48.7015C60.6756 48.81 60.717 48.9268 60.6922 49.0478V49.0394Z"
        fill="#6F3264"
      />
      <path
        d="M62.117 47.8087C62.175 47.8587 62.204 47.9338 62.3407 47.9297C62.378 47.9297 62.3862 47.9589 62.3862 47.9881C62.3862 48.0757 62.3987 48.1591 62.4442 48.2175C62.4525 48.2258 62.4442 48.2425 62.4608 48.2467C62.5934 48.2801 62.5644 48.4261 62.6265 48.5095C62.6721 48.5679 62.6721 48.6555 62.7632 48.6806C62.8129 48.6931 62.8212 48.7473 62.8377 48.7932C62.8875 48.9225 62.8792 49.0935 62.8543 49.2771C62.8377 49.3397 62.817 49.4022 62.7632 49.444C62.73 49.469 62.7176 49.5232 62.6886 49.5441C62.6513 49.5733 62.6099 49.5858 62.5436 49.5983C62.4857 49.6067 62.3862 49.6275 62.3158 49.6108C62.2537 49.5941 62.204 49.5733 62.146 49.5524C62.0507 49.5065 61.9223 49.4857 61.9306 49.3647C61.9306 49.323 61.8892 49.3021 61.856 49.2813C61.7608 49.2187 61.7318 49.1144 61.6945 49.0143C61.6821 48.9809 61.6779 48.9434 61.6489 48.9267C61.5661 48.8808 61.5081 48.8182 61.4667 48.7431C61.4459 48.7098 61.4211 48.6764 61.417 48.6305C61.4128 48.5804 61.3631 48.5596 61.3341 48.5262C61.2637 48.4344 61.1684 48.3552 61.1477 48.2258C61.0152 48.1508 61.011 47.9755 60.924 47.8713C60.9033 47.8337 60.8909 47.7795 60.924 47.6961C61.0069 47.4916 60.9944 47.4875 61.1063 47.4624C61.1394 47.4541 61.1726 47.4499 61.2057 47.4416C61.2554 47.4332 61.3093 47.4249 61.3548 47.4541C61.417 47.4958 61.5164 47.5042 61.6116 47.5208C61.6903 47.5292 61.769 47.5375 61.7939 47.5918C61.8022 47.6126 61.8353 47.6168 61.8602 47.6251C61.9803 47.6627 62.0714 47.7169 62.1129 47.817L62.117 47.8087Z"
        fill="#6F3264"
      />
      <path
        d="M66.9098 49.9613C66.9098 49.8904 66.9471 49.8278 66.8518 49.7318C66.827 49.7068 66.8435 49.6818 66.8643 49.6651C66.9264 49.6109 66.9802 49.5566 66.9968 49.4899C66.9968 49.4815 67.0134 49.4732 67.0092 49.4607C66.9471 49.348 67.0755 49.2813 67.0921 49.1895C67.1045 49.1228 67.1666 49.0686 67.1252 48.9893C67.1004 48.9434 67.1376 48.9059 67.1584 48.8725C67.2205 48.7599 67.353 48.6681 67.5063 48.5763C67.5643 48.5513 67.6223 48.5304 67.6886 48.5429C67.73 48.5513 67.7756 48.5304 67.8087 48.5388C67.8543 48.5471 67.8957 48.5721 67.9454 48.6097C67.991 48.6472 68.0697 48.7056 68.1028 48.764C68.1318 48.8183 68.1525 48.8683 68.1732 48.9184C68.2022 49.0143 68.2726 49.1186 68.1732 49.1812C68.1401 49.2062 68.1566 49.2479 68.1608 49.2813C68.1774 49.3856 68.1194 49.469 68.0697 49.5525C68.0531 49.5817 68.0282 49.6067 68.0365 49.6401C68.0572 49.7277 68.0489 49.8028 68.0241 49.8778C68.0117 49.9154 68.0075 49.9488 67.9744 49.9821C67.9412 50.0155 67.9578 50.0614 67.9495 50.1031C67.9288 50.2116 67.933 50.32 67.8501 50.4118C67.8833 50.5495 67.7548 50.6538 67.7383 50.7831C67.7259 50.8206 67.6927 50.8623 67.6057 50.8874C67.4028 50.9458 67.4069 50.9583 67.3116 50.8915C67.2826 50.8707 67.2578 50.8498 67.2288 50.8331C67.1874 50.8039 67.1501 50.7664 67.1418 50.7163C67.1335 50.6496 67.0714 50.5703 67.0175 50.4952C66.972 50.4327 66.9222 50.3743 66.9471 50.3242C66.9554 50.3075 66.9388 50.2783 66.9264 50.2575C66.8725 50.149 66.856 50.0531 66.9015 49.9613H66.9098Z"
        fill="#6F3264"
      />
      <path
        d="M68.2188 51.3755C68.2064 51.3046 68.2354 51.2295 68.1318 51.1419C68.1028 51.1168 68.1194 51.0876 68.1401 51.0668C68.1981 51.0042 68.2478 50.9416 68.2602 50.8707C68.2602 50.8582 68.2768 50.8499 68.2685 50.8415C68.2022 50.7247 68.3265 50.6496 68.3472 50.5537C68.3596 50.4827 68.4259 50.4285 68.3845 50.3451C68.3638 50.2992 68.401 50.2617 68.4218 50.2199C68.488 50.1031 68.6289 50.0155 68.7904 49.9404C68.8526 49.9238 68.9105 49.9112 68.9768 49.9279C69.0141 49.9404 69.0638 49.9279 69.097 49.9363C69.1425 49.953 69.1757 49.978 69.2254 50.0197C69.2668 50.0614 69.3372 50.124 69.3703 50.1824C69.3952 50.2325 69.4118 50.2825 69.4325 50.3284C69.4573 50.416 69.5236 50.5119 69.4325 50.562C69.3993 50.5787 69.4159 50.6204 69.42 50.6496C69.4366 50.7414 69.3869 50.8123 69.3413 50.8874C69.3289 50.9124 69.3041 50.9333 69.3123 50.9625C69.3372 51.0376 69.3372 51.1043 69.3206 51.1711C69.3124 51.2044 69.3123 51.2336 69.2834 51.2628C69.2544 51.292 69.2792 51.3338 69.2751 51.3713C69.2709 51.4673 69.2916 51.5632 69.2254 51.6508C69.2834 51.7634 69.1798 51.8761 69.1922 51.9887C69.1922 52.0221 69.1674 52.0638 69.0887 52.1055C68.9064 52.2056 68.9147 52.2181 68.807 52.1848C68.7739 52.1723 68.7449 52.1639 68.7117 52.1514C68.6662 52.1347 68.6164 52.1097 68.5957 52.068C68.5709 52.0054 68.4922 51.947 68.4259 51.8844C68.3721 51.8344 68.3058 51.7843 68.3223 51.7301C68.3265 51.7092 68.3058 51.6883 68.2892 51.6675C68.2188 51.5715 68.1815 51.4714 68.2188 51.3713V51.3755Z"
        fill="#6F3264"
      />
      <path
        d="M70.4388 51.9345C70.4471 51.851 70.4968 51.7718 70.4139 51.655C70.3932 51.6216 70.4139 51.5924 70.4388 51.5715C70.5092 51.5089 70.5755 51.4422 70.6003 51.3629C70.6003 51.3504 70.621 51.3421 70.6169 51.3296C70.5713 51.1919 70.7122 51.1168 70.7453 51.0042C70.7701 50.9249 70.8406 50.8623 70.8116 50.7664C70.795 50.7122 70.8364 50.6663 70.8613 50.6246C70.94 50.4911 71.0891 50.3826 71.2589 50.2783C71.3252 50.2491 71.3832 50.2283 71.4495 50.2449C71.4909 50.2533 71.5406 50.2324 71.5737 50.2449C71.6193 50.2575 71.6566 50.2867 71.7022 50.3326C71.7436 50.3784 71.814 50.4535 71.8389 50.5244C71.8596 50.587 71.872 50.6496 71.8844 50.7122C71.8968 50.829 71.9548 50.9541 71.8471 51.0292C71.8099 51.0584 71.8181 51.1085 71.8181 51.1502C71.8181 51.2753 71.7477 51.3755 71.6856 51.4756C71.6649 51.5089 71.6359 51.5381 71.6359 51.5799C71.6442 51.6842 71.6235 51.7801 71.5862 51.8677C71.5696 51.9094 71.5572 51.9553 71.5199 51.9887C71.4826 52.0262 71.4909 52.0846 71.4785 52.1347C71.4412 52.264 71.4246 52.3975 71.3294 52.506C71.3418 52.6728 71.1968 52.798 71.1554 52.9482C71.1347 52.994 71.0974 53.0399 71.0063 53.0691C70.7909 53.1359 70.795 53.1484 70.708 53.0691C70.6832 53.0441 70.6583 53.0191 70.6335 52.994C70.5962 52.9565 70.563 52.9106 70.563 52.8522C70.563 52.7688 70.5133 52.6728 70.4719 52.5811C70.4388 52.506 70.3973 52.4309 70.4263 52.3725C70.4388 52.3516 70.4263 52.3182 70.4181 52.289C70.3808 52.1597 70.3766 52.0387 70.4388 51.9345Z"
        fill="#6F3264"
      />
      <path
        d="M72.9325 51.8052C72.9201 51.726 72.9491 51.6425 72.8372 51.5591C72.8082 51.5382 72.8206 51.5049 72.8372 51.4798C72.8869 51.4047 72.9283 51.3297 72.9283 51.2504C72.9283 51.2379 72.9408 51.2254 72.9325 51.2129C72.8455 51.1044 72.9491 50.9918 72.9408 50.8833C72.9366 50.8082 72.9739 50.7248 72.9118 50.6539C72.8745 50.6121 72.8952 50.5621 72.8994 50.512C72.9159 50.366 73.0029 50.2117 73.1023 50.0448C73.1437 49.9906 73.1893 49.9405 73.2556 49.9238C73.297 49.9113 73.3301 49.8654 73.3633 49.8571C73.4088 49.8446 73.4544 49.8529 73.5165 49.8738C73.5745 49.8946 73.6698 49.928 73.7319 49.9822C73.7817 50.0323 73.8189 50.0865 73.8604 50.1407C73.9266 50.245 74.0302 50.3493 73.9681 50.4703C73.9473 50.512 73.9763 50.5621 73.9929 50.6038C74.0426 50.7289 74.0178 50.8624 73.9929 50.9876C73.9846 51.0293 73.9681 51.071 73.9805 51.1086C74.0219 51.2129 74.0302 51.3171 74.0219 51.4256C74.0178 51.4757 74.0219 51.5257 73.9929 51.5758C73.9639 51.6258 73.9929 51.6842 73.9929 51.7385C73.9929 51.8845 74.0053 52.0305 73.9308 52.1723C73.9722 52.3475 73.8479 52.5102 73.8314 52.6771C73.8189 52.7272 73.7858 52.7856 73.6947 52.8273C73.4834 52.9232 73.4917 52.9357 73.3964 52.8648C73.3674 52.844 73.3426 52.8189 73.3177 52.7981C73.2763 52.7647 73.239 52.723 73.2307 52.6646C73.2183 52.5811 73.1603 52.4935 73.1023 52.4101C73.0568 52.3434 73.0029 52.2766 73.0236 52.2182C73.0319 52.1974 73.0112 52.1682 72.9988 52.1431C72.9366 52.0263 72.9076 51.9179 72.9408 51.8094L72.9325 51.8052Z"
        fill="#6F3264"
      />
      <path
        d="M47.7062 59.9273C47.7145 59.7938 47.7601 59.6561 47.6731 59.5059C47.6482 59.4642 47.6731 59.4142 47.6896 59.3724C47.7559 59.2515 47.8139 59.1263 47.8263 58.9928C47.8263 58.972 47.8429 58.9511 47.8388 58.9344C47.7725 58.7384 47.8967 58.5673 47.905 58.3796C47.9133 58.2503 47.963 58.1168 47.9092 57.9833C47.876 57.9082 47.9009 57.8248 47.9175 57.7413C47.9506 57.4994 48.0542 57.2533 48.166 56.9988C48.2116 56.9195 48.2613 56.8486 48.3275 56.8319C48.369 56.8236 48.4062 56.7568 48.4394 56.7485C48.4891 56.7401 48.5305 56.761 48.5926 56.8111C48.6465 56.857 48.7418 56.932 48.7998 57.0322C48.8453 57.1239 48.8785 57.2199 48.9157 57.3117C48.9696 57.491 49.0649 57.6746 48.982 57.8581C48.953 57.9249 48.982 58.0042 48.9903 58.0792C49.0234 58.2878 48.9737 58.4922 48.9323 58.6925C48.9157 58.755 48.8909 58.8218 48.8992 58.8885C48.924 59.0596 48.9116 59.2306 48.8785 59.3975C48.8619 59.4767 48.8536 59.556 48.8205 59.6311C48.7832 59.7103 48.7915 59.8063 48.7791 59.8897C48.7418 60.1192 48.7211 60.3569 48.6092 60.5697C48.6051 60.8492 48.4352 61.0995 48.3607 61.3623C48.3317 61.4416 48.282 61.5292 48.1743 61.5959C47.9257 61.7461 47.9257 61.7711 47.8553 61.6543C47.8346 61.6168 47.8139 61.5834 47.7932 61.55C47.7642 61.4958 47.7352 61.4332 47.7476 61.3373C47.7601 61.208 47.7269 61.0619 47.6979 60.9243C47.6689 60.8117 47.6399 60.7074 47.6772 60.6072C47.6896 60.5697 47.6772 60.5238 47.6772 60.4821C47.6482 60.2902 47.6482 60.1066 47.7103 59.9231L47.7062 59.9273Z"
        fill="#6F3264"
      />
      <path
        d="M49.6408 60.1859C49.6988 60.0482 49.7941 59.9189 49.7692 59.7312C49.7609 59.6811 49.8024 59.6311 49.8396 59.5977C49.9515 59.4934 50.055 59.3808 50.1255 59.2473C50.1337 59.2264 50.1586 59.2097 50.1586 59.1889C50.1752 58.9636 50.3657 58.8259 50.4568 58.6382C50.519 58.5047 50.6267 58.3921 50.635 58.2336C50.6391 58.146 50.7012 58.0667 50.7509 57.9916C50.8918 57.758 51.0989 57.5536 51.3184 57.3492C51.3971 57.2908 51.4758 57.2407 51.5421 57.2574C51.5835 57.2658 51.6457 57.2157 51.6788 57.2282C51.7244 57.2407 51.7534 57.2824 51.7865 57.3575C51.8155 57.4285 51.8652 57.5411 51.8652 57.6662C51.8652 57.7747 51.8528 57.879 51.8404 57.9875C51.8072 58.1835 51.8155 58.4004 51.6622 58.5465C51.6084 58.6007 51.596 58.6883 51.5752 58.7634C51.5173 58.9803 51.393 59.1638 51.2687 59.3432C51.2273 59.4016 51.1776 59.4559 51.161 59.5226C51.1155 59.702 51.0409 59.8647 50.9498 60.019C50.9042 60.0941 50.8711 60.1692 50.8089 60.2318C50.7426 60.2985 50.7219 60.3986 50.6764 60.4779C50.5604 60.6948 50.461 60.9284 50.2829 61.112C50.1876 61.3957 49.9432 61.6001 49.7941 61.8504C49.7402 61.9213 49.6657 62.0006 49.5414 62.0423C49.2514 62.1424 49.2473 62.1674 49.21 62.0298C49.1976 61.988 49.1893 61.9463 49.181 61.9088C49.1686 61.8462 49.1603 61.7711 49.2017 61.6793C49.2556 61.5458 49.268 61.3873 49.2846 61.2371C49.2929 61.112 49.297 60.9952 49.3674 60.8992C49.3923 60.8659 49.4006 60.8116 49.4088 60.7658C49.4461 60.553 49.5165 60.3611 49.6408 60.1901V60.1859Z"
        fill="#6F3264"
      />
      <path
        d="M54.0026 60.7657C54.1103 60.6489 54.2511 60.5613 54.3008 60.3694C54.3133 60.3194 54.3713 60.286 54.421 60.2652C54.5701 60.2026 54.7151 60.1358 54.8352 60.0315C54.8518 60.0149 54.8808 60.0107 54.8932 59.9898C55.005 59.7812 55.2453 59.7228 55.411 59.581C55.5269 59.4809 55.6761 59.4183 55.7589 59.2723C55.8045 59.193 55.8998 59.143 55.9785 59.0929C56.2187 58.9344 56.5045 58.8343 56.8028 58.7509C56.9022 58.7342 56.9975 58.7258 57.0472 58.7675C57.0762 58.7967 57.159 58.7801 57.1839 58.8051C57.217 58.8385 57.2253 58.8885 57.217 58.9719C57.2046 59.0512 57.1963 59.1764 57.1424 59.2848C57.0927 59.3808 57.0306 59.4725 56.9726 59.5643C56.8525 59.7312 56.7655 59.9272 56.5625 59.994C56.4921 60.019 56.4424 60.0941 56.3885 60.1525C56.2436 60.3277 56.053 60.4404 55.8666 60.5613C55.8086 60.5989 55.7382 60.6323 55.6968 60.6865C55.5849 60.8325 55.4524 60.9576 55.3074 61.0703C55.237 61.1245 55.1749 61.1787 55.092 61.2163C55.005 61.2538 54.9512 61.3414 54.8766 61.404C54.6861 61.5709 54.5162 61.7544 54.2843 61.8712C54.102 62.109 53.8038 62.23 53.5842 62.4261C53.5138 62.4803 53.4144 62.5345 53.286 62.5429C52.9795 62.5595 52.9629 62.5804 52.967 62.4386C52.967 62.401 52.967 62.3593 52.9753 62.3134C52.9836 62.2467 52.996 62.1758 53.0623 62.0923C53.1576 61.9797 53.2197 61.8253 53.2818 61.6835C53.3315 61.5667 53.373 61.4499 53.4765 61.379C53.5138 61.3539 53.5387 61.3039 53.5594 61.2622C53.6712 61.0661 53.8079 60.8992 53.9943 60.7741L54.0026 60.7657Z"
        fill="#6F3264"
      />
      <path
        d="M55.3278 42.9613C55.4355 42.9613 55.5556 42.9863 55.6675 42.8778C55.6964 42.8486 55.742 42.8612 55.7752 42.8778C55.8787 42.9279 55.9864 42.9655 56.0982 42.9571C56.1148 42.9571 56.1314 42.9696 56.148 42.9571C56.2888 42.857 56.4503 42.9488 56.6036 42.9112C56.7072 42.8945 56.8231 42.907 56.9143 42.8236C56.9598 42.7736 57.0344 42.7736 57.1007 42.7652C57.2954 42.7318 57.519 42.7527 57.7634 42.7652C57.8463 42.7819 57.9208 42.7986 57.9623 42.8528C57.9871 42.8862 58.0575 42.8945 58.0783 42.9237C58.1073 42.9655 58.1073 43.0113 58.0948 43.0864C58.0783 43.1532 58.0617 43.27 57.9954 43.3618C57.9374 43.4368 57.867 43.5036 57.8007 43.5703C57.664 43.683 57.5315 43.8373 57.3368 43.8165C57.2705 43.8081 57.2042 43.8582 57.1421 43.8874C56.9598 43.9792 56.761 43.9833 56.5663 43.9833C56.5042 43.9833 56.4379 43.9708 56.3799 43.9917C56.2267 44.0501 56.0651 44.0668 55.9036 44.0626C55.829 44.0626 55.7544 44.0626 55.6757 44.0376C55.597 44.0084 55.51 44.0292 55.4272 44.0292C55.2077 44.0167 54.9798 44.0125 54.7769 43.9082C54.5076 43.9082 54.2715 43.7414 54.0354 43.6538C53.965 43.6204 53.8821 43.5662 53.8324 43.4535C53.7206 43.1949 53.7082 43.1949 53.8241 43.1323C53.8614 43.1156 53.8946 43.0948 53.9277 43.0781C53.9816 43.0531 54.0437 43.028 54.1224 43.0489C54.2384 43.0656 54.3668 43.0405 54.4869 43.0072C54.5863 42.978 54.6775 42.9446 54.7603 42.978C54.7893 42.9905 54.8307 42.978 54.868 42.9696C55.0295 42.9321 55.1787 42.9196 55.3319 42.9613H55.3278Z"
        fill="#6F3264"
      />
      <path
        d="M56.1646 38.3975C56.235 38.385 56.3096 38.4142 56.3883 38.3057C56.409 38.2765 56.438 38.289 56.4587 38.3057C56.525 38.3558 56.5912 38.3975 56.6617 38.3975C56.6741 38.3975 56.6824 38.41 56.6948 38.4017C56.7901 38.3141 56.8895 38.4184 56.9889 38.41C57.0593 38.4058 57.1297 38.4434 57.196 38.3808C57.2292 38.3433 57.2789 38.36 57.3203 38.3683C57.4487 38.385 57.5937 38.4684 57.7511 38.5685C57.8008 38.6103 57.8505 38.652 57.8671 38.7187C57.8795 38.7604 57.9209 38.7896 57.9333 38.8272C57.9458 38.8731 57.9416 38.919 57.9251 38.9815C57.9085 39.0399 57.8836 39.1359 57.8339 39.1985C57.7884 39.2485 57.7387 39.2902 57.6931 39.3319C57.5978 39.3987 57.5025 39.5072 57.3866 39.4446C57.3451 39.4237 57.3037 39.4529 57.2623 39.4696C57.1463 39.5197 57.022 39.4946 56.9019 39.4696C56.8646 39.4613 56.8232 39.4404 56.7859 39.4571C56.6907 39.4988 56.5912 39.5072 56.4918 39.4988C56.4463 39.4946 56.4007 39.4988 56.351 39.4696C56.3054 39.4404 56.2474 39.4696 56.1977 39.4654C56.061 39.4613 55.9202 39.4738 55.7959 39.3945C55.6302 39.4321 55.4811 39.3069 55.3278 39.2819C55.2823 39.2652 55.2284 39.2318 55.1953 39.14C55.1166 38.9273 55.1083 38.9315 55.1829 38.8397C55.2077 38.8105 55.2284 38.7855 55.2491 38.7604C55.2823 38.7229 55.3237 38.6812 55.3776 38.677C55.4521 38.6687 55.5391 38.6103 55.6178 38.556C55.6841 38.5101 55.7421 38.4601 55.7959 38.4809C55.8166 38.4893 55.8415 38.4684 55.8663 38.4559C55.974 38.3975 56.0693 38.3683 56.1687 38.4017L56.1646 38.3975Z"
        fill="#6F3264"
      />
      <path
        d="M55.4232 32.795C55.506 32.7825 55.5972 32.8075 55.6841 32.6949C55.709 32.6657 55.7421 32.6782 55.767 32.6949C55.8457 32.7449 55.9285 32.7867 56.0155 32.7783C56.028 32.7783 56.0404 32.7908 56.0528 32.7783C56.1646 32.6865 56.2889 32.7908 56.4049 32.7783C56.4877 32.77 56.5747 32.8075 56.6493 32.7366C56.6907 32.6991 56.7487 32.7116 56.7984 32.7199C56.9517 32.7283 57.1215 32.8075 57.3038 32.8951C57.3618 32.9368 57.4156 32.9744 57.4405 33.037C57.4529 33.0745 57.5026 33.1079 57.515 33.1412C57.5275 33.1871 57.5233 33.233 57.5026 33.2956C57.4819 33.354 57.4529 33.4541 57.3949 33.5209C57.3452 33.5751 57.2872 33.6168 57.2292 33.6627C57.1215 33.7336 57.0138 33.8504 56.8771 33.7879C56.8316 33.767 56.7818 33.8004 56.7321 33.8212C56.5996 33.8796 56.4546 33.8546 56.3179 33.8379C56.2723 33.8296 56.2268 33.8129 56.1854 33.8296C56.0735 33.8755 55.9617 33.888 55.8457 33.8796C55.7918 33.8796 55.738 33.8796 55.6841 33.8546C55.6303 33.8296 55.5682 33.8546 55.506 33.8546C55.3486 33.8546 55.1912 33.8671 55.0379 33.792C54.8474 33.8337 54.6734 33.7086 54.4912 33.6836C54.4373 33.6669 54.3752 33.6335 54.3296 33.5417C54.2219 33.3248 54.2095 33.3331 54.2882 33.2414C54.3131 33.2122 54.3379 33.1871 54.3586 33.1621C54.3959 33.1204 54.4373 33.0828 54.4995 33.0787C54.5864 33.0703 54.6817 33.0078 54.7687 32.9535C54.8391 32.9076 54.9095 32.8576 54.9717 32.8743C54.9965 32.8826 55.0255 32.8618 55.0504 32.8492C55.1746 32.7867 55.2865 32.7575 55.4066 32.7908L55.4232 32.795Z"
        fill="#6F3264"
      />
      <path
        d="M57.515 28.2647C57.544 28.2522 57.5772 28.2772 57.6144 28.1646C57.6227 28.1354 57.6352 28.1479 57.6393 28.1646C57.66 28.2147 57.689 28.2564 57.718 28.2522C57.7221 28.2522 57.7263 28.2647 57.7304 28.2522C57.7677 28.1604 57.8091 28.2689 57.8505 28.2606C57.8795 28.2564 57.9168 28.2939 57.9334 28.2314C57.9417 28.1938 57.9624 28.2147 57.9831 28.223C58.0411 28.2397 58.1239 28.3273 58.2316 28.4191C58.2689 28.4608 58.3062 28.4983 58.3393 28.5567C58.3559 28.5943 58.3932 28.6235 58.4098 28.6527C58.4305 28.6944 58.4429 28.7361 58.4512 28.7987C58.4595 28.8529 58.4719 28.9489 58.4512 29.0073C58.4346 29.0573 58.4139 29.0991 58.389 29.1408C58.3435 29.2117 58.2979 29.3202 58.2068 29.2617C58.1778 29.2409 58.1529 29.2743 58.1281 29.2909C58.0577 29.3452 57.9707 29.3243 57.892 29.3035C57.8671 29.2951 57.8381 29.2784 57.8174 29.2951C57.7553 29.341 57.689 29.3494 57.6269 29.341C57.5979 29.3368 57.5647 29.341 57.5357 29.3118C57.5067 29.2826 57.4695 29.3118 57.4363 29.3076C57.3493 29.3035 57.2541 29.316 57.1795 29.2367C57.0594 29.2743 56.9848 29.1449 56.8895 29.1157C56.8605 29.0991 56.8357 29.0657 56.8357 28.9781C56.844 28.7737 56.8357 28.782 56.9144 28.6986C56.9351 28.6735 56.9558 28.6485 56.9765 28.6277C57.0097 28.5901 57.0428 28.5567 57.0718 28.5484C57.1174 28.5401 57.1795 28.4817 57.2334 28.4274C57.2789 28.3815 57.3162 28.3315 57.3369 28.3482C57.3452 28.3565 57.3618 28.3356 57.3742 28.3231C57.4363 28.2606 57.4777 28.2272 57.515 28.2564V28.2647Z"
        fill="#6F3264"
      />
      <path
        d="M58.269 23.3505C58.327 23.338 58.3891 23.363 58.4554 23.2546C58.472 23.2254 58.4968 23.2379 58.5134 23.2546C58.5673 23.3046 58.6211 23.3505 58.6791 23.3464C58.6874 23.3464 58.6957 23.3589 58.7039 23.3505C58.7868 23.2629 58.8655 23.3756 58.9442 23.3714C59.0022 23.3714 59.0602 23.4131 59.114 23.3547C59.143 23.3213 59.1845 23.3422 59.2176 23.3547C59.3211 23.3839 59.433 23.4798 59.5573 23.5967C59.5945 23.6467 59.6318 23.6926 59.6442 23.7593C59.6525 23.8011 59.6857 23.8386 59.6898 23.872C59.6981 23.922 59.6898 23.9638 59.6732 24.0222C59.6567 24.0764 59.6277 24.1723 59.5863 24.2266C59.549 24.2725 59.5075 24.3058 59.4661 24.3434C59.3874 24.4018 59.3087 24.4977 59.2176 24.4268C59.1845 24.4018 59.1513 24.4268 59.1182 24.4435C59.0229 24.4852 58.9276 24.4518 58.8324 24.4226C58.8034 24.4143 58.7702 24.3934 58.7412 24.4059C58.6625 24.4435 58.5838 24.4518 58.5051 24.4393C58.4678 24.4351 58.4306 24.4393 58.3974 24.4101C58.3601 24.3809 58.3187 24.4101 58.2773 24.4101C58.1696 24.4101 58.0578 24.4226 57.9583 24.3517C57.8258 24.3976 57.7098 24.2808 57.5855 24.2725C57.5483 24.2599 57.5068 24.2307 57.4778 24.1473C57.4157 23.9429 57.4033 23.9512 57.4654 23.8553C57.482 23.8261 57.5027 23.7969 57.5193 23.7719C57.5483 23.7301 57.5773 23.6884 57.6228 23.6759C57.6849 23.6592 57.7512 23.5925 57.8175 23.5341C57.8714 23.484 57.9211 23.4298 57.9625 23.4465C57.9791 23.4548 57.9998 23.434 58.0205 23.4173C58.1075 23.3505 58.1903 23.3213 58.269 23.3505Z"
        fill="#6F3264"
      />
      <path
        d="M73.1729 45.7938C73.2557 45.7938 73.3303 45.8397 73.4339 45.7437C73.4629 45.7187 73.4919 45.7396 73.5126 45.7604C73.5747 45.823 73.6451 45.8814 73.7238 45.8981C73.7363 45.8981 73.7445 45.9148 73.757 45.9106C73.8854 45.848 73.9682 45.9773 74.0801 45.994C74.1588 46.0065 74.225 46.065 74.312 46.0232C74.3617 45.9982 74.4073 46.0274 74.4529 46.0483C74.5896 46.1025 74.718 46.2276 74.8505 46.3737C74.8878 46.4321 74.9251 46.4863 74.9209 46.553C74.9209 46.5947 74.9541 46.6406 74.9499 46.674C74.9499 46.7241 74.9292 46.7616 74.8919 46.8158C74.8547 46.8659 74.8008 46.9493 74.7304 46.9911C74.6724 47.0244 74.6103 47.0495 74.5523 47.0745C74.4404 47.1079 74.3203 47.1871 74.225 47.0912C74.1919 47.0578 74.1422 47.0745 74.0966 47.0828C73.9682 47.1037 73.8564 47.0453 73.7445 46.9952C73.7073 46.9785 73.6741 46.9493 73.6327 46.9577C73.5291 46.9785 73.4297 46.966 73.3303 46.9368C73.2847 46.9243 73.2392 46.916 73.1978 46.8826C73.1563 46.845 73.0983 46.8617 73.0445 46.8492C72.9119 46.82 72.767 46.8117 72.651 46.7199C72.477 46.7366 72.3445 46.5906 72.1912 46.553C72.1456 46.5322 72.0959 46.4946 72.0669 46.4029C72.0006 46.1818 71.9882 46.1859 72.0669 46.1025C72.0918 46.0775 72.1166 46.0524 72.1415 46.0316C72.1788 45.994 72.2243 45.9607 72.2823 45.9607C72.3652 45.9607 72.4604 45.9106 72.5474 45.8647C72.622 45.8272 72.6924 45.7855 72.7504 45.8147C72.7711 45.8272 72.8001 45.8147 72.8291 45.8021C72.9534 45.7604 73.0652 45.7479 73.1688 45.8021L73.1729 45.7938Z"
        fill="#6F3264"
      />
      <path
        d="M73.206 42.4315C73.3261 42.4566 73.4463 42.5191 73.5995 42.4482C73.6409 42.4315 73.6865 42.4566 73.7196 42.4816C73.8191 42.5609 73.9268 42.6359 74.0469 42.6651C74.0635 42.6693 74.08 42.686 74.1007 42.6818C74.2913 42.6401 74.4321 42.7861 74.6019 42.8153C74.7221 42.8404 74.8339 42.9029 74.9623 42.8654C75.0327 42.8445 75.1114 42.8779 75.1819 42.8988C75.4014 42.9613 75.6127 43.0907 75.8363 43.2325C75.9026 43.2909 75.9648 43.3451 75.9689 43.4119C75.9689 43.4536 76.031 43.4995 76.031 43.5328C76.031 43.5829 76.0062 43.6204 75.9565 43.6789C75.9068 43.7289 75.8281 43.8165 75.7245 43.8582C75.6334 43.8916 75.5422 43.9166 75.4511 43.9417C75.2771 43.975 75.0949 44.0501 74.9333 43.9417C74.8753 43.9083 74.7966 43.9208 74.7262 43.9208C74.5274 43.9291 74.341 43.8582 74.1587 43.7873C74.1007 43.7623 74.0427 43.7289 73.9806 43.7331C73.8191 43.7331 73.6617 43.7039 73.5084 43.6496C73.438 43.6246 73.3634 43.6079 73.2971 43.562C73.2309 43.512 73.1397 43.512 73.061 43.487C72.8498 43.4202 72.6344 43.3702 72.4521 43.2325C72.1911 43.1908 71.984 42.9905 71.7521 42.8821C71.6816 42.8404 71.6071 42.782 71.5657 42.6651C71.4662 42.3982 71.4455 42.3982 71.5657 42.3398C71.6029 42.3231 71.6402 42.3064 71.6734 42.2939C71.7314 42.273 71.7935 42.2522 71.8805 42.2772C72.0006 42.3106 72.1373 42.2939 72.2698 42.2814C72.3775 42.2688 72.4811 42.2522 72.5681 42.298C72.6012 42.3147 72.6427 42.3106 72.6841 42.3106C72.8663 42.3106 73.0362 42.3314 73.1936 42.4148L73.206 42.4315Z"
        fill="#6F3264"
      />
      <path
        d="M76.3791 36.8582C76.4661 36.8623 76.5407 36.9124 76.6567 36.829C76.6857 36.8081 76.7188 36.829 76.7395 36.8498C76.8016 36.9208 76.8721 36.9833 76.9508 37.0084C76.9632 37.0084 76.9715 37.0292 76.9839 37.025C77.1206 36.975 77.1993 37.1168 77.3111 37.146C77.3898 37.1669 77.4561 37.2378 77.5514 37.2086C77.6052 37.1919 77.6508 37.2295 77.6922 37.2587C77.8289 37.3337 77.9408 37.4839 78.0443 37.6508C78.0733 37.7134 78.0982 37.7759 78.0816 37.8427C78.0733 37.8844 78.0982 37.9345 78.0899 37.9678C78.0775 38.0137 78.0526 38.0513 78.0029 38.0971C77.9573 38.1389 77.8869 38.2098 77.8165 38.239C77.7544 38.264 77.6922 38.2765 77.6301 38.289C77.5141 38.3057 77.3898 38.3641 77.3111 38.2598C77.2821 38.2223 77.2324 38.2348 77.1869 38.2348C77.0626 38.2348 76.959 38.1681 76.8555 38.1055C76.8223 38.0846 76.7933 38.0554 76.7519 38.0596C76.6484 38.0679 76.5531 38.0513 76.462 38.0137C76.4205 37.997 76.375 37.9887 76.3377 37.9511C76.3004 37.9136 76.2424 37.9261 76.1927 37.9094C76.0643 37.8761 75.9276 37.8635 75.8199 37.7676C75.6542 37.7843 75.5258 37.6383 75.3726 37.6049C75.327 37.5882 75.2773 37.5507 75.2483 37.4589C75.1779 37.242 75.1613 37.2461 75.2441 37.1585C75.269 37.1335 75.2938 37.1085 75.3146 37.0834C75.3518 37.0459 75.3974 37.0084 75.4554 37.0084C75.5382 37.0084 75.6335 36.9541 75.7246 36.9124C75.7992 36.8749 75.8738 36.8331 75.9318 36.8623C75.9525 36.8749 75.9856 36.8623 76.0146 36.8498C76.143 36.8081 76.2631 36.8039 76.3708 36.8623L76.3791 36.8582Z"
        fill="#6F3264"
      />
      <path
        d="M80.7075 33.0537C80.7903 33.0787 80.8649 33.1413 80.9933 33.0745C81.0264 33.0579 81.0554 33.0829 81.072 33.1079C81.13 33.1872 81.1921 33.2581 81.275 33.2915C81.2874 33.2956 81.2957 33.3123 81.3081 33.3123C81.4531 33.2748 81.5276 33.4208 81.6436 33.4583C81.7223 33.4875 81.7927 33.5543 81.8922 33.5251C81.946 33.5084 81.9957 33.5459 82.0413 33.571C82.1863 33.6461 82.3147 33.7879 82.4514 33.9422C82.4928 34.0048 82.5259 34.0632 82.5218 34.13C82.5218 34.1717 82.5549 34.2217 82.5508 34.2551C82.5466 34.3052 82.5218 34.3427 82.4762 34.3928C82.4307 34.4387 82.3644 34.5179 82.2815 34.5513C82.2111 34.5805 82.1407 34.593 82.0661 34.6097C81.9336 34.6306 81.7845 34.689 81.6809 34.5763C81.6436 34.5388 81.5856 34.5471 81.5318 34.543C81.3827 34.5388 81.2584 34.4595 81.1341 34.3844C81.0968 34.3594 81.0596 34.3219 81.0099 34.3219C80.8856 34.3177 80.7738 34.2801 80.6702 34.2259C80.6205 34.2009 80.5708 34.18 80.5294 34.1341C80.4879 34.0841 80.4175 34.0841 80.3637 34.059C80.2187 33.9923 80.0613 33.9381 79.9494 33.8046C79.7548 33.7629 79.6388 33.571 79.4772 33.4708C79.4317 33.4333 79.3861 33.3749 79.3819 33.2706C79.3695 33.0287 79.353 33.0287 79.4648 32.9744C79.4979 32.9577 79.5311 32.9452 79.5642 32.9327C79.6139 32.9119 79.6719 32.8952 79.7299 32.916C79.8169 32.9452 79.9287 32.9327 80.0364 32.916C80.1234 32.9035 80.2063 32.8868 80.2601 32.9327C80.2808 32.9494 80.314 32.9452 80.3471 32.9452C80.4879 32.9452 80.6081 32.9619 80.7075 33.0453V33.0537Z"
        fill="#6F3264"
      />
      <path
        d="M84.738 29.0907C84.8043 29.0907 84.8623 29.1324 84.9575 29.0448C84.9824 29.0197 85.0031 29.0406 85.0197 29.0614C85.0652 29.124 85.1108 29.1824 85.1729 29.2033C85.1812 29.2033 85.1895 29.22 85.1978 29.2158C85.3096 29.1574 85.3593 29.2909 85.4463 29.3159C85.5043 29.3326 85.5499 29.3994 85.6286 29.3618C85.67 29.3409 85.7031 29.3785 85.7363 29.3994C85.8357 29.4661 85.9103 29.6038 85.9807 29.7623C85.9972 29.8249 86.0138 29.8833 85.9972 29.9458C85.9848 29.9876 86.0014 30.0334 85.9931 30.0668C85.9807 30.1127 85.9558 30.1502 85.9144 30.2003C85.8771 30.242 85.815 30.3171 85.757 30.3505C85.7073 30.3755 85.6576 30.3922 85.6079 30.4131C85.5167 30.4381 85.4132 30.5007 85.3635 30.4005C85.3428 30.3672 85.3055 30.3797 85.2682 30.3839C85.1688 30.3964 85.0984 30.3338 85.0197 30.2796C84.9948 30.2629 84.9741 30.2337 84.941 30.242C84.8581 30.2587 84.7877 30.242 84.7173 30.217C84.6841 30.2045 84.651 30.1961 84.6262 30.1628C84.5972 30.1294 84.5516 30.1419 84.5143 30.1336C84.4149 30.1085 84.3113 30.1044 84.2326 30.0168C84.1001 30.0418 84.0131 29.9083 83.8971 29.8833C83.864 29.8666 83.8308 29.8332 83.8143 29.7456C83.777 29.537 83.7646 29.5412 83.835 29.4536C83.8557 29.4286 83.8764 29.3994 83.8971 29.3743C83.9303 29.3368 83.9675 29.2992 84.0131 29.2909C84.0752 29.2825 84.1581 29.2283 84.2326 29.1824C84.2948 29.1407 84.3528 29.0948 84.3983 29.1199C84.4149 29.1282 84.4398 29.1157 84.4646 29.1032C84.5682 29.0573 84.6634 29.0448 84.7421 29.0948L84.738 29.0907Z"
        fill="#6F3264"
      />
      <path
        d="M33.4235 88.5154C33.2744 88.6447 33.0921 88.7031 32.9306 88.8575C32.885 88.9034 32.8022 88.9034 32.7401 88.895C32.5454 88.87 32.3714 88.8199 32.1933 88.7866C32.1643 88.7824 32.1394 88.7615 32.1146 88.7615C31.837 88.7782 31.6299 88.5446 31.4435 88.3652C31.3027 88.2317 31.2406 88.0524 31.1121 87.9147C31.0417 87.8354 31.021 87.7103 31.0086 87.6018C30.9672 87.2723 31.1039 86.9344 31.3317 86.7216C31.4145 86.659 31.4932 86.6215 31.5554 86.6507C31.5926 86.6674 31.6589 86.6382 31.6879 86.6549C31.7293 86.6799 31.7542 86.7216 31.7832 86.7842C31.808 86.8426 31.866 86.9302 31.895 86.9969C31.9199 87.0595 31.9365 87.1137 31.9655 87.1638C32.0069 87.2639 32.1063 87.314 32.0566 87.4099C32.04 87.4475 32.0773 87.4725 32.1021 87.4892C32.1767 87.5434 32.185 87.6018 32.243 87.6727C32.2596 87.6936 32.2803 87.7228 32.3093 87.7228C32.3921 87.7103 32.4584 87.7145 32.5329 87.7228C32.5661 87.7228 32.5992 87.7228 32.6365 87.7395C32.6779 87.7562 32.7069 87.7186 32.7401 87.7019C32.827 87.6602 32.856 87.5935 32.9596 87.5434C32.9596 87.4224 33.0921 87.3223 33.0714 87.1888C33.0714 87.1471 33.088 87.1054 33.1501 87.0345C33.2827 86.8593 33.2496 86.8176 33.378 86.8342C33.407 86.8342 33.4484 86.8426 33.4898 86.8551C33.5478 86.8718 33.6058 86.901 33.6472 86.9886C33.6969 87.1054 33.7798 87.2305 33.8336 87.3766C33.8792 87.4892 33.9165 87.6185 33.8585 87.7395C33.8378 87.7812 33.8378 87.8396 33.8336 87.8897C33.8046 88.1274 33.6721 88.3736 33.4318 88.5112L33.4235 88.5154Z"
        fill="#6F3264"
      />
      <path
        d="M85.8482 53.0023C85.96 52.9815 86.0719 53.0065 86.192 52.8939C86.2251 52.8647 86.2707 52.8772 86.3038 52.8939C86.4115 52.9481 86.5151 52.994 86.6311 53.0023C86.6476 53.0023 86.6642 53.019 86.6808 53.0107C86.8423 52.9356 86.9873 53.0649 87.1406 53.0816C87.2483 53.0941 87.3477 53.1608 87.4678 53.1191C87.5341 53.0983 87.6004 53.1358 87.6625 53.1608C87.853 53.2318 88.0229 53.3861 88.1761 53.5613C88.2217 53.6281 88.2548 53.6906 88.2466 53.7574C88.2383 53.7991 88.2756 53.8492 88.2673 53.8867C88.2548 53.9326 88.2258 53.9701 88.1761 54.016C88.1264 54.0577 88.0477 54.1287 87.9649 54.1579C87.8903 54.1829 87.8199 54.1954 87.7495 54.2121C87.6169 54.2329 87.4844 54.2955 87.3767 54.1996C87.3394 54.1662 87.2814 54.1787 87.2317 54.1829C87.0909 54.1996 86.9625 54.1453 86.8299 54.0995C86.7885 54.0869 86.7429 54.0619 86.7015 54.0703C86.5855 54.0995 86.4737 54.0995 86.3618 54.0869C86.308 54.0828 86.2583 54.0828 86.2044 54.0536C86.1506 54.0285 86.0884 54.0536 86.0304 54.0536C85.873 54.0536 85.7198 54.0869 85.5624 54.0369C85.3843 54.112 85.1896 54.0244 85.0073 54.0577C84.9493 54.0577 84.883 54.0452 84.8043 53.9785C84.6262 53.8158 84.6055 53.8283 84.6511 53.7115C84.6676 53.6781 84.6801 53.6448 84.6925 53.6114C84.7173 53.5613 84.7463 53.5071 84.8168 53.4779C84.912 53.4403 84.999 53.3527 85.0902 53.2735C85.1606 53.2109 85.231 53.14 85.318 53.1442C85.3511 53.1442 85.3801 53.1233 85.4132 53.1024C85.5541 53.019 85.7032 52.9689 85.8606 52.994L85.8482 53.0023Z"
        fill="#6F3264"
      />
      <path
        d="M87.211 51.2419C87.3063 51.2336 87.4057 51.267 87.5134 51.1627C87.5424 51.1335 87.5797 51.1502 87.6087 51.171C87.6957 51.2294 87.7868 51.2795 87.8821 51.2878C87.8945 51.2878 87.9111 51.3045 87.9235 51.2962C88.0643 51.2211 88.1845 51.3462 88.317 51.3587C88.4123 51.3671 88.4993 51.4255 88.5987 51.3754C88.6525 51.3504 88.7147 51.3754 88.7685 51.3963C88.9384 51.4463 89.0958 51.5757 89.2532 51.7217C89.2987 51.7801 89.3401 51.8385 89.3401 51.9052C89.3401 51.9469 89.3774 51.9928 89.3774 52.0262C89.3774 52.0763 89.3526 52.1138 89.3112 52.168C89.2697 52.2181 89.2076 52.3015 89.133 52.3432C89.0668 52.3766 89.0005 52.4016 88.9342 52.4267C88.8099 52.4642 88.6815 52.5435 88.5738 52.4517C88.5324 52.4225 88.4786 52.4392 88.4288 52.4517C88.2922 52.4767 88.1637 52.4267 88.0312 52.385C87.9898 52.3724 87.9483 52.3474 87.9069 52.3558C87.7951 52.385 87.6832 52.3808 87.5755 52.3641C87.5258 52.3558 87.472 52.3516 87.4223 52.3224C87.3684 52.2932 87.3104 52.314 87.2524 52.3099C87.0992 52.2974 86.9459 52.3099 86.8009 52.2348C86.6187 52.2807 86.4447 52.1597 86.2666 52.1514C86.2127 52.143 86.1506 52.1138 86.0968 52.0262C85.9683 51.826 85.9518 51.8301 86.0222 51.73C86.0429 51.7008 86.0636 51.6716 86.0843 51.6424C86.1175 51.6007 86.1589 51.5548 86.221 51.5423C86.3121 51.5256 86.4074 51.4589 86.4986 51.4005C86.5731 51.3504 86.6435 51.2962 86.7181 51.3129C86.7429 51.3212 86.7761 51.3003 86.8051 51.2878C86.9418 51.2253 87.0702 51.196 87.2027 51.2378L87.211 51.2419Z"
        fill="#6F3264"
      />
    </g>
    <defs>
      <clipPath id="clip0_1019_6471">
        <rect
          width="63.24"
          height="90.52"
          fill="white"
          transform="translate(31.0002 17.36)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default GoatSticker
