import {
  ButtonGroup,
  ButtonRect,
} from 'shared/onboarding-buttons/onboarding-buttons'
import * as blUrls from 'lib/urls'
import Link from 'components/link'
import { PresentIcon, ShoppingBagIcon } from 'components/icons'
import { RETURN_WINDOW } from '../constants'
import { SectionHeading, StyledSubHeading } from '../../components/headings'

export default function ReturnsLandingPage() {
  return (
    <div className="container container-md text-center pbm">
      <div className="mbxl">
        <SectionHeading>
          <strong>Returns Center</strong>
        </SectionHeading>
        <div className="h4">
          <strong>How can we help?</strong>
        </div>
      </div>

      <ButtonGroup>
        <Link to={blUrls.returnGiftPath}>
          <ButtonRect className="">
            <PresentIcon className="block" />
            <div className="h4 text-body mtm mbn">I'm returning a gift</div>
          </ButtonRect>
        </Link>
        <Link to={blUrls.returnPurchasePath}>
          <ButtonRect className="">
            <ShoppingBagIcon className="block" />
            <div className="h4 text-body mtm mbn phs">
              I'm returning an item I purchased
            </div>
          </ButtonRect>
        </Link>
      </ButtonGroup>
      <div className="pvxl">
        <div className="mbxl">
          <StyledSubHeading className="h4 text-bold">
            What can I return?
          </StyledSubHeading>
          <p className="text-center-xs">
            {`Babylist accepts returns on items purchased directly from the Babylist Shop within ${RETURN_WINDOW} of purchase date. Returned items must be in the same condition received, unopened and in the original packaging. `}
            <Link url={blUrls.returnPolicyPath}>
              Check out our full return policy here.
            </Link>
          </p>
        </div>
        <div className="mbxl">
          <StyledSubHeading className="h4 text-bold">
            Will my gift giver know about my return?
          </StyledSubHeading>
          <p>
            Don't worry! Babylist does not notify gift givers about returns.
          </p>
        </div>
        <div className="mbxl">
          <StyledSubHeading className="h4 text-bold">
            When will I get my refund?
          </StyledSubHeading>
          <p>
            Refunds are issued within 5 business days after your return arrives
            at our warehouse.
          </p>
        </div>
      </div>
    </div>
  )
}
