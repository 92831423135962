import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { RECAPTCHA_V3_SITE_KEY } from 'shared/constants'
import css from './LoginPage.scss'
import { LoginPageProps } from './LoginPage.types'
import LoginForm from './components/LoginForm'

const LoginPage = ({
  email,
  nonce,
  passwordFormActive,
  popup,
  requestedUrl,
  ssoEmailVerificationRequired,
  ssoEmailVerificationProvider,
  ssoEnabled,
}: LoginPageProps) => (
  <>
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
      <LoginForm
        email={email}
        nonce={nonce}
        passwordFormActive={passwordFormActive}
        popup={popup}
        requestedUrl={requestedUrl}
        ssoEmailVerificationProvider={ssoEmailVerificationProvider}
        ssoEmailVerificationRequired={ssoEmailVerificationRequired}
        ssoEnabled={ssoEnabled}
      />
    </GoogleReCaptchaProvider>
    <div className={css.loginPageBackground} />
  </>
)

export default LoginPage
