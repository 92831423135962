import { InfoButton } from '../shared/Buttons'
import css from './SummarizedNav.scss'
import { DEFAULT_SUMMARIZED_NAV_LINKS } from './constants'
import { SummarizedNavProps } from './types'
import NavInfoTile from '../shared/NavInfoTile'
import { CategoryHeading } from '../shared/Headings'
import SectionText from '../shared/SectionText'

const SummarizedNav = ({
  navItems = DEFAULT_SUMMARIZED_NAV_LINKS,
}: SummarizedNavProps) => {
  const navItemElements = navItems.map((navItem) => (
    <NavInfoTile
      className={css.SummarizedNavItem}
      image={navItem.image}
      key={navItem.title}
      title={navItem.title}
    >
      <CategoryHeading className={css.SummarizedNavItemHeading}>
        {navItem.title}
      </CategoryHeading>

      {/* @ts-ignore */}
      <SectionText className={css.SummarizedNavItemSubtext}>
        {navItem.subtext}
      </SectionText>

      <div className={css.ButtonGroup}>
        <InfoButton empty pill className={css.Button} href={navItem.link}>
          {navItem.buttonText}
        </InfoButton>
      </div>
    </NavInfoTile>
  ))

  return (
    <section className={css.SummarizedNav}>
      <div className={css.SummarizedNavContent}>{navItemElements}</div>
    </section>
  )
}

export default SummarizedNav
