import classnames from 'classnames'
import * as React from 'react'
import css from './styles.scss'

export interface MultiItemSliderNavigationButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * An icon that indicates the slide direction.
   */
  icon: React.ElementType
}

export default function MultiItemSliderNavigationButton({
  className,
  icon,
  onClick,
  disabled,
  ...rest
}: MultiItemSliderNavigationButtonProps) {
  const ButtonIcon = icon

  return (
    <button
      className={classnames(
        css.navigationButton,
        { [css.disabled]: disabled },
        className
      )}
      onClick={onClick}
      type="button"
      disabled={disabled}
      {...rest}
    >
      <ButtonIcon />
    </button>
  )
}
