import { Component } from 'react'
import PropTypes from 'prop-types'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import get from 'lodash/get'
import values from 'lodash/values'

import { saveEmailPreferences, SET_USER } from './actions'
import registryReducers from 'registry/reducers'

import EmailPreferencesForm from 'registry/forms/email-preferences-form'
import { Alert } from 'components/alerts'
import Link from 'components/link'
import withReactQueryClientProvider from 'shared/HOC/withReactQueryClientProvider'

let middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(createLogger())
}

const CONFIRMATION_PROMPT =
  'We still ❤️ you. Are you sure you want to unsubscribe from all emails?'
const UNSUBSCRIBE_SUCCESS_MSG =
  "You've been unsubcribed from all Babylist emails."
const buildFlashMessage = (message, variant = 'success') => ({
  message,
  variant: variant || 'success',
})

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

class EmailPreferencesApp extends Component {
  static propTypes = {
    restrictedAccessToken: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      flash: props.initialMessage
        ? buildFlashMessage(props.initialMessage, props.initialMessageVariant)
        : null,
    }
    this.store = createStoreWithMiddleware(combineReducers(registryReducers))
    this.store.dispatch({
      type: SET_USER,
      user: props.user,
    })
  }

  handleUnsubscribe = () => {
    if (confirm(CONFIRMATION_PROMPT)) {
      this.handleSubmit(this.buildUnsubscribeData())
      this.setState({ flash: buildFlashMessage(UNSUBSCRIBE_SUCCESS_MSG) })
    }
  }

  handleSubmit = (data, dispatch = this.store.dispatch) => {
    this.setState({ flash: null })
    return dispatch(
      saveEmailPreferences(
        {
          ...data.user.emailPreferences,
          arrivalDate: data.user.family.arrivalDate,
          id: this.props.user.emailPreferences.id,
        },
        this.props.restrictedAccessToken
      )
    )
  }

  buildUnsubscribeData = () => {
    const unsubscribeData = Object.keys(
      this.props.user.emailPreferences
    ).reduce((obj, key) => {
      obj[key] = false
      return obj
    }, {})

    return {
      user: {
        emailPreferences: unsubscribeData,
        family: { arrivalDate: get(this.props.user.family, 'arrivalDate') },
      },
    }
  }

  render() {
    return (
      <Provider store={this.store}>
        <div className="pbxl">
          <p className="mbn">
            Email preferences for <strong>{this.props.user.email}</strong>
          </p>
          {this.renderInlineFlashMessage()}
          {this.renderUnsubscribeAll()}

          <EmailPreferencesForm _handleSubmit={this.handleSubmit} />
        </div>
      </Provider>
    )
  }

  renderUnsubscribeAll = () => {
    if (values(this.props.user.emailPreferences).some((p) => p)) {
      return (
        <p className="h6 mtn mbxl">
          <Link onClick={this.handleUnsubscribe}>Unsubscribe all</Link>
        </p>
      )
    }
  }

  renderInlineFlashMessage = () => {
    if (!this.state.flash) return

    let { message, variant } = this.state.flash
    const reset = () => {
      this.setState({ flash: null })
    }

    return (
      <Alert
        className="alert-flash-message"
        variant={variant}
        onDismiss={reset}
      >
        <div className="alert-container">
          <p className="alert-text-container h5 mvn text-left">{message}</p>
        </div>
      </Alert>
    )
  }
}

export default withReactQueryClientProvider(EmailPreferencesApp)
