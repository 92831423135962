import React from 'react'
import Image from 'components/image'
import css from './PromoBadge.styles.scss'
import classnames from 'classnames'

type Variant = 'primary' | 'neutral' | 'discount'

export type PromoBadgeProps =
  | {
      type: 'text'
      text: string
      variant: Variant
    }
  | {
      type: 'image'
      title: string
      imageUrl: string
    }

const PromoBadge: React.FC<PromoBadgeProps> = (props) => {
  if (props.type === 'text') {
    return (
      // TODO replace with Text Badge component
      <span
        data-testid="badge"
        className={classnames(
          css['PromoBadge__textBadge'],
          css[`PromoBadge__textBadge__${props.variant}`]
        )}
      >
        {props.text}
      </span>
    )
  } else if (props.type === 'image') {
    return (
      <div
        data-testid="badge"
        className={classnames(css['PromoBadge__imageBadge'])}
      >
        <Image src={props.imageUrl} alt={props.title} />
      </div>
    )
  }

  return null
}

export default PromoBadge
