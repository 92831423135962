import { findPath, helloBabyPath, newRegistryPath, storePath } from 'lib/urls'
import { SummarizedNavItem } from './types'

export const DEFAULT_SUMMARIZED_NAV_LINKS: Array<SummarizedNavItem> = [
  {
    buttonText: 'Get Started',
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691709803/QuickNav_Icon_CreateReg_V2_j2sn7j.png',
    link: newRegistryPath,
    subtext: 'Just a couple steps to start adding gifts.',
    title: 'Create a Registry',
  },
  {
    buttonText: 'Find a Registry',
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691709802/QuickNav_Icon_FindReg_v2_zhughw.png',
    link: findPath,
    subtext: 'Give the perfect gift to the new family.',
    title: 'Find a Babylist',
  },
  {
    buttonText: 'Start Shopping',
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691709802/QuickNav_Icon_Shop_V2_vnxtgb.png',
    link: storePath,
    subtext: 'Discover baby gear tested by our experts and real parents',
    title: 'Start Shopping',
  },
  {
    buttonText: 'Explore Guides',
    image:
      '//images.babylist.com/image/upload/f_auto,q_auto/v1691709802/QuickNav_Icon_Guides_v3_ytonma.png',
    link: helloBabyPath,
    subtext: 'Explore our expert-backed guides from pregnancy to parenting',
    title: 'Read Helpful Guides',
  },
]
