import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import checklist from './checklist-reducer'
import questions, * as fromQuestions from './questions-reducer'

const checklistReducer = combineReducers({
  checklist,
  questions,
  form: formReducer,
})

export const getCoregistrationAnswer = (state) =>
  fromQuestions.getCoregistrationAnswer(state.questions)

export default checklistReducer
