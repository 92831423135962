import { Bowed } from 'components/curves'
import { findPath, newRegistryPath } from 'lib/urls'
import { InfoButton, LightButton } from '../shared/Buttons'
import css from './HeroBanner.scss'

const HeroBanner = () => (
  <section>
    <div className={css.HeroContainer}>
      <div className={css.HeroContent}>
        <h1 data-testid="heroBannerHeading">
          The baby registry that grows with your family
        </h1>
        <div className={css.ButtonGroup}>
          <InfoButton pill className={css.Button} href={newRegistryPath}>
            Start a Babylist{' '}
            <span className="hidden-xs hidden-sm">Registry</span>
          </InfoButton>

          <LightButton empty pill className={css.Button} href={findPath}>
            Find a Babylist{' '}
            <span className="hidden-xs hidden-sm">Registry</span>
          </LightButton>
        </div>
      </div>
      <Bowed position="bottom" />
    </div>
  </section>
)

export default HeroBanner
