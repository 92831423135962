import { SecretInput } from 'components/forms'
import { InfoButton, LinkButton } from 'components/buttons'
import { forgotPasswordPath } from 'lib/urls'
import { track } from 'lib/analytics'
import css from './PasswordForm.scss'
import { LoginFormState } from '../LoginForm/LoginForm.types'

export interface PasswordFormProps {
  state: LoginFormState
  togglePasswordForm: () => void
  updateState: (newState: LoginFormState) => void
}

const PasswordForm = ({
  state,
  togglePasswordForm,
  updateState,
}: PasswordFormProps) => {
  const handleLoginClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLFormElement>
  ) => {
    if (state.password === '') {
      event.preventDefault()
      event.stopPropagation()

      updateState({ errors: { password: 'Please enter your password' } })
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({
      password: event.target.value.trim(),
      errors: { password: '' },
    })
  }

  const handlePressEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key !== 'Enter') return

    handleLoginClick(event)
  }

  const handleTogglePasswordForm = () => {
    track.logInStepProcessed({
      eventLocation: state.ssoEmailVerificationRequired
        ? track.EventLocation.LOG_IN_MINUS_VERIFY_EMAIL_PASSWORD
        : track.EventLocation.LOG_IN_MINUS_SUBMIT_EMAIL_PASSWORD,
      eventCta: 'Try Different Email',
      userAuthenticationMethod: track.UserAuthenticationMethod.EMAIL,
    })
    togglePasswordForm()
  }

  return (
    <div className={css.passwordFormContentContainer}>
      <div className={css.passwordFormContentHeaderContainer}>
        <h1 className={css.passwordFormContentHeaderText}>Welcome back!</h1>
        <div className={css.passwordFormEmailContainer}>
          <span className={css.passwordFormEmail}>{state.email}</span>
        </div>
      </div>
      <div className={css.passwordFormFieldContainer}>
        {state.ssoEmailVerificationRequired && (
          <p className="h7 help-block mbl">
            Enter your Babylist password to link your existing account. Going
            forward, you will be able to log in without a password.
          </p>
        )}
        <SecretInput
          autoComplete="current-password"
          error={state.errors?.password}
          label="Password"
          name="password"
          onBlur={handlePasswordChange}
          onChange={handlePasswordChange}
          onKeyDown={handlePressEnter}
        />
        <div
          className={`${css.passwordFormLoginButtonContainer} btn-group btn-group--stretch`}
        >
          <InfoButton pill type="submit" onClick={handleLoginClick}>
            Log In
          </InfoButton>
        </div>
      </div>
      <div>
        <div className="btn-group btn-group--stretch">
          <LinkButton
            className={css.passwordFormLinkButton}
            href={forgotPasswordPath}
          >
            Forgot your password?
          </LinkButton>
        </div>
        <div className="btn-group btn-group--stretch">
          <LinkButton
            className={css.passwordFormLinkButton}
            onClick={handleTogglePasswordForm}
          >
            Try another email
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default PasswordForm
