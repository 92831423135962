import { Component } from 'react'
import PropTypes from 'prop-types'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import OrderAddress from './order-address'
import { StyledSubHeading } from 'components/headings'

import { SET_ORDER } from '../actions'
import orderReducer from '../reducers'
import OrderItems from 'shared/orders/order-items'
import OrderStatus from './order-status'
import ModalManager from './modal-manager'
import CancelOrderButton from 'order/components/cancel-order-button'

let middlewares = [thunk]

if (process.env.NODE_ENV == 'development') {
  // middlewares.push(createLogger())
}

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

class OrderApp extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.store = createStoreWithMiddleware(orderReducer)
    this.store.dispatch({ type: SET_ORDER, order: props.order })
  }

  render() {
    const { cancelUntil, reference, orderItems, shippingAddress } =
      this.props.order

    return (
      <Provider store={this.store}>
        <div className="container container-sm pbm">
          <OrderStatus />
          <StyledSubHeading>{`Order #${reference}`}</StyledSubHeading>
          <OrderItems orderItems={orderItems} className="pbm" />
          <CancelOrderButton expiresAt={cancelUntil} reference={reference} />
          {shippingAddress ? <OrderAddress className="ptl pbxl" /> : null}
          <ModalManager />
        </div>
      </Provider>
    )
  }
}

export default OrderApp
