import { reducer as form } from 'redux-form'

import registry, * as fromRegistry from './registry-reducer'
import user, * as fromUser from './user-reducer'
import feed, * as fromFeed from './feed-reducer'
import userCredit from './user-credit-reducer'
import applicationConfiguration, * as fromApplicationConfiguration from './application-configuration-reducer'
import insertCardOrders, * as fromInsertCardOrders from './insert-card-orders-reducer'
import ui, * as fromUi from './ui-reducer'
import regItems, * as fromRegItems from './reg-items-reducer'
import userOrders, * as fromOrders from './orders-reducer'

export default {
  applicationConfiguration,
  feed,
  form,
  insertCardOrders,
  ui,
  registry,
  regItems,
  user,
  userCredit,
  userOrders,
}

export const getIsShippingAddressButtonVisible = (state) =>
  fromUi.getIsShippingAddressButtonVisible(state.ui)
export const getActiveModal = (state) => fromUi.getActiveModal(state.ui)
export const getActiveModalProps = (state) =>
  fromUi.getActiveModalProps(state.ui)
export const getIsRegistryDiscountView = (state) =>
  fromUi.getIsRegistryDiscountView(state.ui)
export const getIsAllCategoriesCollapsed = (state) =>
  fromUi.getIsAllCategoriesCollapsed(state.ui)
export const getIsContributorView = (state) =>
  fromUi.getIsContributorView(state.ui)
export const getIsMobile = (state) => fromUi.getIsMobile(state.ui)

export const getCashFunds = (state) =>
  fromApplicationConfiguration.getCashFunds(state.applicationConfiguration)
export const getCashFundPreferences = (state) =>
  fromRegistry.getCashFundPreferences(state.registry)
export const getCatalogCategoriesConfig = (state) =>
  fromApplicationConfiguration.getCatalogCategoriesConfig(
    state.applicationConfiguration
  )
export const getBookmarkletJS = (state) =>
  fromApplicationConfiguration.getBookmarkletJS(state.applicationConfiguration)
export const getShowExtensionInstall = (state) =>
  fromApplicationConfiguration.getShowExtensionInstall(
    state.applicationConfiguration
  )

export const getRegItemsCount = (state) =>
  fromRegItems.getRegItemsCount(state.regItems)
export const getCategorizedRegItems = (state, onlyRegistryDiscountEligible) =>
  fromRegItems.getCategorizedRegItems(
    state.regItems,
    onlyRegistryDiscountEligible
  )
export const getCategorizedRegItemsCount = (state) =>
  fromRegItems.getCategorizedRegItemsCount(state.regItems)
export const getFavoriteRegItemsCount = (state) =>
  fromRegItems.getFavoriteRegItemsCount(state.regItems)
export const getGroupGiftRegItemsCount = (state) =>
  fromRegItems.getGroupGiftRegItemsCount(state.regItems)
export const getIsFetchingCategorizedRegItems = (state) =>
  fromRegItems.getIsFetchingCategorizedRegItems(state.regItems)
export const getIsPartialSetOfRegItems = (state) =>
  fromRegItems.getIsPartialSetOfRegItems(state.regItems)
export const isPageReady = (state) => fromRegItems.isPageReady(state.regItems)
export const isGiftTrackerPageReady = (state) =>
  fromRegItems.isGiftTrackerPageReady(state.regItems)
export const getDistinctStores = (state) =>
  fromRegItems.getDistinctStores(state.regItems)
export const getPopulatedCategories = (state) =>
  fromRegItems.getPopulatedCategories(state.regItems)
export const getReservationsByCurrentVisitor = (state) =>
  fromRegItems.getReservationsByCurrentVisitor(state.regItems)
export const getReservedRegItemsCount = (state) =>
  fromRegItems.getReservedRegItemsCount(state.regItems)
export const getReservedRegItems = (state) =>
  fromRegItems.getReservedRegItems(state.regItems)
export const getReservationCount = (state) =>
  fromRegItems.getReservationCount(state.regItems)
export const getPurchasedReservationCount = (state) =>
  fromRegItems.getPurchasedReservationCount(state.regItems)
export const getReservedRegItemsByCurrentVisitor = (state) =>
  fromRegItems.getReservedRegItemsByCurrentVisitor(state.regItems)
export const getRegItem = (state, id) =>
  fromRegItems.getRegItem(state.regItems, id)
export const getRegItems = (state) => fromRegItems.getRegItems(state.regItems)
export const getRegItemsById = (state) =>
  fromRegItems.getRegItemsById(state.regItems)

export const getRegistryCategories = (state) =>
  fromRegItems.getRegistryCategories(state.regItems)
export const getRegistryCategoriesHash = (state) =>
  fromRegItems.getRegistryCategoriesHash(state.regItems)
export const getRegistryCategory = (state, categoryId) =>
  fromRegItems.getRegistryCategory(state.regItems, categoryId)
export const getFilters = (state) => fromRegItems.getFilters(state.regItems)
export const getIsOrganizing = (state) =>
  fromRegItems.getIsOrganizing(state.regItems)

export const getIsBookmarkletInstalled = (state) =>
  fromUser.getIsBookmarkletInstalled(state.user)
export const getIsBookmarkletUsed = (state) =>
  fromUser.getIsBookmarkletUsed(state.user)
export const getIsBabylistAppInstalled = (state) =>
  fromUser.getIsBabylistAppInstalled(state.user)
export const getIsBabylistExtensionInstalled = (state) =>
  fromUser.getIsBabylistExtensionInstalled(state.user)

export const getAllRegistries = (state) => fromUser.getAllRegistries(state.user)
export const getArrivalDate = (state) =>
  fromRegistry.getArrivalDate(state.registry)
export const getArrivalKnown = (state) =>
  fromRegistry.getArrivalKnown(state.registry)
export const getBabylistAppInstalled = (state) =>
  fromUser.getBabylistAppInstalled(state.user)
export const getBabylistExtensionInstalled = (state) =>
  fromUser.getBabylistExtensionInstalled(state.user)
export const getBabylistButtonAdded = (state) =>
  fromUser.getBabylistButtonAdded(state.user)
export const getCashFundsCount = (state) =>
  fromRegistry.getCashFundsCount(state.registry)
export const getCollaborators = (state) =>
  fromRegistry.getCollaborators(state.registry)
export const getCreatedAt = (state) => fromRegistry.getCreatedAt(state.registry)
export const getCurrentShower = (state) =>
  fromRegistry.getCurrentShower(state.registry)
export const getDevicePlatform = (state) =>
  fromUser.getDevicePlatform(state.user)
export const getEligibleForHelloBabyBox = (state) =>
  fromRegistry.getEligibleForHelloBabyBox(state.registry)
export const getEligibleForRegistryDiscount = (state) =>
  fromRegistry.getEligibleForRegistryDiscount(state.registry)
export const getExpectingChildren = (state) =>
  fromRegistry.getExpectingChildren(state.registry)
export const getExternalRegistriesCount = (state) =>
  fromRegistry.getExternalRegistriesCount(state.registry)
export const getFirstNames = (state) =>
  fromRegistry.getFirstNames(state.registry)
export const getGiftsPurchasedCount = (state) =>
  fromRegistry.getGiftsPurchasedCount(state.registry)
export const getHasPartner = (state) =>
  fromRegistry.getHasPartner(state.registry)
export const getHasVisitorPassword = (state) =>
  fromRegistry.getHasVisitorPassword(state.registry)
export const getHelloBabyBoxQualificationGoals = (state) =>
  fromRegistry.getHelloBabyBoxQualificationGoals(state.registry)
export const getIndexOfCurrentShower = (state) =>
  fromRegistry.getIndexOfCurrentShower(state.registry)
export const getInitialRegItemsCount = (state) =>
  fromRegistry.getRegItemsCount(state.registry)
export const getInitialReservationCount = (state) =>
  fromRegistry.getReservationCount(state.registry)
export const getInsertCardOrders = (state) =>
  fromInsertCardOrders.getInsertCardOrders(state.insertCardOrders)
export const getIsAdmin = (state) => fromUser.getIsAdmin(state.user)
export const getIsDotcomHbbAvailable = (state) =>
  fromRegistry.getIsDotcomHbbAvailable(state.registry)
export const getPartner = (state) => fromRegistry.getPartner(state.registry)
export const getPartnerCollaborator = (state) =>
  fromRegistry.getPartnerCollaborator(state.registry)
export const getPlanningStage = (state) =>
  fromRegistry.getPlanningStage(state.registry)
export const getReceivingHelloBabyBox = (state) =>
  fromRegistry.getReceivingHelloBabyBox(state.registry)
export const getRegItemsBabylistCount = (state) =>
  fromRegistry.getRegItemsBabylistCount(state.registry)
export const getRegItemsOtherCount = (state) =>
  fromRegistry.getRegItemsOtherCount(state.registry)
export const getRegistryDescription = (state) =>
  fromRegistry.getRegistryDescription(state.registry)
export const getRegistryDiscountStartsAt = (state) =>
  fromRegistry.getRegistryDiscountStartsAt(state.registry)
export const getRegistryDiscountEndsAt = (state) =>
  fromRegistry.getRegistryDiscountEndsAt(state.registry)
export const getRegistryId = (state) => fromRegistry.getId(state.registry)
export const getRegistryIsBabyRegistry = (state) =>
  fromRegistry.getRegistryIsBabyRegistry(state.registry)
export const getRegistryOwnerId = (state) =>
  fromRegistry.getOwnerId(state.registry)
export const getRegistryPhoto = (state) =>
  fromRegistry.getRegistryPhoto(state.registry)
export const getRegistrySetupProgress = (state) =>
  fromRegistry.getRegistrySetupProgress(state.registry)
export const getRegistrySetupGoal = (state, goalId) =>
  fromRegistry.getRegistrySetupGoal(state.registry, goalId)
export const getRegistrySetupGoalsToDisplay = (state) =>
  fromRegistry.getRegistrySetupGoalsToDisplay(state.registry)
export const getRegistrySetupGoalIsCompleted = (state, goalId) =>
  fromRegistry.getRegistrySetupGoalIsCompleted(state.registry, goalId)
export const getRegistryShareUrl = (state) =>
  fromRegistry.getRegistryShareUrl(state.registry)
export const getRegistryTitle = (state) =>
  fromRegistry.getRegistryTitle(state.registry)
export const getRegistryUIState = (state) =>
  fromRegistry.getRegistryUIState(state.registry)
export const getRegistryUrl = (state) =>
  fromRegistry.getRegistryUrl(state.registry)
export const getRegistryUrlSlug = (state) =>
  fromRegistry.getRegistryUrlSlug(state.registry)
export const getSearchLevel = (state) =>
  fromRegistry.getSearchLevel(state.registry)
export const getShippingAddress = (state) =>
  fromRegistry.getShippingAddress(state.registry)
export const getShippingConsolidation = (state) =>
  fromRegistry.getShippingConsolidation(state.registry)
export const getShowerInfo = (state) =>
  fromRegistry.getShowerInfo(state.registry)
export const getShowers = (state) => fromRegistry.getShowers(state.registry)
export const getUseShopifyHelloBabyBoxCheckout = (state) =>
  fromRegistry.getUseShopifyHelloBabyBoxCheckout(state.registry)
export const getUserId = (state) => fromUser.getId(state.user)
export const getUserEmail = (state) => fromUser.getEmail(state.user)
export const getUserFirstName = (state) => fromUser.getFirstName(state.user)
export const getUserFullName = (state) => fromUser.getFullName(state.user)
export const getUserCoregistrations = (state) =>
  fromUser.getCoregistrations(state.user)
export const getUserOwnsRegistry = (state) =>
  fromUser.getId(state.user) === fromRegistry.getOwnerId(state.registry)
export const getVisitorPassword = (state) =>
  fromRegistry.getVisitorPassword(state.registry)
export const getIsShippingAddressPrivate = (state) =>
  fromRegistry.getIsShippingAddressPrivate(state.registry)
export const getWantsInsertCards = (state) =>
  fromRegistry.getWantsInsertCards(state.registry)

export const getFeedItems = (state) => fromFeed.getFeedItems(state.feed)
export const getFeedSessionNumber = (state) =>
  fromFeed.getFeedSessionNumber(state.feed)
export const getIsFetchingFeed = (state) =>
  fromFeed.getIsFetchingFeed(state.feed)
export const getHasMoreFeedItems = (state) =>
  fromFeed.getHasMoreFeedItems(state.feed)
export const getIsFetchingCashFundsConfig = (state) =>
  fromApplicationConfiguration.getIsFetchingCashFundsConfig(
    state.applicationConfiguration
  )
export const getIsFetchingCatalogCategoriesConfig = (state) =>
  fromApplicationConfiguration.getIsFetchingCatalogCategoriesConfig(
    state.applicationConfiguration
  )

export const getOrders = (state) => fromOrders.getOrders(state.userOrders)
export const getIsFetchingUserOrders = (state) =>
  fromOrders.getIsFetchingUserOrders(state.userOrders)
export const getOrdersHasError = (state) =>
  fromOrders.getHasError(state.userOrders)
