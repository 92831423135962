import { useState } from 'react'
import PropTypes from 'prop-types'
import GiftGiverPreferencesForm, {
  UserRegistryShape,
} from 'registry/forms/gift-giver-preferences-form'
import { Alert } from 'components/alerts'
import { errorMessage } from 'lib/flash-message'
import { parseErrors } from 'lib/api-utils'
import { saveGiftGiverPreferences } from './api'

const buildFlashMessage = (message, variant = 'success') => ({
  message,
  variant,
})

const GiftGiverPreferencesApp = ({
  userRegistry,
  restrictedAccessToken,
  formOptions,
}) => {
  const [flashMessage, setFlashMessage] = useState()

  const handleSubmit = (data, complete) => {
    saveGiftGiverPreferences(
      {
        id: userRegistry.id,
        ...data,
      },
      restrictedAccessToken
    )
      .then((response) => {
        setFlashMessage(
          buildFlashMessage('Your preferences have been updated!')
        )
        complete(null)
      })
      .catch((response) => {
        errorMessage(parseErrors(response)._error)
        complete(response.error.fields)
      })
  }

  const renderInlineFlashMessage = (flashMessage) => {
    if (!flashMessage) return

    const { message, variant } = flashMessage
    const reset = () => setFlashMessage(null)

    return (
      <Alert
        className="alert-flash-message"
        variant={variant}
        onDismiss={reset}
      >
        <div className="alert-container">
          <p className="alert-text-container h5 mvn text-left">{message}</p>
        </div>
      </Alert>
    )
  }

  return (
    <div className="pbxl">
      {renderInlineFlashMessage(flashMessage)}

      <GiftGiverPreferencesForm
        handleSubmit={handleSubmit}
        userRegistry={userRegistry}
        formOptions={formOptions}
      />
    </div>
  )
}

GiftGiverPreferencesApp.propTypes = {
  userRegistry: PropTypes.shape(UserRegistryShape).isRequired,
  formOptions: PropTypes.object.isRequired,
  restrictedAccessToken: PropTypes.string.isRequired,
}

export default GiftGiverPreferencesApp
