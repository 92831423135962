/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import classNames from 'classnames'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'
import { partition } from 'lodash'
import Link from 'components/link'
import { CategoryIcon } from 'registry/components/CategoryIcon'
import styles from './RegItemCategory.styles.scss'
import RegItemCard, { regItemProps } from '../reg-item-card'

export const categoryProps = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  regItems: PropTypes.arrayOf(PropTypes.shape(regItemProps)),
  isPrivate: PropTypes.bool,
  description: PropTypes.string,
}

export class RegItemCategory extends Component {
  constructor(props) {
    super(props)

    this.state = { isCollapsed: false }
  }

  componentDidUpdate(prevProps) {
    const { isAllCategoriesCollapsed } = this.props
    if (prevProps.isAllCategoriesCollapsed !== isAllCategoriesCollapsed) {
      this.setState({ isCollapsed: isAllCategoriesCollapsed })
    }
  }

  toggleCollapse = () => {
    const { isCollapsed } = this.state
    this.setState({ isCollapsed: !isCollapsed })
  }

  /**
   * Renders a dynamic message depending on the reg item reservation state.
   * #BL-5594
   */
  // eslint-disable-next-line complexity
  renderGiftStatusContent = (regItem) => {
    const {
      quantity,
      quantityNeeded,
      quantityPurchased,
      isBonus,
      isCashFund,
      isCrowdfund,
      isExternalRegistry,
      isReserved,
    } = regItem
    /**
     * Exit early (no banner) if no one has reserved.
     * We can technically be in a non-reserved state if multiple items
     * available so `isReserved` won't work here.
     */
    if (quantity === quantityNeeded) return null

    if (isExternalRegistry) return null

    const hasPurchase = quantityPurchased > 0
    const hasMultiple = quantity > 1

    let content

    if (isCashFund || isCrowdfund) {
      content = hasPurchase
        ? `${quantityPurchased} ${pluralize('Contribution', quantityPurchased)}`
        : null
    } else if (hasMultiple && hasPurchase) {
      content = `${quantityPurchased} of ${quantity} Purchased`
    } else if (hasMultiple && !hasPurchase) {
      content = `${quantity - quantityNeeded} of ${quantity} Reserved`
    } else if (isBonus && hasPurchase) {
      content = `Bonus Item Purchased`
    } else if (hasPurchase) {
      content = (
        <>
          Marked as Purchased
          <i className="fa fa-check pull-right" style={{ marginTop: '2px' }} />
        </>
      )
    } else if (isReserved) {
      content = `On Hold`
    } else {
      content = null
    }

    return content
  }

  renderRegItem = (regItem) => {
    const {
      reservationsByCurrentVisitor,
      isContributorView,
      isRegistryDiscountView,
    } = this.props
    const { isMobile } = this.context
    const regItemCardProps = {
      isContributorView,
      regItem,
      reservationByCurrentVisitor: reservationsByCurrentVisitor[regItem.id],
      isRegistryDiscountView,
      giftStatusContent:
        isContributorView && this.renderGiftStatusContent(regItem),
    }

    return (
      <li className={`reg-item ${isMobile ? 'mvl' : ''}`} key={regItem.id}>
        <RegItemCard {...regItemCardProps} />
      </li>
    )
  }

  renderGuestViewRegItems = (regItems) => {
    const { showAllReserved } = this.state
    const [availableRegItems, reservedRegItems] = partition(
      regItems,
      (ri) =>
        (!ri.isGroupGift && !ri.isReserved) ||
        (ri.isGroupGift && !ri.isGoalComplete && !ri.isReserved)
    )

    const maxVisibleRegReservedItems = 3

    const displayAvailableRegItems = availableRegItems.map(this.renderRegItem)
    const displayReservedRegItems = showAllReserved
      ? reservedRegItems.map(this.renderRegItem)
      : reservedRegItems
          .slice(0, maxVisibleRegReservedItems)
          .map(this.renderRegItem)

    return (
      <>
        {displayAvailableRegItems}
        {displayReservedRegItems}
        {reservedRegItems.length > maxVisibleRegReservedItems ? (
          <Link
            className="text-center mvm block h6 link-underline"
            onClick={() => this.setState({ showAllReserved: !showAllReserved })}
          >
            {!showAllReserved
              ? `+ ${reservedRegItems.length - maxVisibleRegReservedItems} more`
              : `View less`}
          </Link>
        ) : null}
      </>
    )
  }

  render() {
    const {
      category,
      category: { title, regItems, isPrivate, description },
      isContributorView,
    } = this.props
    const { isCollapsed } = this.state
    const { isMobile } = this.context

    const sortedRegItems = category.regItems.sort(
      (r1, r2) => r2.position - r1.position
    ) // RegItems are sorted by position desc

    return (
      <div className={classNames(styles.RegItemCategory, 'category')}>
        <hr className="mbl" />
        <Link
          className={classNames(
            styles.RegItemCategory__link,
            'link-lowlight h5'
          )}
          onClick={this.toggleCollapse}
        >
          <div className={styles.RegItemCategory__container}>
            <CategoryIcon
              categoryId={category.id}
              className={styles.RegItemCategory__icon}
            />
            <CategoryHeader
              isContributorView={isContributorView}
              isPrivate={isPrivate}
              regItemCount={regItems.length}
            >
              {title}
            </CategoryHeader>
          </div>
          <span className="pull-right pts">
            <i
              className={classNames(
                { 'rotate-90-neg': isCollapsed },
                'fa fa-chevron-down h7'
              )}
            />
          </span>
        </Link>
        {isContributorView && description && (
          <div className="h7 mbl">
            {description}
            {isPrivate && <span> (visible only to you)</span>}
          </div>
        )}

        <Collapse in={!isCollapsed}>
          <div className="reg-category">
            <ul className={isMobile ? 'list-unstyled' : 'list-bordered'}>
              {isContributorView
                ? sortedRegItems.map(this.renderRegItem)
                : this.renderGuestViewRegItems(sortedRegItems)}
            </ul>
          </div>
        </Collapse>
      </div>
    )
  }
}

RegItemCategory.propTypes = {
  isContributorView: PropTypes.bool,
  isRegistryDiscountView: PropTypes.bool,
  category: PropTypes.shape(categoryProps).isRequired,
  reservationsByCurrentVisitor: PropTypes.object,
  isAllCategoriesCollapsed: PropTypes.bool,
}

RegItemCategory.defaultProps = {
  isContributorView: false,
  isRegistryDiscountView: false,
  reservationsByCurrentVisitor: {},
  isAllCategoriesCollapsed: false,
}

RegItemCategory.contextTypes = {
  isMobile: PropTypes.bool,
}

export default RegItemCategory

const CategoryHeader = ({
  children,
  isPrivate,
  isContributorView,
  regItemCount,
  addOn,
}) => (
  <span>
    <h3 className="text-category-underline h4 text-body">
      {isContributorView && !!isPrivate && (
        <i className="fa fa-lock prs mrs text-muted" />
      )}
      {children}
    </h3>
    {addOn}
    {isContributorView && regItemCount ? (
      <span className="text-body h6 text-muted">
        {' '}
        — <span className="category-item-count">{regItemCount}</span>
      </span>
    ) : null}
  </span>
)

CategoryHeader.propTypes = {
  addOn: PropTypes.node,
  children: PropTypes.node,
  isContributorView: PropTypes.bool,
  isPrivate: PropTypes.bool,
  regItemCount: PropTypes.number,
}

CategoryHeader.defaultProps = {
  addOn: null,
  children: null,
  isContributorView: false,
  isPrivate: false,
  regItemCount: null,
}

export { CategoryHeader }
