import cn from 'classnames'
import PropTypes from 'prop-types'

import { BorderBox } from 'components/border-box'
import Card, { HalfRoundCard } from 'components/card'
import Link from 'components/link'
import { QuickAddButton } from 'components/card/product-preview-card/quick-add-button'

import css from './ui.scss'

const ChildrenPropType = {
  children: PropTypes.node.isRequired,
}

export const Heading = ({ children }) => {
  return <h1 className={cn(css.heading, 'mbl')}>{children}</h1>
}

Heading.propTypes = ChildrenPropType

export const Subheading = ({ children }) => {
  return <p className={cn(css.subHeading, 'mbxl')}>{children}</p>
}

Subheading.propTypes = ChildrenPropType

export const ResultProTipBox = ({ children }) => {
  return (
    <BorderBox p={['tl', 'hxl', 'bxl']}>
      <div className={cn(css.proTipBox, 'mbl')}>PRO TIP:</div>
      {children}
    </BorderBox>
  )
}

ResultProTipBox.propTypes = ChildrenPropType

export const ProductCard = ({ href, mediaComponent, highlights }) => {
  return (
    <Link url={href} style={{ height: '100%' }}>
      <HalfRoundCard
        layout="vertical"
        style={{ height: '100%', justifyContent: 'space-between' }}
      >
        <div>
          <Card.Media>{mediaComponent}</Card.Media>
          <Card.Content>
            <div className="text-left text-body">
              <ul>
                {highlights.map((highlight) => {
                  return <li key={highlight}>{highlight}</li>
                })}
              </ul>
            </div>
          </Card.Content>
        </div>
        <div className="pbl prl" style={{ textAlign: 'right' }}>
          <QuickAddButton />
        </div>
      </HalfRoundCard>
    </Link>
  )
}

ProductCard.propTypes = {
  href: PropTypes.string.isRequired,
  mediaComponent: PropTypes.node.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
}
