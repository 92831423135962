import Link from 'components/link'
import css from './SocialLinks.scss'
import TikTok from '../../svg/TikTok.svg'
import Instagram from '../../svg/Instagram.svg'
import Pinterest from '../../svg/Pinterest.svg'
import YouTube from '../../svg/YouTube.svg'

const SocialLinks = () => {
  return (
    <div className={css.SocialLinks}>
      <p>Follow us</p>
      <div className={css.SocialLinks__icons}>
        <Link url="https://www.tiktok.com/@babylist">
          <TikTok className={css.SocialLinks__icon} />
        </Link>
        <Link url="https://www.instagram.com/babylist">
          <Instagram className={css.SocialLinks__icon} />
        </Link>
        <Link url="https://www.pinterest.com/babylist">
          <Pinterest className={css.SocialLinks__icon} />
        </Link>
        <Link url="https://www.youtube.com/@Babylist-Videos">
          <YouTube className={css.SocialLinks__icon} />
        </Link>
      </div>
    </div>
  )
}

export default SocialLinks
