import PropTypes from 'prop-types'
import { DefaultButton, InfoButton } from 'components/buttons'
import Image from 'components/image'

const RemoveBLCartItem = ({ cartItem, onHide, onRemove }) => (
  <div>
    <div>
      <Image
        className="center-block img-responsive"
        role="presentation"
        src={cartItem.imageUrl}
        style={{ maxHeight: 250, maxWidth: 250 }}
      />
      <div className="h6 mbn text-center">{cartItem.name}</div>
    </div>
    <div className="mtxl text-center">
      <DefaultButton empty className="mrm" onClick={() => onHide(null)}>
        Cancel
      </DefaultButton>{' '}
      <InfoButton onClick={onRemove}>Remove</InfoButton>
    </div>
  </div>
)

RemoveBLCartItem.propTypes = {
  cartItem: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default RemoveBLCartItem
