import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'components/forms'

import FormHeader from './form-header'
import { getQuestionText, question1Answers } from './questions.js'

export default class Step2 extends Component {
  static propTypes = {
    question1Answer: PropTypes.string,
    questionIds: PropTypes.array,
    onChange: PropTypes.func,
  }

  componentDidMount() {
    console.assert(
      this.props.questionIds[0] == 2,
      'first question id passed to this component was not 2!!'
    )
  }

  handleChange = (event) => {
    this.props.onChange(this.props.questionIds[0], event.target.value)
  }

  render() {
    let questionText
    if (this.props.question1Answer === question1Answers[0]) {
      questionText = 'Why do you love it?'
    } else if (this.props.question1Answer === question1Answers[1]) {
      questionText = 'Why do you like it (but not love it)?'
    } else if (this.props.question1Answer === question1Answers[2]) {
      questionText = "What don't you like about it?"
    } else {
      questionText = getQuestionText(2)
    }

    return (
      <div>
        <FormHeader questionText={questionText} />
        <Input
          aria-label={questionText}
          onChange={this.handleChange}
          type="textarea"
        />
      </div>
    )
  }
}
