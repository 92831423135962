import { Bowed } from 'components/curves'
import Fade from 'react-reveal/Fade'
import { InfoButton } from '../shared/Buttons'
import css from './HealthSection.scss'
import { CategoryHeading, SectionHeading } from '../shared/Headings'
import SectionText from '../shared/SectionText'
import NavInfoTile from '../shared/NavInfoTile'
import { HealthSectionProps } from './types'
import { DEFAULT_BL_HEALTH_NAV_ITEMS } from './constants'

const HealthSection = ({
  navItems = DEFAULT_BL_HEALTH_NAV_ITEMS,
}: HealthSectionProps) => {
  const navInfoTiles = navItems.map((navInfo) => (
    <NavInfoTile
      className={css.HealthNavItem}
      fade="top"
      image={navInfo.image}
      key={navInfo.title}
      title={navInfo.title}
    >
      <CategoryHeading className={css.HealthNavItemTitle}>
        {navInfo.title}
      </CategoryHeading>

      {/* @ts-ignore */}
      <SectionText className={css.HealthNavItemSubtext}>
        {navInfo.subtext}
      </SectionText>

      <div className={css.ButtonGroup}>
        <InfoButton
          pill
          className={css.Button}
          href={navInfo.link}
          newWindow={!!navInfo.newWindow}
        >
          {navInfo.buttonText}
        </InfoButton>
      </div>
    </NavInfoTile>
  ))

  return (
    <section>
      <Bowed inverted />
      <div className={css.HealthSectionContainer}>
        <div className={css.HealthSectionContent}>
          <Fade bottom>
            <SectionHeading
              className={css.HealthSectionHeading}
              data-testid="healthSectionHeading"
            >
              We've Got You Covered
            </SectionHeading>

            <SectionText
              className={css.HealthSectionText}
              data-testid="healthSectionText"
            >
              You deserve the best care — health & wellness from hoping and
              dreaming through the early years of parenting.
            </SectionText>
          </Fade>

          <div className={css.HealthNavContainer}>{navInfoTiles}</div>
        </div>
      </div>
    </section>
  )
}

export default HealthSection
