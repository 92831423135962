import classNames from 'classnames'
import css from '../Headings.scss'
import { HeadingProps } from '../types'

const SectionHeading = ({ children, className, ...rest }: HeadingProps) => (
  <h2 className={classNames(css.BaseHeading, className)} {...rest}>
    {children}
  </h2>
)

export default SectionHeading
