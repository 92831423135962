import { useEffect, useState } from 'react'
import * as React from 'react'
import Link from 'components/link'
import Image from 'components/image'
import { LinkButton } from 'components/buttons'
import { track, useTracking } from 'lib/analytics'
import { RegistryBannerVariant } from 'baby-ui/lib/RegistryBannerVariant'
import { RegistryBanner } from 'baby-ui/compounds/RegistryBanner/RegistryBanner'
import { BREAST_PUMP_BANNER_NAME } from 'baby-ui/compounds/RegistryBanner/RegistryBanner.constants'
import { Category } from '../../types'
import { useRegistryChecklistCategoryChecked } from '../../api/hooks'
import css from './styles.scss'

interface ChecklistCategoryProps extends Category {
  registryId: number
}

export const ChecklistCategory = (props: ChecklistCategoryProps) => {
  const {
    checklistType,
    id,
    isComplete,
    imageUrl,
    isEssential,
    name,
    registryId,
    storeUrl,
    banner,
  } = props
  const tracker = useTracking()
  const [checked, setChecked] = useState(isComplete)
  const { mutate: updateChecked } = useRegistryChecklistCategoryChecked(
    registryId,
    id
  )
  const isBreastPumpBanner = banner === BREAST_PUMP_BANNER_NAME

  useEffect(() => {
    setChecked(isComplete)
  }, [isComplete])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    // Only track if the user is logged in
    if (registryId) {
      tracker.trackEvent({
        checklistType,
        checklistCategoryId: id,
        checklistCategoryName: name,
        event: track.checklistCategoryClicked,
        registryId,
      })
    }

    const url = new URL(storeUrl)
    url.searchParams.set('ref', track.EventLocation.CHECKLIST.toLowerCase())
    window.location.href = url.toString()
  }

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (registryId) {
      tracker.trackEvent({
        checklistType,
        checklistCategoryId: id,
        checklistCategoryName: name,
        event: track.checklistCheckboxClicked,
        eventType: event.target.checked
          ? track.EventType.CHECK
          : track.EventType.UNCHECK,
        registryId,
      })
    }

    setChecked(event.target.checked)
    updateChecked(event.target.checked)
  }

  const fireBreastPumpBannerViewedEvent = () => {
    tracker.trackEvent({
      event: track.healthBannerViewed,
      eventCta: 'Check Your Eligibility',
      eventLocation: track.EventLocation.CHECKLIST_CATEGORY,
      eventType: track.EventType.NONE,
      registryId,
    })
  }

  const fireBreastPumpBannerClickedEvent = () => {
    tracker.trackEvent({
      event: track.healthBannerClicked,
      eventCta: 'Check Your Eligibility',
      eventLocation: track.EventLocation.CHECKLIST_CATEGORY,
      eventType: track.EventType.NONE,
      registryId,
    })
  }

  return (
    <li className={css.root}>
      <div className={css.contentWrapper}>
        <div className={css.checkboxWrapper}>
          <input
            checked={checked}
            className={css.checkbox}
            id={`checkbox_${id}`}
            type="checkbox"
            onChange={handleCheckboxClick}
          />
          <label htmlFor={`checkbox_${id}`} />
        </div>
        <LinkButton className="man pan" onClick={handleClick}>
          <Image
            alt={name}
            className={css.thumbnail}
            height={80}
            src={imageUrl}
            width={80}
          />
        </LinkButton>
        <div className={css.info}>
          <Link url={storeUrl} onClick={handleClick}>
            <h3 className={css.name}>{name}</h3>
          </Link>
          {isEssential && (
            <div className="xsmall flex align-center text-brand-info">
              <Image
                className="mrs"
                height={12}
                lazyLoad={false}
                role="presentation"
                src="https://images.babylist.com/image/upload/v1675979982/icn_essential_spvpp1.svg"
                width={12}
              />{' '}
              Essential
            </div>
          )}
        </div>
        <div>
          <LinkButton
            aria-hidden
            className="fa fa-chevron-right text-brand-info pll prm"
            onClick={handleClick}
          />
        </div>
      </div>
      <RegistryBanner
        variant={
          isBreastPumpBanner
            ? RegistryBannerVariant.ChecklistBreastPumpBanner
            : null
        }
        onClick={fireBreastPumpBannerClickedEvent}
        onView={fireBreastPumpBannerViewedEvent}
      />
    </li>
  )
}
