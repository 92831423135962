import Image from '../../../../components/image'
import Frame from '../../components/Frame'
import SectionHeader from '../../components/SectionHeader'
import { mobileAppDownloadPath } from '../../../../lib/urls'

export default function FromAnyStoreSection() {
  return (
    <Frame>
      <SectionHeader
        subHeading="Add any item from any store"
        className="mvl mhxl text-center"
      >
        One Registry for All Stores
      </SectionHeader>

      <Image
        alt="Crib"
        className="no-pointer-events"
        width="100%"
        src="//images.babylist.com/image/upload/v1644360627/any-item-from-any-store_ek0hbo.jpg"
        lazyLoad={false}
      />

      <a href={mobileAppDownloadPath()} className="mtl btn-pill btn btn-info">
        Try it Now
      </a>
    </Frame>
  )
}
