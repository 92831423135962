import css from '../styles.scss'

export const MobileBackGroundCircle = () => (
  <>
    <svg
      className={css.descBackgroundCircle}
      fill="none"
      height="197"
      viewBox="0 0 132 197"
      width="132"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5001 197C87.9001 197 132 152.9 132 98.5C132 44.1 87.9001 0 33.5001 0C-20.9 0 -65 44.1 -65 98.5C-65 152.9 -20.9 197 33.5001 197Z"
        fill="#DBCCD8"
        fillOpacity="0.2"
      />
    </svg>
  </>
)
