import { apiV3RestrictedGiftGiverPreferencesPath } from 'lib/urls'
import fetch from 'lib/fetch'

export const saveGiftGiverPreferences = (data, restrictedAccessToken) => {
  return fetch(`${apiV3RestrictedGiftGiverPreferencesPath}/${data.id}`, {
    body: JSON.stringify(data),
    headers: { token: restrictedAccessToken },
    method: 'PUT',
  })
}
