/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import classnames from 'classnames'
import { StyledSubHeading } from 'components/headings'
import debounce from 'lodash/debounce'
import { ChevronLeftIcon, ChevronRightIcon } from 'components/icons'
// @ts-ignore
import { bowserFlags } from 'lib/bowser'
import css from './styles.scss'
import MultiItemSliderNavigationButton from './MultiItemSliderNavigationButton'

export interface MultiItemSliderTrackingProps {
  /**
   * Called when an item is clicked.
   * @param index The index of the item that was clicked on.
   */
  onItemClicked: (index: number) => void
  /**
   * Called when items are in view.
   * @param indexes An index array of the items that were viewed.
   */
  onItemsViewed: (indexes: number[]) => void
}

export interface MultiItemSliderProps
  extends Partial<MultiItemSliderTrackingProps> {
  columnClassName?: string
  children: React.ReactNode
  /**
   * If provided it will display a title above the slider.
   */
  title?: string
}

export default function MultiItemSlider({
  columnClassName = 'col-sm-3 col-xs-6',
  children,
  onItemClicked,
  onItemsViewed,
  title,
  ...rest
}: MultiItemSliderProps) {
  const [itemMetadata, setItemMetadata] = React.useState({
    isPreviousButtonDisabled: true,
    isNextButtonDisabled: false,
  })
  const [itemWidth, setItemWidth] = React.useState(0)
  const childrenArray = React.Children.toArray(children)
  const itemRefs = React.useRef<HTMLLIElement[] | null[]>([])
  const sliderRef = React.useRef<HTMLUListElement>(null)
  const shouldSimplifyUI =
    childrenArray.length <=
      MultiItemSlider.Constants.DESKTOP_MIN_ITEMS_TO_DISPLAY &&
    !bowserFlags.mobile
  const debouncedHandleViewedTrackingEvent = React.useCallback(
    debounce(
      handleViewedTrackingEvent,
      MultiItemSlider.Constants.TRACKING_DEBOUNCE_PERIOD
    ),
    []
  )
  const debouncedHandleViewedTrackingEventOnPageScroll = React.useCallback(
    debounce(() => {
      if (isElementInViewport(sliderRef.current)) {
        handleViewedTrackingEvent()
      }
    }, MultiItemSlider.Constants.TRACKING_DEBOUNCE_PERIOD),
    []
  )

  React.useEffect(() => {
    sliderRef.current?.addEventListener('scroll', onSliderScroll)
    window.addEventListener('scroll', onPageScroll)
    setItemWidth(itemRefs.current[0]?.clientWidth as number)

    return () => {
      sliderRef.current?.removeEventListener('scroll', onSliderScroll)
      window.removeEventListener('scroll', onPageScroll)
    }
  }, [])

  function goToNextItem() {
    sliderRef.current?.scrollBy({ left: itemWidth, behavior: 'smooth' })
  }

  function goToPreviousItem() {
    sliderRef.current?.scrollBy({ left: -itemWidth, behavior: 'smooth' })
  }

  function onSliderScroll() {
    handleNavigationButtonsDisabledState()
    debouncedHandleViewedTrackingEvent()
  }

  function onPageScroll() {
    debouncedHandleViewedTrackingEventOnPageScroll()
  }

  function handleClickedTrackingEvent(index: number) {
    onItemClicked?.(index)
  }

  function handleViewedTrackingEvent() {
    if (onItemsViewed) {
      const itemsInViewIndexes = indexOfItemsInView(
        itemRefs.current as HTMLLIElement[]
      )
      onItemsViewed(itemsInViewIndexes)
    }
  }

  function isElementInViewport(elRef: HTMLElement | null) {
    if (elRef) {
      const bounding = getRectFrom(elRef) as DOMRect

      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    return false
  }

  function indexOfItemsInView(elRefs: HTMLLIElement[]) {
    const indexes: number[] = []

    elRefs.forEach((item, i) => {
      if (isSliderItemInView(item)) {
        indexes.push(i)
      }
    })

    return indexes
  }

  function isSliderItemInView(elRef: HTMLElement | null) {
    if (elRef) {
      const sliderDim = getRectFrom(sliderRef.current) as DOMRect
      const itemDim = getRectFrom(elRef) as DOMRect
      return itemDim?.x >= sliderDim.x && itemDim?.right <= sliderDim?.right
    }

    return false
  }

  function getRectFrom(elRef: HTMLElement | null) {
    return elRef?.getBoundingClientRect()
  }

  function handleNavigationButtonsDisabledState() {
    const sliderScrollWidth = sliderRef.current?.scrollWidth as number
    const sliderWidth = getRectFrom(sliderRef.current)?.width as number
    const shouldDisableNextButton =
      sliderScrollWidth - sliderWidth === sliderRef.current?.scrollLeft

    setItemMetadata({
      ...itemMetadata,
      isNextButtonDisabled: shouldDisableNextButton,
      isPreviousButtonDisabled: sliderRef.current?.scrollLeft === 0,
    })
  }

  return (
    <section className="mbxl" style={{ position: 'relative' }}>
      <div className="flex justify-center align-center pbm mbm">
        {title && (
          <StyledSubHeading
            className={classnames(
              'text-bold flex-grow',
              css.styledSubheadingOverrides
            )}
          >
            {title}
          </StyledSubHeading>
        )}

        {!bowserFlags.mobile && !shouldSimplifyUI && (
          <div className="flex flex-grow justify-end">
            <MultiItemSliderNavigationButton
              // This aria label helps control the styling. Check `styles.scss` for reference.
              aria-label="Previous Product"
              className="mrm"
              disabled={itemMetadata.isPreviousButtonDisabled}
              icon={ChevronLeftIcon}
              onClick={goToPreviousItem}
            />

            <MultiItemSliderNavigationButton
              // This aria label helps control the styling. Check `styles.scss` for reference.
              aria-label="Next Product"
              disabled={itemMetadata.isNextButtonDisabled}
              icon={ChevronRightIcon}
              onClick={goToNextItem}
            />
          </div>
        )}
      </div>

      <div
        className={classnames({
          [css.sliderWrapperLeftShadow]:
            !itemMetadata.isPreviousButtonDisabled && !shouldSimplifyUI,
          [css.sliderWrapperRightShadow]:
            !itemMetadata.isNextButtonDisabled && !shouldSimplifyUI,
        })}
      >
        {/* Ideally I would be using the `<Row />` component, but react-bootstrap does not forward the ref */}
        <ul
          aria-roledescription="carousel"
          dir="ltr"
          className={classnames(
            'row pbm',
            css.container,
            css.item,
            css.mandatoryScrollSnapping,
            {
              'flex justify-center': shouldSimplifyUI,
            }
          )}
          ref={sliderRef as React.LegacyRef<HTMLUListElement>}
          tab-index={0}
          {...rest}
        >
          {childrenArray.map((child, i) => (
            // Ideally I would be using the `<Col />` component, but react-bootstrap does not forward the ref
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`slider-item-${i}`}
              className={classnames(columnClassName, {
                [css.marginLeftPaddingFix]: i === 0,
                [css.marginRightPaddingFix]: i === childrenArray.length - 1,
              })}
              data-element-context="slider"
              data-index={i}
              ref={(el) => (itemRefs.current[i] = el)}
              onClick={() => handleClickedTrackingEvent(i)}
            >
              {child}
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

MultiItemSlider.Constants = {
  DESKTOP_MIN_ITEMS_TO_DISPLAY: 4,
  /**
   * ### Reasoning behind this debounce period:
   * A team of neuroscientists from MIT has found that the human brain can process entire images
   * that the eye sees for as little as 13 milliseconds https://news.mit.edu/2014/in-the-blink-of-an-eye-0116
   * There are between 2 - 4 products displayed any given time.
   * There is a padding of 1s added for reading the title of the product.
   * Finally I played around with numbers ranging from 2 - 1s and 1.4s felt the most natural for assuming that the product was viewed.
   */
  TRACKING_DEBOUNCE_PERIOD: 1400,
}
