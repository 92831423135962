import { SET_QUESTIONS, UPDATE_ANSWER } from '../actions'
import { QUESTION_ID_COREGISTRATION } from '../constants'

const questionsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_QUESTIONS:
      return action.questions
    case UPDATE_ANSWER:
      const questions = { ...state }
      questions[action.questionId].answer = action.answer
      return questions
    default:
      return state
  }
}

export const getCoregistrationAnswer = (state) =>
  state[QUESTION_ID_COREGISTRATION]?.answer

export default questionsReducer
