import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { bowserFlags } from 'lib/bowser'
import Link from 'components/link'
import withRouter from 'shared/HOC/withRouter'
import { Helmet } from 'react-helmet'
import { isArray, isObject, keys, map, max, min } from 'lodash'
import { FixedButtonBar, MultiStepWizard } from 'components/presentation'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import { ProgressBar } from 'components/progress-bar'
import styleMaps from '../../shared/style-maps'
import * as blUrls from '../../lib/urls'
import Question from '../components/question.jsx'
import { track } from '../../lib/analytics'
import { updateAnswer } from '../actions'
import { submitAnswer } from '../api'
import {
  QUESTION_TYPE_ARRIVAL,
  QUESTION_TYPE_CHECKBOX,
  QUESTION_TYPE_RADIO,
} from '../constants'
import ChecklistQuestion from './ChecklistQuestion'

export class QuestionList extends Component {
  static propTypes = {
    checklist: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    onUpdateAnswer: PropTypes.func.isRequired,
    questions: PropTypes.object.isRequired,
    questionId: PropTypes.number.isRequired,
  }

  render() {
    const progress = this.props.questionId / keys(this.props.questions).length
    const isChecklistLandingPage =
      typeof window !== 'undefined'
        ? this.props.router.location.pathname === blUrls.checklistQuizPath
        : true

    return (
      <div className="checklist-questions text-center">
        <Helmet
          meta={
            !isChecklistLandingPage && [
              {
                name: 'robots',
                content: 'noindex',
              },
            ]
          }
        />
        <ProgressBar
          color={styleMaps.COLORS.brandSecondary}
          height={3}
          percent={progress * 100}
          wrapperClassName="checklist-progress-bar"
        />

        <MultiStepWizard activeStep={this.props.questionId}>
          {map(this.props.questions, (question) => (
            <Question
              key={question.id}
              answer={question.answer}
              answers={question.answers}
              id={question.id}
              onUpdateAnswer={(answer) => {
                this.props.onUpdateAnswer(this.props.questionId, answer)
                this.handleNext(question.type)
              }}
              title={question.title}
              type={question.type}
            />
          ))}
        </MultiStepWizard>
        <FixedButtonBar>
          <Link
            className={classnames(
              'btn btn-block btn-default btn-empty',
              bowserFlags.mobile ? 'btn-lg' : 'btn-xl'
            )}
            to={this.prevLink()}
          >
            Back
          </Link>
          <a
            className={classnames(
              'next-link btn btn-block btn-info',
              bowserFlags.mobile ? 'btn-lg' : 'btn-xl'
            )}
            onClick={this.onNext.bind(this)}
          >
            Next
          </a>
        </FixedButtonBar>
      </div>
    )
  }

  // auto advance to next question
  handleNext(questionType) {
    switch (questionType) {
      case QUESTION_TYPE_ARRIVAL:
        break
      case QUESTION_TYPE_CHECKBOX:
        break
      default:
        this.onNext()
        break
    }
  }

  onNext() {
    const question = this.props.questions[this.props.questionId]
    const checklistQuestion = new ChecklistQuestion(question)

    if (checklistQuestion.isAnswered) {
      track.checklistQuestionAnswered({
        eventLocation: checklistQuestion.eventLocation,
        answer: checklistQuestion.answer,
        userLoggedIn: Boolean(this.props.currentUser),
      })
    }

    // Require answers for radio select questions
    if (this.blockNext(question)) {
      return
    }

    const nextLink = this.nextLink()
    if (nextLink) {
      // track that the user has engaged with the quiz
      if (this.props.questionId == 1) {
        // facebook tracking event
        fbq('trackCustom', 'ChecklistStarted')
      }
      // opportunistic update when we know where the user should go next
      this.props.router.navigate(nextLink)
    }

    submitAnswer(this.props.questionId, question.answer)

    // if we don't know where to take the checklist user
    // and it is a logged in user we know that they should
    // be redirected to the results page
    if (!nextLink && this.props.currentUser) {
      this.redirectToResults()
    }
  }

  prevLink() {
    let prevQuestionId = this.props.questionId - 1
    const firstQuestionId = min(
      map(keys(this.props.questions), (i) => parseInt(i))
    )

    // Look for the next valid question
    while (prevQuestionId >= firstQuestionId) {
      if (this.showQuestion(this.props.questions[prevQuestionId])) {
        return blUrls.checklistQuestionsPath(prevQuestionId)
      }
      prevQuestionId--
    }

    return blUrls.checklistQuizPath
  }

  nextLink() {
    let nextQuestionId = this.props.questionId + 1
    const lastQuestionId = max(
      map(keys(this.props.questions), (i) => parseInt(i))
    )

    // Look for the next valid question
    while (nextQuestionId <= lastQuestionId) {
      if (this.showQuestion(this.props.questions[nextQuestionId])) {
        return blUrls.checklistQuestionsPath(nextQuestionId)
      }
      nextQuestionId++
    }
    // short circuit the email capture form
    // the post checklist results page
    if (this.props.currentUser) {
      return null
    }

    return blUrls.checklistEmailPath
  }

  redirectToResults() {
    window.location.href = `${blUrls.checklistQuizPath}/${this.props.checklist.user_key}`
  }

  showQuestion(question) {
    // The questions in checklist.rb have an option last parameter with some
    // logic for showing a question. There are 4 types of logic currently:
    // 1. Only show if the user answered a previous question in a certain way (pattern or exact)
    // 2. Only show if the user answered a previous question (did not skip it)
    // 3. Only show to not logged in users
    // 4. Only show to logged in users
    const { showWhen } = question
    return (
      !showWhen ||
      (showWhen.require_no_user && !this.props.currentUser) ||
      (showWhen.require_user && this.props.currentUser) ||
      (isObject(showWhen.answers) &&
        showWhen.answers.source &&
        new RegExp(showWhen.answers.source).test(
          this.props.questions[showWhen.question_id].answer
        )) || // a specific pattern is required
      (isArray(showWhen.answers) &&
        showWhen.answers.indexOf(
          this.props.questions[showWhen.question_id].answer
        ) != -1) || // a specific answer is required
      (!isObject(showWhen.answers) &&
        showWhen.question_id &&
        !!this.props.questions[showWhen.question_id].answer)
    ) // no specific answer required, just that there is one
  }

  blockNext(question) {
    switch (question.type) {
      case QUESTION_TYPE_RADIO:
      case QUESTION_TYPE_ARRIVAL:
        return !question.answer || question.answer == ''
      default:
        return false
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    questionId: parseInt(ownProps.router.params.questionId) || 1,
    checklist: state.checklist,
    questions: state.questions,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    onUpdateAnswer: updateAnswer,
  })(withCurrentUser(QuestionList))
)
