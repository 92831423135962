import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { get } from 'lodash'

import { getShippingAddress } from '../reducers'
import { setActiveModal, saveShippingAddress } from '../actions'
import { AddressSuggestionForm } from 'shared/address-forms/address-suggestion-form'

const mapStateToProps = (state, props) => {
  const savedAddress = getShippingAddress(state)
  const recommendedAddress =
    props.recommendedAddress || get(savedAddress, 'recommendedAddress', {})
  return {
    initialValues: {
      addressOption: 'recommended',
    },
    recommendedAddress: {
      name: get(props.enteredAddress, 'name', get(savedAddress, 'name')),
      streetAddress1: recommendedAddress.line1,
      streetAddress2: recommendedAddress.line2,
      city: recommendedAddress.city,
      zip: recommendedAddress.zip,
      state: recommendedAddress.state,
      phone: get(props.enteredAddress, 'phone', get(savedAddress, 'phone')),
      country: get(
        props.enteredAddress,
        'country',
        get(savedAddress, 'country')
      ),
    },
    enteredAddress: props.enteredAddress,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onEdit: props.onEdit,
    onConfirm: (address) => {
      return dispatch(saveShippingAddress(address))
    },
    onSubmitSuccess: () => {
      dispatch(setActiveModal(null))
      dispatch(reset('orderAddressForm'))
      PubSub.publish(BLConstants.FLASH_MESSAGE, {
        variant: 'success',
        message: 'Your shipping address has been updated!',
      })
    },
  }
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm(
    {
      form: 'addressSuggestionForm',
      fields: ['addressOption'],
    },
    mapStateToProps
  )(AddressSuggestionForm)
)
