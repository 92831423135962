import { DismissUI } from 'components/dismiss-ui'
import {
  ChecklistCompletionModal,
  ChecklistCompletionModalProps,
} from './ChecklistCompletionModal'

interface DismissableChecklistCompletionModal
  extends ChecklistCompletionModalProps {
  registryId: number
}

export const DismissableChecklistCompletionModal = ({
  registryId,
  type,
  onHide,
}: DismissableChecklistCompletionModal) => {
  return (
    <DismissUI
      dismissDays={30}
      dismissId={`checklist-${type}-completion-modal-${registryId}`}
    >
      {(isDismissed: boolean, onDismiss: () => void) =>
        !isDismissed && (
          <ChecklistCompletionModal
            type={type}
            onHide={() => {
              onHide(), onDismiss()
            }}
          />
        )
      }
    </DismissUI>
  )
}
