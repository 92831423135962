import {
  SET_ORDER,
  SET_SHIPPING_ADDRESS,
  SET_ORDER_CAN_CANCEL,
  SET_ORDER_STATUS,
} from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ORDER: {
      return { ...state, ...action.order }
    }
    case SET_ORDER_CAN_CANCEL: {
      return { ...state, canCancel: action.canCancel }
    }
    case SET_ORDER_STATUS: {
      return { ...state, status: action.status }
    }
    case SET_SHIPPING_ADDRESS: {
      return { ...state, shippingAddress: action.address }
    }
    default:
      return state
  }
}

export const getIsPremiumShipping = (state) => state.isPremiumShipping
export const getIsHbbOrder = (state) => state.isHbbOrder
export const getOrderCreatedAt = (state) => state.createdAt
export const getOrderHolds = (state) => state.holds
export const getOrderItems = (state) => state.orderItems
export const getOrderCanCancel = (state) => state.canCancel
export const getOrderReference = (state) => state.reference
export const getOrderStatus = (state) => state.status
export const getOrderTotal = (state) => state.total
export const getOrderUuid = (state) => state.uuid
export const getShippingAddress = (state) => state.shippingAddress
