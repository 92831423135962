import React from 'react'

interface ChevronProps {
  className?: string
}

const Chevron: React.FC<ChevronProps> = ({ className }: ChevronProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.64645 3.64645C3.84171 3.45118 4.15829 3.45118 4.35355 3.64645L7.59931 6.8922C7.73715 7.03004 7.81954 7.11188 7.88538 7.16778C7.92557 7.20189 7.94519 7.2135 7.95067 7.21639C7.9828 7.22635 8.0172 7.22635 8.04933 7.21639C8.05481 7.2135 8.07443 7.20189 8.11462 7.16778C8.18046 7.11188 8.26285 7.03004 8.40069 6.8922L11.6464 3.64645C11.8417 3.45118 12.1583 3.45118 12.3536 3.64645C12.5488 3.84171 12.5488 4.15829 12.3536 4.35355L9.09545 7.61166C8.97407 7.73305 8.8626 7.84454 8.7618 7.93012C8.65312 8.02238 8.52441 8.11351 8.36052 8.16676C8.1262 8.24289 7.8738 8.24289 7.63948 8.16676C7.47559 8.11351 7.34688 8.02238 7.2382 7.93012C7.1374 7.84454 7.02593 7.73305 6.90455 7.61166L3.64645 4.35355C3.45118 4.15829 3.45118 3.84171 3.64645 3.64645Z"
      fill="#4A2143"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.64645 7.64645C3.84171 7.45118 4.15829 7.45118 4.35355 7.64645L7.59931 10.8922C7.73715 11.03 7.81954 11.1119 7.88538 11.1678C7.92557 11.2019 7.94519 11.2135 7.95067 11.2164C7.9828 11.2263 8.0172 11.2263 8.04933 11.2164C8.05481 11.2135 8.07443 11.2019 8.11462 11.1678C8.18046 11.1119 8.26285 11.03 8.40069 10.8922L11.6464 7.64645C11.8417 7.45118 12.1583 7.45118 12.3536 7.64645C12.5488 7.84171 12.5488 8.15829 12.3536 8.35355L9.09545 11.6117C8.97407 11.7331 8.8626 11.8445 8.7618 11.9301C8.65312 12.0224 8.52441 12.1135 8.36052 12.1668C8.1262 12.2429 7.8738 12.2429 7.63948 12.1668C7.47559 12.1135 7.34688 12.0224 7.2382 11.9301C7.1374 11.8445 7.02593 11.7331 6.90455 11.6117L3.64645 8.35355C3.45118 8.15829 3.45118 7.84171 3.64645 7.64645Z"
      fill="#4A2143"
    />
  </svg>
)

export default Chevron
