import { useState } from 'react'
import PropTypes from 'prop-types'
import { LinkButton } from 'components/buttons'
import Collapse from 'components/collapse'
import OrderItem from './order-item'

export const LIMIT = 6

const renderItem = ({
  id,
  imageUrl,
  name,
  productUrl,
  quantity,
  turntoSku,
}) => {
  return (
    <li key={id}>
      <OrderItem
        imageUrl={imageUrl}
        name={name}
        productUrl={productUrl}
        quantity={quantity}
        reviewSku={turntoSku}
      />
    </li>
  )
}

const OrderItems = ({ className, limit, orderItems }) => {
  const [showAll, setShowAll] = useState(false)
  const firstItems = orderItems.slice(0, limit)
  const additionalItems = orderItems.slice(limit)

  const hiddenCount = additionalItems.length
  return (
    <>
      {orderItems.length === 0 ? null : (
        <div className={className}>
          <ul className="list-bordered mbn">{firstItems.map(renderItem)}</ul>

          {hiddenCount > 0 ? (
            <>
              <Collapse expanded={showAll}>
                <div>
                  <hr />
                  <ul className="list-bordered mbn">
                    {additionalItems.map(renderItem)}
                  </ul>
                </div>
              </Collapse>
              <div className="pbl">
                <LinkButton
                  onClick={() => {
                    setShowAll(!showAll)
                  }}
                  className="h7 text-uppercase"
                >
                  {showAll
                    ? `Show Fewer Items`
                    : `+ ${hiddenCount} More Item${hiddenCount > 1 ? 's' : ''}`}
                </LinkButton>
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  )
}

OrderItems.defaultProps = {
  className: '',
  orderItems: [],
  limit: LIMIT,
}

OrderItems.propTypes = {
  className: PropTypes.string,
  orderItems: PropTypes.arrayOf(PropTypes.object),
  limit: PropTypes.number,
}

export default OrderItems
