import { connect } from 'react-redux'
import { setActiveModal, cancelOrder } from '../actions'
import { getSubmittingCancelOrder } from '../reducers'
import CancelOrderForm from 'shared/orders/cancel-order-form'

const mapDispatchToProps = (dispatch) => {
  return {
    onCancelNo: () => {
      dispatch(setActiveModal(null))
    },
    onCancelYes: () => {
      dispatch(cancelOrder())
    },
  }
}

const mapStateToProps = (state) => {
  return {
    submitting: getSubmittingCancelOrder(state),
  }
}

const ConnectedCancelOrderForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelOrderForm)

export default ConnectedCancelOrderForm
