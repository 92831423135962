import { InfoButton } from 'components/buttons'
import { newRegistryPathFromChecklist } from 'lib/urls'
import { ProgressRingDecoration } from './ProgressRingDecoration'
import css from './styles.scss'

export const HeroSection = () => (
  <div>
    <picture>
      <source
        media="(max-width:768px)"
        srcSet="//images.babylist.com/image/upload/q_auto:best/f_auto/v1678480871/checklist/Checklist_LP_Hero_Mobile_V2.png"
      />
      <img
        alt="mother holding baby"
        className={css.heroImage}
        role="presentation"
      />
    </picture>
    <div className={css.heroContainer}>
      <div className={css.contentContainer}>
        <div className={css.heroText}>
          <h1 className={css.heading}>Baby Registry Checklist</h1>
          <p className={`${css.subtext} h3 text-brand-info`}>
            Not sure what you need?
            <br /> Take the guesswork out of your baby registry with our
            interactive and printable checklist.
          </p>
          <ul className={css.featuresList}>
            <li className="text-bold text-uppercase">All the essentials</li>
            <li className="text-bold text-uppercase">Plus the fun extras</li>
          </ul>
          <InfoButton
            pill
            className={css.checklistCTA}
            href={newRegistryPathFromChecklist}
          >
            Get my checklist
          </InfoButton>
        </div>
      </div>
      <div className={css.attribution}>@camrynbyer</div>
    </div>

    <div className={css.decorationContainer}>
      <ProgressRingDecoration className={css.decoration} />
    </div>
  </div>
)
