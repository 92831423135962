import PropTypes from 'prop-types'
import { Media, Panel } from 'react-bootstrap'
import { map } from 'lodash'
import Image from 'components/image'
import RegItemNote from 'shared/reg-item-note'
import { LinkButton } from 'components/buttons'
import Card from 'components/card'
import { cashFundPrintablePath } from 'lib/urls'
import { PrintableDownloadButton } from './printable-download-button'

export const CrowdfundItems = ({
  crowdfundReservations,
  onSetReservationToRemove,
}) => {
  return (
    <Card id="help-and-favor-items" className="mbl">
      {map(crowdfundReservations, (reservation, idx) => {
        const { regItem } = reservation
        return (
          <div key={idx} className="pal">
            <Media id={`${reservation.id}`}>
              <Media.Left className="prl">
                <Image
                  width={100}
                  height={100}
                  src={regItem.imgUrl}
                  alt={regItem.title}
                />
              </Media.Left>

              <Media.Body>
                <div className="media-heading h5 mbm">
                  {reservation.displayTitle}
                </div>
                <div>
                  {regItem.description ? (
                    <RegItemNote
                      description={regItem.description}
                      className="h6 mtn mbm"
                    />
                  ) : null}
                  <div className="pts">
                    <PrintableDownloadButton url={cashFundPrintablePath} />
                  </div>
                  <p className="h7">
                    <LinkButton
                      className="xsmall pan mtxl"
                      onClick={() => onSetReservationToRemove(reservation)}
                    >
                      Remove
                    </LinkButton>
                  </p>
                </div>
              </Media.Body>
            </Media>
          </div>
        )
      })}
    </Card>
  )
}

CrowdfundItems.propTypes = {
  onSetReservationToRemove: PropTypes.func.isRequired,
  crowdfundReservations: PropTypes.array.isRequired,
}

export default CrowdfundItems
