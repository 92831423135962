import React from 'react'

interface LeoStickerProps {
  className?: string
}

const LeoSticker: React.FC<LeoStickerProps> = ({
  className,
}: LeoStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.609 14.8369C65.609 14.8369 60.6224 17.7776 54.8495 15.7336C49.0765 13.6895 46.3228 13.9904 44.4178 17.1573C42.5128 20.3242 39.8279 25.5739 35.7501 27.0358C31.6723 28.4978 25.0431 33.9816 21.6495 38.968C18.2599 43.9544 22.2509 50.0332 21.4019 58.13C20.5528 66.2267 16.2715 72.9969 17.3502 78.3294C18.4328 83.6619 25.2301 84.8918 27.5989 90.2526C29.9676 95.6134 29.7406 102.347 36.3799 104.3C43.0192 106.254 48.7702 106.425 56.5094 108.618C64.2486 110.811 74.4491 110.925 77.9188 105.526C81.3885 100.128 87.4059 103.44 90.0569 97.5805C92.7079 91.7208 95.3807 88.0996 100.058 84.8718C104.735 81.644 103.251 76.2295 104.966 69.5085C106.681 62.7874 109.603 52.8394 102.717 46.3192C95.8309 39.7989 90.6592 35.725 89.8704 32.1048C89.0817 28.4847 87.3083 21.3579 80.2624 19.2249C73.2166 17.0919 73.3941 13.5052 65.6091 14.8328L65.609 14.8369Z"
      fill="#FF7A5E"
    />
    <path
      d="M60.0569 85.5351C59.4997 86.4612 58.8594 87.4016 58.514 88.3891C58.4716 88.5222 58.401 88.6831 58.3264 88.844C58.2557 89.009 58.1809 89.1779 58.1145 89.3267C57.923 89.7774 57.7786 90.2414 57.6811 90.7266C57.5993 91.2163 57.5408 91.7227 57.5528 92.2551C57.5494 92.4094 57.5455 92.5839 57.5582 92.7264C57.6957 94.1916 57.8199 95.7216 57.8085 97.3176C57.7877 98.4423 57.5805 99.6116 57.1614 100.719C56.9543 101.344 56.5176 101.997 56.0832 102.544C54.7444 104.196 53.0072 105.295 51.2933 106.232C50.758 106.525 50.2794 106.75 50.1324 106.617C50.0472 106.542 49.574 106.702 49.4935 106.591C49.279 106.293 49.9078 105.702 51.1922 104.91C51.7644 104.553 52.3525 104.196 52.8943 103.79C53.9374 103.021 54.8022 102.105 55.5622 101.107C55.8229 100.743 56.0706 100.244 56.2113 99.7683C56.663 98.4338 56.6873 96.9721 56.6098 95.4675C56.5849 94.9836 56.5645 94.4795 56.5118 94.0031C56.3774 92.7532 56.304 91.4275 56.582 90.0932C56.7142 89.4625 56.9049 88.8696 57.1781 88.2907C57.4596 87.6957 57.7615 87.0688 58.0901 86.487C58.9683 84.9588 59.9033 83.5456 60.5206 82.036C60.7933 81.124 60.9959 80.1658 61.0769 79.1765C61.1539 78.1872 61.1208 77.1791 60.9686 76.2009C60.8714 75.5977 60.7729 74.8725 60.7216 74.1524C60.5851 72.4598 60.6132 72.2613 61.0778 72.8524C61.1891 72.9929 61.3034 73.1823 61.417 73.4001C61.576 73.7082 61.6979 74.0886 61.8004 74.8139C61.944 75.8203 62.1076 76.819 62.1841 77.8239C62.2415 78.6254 62.2716 79.41 62.1059 80.304C62.0515 80.6236 61.9925 80.9757 61.9378 81.3076C61.795 82.0599 61.5417 82.8098 61.2144 83.5135C60.8871 84.2171 60.4895 84.8866 60.0647 85.5394L60.0569 85.5351Z"
      fill="#F6491D"
    />
    <path
      d="M43.3808 79.4462C42.6069 79.8191 41.7292 80.0638 40.9165 80.4075C40.6972 80.5083 40.3371 80.5491 40.0599 80.5917C39.2167 80.7032 38.3676 80.7292 37.4945 80.7628C37.3643 80.7599 37.2227 80.7406 37.1041 80.7501C35.8702 80.857 34.746 80.4668 33.813 80.7184C33.1661 80.8788 32.5227 81.2384 32.0638 81.8253C31.812 82.1447 31.4958 82.5237 31.2386 82.912C30.4709 84.0812 29.9968 85.537 29.322 86.9682C29.1109 87.4144 28.8895 87.7913 28.7009 87.7506C28.5947 87.732 28.3314 88.039 28.2064 87.9835C27.8862 87.8221 28.0029 87.1829 28.4258 86.0792C28.6264 85.5759 28.8311 85.0605 29.0318 84.5491C29.2282 84.0538 29.4209 83.5463 29.6608 83.0479C29.9047 82.5455 30.2035 82.0606 30.5839 81.6344C30.855 81.3317 31.1675 80.9405 31.506 80.627C31.9855 80.1786 32.5617 79.8217 33.2016 79.6205C33.8456 79.4153 34.5087 79.4136 35.1076 79.4593C35.4938 79.4881 35.8793 79.5494 36.2303 79.5611C36.6919 79.5713 37.142 79.5649 37.6 79.5547C38.0547 79.52 38.5128 79.5098 38.9683 79.4386C39.4033 79.3994 39.8159 79.3028 40.2555 79.2353C40.485 79.2079 40.7117 79.1316 40.9421 79.0636C41.1727 78.9874 41.4069 78.9276 41.63 78.835C42.8312 78.4146 43.9575 77.8139 45.0496 77.1474C46.2337 76.2514 47.4867 75.2717 48.3079 74.0062C48.4306 73.8099 48.5811 73.606 48.7318 73.3941C48.8748 73.1738 49.0335 72.958 49.1999 72.7504C49.9287 71.7347 50.0535 71.6238 50.1307 72.2428C50.1472 72.3894 50.1432 72.5721 50.1191 72.7706C50.097 73.0585 49.9762 73.3483 49.6656 73.8289C49.2287 74.4935 48.788 75.1459 48.3088 75.7609C47.9154 76.2397 47.5339 76.7146 46.9804 77.1167C46.7837 77.2668 46.5939 77.4616 46.3971 77.6157C45.5239 78.3764 44.5158 78.9878 43.3728 79.4501L43.3808 79.4462Z"
      fill="#F6491D"
    />
    <path
      d="M37.0816 59.67C36.4479 59.7658 35.7452 59.9534 35.1836 60.3635C35.0352 60.4699 34.78 60.5861 34.5806 60.6833C33.9782 60.9747 33.3856 61.3556 32.815 61.8183C32.7306 61.8855 32.6305 61.9524 32.5579 62.0198C31.8277 62.7389 30.9738 63.3416 30.1113 63.9725C29.5015 64.4181 28.8347 64.769 28.194 65.1855C27.8376 65.4173 27.3755 65.6102 26.9418 65.7712C25.6441 66.2667 24.2796 66.5738 22.9075 66.6858C22.4805 66.7211 22.1056 66.7169 22.0506 66.5289C22.0174 66.4225 21.6715 66.354 21.6548 66.2196C21.6115 65.8531 22.1576 65.6092 23.1519 65.4442C23.5958 65.3605 24.0551 65.2975 24.4996 65.1855C25.3528 64.9768 26.1499 64.6247 27.0003 64.3631C27.3064 64.2683 27.6461 64.0767 27.9499 63.9006C28.8214 63.3999 29.6459 62.8778 30.4607 62.2621C30.7216 62.0647 30.9983 61.8677 31.232 61.6495C31.8564 61.0742 32.5161 60.512 33.2455 60.0081C33.5903 59.7679 33.9386 59.5441 34.3374 59.3579C34.7441 59.1678 35.1715 58.9375 35.621 58.7809C36.2098 58.5704 36.8328 58.4176 37.4808 58.3872C38.125 58.3485 38.7979 58.4405 39.4118 58.7058C40.1687 58.9256 40.7937 59.4023 41.3528 59.8208C41.9198 60.2435 42.448 60.6369 43.0017 60.7628C43.3472 60.8476 43.7362 60.9252 44.0752 60.9449C44.4774 60.9659 44.6688 60.8767 44.7788 60.8994C44.8888 60.9221 44.9219 61.0325 44.837 61.3028C44.7948 61.4278 44.701 61.5638 44.5681 61.6868C44.3788 61.8573 44.08 61.9848 43.5522 61.9366C42.8277 61.8557 42.1226 61.6127 41.5103 61.2743C41.0227 61.0118 40.5827 60.7421 40.1401 60.4034C39.981 60.2821 39.7897 60.1845 39.6139 60.1035C38.8248 59.7287 38.0183 59.6094 37.0853 59.6782L37.0816 59.67Z"
      fill="#F6491D"
    />
    <path
      d="M30.4248 73.0231C30.0285 73.2784 29.6033 73.5859 29.3531 74.015C29.2874 74.1313 29.1346 74.2579 29.0296 74.3693C28.6874 74.6746 28.3904 75.0742 28.108 75.5351C28.067 75.6033 28.0103 75.6711 27.9772 75.7394C27.6659 76.4312 27.1071 77.0769 26.4793 77.6236C26.0404 78.0161 25.5255 78.281 25.041 78.5952C24.7689 78.7639 24.4149 78.8861 24.0852 78.9804C23.1 79.2715 22.1149 79.3757 21.1541 79.4521C20.8536 79.4739 20.5971 79.4724 20.5459 79.2884C20.5166 79.1822 20.2693 79.124 20.2446 78.9894C20.1893 78.6307 20.5706 78.3426 21.2996 78.2205C21.6244 78.1667 21.9568 78.1294 22.2817 78.0715C22.9 77.9551 23.4731 77.7403 24.0755 77.6276C24.2935 77.5877 24.5291 77.4629 24.737 77.3416C25.3323 77.0135 25.8576 76.6392 26.3145 76.1456C26.46 75.9863 26.61 75.8068 26.7208 75.6143C27.0083 75.1048 27.3006 74.5588 27.6795 74.0188C27.8588 73.7628 28.0576 73.5194 28.2835 73.2969C28.4083 73.1859 28.5216 73.0584 28.6389 72.931C28.7638 72.8119 28.8889 72.6887 29.0139 72.5696C29.6942 71.9672 30.4787 71.4727 31.3449 71.2115C31.8159 70.97 32.3513 70.8559 32.8821 70.766C33.4166 70.6924 33.9542 70.6555 34.4919 70.6145C34.824 70.5893 35.2298 70.6226 35.5978 70.7607C36.4587 71.0965 36.4957 71.211 36.0717 71.4657C35.9717 71.5285 35.8521 71.5827 35.7247 71.6327C35.5456 71.6978 35.3554 71.7302 35.0251 71.6742C34.5604 71.6274 34.0975 71.6782 33.661 71.7823C33.312 71.8599 32.9938 71.9707 32.6137 72.0233C32.4753 72.0365 32.3355 72.1106 32.2042 72.1605C31.6027 72.4113 31.028 72.6952 30.4288 73.0232L30.4248 73.0231Z"
      fill="#F6491D"
    />
    <path
      d="M35.8059 50.9121C35.0687 50.8715 34.2557 50.8699 33.4707 51.0273C33.2606 51.0714 32.9291 51.0682 32.6687 51.0624C31.88 51.0329 31.0753 51.0071 30.2248 50.9153C30.0987 50.9044 29.9653 50.869 29.847 50.8623C28.6412 50.7749 27.5247 50.2142 26.5364 49.5669C25.8362 49.0966 25.2374 48.5067 24.6188 47.9204C24.4527 47.7542 24.2914 47.5517 24.1262 47.3449C23.9651 47.1342 23.8322 46.8957 23.691 46.6774C23.2954 45.9985 22.9519 45.2842 22.7358 44.5239C22.6058 44.1515 22.5435 43.7561 22.4572 43.3724C22.426 43.1767 22.4067 42.9773 22.3833 42.7818C22.3561 42.5862 22.3407 42.3869 22.3451 42.188C22.3426 41.9401 22.3359 41.7085 22.3752 41.5347C22.4185 41.361 22.4764 41.2404 22.5716 41.2181C22.6788 41.192 22.7819 40.8084 22.9161 40.8114C23.0897 40.8152 23.2322 40.9767 23.3245 41.2631C23.405 41.5533 23.4827 41.9693 23.5499 42.5028C23.6182 42.9836 23.7062 43.473 23.8498 43.9432C24.1299 44.843 24.6058 45.6414 25.073 46.4722C25.2562 46.7565 25.5135 47.083 25.7881 47.3368C26.5334 48.0884 27.3518 48.7441 28.2851 49.1992C28.4293 49.2836 28.5899 49.3359 28.7425 49.4001C28.8991 49.4564 29.0514 49.5329 29.2088 49.5526C30.0255 49.7452 30.8892 49.7804 31.7733 49.7795C32.1957 49.7766 32.6107 49.7533 33.0487 49.7548C33.5026 49.7566 33.9934 49.6943 34.4793 49.6766C35.1192 49.6541 35.7746 49.6441 36.4402 49.7116C37.1056 49.7831 37.7654 49.9357 38.3916 50.1769C39.9182 50.576 41.2735 51.5847 42.4826 52.6024C42.8665 52.9154 43.2591 53.3709 43.5639 53.869C43.9061 54.4615 44.0403 54.8178 44.0447 54.9763C44.0491 55.1348 43.9038 55.1032 43.6222 54.9873C43.4852 54.9315 43.3452 54.8391 43.1815 54.7461C42.9556 54.6071 42.7547 54.4077 42.4241 54.0064C41.9548 53.4519 41.3881 53.017 40.8079 52.6549C40.3406 52.3684 39.8799 52.139 39.3696 51.8312C39.1824 51.7296 38.9633 51.6395 38.7595 51.5741C38.2896 51.4054 37.8108 51.2811 37.3279 51.1608C36.8369 51.0566 36.3454 50.9686 35.8138 50.9123L35.8059 50.9121Z"
      fill="#F6491D"
    />
    <path
      d="M43.3627 40.7449C42.7221 40.6211 41.9871 40.4791 41.2503 40.4223C41.0533 40.4057 40.7632 40.3141 40.5442 40.2239C39.875 39.9615 39.2185 39.6627 38.5512 39.315C38.4537 39.26 38.3527 39.1887 38.2589 39.1461C37.3015 38.7067 36.5182 37.8933 35.9225 36.9785C35.4948 36.3274 35.2506 35.5868 35.0142 34.8505C34.9397 34.6458 34.9093 34.4136 34.8711 34.1772C34.8526 33.9412 34.8264 33.6969 34.8274 33.4694C34.791 32.0755 35.0491 30.7489 35.3572 29.4803C35.4528 29.0843 35.5548 28.7494 35.7482 28.7496C35.8548 28.752 36.0075 28.4466 36.1413 28.4658C36.4915 28.5141 36.6008 29.1096 36.4292 30.0928C36.3603 30.534 36.2637 30.9787 36.2105 31.4202C36.101 32.2708 36.1343 33.092 36.12 33.9203C36.1135 34.2167 36.2005 34.5679 36.2925 34.8705C36.4291 35.3 36.5898 35.7137 36.7983 36.1042C37.0108 36.4907 37.2634 36.8496 37.568 37.1772C37.7608 37.3845 37.9771 37.6005 38.2146 37.7478C38.8333 38.1514 39.5132 38.4669 40.2255 38.7385C40.5662 38.8637 40.9034 38.9646 41.2561 39.082C41.6204 39.2078 42.0337 39.2575 42.4307 39.3312C42.9615 39.4241 43.5165 39.4932 44.086 39.6235C44.6515 39.7537 45.2391 39.9535 45.7684 40.2982C46.4358 40.6378 46.9956 41.2106 47.3917 41.8611C47.7838 42.5196 48.0402 43.2442 48.2093 43.9912C48.3136 44.4525 48.3243 45.0417 48.1742 45.5867C47.9984 46.2246 47.7864 46.5286 47.6739 46.6196C47.5535 46.7104 47.4934 46.5709 47.4088 46.2888C47.3722 46.158 47.3363 45.9988 47.3005 45.8315C47.2793 45.7173 47.2662 45.5952 47.2576 45.4488C47.2608 45.3026 47.2646 45.1321 47.2694 44.9129C47.2909 44.2919 47.0989 43.6906 46.8417 43.1813C46.6258 42.7704 46.3648 42.4316 46.0813 42.0435C45.9819 41.8951 45.8264 41.7861 45.6906 41.6815C45.0614 41.2169 44.2851 40.9805 43.3706 40.7451L43.3627 40.7449Z"
      fill="#F6491D"
    />
    <path
      d="M84.4757 49.8737C84.8173 49.4182 85.1801 48.8941 85.3905 48.3016C85.4493 48.1404 85.5921 47.9283 85.7102 47.7603C86.077 47.2363 86.4357 46.7202 86.8105 46.1882C86.8675 46.1083 86.9443 46.0287 86.9933 45.9526C87.4611 45.1424 88.2759 44.5226 89.0953 44.0573C89.679 43.7208 90.3146 43.5317 90.9509 43.3182C91.1258 43.2612 91.3278 43.2291 91.5338 43.193C91.7396 43.1691 91.9527 43.1697 92.1543 43.1579C92.766 43.1591 93.3762 43.2335 93.9688 43.3887C94.5612 43.552 95.128 43.8 95.6578 44.1204C95.8173 44.2254 95.965 44.3302 96.0654 44.4299C96.1578 44.5375 96.1989 44.64 96.1615 44.7285C96.1198 44.8291 96.3076 45.081 96.2303 45.189C96.1283 45.3371 95.9457 45.3858 95.7137 45.3482C95.5996 45.3295 95.4703 45.286 95.3174 45.238C95.1686 45.186 95.0125 45.1054 94.8246 45.0322L94.5825 44.9172L94.328 44.8222C94.1638 44.7496 93.9873 44.701 93.8146 44.6566C93.1394 44.4874 92.4561 44.5089 91.7543 44.4732C91.5018 44.4677 91.2004 44.5341 90.938 44.6218C90.1832 44.8408 89.4621 45.1458 88.8437 45.6277C88.6431 45.7736 88.4458 45.9521 88.2837 46.1435C87.8775 46.6666 87.5096 47.2434 87.145 47.8487C86.9689 48.1373 86.8126 48.4263 86.6129 48.7144C86.4089 49.0145 86.2392 49.3723 86.0385 49.705C85.5102 50.5748 84.8743 51.4909 83.9221 52.0752C83.4235 52.4907 82.7984 52.741 82.1868 52.9103C81.5674 53.0754 80.9538 53.1594 80.3592 53.2763C79.9906 53.3454 79.552 53.3682 79.1308 53.3143C78.1548 53.1872 78.0657 53.1081 78.4408 52.7427C78.5296 52.6553 78.6499 52.5685 78.7819 52.4902C78.9659 52.3765 79.1882 52.3204 79.5872 52.3048C80.1443 52.2805 80.6877 52.1584 81.1923 52.0071C81.5976 51.8819 81.9562 51.7273 82.3734 51.6025C82.5245 51.553 82.6764 51.467 82.8086 51.3765C83.4251 50.9838 83.9334 50.4832 84.472 49.8655L84.4757 49.8737Z"
      fill="#F6491D"
    />
    <path
      d="M78.5138 79.9301C78.9625 79.9887 79.458 80.0686 79.9715 80.2343C80.4811 80.3917 81.0198 80.6635 81.4691 81.0511C81.7173 81.2515 81.981 81.6473 82.1282 81.9551C82.3625 82.4274 82.5063 82.8895 82.6579 83.3518C82.7936 83.8219 82.9253 84.296 83.025 84.7856C83.0574 84.9285 83.0736 85.0913 83.1023 85.2219C83.4359 86.5615 83.3427 87.9323 83.2661 89.2588C83.21 90.1959 83.0476 91.1184 82.9719 92.0509C82.9329 92.5659 82.8135 93.1482 82.7468 93.6707C82.6466 94.4564 82.6256 95.2318 82.8039 95.9181C82.967 96.5797 83.3834 97.2103 83.9081 97.5792C84.2386 97.8059 84.545 97.8735 84.5723 98.0691C84.579 98.122 84.6685 98.1809 84.7659 98.2358C84.8636 98.2826 84.969 98.3337 84.9833 98.4031C85.005 98.4929 84.9795 98.5776 84.8988 98.6571C84.818 98.7406 84.6784 98.8066 84.4803 98.8428C84.0879 98.9155 83.4318 98.7792 82.8392 98.2625C82.3165 97.8001 81.9312 97.1946 81.6971 96.5314C81.2595 95.2545 81.3275 93.9563 81.5977 92.7965C81.6976 92.3803 81.764 91.8741 81.8213 91.4245C81.9876 90.1405 82.1573 88.8851 82.1732 87.6263C82.182 87.2244 82.1794 86.806 82.1287 86.419C81.9892 85.4005 81.7742 84.4088 81.4042 83.4664C81.2366 83.0119 81.0362 82.6094 80.7996 82.2427C80.5515 81.8595 80.1779 81.6157 79.7393 81.4599C79.1599 81.244 78.4702 81.1964 77.7343 81.0949C76.9981 81.0097 76.1825 80.7643 75.5046 80.3635C74.6606 79.9713 73.9209 79.3295 73.3295 78.5813C72.7341 77.833 72.2792 76.9822 71.9277 76.0931C71.7228 75.5403 71.5569 74.8299 71.6 74.1241C71.6497 73.3006 71.8053 72.8654 71.8994 72.7172C72.0013 72.5691 72.089 72.7091 72.2241 73.0289C72.2918 73.1807 72.3351 73.3644 72.4136 73.5652C72.4657 73.7085 72.4939 73.8594 72.5253 74.0469C72.5725 74.2348 72.5793 74.4624 72.6321 74.756C72.7562 75.5752 73.1067 76.3262 73.5387 76.9653C73.8825 77.4846 74.2836 77.9118 74.7348 78.3929C74.8886 78.579 75.1186 78.71 75.3087 78.8604C75.7758 79.155 76.2639 79.3972 76.777 79.5791C77.0439 79.6459 77.2946 79.7327 77.5855 79.7837C77.8883 79.8351 78.191 79.8905 78.5017 79.942L78.5138 79.9301Z"
      fill="#F6491D"
    />
    <path
      d="M89.6983 57.9088C90.1403 58.0891 90.5769 58.3384 90.9889 58.6277C91.4009 58.917 91.7806 59.2422 92.1487 59.5549C92.3503 59.7218 92.592 60.0358 92.7641 60.2874C93.2842 61.0462 93.7252 61.8113 94.2425 62.5213C94.3191 62.6286 94.3915 62.7439 94.4685 62.835C95.2546 63.7012 96.0043 64.6113 96.9901 65.0107C97.6669 65.2855 98.4031 65.3708 99.1237 65.0901C99.5176 64.9444 99.9991 64.7682 100.446 64.5424C101.109 64.2117 101.801 63.7923 102.453 63.3883C103.097 62.976 103.703 62.5344 104.149 61.9999C104.429 61.6689 104.597 61.3558 104.782 61.3802C104.896 61.403 105.097 61.0703 105.227 61.0854C105.396 61.1094 105.51 61.3109 105.47 61.6918C105.43 62.0647 105.205 62.6202 104.724 63.1417C104.299 63.6117 103.798 63.9825 103.309 64.3251C102.836 64.6518 102.383 64.9546 101.907 65.2488C101.427 65.5428 100.919 65.816 100.351 66.0025C99.9494 66.1318 99.4597 66.3241 98.9801 66.4151C98.2982 66.5504 97.5531 66.5097 96.8953 66.2718C96.2293 66.046 95.6497 65.6595 95.15 65.2261C94.8286 64.9469 94.5317 64.6276 94.2498 64.3412C93.497 63.5772 92.9164 62.6953 92.3346 61.8701C92.0551 61.4781 91.779 61.1105 91.4951 60.7387C91.1957 60.3544 90.8125 60.0089 90.4281 59.7201C89.411 58.9464 88.1311 58.638 86.8384 58.5486C85.3296 58.5967 83.7123 58.9104 82.2989 59.647C81.8598 59.8729 81.3294 60.1212 80.7881 60.3246C79.5106 60.8042 79.3445 60.8168 79.6651 60.239C79.7431 60.1026 79.8569 59.9507 79.9906 59.7953C80.1809 59.5761 80.4457 59.3829 80.9762 59.1305C81.718 58.7813 82.437 58.395 83.1859 58.0784C83.7835 57.8275 84.3766 57.6009 85.1008 57.5153C85.362 57.4844 85.6434 57.4297 85.9087 57.3909C87.124 57.2307 88.4442 57.3207 89.6984 57.9047L89.6983 57.9088Z"
      fill="#F6491D"
    />
    <path
      d="M53.3124 28.9325C53.603 28.2809 53.9721 27.6513 54.0808 27.016C54.1122 26.8461 54.2041 26.6125 54.2634 26.431C54.449 25.8868 54.5138 25.2749 54.409 24.6552C54.3912 24.5654 54.3896 24.4598 54.3598 24.382C54.0856 23.5676 53.7958 22.7449 53.2306 22.0622C52.8384 21.5906 52.4065 21.1262 51.8566 20.8257C51.5524 20.6606 51.2181 20.4258 50.8827 20.2397C50.3956 19.9568 49.8162 19.741 49.2128 19.3581C48.9093 19.1605 48.6222 18.9349 48.3571 18.6041C48.1039 18.2736 47.9266 17.9001 47.845 17.4799C47.7264 16.9534 47.9078 16.4212 48.1047 16.4418C48.1599 16.443 48.2409 16.3514 48.3331 16.2843C48.4255 16.2133 48.5289 16.1749 48.5834 16.2086C48.7312 16.3093 48.7625 16.5009 48.8134 16.7011C48.8603 16.9011 48.9104 17.1378 49.065 17.4621C49.2007 17.7535 49.4049 17.9813 49.7278 18.1956C50.0274 18.3931 50.3913 18.5351 50.7823 18.7062C51.1732 18.8773 51.5908 19.0936 51.9517 19.3737C52.2117 19.5743 52.5507 19.7768 52.8459 19.9945C53.6769 20.6139 54.3574 21.4371 54.826 22.3857C54.9772 22.6896 55.1003 23.0132 55.2239 23.3165C55.5577 24.1118 55.7117 25.0047 55.6368 25.9007C55.5998 26.3264 55.5275 26.7432 55.3766 27.1419C55.2215 27.5528 55.0778 27.9843 54.8995 28.3784C54.667 28.8973 54.4313 29.3876 54.243 29.8709C54.0507 30.3581 53.882 30.854 53.7288 31.3584C53.6286 31.9654 53.5202 32.5845 53.4631 33.2088C53.4405 33.521 53.4217 33.8334 53.4346 34.1464C53.4391 34.3009 53.4515 34.4555 53.4678 34.6102C53.4881 34.765 53.5084 34.9198 53.5414 35.0342C53.6216 35.3366 53.7477 35.7049 53.8612 35.9267C53.9294 36.0541 53.964 36.0996 53.9868 36.1366C54.0096 36.1777 54.0405 36.2069 54.0674 36.244C54.1091 36.3221 54.1301 36.4403 53.9487 36.6151C53.86 36.6984 53.7131 36.7399 53.5358 36.7238C53.2957 36.6941 53.0118 36.5011 52.8011 36.0334C52.6622 35.7054 52.559 35.3741 52.4995 35.0276C52.4437 34.6933 52.3954 34.3754 52.3631 34.0497C52.3114 33.5287 52.2792 33.0203 52.3825 32.4498C52.4226 32.2436 52.4315 32.0163 52.4559 31.8056C52.5641 30.8332 52.8421 29.8604 53.3164 28.9326L53.3124 28.9325Z"
      fill="#F6491D"
    />
    <path
      d="M58.2447 38.1644C58.2447 38.1644 54.3522 35.1462 50.9523 35.9366C47.5523 36.727 42.6482 52.0742 42.6482 52.0742C42.6482 52.0742 42.9922 63.7146 45.941 67.825C48.8898 71.9353 50.7818 72.4115 50.7818 72.4115C50.7818 72.4115 50.681 81.1259 53.3922 83.4763C56.1034 85.8268 60.3227 86.9066 65.3988 85.2797C70.4749 83.6529 72.5169 81.6141 72.5638 79.8402C72.6107 78.0662 73.4018 71.5239 73.4018 71.5239C73.4018 71.5239 78.3568 67.6888 79.248 65.5719C80.1353 63.4509 79.8164 49.5933 79.8164 49.5933C79.8164 49.5933 71.9557 34.2579 67.5042 34.225C63.0527 34.1921 58.2487 38.1645 58.2487 38.1645L58.2447 38.1644Z"
      fill="#FFD200"
      stroke="#FFD200"
      stroke-width="3.3728"
      stroke-miterlimit="10"
    />
    <path
      d="M61.6342 68.6984C60.0383 68.7608 56.8129 68.2512 55.4909 68.4211C54.1689 68.5911 58.9183 71.0514 60.1704 72.8051C60.5762 73.3746 60.902 73.8163 61.1552 74.1509C61.4774 74.5764 62.1018 74.5373 62.3841 74.0845C62.8016 73.4073 63.5446 72.4691 64.8086 71.5261C67.2003 69.7387 67.9358 68.6014 67.6337 67.9773C67.3317 67.3533 64.1705 68.5957 61.6343 68.6943L61.6342 68.6984Z"
      fill="#6F3264"
    />
    <path
      d="M61.5141 79.0079C61.073 79.3232 60.5805 79.4626 60.2039 79.7183C60.1116 79.7854 59.9448 79.8304 59.818 79.8561C59.4253 79.9409 59.0321 80.0541 58.6533 80.2285C58.5974 80.2557 58.53 80.2705 58.482 80.3019C57.993 80.6405 57.4211 80.8026 56.8782 81.079C56.495 81.2737 56.0737 81.4066 55.67 81.6374C55.4423 81.7583 55.1445 81.8411 54.8554 81.8835C53.9993 82.0474 53.0969 81.9788 52.2967 81.75C52.0457 81.6795 51.8386 81.5856 51.8624 81.3993C51.8766 81.2899 51.702 81.1561 51.7364 81.0269C51.8271 80.6714 52.2049 80.5417 52.756 80.6106C53.0037 80.6526 53.2518 80.6784 53.5 80.7001C53.9736 80.7024 54.4105 80.578 54.9005 80.5522C55.0784 80.5399 55.2775 80.4589 55.457 80.3735C55.9712 80.1452 56.5126 79.9337 57.0624 79.7021C57.2416 79.6288 57.4247 79.5598 57.5886 79.4659C58.0165 79.2113 58.4674 78.9897 58.9452 78.8011C59.1683 78.7086 59.3872 78.624 59.6254 78.5602C59.8795 78.4927 60.1029 78.3839 60.3143 78.287C60.5933 78.1631 60.8335 78.01 60.999 77.843C61.1684 77.6762 61.2551 77.5034 61.3474 77.2536C61.3964 76.6414 61.4226 75.8093 61.171 75.0442C61.0933 74.8069 61.0367 74.5132 61.0433 74.2087C61.0591 73.4942 61.128 73.4104 61.5217 73.6344C61.6153 73.6852 61.7084 73.7603 61.8014 73.8395C61.9292 73.952 62.0088 74.1 62.0617 74.3855C62.1358 74.7852 62.2774 75.166 62.3871 75.5624C62.4749 75.8771 62.5667 76.1919 62.5385 76.5771C62.5276 76.715 62.544 76.8656 62.5446 77.0159C62.5451 77.3531 62.5014 77.7258 62.3313 78.1039C62.161 78.486 61.8499 78.8122 61.522 79.0081L61.5141 79.0079Z"
      fill="#6F3264"
    />
    <path
      d="M63.2573 77.8396C63.4691 77.9012 63.6927 77.9629 63.9124 78.0246C64.1359 78.0905 64.3597 78.1441 64.5962 78.1615C64.7183 78.1723 64.8902 78.2533 65.0189 78.3211C65.4091 78.5247 65.7915 78.728 66.2065 78.8793C66.2692 78.901 66.3275 78.9429 66.3865 78.9523C66.9838 79.0711 67.5058 79.3872 68.0708 79.5377C68.2709 79.5949 68.4628 79.66 68.6592 79.705C68.8554 79.758 69.0559 79.7949 69.2574 79.7831C69.4823 79.7881 69.7301 79.826 69.9624 79.8514C70.6596 79.9155 71.3283 79.8408 71.9035 79.5326C72.0869 79.4473 72.2276 79.3326 72.3666 79.4737C72.4399 79.5485 72.6424 79.496 72.7389 79.5916C72.8624 79.7202 72.9022 79.8836 72.8308 80.081C72.7594 80.2785 72.5881 80.5265 72.2567 80.702C71.9762 80.8908 71.6433 80.9526 71.3332 81.0554C70.7303 81.1884 70.1086 81.2803 69.5244 81.105C69.3164 81.0517 69.0685 81.0178 68.8361 80.9924C68.1911 80.8888 67.5921 80.6645 67.0172 80.4244C66.8294 80.3512 66.6459 80.2578 66.4615 80.2091C65.971 80.0764 65.4972 79.9035 65.0276 79.7226C64.8045 79.6365 64.589 79.5627 64.3703 79.4563C64.1438 79.3457 63.8844 79.2994 63.6414 79.221C63.461 79.1683 63.2842 79.1278 63.1077 79.0792C62.99 79.0482 62.5615 78.9698 62.3261 78.7249C62.2256 78.6293 62.1452 78.5138 62.0849 78.3866C62.0546 78.325 62.0285 78.2554 62.0063 78.1899L61.9621 78.0467C61.9146 77.871 61.8751 77.6955 61.8552 77.5204C61.5783 76.6532 61.6574 75.7492 61.4342 74.9482C61.3647 74.6989 61.3238 74.4056 61.334 74.1215C61.3452 73.7886 61.353 73.6142 61.414 73.5343C61.475 73.4544 61.5891 73.4691 61.7963 73.5589C61.894 73.6017 61.9912 73.6688 62.0881 73.7522C62.2197 73.8688 62.3031 74.025 62.3479 74.3225C62.4098 74.7381 62.5233 75.1427 62.6291 75.535C62.713 75.8496 62.8052 76.1441 62.8213 76.4856C62.8265 76.6076 62.8671 76.7384 62.9039 76.857C62.9665 77.0656 63.0251 77.27 63.0837 77.4784L63.1762 77.7566C63.1762 77.7566 63.1875 77.7853 63.199 77.7977C63.2184 77.8144 63.1986 77.818 63.2612 77.8438L63.2573 77.8396Z"
      fill="#6F3264"
    />
    <path
      d="M66.7121 75.4213C66.7127 75.3929 66.6982 75.3357 66.6904 75.3315C66.6943 75.3356 66.7059 75.344 66.7215 75.3525C66.7294 75.3526 66.7332 75.3608 66.7411 75.361L66.749 75.3612L66.7529 75.3613L66.7018 75.352C66.6543 75.3591 66.71 75.336 66.683 75.311C66.6713 75.3026 66.6642 75.2659 66.632 75.2936C66.6198 75.3137 66.6121 75.3013 66.6005 75.2929C66.5694 75.2719 66.5104 75.2625 66.443 75.2732C66.4233 75.2728 66.4036 75.2724 66.358 75.1901C66.3354 75.1409 66.2964 75.1197 66.2622 75.058C66.1748 74.9058 66.1624 74.7512 66.2862 74.6849C66.3382 74.6576 66.4015 74.6509 66.4609 74.64C66.5719 74.6181 66.7146 74.5887 66.823 74.6845C66.8578 74.7178 66.9167 74.7312 66.9675 74.7567C67.108 74.8289 67.1878 74.9687 67.228 75.1158C67.2388 75.1648 67.2416 75.2136 67.2563 75.2627C67.3011 75.3815 67.2776 75.5515 67.1959 75.6757C67.1592 75.7317 67.1186 75.7836 67.0626 75.8149C67.0027 75.8461 66.9542 75.8978 66.8825 75.9287C66.7109 76.0143 66.4158 75.9713 66.3016 75.7819C66.1967 75.7065 66.152 75.5836 66.1343 75.4898C66.1128 75.3919 66.1068 75.3065 66.0692 75.2203C66.0467 75.167 66.0441 75.1061 66.0729 75.0539C66.1466 74.9336 66.182 74.9385 66.3579 75.0155C66.4009 75.0327 66.44 75.0538 66.479 75.075C66.5336 75.1046 66.5606 75.1296 66.5598 75.1702C66.5624 75.2271 66.6047 75.2808 66.6433 75.3183C66.6743 75.3474 66.7134 75.3645 66.705 75.3846C66.7048 75.3927 66.7167 75.393 66.7247 75.3891C66.7247 75.3891 66.7366 75.3853 66.7326 75.3893L66.7165 75.4011C66.7165 75.4011 66.7084 75.409 66.7085 75.405L66.7167 75.393L66.7169 75.3849L66.6919 75.4412C66.6919 75.4412 66.692 75.4371 66.6923 75.4249L66.7121 75.4213Z"
      fill="#6F3264"
    />
    <path
      d="M68.3736 73.3249C68.3741 73.3005 68.3704 73.2883 68.3742 73.2965C68.374 73.3046 68.3741 73.3005 68.378 73.3047C68.3817 73.3129 68.3895 73.3171 68.3933 73.3253L68.4049 73.3337C68.4049 73.3337 68.4243 73.3504 68.3855 73.3211C68.3855 73.3211 68.3855 73.3211 68.3776 73.3209C68.3737 73.3168 68.3384 73.3079 68.3541 73.3123C68.362 73.3124 68.3739 73.3086 68.3702 73.3004C68.3663 73.2963 68.3825 73.2763 68.3467 73.2918C68.3347 73.2996 68.3427 73.2917 68.3467 73.2918C68.3426 73.2958 68.3667 73.276 68.3386 73.2997C68.3386 73.2997 68.3161 73.2464 68.2695 73.2129C68.2539 73.2045 68.2424 73.188 68.2681 73.0951C68.2811 73.0426 68.2742 72.9977 68.2914 72.9331C68.3347 72.7594 68.4084 72.6392 68.5028 72.6535C68.5461 72.6585 68.5811 72.6836 68.624 72.7049C68.7021 72.7431 68.8119 72.7781 68.8407 72.9005C68.8517 72.9414 68.8825 72.9746 68.9093 73.0117C68.978 73.1188 68.9752 73.2487 68.9451 73.3618C68.9364 73.3982 68.9198 73.4344 68.915 73.4708C68.9128 73.5723 68.8592 73.6767 68.7748 73.748C68.7384 73.7838 68.6984 73.8113 68.6507 73.8265C68.6031 73.8376 68.5589 73.8732 68.5071 73.8924C68.3799 73.9343 68.202 73.9466 68.0704 73.83C67.9725 73.7953 67.9032 73.7126 67.8617 73.6264C67.8202 73.5402 67.7945 73.4502 67.7573 73.3479C67.731 73.2864 67.741 73.1932 67.7898 73.1252C67.9237 72.9616 67.9621 73.0112 68.0812 73.16C68.112 73.1932 68.1429 73.2264 68.1738 73.2555C68.2203 73.2931 68.2436 73.3098 68.2472 73.3262C68.2506 73.3506 68.2975 73.3719 68.3331 73.3687C68.3607 73.3693 68.4006 73.3498 68.3807 73.3616C68.3807 73.3616 68.3726 73.3695 68.3646 73.3734C68.3606 73.3774 68.3564 73.3854 68.3524 73.3894L68.3646 73.3734L68.3647 73.3694C68.3649 73.3612 68.3731 73.3452 68.3735 73.3289L68.3736 73.3249Z"
      fill="#6F3264"
    />
    <path
      d="M56.9547 74.9149C57.0974 74.8896 57.2433 74.8928 57.3976 74.8759C57.4331 74.8767 57.4722 74.8938 57.5032 74.9188L57.5225 74.9396C57.6228 75.0474 57.6634 75.1742 57.6958 75.3171C57.7032 75.3375 57.6947 75.3658 57.7062 75.3823C57.784 75.6114 57.7426 75.6999 57.6982 75.9264C57.6679 76.0476 57.5947 76.1434 57.5215 76.2434C57.5094 76.2553 57.4971 76.2753 57.4848 76.2954C57.436 76.3633 57.3404 76.4019 57.257 76.4244C57.1896 76.4392 57.1263 76.4459 57.0593 76.4404C57.0277 76.4397 56.9922 76.4389 56.9569 76.43C56.9254 76.4252 56.9019 76.4166 56.8784 76.412C56.7804 76.3814 56.6944 76.343 56.6165 76.2966C56.5697 76.2671 56.531 76.2338 56.5723 76.1494C56.5931 76.1011 56.5786 76.0439 56.6115 75.9837C56.6978 75.8272 56.8102 75.7444 56.8967 75.7585C56.936 75.7634 56.9672 75.7803 56.9987 75.7851C57.0145 75.7854 57.0303 75.7858 57.0382 75.7859C57.0382 75.7859 57.0462 75.778 57.0502 75.7781C57.0582 75.7742 57.066 75.7784 57.0853 75.7951C57.1008 75.8076 57.1051 75.7915 57.1094 75.7794C57.1141 75.7429 57.1415 75.7557 57.1627 75.6871C57.171 75.667 57.1798 75.6266 57.1683 75.6142C57.1452 75.5852 57.1377 75.5688 57.1303 75.5443C57.1305 75.5362 57.1188 75.5318 57.1151 75.5196L57.1153 75.5114C57.1153 75.5114 57.0797 75.5147 57.0677 75.5185C57.0479 75.5222 57.0358 75.5341 57.0238 75.5419C56.9959 75.5535 56.9759 75.5652 56.9601 75.5649C56.9636 75.5853 56.96 75.573 56.9715 75.5855C56.9715 75.5855 56.9715 75.5855 56.9714 75.5936C56.9712 75.6017 56.9745 75.6302 57.014 75.627C57.0377 75.6275 57.042 75.6114 57.0219 75.6272C56.9816 75.6669 57.0002 75.7161 56.8708 75.8594C56.8385 75.8912 56.8024 75.9189 56.7625 75.9383C56.7066 75.9655 56.6515 75.9602 56.6092 75.9065C56.5478 75.828 56.4786 75.7452 56.4376 75.6346C56.404 75.5486 56.3826 75.4425 56.4441 75.3383C56.4647 75.3022 56.4775 75.2578 56.4982 75.2136C56.6012 75.0168 56.7651 74.9229 56.9627 74.911L56.9547 74.9149Z"
      fill="#6F3264"
    />
    <path
      d="M55.67 73.0312C55.8002 73.0341 55.9333 73.0857 56.083 73.0971C56.1264 73.0981 56.1767 73.1439 56.2154 73.1813C56.331 73.3057 56.3648 73.3836 56.4415 73.4868C56.453 73.5033 56.4566 73.5197 56.4682 73.5321C56.5299 73.5944 56.5642 73.652 56.5891 73.7744C56.5996 73.8356 56.6055 73.925 56.584 74.0058C56.5625 74.0866 56.5295 74.1508 56.4929 74.2028C56.391 74.3468 56.2557 74.4007 56.1159 74.4748C56.036 74.5177 55.9336 74.5074 55.8551 74.4894C55.6076 74.4352 55.4018 74.2804 55.301 74.0182C55.2712 73.9363 55.2691 73.855 55.3602 73.8367C55.4119 73.8216 55.4447 73.7655 55.5119 73.7629C55.6894 73.7668 55.8193 73.7818 55.847 73.7784C55.8588 73.7827 55.8664 73.7951 55.8819 73.8076C55.917 73.8246 55.9571 73.7971 55.9757 73.8503C55.9871 73.8708 55.9954 73.8507 55.9993 73.8508C56.0035 73.8428 55.9997 73.8346 56.0191 73.8512C56.0422 73.8761 56.0773 73.8972 56.0969 73.9017C56.1164 73.9102 56.1442 73.9027 56.0849 73.9095C56.0691 73.9092 56.0493 73.9128 56.0254 73.9204C56.0254 73.9204 56.0254 73.9204 56.0255 73.9164C56.0256 73.9123 56.0259 73.9001 56.0142 73.8917C55.964 73.8419 55.9333 73.8047 55.8986 73.7673C55.8793 73.7466 55.8752 73.7506 55.8678 73.7301C55.8604 73.7096 55.8404 73.7214 55.8285 73.7211C55.793 73.7203 55.7846 73.7445 55.7416 73.7273C55.7411 73.7476 55.7448 73.7599 55.7485 73.7722C55.748 73.7925 55.7395 73.8207 55.7945 73.8341C55.8061 73.8425 55.8145 73.8224 55.8179 73.8468C55.8213 73.8712 55.8168 73.8955 55.8122 73.9239C55.77 74.0488 55.738 74.0684 55.5489 74.0521C55.5055 74.0511 55.4584 74.0379 55.4192 74.0289C55.3603 74.0114 55.3254 73.9863 55.3147 73.9332C55.3005 73.8598 55.2361 73.7365 55.1954 73.6138C55.1621 73.5156 55.1446 73.4136 55.1935 73.3416C55.2017 73.3296 55.206 73.3134 55.2142 73.2974C55.2185 73.2812 55.2269 73.2611 55.2351 73.245C55.3096 73.0883 55.4851 73.0068 55.6698 73.0434L55.67 73.0312Z"
      fill="#6F3264"
    />
    <path
      d="M54.9391 76.6253C55.016 76.7204 55.0604 76.8554 55.1242 77.0031C55.1391 77.044 55.1338 77.1048 55.113 77.1572C55.0543 77.3102 54.9572 77.4218 54.8645 77.5091C54.8484 77.521 54.8284 77.5327 54.8203 77.5447C54.7025 77.6965 54.513 77.6964 54.3395 77.6885C54.2093 77.6856 54.0887 77.6058 53.9713 77.5585C53.901 77.5286 53.8473 77.4543 53.8133 77.3885C53.7033 77.183 53.6924 76.9635 53.7561 76.7577C53.7772 76.6932 53.8139 76.6412 53.9 76.6756C53.9509 76.6929 54.0026 76.6778 54.0611 76.7075C54.2129 76.8043 54.3097 76.8917 54.3288 76.9206C54.3363 76.937 54.328 76.9531 54.3356 76.9695C54.3466 77.0063 54.39 77.0072 54.3614 77.0513C54.3531 77.0714 54.3689 77.0677 54.3768 77.0679C54.3929 77.056 54.3844 77.0843 54.4275 77.0974C54.4393 77.0977 54.4549 77.1061 54.463 77.0982C54.4795 77.0661 54.4839 77.0458 54.4878 77.0459C54.4878 77.0459 54.4878 77.0459 54.4957 77.0461C54.5117 77.0383 54.4881 77.0338 54.4842 77.0296C54.4688 77.013 54.4256 77.0039 54.3912 76.9544C54.3515 76.9617 54.3202 76.9529 54.2888 76.9441C54.2573 76.9393 54.2257 76.9427 54.2327 76.9835C54.2321 77.0078 54.2399 77.012 54.2324 76.9956C54.2055 76.9626 54.194 76.946 54.1174 76.8388C54.0945 76.8058 54.0755 76.7729 54.0451 76.7153C54.0112 76.6415 54.0007 76.5844 54.045 76.5407C54.1056 76.4811 54.1975 76.4262 54.2851 76.3916C54.3528 76.3646 54.4283 76.3419 54.4985 76.38C54.5219 76.3927 54.5575 76.3895 54.5851 76.3941C54.7189 76.4093 54.8594 76.4814 54.9468 76.6377L54.9391 76.6253Z"
      fill="#6F3264"
    />
    <path
      d="M68.7493 75.9585C68.7819 75.9105 68.8258 75.8871 68.8395 75.8021C68.8401 75.7777 68.8678 75.7743 68.8876 75.7706C68.9507 75.772 69.002 75.7731 69.0537 75.754C69.0616 75.7541 69.0735 75.7544 69.0775 75.7504C69.1421 75.6828 69.2238 75.7374 69.287 75.7347C69.3344 75.7358 69.3733 75.761 69.4213 75.7336C69.4492 75.718 69.4765 75.7348 69.5 75.7435C69.5703 75.7775 69.6286 75.8154 69.679 75.8571C69.6945 75.8696 69.7059 75.8902 69.6646 75.9745C69.6399 76.0227 69.635 76.0633 69.602 76.1275C69.5196 76.2882 69.4383 76.392 69.4027 76.3993C69.3869 76.399 69.3752 76.3906 69.3634 76.3904C69.3358 76.3897 69.3075 76.4176 69.3009 76.3605C69.3013 76.3402 69.2853 76.3521 69.2773 76.356C69.2534 76.3676 69.2421 76.343 69.2267 76.3224C69.2229 76.3182 69.2153 76.3059 69.2111 76.3139C69.2027 76.3381 69.2025 76.3462 69.1907 76.3459C69.1868 76.3458 69.1867 76.3499 69.1749 76.3456C69.1633 76.3372 69.1707 76.3577 69.1706 76.3617C69.1703 76.378 69.1816 76.4026 69.1619 76.3981C69.2008 76.4274 69.1534 76.4264 69.2163 76.4399C69.236 76.4404 69.2399 76.4404 69.2242 76.4401C69.1845 76.4473 69.1763 76.4634 69.0496 76.4809C69.01 76.4841 68.9745 76.4874 68.9389 76.4907C68.8716 76.4973 68.8363 76.4884 68.8171 76.4636C68.7941 76.4306 68.7437 76.3889 68.7054 76.3393C68.6747 76.298 68.6481 76.2528 68.6686 76.2167C68.6768 76.2047 68.6693 76.1842 68.6658 76.1638C68.6519 76.0782 68.6774 75.9975 68.7416 75.9502L68.7493 75.9585Z"
      fill="#6F3264"
    />
    <path
      d="M53.2095 54.4313C53.2095 54.4313 51.0523 55.4237 48.8933 54.5273C46.7343 53.6309 46.3945 52.3887 46.3945 52.3887C46.3945 52.3887 47.1594 51.5282 49.4657 51.4733C51.7719 51.4184 52.9473 52.7115 53.2281 53.4041C53.5129 54.0968 53.5478 54.3047 53.5478 54.3047L53.2095 54.4313Z"
      fill="white"
    />
    <path
      d="M49.8946 51.9045C49.5965 52.357 49.3917 52.8764 49.3009 53.4146C49.2188 53.9165 49.1861 54.5088 49.5363 54.9145C49.8673 55.2996 50.4078 55.3115 50.8667 55.2606C51.1516 55.2303 51.3757 55.089 51.4378 54.7817C51.4998 54.4744 51.5101 54.1822 51.5206 53.8859C51.5311 53.5896 51.5181 53.2806 51.4892 52.9794C51.4671 52.7312 51.445 52.4788 51.3634 52.2415C51.2817 52.0041 51.1641 51.7903 50.9621 51.6437C50.7407 51.4804 50.4296 51.4451 50.2011 51.6066C49.9726 51.7681 49.8867 52.0831 49.7905 52.3287C49.6819 52.6025 49.5889 52.8808 49.5077 53.1633C49.4265 53.4458 49.3613 53.7206 49.4617 53.999C49.5511 54.2447 49.7282 54.4476 49.93 54.6023C50.1707 54.7863 50.5121 54.8751 50.7803 54.7023C51.0206 54.5451 51.094 54.2583 51.1119 53.9825C51.1582 53.3133 51.1062 52.6298 51.0102 51.9697C50.9723 51.717 50.5828 51.6597 50.4713 51.8847C50.1695 52.5077 50.0124 53.1866 50.0287 53.8815C50.0399 54.2717 50.6316 54.2887 50.6205 53.8945C50.6057 53.3093 50.7172 52.7269 50.9734 52.2045L50.4345 52.1195C50.4998 52.5637 50.5295 53.007 50.5315 53.4539C50.5314 53.6367 50.5351 53.8276 50.5193 54.0101C50.5092 54.1073 50.5149 54.209 50.4051 54.1782C50.2795 54.1429 50.1599 54.0184 50.0832 53.9152C49.9722 53.7584 49.9949 53.6249 50.0421 53.4512C50.1538 53.0394 50.2932 52.6241 50.4676 52.2339C50.484 52.2018 50.5046 52.1657 50.5171 52.1335C50.5545 52.049 50.5453 52.1097 50.5215 52.1133C50.569 52.1062 50.6119 52.1315 50.6506 52.1649C50.7398 52.2399 50.7804 52.3667 50.8135 52.4771C50.9194 52.8653 50.9298 53.292 50.9289 53.6901C50.9282 53.9013 50.9196 54.1123 50.8992 54.3231C50.8892 54.4203 50.8591 54.5293 50.865 54.6229C50.8647 54.6351 50.8602 54.6593 50.8521 54.6713C50.8766 54.6313 50.8644 54.6513 50.809 54.6541C50.548 54.6768 50.1482 54.729 49.9602 54.4852C49.7452 54.2124 49.8267 53.7349 49.8929 53.4195C49.9853 52.9829 50.1521 52.5804 50.393 52.212C50.6054 51.8877 50.1033 51.5679 49.887 51.8922L49.8946 51.9045Z"
      fill="#6F3264"
    />
    <path
      d="M66.7828 53.7337C66.7828 53.7337 69.0356 54.494 71.0823 53.3164C73.1291 52.1388 73.3276 50.8272 73.3276 50.8272C73.3276 50.8272 72.4727 50.0366 70.1787 50.2543C67.8807 50.4718 66.8577 51.94 66.6519 52.6828C66.4461 53.4257 66.4334 53.6447 66.4334 53.6447L66.7828 53.7337Z"
      fill="white"
    />
    <path
      d="M69.35 51.1343C69.5845 51.4197 69.7787 51.7449 69.9091 52.097C70.0207 52.4041 70.1425 52.7927 70.0798 53.1284C70.0172 53.4642 69.7352 53.5433 69.4504 53.5695C69.3753 53.576 69.3003 53.5784 69.2292 53.5809C69.2213 53.5807 69.099 53.578 69.099 53.578C69.1032 53.5659 69.1534 53.6158 69.1496 53.6076C69.0989 53.4034 69.0481 53.1991 69.0132 52.9912C68.9437 52.5632 68.9139 52.1239 68.9234 51.6895C68.9264 51.5555 68.9372 51.4217 68.9755 51.2926C69.0011 51.2079 69.0807 50.9984 69.1987 51.0172C69.175 51.0167 69.2175 51.0583 69.229 51.0748C69.2824 51.1612 69.3317 51.2517 69.3771 51.342C69.4832 51.5393 69.5814 51.7405 69.6756 51.9457C69.7507 52.1179 69.8494 52.2988 69.8847 52.4864C69.9127 52.6495 69.8266 52.7979 69.7213 52.9174C69.6848 52.9613 69.5473 53.1126 69.4732 53.0745C69.4185 53.0489 69.4063 52.8821 69.3956 52.8331C69.3744 52.7189 69.3611 52.6049 69.3478 52.4909C69.2877 51.994 69.2713 51.4859 69.2902 50.9827L68.7345 51.1248C69.0435 51.615 69.2444 52.1718 69.2907 52.7577C69.3029 52.9204 69.4063 53.0649 69.5799 53.0687C69.7258 53.0719 69.8946 52.9375 69.8824 52.7707C69.8266 52.079 69.6202 51.4124 69.2501 50.8315C69.0937 50.5844 68.7053 50.6571 68.6944 50.9737C68.6729 51.5946 68.7067 52.2128 68.7958 52.824C68.8302 53.0563 68.8721 53.3049 69.034 53.4832C69.2266 53.6986 69.5139 53.7334 69.765 53.6252C70.2591 53.4126 70.5964 52.7905 70.4344 52.2589C70.3499 51.9768 70.2178 51.7017 70.0933 51.439C69.9763 51.1927 69.8518 50.9341 69.6949 50.7073C69.5228 50.4557 69.245 50.3481 68.9584 50.4555C68.7117 50.5475 68.5453 50.7551 68.4492 50.9967C68.2612 51.4678 68.3154 52.0579 68.3598 52.5504C68.3885 52.8597 68.4331 53.1653 68.4975 53.4673C68.5512 53.7203 68.6043 53.9977 68.8501 54.1249C69.0647 54.2393 69.3697 54.1892 69.6033 54.1577C69.8368 54.1263 70.0514 54.062 70.2399 53.924C70.7494 53.5534 70.7326 52.8788 70.5949 52.3193C70.4502 51.7231 70.1661 51.1766 69.7778 50.7091C69.5317 50.4112 69.1041 50.8324 69.3501 51.1302L69.35 51.1343Z"
      fill="#6F3264"
    />
    <path
      d="M66.0122 60.5933C65.9557 59.93 65.9438 59.2149 65.7888 58.5454C65.7454 58.3657 65.7476 58.0855 65.7524 57.8662C65.7597 57.5333 65.7709 57.2046 65.7743 56.8716L65.7696 56.3678C65.7656 56.189 65.7617 56.0102 65.7577 55.8314C65.7602 55.7178 65.7747 55.5962 65.769 55.4986C65.7322 54.4783 66.0259 53.5059 66.3902 52.5553C66.6537 51.8787 67.0619 51.2662 67.541 50.6594C67.8089 50.3241 68.2417 50.0249 68.6684 49.8272C69.3106 49.5204 70.0118 49.4018 70.6747 49.4123C71.3415 49.4229 71.9781 49.5506 72.582 49.7304C72.9585 49.8402 73.2673 49.977 73.2511 50.1757C73.2408 50.2851 73.5092 50.4657 73.4707 50.5989C73.372 50.9582 72.8306 50.991 71.9777 50.826C71.5924 50.7566 71.2071 50.6872 70.8244 50.6828C70.0904 50.6748 69.4538 50.9086 68.8355 51.2037C68.6158 51.3207 68.3939 51.5392 68.2193 51.7629C67.7199 52.3977 67.3514 53.1776 67.1157 54.0254C67.0387 54.2958 66.9653 54.5826 66.9475 54.8543C66.9198 55.2152 66.9037 55.5885 66.9201 55.922C66.9393 56.3042 66.9626 56.6825 66.9817 57.0687C66.9973 57.4346 67.025 57.7927 67.0207 58.1663C67.0201 58.5521 67.0782 58.9636 67.1088 59.3664C67.192 60.4284 67.3188 61.4832 67.3545 62.5522C67.5604 63.7793 67.5473 65.091 67.8164 66.3154C67.8989 66.695 67.956 67.1471 67.958 67.598C67.9585 68.65 67.9045 68.7707 67.494 68.4123C67.3973 68.3249 67.2972 68.209 67.2054 68.077C67.0713 67.8912 66.9858 67.6538 66.9284 67.2098C66.8473 66.5906 66.7066 65.9904 66.5814 65.3987C66.4813 64.9254 66.3729 64.4721 66.353 63.9396C66.3454 63.7485 66.3065 63.5405 66.2713 63.3489C66.121 62.4641 66.0263 61.5644 66.0041 60.6013L66.0122 60.5933Z"
      fill="#6F3264"
    />
    <path
      d="M54.4985 60.629C54.4535 59.9822 54.4491 59.2834 54.2978 58.6262C54.2582 58.4506 54.2603 58.1786 54.2648 57.9715C54.2786 57.3463 54.2643 56.7367 54.1072 56.1646C54.0856 56.0707 54.0673 56.0053 54.0371 55.9437C53.653 55.2813 53.2524 54.4724 52.6897 53.8548C52.2847 53.4275 51.88 52.984 51.3605 52.733C51.2242 52.6487 51.0637 52.5924 50.9157 52.4998C50.8416 52.4575 50.7678 52.4072 50.6897 52.3689C50.6115 52.3347 50.5332 52.3045 50.455 52.2703C49.5324 51.8642 48.5033 51.8172 47.5209 52.1571C47.2186 52.2641 46.9594 52.3844 46.8366 52.2273C46.7714 52.1406 46.4781 52.1951 46.3977 52.0796C46.2984 51.9312 46.3262 51.7449 46.5005 51.5335C46.6786 51.3343 46.9769 51.0525 47.4733 50.9131C47.6918 50.8489 47.9071 50.748 48.1442 50.737L48.8446 50.6549C49.7371 50.6339 50.6652 50.7883 51.4158 51.2963C51.544 51.3885 51.6959 51.4812 51.8559 51.5659C52.0118 51.6547 52.1555 51.7634 52.3033 51.8601C53.1197 52.4304 53.7384 53.1914 54.2574 53.9989C54.4214 54.2584 54.5771 54.5381 54.7333 54.7974C54.8401 54.9622 54.9388 55.1431 55.0294 55.3319C55.12 55.5167 55.2094 55.7624 55.2566 55.9462C55.3663 56.3426 55.4285 56.7461 55.4711 57.141C55.5102 57.5196 55.5378 57.8776 55.5336 58.2472C55.5292 58.6289 55.5874 59.0323 55.6143 59.4269C55.686 60.4723 55.7934 61.5104 55.7979 62.5625C55.9686 63.7767 55.9127 65.063 56.1268 66.2741C56.1935 66.6492 56.231 67.1009 56.2056 67.539C56.1512 68.5736 56.0895 68.69 55.703 68.3159C55.6102 68.2286 55.5181 68.1088 55.4303 67.9728C55.3082 67.7833 55.2344 67.5461 55.2006 67.1107C55.1508 66.5044 55.0376 65.9089 54.932 65.3258C54.8515 64.8569 54.7587 64.4121 54.7583 63.8922C54.7584 63.7054 54.7234 63.5015 54.6961 63.31C54.5731 62.4422 54.4977 61.5591 54.4947 60.6208L54.4985 60.629Z"
      fill="#6F3264"
    />
    <path
      d="M76.4625 35.7209C76.4625 35.7209 82.6323 28.8703 86.1453 33.3505C89.6583 37.8307 82.4109 44.1458 82.4109 44.1458C82.4109 44.1458 82.4552 42.4896 81.6302 41.5941C80.8052 40.7027 79.7932 41.31 79.208 40.6391C78.6228 39.9683 79.6648 37.4566 78.5905 36.5922C77.7217 35.8948 76.4665 35.7169 76.4665 35.7169L76.4625 35.7209Z"
      fill="#FFD200"
      stroke="#FFD200"
      stroke-width="3.3728"
      stroke-miterlimit="10"
    />
    <path
      d="M82.9617 33.4509C83.3294 33.4225 83.7835 33.4121 84.3382 33.4934C84.4877 33.517 84.6863 33.6391 84.8101 33.7515C85.1893 34.097 85.4099 34.4796 85.6351 34.8257C85.6695 34.8793 85.6879 34.9406 85.7263 34.9861C86.0954 35.4329 86.2246 36.0207 86.3579 36.6004C86.4513 37.0167 86.4338 37.455 86.4394 37.9182C86.4455 38.1783 86.3402 38.4806 86.2242 38.7299C85.8601 39.4936 85.2921 40.0132 84.7617 40.4402C84.593 40.5746 84.441 40.6687 84.3057 40.5398C84.2284 40.465 84.0498 40.5098 83.9612 40.4063C83.7264 40.1372 83.794 39.7568 84.1334 39.3988C84.2829 39.2396 84.444 39.0928 84.5778 38.9333C84.8372 38.6222 84.963 38.2878 85.1549 37.9955C85.2244 37.8874 85.2398 37.7253 85.239 37.5831C85.2478 37.1812 85.2178 36.754 85.0933 36.3126C85.0529 36.1736 85.0128 36.0225 84.9365 35.903C84.7343 35.5858 84.5205 35.2561 84.3047 35.0199C84.2006 34.9079 84.0918 34.8284 83.9909 34.749C83.8863 34.6614 83.7363 34.6621 83.5707 34.6544C83.3538 34.6456 83.0845 34.6844 82.8155 34.715C82.5463 34.7497 82.2811 34.7845 82.0123 34.8029C81.4314 35.0136 80.7707 35.0843 80.347 35.5056C80.2139 35.6326 80.0652 35.7553 79.8857 35.8407C79.4823 36.0552 79.387 36.0856 79.2618 35.6726C79.2364 35.5746 79.2191 35.4605 79.2256 35.3469C79.2291 35.1885 79.2954 35.0438 79.4964 34.8776C79.7736 34.6562 80.0473 34.4145 80.34 34.2097C80.5725 34.0442 80.8127 33.8911 81.1139 33.8368C81.2209 33.8148 81.3325 33.7644 81.44 33.7221C81.9219 33.5296 82.433 33.4393 82.9696 33.4471L82.9617 33.4509Z"
      fill="#6F3264"
    />
    <path
      d="M71.1931 95.1295C71.2792 95.5213 71.3804 95.946 71.6136 96.2923C71.6707 96.387 71.7145 96.5504 71.7472 96.677C71.7943 96.869 71.8413 97.065 71.9001 97.2612C71.963 97.4535 72.0497 97.6382 72.1364 97.8229C72.1629 97.8763 72.1811 97.9457 72.2117 97.9911C72.5609 98.4415 72.7504 98.9818 73.0997 99.4282C73.2146 99.5891 73.3254 99.754 73.4564 99.899C73.5833 100.052 73.7143 100.197 73.8734 100.323C74.0602 100.444 74.2255 100.643 74.3914 100.817C74.6383 101.079 74.9174 101.308 75.1805 101.549C75.472 101.755 75.7389 102.004 76.0467 102.186C76.2336 102.304 76.3928 102.421 76.3296 102.602C76.2957 102.707 76.4224 102.872 76.3722 103.001C76.2384 103.34 75.8303 103.412 75.2485 103.123C74.9991 102.979 74.7663 102.795 74.5251 102.636C74.0825 102.301 73.5996 102.006 73.2827 101.52C73.1681 101.347 72.9739 101.2 72.8277 101.031C72.6158 100.794 72.4039 100.558 72.2359 100.299C72.0681 100.031 71.8962 99.7713 71.7444 99.4958C71.6536 99.3192 71.567 99.1264 71.456 98.9696C71.3027 98.7631 71.1774 98.537 71.06 98.311C70.9427 98.081 70.8174 97.8548 70.7281 97.6091C70.6384 97.3797 70.5446 97.1583 70.4749 96.9171C70.4173 96.664 70.3045 96.4097 70.2155 96.1519C70.0965 95.8162 70.0215 95.4612 69.9265 95.1138C69.8434 94.7627 69.8079 94.4045 69.7764 94.0423C69.5934 93.2097 69.688 92.3182 69.6437 91.4602C69.6259 91.1917 69.6408 90.8711 69.7578 90.5772C70.0098 89.8882 70.0857 89.8452 70.3938 90.1972C70.4669 90.28 70.5358 90.379 70.5966 90.486C70.684 90.6382 70.7121 90.7972 70.6705 91.0725C70.6501 91.462 70.6692 91.8523 70.736 92.2234C70.7966 92.5213 70.8338 92.8023 70.8307 93.1231C70.8281 93.2409 70.8608 93.3675 70.886 93.4818C70.9314 93.7509 70.9966 94.0163 71.0498 94.2856C71.0745 94.4201 71.0951 94.5587 71.1158 94.6973C71.1404 94.8359 71.1689 94.9746 71.1894 95.1213L71.1931 95.1295Z"
      fill="#F6491D"
    />
    <path
      d="M77.5537 86.436C77.6562 86.6211 77.6948 86.8412 77.8848 86.9957C77.9312 87.0373 77.9451 87.127 77.9474 87.2002C77.9585 87.4157 77.9657 87.623 78.0205 87.8232C78.0278 87.8518 78.0231 87.8883 78.0383 87.913C78.1985 88.1683 78.1484 88.4718 78.2056 88.7412C78.2487 88.933 78.2324 89.1358 78.339 89.3168C78.3959 89.4196 78.397 89.5496 78.4022 89.6716C78.4259 90.0295 78.4339 90.3872 78.4299 90.7445C78.4275 90.8541 78.4175 90.9514 78.2314 90.9798C78.1284 90.9978 78.0592 91.0897 77.9247 91.1071C77.5765 91.1441 77.3069 91.0204 77.2418 90.7509C77.2128 90.6324 77.2116 90.5065 77.1946 90.3843C77.1602 90.152 77.0624 89.9305 77.127 89.6882C77.1526 89.5994 77.1192 89.5012 77.0936 89.4113C77.0242 89.1579 77.0299 88.8981 77.0159 88.6379C77.0098 88.5565 77.0117 88.4672 76.9818 88.3934C76.8993 88.1966 76.8325 88.0042 76.7817 87.8C76.7522 87.706 76.7186 87.6199 76.709 87.5141C76.7036 87.4043 76.6264 87.3214 76.5812 87.2229C76.4645 86.9685 76.2956 86.7495 76.2066 86.4917C75.9585 86.2872 75.8714 85.94 75.55 85.8355C75.452 85.8049 75.3746 85.7342 75.3255 85.6356C75.2124 85.3935 75.2222 85.3084 75.5272 85.0755C75.5995 85.0202 75.6795 84.9732 75.7631 84.9385C75.8788 84.8842 75.9735 84.8862 76.0863 84.9618C76.2256 85.0867 76.4216 85.1479 76.5931 85.2492C76.7255 85.3293 76.8819 85.3977 76.9495 85.5535C76.9759 85.611 77.0303 85.6487 77.0807 85.6904C77.2974 85.8861 77.4657 86.1295 77.546 86.4277L77.5537 86.436Z"
      fill="#F6491D"
    />
    <path
      d="M43.1669 98.1292C43.4908 97.7585 43.8472 97.348 44.0278 96.8524C44.0741 96.7194 44.2002 96.5515 44.2978 96.4197C44.5987 96.0201 44.8687 95.5874 45.0728 95.1004C45.102 95.0279 45.151 94.9559 45.1683 94.8872C45.3535 94.1805 45.68 93.5134 45.9173 92.7753C46.0825 92.259 46.3147 91.7524 46.4769 91.1995C46.5665 90.8927 46.7555 90.5557 46.9478 90.2472C47.5244 89.3338 48.3201 88.5064 49.3721 88.0543C49.6949 87.9111 50.0078 87.8612 50.0868 88.0376C50.1361 88.1321 50.4117 88.1625 50.4403 88.2971C50.5072 88.6642 50.1817 88.9292 49.6413 89.2707C49.3971 89.4278 49.1447 89.6009 48.9229 89.8154C48.4953 90.2325 48.2074 90.7623 47.8293 91.2617C47.6911 91.4455 47.5944 91.7114 47.5024 91.9491C47.2303 92.6539 46.9625 93.3466 46.6945 94.0475C46.6065 94.2771 46.4988 94.5103 46.4305 94.7444C46.2472 95.3618 46.001 95.9697 45.6962 96.5479C45.5519 96.825 45.408 97.0899 45.2207 97.3498C45.0293 97.6177 44.8567 97.9267 44.6611 98.2067C44.1318 98.9424 43.5203 99.6479 42.7356 100.151C41.9144 100.88 40.8173 101.233 39.7718 101.572C39.4458 101.679 39.03 101.738 38.6247 101.681C38.1525 101.618 37.9058 101.527 37.821 101.436C37.7362 101.344 37.8253 101.241 38.0105 101.074C38.0991 100.995 38.2073 100.92 38.3235 100.842C38.4876 100.735 38.6704 100.683 39.0066 100.649C39.4773 100.603 39.9154 100.426 40.3186 100.219C40.6419 100.052 40.9263 99.863 41.2811 99.7043C41.4086 99.6502 41.5331 99.5514 41.6456 99.4605C42.1677 99.0536 42.6471 98.6133 43.1632 98.1169L43.1669 98.1292Z"
      fill="#F6491D"
    />
    <path
      d="M42.4147 29.8498C42.4903 30.0018 42.5415 30.1857 42.729 30.2751C42.7758 30.3005 42.798 30.37 42.8125 30.4272C42.8481 30.6026 42.9076 30.7705 43.0148 30.9191C43.0301 30.9397 43.0373 30.9724 43.0607 30.9851C43.2867 31.12 43.3598 31.3856 43.5414 31.5643C43.6689 31.6889 43.7639 31.8535 43.9479 31.9225C44.0497 31.9613 44.1226 32.0563 44.2037 32.1394C44.4429 32.3883 44.6821 32.6373 44.9214 32.8863C44.9986 32.9651 45.0561 33.0436 44.9462 33.1955C44.885 33.2835 44.9022 33.4017 44.8208 33.5096C44.6015 33.7931 44.3228 33.9007 44.0814 33.7492C43.9724 33.6818 43.8837 33.5864 43.7828 33.5071C43.5889 33.3525 43.3545 33.2417 43.2376 32.9914C43.1962 32.9011 43.1028 32.8422 43.0213 32.7795C42.7963 32.5999 42.632 32.3526 42.4716 32.1094C42.422 32.0311 42.3765 31.9448 42.3069 31.8824C42.1248 31.724 41.9751 31.5339 41.8415 31.3238C41.7766 31.2249 41.7156 31.1301 41.6747 31.0155C41.6339 30.8928 41.5413 30.7973 41.4767 30.6822C41.3097 30.382 41.127 30.0733 41.0757 29.7148C40.855 29.3403 40.9085 28.8825 40.7482 28.4525C40.6999 28.3174 40.6918 28.1466 40.7584 27.9897C40.9125 27.6234 40.9875 27.621 41.3346 27.8114C41.4165 27.8579 41.4983 27.9085 41.5721 27.9588C41.6732 28.0301 41.7347 28.1046 41.736 28.2264C41.748 28.3973 41.8312 28.5616 41.9227 28.7098C41.9911 28.8291 42.0757 28.9325 42.0766 29.0706C42.0755 29.1194 42.11 29.1689 42.1406 29.2102C42.2704 29.412 42.3646 29.6131 42.4185 29.858L42.4147 29.8498Z"
      fill="#F6491D"
    />
    <path
      d="M61.1123 24.6C61.1702 25.0156 61.1763 25.4584 61.2933 25.8794C61.3303 25.9939 61.3106 26.1722 61.284 26.3057C61.2157 26.7185 61.1318 27.1228 61.0831 27.5441C61.0738 27.6049 61.0527 27.6694 61.0513 27.7303C61.062 28.3235 60.829 28.8668 60.6905 29.4202C60.595 29.8161 60.4251 30.182 60.3688 30.591C60.3322 30.8177 60.2242 31.0631 60.1246 31.2843C59.8333 31.9643 59.4992 32.619 59.142 33.2489C59.0311 33.4454 58.9209 33.6096 58.7448 33.5407C58.647 33.502 58.4904 33.6245 58.3693 33.5649C58.0531 33.4077 57.9709 33.016 58.1835 32.5048C58.2753 32.2753 58.3986 32.0546 58.4944 31.8252C58.6856 31.3867 58.7984 30.9261 59.0639 30.5176C59.154 30.3693 59.2098 30.1675 59.2572 29.9857C59.4028 29.465 59.5951 28.9777 59.7445 28.4652C59.7915 28.3037 59.8544 28.1345 59.8619 27.9722C59.8992 27.5344 59.9401 27.1088 59.9772 26.679C59.9975 26.4723 59.9939 26.2773 60.0222 26.0667C60.0427 25.8519 60.0084 25.6196 59.9857 25.3957C59.9316 24.8015 59.7941 24.2339 59.6964 23.6509C59.426 23.0276 59.276 22.3094 58.8173 21.8079C58.6706 21.6584 58.5367 21.4646 58.4389 21.2471C58.1973 20.7463 58.1916 20.6446 58.6156 20.5686C58.7186 20.5506 58.8291 20.553 58.9432 20.5677C59.1087 20.5835 59.237 20.6717 59.406 20.8825C59.6203 21.1878 59.8826 21.4657 60.1047 21.7752C60.2849 22.0188 60.4612 22.2583 60.5449 22.581C60.5739 22.6954 60.6464 22.8107 60.6912 22.9254C60.9287 23.4343 61.0822 23.9941 61.1124 24.5959L61.1123 24.6Z"
      fill="#F6491D"
    />
    <path
      d="M96.1915 54.6557C96.5496 54.887 96.8467 55.1981 97.2062 55.3604C97.3039 55.4032 97.404 55.5191 97.481 55.6101C97.7038 55.8872 97.9276 56.1196 98.2194 56.3128C98.2622 56.3381 98.3007 56.3837 98.3478 56.3969C98.7941 56.561 99.1663 56.8657 99.6211 57.0057C99.9347 57.106 100.252 57.2348 100.591 57.2139C100.777 57.2017 100.989 57.2511 101.19 57.2717C101.489 57.3067 101.793 57.3256 102.097 57.316C102.393 57.3185 102.646 57.2753 102.802 57.2016C102.894 57.1548 102.914 57.1512 103.077 57.2401C103.167 57.303 103.287 57.2366 103.416 57.2638C103.581 57.3081 103.725 57.4249 103.711 57.6724C103.706 57.9119 103.478 58.2278 103.143 58.3789C102.848 58.5186 102.543 58.5566 102.274 58.5831C101.78 58.6332 101.269 58.7032 100.779 58.5381C100.607 58.4815 100.39 58.4686 100.19 58.4398C99.627 58.3543 99.1069 58.1317 98.6154 57.8691C98.4553 57.7884 98.3038 57.6794 98.1434 57.615C97.7211 57.4351 97.3279 57.1868 96.9673 56.8905C96.7968 56.7486 96.6379 56.6152 96.4954 56.4536C96.3491 56.2879 96.1584 56.1619 95.9916 56.0283C95.5497 55.6652 95.1316 55.4732 94.6619 55.2923C94.0867 55.2431 93.4343 55.115 92.7979 55.3366C92.603 55.4054 92.3692 55.449 92.1248 55.4355C91.5491 55.4107 91.4713 55.3561 91.6344 54.9414C91.672 54.8447 91.7295 54.7444 91.7947 54.6484C91.8884 54.5164 92.0126 54.4338 92.2584 54.3864C92.5992 54.3208 92.9373 54.2024 93.2866 54.1125C93.5644 54.0455 93.8461 53.9786 94.1646 54.0344C94.2786 54.0572 94.4091 54.0438 94.5313 54.0506C95.0876 54.0628 95.6991 54.255 96.1878 54.6475L96.1915 54.6557Z"
      fill="#F6491D"
    />
    <path
      d="M87.7519 70.1369C88.1945 70.1101 88.6649 70.0798 89.0847 69.8372C89.1966 69.7747 89.3908 69.7383 89.5453 69.7092C90.0168 69.6302 90.5048 69.515 91.0326 69.3845C91.112 69.3659 91.1989 69.3597 91.2705 69.3369C92.0102 69.0851 92.7934 69.1836 93.5376 69.2649C94.0692 69.3213 94.5706 69.4988 95.1052 69.5959C95.2506 69.6275 95.4073 69.6797 95.5638 69.74C95.7162 69.8124 95.8684 69.8889 96.0167 69.9652C96.8865 70.4393 97.6818 71.0701 98.2998 71.8635C98.4958 72.1034 98.6366 72.3421 98.5032 72.4854C98.4304 72.565 98.5079 72.8104 98.3994 72.8974C98.1103 73.1226 97.7194 72.9475 97.2519 72.4904C97.0393 72.2867 96.823 72.0707 96.5902 71.8869C96.1362 71.5316 95.6201 71.3091 95.1339 70.9856C94.9587 70.8721 94.7158 70.7937 94.4958 70.7442C93.8718 70.5801 93.2516 70.4284 92.609 70.394C92.4042 70.3732 92.1909 70.3766 91.993 70.4048C91.4816 70.5073 90.9771 70.6505 90.4287 70.8213C90.1704 70.9009 89.9119 70.9886 89.6305 71.0433C89.3372 71.0978 89.0306 71.2211 88.7057 71.2748C87.8614 71.4391 86.9806 71.4684 86.1449 71.2388C85.6447 71.1913 85.147 71.0301 84.6739 70.8247C84.2009 70.6153 83.7486 70.3616 83.2841 70.128C82.9992 69.9796 82.6886 69.7453 82.4504 69.4476C81.9136 68.7331 81.9438 68.616 82.4254 68.6103C82.54 68.6047 82.6661 68.6156 82.7957 68.6428C82.9887 68.6633 83.1406 68.756 83.3929 68.9484C83.742 69.2282 84.1524 69.4119 84.5521 69.5426C84.8732 69.6593 85.1876 69.719 85.5158 69.8724C85.6371 69.9198 85.7787 69.9432 85.9124 69.9665C86.5182 70.057 87.1131 70.1107 87.7518 70.1409L87.7519 70.1369Z"
      fill="#F6491D"
    />
    <path
      d="M75.1367 30.4976C75.2238 30.1259 75.3315 29.718 75.2532 29.3263C75.2319 29.2203 75.2629 29.0666 75.2853 28.9452C75.3604 28.5813 75.3963 28.2044 75.3454 27.8255C75.3388 27.7685 75.348 27.7078 75.3254 27.6585C75.1509 27.1592 75.1024 26.6707 74.8489 26.1736C74.675 25.8246 74.5216 25.4435 74.2692 25.0723C74.1277 24.8662 74.0276 24.5756 73.9509 24.2936C73.8263 23.8563 73.7778 23.3678 73.8315 22.9019C73.8813 22.4318 74.0301 21.9477 74.3394 21.5239C74.5308 21.256 74.7909 21.0952 74.9221 21.2321C74.9995 21.2988 75.2374 21.2512 75.2979 21.3703C75.3892 21.5267 75.3893 21.7013 75.3701 21.8593C75.3468 22.0213 75.3002 22.1665 75.225 22.3557C75.1581 22.5249 75.0907 22.7183 75.0703 22.925C75.0501 23.1277 75.0811 23.3355 75.1202 23.5313C75.1554 23.723 75.214 23.9315 75.2482 24.1719C75.272 24.343 75.3785 24.5281 75.4734 24.7008C75.7388 25.194 75.9951 25.7439 76.1788 26.3654C76.2375 26.5657 76.2764 26.7737 76.3393 26.9619C76.502 27.461 76.5772 27.9907 76.5775 28.5147C76.576 28.7624 76.5746 29.0061 76.5297 29.2529C76.4886 29.5079 76.4825 29.788 76.445 30.0552C76.3388 30.7556 76.1852 31.459 75.891 32.0942C75.6997 32.8983 75.2146 33.5985 74.9766 34.369C74.9043 34.6071 74.7721 34.8723 74.5976 35.0918C74.1836 35.6107 74.0884 35.633 73.892 35.2266C73.8429 35.128 73.8098 35.0176 73.7808 34.8992C73.7411 34.7318 73.7644 34.5698 73.8843 34.3206C74.0538 33.9751 74.1567 33.5996 74.2594 33.2403C74.3408 32.9497 74.3982 32.6707 74.5544 32.3898C74.6079 32.2894 74.6383 32.1642 74.6684 32.0511C74.8022 31.5301 74.9555 31.0217 75.1367 30.4976Z"
      fill="#F6491D"
    />
    <path
      d="M43.0063 37.3781C43.0063 37.3781 36.1342 31.2928 33.1301 36.1577C30.1221 41.0226 38.0113 46.4479 38.0113 46.4479C38.0113 46.4479 37.7869 44.8061 38.5111 43.8228C39.2353 42.8395 40.306 43.3261 40.8156 42.59C41.3252 41.8538 40.0156 39.4814 40.9925 38.4955C41.7796 37.7005 43.0103 37.3782 43.0103 37.3782L43.0063 37.3781Z"
      fill="#FFD200"
      stroke="#FFD200"
      stroke-width="3.3728"
      stroke-miterlimit="10"
    />
    <path
      d="M33.6638 38.6475C33.7014 38.1934 33.8618 37.7217 34.0464 37.2221C34.0968 37.0851 34.2466 36.9138 34.3831 36.8112C34.7848 36.491 35.2107 36.3298 35.6009 36.1759C35.6606 36.1528 35.7279 36.1421 35.7759 36.1147C36.2793 35.8374 36.852 35.8216 37.41 35.7526C37.8097 35.7045 38.2074 35.7498 38.6264 35.7184C38.8596 35.7032 39.1344 35.7743 39.3813 35.8528C40.122 36.0925 40.8109 36.5341 41.3179 37.1708C41.4755 37.3652 41.5775 37.5705 41.4405 37.6975C41.3639 37.7729 41.3949 37.9767 41.2829 38.0474C40.9787 38.2356 40.6573 38.1351 40.3358 37.86C40.1885 37.7349 40.0376 37.5976 39.8663 37.4922C39.5313 37.2899 39.1579 37.2167 38.8027 37.0343C38.67 36.9663 38.4924 36.9665 38.3307 36.9629C37.877 36.953 37.4194 36.9429 36.9483 37.0057C36.7978 37.0267 36.6397 37.0395 36.5002 37.0974C36.1299 37.2476 35.7755 37.3861 35.5032 37.5669C35.371 37.6534 35.2742 37.7487 35.1654 37.8479C35.0487 37.9509 35.0015 38.1245 34.9506 38.2859C34.8185 38.7298 34.8625 39.2384 34.9183 39.7555C35.131 40.3126 35.2943 40.962 35.7754 41.3422C35.9229 41.4551 36.0657 41.6045 36.1842 41.7777C36.4674 42.182 36.5089 42.2682 36.122 42.4506C36.0303 42.4932 35.9192 42.5192 35.8085 42.529C35.6503 42.5458 35.5013 42.4978 35.3119 42.315C35.0491 42.0614 34.7703 41.8197 34.5238 41.5381C34.3275 41.3144 34.1391 41.0828 34.0476 40.7599C34.0186 40.6415 33.95 40.5303 33.9013 40.4115C33.6759 39.8907 33.5627 39.2952 33.6677 38.6476L33.6638 38.6475Z"
      fill="#6F3264"
    />
  </svg>
)

export default LeoSticker
