import { apiV3OrderPath, apiV3OrderAddressPath } from 'lib/urls'
import fetch from 'lib/fetch'

const DELETE = 'DELETE'
const PUT = 'PUT'

export const cancelOrder = (uuid) => {
  return fetch(apiV3OrderPath(uuid), {
    method: DELETE,
  })
}

export const saveOrderAddress = (data) => {
  return fetch(apiV3OrderAddressPath, {
    body: JSON.stringify(data),
    method: PUT,
  })
}
