import Dog from './Dog.svg'
import Dragon from './Dragon.svg'
import Goat from './Goat.svg'
import Horse from './Horse.svg'
import Monkey from './Monkey.svg'
import Ox from './Ox.svg'
import Pig from './Pig.svg'
import Rabbit from './Rabbit.svg'
import Rat from './Rat.svg'
import Rooster from './Rooster.svg'
import Snake from './Snake.svg'
import Tiger from './Tiger.svg'

export {
  Dog,
  Dragon,
  Goat,
  Horse,
  Monkey,
  Ox,
  Pig,
  Rabbit,
  Rat,
  Rooster,
  Snake,
  Tiger,
}
