import { Component } from 'react'
import PropTypes from 'prop-types'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import Router from 'shared/universal-router'
import { Routes, Route } from 'react-router-dom-v5-compat'
import { keyBy } from 'lodash'

import { RECAPTCHA_V3_SITE_KEY } from 'shared/constants'

import * as blUrls from '../../lib/urls'

import EmailCapture from './email-capture'
import QuestionList from './question-list'
import ChecklistWrapper from '../components/checklist-wrapper'

import { SET_CHECKLIST, SET_QUESTIONS } from '../actions'
import checklistReducer from '../reducers'

const middlewares = [thunk]

if (process.env.NODE_ENV == 'development') {
  // middlewares.push(createLogger())
}
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

class ChecklistApp extends Component {
  constructor(props) {
    super(props)
    this.store = createStoreWithMiddleware(checklistReducer)
    this.store.dispatch({ type: SET_CHECKLIST, checklist: props.checklist })
    this.store.dispatch({
      type: SET_QUESTIONS,
      questions: keyBy(props.questions, 'id'),
    })
  }

  render() {
    const { path, isEuIp } = this.props

    return (
      <Provider store={this.store}>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
          <Router
            location={{ pathname: path }}
            initialEntries={[{ pathname: path }]}
            useV6Router
          >
            <Routes>
              <Route
                path={blUrls.checklistQuizPath}
                element={<ChecklistWrapper isEuIp={isEuIp} />}
              >
                <Route index element={<QuestionList />} />
                <Route path={'q/:questionId'} element={<QuestionList />} />
                <Route path={'email'} element={<EmailCapture />} />
              </Route>
            </Routes>
          </Router>
        </GoogleReCaptchaProvider>
      </Provider>
    )
  }
}

ChecklistApp.propTypes = {
  isEuIp: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
}

export default ChecklistApp
