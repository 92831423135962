import { useEffect, useState } from 'react'
import { SectionHeading } from 'components/headings'
import { Col, Row } from 'react-bootstrap'
import { ProgressRing } from '../../../../../baby-ui/compounds/ProgressRing'
import { useRegistryChecklistCategories } from '../../api/hooks'
import css from './styles.scss'
import { DismissableChecklistCompletionModal } from '../ChecklistCompletionModal/DismissableChecklistCompletionModal'
import { PdfLink } from './PdfLink/PdfLink'

interface ChecklistOverviewProps {
  registryId: number
}

export const ChecklistOverview = ({ registryId }: ChecklistOverviewProps) => {
  const { isLoading, data } = useRegistryChecklistCategories(registryId)
  const {
    completedCategoriesCount,
    completedEssentialsCategoryCount,
    totalCategoriesCount,
    totalEssentialsCategoryCount,
  } = data

  let percentEssentialsCompleted = 0
  let percentEverythingCompleted = 0

  if (!isLoading && data) {
    percentEssentialsCompleted = Math.round(
      (completedEssentialsCategoryCount / totalEssentialsCategoryCount) * 100
    )
    percentEverythingCompleted = Math.round(
      (completedCategoriesCount / totalCategoriesCount) * 100
    )
  }

  const [showEverythingCompletionModal, setShowEverythingCompletionModal] =
    useState(false)
  const [showEssentialsCompletionModal, setShowEssentialsCompletionModal] =
    useState(false)

  useEffect(() => {
    if (percentEverythingCompleted === 100) {
      setShowEverythingCompletionModal(true)
    } else if (percentEssentialsCompleted === 100) {
      setShowEssentialsCompletionModal(true)
    }
  }, [completedCategoriesCount])

  return (
    <>
      {showEverythingCompletionModal && (
        <DismissableChecklistCompletionModal
          registryId={registryId}
          type="everything"
          onHide={() => setShowEverythingCompletionModal(false)}
        />
      )}
      {showEssentialsCompletionModal && (
        <DismissableChecklistCompletionModal
          registryId={registryId}
          type="essentials"
          onHide={() => setShowEssentialsCompletionModal(false)}
        />
      )}
      <Row className="mtm text-right visible-xs">
        <PdfLink />
      </Row>
      <Row className="mts-xs mtxl-sm mbl-sm">
        <Col sm={6} md={8}>
          <SectionHeading className="mbs mtn text-center-xs text-center-sm">
            Checklist
          </SectionHeading>
          <span className="h6 hidden-xs">
            Add any item from any store to automatically update your checklist!
          </span>
          <div className="hidden-xs mts">
            <PdfLink displayText />
          </div>
        </Col>
        <Col sm={6} md={4}>
          {!isLoading && (
            <div className={css.progressContainer}>
              <div>
                <ProgressRing
                  label="Essentials"
                  progress={percentEssentialsCompleted}
                />
              </div>
              <div className="mlxl">
                <ProgressRing
                  label="Everything"
                  progress={percentEverythingCompleted}
                  variant="secondary"
                />
              </div>
            </div>
          )}
          {isLoading && <div className={css.placeholderContainer} />}
        </Col>
      </Row>
    </>
  )
}
