import React from 'react'

interface MonkeyStickerProps {
  className?: string
}

const MonkeySticker: React.FC<MonkeyStickerProps> = ({
  className,
}: MonkeyStickerProps) => (
  <svg
    className={className}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1019_6466)">
      <path
        d="M22.2884 59.2007C22.2884 59.2007 20.3439 54.3544 20.2169 52.2654C20.0899 50.1765 22.6738 53.809 22.6738 53.809C22.6738 53.809 21.0359 46.6231 22.2971 47.7357C23.5584 48.8483 25.2225 52.8019 25.2225 52.8019C25.2225 52.8019 25.4459 45.7083 26.3261 48.2283C27.2064 50.7482 26.7816 54.4775 26.7816 54.4775C26.7816 54.4775 28.7829 48.6241 29.1596 49.8115C29.5362 50.9989 28.4107 55.511 28.4107 55.511C28.4107 55.511 32.4704 52.9603 32.3871 54.7326C32.3039 56.5049 26.7772 59.2007 26.7772 59.2007H22.2884Z"
        fill="#F3D7E8"
      />
      <path
        d="M40.3226 96.8632C40.3226 96.8632 34.6207 93.9255 36.3549 96.4014C38.0891 98.8774 39.4292 99.3392 39.4292 99.3392C39.4292 99.3392 33.0923 96.5026 33.3025 98.6267C33.5127 100.751 38.7635 102.281 38.7635 102.281C38.7635 102.281 32.4047 102.462 33.8981 103.684C35.3914 104.907 40.0248 104.397 40.0248 104.397C40.0248 104.397 33.8587 106.305 36.4337 107.194C39.0088 108.082 42.1663 106.23 42.1663 106.23C42.1663 106.23 36.9111 108.165 39.1095 108.948C41.3079 109.731 52.0899 107.633 52.6767 106.323C53.2636 105.012 40.3226 96.8588 40.3226 96.8588V96.8632Z"
        fill="#F3D7E8"
      />
      <path
        d="M44.4129 63.6908C44.4129 63.6908 45.8887 77.5174 50.4345 81.2248C54.9802 84.9321 58.5976 91.5112 59.1406 95.0514C59.6837 98.5916 67.238 110.057 75.7997 102.638C84.3613 95.2185 85.5393 83.4149 75.7997 71.4441C66.06 59.4733 54.2358 59.1963 54.2358 59.1963L43.6465 61.4655L44.4172 63.6864L44.4129 63.6908Z"
        fill="#F26D51"
      />
      <path
        d="M31.1917 47.3356C31.1917 47.3356 21.2637 36.2092 31.1917 28.368C41.1197 20.5267 62.4165 21.2875 65.3682 30.8967C68.3199 40.5058 64.6631 45.0619 59.8152 47.3356C54.9716 49.6136 34.1697 52.0104 31.1917 47.3356Z"
        fill="#F26D51"
      />
      <path
        d="M48.9761 61.4569C43.2479 62.917 37.3752 59.5263 35.6103 53.8663C34.7038 50.9682 33.9637 47.4895 34.6775 45.3126C36.0877 41.0116 59.8194 47.3356 59.8194 47.3356C59.8194 47.3356 62.447 55.599 51.8489 60.4894C50.8548 60.9467 49.8782 61.2282 48.9761 61.4569Z"
        fill="#F26D51"
      />
      <path
        d="M41.9296 30.901C44.417 31.2352 46.9571 31.1165 49.3394 30.3249C51.1919 29.7092 53.421 29.3706 55.3085 30.2194C59.4207 32.0752 60.6995 40.9851 56.7361 43.2852C56.2062 43.593 55.6982 43.8481 55.2428 44.0548C54.1436 44.5561 53.421 45.6556 53.4516 46.8693C53.5261 49.9478 56.1449 57.7846 47.2505 57.7846C40.6201 57.7846 42.1354 51.5046 41.7194 48.7956C41.5091 47.4323 40.6158 46.2712 39.3501 45.7391C36.6787 44.6177 32.5052 42.1021 32.3913 37.2998C32.2643 31.9873 37.7123 30.3293 41.9296 30.8966V30.901Z"
        fill="#F3D7E8"
      />
      <path
        d="M28.6121 40.0044C28.6121 40.0044 23.8255 38.2277 24.4999 43.7557C25.1744 49.2838 32.2207 48.9451 32.3871 46.7506C32.3871 46.7506 29.1158 44.5605 28.6121 40.0044Z"
        fill="#F3D7E8"
      />
      <path
        d="M63.2003 34.5248C63.2003 34.5248 63.7871 40.4267 62.4426 41.8603C62.4426 41.8603 67.3125 44.4726 68.9065 40.3431C70.5006 36.2136 66.8088 32.8405 63.1959 34.5248H63.2003Z"
        fill="#F3D7E8"
      />
      <path
        d="M39.946 41.8472C41.8543 41.8472 43.4013 40.2937 43.4013 38.3773C43.4013 36.461 41.8543 34.9075 39.946 34.9075C38.0377 34.9075 36.4907 36.461 36.4907 38.3773C36.4907 40.2937 38.0377 41.8472 39.946 41.8472Z"
        fill="#F9F6F3"
      />
      <path
        d="M39.9459 40.7388C41.2448 40.7388 42.2977 39.6815 42.2977 38.3772C42.2977 37.073 41.2448 36.0156 39.9459 36.0156C38.6471 36.0156 37.5942 37.073 37.5942 38.3772C37.5942 39.6815 38.6471 40.7388 39.9459 40.7388Z"
        fill="#6F3264"
      />
      <path
        d="M51.8794 41.3722C53.7877 41.3722 55.3347 39.8187 55.3347 37.9023C55.3347 35.986 53.7877 34.4325 51.8794 34.4325C49.9711 34.4325 48.4241 35.986 48.4241 37.9023C48.4241 39.8187 49.9711 41.3722 51.8794 41.3722Z"
        fill="#F9F6F3"
      />
      <path
        d="M51.8795 40.2639C53.1784 40.2639 54.2313 39.2065 54.2313 37.9023C54.2313 36.598 53.1784 35.5406 51.8795 35.5406C50.5807 35.5406 49.5278 36.598 49.5278 37.9023C49.5278 39.2065 50.5807 40.2639 51.8795 40.2639Z"
        fill="#6F3264"
      />
      <path
        d="M45.5603 48.2327C45.5603 48.2327 43.7341 47.6742 44.4261 48.844C45.118 50.0138 46.5369 50.3393 46.3486 49.4245C46.1603 48.5098 45.5603 48.2327 45.5603 48.2327Z"
        fill="#6F3264"
      />
      <path
        d="M49.5278 47.8632C49.5278 47.8632 48.0782 49.209 48.7395 49.8379C49.4008 50.4667 50.7846 48.1359 50.3467 47.8632C49.9088 47.5906 49.5278 47.8632 49.5278 47.8632Z"
        fill="#6F3264"
      />
      <path
        d="M46.6334 53.0701C46.8611 53.0965 47.102 53.1669 47.3428 53.0701C47.4085 53.0481 47.4961 53.0701 47.5662 53.0877C47.7808 53.1581 47.9997 53.1977 48.2187 53.1845C48.2493 53.1845 48.2888 53.1933 48.315 53.1845C48.6041 53.0657 48.9194 53.1185 49.2128 53.0174C49.4143 52.9514 49.6332 52.9074 49.7953 52.7579C49.8785 52.6699 50.0142 52.626 50.1193 52.56C50.4565 52.3797 50.785 52.1818 51.1178 51.9091C51.2317 51.83 51.3193 51.7464 51.3937 51.764C51.4375 51.7772 51.5295 51.6936 51.5645 51.7024C51.6127 51.7156 51.639 51.7728 51.6565 51.8871C51.6696 52.0015 51.674 52.173 51.6171 52.3753C51.5601 52.5556 51.4594 52.7183 51.3587 52.8766C51.1572 53.1757 50.9251 53.4835 50.5748 53.6067C50.4522 53.6462 50.3295 53.7518 50.2069 53.8222C49.8653 54.0377 49.4712 54.1344 49.0902 54.1916C48.9676 54.2092 48.8362 54.2136 48.7223 54.2443C48.4158 54.3367 48.0873 54.3631 47.7676 54.3499C47.6187 54.3455 47.4698 54.3323 47.3209 54.2971C47.1676 54.2531 46.9969 54.2663 46.8392 54.2399C46.4144 54.1784 45.994 54.1036 45.613 53.9233C45.1269 53.8441 44.6846 53.5671 44.2422 53.4044C44.1021 53.356 43.9576 53.2548 43.8306 53.1141C43.5372 52.7931 43.5021 52.7623 43.6817 52.7271C43.7255 52.7139 43.7824 52.7007 43.8481 52.6875C43.9357 52.6699 44.0408 52.6611 44.2028 52.7051C44.4306 52.7623 44.6714 52.7799 44.9035 52.7887C45.0918 52.7975 45.2714 52.7887 45.4466 52.8678C45.5123 52.8942 45.5911 52.8986 45.6611 52.903C45.994 52.9294 46.3093 52.9734 46.629 53.0701H46.6334Z"
        fill="#6F3264"
      />
      <path
        d="M44.9821 38.0255C45.0741 38.3069 45.1354 38.6148 45.2624 38.8962C45.2974 38.971 45.3193 39.0941 45.3281 39.1909C45.3631 39.4811 45.3981 39.7714 45.4463 40.0704C45.4551 40.1144 45.4551 40.1628 45.4682 40.2024C45.5777 40.607 45.5733 41.0291 45.6083 41.4381C45.6434 41.7284 45.6259 42.023 45.6784 42.3133C45.7003 42.476 45.6915 42.6695 45.6915 42.8498C45.6784 43.3907 45.6215 43.9273 45.5076 44.4594C45.4682 44.6221 45.4332 44.7629 45.3938 44.7804C45.3719 44.7892 45.3368 44.9256 45.3193 44.93C45.2755 44.9432 45.223 44.7101 45.1879 44.2879C45.1704 44.0988 45.166 43.9097 45.1616 43.7206C45.1441 43.36 45.1003 43.0037 45.1266 42.6387C45.1354 42.5068 45.1266 42.3485 45.1047 42.2077C45.0609 41.7988 45.0434 41.3986 45.0171 40.994C45.004 40.8664 44.9996 40.7301 44.9733 40.607C44.9076 40.2815 44.8463 39.9561 44.7894 39.6262C44.7587 39.4723 44.7281 39.3184 44.7062 39.1601C44.6799 38.993 44.623 38.8259 44.5836 38.6543C44.4697 38.2102 44.3252 37.7748 44.2113 37.3262C44.0055 36.8293 43.861 36.2795 43.6376 35.7914C43.5676 35.6419 43.5106 35.4484 43.4581 35.2593C43.3442 34.8107 43.3267 34.7579 43.4493 34.8942C43.4887 34.9338 43.5281 34.9778 43.5632 35.0174C43.6245 35.0833 43.6902 35.1713 43.7865 35.3428C43.9092 35.5891 44.0581 35.8178 44.1851 36.0509C44.2858 36.24 44.3996 36.4115 44.4697 36.6358C44.496 36.7149 44.5354 36.7985 44.5748 36.8776C44.7456 37.2383 44.8857 37.6121 44.9865 38.0255H44.9821Z"
        fill="#6F3264"
      />
      <path
        d="M47.3863 38.7686C47.4038 39.0105 47.417 39.2699 47.4958 39.5074C47.5177 39.5734 47.5221 39.6745 47.5221 39.7537C47.5221 39.9956 47.5352 40.2331 47.5746 40.4793C47.579 40.5145 47.579 40.5541 47.5877 40.5893C47.6929 40.9191 47.7016 41.2621 47.7848 41.592C47.8374 41.8251 47.8812 42.0625 47.9731 42.2868C48.0301 42.41 48.0651 42.5639 48.1001 42.709C48.2184 43.1356 48.3279 43.5666 48.433 44.0064C48.4636 44.1427 48.4899 44.2658 48.4636 44.2966C48.4461 44.3142 48.4724 44.4285 48.4549 44.4417C48.4198 44.4725 48.2797 44.3274 48.0739 44.0196C47.9906 43.8788 47.9206 43.7293 47.8418 43.5798C47.706 43.2939 47.5571 43.0125 47.5045 42.6958C47.487 42.5815 47.4301 42.4539 47.3907 42.3352C47.2724 41.9966 47.2067 41.6447 47.1454 41.2973C47.1279 41.183 47.1148 41.0686 47.0885 40.9631C47.0141 40.6816 46.9659 40.3958 46.9265 40.1011C46.9133 39.9604 46.8914 39.8285 46.8871 39.6877C46.8871 39.5426 46.8608 39.3843 46.8433 39.2348C46.8082 38.8346 46.7819 38.43 46.8126 38.0254C46.7863 37.5548 46.852 37.0623 46.8958 36.5917C46.9089 36.4466 46.9396 36.2751 47.0053 36.1123C47.1498 35.7341 47.1586 35.6946 47.1936 35.8529C47.2024 35.9013 47.2155 35.9496 47.2243 35.9936C47.2418 36.0728 47.2505 36.1651 47.2418 36.3278C47.2374 36.5609 47.2462 36.7852 47.2724 37.0095C47.2943 37.1898 47.3118 37.3569 47.3031 37.5504C47.3031 37.6208 47.3075 37.6955 47.3162 37.7703C47.3469 38.0957 47.3732 38.4256 47.3775 38.773L47.3863 38.7686Z"
        fill="#6F3264"
      />
      <path
        d="M43.3357 48.888C43.2831 48.7517 43.2481 48.5934 43.1605 48.4614C43.1342 48.4263 43.1298 48.3603 43.1254 48.3119C43.1254 48.1228 43.1167 47.9689 43.1167 47.8282C43.1167 47.8018 43.1254 47.7754 43.1167 47.749C43.0773 47.4983 43.2262 47.2037 43.4408 47.0102C43.594 46.8694 43.778 46.8167 43.9488 46.7463C44.0451 46.7067 44.1677 46.7067 44.2772 46.7199C44.61 46.7551 44.8597 46.9266 45.0655 47.1069C45.1268 47.1685 45.175 47.2257 45.1662 47.2609C45.1618 47.2828 45.21 47.3312 45.2013 47.3488C45.1881 47.3928 45.0611 47.3972 44.8553 47.3708C44.7677 47.3532 44.6845 47.3312 44.6013 47.3224C44.4436 47.296 44.3079 47.318 44.1765 47.2872C44.1283 47.2784 44.0714 47.3004 44.0276 47.318C43.8962 47.3796 43.8042 47.4324 43.7342 47.5335C43.7123 47.5643 43.6904 47.6083 43.6904 47.6523C43.6904 47.771 43.6991 47.9029 43.7167 48.0481C43.7254 48.1184 43.7429 48.1844 43.7429 48.2635C43.7429 48.3427 43.778 48.4219 43.7999 48.4966C43.8568 48.7033 43.94 48.8968 43.9926 49.1079C44.1196 49.3234 44.1896 49.5829 44.3254 49.794C44.3648 49.8599 44.4042 49.9435 44.4217 50.0446C44.4655 50.2777 44.4874 50.2953 44.3911 50.2601C44.3604 50.2469 44.3341 50.2382 44.3079 50.225C44.2641 50.203 44.2159 50.1766 44.1546 50.1018C44.0714 49.9963 43.9663 49.9083 43.8743 49.8116C43.7955 49.7368 43.721 49.6576 43.6772 49.5521C43.6597 49.5125 43.6334 49.4773 43.6072 49.4421C43.4889 49.2794 43.397 49.0991 43.3444 48.8924L43.3357 48.888Z"
        fill="#6F3264"
      />
      <path
        d="M50.0051 46.1921C50.2197 46.1965 50.4343 46.2668 50.6533 46.302C50.7146 46.3108 50.7934 46.3592 50.846 46.4032C51.0124 46.5307 51.1569 46.6758 51.3014 46.8297C51.3233 46.8517 51.3408 46.8825 51.3627 46.9001C51.5904 47.0936 51.6824 47.4015 51.7656 47.6609C51.8269 47.8544 51.8357 48.0523 51.8795 48.2458C51.9058 48.3558 51.897 48.4833 51.8795 48.602C51.8488 48.9583 51.7131 49.2925 51.5466 49.5915C51.4853 49.6795 51.4284 49.7543 51.3934 49.7543C51.3715 49.7543 51.3189 49.8202 51.3014 49.8202C51.2576 49.8114 51.2532 49.6619 51.262 49.4024C51.2751 49.2925 51.2883 49.1782 51.2926 49.0682C51.3102 48.8571 51.2839 48.6548 51.3233 48.4525C51.3364 48.3778 51.3233 48.2898 51.3058 48.215C51.262 47.9952 51.2226 47.7797 51.1394 47.5774C51.1175 47.5158 51.0868 47.4498 51.043 47.3971C50.9248 47.2651 50.8022 47.142 50.662 47.032C50.5963 46.9837 50.535 46.9441 50.4606 46.8869C50.3861 46.8341 50.2942 46.8121 50.2109 46.7902C49.9788 46.7242 49.7555 46.7418 49.5365 46.7902C49.3 46.9089 49.0373 47.0716 48.8446 47.3179C48.7833 47.3883 48.7044 47.4762 48.5993 47.5466C48.3629 47.7137 48.3497 47.7445 48.3629 47.6257C48.3672 47.5906 48.3716 47.551 48.376 47.5158C48.3848 47.4586 48.4023 47.3883 48.468 47.2871C48.5556 47.1464 48.63 46.9881 48.7263 46.8429C48.8008 46.7242 48.884 46.6098 49.0198 46.5219C49.0679 46.4911 49.1161 46.4471 49.1643 46.4119C49.3876 46.2448 49.6942 46.1481 50.0007 46.1877L50.0051 46.1921Z"
        fill="#6F3264"
      />
      <path
        d="M38.9781 42.4672C39.1708 42.4848 39.381 42.52 39.5825 42.4672C39.635 42.454 39.7182 42.4584 39.7795 42.4672C39.9722 42.4892 40.1649 42.4936 40.362 42.4672C40.3926 42.4628 40.4233 42.4672 40.4496 42.454C40.7123 42.3616 40.9882 42.3484 41.251 42.2517C41.4349 42.1857 41.6232 42.1286 41.7853 42.0142C41.8729 41.9482 41.9911 41.8999 42.0962 41.8427C42.4159 41.6756 42.7268 41.4909 43.0421 41.2842C43.1429 41.2226 43.2305 41.1654 43.2655 41.1786C43.2874 41.1874 43.3706 41.1347 43.3881 41.1434C43.4275 41.161 43.3794 41.3326 43.1954 41.6008C43.1078 41.7152 43.0071 41.8207 42.9064 41.9219C42.7093 42.111 42.5035 42.3089 42.2407 42.41C42.1444 42.4452 42.048 42.5156 41.9517 42.5683C41.6758 42.7266 41.378 42.819 41.0758 42.8894C40.9795 42.9114 40.8787 42.9289 40.7868 42.9553C40.5459 43.0345 40.2919 43.0741 40.0335 43.0829C39.9109 43.0917 39.7927 43.0917 39.67 43.0829C39.543 43.0653 39.4029 43.0829 39.2715 43.0653C38.9212 43.0389 38.5664 42.9861 38.238 42.8586C37.8307 42.775 37.4541 42.5551 37.095 42.3572C36.9855 42.2957 36.8629 42.1989 36.7665 42.0802C36.5432 41.8031 36.5257 41.7767 36.657 41.8119C36.7008 41.8207 36.7403 41.8339 36.7797 41.8427C36.8454 41.8603 36.9198 41.8823 37.0424 41.9526C37.2132 42.0406 37.3972 42.1066 37.5767 42.1505C37.7212 42.1901 37.8614 42.2121 38.0059 42.2781C38.0584 42.3001 38.1198 42.3133 38.1811 42.3221C38.4745 42.3748 38.7022 42.4188 38.9869 42.4716L38.9781 42.4672Z"
        fill="#6F3264"
      />
      <path
        d="M51.3934 42.1461C51.5861 42.2033 51.7919 42.278 52.0021 42.2428C52.0591 42.2384 52.1379 42.2516 52.2036 42.2648C52.3963 42.3132 52.5934 42.3308 52.7948 42.3088C52.8255 42.3088 52.8605 42.3088 52.8868 42.3C53.1583 42.2165 53.443 42.1989 53.7057 42.0889C53.8896 42.0142 54.078 41.9394 54.2356 41.8075C54.3188 41.7283 54.4371 41.6667 54.5334 41.5964C54.8356 41.3853 55.1202 41.139 55.3874 40.8531C55.475 40.7696 55.545 40.686 55.5845 40.6904C55.6064 40.6904 55.6764 40.6157 55.6939 40.6201C55.7334 40.6245 55.7334 40.8136 55.6064 41.1346C55.5407 41.2709 55.4575 41.4029 55.3699 41.5304C55.1991 41.7679 55.0064 42.0054 54.7436 42.1505C54.6473 42.1989 54.5509 42.2868 54.4502 42.3484C54.1699 42.5419 53.8546 42.6606 53.5349 42.7398C53.4298 42.7618 53.3291 42.7838 53.224 42.8102C52.9612 42.8893 52.6853 42.9157 52.4094 42.9069C52.278 42.9069 52.151 42.8893 52.0197 42.8673C51.8883 42.8321 51.7394 42.8233 51.5992 42.7882C51.2314 42.7002 50.8723 42.5683 50.5613 42.3484C50.1628 42.1549 49.8387 41.8075 49.5497 41.4776C49.4621 41.3721 49.3745 41.2314 49.3264 41.0774C49.2125 40.7168 49.2125 40.6816 49.3264 40.7784C49.3614 40.8048 49.3964 40.8356 49.4271 40.8575C49.4796 40.9059 49.5453 40.9543 49.6329 41.0686C49.7643 41.2226 49.922 41.3501 50.084 41.4556C50.211 41.5436 50.3424 41.6096 50.4694 41.7151C50.5175 41.7503 50.5745 41.7855 50.6314 41.8075C50.881 41.9306 51.135 42.0361 51.4022 42.1461H51.3934Z"
        fill="#6F3264"
      />
      <path
        d="M35.9562 34.2741C36.1183 34.0586 36.3241 33.8563 36.4993 33.6232C36.5474 33.5617 36.6394 33.4913 36.7182 33.4429C36.9503 33.2934 37.1956 33.1659 37.454 33.0427C37.4934 33.0251 37.5372 33.0119 37.5722 32.9943C37.9182 32.792 38.3211 32.7525 38.6977 32.7085C38.9648 32.6733 39.2364 32.6909 39.5035 32.6645C39.6524 32.6513 39.8276 32.6733 39.9852 32.6953C40.467 32.7613 40.9312 32.8888 41.3735 33.0603C41.5136 33.1131 41.6275 33.1659 41.6406 33.2054C41.645 33.2274 41.7589 33.2802 41.7589 33.2978C41.7633 33.3462 41.553 33.3682 41.1808 33.3594C41.0144 33.3506 40.848 33.333 40.6816 33.3198C40.3662 33.2934 40.0553 33.3022 39.7444 33.2406C39.6349 33.2186 39.4991 33.2274 39.3765 33.2318C39.0262 33.2494 38.6889 33.2626 38.3561 33.3242C38.251 33.3418 38.1372 33.3638 38.0452 33.4077C37.7912 33.5177 37.5459 33.6364 37.3138 33.7859C37.2044 33.8563 37.1036 33.9355 36.9898 34.0102C36.8715 34.0894 36.7752 34.2037 36.6701 34.3093C36.3986 34.5907 36.1796 34.9118 35.9431 35.2372C35.7285 35.6506 35.4701 36.0684 35.2862 36.5082C35.2293 36.6445 35.1461 36.7984 35.041 36.9435C34.8045 37.2822 34.7826 37.3218 34.7913 37.159C34.7913 37.1063 34.7957 37.0579 34.8001 37.0095C34.8045 36.926 34.8176 36.8336 34.8745 36.6665C34.9534 36.4378 35.0103 36.2047 35.076 35.976C35.1285 35.7913 35.1767 35.6154 35.2818 35.4307C35.3212 35.3647 35.3475 35.2856 35.3782 35.2152C35.5183 34.8854 35.7022 34.5643 35.9562 34.2697V34.2741Z"
        fill="#6F3264"
      />
      <path
        d="M51.5949 31.974C51.8227 31.9608 52.0591 31.9784 52.3 31.9476C52.3657 31.9388 52.4577 31.9564 52.5321 31.9784C52.7511 32.04 52.9657 32.1147 53.189 32.1807C53.2241 32.1895 53.2547 32.2115 53.2854 32.2159C53.6051 32.2775 53.8766 32.4622 54.1525 32.6161C54.3495 32.726 54.5203 32.8712 54.7218 32.9811C54.8313 33.0471 54.9408 33.1482 55.0371 33.245C55.3349 33.5352 55.5758 33.8826 55.7378 34.2609C55.7904 34.3796 55.821 34.4851 55.7947 34.5159C55.7816 34.5335 55.8122 34.6347 55.7947 34.6479C55.7597 34.683 55.6239 34.5687 55.3962 34.362C55.2955 34.2653 55.2035 34.1729 55.1028 34.0762C54.9145 33.9002 54.7043 33.7595 54.5335 33.5616C54.4678 33.4956 54.3758 33.4297 54.2882 33.3813C54.043 33.2274 53.8109 33.0691 53.5569 32.9327C53.4737 32.8931 53.3948 32.8404 53.3073 32.8184C53.0839 32.7524 52.8606 32.6865 52.6328 32.6337C52.5234 32.6073 52.4183 32.5941 52.3088 32.5633C52.1949 32.5369 52.0679 32.5369 51.9453 32.5325C51.6212 32.5193 51.3015 32.5501 50.9731 32.5721C50.6052 32.6645 50.2111 32.726 49.8651 32.8887C49.76 32.9415 49.6242 32.9811 49.4841 33.0163C49.1556 33.0998 49.1206 33.1218 49.2038 33.0075C49.2301 32.9723 49.2564 32.9371 49.2783 32.9019C49.3177 32.8448 49.379 32.792 49.5016 32.7084C49.6768 32.6073 49.8432 32.4886 50.0227 32.3874C50.1629 32.3038 50.303 32.2247 50.4738 32.1895C50.5351 32.1763 50.6008 32.1455 50.6665 32.1279C50.9512 32.0268 51.2577 31.9652 51.5818 31.974H51.5949Z"
        fill="#6F3264"
      />
      <path
        d="M25.7567 41.9526C25.6559 42.1593 25.5684 42.41 25.5946 42.6562C25.6034 42.7222 25.5859 42.819 25.5771 42.8981C25.5421 43.1356 25.5421 43.3863 25.5946 43.6369C25.6034 43.6765 25.6034 43.7161 25.6165 43.7513C25.7392 44.0855 25.8136 44.4329 25.9888 44.7496C26.107 44.9695 26.234 45.1938 26.4136 45.3741C26.5143 45.4708 26.615 45.6028 26.7201 45.7171C27.0354 46.0557 27.3858 46.3724 27.7843 46.6406C27.9069 46.7286 28.0164 46.7989 28.0252 46.8385C28.0252 46.8605 28.1347 46.9309 28.1347 46.9485C28.1347 46.9881 27.9113 47.0012 27.5128 46.8781C27.3376 46.8165 27.1712 46.7286 27.0048 46.6362C26.6939 46.4559 26.3873 46.2536 26.1683 45.9546C26.0939 45.849 25.9756 45.7391 25.8881 45.6247C25.6209 45.3081 25.4326 44.9431 25.2925 44.5605C25.2443 44.4417 25.2136 44.3054 25.1655 44.1911C25.0472 43.8788 24.9771 43.5446 24.9596 43.2016C24.9509 43.0388 24.9509 42.8805 24.9815 42.7134C25.0078 42.5419 25.0253 42.3572 25.0779 42.1813C25.2049 41.7151 25.472 41.2621 25.8881 40.9807C26.107 40.7916 26.3873 40.6552 26.6939 40.6069C27.0004 40.5629 27.307 40.5981 27.5829 40.6992C27.758 40.7564 27.9463 40.8839 28.0733 41.0598C28.2222 41.2621 28.2573 41.3941 28.266 41.4468C28.2748 41.4996 28.2485 41.4732 28.1785 41.4161C28.1478 41.3897 28.0996 41.3589 28.0515 41.3237C27.977 41.2797 27.8982 41.2226 27.7493 41.139C27.5391 41.0159 27.2938 40.9895 27.0792 41.0071C26.8997 41.0291 26.7508 41.0774 26.5756 41.1346C26.5099 41.1566 26.4486 41.1962 26.3961 41.2358C26.1421 41.4205 25.9362 41.6667 25.7654 41.957L25.7567 41.9526Z"
        fill="#6F3264"
      />
      <path
        d="M28.1741 44.2747C28.1741 44.3407 28.1609 44.4199 28.2266 44.4858C28.2442 44.5034 28.2442 44.5298 28.2398 44.5518C28.2266 44.6222 28.2266 44.6969 28.2529 44.7673C28.2529 44.7761 28.2529 44.7937 28.2617 44.7981C28.3493 44.8684 28.3493 44.9872 28.4193 45.0707C28.4675 45.1279 28.5025 45.1983 28.5814 45.2247C28.6252 45.2379 28.6558 45.273 28.6908 45.3038C28.7959 45.3918 28.8967 45.4973 29.0324 45.5853C29.0718 45.6205 29.1113 45.6469 29.1156 45.6864C29.1156 45.7084 29.1594 45.7348 29.1594 45.7524C29.1638 45.792 29.0894 45.8667 28.923 45.9019C28.8485 45.9107 28.7741 45.9019 28.6996 45.8975C28.5638 45.8711 28.4062 45.8492 28.3142 45.7304C28.2792 45.6908 28.2223 45.6644 28.1785 45.6293C28.0427 45.5413 27.9507 45.405 27.8807 45.2642C27.8588 45.2203 27.8369 45.1719 27.8106 45.1323C27.7318 45.0268 27.6748 44.908 27.6486 44.7761C27.631 44.7145 27.6223 44.653 27.6267 44.587C27.6267 44.521 27.6135 44.4507 27.6135 44.3803C27.6135 44.2 27.6486 43.9845 27.7624 43.8306C27.8369 43.6151 28.0427 43.4568 28.2617 43.3512C28.3317 43.3204 28.4325 43.3072 28.5244 43.3424C28.7259 43.4436 28.7127 43.4612 28.6471 43.5007C28.6295 43.5139 28.612 43.5227 28.5901 43.5447C28.5595 43.5711 28.5332 43.5931 28.4806 43.6107C28.4106 43.6415 28.3624 43.7118 28.3317 43.7778C28.3055 43.835 28.2967 43.8877 28.2573 43.9317C28.2442 43.9449 28.2398 43.9713 28.2354 43.9933C28.2179 44.0944 28.2047 44.1868 28.1741 44.2704V44.2747Z"
        fill="#6F3264"
      />
      <path
        d="M67.8378 35.8529C68.0743 36.0596 68.267 36.3279 68.4772 36.5742C68.5342 36.6401 68.5911 36.7633 68.6261 36.86C68.7312 37.1547 68.8013 37.4537 68.8539 37.7704C68.8626 37.8187 68.8626 37.8671 68.8714 37.9111C68.9721 38.3465 68.8801 38.7951 68.7619 39.2128C68.6787 39.5119 68.5254 39.7846 68.3853 40.0616C68.3064 40.2155 68.1751 40.3739 68.0437 40.5058C67.6495 40.9148 67.1415 41.205 66.6029 41.315C66.4365 41.3458 66.2876 41.359 66.2613 41.3238C66.2438 41.3062 66.108 41.3106 66.0992 41.293C66.073 41.249 66.2832 41.1567 66.6204 40.95C66.7693 40.8576 66.9226 40.7697 67.0539 40.6597C67.3167 40.4574 67.5225 40.2067 67.7546 39.9737C67.8378 39.8901 67.9079 39.7626 67.9692 39.6482C68.1356 39.3184 68.2626 38.9842 68.3196 38.6191C68.3371 38.5048 68.3502 38.3817 68.3415 38.2673C68.3108 37.9683 68.2539 37.6736 68.1619 37.3834C68.1181 37.247 68.0612 37.1195 68.0086 36.9832C67.9473 36.8424 67.851 36.7105 67.7546 36.5874C67.505 36.2531 67.1722 36.0069 66.8131 35.7958C66.3664 35.6111 65.8628 35.4571 65.3723 35.4967C65.219 35.5055 65.0438 35.5143 64.8599 35.5143C64.4307 35.5407 64.3782 35.5759 64.5183 35.4615C64.5533 35.4351 64.5971 35.3956 64.6497 35.3648C64.7241 35.3164 64.8205 35.2636 65.0088 35.2153C65.2715 35.1537 65.5387 35.1053 65.8102 35.0921C66.0248 35.0833 66.2394 35.0789 66.4715 35.1537C66.5547 35.1757 66.6467 35.1977 66.7343 35.2197C67.1284 35.3252 67.5182 35.5275 67.8335 35.8441L67.8378 35.8529Z"
        fill="#6F3264"
      />
      <path
        d="M65.889 38.0035C65.9722 38.1222 66.0248 38.2585 66.1211 38.3905C66.1518 38.4256 66.1562 38.4916 66.1518 38.54C66.1518 38.6939 66.1211 38.8434 66.0949 38.993C66.0905 39.0149 66.0773 39.0369 66.0773 39.0589C66.0686 39.2788 65.9197 39.4415 65.8014 39.5955C65.7182 39.7098 65.6 39.7802 65.5124 39.8945C65.4599 39.9517 65.3767 39.9957 65.2978 40.0264C65.0657 40.132 64.7854 40.1408 64.549 40.0616C64.4745 40.0352 64.4176 39.9913 64.4176 39.9561C64.4176 39.9341 64.365 39.9033 64.3694 39.8857C64.3694 39.8373 64.4789 39.8021 64.6146 39.7318C64.676 39.7054 64.7373 39.6878 64.7898 39.657C64.8993 39.6042 64.9781 39.5207 65.0964 39.4943C65.1358 39.4811 65.1752 39.4459 65.2015 39.4063C65.2891 39.3096 65.381 39.226 65.4555 39.1249C65.4774 39.0897 65.508 39.0589 65.5124 39.0193C65.5299 38.9182 65.5387 38.8214 65.5431 38.7247C65.5431 38.6763 65.5343 38.6367 65.5431 38.584C65.5431 38.5312 65.5168 38.4828 65.4905 38.4344C65.4336 38.3025 65.3372 38.197 65.2497 38.0738C65.1051 37.9859 64.9694 37.8539 64.7986 37.8188C64.746 37.81 64.6935 37.788 64.6278 37.744C64.4745 37.6516 64.4438 37.6648 64.5052 37.5989C64.5183 37.5813 64.5402 37.5637 64.5621 37.5461C64.5971 37.5153 64.6409 37.4977 64.7197 37.4889C64.8336 37.4889 64.9562 37.4713 65.0745 37.4845C65.1708 37.4889 65.2715 37.5021 65.3591 37.5637C65.3898 37.5857 65.4336 37.5989 65.4686 37.6165C65.635 37.6912 65.7883 37.8188 65.8847 37.9991L65.889 38.0035Z"
        fill="#6F3264"
      />
      <path
        d="M67.9606 88.1908C67.4657 87.7818 39.4904 72.4116 34.2615 75.6044C20.6373 83.925 48.49 101.538 48.49 101.538C48.49 101.538 41.321 98.6047 42.7968 103.328C44.2727 108.051 51.1964 108.856 53.9817 105.676C56.7626 102.501 41.2465 87.7114 41.2465 87.7114C41.2465 87.7114 68.4598 112.431 73.1063 103.653C76.4696 97.2986 67.9562 88.1908 67.9562 88.1908H67.9606Z"
        fill="#F26D51"
      />
      <path
        d="M38.921 99.2513C39.0348 99.2997 39.1443 99.3701 39.2844 99.3701C39.3195 99.3701 39.3633 99.4009 39.3983 99.4272C39.4946 99.5064 39.591 99.5812 39.7049 99.6339C39.7224 99.6427 39.7355 99.6559 39.753 99.6603C39.9282 99.6955 40.0552 99.8274 40.2085 99.9066C40.318 99.9594 40.4143 100.039 40.5369 100.065C40.607 100.078 40.6727 100.126 40.734 100.166C40.9267 100.285 41.0975 100.434 41.2683 100.597C41.3165 100.654 41.3602 100.707 41.3515 100.742C41.3471 100.764 41.3865 100.808 41.3821 100.826C41.369 100.865 41.2551 100.883 41.0625 100.865C40.9836 100.848 40.9004 100.826 40.8216 100.804C40.6727 100.755 40.515 100.729 40.3968 100.623C40.353 100.584 40.2873 100.566 40.2304 100.544C40.064 100.483 39.9194 100.39 39.7749 100.298C39.7268 100.267 39.683 100.232 39.6304 100.214C39.4946 100.17 39.3676 100.109 39.2406 100.039C39.1793 100.008 39.1224 99.977 39.0655 99.933C39.0085 99.889 38.9341 99.867 38.8684 99.8362C38.6932 99.7483 38.5093 99.6735 38.3473 99.5548C38.1283 99.48 37.9356 99.3217 37.7298 99.2249C37.6685 99.1898 37.5984 99.1414 37.5415 99.0666C37.4145 98.8907 37.3926 98.8863 37.4845 98.8687C37.5108 98.8643 37.5415 98.8599 37.5677 98.8555C37.6115 98.8511 37.6641 98.8467 37.7385 98.8775C37.848 98.9171 37.9663 98.9303 38.0801 98.9523C38.1721 98.9655 38.264 98.9743 38.3473 99.0226C38.3779 99.0402 38.4173 99.049 38.4524 99.0578C38.6188 99.0974 38.7764 99.1546 38.9253 99.2469L38.921 99.2513Z"
        fill="#6F3264"
      />
      <path
        d="M38.5533 104.524C38.6497 104.515 38.7548 104.533 38.8599 104.476C38.8862 104.458 38.9299 104.467 38.9606 104.476C39.0569 104.502 39.1533 104.529 39.254 104.529C39.2672 104.529 39.2847 104.537 39.2978 104.529C39.438 104.485 39.5737 104.551 39.7095 104.551C39.8058 104.551 39.9022 104.581 40.0029 104.551C40.0554 104.533 40.1211 104.551 40.1824 104.559C40.362 104.581 40.5372 104.643 40.7167 104.718C40.7737 104.749 40.8174 104.775 40.8262 104.81C40.8262 104.832 40.8744 104.854 40.8744 104.876C40.8744 104.92 40.7999 104.986 40.6554 105.048C40.5897 105.07 40.524 105.083 40.4583 105.096C40.3357 105.118 40.2087 105.162 40.0861 105.118C40.0423 105.105 39.9854 105.118 39.9372 105.118C39.7971 105.136 39.6569 105.114 39.5212 105.092C39.4774 105.087 39.4336 105.074 39.3898 105.078C39.2759 105.096 39.1621 105.096 39.0482 105.092C38.9956 105.092 38.9431 105.092 38.8862 105.074C38.8292 105.061 38.7679 105.074 38.7066 105.074C38.5489 105.074 38.3913 105.083 38.2336 105.048C38.0497 105.074 37.857 105.017 37.6731 105.017C37.6161 105.017 37.5505 104.999 37.4848 104.955C37.3315 104.854 37.314 104.858 37.3797 104.801C37.3972 104.784 37.4191 104.771 37.4366 104.753C37.4672 104.731 37.5023 104.705 37.568 104.696C37.6599 104.687 37.7519 104.647 37.8439 104.612C37.9139 104.586 37.984 104.555 38.0628 104.559C38.0891 104.559 38.1241 104.551 38.1504 104.542C38.2818 104.507 38.4176 104.489 38.5577 104.507L38.5533 104.524Z"
        fill="#6F3264"
      />
      <path
        d="M38.7632 101.617C38.8246 101.617 38.8859 101.648 38.9603 101.604C38.9822 101.591 39.0041 101.604 39.0216 101.617C39.0742 101.657 39.1311 101.688 39.1924 101.701C39.2012 101.701 39.2099 101.714 39.2187 101.71C39.315 101.683 39.3851 101.758 39.4683 101.776C39.5296 101.789 39.5822 101.828 39.6479 101.811C39.6829 101.802 39.7223 101.824 39.7574 101.837C39.8625 101.881 39.9588 101.96 40.0508 102.053C40.0771 102.088 40.1033 102.123 40.0989 102.158C40.0989 102.18 40.1208 102.207 40.1165 102.224C40.1121 102.268 40.0508 102.321 39.9457 102.369C39.9019 102.383 39.8581 102.387 39.8143 102.396C39.7355 102.405 39.6479 102.435 39.5822 102.378C39.5603 102.361 39.5209 102.365 39.4902 102.365C39.3983 102.365 39.3194 102.33 39.2406 102.295C39.2143 102.286 39.188 102.268 39.1618 102.268C39.0873 102.273 39.0172 102.264 38.9472 102.242C38.9165 102.233 38.8815 102.229 38.8508 102.207C38.8202 102.185 38.7764 102.193 38.7413 102.185C38.645 102.167 38.5443 102.158 38.4567 102.105C38.3384 102.114 38.2333 102.035 38.1195 102.013C38.0844 102.004 38.045 101.982 38.0144 101.934C37.9443 101.815 37.9312 101.82 37.9793 101.771C37.9925 101.758 38.01 101.745 38.0231 101.732C38.045 101.71 38.0757 101.692 38.1151 101.692C38.172 101.692 38.2377 101.666 38.299 101.644C38.3472 101.626 38.3998 101.6 38.4436 101.617C38.4611 101.622 38.483 101.617 38.5005 101.613C38.5881 101.591 38.6757 101.591 38.7589 101.622L38.7632 101.617Z"
        fill="#6F3264"
      />
      <path
        d="M40.5544 107.251C40.6507 107.238 40.7558 107.251 40.8566 107.189C40.8828 107.172 40.9266 107.18 40.9573 107.189C41.0536 107.216 41.15 107.233 41.2507 107.233C41.2639 107.233 41.2814 107.242 41.2945 107.233C41.4346 107.185 41.5704 107.242 41.7105 107.242C41.8069 107.242 41.9032 107.264 42.004 107.233C42.0565 107.216 42.1222 107.229 42.1835 107.233C42.3631 107.251 42.5426 107.304 42.7222 107.37C42.7791 107.396 42.8273 107.422 42.8317 107.457C42.8317 107.479 42.8798 107.501 42.8842 107.519C42.8886 107.563 42.8141 107.629 42.674 107.699C42.6083 107.721 42.547 107.739 42.4813 107.757C42.3587 107.783 42.2361 107.836 42.1091 107.792C42.0653 107.779 42.0083 107.792 41.9602 107.801C41.82 107.823 41.6799 107.805 41.5441 107.792C41.5003 107.787 41.4522 107.779 41.4127 107.787C41.2989 107.809 41.185 107.814 41.0712 107.814C41.0186 107.814 40.9661 107.814 40.9091 107.801C40.8522 107.787 40.7909 107.801 40.7296 107.801C40.5719 107.801 40.4143 107.823 40.2566 107.792C40.0727 107.827 39.88 107.774 39.696 107.783C39.6391 107.783 39.569 107.77 39.5034 107.73C39.3457 107.633 39.3326 107.638 39.3895 107.581C39.407 107.563 39.4245 107.545 39.442 107.532C39.4727 107.506 39.5077 107.484 39.5734 107.471C39.6654 107.457 39.7574 107.414 39.8449 107.378C39.915 107.348 39.9851 107.312 40.0639 107.321C40.0902 107.321 40.1208 107.312 40.1515 107.304C40.2829 107.264 40.4143 107.238 40.5588 107.251H40.5544Z"
        fill="#6F3264"
      />
      <path
        d="M81.668 105.056C78.5061 105.241 75.3618 104.705 72.5853 103.429C71.0744 102.739 70.4131 100.949 71.1007 99.4316C71.7882 97.9144 73.575 97.2504 75.0815 97.9408C78.8565 99.6735 83.6825 99.4009 87.6765 97.2328C91.4515 95.1834 94.6178 91.4277 96.3651 86.92C97.968 82.7949 98.5066 77.8737 97.9242 72.6888C97.403 68.0315 96.0936 64.1747 94.0353 61.215C91.8237 58.0398 88.5787 55.6122 85.443 53.3957C85.027 53.1011 84.6066 52.8064 84.1862 52.5117C81.0462 50.3129 77.7967 48.0348 75.0508 45.0619C71.2539 40.9544 69.2088 36.1564 69.292 31.5563C69.3664 27.5939 71.0218 23.7327 73.8421 20.9621C76.6624 18.1915 80.5382 16.6083 84.4883 16.6127C86.1481 16.6127 87.4882 17.9716 87.4838 19.6384C87.4838 21.3051 86.1218 22.6508 84.4708 22.6464C82.1235 22.6376 79.7236 23.6227 78.0463 25.2719C76.369 26.9211 75.3443 29.3135 75.3005 31.6663C75.226 35.7562 77.8711 39.2436 79.4565 40.9588C81.7644 43.4567 84.611 45.4533 87.624 47.5642C88.0487 47.8633 88.4779 48.1623 88.9027 48.4614C92.3186 50.8758 96.19 53.7827 98.9577 57.7539C101.598 61.5448 103.258 66.3428 103.893 72.0115C104.581 78.164 103.915 84.079 101.962 89.1101C99.6715 95.0163 95.6119 99.7835 90.5275 102.541C87.7685 104.036 84.7073 104.881 81.6637 105.056H81.668Z"
        fill="#F26D51"
      />
      <path
        d="M43.6421 61.47C43.6421 61.47 31.3099 67.8248 26.7072 70.9868L26.7817 59.2051C26.7817 59.2051 26.7817 53.8179 23.3658 56.0651C21.6403 57.2041 20.344 76.6775 21.4783 78.4498C22.6125 80.2221 39.3591 73.8981 39.3591 73.8981C39.3591 73.8981 49.0638 71.0747 49.4973 67.3014C49.9309 63.5281 43.6378 61.4744 43.6378 61.4744L43.6421 61.47Z"
        fill="#F26D51"
      />
      <path
        d="M57.4632 82.3637C58.0194 82.5572 58.5931 82.8167 59.1975 82.9311C59.3595 82.9618 59.5829 83.0674 59.7536 83.1597C60.2748 83.4324 60.7916 83.7007 61.3477 83.9425C61.4309 83.9777 61.5141 84.0305 61.593 84.0525C62.39 84.3076 63.0995 84.7561 63.8483 85.108C64.3782 85.3542 64.8775 85.6621 65.438 85.86C65.7446 85.9743 66.0774 86.1722 66.3927 86.3437C67.3343 86.8715 68.2452 87.4564 69.1123 88.1028C69.3838 88.3051 69.6072 88.4942 69.6072 88.5822C69.6072 88.6306 69.8218 88.8065 69.813 88.8461C69.7999 88.934 69.3313 88.8461 68.5255 88.547C68.1664 88.4107 67.8116 88.2524 67.4613 88.094C66.7869 87.8038 66.0993 87.5443 65.4731 87.1441C65.2497 87.0034 64.9519 86.8759 64.6804 86.7615C63.914 86.4317 63.1914 86.0491 62.4557 85.6709C62.2192 85.5521 61.9827 85.4202 61.7419 85.3322C61.1112 85.0904 60.4894 84.8309 59.8631 84.5582C59.5653 84.4307 59.2763 84.312 58.9829 84.158C58.6807 84.0041 58.3304 83.8942 58.0019 83.7622C57.1304 83.4148 56.2502 83.1114 55.3874 82.7244C54.3408 82.4121 53.2941 81.9284 52.2474 81.6029C51.9234 81.5018 51.5555 81.3347 51.2008 81.1456C50.3775 80.6882 50.2855 80.6486 50.6534 80.653C50.7628 80.653 50.8767 80.653 50.9906 80.653C51.1745 80.653 51.3935 80.6794 51.7745 80.7761C52.3044 80.9213 52.843 81.0136 53.3686 81.1192C53.789 81.2071 54.1962 81.2687 54.6342 81.4402C54.7918 81.5018 54.9758 81.5414 55.1422 81.5853C55.913 81.7788 56.6793 82.0163 57.4589 82.3505L57.4632 82.3637Z"
        fill="#6F3264"
      />
      <path
        d="M46.2915 70.0897C46.4448 70.0061 46.6287 69.9446 46.7382 69.7599C46.7689 69.7115 46.839 69.6895 46.8959 69.6807C47.0711 69.6499 47.2462 69.6103 47.4039 69.5224C47.4258 69.5092 47.4608 69.5092 47.4783 69.4872C47.6491 69.2849 47.9207 69.2673 48.1309 69.131C48.2798 69.0386 48.4506 68.9859 48.5644 68.8363C48.6257 68.7528 48.7352 68.7088 48.8316 68.656C49.1206 68.5065 49.4315 68.3834 49.7775 68.291C49.887 68.2646 49.9921 68.2514 50.0446 68.2954C50.0797 68.3262 50.176 68.3086 50.2023 68.335C50.268 68.3966 50.2461 68.5945 50.1191 68.8715C50.0578 68.9903 49.9702 69.0826 49.8914 69.1838C49.7337 69.3685 49.5979 69.5884 49.3527 69.6587C49.2651 69.6851 49.195 69.7687 49.1206 69.8302C48.9148 70.0149 48.6695 70.1249 48.4199 70.2348C48.3411 70.27 48.2535 70.2964 48.1878 70.3492C48.017 70.4943 47.8243 70.6086 47.6229 70.701C47.5265 70.745 47.4345 70.7933 47.3294 70.8197C47.22 70.8417 47.1236 70.9209 47.0185 70.9693C46.7426 71.1012 46.4711 71.2463 46.1645 71.2991C45.8536 71.4882 45.4638 71.5102 45.131 71.6245C45.0259 71.6597 44.8901 71.6641 44.7456 71.6157C44.4128 71.5146 44.3821 71.5102 44.4653 71.3783C44.4916 71.3343 44.5135 71.2947 44.5398 71.2551C44.5792 71.1935 44.6318 71.1276 44.7456 71.0792C44.8989 71 45.039 70.8857 45.1704 70.7626C45.2755 70.6658 45.3719 70.5647 45.5076 70.5295C45.5558 70.5163 45.604 70.4767 45.6434 70.4415C45.8405 70.2832 46.0463 70.1557 46.2872 70.0809L46.2915 70.0897Z"
        fill="#6F3264"
      />
    </g>
    <defs>
      <clipPath id="clip0_1019_6466">
        <rect
          width="84.32"
          height="94.24"
          fill="white"
          transform="translate(19.8401 14.8799)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default MonkeySticker
