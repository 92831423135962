import ProductGrid from './product-grid'

export * from './product-grid'
export default ProductGrid

export const mapHeroesToProductCards = (heroProduct, overrides = {}) => {
  return {
    productId: heroProduct.id,
    title: heroProduct.shortName,
    callouts: heroProduct.callouts,
    fsaHsaEligible: heroProduct.fsaHsaEligible,
    promotionalBadges: heroProduct.promotionalBadges,
    isFavor: heroProduct.isFavor,
    isCashFund: heroProduct.isCashfund,
    isCrowdfund: heroProduct.isCrowdfund,
    isElectronicGiftCard: heroProduct.isElectronicGiftCard,
    brand: heroProduct.brand,
    imageUrl: heroProduct.images?.url,
    secondaryImageUrl: heroProduct.images?.secondaryUrl,
    gpId: heroProduct.genericProductId,
    path: heroProduct.heroPath,
    price: heroProduct.pricing,
    priceDetails: heroProduct.priceDetails,
    rating: heroProduct.rating,
    reviewCount: heroProduct.reviewCount,
    optionsCta: heroProduct.productOptionsCta,
    productBadge: heroProduct.productBadge,
    merchandisingMessage: heroProduct.merchandisingMessage,
    currentlyInActiveSale: heroProduct.currentlyInActiveSale,
    priceDetails: heroProduct.priceDetails,
    ...overrides,
  }
}
